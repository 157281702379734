import React, { useState } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import FolderIcon from '@mui/icons-material/Folder'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'

import SfModal from '../common/ModalNew'
import Button from '../common/Button'
import { t } from '../../utils/languages/i18n'
import BgStep1 from '../../assets/images/gettingStarted/getting-started-01.png'
import BgStep2 from '../../assets/images/gettingStarted/getting-started-02.png'
import BgStep3 from '../../assets/images/gettingStarted/getting-started-03.png'
import BgStep4 from '../../assets/images/gettingStarted/getting-started-04.png'
import BgStep5 from '../../assets/images/gettingStarted/getting-started-05.png'
import BgStep7 from '../../assets/images/gettingStarted/getting-started-07.png'
import styles from './GettingStartedGuide.module.scss'
import { StoreInterface } from '../../utils/interfaces'
import { saveConfig } from '../../api/requests/config'

interface BackgroundImageInterface {
	background: string
}

const BackgroundImage = styled('div')<BackgroundImageInterface>`
	background-image: url(${(props) => props.background});
	background-position: bottom;
`
const QuickTips = styled('div')`
	height: 36vh;
`

interface GettingStartedGuideInterface {
	closeModal: () => void
	isShowing: boolean
}

const GettingStartedGuide = ({
	closeModal,
	isShowing
}: GettingStartedGuideInterface) => {
	const authUser = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.user
	)

	const [step, setStep] = useState(1)

	const nextStepHandler = () => {
		if (step < 8) setStep(step + 1)
	}

	const previousStepHandler = () => {
		setStep(step - 1)
	}

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<BackgroundImage background={BgStep1} className={styles.body}>
						<h1>
							{t('misc.getting-started.step-1.heading')}, {authUser?.firstname}!
						</h1>
						<p>{t('misc.getting-started.step-1.content-1')}</p>
					</BackgroundImage>
				)
			case 2:
				return (
					<BackgroundImage background={BgStep2} className={styles.body}>
						<h1>{t('misc.getting-started.step-2.heading')}</h1>
						<p>
							<span className={styles.icon}>
								<AccountBalanceIcon />
							</span>
							Library&nbsp;
							<span>{t('misc.getting-started.step-2.content-1')}</span>
						</p>
						<small>{t('misc.getting-started.step-2.content-2')}</small>
						<p>
							<span className={styles.icon}>
								<FolderIcon />
							</span>
							My files&nbsp;
							<span>{t('misc.getting-started.step-2.content-3')}</span>
						</p>
						<small>{t('misc.getting-started.step-2.content-4')}</small>
						<p>
							<span className={styles.icon}>
								<FolderSpecialIcon />
							</span>
							Starred files&nbsp;
							<span> {t('misc.getting-started.step-2.content-5')}</span>
						</p>
						<small>{t('misc.getting-started.step-2.content-6')}</small>
					</BackgroundImage>
				)
			case 3:
				return (
					<BackgroundImage background={BgStep3} className={styles.body}>
						<h1>{t('misc.getting-started.step-3.heading')}</h1>
						<p>{t('misc.getting-started.step-3.content-1')}</p>
						<small>{t('misc.getting-started.step-3.content-2')}</small>
					</BackgroundImage>
				)
			case 4:
				return (
					<BackgroundImage background={BgStep4} className={styles.body}>
						<h1>{t('misc.getting-started.step-4.heading')}</h1>
						<p>{t('misc.getting-started.step-4.content-1')}</p>
						<small>{t('misc.getting-started.step-4.content-2')}</small>
					</BackgroundImage>
				)
			case 5:
				return (
					<BackgroundImage background={BgStep5} className={styles.body}>
						<h1>{t('misc.getting-started.step-5.heading')}</h1>
						<p>{t('misc.getting-started.step-5.content-1')}</p>
						<small>{t('misc.getting-started.step-5.content-2')}</small>
					</BackgroundImage>
				)
			case 6:
				return (
					<div className={styles.body}>
						<QuickTips>
							<h1>{t('misc.getting-started.step-6.heading')}</h1>
							<p>
								<span className={styles.ol}>1</span>{' '}
								{t('misc.getting-started.step-6.content-1')}
							</p>
							<p>
								<span className={styles.ol}>2</span>{' '}
								{t('misc.getting-started.step-6.content-2')}
							</p>
							<p>
								<span className={styles.ol}>3</span>{' '}
								{t('misc.getting-started.step-6.content-3')}
							</p>
							<p>
								<span className={styles.ol}>4</span>{' '}
								{t('misc.getting-started.step-6.content-4')}
							</p>
							<p>
								<span className={styles.ol}>5</span>{' '}
								{t('misc.getting-started.step-6.content-5')}
							</p>
						</QuickTips>
					</div>
				)
			case 7:
				return (
					<BackgroundImage background={BgStep7} className={styles.body}>
						<h1>{t('misc.getting-started.step-7.heading')}</h1>
						<p>{t('misc.getting-started.step-7.content-1')}</p>
					</BackgroundImage>
				)
			default:
				return ''
		}
	}

	/**
	 *
	 * @description save config for getting.started key to prevent
	 * modal from appearing again
	 */
	const skipModalHandler = () => {
		saveConfig('getting.started', 1, true)
		closeModal()
	}

	return (
		<React.Fragment>
			<SfModal
				isShowing={isShowing}
				hide={closeModal}
				customClassName={classnames(styles.gettingStartedModal)}
				modalTitle={`${t('titles.getting-started')} (${step}/7)`}
				headerClassname={styles.header}
				footer={
					<div className={styles.buttonsContainer}>
						<div>
							{step > 1 && step < 8 && (
								<Button
									label={t('buttons.previous')}
									onClick={previousStepHandler}
									isPositive
									id="getting-started-guide-previous"
								/>
							)}
						</div>
						<div>
							<Button
								label={t('buttons.skip-this-guide')}
								onClick={skipModalHandler}
								id="getting-started-guide-skip"
							/>
							{step === 7 ? (
								<Button
									label={t('buttons.close')}
									onClick={skipModalHandler}
									isPositive
									id="getting-started-guide-close"
								/>
							) : (
								<Button
									label={t('buttons.next')}
									onClick={nextStepHandler}
									isPositive
									id="getting-started-guide-next"
								/>
							)}
						</div>
					</div>
				}
				zIndex={10000}
			>
				{renderStep()}
			</SfModal>
		</React.Fragment>
	)
}

export default GettingStartedGuide
