import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { differenceInSeconds } from 'date-fns'
import { Resizable } from 're-resizable'
import { useNavigate } from 'react-router-dom'

import styles from './LibraryNav.module.scss'

import { StoreInterface, TagInterface } from '../../../../utils/interfaces'
import { LIBRARY, PRESENTATION_BAR } from '../../../../utils/consts'
import {
	clearTempPresentation,
	setTempValue
} from '../../../../store/actions/temp'
import { SelectedTagInterface } from '../../../FileBrowser/components/Folder/interfaces'
import Tree from '../../../common/TreeView/Tree'
import {
	setFolderPath,
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading
} from '../../../../store/actions/buildContext'
import { fetchContentByTagId } from '../../../../api/requests/tags'
import { createSlideShow } from '../../../../store/actions/presentation'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import Embedded from '../../../FileBrowser/components/EmbeddedModal'
import { getContentByTagId } from '../../../../store/actions/content'
import { LIBRARY_NAV_WIDTH } from '../../conts'
import { useWindowSize } from '../../../../utils/hooks/useWindowSize'

const sidebar = {
	open: {
		x: 0,
		minWidth: LIBRARY_NAV_WIDTH,
		width: '100%',
		opacity: 1,
		transition: {
			ease: 'easeOut',
			duration: 0.2
		}
	},
	closed: {
		opacity: 0,
		x: -200,
		minWidth: 0,
		width: 0,
		transition: {
			ease: 'easeOut',
			duration: 0.2
		}
	}
}

interface MyFilesNavInterface {
	isVisible: boolean
}

let embeddedContentOpenedTime: Date | number = 0

const LibraryNav = ({ isVisible }: MyFilesNavInterface) => {
	const dispatch = useDispatch()
	const windowSize = useWindowSize()
	const navigate = useNavigate()

	const initialSize = {
		width: LIBRARY_NAV_WIDTH,
		height: windowSize.height || '100%'
	}

	const authUser = useSelector((store: StoreInterface) => store.authUser)
	const buildContext = useSelector(
		(store: StoreInterface) => store.buildContext
	)
	const tagTree = useSelector((store: StoreInterface) => store.tags.tagTree)
	const { isSidebarOpened, sidebarNavsDisabled } = useSelector(
		(store: StoreInterface) => store.temp
	)

	const [selectedTagToEmbed, setSelectedTagToEmbed] =
		React.useState<SelectedTagInterface>()
	const [size, setSize] = React.useState(initialSize)

	/**
	 *
	 * @description fetches the content belongs to that specific tag
	 * and sets the selected tag's data using context API
	 * and toggles the loading state
	 * @param {object} tag the object of the selected tag
	 */
	const fetchContentForTag = (tag: TagInterface) => {
		dispatch(setFolderPath(tag.path))
		dispatch(toggleLoading(true))
		dispatch(getContentByTagId(tag.tag_id, authUser.user.token))
		dispatch(toggleLoading(false))
		dispatch(
			setSelectedCategory({
				...tag,
				id: tag.tag_id,
				categoryName: tag.filename
			})
		)
		dispatch(clearTempPresentation(PRESENTATION_BAR))
		dispatch(setSelectedSlides([]))
	}

	const closeEmbedModalHandler = () => {
		const time = differenceInSeconds(new Date(), embeddedContentOpenedTime)
		if (selectedTagToEmbed) {
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.storyboard.external.opened.time',
					tag_id: selectedTagToEmbed.id,
					name: selectedTagToEmbed.name,
					time
				})
			)
		}
		setSelectedTagToEmbed(undefined)
		embeddedContentOpenedTime = 0
	}

	const openTagHandler = () => {
		if (selectedTagToEmbed && selectedTagToEmbed.target) {
			window.open(selectedTagToEmbed.embeddedUrl, '_blank')
			setSelectedTagToEmbed(undefined)
		} else {
			return (
				<Embedded
					isShowing={!!selectedTagToEmbed}
					closeModal={closeEmbedModalHandler}
					embeddedUrl={selectedTagToEmbed ? selectedTagToEmbed.embeddedUrl : ''}
				/>
			)
		}
	}

	const embeddedUrlHandler = (tag: SelectedTagInterface) => {
		setSelectedTagToEmbed(tag)
		embeddedContentOpenedTime = new Date()
	}

	const autoplayTagHandler = (tag: TagInterface) => {
		fetchContentByTagId(tag.tag_id, authUser.user.token).then((result: any) => {
			const onlyNotHidden = result.filter((file: any) => file.visibility === 1)
			dispatch(
				createSlideShow({
					slides: onlyNotHidden,
					startFrom: 0
				})
			)
		})
	}

	//
	return (
		<motion.div
			layout
			animate={isVisible ? 'open' : 'closed'}
			variants={sidebar}
			initial="closed"
			className={classnames(
				styles.libraryContainer,
				isVisible && styles.visible,
				sidebarNavsDisabled && styles.navsDisabled,
				!isSidebarOpened && styles.collapsedMenuHovered
			)}
			onChange={() => setSize(initialSize)}
		>
			<Resizable
				size={size}
				onResizeStop={(e, direction, ref, d) => {
					if (['left', 'right'].includes(direction)) {
						setSize({
							width: size.width + d.width,
							height: size.height
						})
					}
				}}
				className={styles.resizable}
				onResizeStart={(e, direction) => {
					if (!['left', 'right'].includes(direction)) {
						return false
					}
				}}
			>
				<Tree
					isSidebarCollapsed={true}
					autoplayTag={(tag: TagInterface) => autoplayTagHandler(tag)}
					data={tagTree}
					onClick={(tag) => {
						fetchContentForTag(tag)
						dispatch(setTempValue('libraryHomepageVisible', false))
						dispatch(setTempValue('libraryTreeSelectedTagId', tag.tag_id))
						navigate(`/${LIBRARY.toLowerCase()}/${tag.tag_id}`)
					}}
					// @ts-ignore
					folderPath={buildContext.folderPath}
					selectedTagToEmbed={(tag: SelectedTagInterface) =>
						embeddedUrlHandler(tag)
					}
				/>
				{!!selectedTagToEmbed && openTagHandler()}
			</Resizable>
		</motion.div>
	)
}

export default LibraryNav
