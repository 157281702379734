import React from 'react'
import {
	LinkOff,
	Edit,
	AutoDeleteOutlined,
	InsertDriveFileOutlined
} from '@mui/icons-material'
import c from 'classnames'

import styles from './Thumbnail.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { isLoadedInDeck } from '../../../../utils/helpers/decideThumbanil'

interface ThumbnailInterface {
	className?: string
	draggingFiles?: number
	hoursToDeletion?: number
	extension: string
	isExpired?: boolean
	isHovered?: boolean
	isNew?: boolean
	isRestricted?: boolean
	thumbnailUrl: string
	openAutomatedDeletionModal: (event: React.MouseEvent) => void
	time?: boolean | string
	isEditable: boolean
	role?: string
}

const Thumbnail = ({
	hoursToDeletion,
	thumbnailUrl,
	className,
	extension,
	isExpired,
	isHovered,
	isNew,
	isRestricted,
	openAutomatedDeletionModal,
	time,
	isEditable,
	draggingFiles,
	role
}: ThumbnailInterface) => {
	const checkIfExpiredOrRestricted = () => {
		if (isHovered) {
			if (isRestricted) {
				return (
					<p
						className={styles.restricted}
						title={t('labels.restricted_from_link_sharing')}
					>
						<LinkOff />
					</p>
				)
			}
		} else {
			// check is expired
			if (isExpired) {
				// expired + restricted
				if (isRestricted) {
					if (role && isLoadedInDeck(role)) {
						// expired + restricted + loaded from deck
						return (
							<div className={styles.overlay}>
								<p className={styles.label}>
									{t('labels.restricted_from_link_sharing')}
								</p>
							</div>
						)
					} else {
						return (
							<React.Fragment>
								<p
									className={styles.restricted}
									title={t('labels.restricted_from_link_sharing')}
								>
									<LinkOff />
								</p>
								<div className={styles.overlay}>
									<p className={styles.label}>
										{t('labels.expired').toUpperCase()}
									</p>
								</div>
							</React.Fragment>
						)
					}
				} else {
					// only expired
					return (
						<div className={styles.overlay}>
							<p className={styles.label}>
								{t('labels.expired').toUpperCase()}
							</p>
						</div>
					)
				}
			} else {
				// not expired
				if (isRestricted) {
					// only restricted
					return (
						<div className={styles.overlay}>
							<p
								className={styles.restricted}
								title={t('labels.restricted_from_link_sharing')}
							>
								<LinkOff />
							</p>
						</div>
					)
				}
				// not expired and not restricted
				return null
			}
		}
	}

	return (
		<div className={className}>
			{hoursToDeletion ? (
				<p
					className={`${styles.deletionTime} ${
						Math.round(hoursToDeletion / 24) < 30 ? styles.orangeIcon : ''
					}`}
					onClick={(e) => openAutomatedDeletionModal(e)}
					title={t('tooltips.time-left-until-automatic-deletion')}
				>
					<AutoDeleteOutlined />
					{`${
						hoursToDeletion === 0 || hoursToDeletion < 24
							? '<1'
							: Math.round(hoursToDeletion / 24)
					}d`}
				</p>
			) : null}
			{time && (
				<p className={styles.time} title={t('tooltips.view-time')}>
					{time}
				</p>
			)}
			{thumbnailUrl.includes('data:image') ? (
				<InsertDriveFileOutlined className={styles.noThumbnail} />
			) : (
				<img alt="" className={styles.image} src={thumbnailUrl} />
			)}
			<p className={styles.extension}>
				{isEditable && <Edit />}
				{extension}
			</p>
			<div className={c(draggingFiles && styles.isDragging)}>
				{draggingFiles && (
					<p className={styles.draggingLabel}>{`${t(
						'labels.moving'
					)} ${draggingFiles} ${t('labels.files')}`}</p>
				)}
			</div>
			{checkIfExpiredOrRestricted()}
			{isNew && !time && <p className={styles.new}>{t('labels.new')}</p>}
		</div>
	)
}

export default React.memo(Thumbnail)
