/**
 *
 * @description check if slide has business_rules & tokenRequired property
 * set to true, and return boolean if it's necessary to add token
 */
export const addTokenToSlideTitle = (slide: any) => {
	let addToken = false
	if (slide.business_rules) {
		const businessRules = JSON.parse(slide.business_rules)
		if (businessRules.tokenRequired) {
			addToken = true
		}
	}
	return addToken
}
