import React from 'react'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { format as defaultFormat } from 'date-fns'
import { enUS, fi } from 'date-fns/locale'
import { has, isNull } from 'lodash'
import Parser from 'html-react-parser'
import c from 'classnames'
import {
	AccountCircle as UserProfileIcon,
	OpenInBrowserOutlined as MemositeOpenedIcon,
	FlagOutlined as FlagContentIcon,
	MoreTimeOutlined as MemositeContinuationRequestedIcon,
	LockOutlined as MemositePasswordChangeRequestedIcon,
	FileDownloadOutlined as MemositeDownloadedIcon,
	WorkOutline as SharedPresentationIcon,
	FolderSharedOutlined as SharedFolderIcon,
	DeleteOutlined as DeleteIcon
} from '@mui/icons-material'

import styles from './FeedItem.module.scss'

import {
	BUTTON_TEXT,
	FILE_FLAGGED,
	FILE_RESOLVED,
	MEMOSITE_CODE_REQUEST,
	MEMOSITE_DOWNLOADED,
	MEMOSITE_OPENED,
	MEMOSITE_REQUEST,
	SHARED_FOLDER,
	SHARED_PRESENTATION
} from '../../../../utils/consts'
import {
	FeedPropertiesInterface,
	FeedResponseItemInterface,
	StoreInterface
} from '../../../../utils/interfaces'
import { getActiveLanguage, t } from '../../../../utils/languages/i18n'
import { useSelector } from 'react-redux'
import Button from '../../../../components/common/Button'
import { useNavigate } from 'react-router-dom'
import getAdminPath from '../../../../utils/helpers/getAdminPath'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'

interface FeedItemInterface {
	item: FeedResponseItemInterface
	isUnread?: boolean
	sendFeedToDelete?: (id: number) => void
}

const FeedItem = ({ item, isUnread, sendFeedToDelete }: FeedItemInterface) => {
	const navigate = useNavigate()

	const { user } = useSelector((store: StoreInterface) => store.authUser)

	const dateLocale = getActiveLanguage()?.lng === 'fi' ? fi : enUS

	const createDate = () => {
		const hasUserTimeZone =
			has(user.user, 'timezone') &&
			!isNull(user.user.timezone) &&
			user.user.timezone !== ''
		const userTimeZone = hasUserTimeZone
			? JSON.parse(user.user.timezone || '')
			: false

		return userTimeZone
			? format(
					zonedTimeToUtc(new Date(item.created_at), userTimeZone.value),
					'dd / MM / yyyy HH:mm'
			  )
			: defaultFormat(new Date(item.created_at), 'dd / MM / yyyy HH:mm', {
					locale: dateLocale
			  })
	}

	/**
	 *
	 * @description creates notification JSX from notification object
	 */
	const createNotificationText = () => {
		const notificationType = item.content_new
		const sharedBy = `${item.user.firstname} ${item.user.lastname}`
		try {
			const properties: FeedPropertiesInterface =
				item.properties &&
				typeof item.properties === 'string' &&
				JSON.parse(item.properties)
			let itemText: React.ReactElement | string = ''
			let itemIcon: React.ReactElement | string = ''
			let itemButton: React.ReactElement | string = ''
			switch (notificationType) {
				case MEMOSITE_OPENED:
					itemText = `${t(
						`notifications.feed-items.${notificationType.toLowerCase()}`
					)}: ${properties.subject}`
					itemIcon = <MemositeOpenedIcon />
					itemButton = properties?.slug ? (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() => navigate(`/followup#${properties?.slug}`)}
							label={t('buttons.view_data')}
							id="feed-see-data-button"
						/>
					) : (
						''
					)
					break
				case MEMOSITE_REQUEST:
					itemText = (
						<span className={styles.messageMeta}>
							{t(`notifications.feed-items.${notificationType.toLowerCase()}`)}:{' '}
						</span>
					)
					itemIcon = <MemositeContinuationRequestedIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() => navigate(`/followup#${properties.request.slug}`)}
							label={t('buttons.view_memosite')}
							id="feed-open-memosite-button"
						/>
					)
					break
				case SHARED_PRESENTATION:
					itemText = (
						<span className={styles.messageMeta}>
							<span>
								{t(
									`notifications.feed-items.${notificationType.toLowerCase()}`
								)}
							</span>
							<span>{`${t('labels.presentation')}: ${
								properties.subject
							}`}</span>
							<span>
								{t('labels.presentation-from')}: {sharedBy}
							</span>
						</span>
					)
					itemIcon = <SharedPresentationIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() => navigate(`/my-presentations`)}
							label={t('buttons.go_to_my_presentations')}
							id="feed-go-to-my-presentations-button"
						/>
					)
					break
				case FILE_RESOLVED:
					itemText = (
						<span className={styles.messageMeta}>
							<span className={styles.text}>
								{t(
									`notifications.feed-items.${notificationType.toLowerCase()}`
								)}
							</span>
							{properties.material_name && (
								<span>
									{t('labels.file')}: {properties.material_name}
								</span>
							)}
							<span>{`${t('labels.comment')}: ${
								properties.comments || properties.description
							}`}</span>
							<span>
								{t('labels.presentation-from')}: {sharedBy}
							</span>
						</span>
					)
					itemIcon = <FlagContentIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() =>
								window.open(
									getAdminPath(user.refresh_token, user.token),
									'_self'
								)
							}
							label={t('buttons.go_to_admin_tools')}
							id="feed-go-to-folder-button"
						/>
					)
					break
				case FILE_FLAGGED:
					itemText = (
						<span className={styles.messageMeta}>
							<span className={styles.text}>
								{t(
									`notifications.feed-items.${notificationType.toLowerCase()}`
								)}
								{properties.material_name && `: ${properties.material_name}`}
							</span>
							<span>
								{t('labels.by')}: {sharedBy}
							</span>
							<span>{`${t('labels.comment')}: ${
								properties.comments || properties.description
							}`}</span>
						</span>
					)
					itemIcon = <FlagContentIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() =>
								window.open(
									getAdminPath(user.refresh_token, user.token),
									'_self'
								)
							}
							label={t('buttons.go_to_admin_tools')}
							id="feed-go-to-admin-tools-button"
						/>
					)
					break
				case SHARED_FOLDER:
					itemText = (
						<span className={styles.messageMeta}>
							<span>
								{sharedBy} {t('misc.shared-folder-notification')}:{' '}
								{properties.name}
							</span>
						</span>
					)
					itemIcon = <SharedFolderIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() => navigate(`/my-files`)}
							label={t('buttons.go_to_my_files')}
							id="feed-go-to-my-files-button"
						/>
					)
					break
				case MEMOSITE_CODE_REQUEST:
					itemText = (
						<span className={styles.messageMeta}>
							<span>
								{t('notifications.feed-items.memosite_password_change_request')}
							</span>
							<span>
								{`${properties.request.firstName} ${
									properties.request.lastName
								} (${properties.request.emailAddress}) ${t(
									'notifications.feed-items.tried_wrong_password'
								)}`}{' '}
							</span>
							<span>
								<a
									href={`${window.location.origin}/followup#${properties.request.slug}`}
									target="_blank"
									rel="noreferrer"
								>
									{properties.meeting.subject}
								</a>{' '}
								{t('notifications.feed-items.memosite_locked_change_password')}
							</span>
						</span>
					)
					itemIcon = <MemositePasswordChangeRequestedIcon />
					break
				case MEMOSITE_DOWNLOADED:
					itemText = (
						<span>
							{t('notifications.feed-items.memosite_downloaded')}:{' '}
							{properties.subject}
						</span>
					)
					itemIcon = <MemositeDownloadedIcon />
					itemButton = (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.actionBtn}
							onClick={() => navigate(`/followup#${properties?.slug}`)}
							label={t('buttons.view_memosite')}
							id="feed-open-memosite-button"
						/>
					)
					break
				default:
					return item.content
			}
			return (
				<div
					className={c(styles.notificationWrapper, isUnread && styles.unread)}
				>
					<div className={styles.icon}>{itemIcon}</div>
					<div className={styles.content}>
						<div className={styles.message}>{itemText}</div>
						<div className={styles.meta}>
							<div>{createDate()}</div>
							{itemButton}
						</div>
					</div>
				</div>
			)
		} catch (error) {
			console.error(error)
		}
		return false
	}

	const createMessageText = () => {
		return (
			<div className={c(styles.messageWrapper, isUnread && styles.unread)}>
				<div className={styles.user}>
					{item.user.profile_picture ? (
						<img
							src={`${sfApiRoot}/files/assets/files/${
								item.user.profile_picture
							}?oauth=${encodeURIComponent(user.token)}`}
							alt=""
						/>
					) : (
						<UserProfileIcon />
					)}
				</div>
				<div className={styles.content}>
					<div className={styles.message}>
						{Parser(item.content.replace(/\n/g, '<br>'))}
					</div>
					<div className={styles.meta}>
						<div>{`${item.user.firstname} ${item.user.lastname}`}</div>
						<div className={styles.right}>
							{item.creator === user.user.id && (
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.actionBtn}
									onClick={() => sendFeedToDelete && sendFeedToDelete(item.id)}
									id="feed-delete-button"
									icon={<DeleteIcon />}
								/>
							)}{' '}
							{createDate()}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<React.Fragment>
			{item.type === 'feed' ? createMessageText() : createNotificationText()}
		</React.Fragment>
	)
}

export default FeedItem
