import React, { useState } from 'react'
import classnames from 'classnames'
import Proptypes from 'prop-types'

import styles from './RadioGroup.module.scss'

/**
 * @author zilahir
 * @function RadioGroup
 * */

const RadioGroup = props => {
	const { radioItems, defaultSelected, continerClass, withCustomIcon, onChange } = props
	const [isChecked, toggleChecked] = useState(defaultSelected)

	/**
	 *
	 * @description set the clicked radio button to active
	 * @param {object} e the performed click's event object
	 * @param {number} ind the index of the selected radio button
	 */
	function handleCheckBoxClick(e, ind) {
		toggleChecked(ind)
		onChange(ind)
		e.stopPropagation()
	}
	return (
		<div className={classnames(
			styles.radioContainer,
			continerClass,
		)}
		>
			{
				radioItems.map((currRadio, index) => (
					<div
						key={currRadio.key}
						className={classnames(
							styles.oneRadio,
							isChecked === index ? styles.isChecked : styles.notChecked,
						)}
					>
						<label
							htmlFor={`radio-${currRadio.key}`}
							className={classnames(
								styles.label,
								withCustomIcon ? styles.withCustomIcon : '',
							)}
						>
							{currRadio.icon}
							<input
								checked={isChecked === currRadio.key}
								type="radio"
								id={`radio-${currRadio.key}`}
								onChange={e => handleCheckBoxClick(e, index)}
							/>
							{currRadio.label}
						</label>
					</div>
				))
			}
		</div>
	)
}

RadioGroup.defaultProps = {
	continerClass: null,
	defaultSelected: null,
	onChange: () => {},
	withCustomIcon: false,
}

RadioGroup.propTypes = {
	continerClass: Proptypes.string,
	defaultSelected: Proptypes.number,
	onChange: Proptypes.func,
	radioItems: Proptypes.arrayOf(
		Proptypes.objectOf(
			Proptypes.any,
		),
	).isRequired,
	withCustomIcon: Proptypes.bool,
}

export default RadioGroup
