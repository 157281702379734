import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import classnames from 'classnames'
import { EditorState } from 'draft-js'

import styles from './TextGreetingModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import Modal from '../../../common/ModalNew'

interface TextGreetingModalInterface {
	editGreeting: EditorState
	handleClose: () => void
	saveAndClose: (greeting: EditorState, greetingPlainText: string) => void
}

const TextGreetingModal = ({
	editGreeting,
	handleClose,
	saveAndClose
}: TextGreetingModalInterface) => {
	const editor = React.useRef(null)

	const [memositeGreeting, setMemositeGreeting] = React.useState(
		editGreeting || EditorState.createEmpty()
	)

	return (
		<Modal
			isShowing
			hide={handleClose}
			customClassName={classnames(styles.modalWrapper)}
			modalTitle={t('titles.write_text_greeting')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={handleClose}
						id="text-greeting-modal-cancel"
					/>
					<Button
						label={t('buttons.save_and_close')}
						onClick={() =>
							saveAndClose(
								memositeGreeting,
								memositeGreeting.getCurrentContent().getPlainText()
							)
						}
						isPositive
						id="text-greeting-modal-save"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<Editor
					ref={editor}
					editorState={memositeGreeting}
					onEditorStateChange={setMemositeGreeting}
					placeholder={t('input-placeholders.write-a-greeting')}
					wrapperClassName={styles.editorWrapper}
					editorClassName={styles.editor}
					toolbarClassName={styles.editorToolbar}
					toolbar={{
						options: ['inline', 'link', 'list', 'blockType'],
						link: {
							className: 'customIcon'
						},
						inline: {
							options: ['bold', 'italic'],
							className: 'button',
							bold: {
								className: 'customIcon'
							},
							italic: {
								className: 'customIcon'
							}
						},
						list: {
							options: ['unordered', 'ordered'],
							className: 'customIcon'
						},
						blockType: {
							inDropdown: false,
							options: ['Normal', 'H1', 'H2'],
							className: 'button'
						}
					}}
					stripPastedStyles
				/>
			</div>
		</Modal>
	)
}

export default TextGreetingModal
