import { fetchFolders, fetchTemplates } from '../../api/requests/customSlides'
import {
	SET_FOLDERS_CUSTOM_SLIDES,
	SET_TEMPLATES_CUSTOM_SLIDES
} from './actionTypes'

export const setFoldersForCustomSlides = (folders: any) => (dispatch: any) =>
	new Promise((resolve) => {
		dispatch({
			type: SET_FOLDERS_CUSTOM_SLIDES,
			payload: {
				folders
			}
		})
		// @ts-ignore
		resolve()
	})
export const getFoldersForCustomSlides = () => (dispatch: any) =>
	new Promise((resolve) => {
		fetchFolders().then((response: any) => {
			dispatch(setFoldersForCustomSlides(response))
			resolve(response)
		})
	})
export const setTemplatesForCustomSlides = (content: any) => (dispatch: any) =>
	new Promise((resolve) => {
		dispatch({
			type: SET_TEMPLATES_CUSTOM_SLIDES,
			payload: {
				content
			}
		})
		// @ts-ignore
		resolve()
	})
export const getTemplatesForCustomSlides =
	(folderId: number) => (dispatch: any) =>
		new Promise((resolve) => {
			fetchTemplates(folderId).then((response: any) => {
				dispatch(setTemplatesForCustomSlides(response))
				resolve(response)
			})
		})
