import { cloudFnPost, cloudFnDelete, cloudFnGet } from '../../utils/requests'
import { saveFeedSchema } from '../../utils/schemas/requests'
import { FeedResponseInterface } from '../../utils/interfaces'
import {
	getNewsByCodeEndpoint,
	getNewsEndpoint,
	newsEndpoint
} from '../apiEndpoints_new'

export const saveFeed = (feed: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(`${newsEndpoint}`, feed, {}, saveFeedSchema)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteFeed = (feedId: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(`${newsEndpoint}/${feedId}`)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getFeeds = (
	offset: number,
	type: string,
	date?: string
): Promise<FeedResponseInterface> =>
	new Promise((resolve, reject) => {
		const params = date
			? `${getNewsEndpoint}/${offset}/5/${type}/${date}`
			: `${getNewsEndpoint}/${offset}/5/${type}`
		cloudFnGet(params)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getFeedNotificationsByCode = (
	code: string
): Promise<FeedResponseInterface> =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${getNewsByCodeEndpoint}/${code}`, {}, null)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
