import React from 'react'
import classnames from 'classnames'
import MoonLoader from 'react-spinners/MoonLoader'

import styles from './Spinner.module.scss'

import { theme } from '../../../utils/theme'

interface SpinnerInterface {
	color?: string
	size?: number
	wrapperClassName?: string
	isLoading?: boolean
}

const Spinner = ({
	color,
	size,
	wrapperClassName,
	isLoading
}: SpinnerInterface) => (
	<div className={classnames(styles.wrapper, wrapperClassName)}>
		<MoonLoader
			size={size || 40}
			color={color || theme.colors.spinnerColor}
			loading={isLoading}
		/>
	</div>
)

export default Spinner
