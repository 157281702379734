import { format } from 'date-fns'

declare global {
	interface Window {
		formatDate: (date: string, format: string) => string
	}
}

window.formatDate = (date: string, dateFormat: string) => {
	try {
		return format(new Date(date), dateFormat)
	} catch {}
	return ''
}
