import { useState, useRef, useEffect } from 'react'
import { clamp, distance } from 'popmotion'
import { arrayMoveImmutable } from 'array-move'

const buffer = 30

export const findIndex = (i: any, offset: any, positions: any) => {
	let target = i
	const xOffset = offset.x
	const yOffset = offset.y
	const { top, height } = positions[i]
	const bottom = top + height

	// TODO: XES-797
	// to implement reorder on both axis (xy) you need to consider
	// the value of x offset together with y offset determines the direction
	// the remaining task here is to figure out a way
	// how to find the inddx using both delta (x, y) of the dragging event
	// calculating the position of the other items of the positions
	// and swapping the item in the provided array (positions)
	// the challange: to move on both axis, we need to respect the width as well of the
	// element being moved, to be able to calculate which element is currently being given new position
	// i am using the frammer motion gesture event here
	// keep in mind that there are components where only Y dragging is enabled (layouot vertical alignment)
	// and there are others where only X dragging is allowed (small presentation bar)

	if (yOffset > 0) {
		// moving UP
		const nextItem = positions[i + 1]
		if (nextItem === undefined) return i

		const swapOffset
      = distance(bottom, nextItem.top + nextItem.height / 2) + buffer
		if (yOffset > swapOffset) target = i + 1
	} else if (yOffset < 0) {
		const prevItem = positions[i - 1]
		if (prevItem === undefined) return i
		const prevBottom = prevItem.top + prevItem.height
		const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
		if (yOffset < -swapOffset) target = i - 1
	}

	if (xOffset > 0) {
		const nextItem = positions[i + 1]
		if (nextItem === undefined) return i

		const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer
		if (xOffset > swapOffset) target = i + 1

		// If moving left or top
	} else if (xOffset < 0) {
		const prevItem = positions[i - 1]
		if (prevItem === undefined) return i

		const prevBottom = prevItem.top + prevItem.height
		const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer
		if (xOffset < -swapOffset) target = i - 1
	}

	return clamp(0, positions.length, target)
}


/**
 * @param {Array} initialState the initial array that can be reorder with dragging
 * @returns {Array} an array containing the new order, and the update functions
 */
export function usePositionReorder(initialState: any) {
	const [order, setOrder] = useState(initialState)

	useEffect(() => {
		setOrder(initialState)
	}, [initialState])

	// We need to collect an array of height and position data for all of this component's
	// `Item` children, so we can later us that in calculations to decide when a dragging
	// `Item` should swap places with its siblings.
	const positions: any = useRef([]).current
	// eslint-disable-next-line no-return-assign
	const updatePosition = (i: any, offset: any) => (positions[i] = offset)

	// Find the ideal index for a dragging item based on its position in the array, and its
	// current drag offset. If it's different to its current index, we swap this item with that
	// sibling.
	const updateOrder = (i: any, dragOffset: any) => {
		const targetIndex = findIndex(i, dragOffset, positions)
		if (targetIndex !== i) {
			setOrder(arrayMoveImmutable(order, i, targetIndex))
		}
	}

	return [order, updatePosition, updateOrder, setOrder]
}
