import { cloudFnGet } from '../../utils/requests'
import { customerFontsEndpoint } from '../apiEndpoints_new'

export const getCustomerFonts = () =>
	new Promise<any>((resolve, reject) => {
		cloudFnGet(customerFontsEndpoint, null, null)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
