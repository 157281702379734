import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'
import styles from './MobileVerificationCode.module.scss'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface } from '../../utils/interfaces'
import { t } from '../../utils/languages/i18n'

const MobileVerificationCode = () => {
	const dispatch = useDispatch()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		dispatch(
			setTempValue(
				'currentModule',
				ModuleNamesInterface.MOBILE_VERIFICATION_CODE
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.signupContainer}>
			<div className={styles.card}>
				<div className={styles.container}>
					<div className={styles.logo}>
						<img src={salesframeLogo} alt="salesframe" />
					</div>
					<p className={styles.verificationText}>
						{t('labels.your-verification-code-is')}
					</p>
					{searchParams.get('oauth_code') && (
						<p className={styles.verificationCodeText}>
							{searchParams.get('oauth_code')}
						</p>
					)}
					<p className={styles.explainerText}>
						{t('labels.verification-code-explainer')}
					</p>
				</div>
			</div>
		</div>
	)
}

export default MobileVerificationCode
