const collaboraOnlineStyles = `
	#presentation-controls-wrapper {
		display: none !important;
	}
	
	body {
		overflow: hidden !important;
	}
	
	#File-tab-label {
		display: none !important;
	}
	
	#sidebar-dock-wrapper {
		display: none !important;
	}
	
	#document-header {
		display: none !important;
	}
	
	.notebookbar-options-section {
		display: none !important;
	}
	
	.cell:has(> div#Presentation) {
		display: none;
	}
	
	#Help-tab-label {
		display: none !important;
	}
	
	#Insert > .cell:nth-child(1), #Insert > .cell:nth-child(2), #Insert > .cell:nth-child(5), #Insert > .cell:nth-child(10) {
		display: none;
	}
	
	#Layout > .cell:nth-child(2), #Layout > .cell:nth-child(3), #Layout > .cell:nth-child(5), #Layout > .cell:nth-child(6), #Layout > .cell:nth-child(7) {
		display: none;
	}
	
	.cell:has(> div#DuplicatePage2) {
		display: none;
	}
	
	#Review-tab-label {
		display: none !important;
	}
	
	#View-container-row > .cell:nth-child(1), #View-container-row > .cell:nth-child(4), #View-container-row > .cell:nth-child(6), #View-container-row > .cell:nth-child(7), #View-container-row > .cell:nth-child(8) {
		display: none;
	}
	
	.notebookbar-tabs-container {
		flex: none !important;
	}
	
	#document-name-input {
		width: 100%;
		text-align: center;
		display: none;
	}
	
	.document-title .file-title {
		font-family: var(--cool-font);
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
	}
`

export default collaboraOnlineStyles
