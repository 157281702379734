import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import { useAnimation } from 'framer-motion'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import styles from './Forgot.module.scss'
import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import { forget } from '../../api/requests/user'
import { DEFAULT } from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import Spinner from '../../components/common/Spinner'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface } from '../../utils/interfaces'

const ForgotPassword = () => {
	const [captchaToken, setCaptchaToken] = useState('')
	const [email, setEmail] = useState(undefined)
	const [step1, setStep1] = useState(true)
	const [step2, setStep2] = useState(false)
	const [userExists, setUserExists] = useState(true)
	const [isLoading, toggleLoading] = useState(false)
	const loginControl = useAnimation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			setTempValue('currentModule', ModuleNamesInterface.FORGET_PASSWORD)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 * @function handleVerify generates captcha
	 * @param token
	 */
	function handleVerify(token: string) {
		setCaptchaToken(token)
	}
	/**
	 * @function step1done checks email and send forget password request
	 */
	const step1done = () => {
		loginControl.start('loading').then(() => {
			toggleLoading(true)
		})
		const userObject: any = {
			captcha: captchaToken,
			phase: '1',
			email
		}

		forget(userObject)
			.then(() => {
				toggleLoading(false)
				setStep1(false)
				setStep2(true)
			})
			.catch(() => {
				toggleLoading(false)
				setUserExists(false)
				setStep1(true)
				setStep2(false)
			})
		setCaptchaToken('')
	}

	const routeChange = () => {
		const path = `/login`
		navigate(path)
	}

	/**
	 * @returns first page component
	 */
	function firstStep() {
		return (
			<React.Fragment>
				{!captchaToken && (
					<GoogleReCaptcha onVerify={(token) => handleVerify(token)} />
				)}
				<h4 className={styles.forgotPassword}>{t('misc.find-your-account')}</h4>
				<p className={styles.description}>{t('titles.enter-email')}</p>
				<form>
					<Input
						inputClassName={
							userExists || !validator.isEmail(email || '')
								? styles.inputError
								: null
						}
						label={null}
						onChange={(e) => setEmail(e.target.value)}
						name="email"
						wrapperClassName={styles.forgotPasswordInput}
						initialValue={email}
						state={DEFAULT.toLowerCase()}
						placeholderText={t('input-placeholders.your-email')}
					/>

					{!userExists && (
						<p className={styles.description}>
							{t('misc.email-not-registered')}
							<span>
								<a className={styles.links} href="/signup">
									{t('misc.signup')}?
								</a>
							</span>{' '}
						</p>
					)}

					{email && validator.isEmail(email || '') ? (
						<Button
							label={t('buttons.next')}
							onClick={step1done}
							buttonClass={styles.forgotPasswordBtn}
							containerClass={styles.forgotPasswordBtnContainer}
							isPositive
							id="forgot-password-next"
						/>
					) : (
						<Button
							label={t('buttons.next')}
							onClick={null}
							buttonClass={styles.forgotPasswordBtn}
							containerClass={styles.forgotPasswordBtnContainer}
							isDisabled
							id="forgot-password-next"
						/>
					)}

					<Button
						label={t('buttons.cancel')}
						onClick={routeChange}
						buttonClass={styles.forgotPasswordBtn}
						containerClass={styles.forgotPasswordBtnContainer}
						id="forgot-password-cancel"
					/>
				</form>
			</React.Fragment>
		)
	}

	/**
	 * @returns second page component
	 */
	function secondStep() {
		return (
			<div>
				<h4 className={styles.forgotPassword}>{t('misc.reset-password')}</h4>
				<p className={styles.description}>{t('titles.reset-link-sent')}</p>
			</div>
		)
	}

	return (
		<React.Fragment>
			<div className={styles.forgotPasswordContainer}>
				<div className={styles.card}>
					<div className={styles.container}>
						<div className={styles.logo}>
							<img src={salesframeLogo} alt="salesframe" />
						</div>
						{step1 ? firstStep() : null}
						{step2 ? secondStep() : null}
					</div>
					{isLoading && (
						<div className={styles.loader}>
							<Spinner isLoading size={44} />
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	)
}

export default ForgotPassword
