import React from 'react'
import { ReactSVG } from 'react-svg'

import chevronLeftIcon from './svg.svg'

export const ChevronLeft = () => (
	<ReactSVG
		role="img"
		src={chevronLeftIcon}
	/>
)
