import { SET_TREE_DATA } from '../actions/actionTypes'

const initialState = {
	treeData: [{ rootId: 'root', items: {} }]
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_TREE_DATA:
			return {
				...state,
				treeData: action.payload.items
			}
		default:
			return state
	}
}

export default reducer
