import React, { useEffect } from 'react'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import styles from './AutomatedDeletionModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import Modal from '../../../common/ModalNew'
import { StoreInterface } from '../../../../utils/interfaces'
import { getConfig } from '../../../../api/requests/config'
import { setTempValue } from '../../../../store/actions/temp'

interface AutomatedDeletionModalInterface {
	action: () => void
	closeModal: () => void
	hoursToDeletion: number | null
	isVisible: boolean
}

const AutomatedDeletionModal = ({
	action,
	closeModal,
	hoursToDeletion,
	isVisible
}: AutomatedDeletionModalInterface) => {
	const dispatch = useDispatch()

	const { automatedDeleteTime } = useSelector(
		(store: StoreInterface) => store.temp
	)

	useEffect(() => {
		if (isVisible && !automatedDeleteTime) {
			getConfig('automated_delete_time').then((response) => {
				if (response) {
					dispatch(setTempValue('automatedDeleteTime', response.value))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible])

	const translateHoursToDays = () => {
		if (hoursToDeletion) {
			if (hoursToDeletion === 0 || hoursToDeletion < 24) return '<1'
			return Math.round(hoursToDeletion / 24).toString()
		}
		return ''
	}

	return (
		<Modal
			isShowing={isVisible}
			hide={closeModal}
			customClassName={classnames(styles.modalWrapper)}
			modalTitle={t('titles.file-auto-deletion')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.okay')}
						onClick={closeModal}
						isPositive
						id="automated-deletion-modal-okay"
					/>
					<Button
						label={t('labels.reset')}
						onClick={action}
						id="automated-deletion-modal-reset"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				{t('misc.auto_file_deletion', [
					translateHoursToDays(),
					automatedDeleteTime ? automatedDeleteTime.toString() : ''
				])}
			</div>
		</Modal>
	)
}

export default AutomatedDeletionModal
