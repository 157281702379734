import React from 'react'
import ReactPaginate from 'react-paginate'

import styles from './Pagination.module.scss'
import { t } from '../../../../utils/languages/i18n'

interface OnChangeInterface {
	selected: number
}

interface PaginationInterface {
	changePage: (selectedItem: OnChangeInterface) => void
	memositePaginationPage: number
	numberOfPages: number
}

const Pagination = ({
	changePage,
	memositePaginationPage,
	numberOfPages
}: PaginationInterface) => (
	<ReactPaginate
		activeClassName={styles.active}
		containerClassName={styles.pagination}
		breakLabel="..."
		nextLabel={t('buttons.next')}
		onPageChange={changePage}
		pageClassName={styles.item}
		pageRangeDisplayed={2}
		pageCount={numberOfPages}
		previousClassName={styles.item}
		nextClassName={styles.item}
		previousLabel={t('buttons.previous')}
		renderOnZeroPageCount={undefined}
		forcePage={memositePaginationPage}
	/>
)

export default Pagination
