import { cloudFnGet } from '../../utils/requests'
import { configEndpoint } from '../apiEndpoints_new'

export const getOffers = () =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${configEndpoint}/offers`)
			.then((offerResult) => {
				resolve(offerResult)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
