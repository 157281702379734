import { nanoid } from 'nanoid'

import {
	cloudFnGet,
	cloudFnPost,
	cloudFnDelete,
	cloudFnPut
} from '../../utils/requests'
import {
	SET_MY_FILES_CATEGORIES,
	SET_MY_FILES,
	CLEAR_MY_FILES,
	ADD_FILE_TO_MY_FILES,
	CREATE_FOLDER_MODAL
} from './actionTypes'
import {
	createMyFilesCategorySchema,
	renameMyFileCategorySchema
} from '../../utils/schemas/requests'
import { sortMyFileCategories } from '../../utils/helpers/myFiles'
import { myFilesEndpoint, myFolderEndpoint } from '../../api/apiEndpoints_new'

export const setMyFilesCategories =
	(myFilesCategories: any) => (dispatch: any) =>
		new Promise((resolve) => {
			dispatch({
				type: SET_MY_FILES_CATEGORIES,
				payload: {
					myFilesCategories
				}
			})
			// @ts-ignore
			resolve()
		})

export const getAllMyFilesCategories = () => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(myFolderEndpoint)
			.then((response: any) => {
				dispatch(setMyFilesCategories(sortMyFileCategories(response)))
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const createMyFilesFolder = (newCategoryName: any, authToken: any) =>
	new Promise((resolve, reject) => {
		const newMyCategoryObject = {
			parent: 0,
			name: newCategoryName
		}
		cloudFnPost(
			myFolderEndpoint,
			newMyCategoryObject,
			{ withToken: true, authToken },
			createMyFilesCategorySchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const setMyFilesContent = (myFilesContent: any) => (dispatch: any) =>
	new Promise((resolve) => {
		dispatch({
			type: SET_MY_FILES,
			payload: {
				myFilesContent
			}
		})
		// @ts-ignore
		resolve()
	})

export const getMyFilesByCategoryId =
	(categoryId: any, authToken: any) => (dispatch: any) =>
		new Promise((resolve, reject) => {
			cloudFnGet(
				`${myFilesEndpoint}/${categoryId}`,
				{},
				{ withToken: true, authToken }
			)
				.then((response: any) => {
					const uniqueFiles = response.map((file: any) => ({
						...file,
						key: nanoid()
					}))
					dispatch(setMyFilesContent(uniqueFiles))
					resolve(response)
				})
				.catch((error) => {
					reject(new Error(`There was an error: ${error}`))
				})
		})

export const removeCategroyById = (categoryId: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(
			`${myFolderEndpoint}/${categoryId}`,
			{},
			{ withToken: true, authToken }
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const modifyMyFileCategory = (myCategory: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPut(
			`${myFolderEndpoint}/${myCategory.id}`,
			myCategory,
			{ withToken: true, authToken },
			renameMyFileCategorySchema
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const clearMyFiles = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_MY_FILES,
		payload: null
	})
}

export const addFileToMyFiles = (myFile: any) => (dispatch: any) => {
	dispatch({
		type: ADD_FILE_TO_MY_FILES,
		payload: {
			myFile
		}
	})
}

export const createFolderModal =
	(createFolderModalVisible: boolean) => (dispatch: any) => {
		dispatch({
			type: CREATE_FOLDER_MODAL,
			payload: {
				createFolderModalVisible
			}
		})
	}
