import React from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import ReactToggle from 'react-toggle'
import 'react-toggle/style.css'

import './Toggle.scss'

/**
 * @author zilahir
 * @function Toggle
 * */

const Toggle = (props) => {
	const { states, currentState, wrapperClassname, onChange } = props

	const [value, setValue] = React.useState(currentState)

	React.useEffect(() => setValue(currentState), [currentState])

	/**
	 *
	 * @description Handles the toggling true|false states
	 * @param {boolean} bool the true|false parameter te toggle needs to be set.
	 */
	function toggleToggle(bool) {
		onChange(bool)
	}

	/**
	 *
	 *
	 * @returns {string} the corresponding label according to the state of the tooggle
	 */
	function getLabel() {
		return currentState ? states.on : states.off
	}
	return (
		<div className={classnames('toggleContainer', wrapperClassname)}>
			<ReactToggle
				onChange={(e) => toggleToggle(e.target.checked)}
				checked={value}
				icons={null}
			/>
			<p>{getLabel()}</p>
		</div>
	)
}

Toggle.defaultProps = {
	currentState: false,
	wrapperClassname: null
}

Toggle.propTypes = {
	currentState: Proptypes.bool,
	onChange: Proptypes.func.isRequired,
	states: Proptypes.objectOf(Proptypes.string).isRequired,
	wrapperClassname: Proptypes.string
}

export default Toggle
