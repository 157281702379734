import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning'

import Modal from '../../../../components/common/ModalNew'
import styles from './AdvancedBusinessRulesModal.module.scss'
import Section from '../../../../components/common/Section'
import JSONEditor from '../../../../components/JSONEditor'
import { FLEX_START } from '../../../../utils/consts'
import Button from '../../../../components/common/Button'
import { t } from '../../../../utils/languages/i18n'
import { toast } from 'react-toastify'
import { modifyFolderOptions } from '../../../../api/requests/layout'
import { setTagBusinessRules } from '../../../../store/actions/storyBoardContext'
import { getTags, getAllShortcuts } from '../../../../store/actions/tags'

const AdvancedBusinessRulesModal = ({ isShowing, handleClose }) => {
	const { tagBusinessRules } = useSelector((state) => state.storyboardContext)
	const storyBoardContext = useSelector((store) => store.storyboardContext)
	const authToken = useSelector((store) => store.authUser.user.token)
	const dispatch = useDispatch()
	const [isDirty, toggleDirty] = useState(false)
	const [jsonSrc, setJsonSrc] = useState(tagBusinessRules)

	const handleSave = () => {
		modifyFolderOptions(
			jsonSrc,
			'business_rules',
			storyBoardContext.selectedTag.id,
			authToken
		).then(() => {
			handleClose()
			dispatch(getTags(authToken))
			dispatch(getAllShortcuts())
			dispatch(setTagBusinessRules(jsonSrc))
			toast(t('notifications.success.folder-options-saved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: 'success',
				autoClose: 5000
			})
		})
	}
	return (
		<Modal
			isShowing={isShowing}
			hide={handleClose}
			customClassName={styles.advanceBRModal}
			modalTitle={t('titles.advanced-business-rules')}
		>
			<Section sectionClassName={styles.danger} alignItems="center">
				<p>
					<WarningIcon htmlColor="#ffffff" />
					{t('titles.make-sure-you-know')}
				</p>
			</Section>
			<Section padding="20px" justify={FLEX_START}>
				<JSONEditor
					jsonSrc={jsonSrc}
					onChange={toggleDirty}
					setJsonSrc={setJsonSrc}
				/>
			</Section>
			<Section>
				<Button label={t('buttons.cancel')} onClick={handleClose} />
				<Button
					label={t('buttons.save')}
					onClick={handleSave}
					isDisabled={!isDirty}
					isPositive
				/>
			</Section>
		</Modal>
	)
}

AdvancedBusinessRulesModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	isShowing: PropTypes.bool.isRequired
}

export default AdvancedBusinessRulesModal
