import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
	LibraryAddCheck as SelectAllIcon,
	PlayCircleOutlined as PlayAllIcon,
	Search as SearchIcon,
	FolderOutlined as FolderIcon,
	VerticalSplitOutlined as NavigationToggleIcon,
	ArrowBack as BackIcon,
	EditOutlined as EditIcon,
	DeleteOutline as DeleteIcon,
	GroupOutlined as PersonAddIcon,
	ImageOutlined as ImageIcon,
	DynamicFeed as DynamicFeedIcon,
	ExitToApp as MoveIcon,
	Close as CloseIcon,
	OpenInNew as OpenFolderIcon,
	FolderSharedOutlined as FolderSharedIcon,
	FolderDeleteOutlined as DeleteFolderIcon,
	Info as InfoIcon
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import objectScan from 'object-scan'
import ReactDOM from 'react-dom'
import c from 'classnames'

import styles from './ActionHeader.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import {
	LIBRARY,
	MY_FILES,
	STARRED,
	BUTTON_TEXT,
	SUCCESS,
	CONTENT_PANE,
	PRESENTATION_BAR,
	ICON_LEFT,
	ADMIN,
	ERROR,
	PDF,
	KEY
} from '../../../../utils/consts'
import {
	createSlideShow,
	clearSlideShow
} from '../../../../store/actions/presentation'
import Search from '../../../Search'
import AddLinkModal from '../../../AddLinkModal'
import ConfirmDeleteModal from '../../../common/ConfirmDeleteModal'
import {
	deleteMyFiles,
	sendSharedInvitation,
	patchFilesOrSlides,
	removeSharedCategory,
	createPdfThumbnail
} from '../../../../api/requests/content'
import {
	clearTempPresentation,
	setTempValue
} from '../../../../store/actions/temp'
import {
	setMyFilesContent,
	getAllMyFilesCategories,
	modifyMyFileCategory,
	removeCategroyById
} from '../../../../store/actions/myfiles'
import MoveMyFileModal from '../../../common/MoveFileModal'
import Toggle from '../../../common/Toggle'
import {
	getAllUserGroups,
	getUsersByGroupId
} from '../../../../store/actions/users'
import AutoSuggest from '../../../common/AutoSuggest'
import autoSuggestStyles from './AutoSuggesStyle.module.scss'
import RenameFileModal from '../../../common/RenameFileModal'
import Input from '../../../common/Input'
import { convertBusinessRules } from '../../../../utils/convertBusinessRules'
import getEmbedUrl from '../../../../utils/helpers/getEmbedUrl'
import { isEditableSlide } from '../../../../utils/helpers/isEditableSlide'
import {
	setFolderPath,
	setSelectedCategory
} from '../../../../store/actions/buildContext'

const ActionHeader = ({
	goToFolder,
	handleNavigation,
	selectedTag,
	role,
	convertToSlides,
	splitToSlides,
	folderHasFiles,
	selectAll,
	selectedEmbeddedTag,
	isSelectedAll,
	isLoadingThumbnail
}) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const categoryNameRef = useRef(null)

	const [showSearch, toggleShowSearch] = useState(false)
	const [showAddLinkModal, setAddLinkModal] = useState(false)
	const [disabledButton, setDisabledButton] = useState(false)
	const [showDeleteMyFileModal, setShowDeleteMyFileModal] = useState(false)
	const [isMoveModalOpen, toggleMoveModal] = useState(false)
	const [showShareFolderModal, setShowShareFolderModal] = useState(false)
	const [disableShareButton, setDisableShareButton] = useState(false)
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedUserGroups, setSelectedUserGroups] = useState([])
	const [showRenameFileModal, setShowRenameFileModal] = useState(false)
	const [selectedObjectToRename, setSelectedObjectToRename] =
		useState(undefined)
	const [newFilename, setNewFilename] = useState('')
	const [isEditing, toggleEditing] = useState(false)
	const [isConfirmModalOpen, toggleConfirmModalOpen] = useState(false)
	const [newCategoryName, setNewCategoryName] = useState('')

	const tempPresentationSlides = useSelector((store) => store.temp.slides)
	const userGroups = useSelector((store) => store.users.userGroups)
	const userList = useSelector((store) => store.users.users)
	const buildContext = useSelector((store) => store.buildContext)
	const { selectedCategory } = buildContext
	const userRole = useSelector((store) => store.authUser.user.user.role)
	const myFiles = useSelector((store) => store.myfiles.myFiles)
	const authToken = useSelector((store) => store.authUser.user.token)
	const refreshToken = useSelector((store) => store.authUser.user.refresh_token)
	const tagTree = useSelector((store) => store.tags.tagTree)
	const { content, starredContent } = useSelector((store) => store.content)
	const { myFilesHomepageVisible, sidebarNavsDisabled } = useSelector(
		(store) => store.temp
	)

	useEffect(() => {
		if (showShareFolderModal) {
			dispatch(getAllUserGroups(authToken))
			dispatch(getUsersByGroupId())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showShareFolderModal])

	/**
	 *
	 * @description put the selected files form the fileBrowser view into the slideshow reducer
	 * and navigates to slideshow
	 */
	function handlePlaySelected() {
		const selectedSlides =
			tempPresentationSlides[PRESENTATION_BAR.toLowerCase()]
		dispatch(clearSlideShow())
		dispatch(
			createSlideShow({
				slides: selectedSlides,
				startFrom: 0
			})
		)
	}

	/**
	 *
	 * @description put the "content" files form the selected library folder into the slideshow reducer
	 * and navigates to slideshow
	 */
	function handlePlayAll() {
		dispatch(clearSlideShow())
		if (role === LIBRARY) {
			dispatch(
				createSlideShow({
					slides: content.filter((item) => item.visibility),
					startFrom: 0
				})
			)
		}
		if (role === MY_FILES) {
			dispatch(
				createSlideShow({
					slides: [...myFiles],
					startFrom: 0
				})
			)
		}
		if (role === STARRED) {
			dispatch(
				createSlideShow({
					slides: [...starredContent],
					startFrom: 0
				})
			)
		}
	}

	/**
	 *
	 * @description show title
	 * @returns {string} the string representation of the title
	 */
	function getTitle() {
		if (role === LIBRARY) {
			if (selectedTag.name) {
				return selectedTag.name
			}
		}
		if (role === MY_FILES) {
			if (selectedTag.name) {
				return selectedTag.name
			}
			return t('labels.myfiles')
		}
		if (role === STARRED) {
			return t('labels.starred')
		}
		return null
	}

	/**
	 *
	 * @description deletes the selected files
	 * calls the related DELETE api requests with an aray of the selected Array<FileId>
	 * sets the new content in the filebrowser, without the recently deleted files
	 */
	const deleteFile = () => {
		setDisabledButton(true)
		const files = tempPresentationSlides[PRESENTATION_BAR.toLowerCase()]
		deleteMyFiles(authToken, files)
			.then(() => {
				const fileIds = new Set(files.map((item) => item.id))
				const newMyFiles = myFiles.filter((item) => !fileIds.has(item.id))
				dispatch(clearTempPresentation(PRESENTATION_BAR.toLowerCase()))
				dispatch(setMyFilesContent(newMyFiles))
				setDisabledButton(false)
				setShowDeleteMyFileModal(false)
				toast(t('notifications.success.files-deleted'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: SUCCESS.toLowerCase(),
					autoClose: 5000
				})
			})
			.catch((error) => {
				setDisabledButton(false)
				console.error('Error while deleting my files', error)
			})
	}

	/**
	 *
	 * @description check if button "Convert to Slides" should be shown or not.
	 * Button should be shown only there is one selected file which is pdf, ppt or pptx
	 * @returns {boolean} button should be shown or not
	 */
	function showConvertToSlidesButton() {
		if (tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length === 1) {
			return (
				tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].findIndex(
					(item) =>
						item.type.toUpperCase() === PDF || item.type.toUpperCase() === KEY
				) > -1
			)
		}
		return false
	}

	const showSplitToSlidesButton = () => {
		if (tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length === 1) {
			return (
				isEditableSlide(
					tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].type
				) &&
				tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].splitted !== 1
			)
		}
		return false
	}

	/**
	 *
	 * @description check if button "Create Thumbnail" should be shown or not.
	 * Button should be shown only there is one selected file which is pdf
	 * @returns {boolean} button should be shown or not
	 */
	const showCreatePdfThumbnailButton = () => {
		if (tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length === 1) {
			return (
				tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].findIndex(
					(item) => item.type.toUpperCase() === PDF
				) > -1
			)
		}
		return false
	}

	const createThumbnail = async () => {
		const file = tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0]
		if (file) {
			isLoadingThumbnail(file.id)
			await createPdfThumbnail(file.checksum)
				.then(() => {
					const newMyFiles = myFiles.map((item) =>
						item.id === file.id ? { ...file, thumbnail: file.checksum } : item
					)
					dispatch(setMyFilesContent(newMyFiles))
					isLoadingThumbnail(null)
					toast(t('notifications.success.thumbnail-created'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: SUCCESS.toLowerCase(),
						autoClose: 5000
					})
				})
				.catch((error) => {
					console.error(error)
					isLoadingThumbnail(null)
					toast(t('notifications.error.thumbnail-creation-failed'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: ERROR.toLowerCase(),
						autoClose: 5000
					})
				})
			isLoadingThumbnail(null)
			dispatch(clearTempPresentation(PRESENTATION_BAR.toLowerCase()))
		}
	}

	/**
	 * @description add user to state if it's not already added
	 * @param {object} user object
	 */
	function handleUserSelection(user) {
		if (!selectedUsers.includes(user)) {
			setSelectedUsers([...selectedUsers, user])
		}
	}

	/**
	 * @description remove user from state
	 * @param {object} user object
	 */
	function removeSelectedUser(user) {
		const newSelectedUsers = [...selectedUsers].filter(
			(item) => item.id !== user.id
		)
		setSelectedUsers(newSelectedUsers)
	}

	/**
	 * @description handle user group selection
	 * @param {object} userGroup object
	 */
	function handleUserGroupSelection(userGroup) {
		if (selectedUserGroups.includes(userGroup)) {
			const newSelectedUserGroups = selectedUserGroups.filter(
				(item) => item.id !== userGroup.id
			)
			setSelectedUserGroups(newSelectedUserGroups)
		} else {
			setSelectedUserGroups([...selectedUserGroups, userGroup])
		}
	}

	/**
	 * @description send share folder invitation
	 */
	const shareFolder = () => {
		const userIds = selectedUsers.map((item) => item.id)
		const userGroupIds = selectedUserGroups.map((item) => item.id)
		if (userIds.length > 0 || userGroupIds.length > 0) {
			setDisableShareButton(true)
			sendSharedInvitation(selectedCategory.id, userIds, userGroupIds)
				.then(() => {
					dispatch(getAllMyFilesCategories())
					setShowShareFolderModal(false)
					setDisableShareButton(false)
					setSelectedUsers([])
					setSelectedUserGroups([])
					toast(t('notifications.success.folder-shared'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: SUCCESS.toLowerCase(),
						autoClose: 5000
					})
				})
				.catch(() => {
					setDisableShareButton(false)
					toast(t('notifications.error.default-error'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: ERROR.toLowerCase(),
						autoClose: 5000
					})
				})
		}
	}

	/**
	 *
	 * @description fired when delete button is clicked to trigger modal
	 * and set selected file to local state
	 */
	const fileRenameInitialAction = () => {
		setShowRenameFileModal(true)
		setSelectedObjectToRename(
			tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0]
		)
		setNewFilename(
			tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].name
		)
	}

	/**
	 *
	 * @description triggers the file changes
	 */
	const handleFileRename = () => {
		patchFilesOrSlides({
			id: selectedObjectToRename.id,
			category_id: selectedObjectToRename.category_id,
			checksum: selectedObjectToRename.checksum,
			children: selectedObjectToRename.children,
			content: selectedObjectToRename.content,
			created_at: selectedObjectToRename.created_at,
			creator: selectedObjectToRename.creator,
			description: selectedObjectToRename.description,
			files_id: selectedObjectToRename.files_id,
			key: selectedObjectToRename.key,
			modifier: selectedObjectToRename.modifier,
			name: newFilename,
			page_order: selectedObjectToRename.page_order,
			pagenumber: selectedObjectToRename.pagenumber,
			parent_file: selectedObjectToRename.parent_file,
			size: selectedObjectToRename.size,
			slug: selectedObjectToRename.slug,
			sortorder: selectedObjectToRename.sortorder,
			starred: selectedObjectToRename.starred,
			status: selectedObjectToRename.status,
			tags: selectedObjectToRename.tags,
			tags_id: selectedObjectToRename.tags_id,
			thumbnail: selectedObjectToRename.thumbnail,
			title: selectedObjectToRename.title,
			type: selectedObjectToRename.type,
			updated_at: selectedObjectToRename.updated_at,
			_file: selectedObjectToRename._file,
			_thumbnail: selectedObjectToRename._thumbnail,
			_type: selectedObjectToRename._type,
			version: selectedObjectToRename.version,
			external_id: selectedObjectToRename.external_id,
			has_thumbnail: selectedObjectToRename.has_thumbnail,
			converted: selectedObjectToRename.converted
		})
			.then((result) => {
				const newMyFiles = myFiles.map((file) =>
					file.id === result.id ? { ...file, name: result.name } : file
				)
				dispatch(setMyFilesContent(newMyFiles))
				setSelectedObjectToRename(undefined)
				setNewFilename('')
				dispatch(clearTempPresentation(PRESENTATION_BAR.toLowerCase()))
			})
			.catch((error) => {
				setDisabledButton(false)
				console.error('Error while deleting my files', error)
			})
		setShowRenameFileModal(false)
	}

	/**
	 *
	 * @description finds the tag in tagTree - helper function
	 * @param {object} data usually 'tagTree' value
	 * @param {number} id id which should be found
	 */
	const findById = (data, id) =>
		objectScan(['**(^children$).id'], {
			abort: true,
			rtn: 'parent',
			useArraySelector: false,
			filterFn: ({ value }) => value === id
		})(data)

	/**
	 *
	 * @description finds the tag in tagTree by 'name' and sends it to the
	 * FileBrowser for further navigating actions
	 * @param {number} id id
	 */
	const selectedBreadcrumbHandler = (id) => {
		const selectedTag = findById(tagTree, id)
		dispatch(clearTempPresentation(PRESENTATION_BAR))
		try {
			goToFolder(selectedTag)
		} catch (e) {
			console.error(e)
		}
	}

	/**
	 *
	 * @description function triggered by clicking on arrow next to the
	 * current level tag in breadcrumbs, used to take the user back to the
	 * previous level
	 */
	const goToPreviousLevelFolder = () => {
		const previousLevelTag = findById(tagTree, selectedTag.parent)
		try {
			if (previousLevelTag) {
				goToFolder(previousLevelTag)
			} else {
				navigate(`/${LIBRARY.toLowerCase()}`)
				dispatch(setTempValue('libraryHomepageVisible', true))
			}
		} catch (e) {
			console.error(e)
		}
	}

	const previousLevelTagName = () => {
		const previousLevelTag = findById(tagTree, selectedTag.parent)
		try {
			if (previousLevelTag) {
				return previousLevelTag.name
			} else {
				return t('labels.library')
			}
		} catch (e) {
			console.error(e)
		}
	}

	/**
	 *
	 * @description gets embedded url from helper function and sends it as a prop
	 * to parent component
	 * @param {object} businessRules object representation of the selected tag
	 */
	const embeddedTagHandler = (businessRules) => {
		const newTag = {
			id: selectedTag.id,
			name: selectedTag.name,
			embeddedUrl: getEmbedUrl(
				location,
				businessRules,
				refreshToken,
				authToken
			),
			target: businessRules.target || false
		}
		selectedEmbeddedTag(newTag)
	}

	/**
	 *
	 * @description renders the open folder button if it has embeddedUrl attached
	 */
	const renderOpenFolderButton = () => {
		const businessRules = convertBusinessRules(selectedTag.business_rules)
		if (businessRules.embeddedUrl) {
			return (
				<div>
					<Button
						label={t('buttons.open-folder-link')}
						onClick={() => embeddedTagHandler(businessRules)}
						containerClass={styles.openFolderButtonContainer}
						type={BUTTON_TEXT}
						buttonClass={styles.addLinkbutton}
						icon={<OpenFolderIcon />}
						id="action-header-open-folder-button"
					/>
				</div>
			)
		}
		return null
	}

	/**
	 * @description show message after deleting folder and get all folders
	 */
	const onCategoryDeleted = () => {
		dispatch(getAllMyFilesCategories()).then(() => {
			toast(t('notifications.success.folder-deleted'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
			toggleConfirmModalOpen(false)
			handleNavigation(MY_FILES)
		})
	}

	/**
	 *
	 * @description removes a category from my files categories
	 */
	const handleCategoryDelete = () => {
		if (selectedTag.code === 'system_shared') {
			removeSharedCategory(selectedTag.id).then(() => {
				onCategoryDeleted()
			})
		} else {
			removeCategroyById(selectedTag.id, authToken).then(() => {
				onCategoryDeleted()
			})
		}
	}

	useEffect(() => {
		const categoryRefForCleanup = categoryNameRef.current
		if (isEditing) {
			categoryNameRef.current.focus()
		}
		return () => categoryRefForCleanup
	}, [isEditing])

	useEffect(() => {
		setNewCategoryName(buildContext.selectedCategory.categoryName)
	}, [buildContext.selectedCategory.categoryName])

	/**
	 *
	 * @description renames a myfile category with the new given name
	 * then dispatches my files categories again to have the updates ready
	 * @param {Event} e onSubmit event
	 */
	const handleCategoryNameChange = (e) => {
		if (e) {
			e.stopPropagation()
			e.preventDefault()
		}
		modifyMyFileCategory(
			{
				id: selectedTag.id,
				name: newCategoryName
			},
			authToken
		).then(() => {
			toast(t('notifications.success.myfiles-category-renamed'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
			dispatch(getAllMyFilesCategories(authToken))
			toggleEditing(false)
		})
	}

	const getPlayAllButtonId = (isPlaySelected) => {
		switch (true) {
			case isPlaySelected && role === LIBRARY:
				return 'play_selected_library'
			case isPlaySelected && role === MY_FILES:
				return 'play_selected_my_files'
			case isPlaySelected && role === STARRED:
				return 'play_selected_starred'
			case !isPlaySelected && role === LIBRARY:
				return 'play_all_library'
			case !isPlaySelected && role === MY_FILES:
				return 'play_all_my_files'
			case !isPlaySelected && role === STARRED:
				return 'play_all_starred'
			default:
				return ''
		}
	}

	return (
		<>
			<div
				className={c(
					styles.actionHeaderRootWrapper,
					[STARRED].includes(role) && styles.morePadding
				)}
			>
				<div className={styles.metaContainer}>
					{role === LIBRARY && (
						<div className={styles.libraryBreadcrumbs}>
							<div>
								<div
									title={t('tooltips.show_hide_folder_structure')}
									className={styles.navToggleIcon}
									onClick={() =>
										dispatch(
											setTempValue('sidebarNavsDisabled', !sidebarNavsDisabled)
										)
									}
									id="action-header-navigation-toggle-button-library"
								>
									<NavigationToggleIcon />
								</div>
								<Button
									containerClass={styles.featuredBtnWrapper}
									buttonClass={styles.featuredBtnText}
									type={BUTTON_TEXT}
									iconSide={ICON_LEFT}
									icon={<InfoIcon />}
									onClick={() => null}
									id="action-header-library-featured-guide-button"
									tooltip={t('tooltips.quick_guide_for', [t('labels.library')])}
								/>
							</div>
							<div>
								<div className={styles.breadcrumbs}>
									<div
										className={styles.item}
										onClick={() => {
											dispatch(clearTempPresentation(PRESENTATION_BAR))
											dispatch(
												setSelectedCategory({
													id: null,
													categoryName: ''
												})
											)
											dispatch(setFolderPath([]))
											dispatch(setTempValue('libraryHomepageVisible', true))
											navigate(`/${LIBRARY.toLowerCase()}`)
										}}
										id="action-header-breadcrumbs-library-homepage-button"
									>
										{t('titles.library')}
									</div>
									<div className={styles.separator}>/</div>
									{buildContext.folderPath.length > 0 &&
										buildContext.folderPath.map((path, index) => {
											if (buildContext.folderPath.length > index + 1)
												return (
													<React.Fragment>
														<div
															className={styles.item}
															onClick={() => selectedBreadcrumbHandler(path.id)}
															id="action-header-breadcrumbs-library-folder-button"
														>
															{path.name}
														</div>
														<div className={styles.separator}>/</div>
													</React.Fragment>
												)
											return null
										})}
								</div>
								<Button
									label={`${t('labels.back_to')} ${previousLevelTagName()}`}
									onClick={goToPreviousLevelFolder}
									iconSide={ICON_LEFT}
									buttonClass={styles.backButton}
									iconClass={styles.iconClass}
									icon={<BackIcon />}
									type={BUTTON_TEXT}
									id="action-header-back-button"
								/>
							</div>
						</div>
					)}
					{role === MY_FILES && (
						<div className={styles.actionBtnContainer}>
							<div>
								<div
									title={t('tooltips.show_hide_folder_structure')}
									className={styles.navToggleIcon}
									onClick={() =>
										dispatch(
											setTempValue('sidebarNavsDisabled', !sidebarNavsDisabled)
										)
									}
									id="action-header-navigation-toggle-button-my-files"
								>
									<NavigationToggleIcon />
								</div>
								<Button
									containerClass={styles.featuredBtnWrapper}
									buttonClass={styles.featuredBtnText}
									type={BUTTON_TEXT}
									iconSide={ICON_LEFT}
									icon={<InfoIcon />}
									onClick={() => null}
									id="action-header-myfiles-featured-guide-button"
									tooltip={t('tooltips.quick_guide_for', [t('labels.myfiles')])}
								/>
							</div>
							{myFilesHomepageVisible ? (
								<div>
									<FolderIcon /> {t('misc.my-files')}
								</div>
							) : (
								<Button
									label={t('labels.back_to_my_files')}
									onClick={() => {
										dispatch(
											setSelectedCategory({
												id: null,
												categoryName: ''
											})
										)
										dispatch(setTempValue('myFilesHomepageVisible', true))
									}}
									iconSide={ICON_LEFT}
									buttonClass={styles.buttonClass}
									iconClass={styles.iconClass}
									icon={<BackIcon />}
									type={BUTTON_TEXT}
									id="action-header-back-to-my-files-button"
								/>
							)}
						</div>
					)}
				</div>
				{(role === LIBRARY || role === MY_FILES || role === STARRED) && (
					<div className={styles.actionBtnContainer}>
						<Button
							label={
								isSelectedAll
									? t('labels.deselect_all')
									: t('labels.select-all')
							}
							type={BUTTON_TEXT}
							icon={<SelectAllIcon />}
							iconSide={ICON_LEFT}
							buttonClass={styles.buttonClass}
							iconClass={styles.iconClass}
							onClick={selectAll}
							isDisabled={!folderHasFiles}
							id="action-header-select-all-button"
						/>
						{role === LIBRARY && (
							<Button
								label={t('labels.search')}
								onClick={() => toggleShowSearch(true)}
								iconSide={ICON_LEFT}
								buttonClass={styles.buttonClass}
								iconClass={styles.iconClass}
								icon={<SearchIcon />}
								type={BUTTON_TEXT}
								id="action-header-search-button"
							/>
						)}
						{tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length >
						0 ? (
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.present_selected')}
								buttonClass={styles.buttonClass}
								iconClass={styles.iconClass}
								onClick={() => handlePlaySelected()}
								id={getPlayAllButtonId(true)}
								iconSide={ICON_LEFT}
								icon={<PlayAllIcon />}
							/>
						) : (
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.present_all')}
								buttonClass={styles.buttonClass}
								iconClass={styles.iconClass}
								onClick={() => handlePlayAll()}
								id={getPlayAllButtonId(false)}
								iconSide={ICON_LEFT}
								icon={<PlayAllIcon />}
							/>
						)}
						<Search
							show={showSearch}
							handleClose={() => toggleShowSearch(false)}
						/>
					</div>
				)}
			</div>
			{role !== MY_FILES && (
				<div className={styles.subHeader}>
					<div className={styles.folderActions}>
						<div className={styles.title}>
							<FolderIcon />
							<h1>{getTitle()}</h1>
						</div>
					</div>
					<div className={styles.metaContainer}>
						<div className={styles.buttons}>
							{renderOpenFolderButton()}
							<div className={styles.filesCount}>
								<span>
									{`${
										(selectedCategory.hasChildren &&
											selectedCategory.children.length) ||
										0
									} ${t('titles.folders').toLowerCase()}, `}
								</span>
								<span>
									{`${
										role === STARRED
											? starredContent.length
											: selectedCategory.material_number
									} ${t('labels.files')}`}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
			{role === MY_FILES && (
				<div className={styles.subHeader}>
					<div className={styles.folderActions}>
						{isEditing ? (
							<form
								method="post"
								onSubmit={(event) => handleCategoryNameChange(event)}
							>
								<input
									className={styles.categoryNameInput}
									ref={categoryNameRef}
									type="text"
									value={newCategoryName}
									onChange={(event) => setNewCategoryName(event.target.value)}
									onBlur={handleCategoryNameChange}
									id="action-header-edit-folder-input"
								/>
								{ReactDOM.createPortal(
									<div
										className={styles.overlay}
										role="button"
										tabIndex={-1}
										onKeyDown={undefined}
										onClick={() => toggleEditing(false)}
									/>,
									document.body
								)}
							</form>
						) : (
							<div className={styles.title}>
								{selectedCategory.code === 'system_shared' ? (
									<FolderSharedIcon />
								) : (
									<FolderIcon />
								)}
								<h1>{newCategoryName}</h1>
							</div>
						)}
						{(selectedCategory.code === 'user_created' ||
							selectedCategory.code === 'system_shared') && (
							<div className={styles.actions}>
								<div className={styles.buttonWrapper}>
									<Button
										type={BUTTON_TEXT}
										buttonClass={styles.addLinkbutton}
										icon={<EditIcon />}
										onClick={() => toggleEditing(!isEditing)}
										id="action-header-edit-folder-button"
									/>
								</div>
								<div className={styles.buttonWrapper}>
									<Button
										type={BUTTON_TEXT}
										buttonClass={styles.addLinkbutton}
										icon={<DeleteFolderIcon />}
										onClick={() => toggleConfirmModalOpen(true)}
										id="action-header-delete-folder-button"
									/>
								</div>
								<div className={styles.buttonWrapper}>
									<Button
										type={BUTTON_TEXT}
										buttonClass={styles.addLinkbutton}
										icon={<PersonAddIcon />}
										onClick={() => setShowShareFolderModal(true)}
										id="action-header-share-folder-button"
									/>
								</div>
							</div>
						)}
					</div>
					<div className={styles.metaContainer}>
						<div className={styles.buttons}>
							{showCreatePdfThumbnailButton() && (
								<Button
									label={t('buttons.thumbnail')}
									type={BUTTON_TEXT}
									buttonClass={styles.createThumbnailButton}
									icon={<ImageIcon />}
									onClick={() => createThumbnail()}
									id="action-header-create-thumbnail-button"
								/>
							)}
							{showConvertToSlidesButton() && (
								<Button
									label={t('labels.convert-to-slides')}
									type={BUTTON_TEXT}
									buttonClass={styles.convertToSlidesButton}
									icon={<DynamicFeedIcon />}
									onClick={convertToSlides}
									id="action-header-convert-to-slides-button"
								/>
							)}
							{showSplitToSlidesButton() && (
								<Button
									label={t('labels.split_to_slides')}
									type={BUTTON_TEXT}
									buttonClass={styles.convertToSlidesButton}
									icon={<DynamicFeedIcon />}
									onClick={splitToSlides}
									id="action-header-split-to-slides-button"
								/>
							)}
							{tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length >
								0 && (
								<Button
									label={t('labels.move')}
									type={BUTTON_TEXT}
									buttonClass={styles.moveFileButton}
									icon={<MoveIcon />}
									onClick={() => toggleMoveModal(true)}
									id="action-header-move-file-button"
								/>
							)}
							{tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length ===
								1 && (
								<Button
									label={t('labels.rename')}
									type={BUTTON_TEXT}
									buttonClass={styles.renameFileButton}
									icon={<EditIcon />}
									onClick={fileRenameInitialAction}
									id="action-header-rename-file-button"
								/>
							)}
							{tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length !==
								0 && (
								<Button
									label={t('labels.delete')}
									type={BUTTON_TEXT}
									buttonClass={styles.deleteFileButton}
									icon={<DeleteIcon />}
									onClick={() => setShowDeleteMyFileModal(true)}
									id="action-header-delete-file-button"
								/>
							)}
						</div>
					</div>
				</div>
			)}
			<AddLinkModal
				handleClose={() => setAddLinkModal(false)}
				isShowing={showAddLinkModal}
				status={11}
				role={MY_FILES}
			/>
			<MoveMyFileModal
				isShowing={isMoveModalOpen}
				handleClose={() => toggleMoveModal(false)}
			/>
			<ConfirmDeleteModal
				isActive={showDeleteMyFileModal}
				handleClose={() => setShowDeleteMyFileModal(false)}
				title={t('titles.delete-files')}
				onApprove={deleteFile}
				disableButton={disabledButton}
			>
				<div className={styles.deleteModalContent}>
					<p>{`${t(
						'misc.are-you-sure-you-want-to-delete'
					)} ${tempPresentationSlides[CONTENT_PANE.toLowerCase()]
						.map((item) => item.name)
						.join(', ')}?`}</p>
				</div>
			</ConfirmDeleteModal>
			<ConfirmDeleteModal
				isActive={showShareFolderModal}
				handleClose={() => setShowShareFolderModal(false)}
				title={t('titles.share_folder')}
				onApprove={shareFolder}
				disableButton={disableShareButton}
				confirmLabel={t('labels.share')}
			>
				<div className={styles.shareCategoryModalContent}>
					<p className={styles.text}>{t('misc.share-folder-text')}</p>
					{userRole === ADMIN && (
						<React.Fragment>
							<p className={styles.text}>{t('misc.share-with-groups')}:</p>
							<div className={styles.userGroupsContainer}>
								{userGroups.map((item, index) => (
									<div key={index.toString()} className={styles.userGroupItem}>
										<p className={styles.label}>{item.name}</p>
										<Toggle
											currentState={selectedUserGroups.includes(item)}
											onChange={() => handleUserGroupSelection(item)}
											states={{
												off: t('misc.off'),
												on: t('misc.on')
											}}
											wrapperClassname={styles.toggleContainer}
										/>
									</div>
								))}
							</div>
						</React.Fragment>
					)}
					<p className={styles.text}>{t('misc.share-with-users')}</p>
					<div className={styles.userSelectionContainer}>
						<AutoSuggest
							theme={autoSuggestStyles}
							suggestionList={userList}
							placeHolder={t('labels.type-a-name')}
							getBackSelected={(selected) => handleUserSelection(selected)}
						/>
						<ul className={styles.selectedUsers}>
							{selectedUsers.map((item, index) => (
								<li key={index.toString()}>
									{item.firstname} {item.lastname}
									<Button
										buttonClass={styles.deleteBtn}
										icon={<CloseIcon htmlColor="#ffffff" />}
										onClick={() => removeSelectedUser(item)}
										id="action-header-remove-selected-user-button"
									/>
								</li>
							))}
						</ul>
					</div>
					{selectedCategory.users && selectedCategory.users.length > 0 && (
						<React.Fragment>
							<p className={styles.text}>{t('misc.shared-with')}:</p>
							<p className={styles.text}>
								{selectedCategory.users.length > 0 &&
									selectedCategory.users.map((user, index) => (
										<span className={styles.text} key={index}>
											{`${user.name}${
												index < selectedCategory.users.length - 1 ? ', ' : ''
											}`}
										</span>
									))}
							</p>
						</React.Fragment>
					)}
				</div>
			</ConfirmDeleteModal>
			<RenameFileModal
				isActive={showRenameFileModal}
				title={t('titles.rename-file')}
				handleClose={() => setShowRenameFileModal(false)}
				confirmLabel={t('buttons.done')}
				onApprove={handleFileRename}
			>
				<Input
					placeholderText={t('labels.title')}
					name="title"
					initialValue={newFilename}
					inputClassName={styles.input}
					wrapperClassName={styles.inputWrapper}
					onChange={(event) => setNewFilename(event.target.value)}
				/>
			</RenameFileModal>
			<ConfirmDeleteModal
				isActive={isConfirmModalOpen}
				handleClose={() => toggleConfirmModalOpen(false)}
				onApprove={() => handleCategoryDelete()}
				title={
					selectedTag.code === 'system_shared'
						? t('titles.delete_shared_folder')
						: t('titles.are-you-sure')
				}
				confirmLabel={t('labels.delete')}
			>
				<div className={styles.confirmDelete}>
					{selectedTag.code === 'system_shared' ? (
						<p>{t('misc.delete_shared_folder_content')}</p>
					) : (
						<p>
							{t('titles.confirm-folder-delete')}: {newCategoryName}?
						</p>
					)}
				</div>
			</ConfirmDeleteModal>
		</>
	)
}

ActionHeader.defaultProps = {
	convertToSlides: null,
	folderHasFiles: false,
	isSelectedAll: false,
	isLoadingThumbnail: null,
	onFileUploaded: null,
	selectedTag: undefined,
	splitToSlides: null
}

ActionHeader.propTypes = {
	goToFolder: PropTypes.func,
	convertToSlides: PropTypes.func,
	folderHasFiles: PropTypes.bool,
	handleNavigation: PropTypes.func,
	isSelectedAll: PropTypes.bool,
	isLoadingThumbnail: PropTypes.func,
	role: PropTypes.string.isRequired,
	selectAll: PropTypes.func.isRequired,
	selectedEmbeddedTag: PropTypes.func,
	selectedTag: PropTypes.objectOf(PropTypes.any),
	splitToSlides: PropTypes.func
}

export default ActionHeader
