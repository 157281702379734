import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import c from 'classnames'

import styles from './CRMDynamicsOneModal.module.scss'

import Result from './Result'
import Modal from '../../../components/common/ModalNew'
import Spinner from '../../../components/common/Spinner'
import { t } from '../../../utils/languages/i18n'
import Section from '../../../components/common/Section'
import Button from '../../../components/common/Button'
import { FLEX_END } from '../../../utils/consts'
import Input from '../../../components/common/Input'
import {
	searchUser,
	sendCrmDynamicsData
} from '../../../api/requests/crmDynamics'
import { getMemositeLink } from '../../../utils/helpers/memosites'
import { StoreInterface } from '../../../utils/interfaces'

interface CRMDynamicsOneInterface {
	handleClose: () => void
	memosite: any
	saveButtonDisabled: boolean
	zIndex?: number
}

const CRMDynamicsOne = ({
	handleClose,
	memosite,
	saveButtonDisabled,
	zIndex = 9999
}: CRMDynamicsOneInterface) => {
	const [customers, setCustomers] = useState([])
	const [searchQuery, setSearchQuery] = useState('')
	const [opportunities, setOpportunities] = useState<any[]>([])
	const [selectedCustomer, setSelectedCustomer] = useState<any>(null)
	const [selectedOpportunityId, setSelectedOpportunityId] = useState('')
	const [secondStep, setSecondStep] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const instanceName = useSelector(
		(state: StoreInterface) => state.temp.instanceName
	)

	const reset = () => {
		setSearchQuery('')
		setCustomers([])
		setOpportunities([])
		setSelectedCustomer(null)
		setSelectedOpportunityId('')
		setSecondStep(false)
	}

	const close = () => {
		reset()
		handleClose()
	}

	const handleSearch = () => {
		setIsLoading(true)
		searchUser(searchQuery)
			.then((result) => {
				setCustomers(result)
				setIsLoading(false)
			})
			.catch()
	}

	const handleSend = () => {
		setIsLoading(true)
		const memoUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			authUser.user.role,
			true
		)
		const data = {
			content: `<h4>${
				memosite.subject
			} memosite was created in Salesframe</h4><h4>By user ${
				authUser.user.firstname
			} ${
				authUser.user.lastname
			}</h4><h4>${new Date().toDateString()}</h4><br /><a href='${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false'>${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false</a>`,
			meeting_id: memosite.id,
			opportunity: selectedOpportunityId,
			subject: 'Salesframe presentation created',
			user_id: authUser.user.id
		}
		sendCrmDynamicsData(data)
			.then(() => {
				reset()
				close()
				toast(t('notifications.success.crm-data-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
				setIsLoading(false)
			})
			.catch(() => {
				toast(t('notifications.error.crm-data-not-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: 5000
				})
				setIsLoading(false)
			})
	}

	const selectedCustomerHandler = (data: any) => {
		if (secondStep) {
			setSelectedOpportunityId(data.opportunityid)
		} else {
			setSelectedCustomer(data)
			setOpportunities(data.opportunity_parent_account)
		}
	}

	const renderSecondStep = () => {
		return (
			<div>
				{isLoading ? <Spinner isLoading size={44} /> : null}
				<div className={c(opportunities && styles.notesContainer)}>
					{opportunities?.length > 0 ? (
						opportunities.map((item, index) => (
							<Result
								data={item}
								key={index}
								selectedCustomerName={selectedCustomer?.name}
								selectedData={selectedCustomerHandler}
							/>
						))
					) : (
						<p className={styles.noResults}>{t('misc.no-results')}</p>
					)}
				</div>
			</div>
		)
	}

	/**
	 * @description get CRM fields depending on CRM id
	 * @returns {JSX.Element} return CRM input fields
	 */
	const renderFirstStep = () => {
		return (
			<div>
				<div className={styles.crmContainer}>
					<Input
						onChange={(event) => setSearchQuery(event.target.value)}
						placeholderText={t('input-placeholders.account-name')}
						name="accountName"
						initialValue={searchQuery}
						inputClassName={styles.input}
					/>
					<Button
						onClick={handleSearch}
						isPositive
						label={t('labels.search')}
						buttonClass={styles.btn}
						isDisabled={saveButtonDisabled}
						id="crm-dynamics-one-search"
					/>
				</div>
				{isLoading ? <Spinner isLoading size={44} /> : null}
				<div className={c(customers && styles.notesContainer)}>
					{customers?.length > 0 ? (
						customers.map((item, index) => (
							<Result
								data={item}
								key={index}
								selectedData={selectedCustomerHandler}
							/>
						))
					) : (
						<p className={styles.noResults}>{t('misc.no-results')}</p>
					)}
				</div>
			</div>
		)
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={close}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>
					{t('labels.search-and-select-opportunity')}
				</p>
			</div>
			{secondStep ? renderSecondStep() : renderFirstStep()}
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px 30px"
			>
				{secondStep ? (
					<Button
						onClick={() => setSecondStep(false)}
						label={t('buttons.back')}
						buttonClass={styles.btn}
						id="crm-dynamics-one-back"
					/>
				) : (
					<Button
						onClick={close}
						label={t('buttons.cancel')}
						buttonClass={styles.btn}
						id="crm-dynamics-one-cancel"
					/>
				)}
				{secondStep ? (
					<Button
						onClick={handleSend}
						isPositive
						label={t('labels.send')}
						buttonClass={styles.btn}
						isDisabled={saveButtonDisabled}
						id="crm-dynamics-one-send"
					/>
				) : (
					<Button
						onClick={() => setSecondStep(true)}
						isPositive
						label={t('buttons.continue')}
						buttonClass={styles.btn}
						isDisabled={!selectedCustomer}
						id="crm-dynamics-one-continue"
					/>
				)}
			</Section>
		</Modal>
	)
}

export default CRMDynamicsOne
