import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import styles from './StoryBoardTagTree.module.scss'
import { Tag, Wrapper, List } from './components'
import Gallery from './components/Gallery/inidex'
import { usePositionReorder } from '../../../../utils/hooks/usePositionReorder'
import { isShrinked } from '../../../../utils/helpers/shrinkPrevious'
import { ListElement } from './components/List'
import { fetchContentByTagId } from '../../../../api/requests/tags'
import { createSlideShow } from '../../../../store/actions/presentation'

const StoryBoardTagTree = ({
	tags,
	selectedMenus,
	handleTagClick,
	noHiddenTags,
	isDraggingEnabled,
	containerRef
}) => {
	const dispatch = useDispatch()

	const [subTags, setSubTags] = useState(selectedMenus)
	const [hiddenLevel, setHiddenLevel] = useState(-1)
	const [contentFetched, setFetchedContent] = useState(false)

	const layoutSettings = useSelector((state) => state.layout)
	const authToken = useSelector((store) => store.authUser.user.token)
	const isShrinkOn = layoutSettings.misc.shrinkPreviousLevel

	const isActive = (tagId) =>
		selectedMenus.some((menu) => menu.tag_id === tagId)

	const { tagGalleryOptions } = useSelector((state) => state.storyboardContext)
	const isFirstLevelLandingPage = layoutSettings.misc.landingPage
	const landingPageNavigationStyle =
		layoutSettings.misc.landingPageStyle.type.toLowerCase()
	const rootTags = tags

	const [updatePosition, updateOrder] = usePositionReorder(rootTags)

	const landingPageClasses = () =>
		isFirstLevelLandingPage && selectedMenus.length === 0
			? [styles.landingPage, styles[landingPageNavigationStyle]]
			: []

	const navigationClasses = () => [
		styles[layoutSettings.misc.navigationStyle.type.toLowerCase()]
	]

	useEffect(() => {
		if (selectedMenus.length > 0) {
			selectedMenus.slice().forEach((item, index) => {
				if (item.business_rules) {
					const br = JSON.parse(item.business_rules)
					if (br.hidePreviousLevels) {
						setHiddenLevel(index)
					}
				}
			})
		} else {
			setHiddenLevel(-1)
		}
		setSubTags(selectedMenus)
		return () => false
	}, [selectedMenus])

	useEffect(() => {
		if (selectedMenus.length > 0 && !contentFetched) {
			setFetchedContent(true)
			const queryString = window.location
			const query = new URLSearchParams(queryString?.search)
			const analyticsParam = query.get('autoplay')
			const lastTagId = selectedMenus[selectedMenus.length - 1].tag_id
			if (analyticsParam === 'true' && lastTagId !== 0) {
				fetchContentByTagId(lastTagId, authToken).then((result) => {
					const onlyNotHidden = result.filter((file) => file.visibility === 1)

					dispatch(
						createSlideShow({
							slides: onlyNotHidden,
							startFrom: 0
						})
					)
				})
			}
		}
	}, [selectedMenus, contentFetched])

	return (
		<Wrapper
			minus={!isDraggingEnabled ? 70 : 140}
			ref={containerRef}
			className={classnames(
				styles.wrapper,
				...landingPageClasses(),
				...navigationClasses()
			)}
		>
			<ListElement // TODO: this UL needs the font-family styled prop
				key={0}
				fontFamily={layoutSettings.storyBoard.storyBoardFont.family}
				className={classnames(
					styles.oneLevel,
					...landingPageClasses(),
					isShrinked(0, isShrinkOn, selectedMenus) ? styles.shrinkedLevel : '',
					hiddenLevel > -1 ? styles.hidden : ''
				)}
			>
				{!tagGalleryOptions.useGallery &&
					rootTags.map((rootTag, index) => (
						<Tag
							isDraggingEnabled={isDraggingEnabled}
							updateOrder={updateOrder}
							updatePosition={updatePosition}
							index={index}
							tag={rootTag}
							handleTagClick={handleTagClick}
							key={rootTag.id}
							isShrinked={isShrinked(0, isShrinkOn, selectedMenus)}
							isActive={isActive(rootTag.tag_id)}
							noHiddenTag={noHiddenTags}
							onSortEnd={() => {}}
							selectedMenus={selectedMenus}
						/>
					))}
			</ListElement>
			<AnimatePresence>
				{subTags.map(
					(level, index) =>
						level.hasChildren &&
						!tagGalleryOptions.useGallery && (
							<List
								key={`level-${(index + 1).toString()}`}
								depth={index + 1}
								classNames={classnames(
									styles.oneLevel,
									hiddenLevel > index ? styles.hidden : '',
									isShrinked(index + 1, isShrinkOn, selectedMenus)
										? styles.shrinkedLevel
										: ''
								)}
								variants={{
									exit: { opacity: 0, transition: { staggerChildren: 0.5 } },
									enter: { transition: { staggerChildren: 0.1 } }
								}}
								initial="initial"
								animate="enter"
								exit="exit"
								isDraggingEnabled={isDraggingEnabled}
								tags={[...level.children]}
								noHiddenTag={noHiddenTags}
								handleTagClick={handleTagClick}
								selectedMenus={selectedMenus}
							/>
						)
				)}
			</AnimatePresence>
			{tagGalleryOptions.useGallery &&
				selectedMenus.map(
					(level, index) =>
						level.hasChildren &&
						index === selectedMenus.length - 1 && (
							<Gallery
								key={`level-${index + 1}`}
								handleTagClick={handleTagClick}
								tags={[...level.children]}
								isVisible={tagGalleryOptions.useGallery}
								depth={index + 1}
								noHiddenTags={noHiddenTags}
							/>
						)
				)}
		</Wrapper>
	)
}

StoryBoardTagTree.defaultProps = {
	containerRef: null,
	hidePrevious: {
		level: -1,
		bool: false
	},
	isDraggingEnabled: false,
	noHiddenTags: false
}

StoryBoardTagTree.propTypes = {
	containerRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	handleTagClick: PropTypes.func.isRequired,
	hidePrevious: PropTypes.shape({
		bool: PropTypes.bool,
		level: PropTypes.number
	}),
	isDraggingEnabled: PropTypes.bool,
	noHiddenTags: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	selectedMenus: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
		.isRequired,
	tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
}

export default StoryBoardTagTree
