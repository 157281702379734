import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './styles/main.scss'
import styles from './Notification.module.scss'

const Notification = () => (
	<>
		<ToastContainer
			hideProgressBar
			pauseOnFocusLoss={false}
			icon={false}
			className={styles.notificationContainer}
		/>
	</>
)

export default Notification
