import {
	RESET_MY_PRESENTATIONS_REDUCER,
	SET_MY_PRESENTATIONS_VALUE
} from './actionTypes'

export const setMyPresentationsValue = (name: any, value: any) => ({
	type: SET_MY_PRESENTATIONS_VALUE,
	payload: {
		name,
		value
	}
})

export const resetMyPresentationsReducer = () => ({
	type: RESET_MY_PRESENTATIONS_REDUCER
})
