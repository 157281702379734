import { isNull } from 'lodash'

import { cloudFnGet } from '../../utils/requests'
import { SET_USER_GROUPS, SET_USERS } from './actionTypes'
import { setInstances } from './authUser'
import {
	checkUserEndpoint,
	userGroupsEndpoint,
	usersEndpoint
} from '../../api/apiEndpoints_new'
import { store } from '../configureStore'

export const setUserGroup = (userGroups: any) => (dispatch: any) => {
	dispatch({
		type: SET_USER_GROUPS,
		payload: {
			userGroups
		}
	})
}

export const setUsersByGroup =
	(users: any, sortUsers?: boolean) => (dispatch: any) => {
		if (sortUsers) {
			const authUser = store.getState().authUser
			const me: any[] = []
			const others: any[] = []
			users.forEach((user: any) => {
				if (user.id === authUser.user.user.id) {
					me.push(user)
				} else {
					others.push(user)
				}
			})
			users = [...me, ...others]
		}
		dispatch({
			type: SET_USERS,
			payload: {
				users: users.filter(
					(user: any) => !isNull(user.firstname) && !isNull(user.lastname)
				)
			}
		})
	}

export const getAllUserGroups = (authToken: any) => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(
			userGroupsEndpoint,
			{ parent_id: 1 },
			{ withToken: true, authToken }
		)
			.then((result) => {
				dispatch(setUserGroup(result))
				resolve({
					success: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getUsersByGroupId = (sortUsers?: boolean) => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(usersEndpoint, { status: '!3' }, null)
			.then((result) => {
				dispatch(setUsersByGroup(result, sortUsers))
				resolve({
					success: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getInstances =
	(userEmail: any, captchaToken: any) => (dispatch: any) =>
		new Promise((resolve, reject) => {
			cloudFnGet(`${checkUserEndpoint}/${userEmail}/${captchaToken}`)
				.then((customer: any) => {
					dispatch(setInstances(customer.instances))
					resolve({
						success: true
					})
				})
				.catch((error) => {
					reject(new Error(`There was an error: ${error}`))
				})
		})
