import React, { useState } from 'react'
import classnames from 'classnames'

import styles from './SendMessageModal.module.scss'

import Button from '../../../../components/common/Button'
import { saveFeed } from '../../../../api/requests/feeds'
import { t } from '../../../../utils/languages/i18n'
import Modal from '../../../../components/common/ModalNew'

interface SendMessageModalInterface {
	closeModal: () => void
	isShowing: boolean
	toggleRefreshMessages: () => void
}

const SendMessageModal = ({
	closeModal,
	isShowing,
	toggleRefreshMessages
}: SendMessageModalInterface) => {
	const [message, setMessage] = useState('')
	const [buttonDisabled, setButtonDisabled] = useState(false)

	/**
	 * @description save feed in database
	 */
	const handleSaveFeed = () => {
		if (message) {
			setButtonDisabled(true)
			saveFeed({
				content: message,
				type: 1,
				public: true
			})
				.then(() => {
					setButtonDisabled(false)
					toggleRefreshMessages()
					closeModal()
				})
				.catch(() => {
					setButtonDisabled(false)
				})
		}
	}

	return (
		<Modal
			isShowing={isShowing}
			hide={closeModal}
			customClassName={classnames(styles.sendMessageModal)}
			modalTitle={t('labels.write-message-to-users')}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={closeModal}
						id="send-message-modal-cancel"
					/>
					<Button
						onClick={handleSaveFeed}
						label={t('labels.send-message')}
						isPositive
						isDisabled={buttonDisabled}
						id="send-message-modal-send-message"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<textarea
					name="sendMessageTextarea"
					id="sendMessageTextarea"
					className={classnames(styles.textarea)}
					onChange={(e) => setMessage(e.target.value)}
				/>
			</div>
		</Modal>
	)
}

export default SendMessageModal
