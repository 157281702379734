import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTempValue } from '../../store/actions/temp'
import { StoreInterface } from '../../utils/interfaces'
import { getUser } from '../../api/requests/user'

const AutomatedTasks = () => {
	const dispatch = useDispatch()

	const [checkUserRole, setCheckUserRole] = useState(true)

	const { role, id } = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.user
	)
	const { token } = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user
	)

	const checkUserRoleEveryHour = () => {
		if (checkUserRole) {
			getUser(id, token)
				.then((userResponse) => {
					if (userResponse.role !== role) {
						dispatch(setTempValue('showLogoutAndBackInModal', true))
					}
				})
				.catch(() => setCheckUserRole(false))
		}
	}

	/**
	 * @description in production mode check every hour if user still has the same
	 * role. In local environment, check every 5 minutes.
	 */
	useEffect(() => {
		const interval = setInterval(
			() => {
				checkUserRoleEveryHour()
			},
			process.env.REACT_APP_ENV === 'production' ? 60 * 60 * 1000 : 300000
		)
		return () => clearInterval(interval)
	})

	return <div id="automated-tasks" />
}

export default AutomatedTasks
