import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

import Modal from '../../../../components/common/ModalNew'
import { t } from '../../../../utils/languages/i18n'
import Button from '../../../../components/common/Button'
import { mfaVerify } from '../../../../store/actions/authUser'
import Input from '../../../../components/common/Input'
import styles from './MultiFactorAuthModal.module.scss'

interface mfaVerifyDataObjectInterface {
	GUID: string
	UID: number
	signature: string
	code: string
}

interface MultiFactorAuthModalInterface {
	cancelAction: () => void
	closeModal: () => void
	handleAuth: (res: any) => void
	isShowing: boolean
	mfaVerifyDataObject: mfaVerifyDataObjectInterface
}

const MultiFactorAuthModal = ({
	cancelAction,
	closeModal,
	handleAuth,
	isShowing,
	mfaVerifyDataObject
}: MultiFactorAuthModalInterface) => {
	const navigate = useNavigate()

	const [countdown, setCountdown] = useState(60)
	const [code, setCode] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const mfaAuthHandler = () => {
		try {
			mfaVerify({
				...mfaVerifyDataObject,
				code
			})
				.then((res) => {
					closeModal()
					handleAuth(res)
				})
				.catch(() => {
					setErrorMessage(t('misc.invalid-code'))
				})
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (isShowing && countdown > 0) {
			const timer: any = setInterval(() => setCountdown(countdown - 1), 1000)
			return () => clearInterval(timer)
		}
	}, [isShowing, countdown])

	useEffect(() => {
		if (countdown === 0) {
			navigate('/')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countdown])

	return (
		<Modal
			isShowing={isShowing}
			hide={cancelAction}
			customClassName={classnames(styles.mfaModal)}
			modalTitle={`${t('titles.insert-code-in')} ${countdown} ${t(
				'titles.seconds'
			)}`}
			headerClassname={styles.header}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={() => cancelAction()}
						id="multi-factor-auth-modal-cancel"
					/>
					<Button
						label={t('misc.login')}
						onClick={mfaAuthHandler}
						isPositive
						id="multi-factor-auth-login"
					/>
				</div>
			}
			zIndex={10000}
		>
			<div className={styles.content}>
				<Input
					name="code"
					initialValue={code}
					onChange={(e) => setCode(e.target.value)}
					wrapperClassName={styles.codeWrapper}
				/>
				<div className={styles.errorMsg}>{errorMessage}</div>
			</div>
		</Modal>
	)
}

export default MultiFactorAuthModal
