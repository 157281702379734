export const SET_SELECTED_TAG = 'SET_SELECTED_TAG'
export const SET_TAG_BUSINESS_RULES = 'SET_TAG_BUSINESS_RULES'
export const SET_TAG_GALLERY_OPTIONS = 'SET_TAG_GALLERY_OPTIONS'
export const RESET_STORYBOARDCONTEXT = 'RESET_STORYBOARDCONTEXT'
export const SET_CURRENT_BACKGROUND_IMAGE = 'SET_CURRENT_BACKGROUND_IMAGE'
export const SET_INITIAL_BACKGROUND_IMAGE = 'SET_INITIAL_BACKGROUND_IMAGE'
export const SET_CURRENT_LOGO_IMAGE = 'SET_CURRENT_LOGO_IMAGE'
export const SET_INITIAL_LOGO_IMAGE = 'SET_INITIAL_LOGO_IMAGE'

export const setSelectedTag = (selectedTag: any) => (dispatch: any) => {
	dispatch({
		type: SET_SELECTED_TAG,
		payload: {
			selectedTag,
		},
	})
}

export const setTagBusinessRules = (tagBusinessRules: any) => (dispatch: any) => {
	dispatch({
		type: SET_TAG_BUSINESS_RULES,
		payload: {
			tagBusinessRules,
		},
	})
}

export const setTagGalleryOptions = (tagGalleryOptions: any) => (dispatch: any) => {
	dispatch({
		type: SET_TAG_GALLERY_OPTIONS,
		payload: {
			tagGalleryOptions,
		},
	})
}

export const resetStoryBoardContext = () => (dispatch: any) => {
	dispatch({
		type: RESET_STORYBOARDCONTEXT,
		payload: null,
	})
}

export const setCurrentBackgroundImage = (backgroundImage: any, level: any = 0) => (dispatch: any) => {
	dispatch({
		type: SET_CURRENT_BACKGROUND_IMAGE,
		payload: {
			backgroundImage,
			level,
		},
	})
}

export const setCurrentLogoImage = (logoImage: any, level: any = 0) => (dispatch: any) => {
	dispatch({
	  type: SET_CURRENT_LOGO_IMAGE,
	  payload: {
			logoImage,
			level,
	  },
	})
}
