import React, { useEffect, useState } from 'react'
import md5 from 'js-md5'
import { useDispatch, useSelector } from 'react-redux'
import { nanoid } from 'nanoid'

import styles from './RiskMeterModal.module.scss'

import Modal from '../../../common/ModalNew'
import Button from '../../../common/Button'
import { t } from '../../../../utils/languages/i18n'
import Input from '../../../common/Input'
import {
	addSurvey,
	getSurveyCompanies,
	updateCustomer
} from '../../../../api/requests/surveys'
import { addToPresentation } from '../../../../store/actions/presentation'
import Dropdown from '../../../common/Dropdown'
import DropdownCreatable from '../../../common/DropdownCreatable'
import { StoreInterface } from '../../../../utils/interfaces'

interface RiskMeterModalInterface {
	handleClose: () => void
	isShowing: boolean
	zIndex?: number
}

const companySizes = [
	'0-4 henkeä',
	'5-9 henkeä',
	'10-19 henkeä',
	'20-49 henkeä',
	'50-99 henkeä',
	'100-249 henkeä',
	'250-499 henkeä',
	'500-999 henkeä',
	'1000- henkeä'
]

const industries = [
	'Maatalous, metsätalous ja kalatalous',
	'Kaivostoiminta ja louhinta',
	'Teollisuus',
	'Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta',
	'Vesihuolto, viemäri- ja jätevesihuolto, jätehuolto ja muu ympäristön puhtaanapito',
	'Rakentaminen',
	'Tukku- ja vähittäiskauppa; moottoriajoneuvojen ja moottoripyörien korjaus',
	'Kuljetus ja varastointi',
	'Majoitus- ja ravitsemistoiminta',
	'Informaatio ja viestintä',
	'Rahoitus- ja vakuutustoiminta',
	'Kiinteistöalan toiminta',
	'Ammatillinen, tieteellinen ja tekninen toiminta',
	'Hallinto- ja tukipalvelutoiminta',
	'Julkinen hallinto ja maanpuolustus; pakollinen sosiaalivakuutus',
	'Koulutus',
	'Terveys- ja sosiaalipalvelut',
	'Taiteet, viihde ja virkistys',
	'Muu palvelutoiminta',
	'Kotitalouksien toiminta työnantajina; kotitalouksien eriyttämätön toiminta tavaroiden ja palvelujen tuottamiseksi omaan käyttöön',
	'Kansainvälisten organisaatioiden ja toimielinten toiminta',
	'Toimiala tuntematon'
]

const RiskMeterModal = ({
	handleClose,
	isShowing,
	zIndex
}: RiskMeterModalInterface) => {
	const dispatch = useDispatch()
	const [existingCustomers, setExistingCustomers] = useState([])
	const [customerName, setCustomerName] = useState<string>('')
	const [customerId, setCustomerId] = useState<string>('')
	const [selectedCompanySize, setSelectedCompanySize] = useState('')
	const [selectedIndustry, setSelectedIndustry] = useState('')
	const [searchApplied, toggleSearchApplied] = useState(false)
	const [newCustomer, toggleNewCustomer] = useState(false)
	const [editCustomerMode, toggleEditCustomerMode] = useState(false)
	const [selectedCustomerId, setSelectedCustomerId] = useState('')
	const [customerBackup, setCustomerBackup] = useState<any>(null)

	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)

	/**
	 * @description fetchSurveyCompanies
	 */
	const fetchSurveyCompanies = () => {
		getSurveyCompanies().then((results: any) => {
			const preparedCustomers = results.map((r: any) => {
				return {
					id: r.company_id,
					title: r.name,
					industry: r.industry,
					companySize: r.companySize,
					existingCustomerId: r.id
				}
			})
			setExistingCustomers(preparedCustomers)
		})
	}

	/**
	 * @description execute fetchSurveyCompanies initially
	 */
	useEffect(() => {
		fetchSurveyCompanies()
	}, [])

	const createLinkHandler = () => {
		const generatedChecksum = md5(`${nanoid()}${customerName}`)
		addSurvey(
			{
				name: customerName,
				checksum: generatedChecksum,
				template_id: 1,
				company_id: customerId,
				companySize: selectedCompanySize || '',
				industry: selectedIndustry || ''
			},
			authToken
		).then((response: any) => {
			dispatch(addToPresentation([response]))
			fetchSurveyCompanies()
			handleCloseModal()
		})
	}

	const handleCloseModal = () => {
		setCustomerName('')
		setCustomerId('')
		setSelectedCompanySize('')
		setSelectedIndustry('')
		setSelectedCustomerId('')
		toggleSearchApplied(false)
		toggleNewCustomer(false)
		toggleEditCustomerMode(false)
		setCustomerBackup(null)
		handleClose()
	}

	const enterEditingModeHandler = () => {
		setCustomerBackup({
			selectedCustomerId,
			customerName,
			customerId,
			selectedCompanySize,
			selectedIndustry
		})
		toggleEditCustomerMode(true)
	}

	const editingModeCanceledHandler = () => {
		setCustomerName(customerBackup.customerName)
		setCustomerId(customerBackup.customerId)
		setSelectedCompanySize(customerBackup.selectedCompanySize)
		setSelectedIndustry(customerBackup.selectedIndustry)
		setSelectedCustomerId(customerBackup.selectedCustomerId)
		toggleEditCustomerMode(false)
	}

	const updateCustomerHandler = () => {
		const customerObjectUpdated = {
			id: selectedCustomerId.toString(),
			name: customerName,
			company_id: customerId,
			companySize: selectedCompanySize || '',
			industry: selectedIndustry || ''
		}
		updateCustomer(customerObjectUpdated, authToken)
			.then(() => {
				toggleEditCustomerMode(false)
			})
			.catch((e) => console.error(e))
	}

	return (
		<Modal
			isShowing={isShowing}
			hide={handleCloseModal}
			modalTitle={
				editCustomerMode
					? t('buttons.edit-customer')
					: t('titles.create-a-risk-meter')
			}
			customClassName={styles.riskMeterModal}
			footer={
				<>
					<Button
						label={editCustomerMode ? t('buttons.cancel') : t('buttons.close')}
						onClick={
							editCustomerMode ? editingModeCanceledHandler : handleCloseModal
						}
						id="risk-metter-modal-cancel"
					/>
					<Button
						label={
							editCustomerMode
								? t('buttons.update-customer')
								: t('buttons.create-link')
						}
						isPositive
						onClick={
							editCustomerMode ? updateCustomerHandler : createLinkHandler
						}
						id="risk-meter-modal-create-link"
					/>
				</>
			}
			zIndex={zIndex}
		>
			<div className={styles.content}>
				{!editCustomerMode && (
					<div className={styles.dropdownWrapper}>
						<div className={styles.labelDropdown}>
							{t('labels.find-or-create')}
						</div>
						<DropdownCreatable
							listItems={existingCustomers.map(
								({ id, title, industry, companySize, existingCustomerId }) => ({
									value: title,
									key: id,
									label: `${title} (${id})`,
									id,
									industry,
									companySize,
									existingCustomerId
								})
							)}
							placement="bottom"
							defaultIndex={null}
							placeholder={t(
								'input-placeholders.write-the-customer-name-or-id'
							)}
							onChangeCallback={(selected) => {
								toggleNewCustomer(selected?.__isNew__)
								toggleSearchApplied(true)
								setCustomerName(selected.value)
								setCustomerId(selected.id)
								setSelectedIndustry(selected.industry)
								setSelectedCompanySize(selected.companySize)
								setSelectedCustomerId(selected.existingCustomerId)
							}}
						/>
					</div>
				)}
				<div className={styles.dropdownWrapper}>
					<div className={styles.label}>{t('labels.customer-name')}</div>
					<div className={styles.inputWIthButton}>
						<Input
							placeholderText={t('input-placeholders.write-the-customer-name')}
							onChange={(event) => setCustomerName(event.target.value)}
							name="customerName"
							inputClassName={styles.input}
							initialValue={customerName}
							isDisabled={!newCustomer && !editCustomerMode}
						/>
						{searchApplied && !newCustomer && !editCustomerMode && (
							<Button
								label={t('buttons.edit-customer')}
								isPositive
								onClick={enterEditingModeHandler}
								id="risk-meter-modal-edit-customer"
							/>
						)}
					</div>
				</div>
				<div className={styles.dropdownWrapper}>
					<div className={styles.label}>{t('labels.customer-id')}</div>
					<Input
						placeholderText={t('input-placeholders.write-the-customer-id')}
						onChange={(event) => setCustomerId(event.target.value)}
						name="customerId"
						inputClassName={styles.input}
						initialValue={customerId}
						isDisabled={!newCustomer && !editCustomerMode}
					/>
				</div>
				<div>
					<div className={styles.dropdownWrapper}>
						<div className={styles.labelDropdown}>
							{t('labels.customer-industry')}
						</div>
						<Dropdown
							listItems={industries.map((item) => ({
								value: item,
								key: item,
								label: item,
								id: item
							}))}
							placement="top"
							defaultIndex={null}
							placeholder={t('input-placeholders.select-the-customer-industry')}
							onChangeCallback={(selected) => {
								setSelectedIndustry(selected.id)
							}}
							value={
								selectedIndustry
									? {
											id: selectedIndustry,
											label: selectedIndustry
									  }
									: null
							}
							isDisabled={!newCustomer && !editCustomerMode}
						/>
					</div>
				</div>
				<div>
					<div className={styles.dropdownWrapper}>
						<div className={styles.labelDropdown}>
							{t('labels.customer-size')}
						</div>
						<Dropdown
							listItems={companySizes.map((item) => ({
								value: item,
								key: item,
								label: item,
								id: item
							}))}
							placement="top"
							defaultIndex={null}
							placeholder={t('input-placeholders.select-the-customer-size')}
							onChangeCallback={(selected) => {
								setSelectedCompanySize(selected.id)
							}}
							value={
								selectedCompanySize
									? {
											id: selectedCompanySize,
											label: selectedCompanySize
									  }
									: null
							}
							isDisabled={!newCustomer && !editCustomerMode}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default RiskMeterModal
