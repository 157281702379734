import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'

import Tag from '../Tag'
import { usePositionReorder } from '../../../../../../utils/hooks/usePositionReorder'
import { isShrinked } from '../../../../../../utils/helpers/shrinkPrevious'

export const ListElement = styled(motion.ul)`
	display: block;
	list-style: none;
	margin-right: 20px;
	padding-top: ${(props) => (props.isShrinked ? '10px' : 0)};
	font-family: '${(props) => props.fontFamily}';
`

const List = ({
	tags,
	handleTagClick,
	noHiddenTag,
	depth,
	isDraggingEnabled,
	selectedMenus,
	classNames
}) => {
	const [order, updatePosition, updateOrder] = usePositionReorder(tags)
	const isActive = (tagId) =>
		selectedMenus.some((menu) => menu.tag_id === tagId)
	const layoutSettings = useSelector((state) => state.layout)
	const isShrinkOn = layoutSettings.misc.shrinkPreviousLevel

	/**
	 *
	 * @description calls the reorder API with the new tag order
	 */
	function handleTagReorder() {
		// const reorderObject = {
		// 	parent: order[order.length - 1].parent,
		// 	level: depth + 1,
		// 	items: order.map((tag) => tag.id)
		// }
	}

	return tags && tags.length > 0 ? (
		<ListElement
			key={depth}
			className={classNames}
			fontFamily={layoutSettings.storyBoard.storyBoardFont.family}
		>
			{order.map((currentTag, index) => (
				<Tag
					depth={depth}
					isDraggingEnabled={isDraggingEnabled}
					onSortEnd={() => handleTagReorder()}
					index={index}
					tag={currentTag}
					handleTagClick={handleTagClick}
					updatePosition={updatePosition}
					updateOrder={updateOrder}
					key={currentTag.id}
					isActive={isActive(currentTag.tag_id)}
					noHiddenTag={noHiddenTag}
					isShrinked={isShrinked(depth, isShrinkOn, selectedMenus)}
				/>
			))}
		</ListElement>
	) : (
		ListElement
	)
}

List.defaultProps = {
	classNames: undefined,
	depth: undefined,
	handleTagClick: undefined,
	isDraggingEnabled: false,
	noHiddenTag: false,
	selectedMenus: undefined,
	tags: undefined
}

List.propTypes = {
	classNames: PropTypes.string,
	depth: PropTypes.number,
	handleTagClick: PropTypes.func,
	isDraggingEnabled: PropTypes.bool,
	noHiddenTag: PropTypes.bool,
	selectedMenus: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
}

export default List
