import { uniqBy } from 'lodash'

import { SET_CONTENT_FOR_TAG, CLEAR_CONTENT, SET_STARRED_CONTENT } from '../actions/actionTypes'

const initialState = {
	content: [],
	starredContent: [],
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
	case SET_CONTENT_FOR_TAG:
		return {
			...state,
			content: action.payload.content,
		}
	case CLEAR_CONTENT:
		return {
			content: initialState.content,
		}
	case SET_STARRED_CONTENT:
		return {
			...state,
			starredContent: uniqBy(action.payload.starredContent, 'id'),
		}
	default:
		return state
	}
}

export default reducer
