import React, { useState } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import { motion } from 'framer-motion'
import { differenceInHours } from 'date-fns'
import { DeleteOutline, InfoOutlined } from '@mui/icons-material'

import styles from './Card.module.scss'

import { MODAL, PRESENTATION_BAR, SLIDE } from '../../utils/consts'
import Thumbnail from './components/Thumbnail'
import ToolBar from './components/ToolBar'
import Loading from './components/Loading'
import FlagContentModal from '../FlagContentModal'
import InfoModal from './components/InfoModal'
import {
	decideThumbnail,
	isRestristed
} from '../../utils/helpers/decideThumbanil'
import { t } from '../../utils/languages/i18n'
import AutomatedDeletionModal from './components/AutomatedDeletionModal'
import { resetContentDeletion } from '../../api/requests/content'
import { setMyFilesContent } from '../../store/actions/myfiles'
import { store as reducerStore } from '../../store/configureStore'
import { clearTempPresentation } from '../../store/actions/temp'
import { StoreInterface } from '../../utils/interfaces'
import isIOS from '../../utils/helpers/isIOS'

const MetaContainer = styled.div<{
	hasLayoutFontColor: boolean
	color: string
}>`
	color: ${(props) => (props.hasLayoutFontColor ? props.color : '#ffffff')};
`

interface CardComponentInterface {
	cardObject: any
	className?: string
	draggingFiles?: number
	handleCardClick: (event: React.MouseEvent) => void
	hasLayoutFontColor: boolean
	hasToolbar: boolean
	isConverting?: boolean
	isLoadingCard?: boolean
	isSelected?: boolean
	isToolbarVisible?: boolean
	openFileEditor: (cardObject: any) => void
	isSplitting?: boolean
	isEditable: boolean
	role?: string
}

const Card = ({
	cardObject,
	handleCardClick,
	isSelected = false,
	isToolbarVisible = false,
	isLoadingCard,
	hasToolbar = true,
	className,
	hasLayoutFontColor,
	isConverting,
	openFileEditor,
	isSplitting,
	isEditable,
	draggingFiles,
	role
}: CardComponentInterface) => {
	const [isHovered, setToolBarVisible] = useState(false)
	const [isFlaggingModalOpen, toggleFlaggingModal] = useState(false)
	const [isInfoVisible, toggleInfoVisible] = useState(false)
	const [automatedDeletionModalVisible, toggleAutomatedDeletionModalVisible] =
		useState(false)
	const layoutSettings = useSelector((store: StoreInterface) => store.layout)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)

	const hoursToDeletion = cardObject.for_delete
		? differenceInHours(Date.parse(cardObject.for_delete), new Date())
		: null

	const cardVariants = {
		visible: {
			opacity: 1
		},
		hidden: {
			opacity: 0
		}
	}

	/**
	 *
	 * @description this is a HOC, so the pressed button identifier is being sent back to it's parent
	 * wher ethe click was performed for further actions
	 * @param {string} action the CONST identifier of the pressed toolbar button
	 */
	const handleToolBarAction = (action: string) => {
		if (action === MODAL) {
			toggleFlaggingModal(true)
		}
	}

	/**
	 *
	 * @description stops click even propogation
	 * and closes the info modal
	 */
	const handleInfoClose = () => {
		toggleInfoVisible((currState) => !currState)
	}

	/**
	 *
	 * @description checks if the file is "Slide"
	 * retruns Slide in that case
	 * the extension otherwise
	 * @returns {string} formatted extension
	 */
	const getExtension = () => {
		let ext
		if (cardObject._type === SLIDE) {
			ext = 'Slide'
		} else {
			ext = cardObject.extension
		}
		return ext
	}

	const openAutomatedDeletionModalHandler = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
		toggleAutomatedDeletionModalVisible(!automatedDeletionModalVisible)
	}

	/**
	 * @description manage card content
	 * @returns {JSX.Element} card content
	 */
	const getCardContent = () => {
		if (cardObject.deleted) {
			return (
				<div className={styles.thumbnailContainer}>
					<div className={styles.deleted}>
						<DeleteOutline />
					</div>
				</div>
			)
		}
		if (isConverting) {
			return (
				<div className={styles.thumbnailContainer}>
					<div className={styles.converter}>
						<Loading width="40px" height="40px" />
						<p>{t('misc.converting')}</p>
					</div>
				</div>
			)
		}
		if (isLoadingCard) {
			return (
				<div className={styles.thumbnailContainer}>
					<Loading width="100%" height="100%" />
				</div>
			)
		}
		if (isSplitting) {
			return (
				<div className={styles.thumbnailContainer}>
					<div className={styles.converter}>
						<Loading width="40px" height="40px" />
						<p>{t('misc.splitting_file')}</p>
					</div>
				</div>
			)
		}
		return (
			<>
				<Thumbnail
					className={styles.thumbnailContainer}
					thumbnailUrl={decideThumbnail(cardObject, authToken)}
					extension={getExtension()}
					isExpired={cardObject.isExpired}
					isHovered={isHovered}
					isNew={cardObject.isNew}
					isRestricted={isRestristed(cardObject.business_rules)}
					openAutomatedDeletionModal={(e) =>
						openAutomatedDeletionModalHandler(e)
					}
					time={cardObject.time || false}
					hoursToDeletion={hoursToDeletion || undefined}
					isEditable={isEditable}
					draggingFiles={draggingFiles}
					role={role}
				/>
				<MetaContainer
					hasLayoutFontColor={hasLayoutFontColor}
					className={styles.metaContainer}
					color={hexToRgba(
						layoutSettings.presentation.presentationDeckFontColor,
						1
					)}
				>
					{cardObject.name && (
						<p className={styles.fileTitle} title={cardObject.name}>
							{cardObject.name}
						</p>
					)}
					<div
						className={styles.infoIcon}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							toggleInfoVisible(true)
						}}
						title={t('tooltips.file-info')}
						id="card-file-info-button"
					>
						<InfoOutlined
							style={{
								color: hasLayoutFontColor
									? hexToRgba(
											layoutSettings.presentation.presentationDeckFontColor,
											1
									  )
									: '#ffffff'
							}}
						/>
					</div>
				</MetaContainer>
			</>
		)
	}

	const resetContentDeletionHandler = () => {
		resetContentDeletion({ id: cardObject.id, type: cardObject._type })
			.then((resp: any) => {
				const myFiles = reducerStore.getState().myfiles.myFiles
				if (Array.isArray(myFiles)) {
					const updatedMyFiles = myFiles.map((file) =>
						file.id === cardObject.id
							? { ...file, for_delete: resp.for_delete }
							: file
					)
					setMyFilesContent(updatedMyFiles)
					clearTempPresentation(PRESENTATION_BAR.toLowerCase())
				}
				toggleAutomatedDeletionModalVisible(false)
			})
			.catch((e) => {
				console.error(e)
				toggleAutomatedDeletionModalVisible(false)
			})
	}

	return (
		<div
			className={classnames(styles.cardRootWrapper)}
			{...(!isIOS()
				? {
						onMouseEnter: () => setToolBarVisible(true),
						onMouseLeave: () => setToolBarVisible(false)
				  }
				: {})}
		>
			{!cardObject.deleted && (
				<ToolBar
					isVisible={
						isToolbarVisible ||
						(hasToolbar && !isLoadingCard && isHovered && !isInfoVisible)
					}
					cardObject={cardObject}
					action={(action) => handleToolBarAction(action)}
					openFileEditor={openFileEditor}
					isEditable={isEditable}
				/>
			)}
			<InfoModal
				isVisible={isInfoVisible}
				cardObject={cardObject}
				closeModal={handleInfoClose}
				thumbnailUrl={decideThumbnail(cardObject, authToken)}
			/>
			<motion.div
				className={classnames(
					styles.cardRootContainer,
					isSelected ? styles.selected : '',
					isIOS() ? styles.ipadRootContainer : '',
					className
				)}
				role="button"
				tabIndex={-1}
				onClick={
					cardObject.deleted || !handleCardClick
						? () => null
						: (event) => handleCardClick(event)
				}
				initial={false}
				variants={cardVariants}
			>
				{getCardContent()}
			</motion.div>
			{hasToolbar && (
				<FlagContentModal
					isActive={isFlaggingModalOpen}
					handleClose={() => toggleFlaggingModal(false)}
					cardObject={cardObject}
					zIndex={10015}
				/>
			)}
			{automatedDeletionModalVisible && (
				<AutomatedDeletionModal
					action={resetContentDeletionHandler}
					closeModal={() => toggleAutomatedDeletionModalVisible(false)}
					hoursToDeletion={hoursToDeletion}
					isVisible={automatedDeletionModalVisible}
				/>
			)}
		</div>
	)
}

export default React.memo(Card)
