import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import classsnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import styled from 'styled-components'
import { isNull } from 'lodash'
import validator from 'validator'

import {
	t,
	languages,
	setLanguage,
	getLanguage
} from '../../utils/languages/i18n'
import Section from '../../components/common/Section'
import styles from './UserSettings.module.scss'
import {
	FLEX_START,
	FLEX_DIRECTION_COL,
	FLEX_END,
	PASSWORD,
	USER,
	BUTTON_TEXT,
	ICON_LEFT,
	UPLOAD_BUTTON,
	DEFAULT
} from '../../utils/consts'
import Toggle from '../../components/common/Toggle'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import {
	modifyUser,
	toggleMfAuthentication,
	modifyUserPassword,
	deleteProfilePicture,
	checkMfAuthentication
} from '../../api/requests/user'
import Spinner from '../../components/common/Spinner'
import Profile from '../../assets/images/profile.png'
import UploadContent from '../../components/common/UploadContent'
import { uploadToStorage } from '../../api/requests/content'
import { setUser } from '../../store/actions/authUser'
import { saveConfig, getConfig } from '../../api/requests/config'
import Dropdown from '../../components/common/Dropdown'
import { timeZones } from '../../utils/timeZones'
import {
	validatePassword,
	validatePasswordConfirmation
} from '../../utils/helpers/passwordValidationHelper'
import { defaultDropdownStyles } from '../../components/common/Dropdown/utils/styles'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { sfApiRoot } from '../../api/apiEndpoints_new'

const ProfileImage = styled.div<{ background: string }>`
	background-image: url(${(props) => props.background});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-right: 20px;
`

const activeLanguage = () =>
	languages.find((currLang) => currLang.lng === getLanguage())

const dropdownStyles = {
	...defaultDropdownStyles
}

const UserSettingsModal = () => {
	const dispatch = useDispatch()
	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const thisUser = useSelector(
		(store: StoreInterface) => store.authUser.user.user
	)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)

	const [firstName, setFirstName] = useState(thisUser.firstname)
	const [lastName, setLastName] = useState(thisUser.lastname)
	const [email, setEmail] = useState(thisUser.email)
	const [profileUrl, setProfileUrl] = useState(thisUser.profile_url || '')
	const [phoneNumber, setPhoneNumber] = useState(thisUser.phone || '')
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
	const [isMultiAuthEnabled, toggleMultiAuth] = useState(false)
	const [multiFactorQrCode, setMultiFactorQrCode] = useState(undefined)
	const [mfsLoader, toggleMfsLoader] = useState(false)
	const [profilePicture, setProfilePicture] = useState<string>(
		thisUser.profile_picture
	)
	const [emailNotifications, setEmailNotifications] = useState({
		isNew: true,
		value: true
	})
	const [memositeHideTitle, setMemositeHideTitle] = useState({
		isNew: true,
		value: false
	})
	const [passwordValidationError, setPasswordValidationError] = useState('')
	const [verifyPasswordError, setVerifyPasswordError] = useState('')

	const disableButtonCondition =
		currentPassword.length === 0 ||
		(newPassword.length === 0
			? true
			: !validator.isStrongPassword(newPassword)) ||
		newPassword !== newPasswordConfirm

	useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.USER_SETTINGS))
		getConfig(`memosite.opened.${thisUser.id}`).then((response) => {
			if (response) {
				setEmailNotifications({
					isNew: false,
					value: response.value === null ? true : response.value === 'true'
				})
			}
		})
		getConfig(`memo.hide.title.${thisUser.id}`).then((response) => {
			if (response) {
				setMemositeHideTitle({
					isNew: false,
					value: response.value === null ? true : response.value === 'true'
				})
			}
		})
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.Settings`
			})
		)
		checkMfAuthentication(authToken)
			.then((mfaResponse: any) => {
				if (mfaResponse.OTP === 1) {
					toggleMultiAuth(true)
					setMultiFactorQrCode(mfaResponse.QR)
				}
			})
			.catch(() => {
				toggleMfsLoader(true)
			})
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Settings`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 * @description decides which controlled state the input should have
	 * @returns {string} return the controlled state
	 */
	const checkPasswordMatch = (): string => {
		let state = ''
		if (newPassword) {
			if (newPassword !== newPasswordConfirm) {
				state = styles.error
			} else {
				state = styles.success
			}
		}
		return state
	}

	/**
	 * @description useEffect that fires on newPassword value change and checks if
	 * provided value satisfies required password rules
	 */
	useEffect(() => {
		setPasswordValidationError(
			newPassword.length > 0 ? validatePassword(newPassword) : ''
		)
	}, [newPassword])

	/**
	 * @description useEffect that fires on newPassword/newPasswordConfirm value
	 * change and checks if these two values are equal
	 */
	useEffect(() => {
		setVerifyPasswordError(
			newPasswordConfirm.length > 0
				? validatePasswordConfirmation(newPassword, newPasswordConfirm)
				: ''
		)
	}, [newPassword, newPasswordConfirm])

	/**
	 * @description call the user API with the modified user object
	 * @param {string} method the method of the user patch. either PASSWORD or USER
	 * @returns {object} return the new user object
	 */
	const handleUserUpdate = (method: string) => {
		const loggedInUser = thisUser
		let userObject = {}
		if (method === USER) {
			userObject = {
				id: loggedInUser.id,
				firstname: firstName,
				lastname: lastName,
				email,
				phone: phoneNumber.toString(),
				profile_url: profileUrl
			}
			modifyUser(userObject).then((result: any) => {
				dispatch(
					setUser({
						...authUser,
						user: {
							...thisUser,
							...result.user
						}
					})
				)
				toast(t('notifications.success.settings-updated'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
			})
		} else if (method === PASSWORD) {
			userObject = {
				id: loggedInUser.id,
				password: newPassword,
				old_password: currentPassword
			}
			modifyUserPassword(userObject)
				.then((result: any) => {
					dispatch(
						setUser({
							...authUser,
							user: {
								...thisUser,
								...result.user
							}
						})
					)
					toast(t('notifications.success.settings-updated'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: 'success',
						autoClose: 5000
					})
				})
				.catch(() => {
					toast(t('notifications.error.please-check-password'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: 'error',
						autoClose: 5000
					})
				})
		}

		return userObject
	}

	/**
	 *
	 * @description toggling on true|false the multi-factor authentication toggle
	 * and calling the API
	 * and adding the result QR code to the virtual DOM
	 */
	const handleMultiAuth = () => {
		toggleMultiAuth(!isMultiAuthEnabled)
		if (!isMultiAuthEnabled) {
			toggleMfsLoader(true)
			toggleMfAuthentication(
				{
					OTP: 1
				},
				authToken
			).then((result: any) => {
				setMultiFactorQrCode(result.QR)
				toggleMfsLoader(false)
			})
		} else {
			setMultiFactorQrCode(undefined)
			toggleMfAuthentication(
				{
					OTP: 0
				},
				authToken
			)
		}
	}

	/**
	 *
	 * @description uploads the chosen profile picture
	 * call the user modifier API
	 * sets the new values in the store
	 * @param {File} file the chosen profile picture
	 */
	const uploadProfileImage = (file: File) => {
		uploadToStorage(file, authToken).then((response: any) => {
			setProfilePicture(response.hash)
			modifyUser({
				id: thisUser.id,
				profile_picture: response.filename
			}).then((result: any) => {
				dispatch(
					setUser({
						...authUser,
						user: {
							...thisUser,
							...result.user
						}
					})
				)
				setProfilePicture(result.user.profile_picture)
				toast(t('notifications.success.profile-picture-updated'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
			})
		})
	}

	/**
	 *
	 * @description removes the user's profile picture
	 * call the user modifier API
	 * sets the new values in the store
	 */
	const removeProfilePicture = () => {
		deleteProfilePicture({
			id: thisUser.id
		}).then((result: any) => {
			setProfilePicture('')
			dispatch(
				setUser({
					...authUser,
					user: {
						...thisUser,
						...result.user
					}
				})
			)
			toast(t('notifications.success.profile-picture-removed'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: 'success',
				autoClose: 5000
			})
		})
	}

	/**
	 * @description save user settings and message notification in config
	 */
	const saveChanges = () => {
		handleUserUpdate(USER)
		saveConfig(
			`memosite.opened.${thisUser.id}`,
			emailNotifications.value.toString(),
			emailNotifications.isNew
		)
		saveConfig(
			`memo.hide.title.${thisUser.id}`,
			memositeHideTitle.value.toString(),
			memositeHideTitle.isNew
		)
	}

	const getChosenTimeZone = () => {
		if (
			thisUser.timezone &&
			!isNull(thisUser.timezone) &&
			thisUser.timezone !== ''
		) {
			const userTimeZone = JSON.parse(thisUser.timezone)
			if (userTimeZone) {
				return timeZones.findIndex(
					(timeZone) => timeZone.label === userTimeZone.label
				)
			}
			return null
		}
	}

	/**
	 *
	 * @description saves the selected timezone in the users settings
	 * @param {object} timeZone the object representation of the selected timezone
	 */
	const changeUserTimeZone = (timeZone: any) => {
		modifyUser({
			id: thisUser.id,
			timezone: JSON.stringify(timeZone)
		})
	}

	const changeLanguage = (selectedLanguage: any) => {
		setLanguage(selectedLanguage)
		modifyUser({
			id: thisUser.id,
			language: selectedLanguage
		}).then((result: any) => {
			const { user } = result
			dispatch(
				setUser({
					...authUser,
					user: {
						...thisUser,
						...user
					}
				})
			)
		})
		toast(t('notifications.success.language-settings-updated'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: 'success',
			autoClose: 5000
		})
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.rootContainer}>
				<div className={styles.mainInfo}>
					<div>
						<div className={styles.titleWrapper}>
							<h1>{t('titles.user-settings')}</h1>
							<Button
								containerClass={styles.btnTextWrapper}
								buttonClass={styles.btnText}
								type={BUTTON_TEXT}
								iconSide={ICON_LEFT}
								icon={<InfoIcon />}
								onClick={() => null}
								id="user-settings-featured-guide-button"
								tooltip={t('tooltips.quick_guide_for', [
									t('titles.user-settings')
								])}
							/>
						</div>
						<Section
							sectionClassName={classsnames(
								styles.section,
								styles.languageSelector
							)}
							padding="10px 20px"
							title={t('labels.language')}
							justify={FLEX_START}
							flexDirection={FLEX_DIRECTION_COL}
							alignItems={FLEX_START}
						>
							<Dropdown
								styles={dropdownStyles}
								listItems={languages.map((item) => ({
									// @ts-ignore
									label: item[activeLanguage().lng],
									value: item.lng
								}))}
								onChangeCallback={(activeLang) =>
									changeLanguage(activeLang.value)
								}
								// @ts-ignore
								defaultIndex={activeLanguage().key}
							/>
						</Section>
					</div>
					<div>
						<div className={styles.profileImageContainer}>
							<ProfileImage
								background={
									profilePicture
										? `${sfApiRoot}/files/assets/files/${profilePicture}?oauth=${encodeURIComponent(
												authToken
										  )}`
										: Profile
								}
							/>
							<div className={styles.buttonsContainer}>
								<p className={styles.title}>{t('labels.profile-picture')}</p>
								<UploadContent
									type={UPLOAD_BUTTON}
									buttonClass={styles.button}
									buttonContainerClass={styles.buttonContent}
									icon={
										<span className={styles.icon}>
											<CloudUploadIcon htmlColor="#ffffff" />
										</span>
									}
									buttonnLabel={t('labels.upload-new')}
									handleFileUpload={(file) => uploadProfileImage(file)}
									acceptFiles={['image/jpeg', 'image/png']}
									multiple={false}
								/>
								<Button
									type={BUTTON_TEXT}
									iconSide={ICON_LEFT}
									containerClass={styles.buttonContainer}
									buttonClass={styles.button}
									icon={
										<span className={styles.icon}>
											<DeleteIcon htmlColor="#ffffff" />
										</span>
									}
									label={t('labels.delete')}
									onClick={removeProfilePicture}
									id="user-settings-delete-profile-picture"
								/>
							</div>
						</div>
					</div>
				</div>
				<Section
					title="Time Zone"
					flexDirection={FLEX_DIRECTION_COL}
					justify={FLEX_START}
					padding="10px 20px"
					alignItems={FLEX_START}
					sectionClassName={classsnames(styles.section, styles.col)}
				>
					<div className={styles.timeZoneDropdownContainer}>
						<Dropdown
							styles={dropdownStyles}
							listItems={timeZones}
							onChangeCallback={(selectedTimeZone) =>
								changeUserTimeZone(selectedTimeZone)
							}
							defaultIndex={getChosenTimeZone() || null}
						/>
					</div>
				</Section>
				<Section
					sectionClassName={classsnames(styles.section, styles.col)}
					padding="10px 20px"
					title={t('labels.profile')}
					flexDirection={FLEX_DIRECTION_COL}
					justify={FLEX_START}
					alignItems={FLEX_START}
				>
					<div className={styles.meta}>
						<Input
							onChange={(e) => setFirstName(e.target.value)}
							label={t('labels.firstname')}
							name="fname"
							wrapperClassName={styles.inputWrapper}
							initialValue={firstName}
						/>
						<Input
							onChange={(e) => setLastName(e.target.value)}
							label={t('labels.lastname')}
							name="lname"
							wrapperClassName={styles.inputWrapper}
							initialValue={lastName}
						/>
					</div>
					<div className={styles.meta}>
						<Input
							onChange={(e) => setEmail(e.target.value)}
							label={t('labels.email')}
							isDisabled
							initialValue={email}
							name="email"
							wrapperClassName={styles.inputWrapper}
						/>
						<Input
							onChange={(e) => setPhoneNumber(e.target.value)}
							label={t('labels.phone')}
							name="phone"
							wrapperClassName={styles.inputWrapper}
							initialValue={phoneNumber}
						/>
					</div>
					<div className={styles.meta}>
						<Input
							onChange={(e) => setProfileUrl(e.target.value)}
							label={t('labels.user-profile-url')}
							initialValue={profileUrl}
							name="profile_url"
							wrapperClassName={styles.profileUrlInputWrapper}
						/>
					</div>
				</Section>
				<Section
					sectionClassName={classsnames(
						styles.section,
						styles.userSettingsSection,
						styles.notificationsLabel
					)}
					flexDirection={FLEX_DIRECTION_COL}
					justify={FLEX_START}
					alignItems={FLEX_START}
					padding="10px 20px"
					title={t('labels.notifications')}
					subTitle={t('misc.email-notification-shared-presentation')}
				>
					<Toggle
						wrapperClassname={styles.notificationToggleWrapper}
						states={{
							off: t('misc.off'),
							on: t('misc.on')
						}}
						currentState={emailNotifications.value}
						onChange={() =>
							setEmailNotifications({
								...emailNotifications,
								value: !emailNotifications.value
							})
						}
					/>
				</Section>
				<Section
					sectionClassName={classsnames(
						styles.section,
						styles.userSettingsSection,
						styles.memositesLabel
					)}
					flexDirection={FLEX_DIRECTION_COL}
					justify={FLEX_START}
					alignItems={FLEX_START}
					padding="10px 20px"
					title={t('labels.memosites')}
					subTitle={t('misc.hide-memosite-name-and-time')}
				>
					<Toggle
						wrapperClassname={styles.notificationToggleWrapper}
						states={{
							off: t('misc.off'),
							on: t('misc.on')
						}}
						currentState={memositeHideTitle.value}
						onChange={() =>
							setMemositeHideTitle({
								...memositeHideTitle,
								value: !memositeHideTitle.value
							})
						}
					/>
				</Section>
				<Section
					sectionClassName={classsnames(
						styles.section,
						styles.saveSection,
						thisUser.sso !== '' ? styles.bottomPadding : ''
					)}
					justify={FLEX_END}
				>
					<Button
						label={t('buttons.save-changes')}
						buttonClass={styles.button}
						isPositive
						onClick={saveChanges}
						id="user-settings-save-changes"
					/>
				</Section>
				{thisUser.sso === '' && (
					<React.Fragment>
						<h1>{t('labels.security-settings')}</h1>
						<Section
							sectionClassName={classsnames(styles.section, styles.mfaLabel)}
							flexDirection={FLEX_DIRECTION_COL}
							justify={FLEX_START}
							alignItems={FLEX_START}
							padding="10px 20px"
							title={t('labels.multiauth')}
						>
							<div className={styles.multiInner}>
								<div>
									<p className={styles.explainer}>
										{t('misc.google-authenticator')}
									</p>
									<Toggle
										wrapperClassname={styles.notificationToggleWrapper}
										states={{
											off: t('misc.off'),
											on: t('misc.on')
										}}
										currentState={isMultiAuthEnabled}
										onChange={() => handleMultiAuth()}
									/>
								</div>
								<div>
									{mfsLoader && !multiFactorQrCode && <Spinner />}
									{multiFactorQrCode && (
										<img
											alt="multifactor google code"
											src={multiFactorQrCode}
										/>
									)}
								</div>
							</div>
						</Section>
						<Section
							sectionClassName={classsnames(styles.section, styles.col)}
							padding="10px 20px"
							title={t('labels.resetpassword')}
							flexDirection={FLEX_DIRECTION_COL}
							justify={FLEX_START}
							alignItems={FLEX_START}
						>
							<div className={styles.currentPassword}>
								<Input
									onChange={(e) => setCurrentPassword(e.target.value)}
									label={t('input-labels.current-password')}
									name="currpw"
									wrapperClassName={styles.inputWrapper}
									type={PASSWORD.toLowerCase()}
									state={
										checkPasswordMatch() === 'error'
											? styles.error
											: styles.success
									}
									initialValue={currentPassword}
								/>
							</div>
							<div className={styles.meta}>
								<Input
									onChange={(e) => setNewPassword(e.target.value)}
									label={t('labels.newpassword')}
									name="newpw"
									wrapperClassName={styles.inputWrapper}
									type={PASSWORD.toLowerCase()}
									state={
										styles[
											passwordValidationError ? 'error' : DEFAULT.toLowerCase()
										]
									}
									initialValue={newPassword}
								/>
								<Input
									onChange={(e) => setNewPasswordConfirm(e.target.value)}
									label={t('labels.verify')}
									name="newpwv"
									wrapperClassName={styles.inputWrapper}
									type={PASSWORD.toLowerCase()}
									state={newPasswordConfirm && checkPasswordMatch()}
									initialValue={newPasswordConfirm}
									isDisabled={!validator.isStrongPassword(newPassword)}
								/>
							</div>
							<div className={styles.passValidationMessages}>
								<div>
									<p>{passwordValidationError}</p>
								</div>
								<div>
									<p>{verifyPasswordError}</p>
								</div>
							</div>
						</Section>
						<Section justify={FLEX_END} sectionClassName={styles.section}>
							<div className={styles.footerBtnContainer}>
								<Button
									label={t('buttons.reset-password')}
									onClick={() => handleUserUpdate(PASSWORD)}
									buttonClass={styles.button}
									isPositive
									isDisabled={disableButtonCondition}
									id="user-settings-reset-password"
								/>
							</div>
						</Section>
					</React.Fragment>
				)}
			</div>
		</div>
	)
}

export default UserSettingsModal
