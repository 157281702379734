import React from 'react'
import { useSelector } from 'react-redux'

// @ts-ignore
import styles from './PreloadTagsBackground.module.scss'
import { StoreInterface } from '../../utils/interfaces'
import { buildFileUrl } from '../../utils/helpers/fileUrl'

const PreloadTagsBackground = () => {
	const { backgroundImages } = useSelector((store: StoreInterface) => store.tags)
	const authToken = useSelector((state: StoreInterface) => state.authUser.user.token)

	return (
		<div className={styles.backgroundImages}>
			{
				authToken && backgroundImages && (
					<React.Fragment>
						{
							backgroundImages.map((item, index) => (
								<img
									key={index.toString()}
									alt={`background ${index}`}
									src={buildFileUrl(item, 'original', authToken)}
								/>
							))
						}
					</React.Fragment>
				)
			}
		</div>
	)
}

export default PreloadTagsBackground
