import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import { useSelector, useDispatch } from 'react-redux'
import { forEach } from 'lodash'

import styles from './BigGreenButton.module.scss'

import {
	addToPresentation,
	setLoadedPresentationDirty
} from '../../../../store/actions/presentation'
import { clearTempPresentation } from '../../../../store/actions/temp'
import Button from '../../../common/Button'
import { ICON_LEFT } from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import { StoreInterface } from '../../../../utils/interfaces'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'

const bigGreenButtonVariants = {
	visible: {
		opacity: 1,
		y: 0
	},
	hidden: {
		opacity: 0,
		y: -20
	},
	additionalMarginLg: {
		opacity: 1,
		y: -240
	}
}

interface BigGreenButtonInterface {
	role: string
	zIndex?: number
}

const BigGreenButton = ({ role, zIndex }: BigGreenButtonInterface) => {
	const dispatch = useDispatch()
	const { slides, presentationBarVisible } = useSelector(
		(store: StoreInterface) => store.temp
	)
	const memositeBuilderStatus = useSelector(
		(store: StoreInterface) => store.memositeBuilder.status
	)
	const loadedPresentation = useSelector(
		(store: StoreInterface) => store.presentation
	)

	/**
	 *
	 * @description adds the currently selected items to the presentation
	 * creates a new array with the required analyics event name
	 * adds the array into the analytics batch reducer
	 * cleares up the temp reducer so the big green button will be hidden
	 */
	const handlePresentationAdd = () => {
		dispatch(addToPresentation(slides[role]))
		if (loadedPresentation) {
			dispatch(setLoadedPresentationDirty(true))
		}
		const analyticsBatch = slides[role].map((fileItem: any) => ({
			event: 'presentation.library.fileSelected',
			...fileItem
		}))
		forEach(analyticsBatch, (item) => {
			dispatch(addToAnalyticsBatch(item))
		})

		dispatch(clearTempPresentation(role))
	}

	const isVisible = slides[role].length > 0

	const decideAnimation = () => {
		if (['opened', 'editing'].includes(memositeBuilderStatus)) {
			if (isVisible && role !== 'my_memosites' && !presentationBarVisible) {
				return 'additionalMarginLg'
			} else {
				return 'visible'
			}
		} else {
			if (isVisible) {
				return 'visible'
			} else {
				return 'hidden'
			}
		}
	}

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					key={1}
					layout
					className={styles.bigGreenBtnContainer}
					variants={bigGreenButtonVariants}
					initial="hidden"
					exit="hidden"
					animate={decideAnimation()}
				>
					<Button
						onClick={handlePresentationAdd}
						buttonClass={styles.bigGreenBtn}
						label={`${t('labels.add')} ${slides[role].length} ${
							slides[role].length > 1
								? t('labels.slides').toLowerCase()
								: t('labels.slide').toLowerCase()
						}`}
						iconSide={ICON_LEFT}
						iconClass={styles.addIcon}
						icon={<LibraryAddIcon htmlColor="#ffffff" />}
						isPositive
						id={`big-green-button-${role.toLowerCase()}`}
					/>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default BigGreenButton
