import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import classnames from 'classnames'
import { useStopwatch } from 'react-timer-hook'
import { useSelector } from 'react-redux'

import styles from './VideoRecorder.module.scss'
import Disconnected from './components/Disconnected'
import CountDown from './components/CountDown'
import Modal from '../../../common/ModalNew'
import Button from '../../../common/Button'
import { t } from '../../../../utils/languages/i18n'
import {
	AVAILABLE,
	CLOSED,
	PREVIEW,
	RECORDING,
	CAMERA_ERROR,
	NO_MEDIA_RECORDER
} from '../../../../utils/consts'
import Spinner from '../../../common/Spinner'
import { StoreInterface } from '../../../../utils/interfaces'

interface VideoRecorderInterface {
	isVisible: boolean
	handleClose: () => void
	setVideoBlob: any
	setGreetingVideoLength: any
}

const VideoRecorder = ({
	isVisible,
	handleClose,
	setVideoBlob,
	setGreetingVideoLength
}: VideoRecorderInterface) => {
	const { minutes, seconds, start, reset } = useStopwatch({
		autoStart: false
	})

	const webcamRef = useRef(null)
	const mediaRecorderRef = useRef(null)
	const [webcamStatus, setWebcamStatus] = useState(
		!window.MediaRecorder
			? NO_MEDIA_RECORDER.toLowerCase()
			: CLOSED.toLowerCase()
	)
	const [webcamReady, toggleWebcamReady] = useState(false)
	const [recordedChunks, setRecordedChunks] = useState([])
	const [isCounting, setCounting] = useState(false)
	const [previewBlob, setPreviewBlob] = useState<string | undefined>()
	const [videoFile, setVideoFile] = useState<Blob>()
	const [availableCameras, setAvailableCameras] = useState([])
	const [selectedCameraId, setSelectedCameraId] = useState('')

	const supportedMimeTypeFromStore = useSelector(
		(reduxStore: StoreInterface) => reduxStore.temp.supportedMimeType
	)

	const handleDevices = useCallback(
		(mediaDevices) =>
			setAvailableCameras(
				mediaDevices.filter(({ kind }: any) => kind === 'videoinput')
			),
		[setAvailableCameras]
	)

	useEffect(() => {
		navigator.mediaDevices.enumerateDevices().then(handleDevices)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 *
	 * @description once the countdown is finished, this fn will change
	 * camera status to RECORDING and execute handleStart()
	 */
	const stopCountDown = () => {
		setCounting(false)
		setWebcamStatus(RECORDING.toLowerCase())
		// start a stopwatch
		start()
		handleStart()
	}

	/**
	 *
	 * @description this callback will execute webcamera record start
	 */
	const handleStart = () => {
		// @ts-ignore
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
			mimeType: supportedMimeTypeFromStore
		})
		// @ts-ignore
		mediaRecorderRef.current.addEventListener(
			'dataavailable',
			dataAvailableHandler
		)
		// @ts-ignore
		mediaRecorderRef.current.start()
	}

	/**
	 *
	 * @description this callback will watch for recorder chunks
	 */
	const dataAvailableHandler = useCallback(
		({ data }) => {
			if (data.size > 0) {
				setRecordedChunks((prev) => prev.concat(data))
			}
		},
		[setRecordedChunks]
	)

	/**
	 *
	 * @description this fn will pause recording, change webcamera status to PREVIEW
	 * and set video length values
	 */
	const handleStop = () => {
		setTimeout(() => {
			setWebcamStatus(PREVIEW.toLowerCase())
			// @ts-ignore
			mediaRecorderRef.current.stop()
			setGreetingVideoLength({
				seconds,
				minutes
			})
			// reset a stopwatch
			reset()
		}, 500)
	}

	/**
	 *
	 * @description when recordedChunks is available this fn will prepare
	 * file for preview and video blob file which should be uploaded
	 */
	useEffect(() => {
		if (recordedChunks.length) {
			const blob = new Blob(recordedChunks, {
				type: supportedMimeTypeFromStore
			})
			setPreviewBlob(URL.createObjectURL(blob))
			setVideoFile(blob)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordedChunks])

	/**
	 *
	 * @description this fn will send video blob to parent component, change webcam
	 * status to CLOSED and remove state values
	 */
	const handleSave = () => {
		setVideoBlob(videoFile)
		setWebcamStatus(CLOSED.toLowerCase())
		setPreviewBlob(undefined)
		setRecordedChunks([])
		toggleWebcamReady(false)
		handleClose()
	}

	/**
	 *
	 * @description this fn will reset state and camera values to defaults
	 */
	const handleReset = () => {
		setWebcamStatus(AVAILABLE.toLowerCase())
		toggleWebcamReady(false)
		setVideoBlob(undefined)
		setPreviewBlob(undefined)
		setRecordedChunks([])
		reset()
	}

	/**
	 *
	 * @description this fn will close the modal and reset state and camera values
	 * to defaults
	 */
	const handleCloseModal = () => {
		setWebcamStatus(CLOSED.toLowerCase())
		setVideoBlob(undefined)
		setPreviewBlob(undefined)
		toggleWebcamReady(false)
		setRecordedChunks([])
		reset()
		handleClose()
	}

	return (
		<Modal
			isShowing={isVisible}
			hide={() => handleCloseModal()}
			customClassName={styles.recordVideoModal}
			modalClassName={styles.recordModalWrapper}
			headerClassname={classnames(
				styles.modalHeader,
				webcamStatus === RECORDING.toLowerCase() ? styles.hidden : ''
			)}
		>
			<div className={styles.videoRecorderContainer}>
				{(webcamStatus === CLOSED.toLowerCase() ||
					webcamStatus === CAMERA_ERROR.toLowerCase() ||
					webcamStatus === NO_MEDIA_RECORDER.toLowerCase()) && (
					<React.Fragment>
						<Disconnected cameraStatus={webcamStatus} />
						{webcamStatus === CLOSED.toLowerCase() && (
							<Button
								isPositive
								buttonClass={styles.primaryButton}
								label={t('buttons.video-turn-on-camera')}
								onClick={() => setWebcamStatus(AVAILABLE.toLowerCase())}
								id="video-recorder-turn-on-camera"
							/>
						)}
					</React.Fragment>
				)}
				{webcamStatus === AVAILABLE.toLowerCase() &&
					(isCounting ? (
						<React.Fragment>
							<CountDown onEnd={() => stopCountDown()} />
						</React.Fragment>
					) : webcamReady ? (
						<React.Fragment>
							<div className={styles.startRecordContainer}>
								<p>{t('misc.press-rec')}</p>
								<span
									role="button"
									onKeyDown={() => null}
									tabIndex={-1}
									onClick={() => setCounting(true)}
								/>
								<div className={styles.availableCameras}>
									{availableCameras.length > 1 &&
										availableCameras.map(
											(device: { deviceId: string; label: string }, key) => (
												<Button
													isPositive
													containerClass={styles.cameraButtonContainer}
													buttonClass={styles.cameraButton}
													label={device.label || `Device ${key + 1}`}
													key={device.deviceId}
													onClick={() => {
														setSelectedCameraId(device.deviceId)
													}}
													id="video-recorder-select-camera"
												/>
											)
										)}
								</div>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Spinner isLoading size={44} />
						</React.Fragment>
					))}
				{webcamStatus === RECORDING.toLowerCase() && (
					<React.Fragment>
						<div className={styles.afterVideoControls}>
							<Button
								isPositive
								containerClass={styles.actionBtnContainer}
								buttonClass={styles.primaryButton}
								label={t('buttons.video-stop')}
								onClick={handleStop}
								id="video-recorder-stop-recording"
							/>
						</div>
					</React.Fragment>
				)}
				{(webcamStatus === AVAILABLE.toLowerCase() ||
					webcamStatus === RECORDING.toLowerCase()) && (
					<Webcam
						forceScreenshotSourceSize
						ref={webcamRef}
						audio
						muted
						onUserMedia={() => toggleWebcamReady(true)}
						onUserMediaError={(error) => {
							console.error('camera error:', error)
							setWebcamStatus(CAMERA_ERROR.toLowerCase())
						}}
						videoConstraints={{
							deviceId: selectedCameraId,
							width: { ideal: 1920 },
							height: { ideal: 1080 }
						}}
						screenshotQuality={1}
					/>
				)}
				{webcamStatus === PREVIEW.toLowerCase() && (
					<React.Fragment>
						<video
							className={styles.videoPreview}
							style={{ display: 'block' }}
							src={previewBlob}
							controls
						/>
						<div className={styles.afterVideoControls}>
							<Button
								containerClass={styles.actionBtnContainer}
								buttonClass={styles.primaryButton}
								label={t('buttons.video-use-another')}
								onClick={handleReset}
								id="video-recorder-use-another-video"
							/>
							<Button
								isPositive
								containerClass={styles.actionBtnContainer}
								buttonClass={styles.primaryButton}
								label={t('buttons.video-save')}
								onClick={handleSave}
								id="video-recorder-save"
							/>
						</div>
					</React.Fragment>
				)}
			</div>
		</Modal>
	)
}

export default VideoRecorder
