import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FolderOpenedIcon from '@mui/icons-material/FolderOpen'
import FolderClosedIcon from '@mui/icons-material/FolderOutlined'
import FolderSharedIcon from '@mui/icons-material/FolderSharedOutlined'

import styles from './Category.module.scss'

import Button from '../Button'
import Popover from '../Popover'
import { BUTTON_TEXT } from '../../../utils/consts'
import { t } from '../../../utils/languages/i18n'

const Category = ({
	categoryName,
	isSelected,
	selectCategory,
	code,
	users
}) => {
	const [popoverOpen, setPopoverOpen] = useState(false)

	return (
		<>
			<div
				className={classnames(
					styles.oneCategory,
					isSelected && styles.active,
					code !== 'user_created' &&
						code !== 'system_shared' &&
						styles.defaultCategory
				)}
			>
				<div className={classnames(styles.icon, isSelected && styles.active)}>
					{code === 'system_shared' ? (
						<Popover
							isOpen={popoverOpen}
							handleClose={() => setPopoverOpen(!popoverOpen)}
							content={
								<div>
									<p>{t('misc.shared-with')}</p>
									<p>
										{t('misc.users')}:{' '}
										{users.map((item) => item.name).join(', ')}
									</p>
								</div>
							}
							position="right"
							zIndex="10001"
						>
							<div
								onClick={() => setPopoverOpen(!popoverOpen)}
								onKeyDown={null}
								role="button"
								tabIndex={0}
							>
								<div title={t('tooltips.click_to_see_users')}>
									<FolderSharedIcon />
								</div>
							</div>
						</Popover>
					) : isSelected ? (
						<FolderOpenedIcon />
					) : (
						<FolderClosedIcon />
					)}
				</div>
				<div className={classnames(styles.categoryContainer)}>
					<Button
						label={categoryName}
						containerClass={styles.categoryBtnContainer}
						buttonClass={classnames(
							styles.categoryBtn,
							isSelected && styles.selected
						)}
						onClick={selectCategory}
						type={BUTTON_TEXT}
						id="category-category-name-button"
					/>
				</div>
			</div>
		</>
	)
}

Category.defaultProps = {
	code: '',
	isSelected: false,
	users: []
}

Category.propTypes = {
	categoryName: PropTypes.string.isRequired,
	code: PropTypes.string,
	isSelected: PropTypes.bool,
	selectCategory: PropTypes.func.isRequired,
	users: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string
		})
	)
}

export default Category
