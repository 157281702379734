import React from 'react'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
	VisibilityOutlined,
	EditOutlined,
	SaveAlt,
	StarOutline,
	Star,
	OpenInNew,
	Flag,
	FlagOutlined
} from '@mui/icons-material'
import { AnimatePresence, motion } from 'framer-motion'

import styles from './ToolBar.module.scss'

import Button from '../../../common/Button'
import {
	SM,
	STARRED,
	MODAL,
	DOWNLOAD,
	BUTTON_TEXT,
	EXTERNAL_FILE_TYPES
} from '../../../../utils/consts'
import { starContent } from '../../../../api/requests/content'
import { t } from '../../../../utils/languages/i18n'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import { getAllStarredContent } from '../../../../store/actions/content'
import { setTempValue } from '../../../../store/actions/temp'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'
import { StoreInterface } from '../../../../utils/interfaces'

const toolBarVariantsSm = {
	hidden: {
		opacity: 0,
		y: 20,
		scale: 0.8
	},
	visible: {
		opacity: 1,
		y: 13,
		scale: 0.8
	}
}

const toolBarVariantsLg = {
	hidden: {
		opacity: 0,
		y: 20,
		scale: 1
	},
	visible: {
		opacity: 1,
		y: 13,
		scale: 1
	}
}

interface ToolBarInterface {
	action: (action: string) => void
	cardObject: any
	isVisible: boolean
	openFileEditor: (cardObject: any) => void
	isEditable: boolean
	isCardList?: boolean
}

const ToolBar = ({
	cardObject,
	action,
	isVisible,
	isCardList = false,
	openFileEditor,
	isEditable
}: ToolBarInterface) => {
	const dispatch = useDispatch()
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const allStarredContent = useSelector(
		(store: StoreInterface) => store.content.starredContent
	)

	const isThisStarred = allStarredContent.some(
		(starredContent) => starredContent.id === cardObject.id
	)

	const { thumbnailSize } = useSelector((store: StoreInterface) => store.misc)

	/**
	 *
	 * @description stars the content
	 * re-fetches all the starred content to
	 * keep the starring consistent
	 * toggles a notification regarding the action
	 */
	const starAContent = () => {
		const starringObject = {
			type: cardObject._type,
			material_id: cardObject.id,
			status: cardObject.status
		}

		starContent(starringObject, authToken).then(() => {
			dispatch(getAllStarredContent(authToken))
			toast(
				cardObject.starred
					? t('notifications.success.unstarred-content')
					: t('notifications.success.starred-content'),
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				}
			)

			const file = cardObject
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.material.file.starred',
					checksum: file.checksum,
					id: file.id,
					name: file.name,
					origin: file._type,
					parent_file: file.parent_file,
					parent_file_name: file.parent_file_name,
					status: file.status,
					tag_id: file.tags_id,
					type: file.type
				})
			)
		})
	}

	/**
	 *
	 * @description downloads the file
	 */
	const downloadAContent = () => {
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.material.download',
				origin: cardObject._type,
				parent_file_name: cardObject._file ? cardObject._file.name : '',
				...cardObject
			})
		)
		if (EXTERNAL_FILE_TYPES.includes(cardObject.type.toUpperCase())) {
			window.open(cardObject.title)
		} else {
			let thisFileUrl = `${sfApiRoot}/files/assets/files/${
				cardObject.checksum
			}/true?oauth=${encodeURIComponent(authToken)}`
			if (cardObject._file) {
				if (cardObject._file.splitted === 1) {
					thisFileUrl = `${sfApiRoot}/files/assets/files/${
						cardObject._file.checksum
					}/true?oauth=${encodeURIComponent(authToken)}`
				}
			}
			window.open(thisFileUrl)
			toast(t('notifications.success.file-download'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: 'success',
				autoClose: 5000
			})
		}
	}

	/**
	 *
	 * @description stop the click to be propagated
	 * and calls the function associated with the button clicked
	 */
	const handleToolBarBtnClick = (btn: string, event: React.MouseEvent) => {
		event.stopPropagation()
		event.preventDefault()
		if (btn === DOWNLOAD) {
			downloadAContent()
		} else if (btn === MODAL) {
			action(MODAL)
		} else if (btn === STARRED) {
			starAContent()
		} else if (btn === 'info') {
			action('info')
		}
	}

	return (
		<AnimatePresence>
			{isVisible && (
				<>
					<motion.div
						className={classnames(
							styles.toolBarContainer,
							styles[thumbnailSize.toLowerCase()],
							!isVisible ? styles.hidden : '',
							isCardList && styles.cardList
						)}
						variants={
							thumbnailSize === SM || isCardList
								? toolBarVariantsSm
								: toolBarVariantsLg
						}
						initial="hidden"
						animate={isVisible ? 'visible' : 'hidden'}
						exit="hidden"
						layout
					>
						<ul>
							<li title={t('tooltips.preview')}>
								<Button
									onClick={(e) => {
										e.preventDefault()
										e.stopPropagation()
										dispatch(setTempValue('fileForPreview', cardObject))
									}}
									iconClass={classnames(styles.toolbarIcon)}
									type={BUTTON_TEXT}
									icon={<VisibilityOutlined htmlColor="#ffffff" />}
									buttonClass={styles.toolbarBtn}
									containerClass={styles.toolbarBtnContainer}
									id="toolbar-preview"
								/>
							</li>
							{isEditable && (
								<li title={t('tooltips.edit')}>
									<Button
										onClick={() => openFileEditor(cardObject)}
										iconClass={classnames(styles.toolbarIcon)}
										type={BUTTON_TEXT}
										icon={<EditOutlined htmlColor="#ffffff" />}
										buttonClass={styles.toolbarBtn}
										containerClass={styles.toolbarBtnContainer}
										id="toolbar-edit"
									/>
								</li>
							)}
							{cardObject.type && (
								<li
									title={
										EXTERNAL_FILE_TYPES.includes(cardObject.type.toUpperCase())
											? t('tooltips.open-in-new-tab')
											: t('tooltips.download-file')
									}
								>
									<Button
										iconClass={styles.toolbarIcon}
										onClick={(e) => handleToolBarBtnClick(DOWNLOAD, e)}
										icon={
											cardObject.type &&
											EXTERNAL_FILE_TYPES.includes(
												cardObject.type.toUpperCase()
											) ? (
												<OpenInNew htmlColor="#ffffff" />
											) : (
												<SaveAlt htmlColor="#ffffff" />
											)
										}
										type={BUTTON_TEXT}
										buttonClass={styles.toolbarBtn}
										containerClass={styles.toolbarBtnContainer}
										id="toolbar-download"
									/>
								</li>
							)}
							<li>
								<Button
									iconClass={styles.toolbarIcon}
									onClick={(e) => handleToolBarBtnClick(MODAL, e)}
									icon={
										cardObject.flagged ? (
											<Flag htmlColor="#ff8c24" />
										) : (
											<FlagOutlined htmlColor="#ffffff" />
										)
									}
									type={BUTTON_TEXT}
									buttonClass={styles.toolbarBtn}
									containerClass={styles.toolbarBtnContainer}
									id="toolbar-flag"
								/>
							</li>
							<li>
								<Button
									iconClass={classnames(
										styles.toolbarIcon,
										isThisStarred ? styles.starred : null
									)}
									onClick={(e) => handleToolBarBtnClick(STARRED, e)}
									icon={
										isThisStarred ? (
											<Star htmlColor="#ffe14d" />
										) : (
											<StarOutline htmlColor="#ffffff" />
										)
									}
									type={BUTTON_TEXT}
									buttonClass={styles.toolbarBtn}
									containerClass={styles.toolbarBtnContainer}
									id="toolbar-star"
								/>
							</li>
						</ul>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	)
}

export default ToolBar
