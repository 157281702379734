import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { differenceInMinutes } from 'date-fns'
import { useSelector, useDispatch } from 'react-redux'

import { t } from '../../utils/languages/i18n'
import { getFileConvertStatus } from '../../api/requests/converter'
import {
	addLoadingCards,
	toggleConverting
} from '../../store/actions/buildContext'
import { getTags } from '../../store/actions/tags'
import { setTempValue } from '../../store/actions/temp'
import { ERROR, SUCCESS } from '../../utils/consts'
import {
	removeAllLoadingThumbnai,
	addToPresentation,
	deleteSlideByChecksum
} from '../../store/actions/presentation'
import { getMyFileSlides } from '../../api/requests/content'

const ConversionHandler = () => {
	const dispatch = useDispatch()
	const authToken = useSelector((store) => store.authUser.user.token)
	const conversionFiles = useSelector((state) => state.temp.conversionFiles)
	const presentationBarSlides = useSelector(
		(state) => state.presentation.presentationBarSlides
	)

	useEffect(() => {
		if (conversionFiles.length > 0) {
			const currentConversionFiles = [...conversionFiles]
			currentConversionFiles.forEach((item, index) => {
				if (!item.intervalHandler && !item.isFinished) {
					item.intervalHandler = setInterval(() => {
						getFileConvertStatus(item.checksum).then((result) => {
							const newResultArr = Object.keys(result).map((key) => result[key])
							const currentItem = newResultArr.find(
								(resItem) => resItem.checksum === item.checksum
							)
							if (currentItem) {
								if (
									currentItem.percent_complete === 100 &&
									currentItem.status === 2
								) {
									dispatch(
										setTempValue('updateConversionFolderId', item.folderId)
									)
									clearInterval(item.intervalHandler)
									if (
										presentationBarSlides.findIndex(
											(presentation) => presentation.checksum === item.checksum
										) > -1
									) {
										getMyFileSlides(currentItem.files_id).then(
											(slidesResult) => {
												dispatch(addToPresentation(slidesResult))
												dispatch(deleteSlideByChecksum(item.checksum))
											}
										)
									}
									currentConversionFiles.splice(item, 1)
									dispatch(
										setTempValue('conversionFiles', currentConversionFiles)
									)
									dispatch(removeAllLoadingThumbnai())
									const queryString = window.location.pathname
									if (queryString?.includes('admin')) {
										dispatch(toggleConverting(false))
										dispatch(addLoadingCards(0))
										dispatch(getTags(authToken))
									} else {
										toast(
											`${currentItem.filename} ${t(
												'notifications.success.conversion-complete'
											)}`,
											{
												position: toast.POSITION.BOTTOM_RIGHT,
												type: SUCCESS.toLowerCase(),
												autoClose: 5000
											}
										)
									}
								} else if (currentItem.status === 3) {
									clearInterval(item.intervalHandler)
									currentConversionFiles.splice(item, 1)
									dispatch(
										setTempValue('conversionFiles', currentConversionFiles)
									)
									dispatch(removeAllLoadingThumbnai())
									dispatch(toggleConverting(false))
									toast(t('notifications.error.pptx-conversion-failed'), {
										position: toast.POSITION.BOTTOM_RIGHT,
										type: ERROR.toLowerCase(),
										autoClose: 5000
									})
								} else if (
									differenceInMinutes(new Date(), item.started) >= 10
								) {
									clearInterval(item.intervalHandler)
									currentConversionFiles.splice(item, 1)
									dispatch(
										setTempValue('conversionFiles', currentConversionFiles)
									)
									dispatch(removeAllLoadingThumbnai())
									toast(t('notifications.error.pptx-conversion-timed-out'), {
										position: toast.POSITION.BOTTOM_RIGHT,
										type: SUCCESS.toLowerCase(),
										autoClose: 5000
									})
								}
							}
						})
					}, 5000)
					dispatch(setTempValue('conversionFiles', currentConversionFiles))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conversionFiles])

	return <div />
}

export default ConversionHandler
