import React, { useState } from 'react'
import Proptypes from 'prop-types'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Modal from '../common/ModalNew'
import styles from './FlagContentModal.module.scss'
import Section from '../common/Section'
import { FLEX_START, FLEX_DIRECTION_COL, SUCCESS } from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import RadioGroup from '../common/RadioGroup'
import Button from '../common/Button'
import { flagContent } from '../../api/requests/content'

const FlagContentModal = (props) => {
	const { isActive, handleClose, cardObject, zIndex } = props
	const authToken = useSelector((store) => store.authUser.user.token)
	const [flagContentData, setFlagContentData] = useState({
		reason: null,
		description: ''
	})

	/**
	 *
	 * @description Handler function that call the API which sets a specific content as flagged
	 * and toggles a notification if the flagging has been successfull
	 */
	function handleContentFlag() {
		flagContent(
			{
				reason: flagContentData.reason,
				description: flagContentData.description,
				type: cardObject._type,
				material_id: cardObject.id
			},
			authToken
		).then(() => {
			handleClose()
			toast(t('notifications.success.content-flagged'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
		})
	}

	/**
	 * @description set input data in state
	 * @param {string} name of the field
	 * @param {string} value from the field
	 */
	const setFlagContent = (name, value) => {
		setFlagContentData({
			...flagContentData,
			[name]: value
		})
	}

	return (
		<>
			<Modal
				isShowing={isActive}
				hide={handleClose}
				customClassName={styles.flagContentModal}
				modalTitle={t('titles.flag-content')}
				zIndex={zIndex}
			>
				<Section
					sectionClassName={styles.section}
					padding="30px"
					justify={FLEX_START}
					flexDirection={FLEX_DIRECTION_COL}
					alignItems={FLEX_START}
				>
					<div className={styles.header}>
						<p>{cardObject.name}</p>
						<p>{t('misc.flag-content')}</p>
					</div>

					<RadioGroup
						continerClass={styles.flagOptions}
						radioItems={[
							{ key: 1, label: t('misc.its-out-of-date') },
							{ key: 2, label: t('misc.there-is-a-mistake-in-it') },
							{ key: 3, label: t('misc.there-is-quality-problem') },
							{ key: 4, label: t('misc.please-contact-me') }
						]}
						onChange={(value) => setFlagContent('reason', value.toString())}
					/>
				</Section>
				<Section
					sectionClassName={styles.section}
					padding="30px"
					justify={FLEX_START}
					flexDirection={FLEX_DIRECTION_COL}
					alignItems={FLEX_START}
				>
					<textarea
						className={styles.commentBox}
						name="description"
						onChange={(event) =>
							setFlagContent(event.target.name, event.target.value)
						}
					/>
				</Section>
				<Section
					sectionClassName={styles.section}
					padding="30px"
					justify={FLEX_START}
					flexDirection={FLEX_DIRECTION_COL}
					alignItems={FLEX_START}
				>
					<div className={styles.footerContainer}>
						<Button
							onClick={() => handleClose()}
							label={t('labels.cancel')}
							id="flag-content-modal-cancel"
						/>
						<Button
							label={t('labels.send')}
							isPositive
							onClick={() => handleContentFlag()}
							id="flag-content-modal-send"
						/>
					</div>
				</Section>
			</Modal>
		</>
	)
}

FlagContentModal.defaultProps = {
	isActive: false,
	zIndex: 9998
}

FlagContentModal.propTypes = {
	cardObject: Proptypes.objectOf(Proptypes.any).isRequired,
	handleClose: Proptypes.func.isRequired,
	isActive: Proptypes.bool,
	zIndex: Proptypes.number
}

export default FlagContentModal
