import React from 'react'

import Modal from '../ModalNew'

import styles from './ConfirmDeleteModalNew.module.scss'

import Button from '../Button'
import { t } from '../../../utils/languages/i18n'

interface ConfirmDeleteModalNewInterface {
	cancelLabel?: string
	children: React.ReactNode | React.ReactNode[]
	confirmLabel?: string
	declineCallback?: () => void
	disableButton?: boolean
	disableOverlayClose?: boolean
	handleClose: () => void
	isActive: boolean
	onApprove: () => void
	title?: string
	zIndex?: number
}

const ConfirmDeleteModalNew = ({
	isActive,
	handleClose,
	title = t('titles.are-you-sure'),
	onApprove,
	children,
	cancelLabel = t('buttons.cancel'),
	confirmLabel = t('buttons.confirm'),
	declineCallback,
	disableButton = false,
	disableOverlayClose = false,
	zIndex
}: ConfirmDeleteModalNewInterface) => {
	/**
	 *
	 * @description calls the callback function after closing the modal
	 */
	const handleDecline = () => {
		handleClose()
		if (typeof declineCallback === 'function') {
			declineCallback()
		}
	}

	return (
		<>
			<Modal
				isShowing={isActive}
				hide={handleClose}
				modalTitle={title}
				customClassName={styles.confirmDeletaionModal}
				disableOverlayClose={disableOverlayClose}
				footer={
					<>
						<Button
							label={cancelLabel}
							onClick={() => handleDecline()}
							id="confirm-delete-modal-cancel"
						/>
						<Button
							label={confirmLabel}
							isPositive
							onClick={onApprove}
							isDisabled={disableButton}
							id="confirm-delete-modal-confirm"
						/>
					</>
				}
				zIndex={zIndex}
			>
				{children && children}
			</Modal>
		</>
	)
}

export default ConfirmDeleteModalNew
