import React from 'react'
import PropTypes from 'prop-types'

import SfModal from '../ModalNew'
import styles from './RenameFileModal.module.scss'
import Button from '../Button'
import { t } from '../../../utils/languages/i18n'

/**
 * @author miroslavstan
 * @function RenameFileModal
 * */

const RenameFileModal = (props) => {
	const {
		isActive,
		handleClose,
		title,
		onApprove,
		children,
		disableButton,
		cancelLabel,
		confirmLabel,
		declineCallback,
		disableOverlayClose,
		zIndex
	} = props

	/**
	 *
	 * @description calls the callback function after closing the modal
	 */
	function handleDecline() {
		handleClose()
		if (typeof declineCallback === 'function') {
			declineCallback()
		}
	}
	return (
		<>
			<SfModal
				isShowing={isActive}
				hide={handleClose}
				modalTitle={title}
				customClassName={styles.confirmRenameModal}
				disableOverlayClose={disableOverlayClose}
				contentClassName={styles.contentRenameModal}
				{...(zIndex ? { zIndex } : {})}
				footer={
					<>
						<Button
							label={cancelLabel}
							onClick={() => handleDecline()}
							id="rename-file-modal-cancel-label-button"
						/>
						<Button
							label={confirmLabel}
							isPositive
							onClick={onApprove}
							isDisabled={disableButton}
							id="rename-file-modal-confirm-label-button"
						/>
					</>
				}
			>
				{children && children}
			</SfModal>
		</>
	)
}

RenameFileModal.defaultProps = {
	cancelLabel: t('buttons.cancel'),
	children: null,
	confirmLabel: t('buttons.confirm'),
	declineCallback: null,
	disableButton: false,
	disableOverlayClose: false,
	isActive: false,
	onApprove: null,
	title: t('titles.are-you-sure'),
	zIndex: undefined
}

RenameFileModal.propTypes = {
	cancelLabel: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]),
	confirmLabel: PropTypes.string,
	declineCallback: PropTypes.func,
	disableButton: PropTypes.bool,
	disableOverlayClose: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	isActive: PropTypes.bool,
	onApprove: PropTypes.func,
	title: PropTypes.string,
	zIndex: PropTypes.number
}

export default RenameFileModal
