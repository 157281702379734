import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'

/**
 * @author zilahir
 * @function AutuSuggest
 * */

const SuggestType = ({
	theme,
	suggestionList,
	getBackSelected,
	placeHolder,
}) => {
	const [value, setValue] = useState('')
	const [suggestions, setSuggestions] = useState([])

	// Teach Autosuggest how to calculate suggestions for any given input value.
	const getSuggestions = input => {
		const inputValue = input.trim().toLowerCase()
		const inputLength = inputValue.length

		return inputLength === 0 ? []
			: suggestionList.filter(
				lang => lang.firstname.toLowerCase().slice(0, inputLength) === inputValue,
			)
	}

	// When suggestion is clicked, Autosuggest needs to populate the input
	// based on the clicked suggestion. Teach Autosuggest how to calculate the
	// input value for every given suggestion.
	const getSuggestionValue = suggestion => suggestion.firstname

	// Use your imagination to render suggestions.
	const renderSuggestion = suggestion => (
		<div>
			{suggestion.firstname} {suggestion.lastname} ({suggestion.email})
		</div>
	)

	/**
	 * @param {object} event the event object of the typing
	 * @param {object} Object an object, containing the newValue type into the input field
	 * @description  sets the typed value in a state
	 */
	function onChange(event, { newValue }) {
		setValue(newValue)
	}

	const inputProps = {
		placeholder: placeHolder,
		value,
		onChange: (event, { newValue }) => onChange(event, { newValue }),
	}

	/**
	 * @param {object} type object representation of the fetch request
	 * @description read more at: https://github.com/moroshko/react-autosuggest
	 */
	function onSuggestionsFetchRequested(type) {
		setSuggestions(getSuggestions(type.value))
	}


	/**
	 *
	 *
	 * @param {object} event the object representation of the clicked event
	 * @param {object} { suggestion } an object containing the clicked Suggestion
	 * @description the selected value is being sent to the parent using a callback
	 * cleares out the search term in the input field
	 */
	function handleSuggestionClick(event, { suggestion }) {
		getBackSelected(suggestion)
		setValue('')
		event.preventDefault()
		event.stopPropagation()
	}

	return (
		<>
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={type => onSuggestionsFetchRequested(type)}
				onSuggestionsClearRequested={() => setSuggestions([])}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
				theme={theme}
				focusInputOnSuggestionClick
				onSuggestionSelected={
					(event, { suggestion }) => handleSuggestionClick(
						event, { suggestion },
					)
				}
			/>
		</>
	)
}

SuggestType.defaultProps = {
	getBackSelected: () => {},
	theme: null,
}

SuggestType.propTypes = {
	getBackSelected: PropTypes.func,
	placeHolder: PropTypes.string.isRequired,
	suggestionList: PropTypes.arrayOf(
		PropTypes.any,
	).isRequired,
	theme: PropTypes.objectOf(
		PropTypes.any,
	),
}

export default SuggestType
