import React, { useEffect, useState } from 'react'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'

import styles from './FilterLabelsDropdown.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Label from '../../../../components/common/Label'
import Button from '../../../../components/common/Button'
import { LabelInterface, StoreInterface } from '../../../../utils/interfaces'
import { setMemositeValue } from '../../../../store/actions/memosite'

interface FilterLabelsDropdownInterface {
	onClose: () => void
}

const FilterLabelsDropdown = ({ onClose }: FilterLabelsDropdownInterface) => {
	const dispatch = useDispatch()

	const [adminLabels, setAdminLabels] = useState<LabelInterface[]>([])
	const [userLabels, setUserLabels] = useState<LabelInterface[]>([])

	const { labels, selectedLabelsToFilter } = useSelector(
		(store: StoreInterface) => store.memosite
	)

	useEffect(() => {
		const adminLabelsArray = []
		const userLabelsArray = []
		if (labels.length > 0) {
			for (const l of labels) {
				if (l.type === 'Admin') {
					adminLabelsArray.push(l)
				} else {
					userLabelsArray.push(l)
				}
			}
			setAdminLabels(adminLabelsArray)
			setUserLabels(userLabelsArray)
		}
	}, [labels])

	const removeLabel = (label: LabelInterface) => {
		const updatedLabels = selectedLabelsToFilter.filter(
			(l) => l.id !== label.id
		)
		dispatch(setMemositeValue('selectedLabelsToFilter', updatedLabels))
	}

	const addLabel = (label: LabelInterface) => {
		const isLabelDuplicated = selectedLabelsToFilter.find(
			(l) => l.id === label.id
		)
		if (!isLabelDuplicated) {
			dispatch(
				setMemositeValue('selectedLabelsToFilter', [
					...selectedLabelsToFilter,
					label
				])
			)
		}
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.heading} onClick={onClose}>
				<div className={styles.title}>{t('labels.labels')}</div>
				<Close className={styles.closeIcon} />
			</div>
			<div className={styles.labels}>
				{selectedLabelsToFilter.length > 0
					? selectedLabelsToFilter.map((label, index) => (
							<div onClick={() => removeLabel(label)} key={index}>
								<Label bgColor={label.color} name={label.name} />
							</div>
					  ))
					: t('labels.no-labels')}
			</div>
			<div className={c(styles.title, styles.addLabels)}>
				{t('labels.add-labels')}
			</div>
			<div className={styles.subtitle}>{t('labels.admin-created')}</div>
			<div className={styles.labels}>
				{adminLabels.length > 0 &&
					adminLabels.map((label, index) => (
						<div onClick={() => addLabel(label)} key={index}>
							<Label bgColor={label.color} name={label.name} />
						</div>
					))}
			</div>
			<div className={styles.subtitle}>{t('labels.your-labels')}</div>
			<div className={styles.labels}>
				{userLabels.length > 0 &&
					userLabels.map((label, index) => (
						<div onClick={() => addLabel(label)} key={index}>
							<Label key={index} bgColor={label.color} name={label.name} />
						</div>
					))}
			</div>
			<div className={styles.footer}>
				<Button
					buttonClass={styles.btn}
					label={t('buttons.done')}
					isPositive
					onClick={onClose}
					id="filter-labels-dropdown-done-button"
				/>
			</div>
		</div>
	)
}

export default FilterLabelsDropdown
