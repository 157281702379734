import logoBottomLeft from '../assets/misc/logo-alignments/logo-bottom-left.svg'
import logoBottomCenter from '../assets/misc/logo-alignments/logo-bottom-middle.svg'
import logoBottomRight from '../assets/misc/logo-alignments/logo-bottom-right.svg'

/* BUILD MODULE */
export const SENT_MEMOSITES = 'SENT_MEMOSITES'
export const MY_PRESENTATIONS = 'MY_PRESENTATIONS'
export const NEW_PRESENTATION = 'NEW_PRESENTATION'
export const ACTIONS_AND_NOTES = 'ACTIONS_AND_NOTES'
export const MY_FILES = 'MY_FILES'
export const STARRED = 'STARRED'
export const LIBRARY = 'LIBRARY'

/* MISC */

export const headers = {
	'Content-Type': 'application/json'
}
export const POST = 'post'
export const GET = 'get'
export const PATCH = 'patch'
export const DELETE = 'delete'
export const PUT = 'put'
export const REQUESTS = [POST, GET, PATCH, DELETE, PUT]
export const BUTTON_NORMAL = 'BUTTON_NORMAL'
export const BUTTON_TEXT = 'BUTTON_TEXT'
export const BUTTON_DROPDOWN = 'BUTTON_DROPDOWN'
export const BUTTON_TYPES = [BUTTON_TEXT, BUTTON_NORMAL]
export const ICON_LEFT = 'ICON_LEFT'
export const ICON_RIGHT = 'ICON_RIGHT'
export const BLUR = 'BLUR'
export const FLEX_START = 'flex-start'
export const FLEX_DIRECTION_COL = 'column'
export const FLEX_END = 'flex-end'
export const JUSTIFY_SPACE_BETWEEN = 'JUSTIFY_SPACE_BETWEEN'
export const INSTANCE_SWITCH_MODAL = 'INSTANCE_SWITCH_MODAL'
export const ACCEPT = 'ACCEPT'
export const DECLINE = 'DECLINE'
export const PLACEHOLDER = 'PLACEHOLDER'
export const HTML = 'HTML'

/* MODULES */

export const HOME = 'HOME'
export const LAYOUT = 'LAYOUT'
export const BUILD = 'BUILD'
export const PRESENT = 'PRESENT'
export const SETTINGS = 'SETTINGS'
export const FOLLOWUP = 'FOLLOWUP'
export const MY_MEMOSITES_ = 'MY_MEMOSITES'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const ERROR_PAGE = 'ERROR_PAGE'
export const ANALYTICS = 'ANALYTICS'
export const DASHBOARD = 'DASHBOARD'

/* MODULES FOR ANALYTICS */

export const MODULES = [
	{ key: HOME, absoluteTarget: '/', analyticsModuleName: 'Home' },
	{
		key: LAYOUT,
		absoluteTarget: `/${LAYOUT.toLowerCase()}`,
		analyticsModuleName: 'Layout'
	},
	{
		key: BUILD,
		absoluteTarget: `/${BUILD.toLowerCase()}`,
		analyticsModuleName: 'Build'
	},
	{
		key: PRESENT,
		absoluteTarget: `/${PRESENT.toLowerCase()}`,
		analyticsModuleName: 'Present'
	},
	{
		key: SETTINGS,
		absoluteTarget: `/${SETTINGS.toLowerCase()}`,
		analyticsModuleName: 'Settings'
	},
	{
		key: FOLLOWUP,
		absoluteTarget: `/${FOLLOWUP.toLowerCase()}`,
		analyticsModuleName: 'FollowUp'
	},
	{
		key: LOGIN,
		absoluteTarget: `/${LOGIN.toLowerCase()}`,
		analyticsModuleName: 'Login'
	}
]

export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'
export const BOTTOM = 'BOTTOM'
export const TOP = 'TOP'
export const UP = 'UP'
export const DOWN = 'DOWN'
export const DIRECTIONS = [TOP, RIGHT, BOTTOM, LEFT]
export const SLIDE_NAV_DIRECTIONS = [LEFT, RIGHT]
export const ORDER_ROW = 'ROW'
export const ORDER_GRID = 'GRID'
export const SHARED = 'SHARED'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const TEXT = 'TEXT'
export const PASSWORD = 'PASSWORD'
export const DEFAULT = 'DEFAULT'
export const USER = 'USER'
export const INPUT_STATES = [
	DEFAULT.toLowerCase(),
	ERROR.toLowerCase(),
	SUCCESS.toLowerCase()
]
export const FEED = 'FEED'
export const NOTE = 'NOTE'
export const SM = 'SM'
export const LG = 'LG'
export const VIDEO = 'VIDEO'
export const VIMEO = 'VIMEO'
export const YOUTUBE = 'YOUTUBE'
export const URL = 'URL'

export const UPLOAD_DROPZONE = 'UPLOAD_DROPZONE'
export const UPLOAD_BUTTON = 'UPLOAD_BUTTON'
export const UPLOAD_TYPES = [UPLOAD_DROPZONE, UPLOAD_BUTTON]
export const NEW_CATEGORY = 'NEW_CATEGORY'
export const CATEGORY = 'CATEGORY'
export const CATEGORY_TYPES = [CATEGORY, NEW_CATEGORY]
export const ADDED_FILES = 'ADDED_FILES'
export const CUSTOM_SLIDES = 'CUSTOM_SLIDES'
export const DOWNLOAD = 'DOWNLOAD'
export const MODAL = 'MODAL'
export const PREVIEW = 'PREVIEW'
export const SAVE_PRESENTATION = 'SAVE_PRESENTATION'
export const SAVE_PRESENTATION_AS = 'SAVE_PRESENTATION_AS'
export const LOGIN_PAGE_INITIAL = 'LOGIN_PAGE_INITIAL'
export const LOGIN_PAGE_PASSWORD = 'LOGIN_PAGE_PASSWORD'
export const SLIDESHOW = 'SLIDESHOW'
export const TOP_LEFT = 'TOP_LEFT'
export const TOP_MIDDLE = 'TOP_MIDDLE'
export const TOP_RIGHT = 'TOP_RIGHT'
export const BOTTOM_LEFT = 'BOTTOM_LEFT'
export const BOTTOM_MIDDLE = 'BOTTOM_MIDDLE'
export const BOTTOM_RIGHT = 'BOTTOM_RIGHT'
export const LOGO_ALIGNMENTS = [
	{ position: BOTTOM_LEFT, image: logoBottomLeft },
	{ position: BOTTOM_MIDDLE, image: logoBottomCenter },
	{ position: BOTTOM_RIGHT, image: logoBottomRight }
]
export const VERTICAL = 'VERTICAL'
export const HORIZONTAL = 'HORIZONTAL'
export const NAVIGATION_SYLES = [VERTICAL, HORIZONTAL]
export const GALLERY_SHAPE_NONE = 'GALLERY_SHAPE_NONE'
export const GALLERY_SHAPE_BOX = 'GALLERY_SHAPE_BOX'
export const GALLERY_SHAPE_ROUNDEDN_BOX = 'GALLERY_SHAPE_ROUNDEDN_BOX'
export const GALLERY_SHAPE_CIRCLE = 'GALLERY_SHAPE_CIRCLE'
export const GALLERY_SHAPES = [
	GALLERY_SHAPE_NONE,
	GALLERY_SHAPE_BOX,
	GALLERY_SHAPE_ROUNDEDN_BOX,
	GALLERY_SHAPE_CIRCLE
]
export const LOGO_IMAGE = 'LOGO_IMAGE'
export const FOLDER_ICON_IMAGE = 'FOLDER_ICON_IMAGE'
export const TAGLIST_BREADCRUMBS = 'TAGLIST_BREADCRUMBS'
export const BREADCRUMB = 'BREADCRUMB'
export const SHORTCUT = 'SHORTCUT'
export const TAGLIST_SHORTCUT = 'TAGLIST_SHORTCUT'
export const FIXED_TAGLIST_TYPES = [TAGLIST_BREADCRUMBS, TAGLIST_SHORTCUT]
export const CONTENT_PANE = 'CONTENT_PANE'

/* KEYS */
export const ENTER = 'ENTER'
export const ARROW_LEFT = 'ARROWLEFT'
export const ARROW_RIGHT = 'ARROWRIGHT'

/* PAGES */

export const REMOTE_PRESENTATION_SLIDESHOW = '/REMOTE'
export const REMOTE_PRESENTATION_ADMIN = '/PRESENTER'
export const REMOTE_PRESENTATION = [
	REMOTE_PRESENTATION_SLIDESHOW.toLowerCase(),
	REMOTE_PRESENTATION_ADMIN.toLowerCase()
]
export const STORYBOARD = 'STORYBOARD'

/* FILE TYPES */

export const PNG = 'PNG'
export const JPG = 'JPG'
export const JPEG = 'JPEG'
export const GIF = 'GIF'
export const IMAGE_TYPES = [PNG, JPEG, JPG, GIF]
export const DOC = 'DOC'
export const DOCX = 'DOCX'
export const XLS = 'XLS'
export const XLSX = 'XLSX'
export const PPT = 'PPT'
export const PPTX = 'PPTX'
export const MSDOC_TYPES = [DOC, DOCX, XLS, XLSX, PPT, PPTX]
export const PDF = 'PDF'
export const MP4 = 'MP4'
export const WEBM = 'WEBM'
export const MKV = 'MKV'
export const KEY = 'KEY'
export const VIDEOS = [MP4, WEBM]
export const VIDEO_EXTENSIONS = [
	VIDEO.toLowerCase(),
	VIMEO.toLowerCase(),
	YOUTUBE.toLowerCase(),
	WEBM.toLowerCase(),
	MP4.toLowerCase()
]
export const CONVERTING_FILE_TYPES = [PPT, PPTX, PDF]
export const CONVERSION_FILE_TYPES_ALLOWED = [PPT, PPTX, KEY]
export const THUMBNAIL_TYPES = [...IMAGE_TYPES, YOUTUBE, VIMEO]
export const FILE = 'file'
export const SLIDE = 'slide'
export const EXTERNAL_FILE_TYPES = [URL, YOUTUBE, VIMEO]
export const APPLICATION_PDF = 'application/pdf'

/* COMPONENTS */

export const TOP_HEADER = 'TOP_HEADER'
export const PRESENTATION_BAR = 'PRESENTATION_BAR'
export const SIDE_MENU = 'SIDE_MENU'
export const BACKGROUND = 'BACKGROUND'
export const IOS_DEVICES = [
	'iPad Simulator',
	'iPhone Simulator',
	'iPod Simulator',
	'iPad',
	'iPhone',
	'iPod'
]
export const MEMOSITE_BAR = 'MEMOSITE_BAR'
export const SEARCH_MODAL = 'SEARCH_MODAL'

/* USER TYPES */

export const ADMIN = 'Admin'
export const SYSADMIN = 'Sysadmin'

/* NOTIFICATION TYPES */

export const FILE_DELETED = 'FILE_DELETED'
export const FILE_FLAGGED = 'FILE_FLAGGED'
export const MEMOSITE_REQUEST = 'MEMOSITE_REQUEST'
export const SHARED_PRESENTATION = 'SHARED_PRESENTATION'
export const MEMOSITE_DOWNLOADED = 'MEMOSITE_DOWNLOADED'
export const MEMOSITE_OPENED = 'MEMOSITE_OPENED'
export const FILE_RESOLVED = 'FILE_RESOLVED'
export const SHARED_FOLDER = 'SHARED_FOLDER'
export const MEMOSITE_CODE_REQUEST = 'MEMOSITE_CODE_REQUEST'

export const ALL_NOTIFICATION_TYPES = [
	FILE_DELETED,
	FILE_FLAGGED,
	MEMOSITE_REQUEST,
	SHARED_PRESENTATION,
	MEMOSITE_DOWNLOADED,
	MEMOSITE_OPENED,
	SHARED_FOLDER,
	MEMOSITE_CODE_REQUEST
]

export const DEV_STAGES = ['development', 'staging']
export const PROOD_STAGES = ['production']

export const FONT_WEIGHTS = {
	100: 'Thin',
	200: 'Extra Light',
	300: 'Light',
	400: 'Regular',
	regular: 'Regular',
	500: 'Medium',
	600: 'Semi Bold',
	700: 'Bold',
	800: 'Extra Bold',
	900: 'Black'
}

export const REGULAR = 'regular'

export const CLOSED = 'CLOSED'
export const AVAILABLE = 'AVAILABLE'
export const RECORDING = 'RECORDING'
export const CAMERA_ERROR = 'CAMERA_ERROR'
export const NO_MEDIA_RECORDER = 'NO_MEDIA_RECORDER'

/* ALLOWED ORIGINS TO SEND MESSAGE TO XENON FROM CHILD IFRAME */

export const ALLOWED_ORIGINS = [
	'https://survey.salesfra.me',
	'https://soleracalculator.salesfra.me',
	'https://valuewaterfall.salesfra.me'
]
export const ALLOWED_MESSAGES = ['newSlideCreated', 'newFolderCreated']
export const PDF_ALLOWED_TYPES = [PNG, JPEG, JPG, GIF, SLIDE]

export const GRID = 'grid'
export const LIST = 'list'
export const PROFILE = 'profile'
export const COVER = 'cover'
export const GREETING = 'greeting'
