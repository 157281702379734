import React from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import styles from './CRMSalesforcePihlajalinna.module.scss'

import Modal from '../../../components/common/ModalNew'
import Spinner from '../../../components/common/Spinner'
import { t } from '../../../utils/languages/i18n'
import Section from '../../../components/common/Section'
import Button from '../../../components/common/Button'
import { BUTTON_TEXT, FLEX_END } from '../../../utils/consts'
import Input from '../../../components/common/Input'
import Results from './Results/Results'
import {
	searchOpportunityByIdPihlajalinna,
	searchOpportunityPihlajalinna,
	sendCrmDataPihlajalinna
} from '../../../api/requests/crmSalesforce'
import { getMemositeLink } from '../../../utils/helpers/memosites'
import { StoreInterface } from '../../../utils/interfaces'
import { setTempValue } from '../../../store/actions/temp'

interface CRMFSalesforceInterface {
	handleClose: () => void
	memosite: any
	saveButtonDisabled: boolean
	zIndex?: number
}

const CRMSalesforcePihlajalinna = ({
	handleClose,
	memosite,
	saveButtonDisabled = false,
	zIndex = 9999
}: CRMFSalesforceInterface) => {
	const dispatch = useDispatch()

	const [allResults, setAllResults] = React.useState<any[]>([])
	const [opportunityName, setOpportunityName] = React.useState('')
	const [opportunityId, setOpportunityId] = React.useState('')
	const [startPos, setStartPos] = React.useState(31)
	const [isLoading, setIsLoading] = React.useState(false)

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const { instanceName, salesforceOpportunityId } = useSelector(
		(state: StoreInterface) => state.temp
	)

	React.useEffect(() => {
		if (salesforceOpportunityId) {
			setOpportunityId(salesforceOpportunityId)
			searchByIdHandler()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salesforceOpportunityId])

	const resetHandler = () => {
		dispatch(setTempValue('salesforceOpportunityId', undefined))
		setOpportunityName('')
		setAllResults([])
		setOpportunityId('')
	}

	const closeHandler = () => {
		resetHandler()
		handleClose()
	}

	const searchByIdHandler = () => {
		searchOpportunityByIdPihlajalinna(salesforceOpportunityId || '')
			.then((result) => {
				setAllResults(result)
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
			})
	}

	const handleSearch = () => {
		setIsLoading(true)
		setStartPos(31)
		searchOpportunityPihlajalinna(opportunityName)
			.then((result: any) => {
				setAllResults(result)
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
			})
	}

	const handleMoreResults = () => {
		setIsLoading(true)
		setStartPos(startPos + 30)
	}

	const escapeHtml = (str: string) =>
		str
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;')

	const handleSend = () => {
		setIsLoading(true)
		const memoUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			authUser.user.role,
			true
		)
		const data = {
			content: `<b>${escapeHtml(
				'A memosite was created in Salesframe'
			)}</b><p>By user ${authUser.user.firstname} ${
				authUser.user.lastname
			} ${new Date().toDateString()}</p> <p><b>${escapeHtml(memoUrl)}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false</b></p>`,
			entity_id: opportunityId,
			title: memosite.subject,
			user_id: authUser.user.id,
			meeting_id: memosite.id
		}
		sendCrmDataPihlajalinna(data)
			.then(() => {
				closeHandler()
				toast(t('notifications.success.crm-data-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
				setIsLoading(false)
			})
			.catch(() => {
				toast(t('notifications.error.crm-data-not-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: 5000
				})
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={closeHandler}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>
					{salesforceOpportunityId
						? `${t('labels.selected_opportunity')}:`
						: t('labels.search_by_opportunity_name')}
				</p>
			</div>
			<div>
				{!salesforceOpportunityId && (
					<div className={styles.crmContainer}>
						<Input
							onChange={(event) => setOpportunityName(event.target.value)}
							placeholderText={t('labels.search_by_opportunity_name')}
							name="opportunityName"
							initialValue={opportunityName}
							inputClassName={styles.input}
						/>
						<Button
							onClick={handleSearch}
							isPositive
							label={t('labels.search')}
							buttonClass={styles.btn}
							isDisabled={saveButtonDisabled}
							id="crm-pihlajalinna-search"
						/>
					</div>
				)}
				<div
					className={c(
						styles.notesContainer,
						allResults.length > 0 && styles.scrollable
					)}
				>
					{salesforceOpportunityId && (
						<Button
							onClick={resetHandler}
							isPositive
							label={t('buttons.change')}
							buttonClass={styles.btn}
							isDisabled={saveButtonDisabled}
							containerClass={styles.changeButton}
							id="crm-pihlajalinna-change"
						/>
					)}
					{allResults &&
						allResults.map((customer: { Id: string; Name: string }) => (
							<Results
								key={customer.Id}
								id={customer.Id}
								name={`${customer.Name}`}
								getId={setOpportunityId}
								isSelected={customer.Id === opportunityId}
							/>
						))}
					{startPos < allResults.length ? (
						<Button
							type={BUTTON_TEXT}
							onClick={handleMoreResults}
							label={t('labels.load-more')}
							buttonClass={styles.showMore}
							containerClass={styles.showMoreContainer}
							id="crm-pihlajalinna-load-more"
						/>
					) : null}
					{isLoading ? <Spinner isLoading size={44} /> : null}
				</div>
			</div>
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px"
			>
				<Button
					onClick={closeHandler}
					label={t('buttons.cancel')}
					buttonClass={styles.btn}
					id="crm-pihlajalinna-cancel"
				/>
				<Button
					onClick={handleSend}
					isPositive
					label={t('labels.send')}
					buttonClass={styles.btn}
					isDisabled={saveButtonDisabled}
					id="crm-pihlajalinna-send"
				/>
			</Section>
		</Modal>
	)
}

export default CRMSalesforcePihlajalinna
