import { AUTH_USER, REMOVE_USER, SET_INSTANCES } from './actionTypes'
import { axiosInstance, cloudFnPost } from '../../utils/requests'
import { loginSchema, mfaVerifySchema } from '../../utils/schemas/requests'
import { getUser } from '../../api/requests/user'
import { AuthUserResponseInterface } from '../../utils/interfaces'
import Cookies from 'js-cookie'
import {
	authorizationCustomEndpoint,
	twoFaVerifyEndpoint,
	usersAuthenticationNewEndpoint
} from '../../api/apiEndpoints_new'

interface AuthDataObjectInterface {
	email: string | undefined
	password: string | undefined
	captchaToken: string | undefined
	client: string | undefined
}

export const setUser = (user: any) => (dispatch: any) =>
	new Promise((resolve) => {
		dispatch({
			type: AUTH_USER,
			payload: {
				user
			}
		})
		resolve(user)
	})

export const authUser = (authData: AuthDataObjectInterface) =>
	new Promise<AuthUserResponseInterface>((resolve) => {
		const authObject = {
			username: `${authData.email}`,
			password: `${authData.password}`,
			token: `${authData.captchaToken}`,
			client: `${authData.client}`
		}
		cloudFnPost(usersAuthenticationNewEndpoint, authObject, {}, loginSchema)
			.then((resp) => {
				resolve(resp as AuthUserResponseInterface)
			})
			.catch((error) => {
				resolve(JSON.parse(error.message))
			})
	})

const handleLogout = () => {
	Cookies.remove('JWT')
	Cookies.remove('KMSI')
	localStorage.clear()
	window.location.href = '/login'
}

export const refreshAccessToken = (refreshToken: any, authToken: any) =>
	new Promise((resolve) => {
		axiosInstance
			.get(authorizationCustomEndpoint, {
				params: {
					refresh_token: refreshToken
				},
				headers: {
					Authorization: `Bearer ${authToken}`
				}
			})
			.then((resp) => {
				getUser(resp.data.user.id, resp.data.token).then((userRes: any) => {
					const userObject = {
						...resp.data,
						authSuccess: true,
						user: {
							...userRes,
							userGroupId: resp.data.user.group_id,
							role: resp.data.user.role
						}
					}
					setUser(userObject)
					resolve({
						accessToken: resp.data.token,
						refreshToken: resp.data.refresh_token,
						userEmail: userRes.email,
						role: resp.data.user.role
					})
				})
			})
			.catch(() => {
				handleLogout()
			})
	})

export const removeUser = () => (dispatch: any) =>
	new Promise((resolve) => {
		dispatch({
			type: REMOVE_USER,
			payload: undefined
		})
		resolve({
			loggedOut: true,
			authSuccess: false
		})
	})

export const setInstances = (instances: any) => (dispatch: any) => {
	// TODO: this could be moved to 'users'
	dispatch({
		type: SET_INSTANCES,
		payload: {
			instances
		}
	})
}

export const mfaVerify = (data: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(twoFaVerifyEndpoint, data, {}, mfaVerifySchema)
			.then((resp) => {
				resolve(resp)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
