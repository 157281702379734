import isValidJSON from '../../utils/helpers/isValidJSON'

import {
	ADD_TO_COLOR_PRESET,
	SET_LOGO_IMAGE,
	CLEAR_LOGO_IMAGE,
	CHANGE_LOGO_POSITION,
	SET_LOGO_SIZE,
	SET_BACKGROUND_COLOR,
	SET_BACKGROUND_OPACITY,
	TOGGLE_PRESENTATION_DECK_SHADOW,
	SET_PRESENTATION_DECK_COLOR,
	SET_CONTENT_TAB_COLOR,
	SET_CONTENT_TAB_OPACITY,
	SET_STORYBOARD_FONT,
	SET_STORYBOARD_TITLE_FONT,
	SET_STORYBOARD_FONT_COLOR,
	SET_PRESENTATION_DECK_FONT_COLOR,
	SET_STORYBOARD_FONT_SIZE,
	TOGGLE_UNDERLINE_TITLE,
	SET_TITLE_UNDERLINE_COLOR,
	SET_NAVIGATION_STYLE,
	TOGGLE_SHRINK_PREVIOUS_LEVEL,
	TOGGLE_BREADCRUMBS,
	TOGGLE_LANDING_PAGE,
	SET_LANDING_PAGE_STYLE,
	SET_LANDING_PAGE_TITLE,
	TOGGLE_STORYBOARD_FOLDER_SEARCH,
	TOGGLE_PARALLAX_SCROLLING_BACKGROUND,
	SET_LAYOUT_OBJECT,
	SET_BACKGROUND_IMAGE,
	SET_FOLDER_BACKGROUND_IMAGE,
	SET_FOLDER_LOGO_IMAGE,
	SET_FOLDER_ICON_IMAGE,
	SET_ROOT_LEVEL_GALLERY,
	SET_ROOT_LEVEL_GALLERY_WIDTH,
	SET_ROOT_LEVEL_ITEMS_PER_ROW,
	SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS,
	SET_ROOT_LEVEL_GALLERY_SHAPE,
	SET_ROOT_LEVEL_SHAPE_SHADOW,
	SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR,
	SET_ROOT_LEVEL_TEXT_SHADOW,
	SET_ROOT_LEVEL_USE_FOLDER_ICONS,
	SET_STORYBOARD_FONT_WEIGHT,
	SET_STORYBOARD_TITLE_FONT_WEIGHT
} from './actionTypes'
import { cloudFnGet } from '../../utils/requests'
import { initialState } from '../reducers/layout'
import { uploadImage } from '../../api/requests/layout'
import { appearanceEndpoint } from '../../api/apiEndpoints_new'
import { setLayoutShallowCopy } from './temp'

export const addColorToPreset = (newColor: any) => ({
	type: ADD_TO_COLOR_PRESET,
	payload: {
		newColor
	}
})

export const setLogoImage = (logoImage: any) => ({
	type: SET_LOGO_IMAGE,
	payload: {
		logoImage
	}
})

export const uploadLogo = (logoImage: any, authToken: any) => (dispatch: any) =>
	new Promise((resolve, reject) => {
		uploadImage(logoImage, authToken)
			.then((result: any) => {
				dispatch(setLogoImage(`${result.hash}.${result.extension}`))
				resolve(true)
			})
			.catch((error) => {
				reject(error)
			})
	})

export const clearLogoImage = () => ({
	type: CLEAR_LOGO_IMAGE,
	payload: null
})

export const changeLogoPosition = (logoPosition: any) => ({
	type: CHANGE_LOGO_POSITION,
	payload: {
		logoPosition
	}
})

export const setLogoSize = (logoSize: any) => ({
	type: SET_LOGO_SIZE,
	payload: {
		logoSize
	}
})

export const setBackgroundColor = (backgroundColor: any) => ({
	type: SET_BACKGROUND_COLOR,
	payload: {
		backgroundColor
	}
})

export const setBackgroundImageOpacity = (backgroundImageOpacity: any) => ({
	type: SET_BACKGROUND_OPACITY,
	payload: {
		backgroundImageOpacity
	}
})

export const setBackgroundImage = (backgroundImage: any) => ({
	type: SET_BACKGROUND_IMAGE,
	payload: {
		backgroundImage
	}
})

export const togglePresentationDeckShadow = (presentationDeckShadow: any) => ({
	type: TOGGLE_PRESENTATION_DECK_SHADOW,
	payload: {
		presentationDeckShadow
	}
})

export const setPresentationDeckColor = (presentationDeckColor: any) => ({
	type: SET_PRESENTATION_DECK_COLOR,
	payload: {
		presentationDeckColor
	}
})

export const setContentTabColor = (contentTabColor: any) => ({
	type: SET_CONTENT_TAB_COLOR,
	payload: {
		contentTabColor
	}
})

export const setContentTabOpacity = (contentTabOpacity: any) => ({
	type: SET_CONTENT_TAB_OPACITY,
	payload: {
		contentTabOpacity
	}
})

export const setStoryBoardFont = (storyBoardFont: any) => ({
	type: SET_STORYBOARD_FONT,
	payload: {
		storyBoardFont
	}
})

export const setStoryBoardTitleFont = (storyBoardTitleFont: any) => ({
	type: SET_STORYBOARD_TITLE_FONT,
	payload: {
		storyBoardTitleFont
	}
})

export const setStoryBoardFontColor = (storyBoardFontColor: any) => ({
	type: SET_STORYBOARD_FONT_COLOR,
	payload: {
		storyBoardFontColor
	}
})

export const setPresentationDeckFontColor = (
	presentationDeckFontColor: any
) => ({
	type: SET_PRESENTATION_DECK_FONT_COLOR,
	payload: {
		presentationDeckFontColor
	}
})

export const setStoryBoardFontSize = (storyBoardFontSize: any) => ({
	type: SET_STORYBOARD_FONT_SIZE,
	payload: {
		storyBoardFontSize
	}
})

export const setTitleUnderline = (titleUnderlined: any) => ({
	type: TOGGLE_UNDERLINE_TITLE,
	payload: {
		titleUnderlined
	}
})

export const setTitleUnderlineColor = (titleUnderlineColor: any) => ({
	type: SET_TITLE_UNDERLINE_COLOR,
	payload: {
		titleUnderlineColor
	}
})

export const setNavigationStyle = (navigationStyle: any) => ({
	type: SET_NAVIGATION_STYLE,
	payload: {
		navigationStyle
	}
})

export const toggleShrinkPreviousLevel = (isPreviousLevelShrink: any) => ({
	type: TOGGLE_SHRINK_PREVIOUS_LEVEL,
	payload: {
		isPreviousLevelShrink
	}
})

export const toggleBreadcrumbsVisible = (isBreadcrumbsVisible: any) => ({
	type: TOGGLE_BREADCRUMBS,
	payload: {
		isBreadcrumbsVisible
	}
})

export const toggleLandingPage = (landingPage: any) => ({
	type: TOGGLE_LANDING_PAGE,
	payload: {
		landingPage
	}
})

export const toggleRootLevelGallery = (isRootLevelGallery: any) => ({
	type: SET_ROOT_LEVEL_GALLERY,
	payload: {
		isRootLevelGallery
	}
})

export const setLandingPageStyle = (landingPageStyle: any) => ({
	type: SET_LANDING_PAGE_STYLE,
	payload: {
		landingPageStyle
	}
})

export const setLandingPageTitle = (landingPageTitle: any) => ({
	type: SET_LANDING_PAGE_TITLE,
	payload: {
		landingPageTitle
	}
})

export const toggleStoryBoardFolderSearch = (storyboardFolderSearch: any) => ({
	type: TOGGLE_STORYBOARD_FOLDER_SEARCH,
	payload: {
		storyboardFolderSearch
	}
})

export const toggletParallaxScrollingBackground = (
	parallaxScrollingBackground: any
) => ({
	type: TOGGLE_PARALLAX_SCROLLING_BACKGROUND,
	payload: {
		parallaxScrollingBackground
	}
})

export const setLayout = (layoutObject: any) => ({
	type: SET_LAYOUT_OBJECT,
	payload: {
		layoutObject
	}
})

export const getLayout = (authToken: any) => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${appearanceEndpoint}/2`, {}, { withToken: true, authToken })
			.then((result: any) => {
				const isValidJson = isValidJSON(result)
				const isEmpty = Object.prototype.hasOwnProperty.call(
					JSON.parse(result),
					'isEmpty'
				)
				if (isValidJson && !isEmpty) {
					const layoutOptions = JSON.parse(result)
					const newLayoutSettings = { ...initialState }
					if (layoutOptions.logoImage) {
						newLayoutSettings.logoImage = {
							...layoutOptions.logoImage
						}
					}
					if (layoutOptions.background) {
						newLayoutSettings.background = {
							...layoutOptions.background
						}
					}
					if (layoutOptions.presentation) {
						newLayoutSettings.presentation = {
							...layoutOptions.presentation
						}
					}
					if (layoutOptions.content) {
						newLayoutSettings.content = {
							...layoutOptions.content
						}
					}
					if (layoutOptions.storyBoard) {
						newLayoutSettings.storyBoard = {
							...layoutOptions.storyBoard
						}
					}
					if (layoutOptions.misc) {
						newLayoutSettings.misc = {
							...layoutOptions.misc
						}
						if (layoutOptions.misc.rootLevelGalleryOptions) {
							newLayoutSettings.misc.rootLevelGalleryOptions = {
								...layoutOptions.misc.rootLevelGalleryOptions
							}
						}
					}
					dispatch(setLayout(newLayoutSettings))
					dispatch(setLayoutShallowCopy(newLayoutSettings))
				} else {
					dispatch(setLayout(initialState))
					dispatch(setLayoutShallowCopy(initialState))
				}
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an errro: ${error}`))
			})
	})

export const uploadBackroundImage =
	(backgroundImage: any, authToken: string) => (dispatch: any) =>
		new Promise((resolve, reject) => {
			const formData = new FormData()
			formData.append('file', backgroundImage)

			uploadImage(backgroundImage, authToken)
				.then((result: any) => {
					const newBackgroundImage = `${result.hash}.${result.extension}`
					dispatch(setBackgroundImage(newBackgroundImage))
					resolve(newBackgroundImage)
				})
				.catch((error) => {
					reject(error)
				})
		})

export const removeBackgroundImage = () => (dispatch: any) => {
	dispatch({
		type: SET_BACKGROUND_IMAGE,
		payload: {
			backgroundImage: false
		}
	})
}

export const setFolderBackgroundImage =
	(folderBackgroundImage: any) => (dispatch: any) => {
		dispatch({
			type: SET_FOLDER_BACKGROUND_IMAGE,
			payload: {
				folderBackgroundImage
			}
		})
	}

export const setFolderLogoImage = (folderLogoImage: any) => (dispatch: any) => {
	dispatch({
		type: SET_FOLDER_LOGO_IMAGE,
		payload: {
			folderLogoImage
		}
	})
}

export const setFolderIconImage = (folderIconImage: any) => (dispatch: any) => {
	dispatch({
		type: SET_FOLDER_ICON_IMAGE,
		payload: {
			folderIconImage
		}
	})
}

export const setRootLevelGalleryWidth =
	(rootLevelGalleryWidth: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_GALLERY_WIDTH,
			payload: {
				rootLevelGalleryWidth
			}
		})
	}

export const setRootLevelGalleryItemsPerRow =
	(itemsPerRow: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_ITEMS_PER_ROW,
			payload: {
				itemsPerRow
			}
		})
	}

export const setRootLevelPaddingBetweenIems =
	(paddingBetweenItems: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS,
			payload: {
				paddingBetweenItems
			}
		})
	}

export const setRootLevelUseFolderIcons =
	(isUseFolderIcons: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_USE_FOLDER_ICONS,
			payload: {
				isUseFolderIcons
			}
		})
	}

export const setRootLevelGalleryItemShape = (shape: any) => (dispatch: any) => {
	dispatch({
		type: SET_ROOT_LEVEL_GALLERY_SHAPE,
		payload: {
			shape
		}
	})
}

export const setRootLevelShapeShadow =
	(rootLevelShapeShadow: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_SHAPE_SHADOW,
			payload: {
				rootLevelShapeShadow
			}
		})
	}

export const setRootLevelShapeBackgroundColor =
	(shapeBackgroundColor: any) => (dispatch: any) => {
		dispatch({
			type: SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR,
			payload: {
				shapeBackgroundColor
			}
		})
	}

export const setRootLevelTextShadow = (textShadow: any) => (dispatch: any) => {
	dispatch({
		type: SET_ROOT_LEVEL_TEXT_SHADOW,
		payload: {
			textShadow
		}
	})
}

export const setStoryBoardFontWeight = (fontWeight: any) => (dispatch: any) => {
	dispatch({
		type: SET_STORYBOARD_FONT_WEIGHT,
		payload: {
			fontWeight
		}
	})
}

export const setStoryBoardTitleFontWeight =
	(fontWeight: any) => (dispatch: any) => {
		dispatch({
			type: SET_STORYBOARD_TITLE_FONT_WEIGHT,
			payload: {
				fontWeight
			}
		})
	}
