import React from 'react'

import styles from './NoContent.module.scss'
import FolderNoContentImage from './assets/folder-no-content.svg'
import { t } from '../../../../utils/languages/i18n'

const NoContent = () => {
	return (
		<div className={styles.noContentContainer}>
			<div className={styles.iconContainer}>
				<img src={FolderNoContentImage} alt="no content" />
			</div>
			<p>{t('misc.looks_like_folder_is_empty')}</p>
		</div>
	)
}

export default NoContent
