import React, { useRef } from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import {
	BUTTON_NORMAL,
	BUTTON_TEXT,
	ICON_LEFT,
	ICON_RIGHT,
	BUTTON_TYPES,
	BUTTON_DROPDOWN
} from '../../../utils/consts'
import styles from './Button.module.scss'
import { useEventListener } from '../../../utils/hooks/useEventListener'
import Spinner from '../Spinner'

/**
 *  @author zilahir
 * @function Button
 * */

const DropDownList = styled.ul`
	background: ${(props) => props.dropdownBackground};
`

const Button = (props) => {
	const {
		dropdownClass,
		label,
		onClick,
		isDisabled,
		type,
		containerClass,
		buttonClass,
		isPositive,
		icon,
		iconSide,
		iconClass,
		dropdownItems,
		isDropdownVisible,
		closeDropDown,
		dropdownBackground,
		isWarning,
		spinner,
		spinnerSize,
		isAdmin,
		buttonType,
		id,
		tooltip
	} = props

	const thisRef = useRef(null)

	/**
	 *
	 * @description clsoes the dropdown if anything else clicked within the DOM
	 */
	function handleDocumentClick() {
		if (thisRef && isDropdownVisible) {
			closeDropDown()
		}
	}
	useEventListener('click', handleDocumentClick)
	return (
		<>
			{type === BUTTON_NORMAL && (
				<div className={classnames(styles.buttonContainer, containerClass)}>
					<button
						id={id}
						className={classnames(
							buttonClass,
							styles.button,
							isPositive ? styles.positive : null,
							icon && iconSide === ICON_LEFT && styles.hasIcon,
							icon && iconSide === ICON_RIGHT && styles.hasIconRight,
							isWarning ? styles.warning : null,
							spinner && styles.spinner,
							label && styles.hasLabel,
							!label && styles.noLabel
						)}
						onClick={onClick}
						type={buttonType}
						disabled={isDisabled}
						title={tooltip}
					>
						{icon && iconSide === ICON_LEFT && (
							<div className={classnames(styles.iconContainer, iconClass)}>
								{icon}
							</div>
						)}
						{spinner && (
							<div className={styles.spinnerContainer}>
								<Spinner isLoading size={spinnerSize} />
							</div>
						)}
						{label && label}
						{icon && iconSide === ICON_RIGHT && (
							<div className={classnames(styles.iconContainer, iconClass)}>
								{icon}
							</div>
						)}
					</button>
				</div>
			)}
			{type === BUTTON_TEXT && (
				<div
					className={classnames(
						isAdmin
							? styles.textButtonContainerAdmin
							: styles.textButtonContainer,
						containerClass
					)}
				>
					<button
						type="button"
						onClick={onClick}
						disabled={isDisabled}
						id={id}
						className={classnames(
							buttonClass,
							styles.button,
							icon ? styles.hasIcon : ''
						)}
						title={tooltip}
					>
						{icon && iconSide === ICON_LEFT && (
							<div className={classnames(styles.iconContainer, iconClass)}>
								{icon}
							</div>
						)}
						{label && label}
						{icon && iconSide === ICON_RIGHT && icon}
						{dropdownItems && (
							<DropDownList
								ref={thisRef}
								dropdownBackground={dropdownBackground}
								className={classnames(
									styles.subMenu,
									dropdownClass,
									isDropdownVisible ? styles.visible : null
								)}
							>
								{dropdownItems &&
									dropdownItems.map((thisSubMenuItem, index) => (
										<li key={`item-${index.toString()}`}>{thisSubMenuItem}</li>
									))}
							</DropDownList>
						)}
					</button>
				</div>
			)}
			{type === BUTTON_DROPDOWN && (
				<div
					className={classnames(
						isAdmin
							? styles.textButtonContainerAdmin
							: styles.dropDownButtonContainer,
						containerClass
					)}
				>
					<button
						type="button"
						onClick={onClick}
						disabled={isDisabled}
						id={id}
						className={classnames(
							buttonClass,
							styles.button,
							icon ? styles.hasIcon : ''
						)}
						title={tooltip}
					>
						{icon && iconSide === ICON_LEFT && (
							<div className={classnames(styles.iconContainer, iconClass)}>
								{icon}
							</div>
						)}
						{label && label}
						{icon && iconSide === ICON_RIGHT && icon}
						{dropdownItems && (
							<DropDownList
								ref={thisRef}
								dropdownBackground={dropdownBackground}
								className={classnames(
									styles.subMenu,
									dropdownClass,
									isDropdownVisible ? styles.visible : null
								)}
							>
								{dropdownItems &&
									dropdownItems.map((thisSubMenuItem, index) => (
										<li key={`item-${index.toString()}`}>{thisSubMenuItem}</li>
									))}
							</DropDownList>
						)}
					</button>
				</div>
			)}
		</>
	)
}

Button.defaultProps = {
	buttonClass: null,
	closeDropDown: () => {},
	containerClass: null,
	dropdownBackground: 'inherit',
	dropdownClass: null,
	dropdownItems: null,
	icon: null,
	iconClass: null,
	iconSide: ICON_LEFT,
	isAdmin: false,
	isDisabled: false,
	isDropdownVisible: false,
	isPositive: false,
	isWarning: false,
	label: null,
	onClick: null,
	spinner: false,
	spinnerSize: '14px',
	type: BUTTON_NORMAL,
	buttonType: 'button',
	tooltip: ''
}

Button.propTypes = {
	buttonClass: Proptypes.string,
	closeDropDown: Proptypes.func,
	containerClass: Proptypes.string,
	dropdownBackground: Proptypes.string,
	dropdownClass: Proptypes.string,
	dropdownItems: Proptypes.arrayOf(Proptypes.node),
	icon: Proptypes.node,
	iconClass: Proptypes.string,
	iconSide: Proptypes.oneOf([ICON_LEFT, ICON_RIGHT]),
	isAdmin: Proptypes.bool,
	isDisabled: Proptypes.bool,
	isDropdownVisible: Proptypes.bool,
	isPositive: Proptypes.bool,
	isWarning: Proptypes.bool,
	label: Proptypes.string,
	onClick: Proptypes.func,
	spinner: Proptypes.bool,
	spinnerSize: Proptypes.string,
	type: Proptypes.oneOf([...BUTTON_TYPES]),
	buttonType: Proptypes.string,
	id: Proptypes.string.isRequired,
	tooltip: Proptypes.string
}

export default Button
