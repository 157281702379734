import React, { useEffect, useState } from 'react'
import { Add, Close } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import styles from './Labels.module.scss'

import Label from '../../../../components/common/Label'
import { t } from '../../../../utils/languages/i18n'
import Button from '../../../../components/common/Button'
import {
	LabelInterface,
	MeetingInterface,
	StoreInterface
} from '../../../../utils/interfaces'
import {
	connectLabelToMeeting,
	removeLabelFromMeeting
} from '../../../../api/requests/labels'
import { setMemositeValue } from '../../../../store/actions/memosite'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'

const Labels = ({
	onClose,
	createLabel,
	labelToEdit,
	role,
	selectedMemosite
}: {
	onClose: () => void
	createLabel?: () => void
	labelToEdit?: (label: LabelInterface) => void
	role: 'my_memosites' | 'memosite_builder' | 'settings_modal'
	selectedMemosite: MeetingInterface
}) => {
	const dispatch = useDispatch()

	const [adminLabels, setAdminLabels] = useState<LabelInterface[]>([])
	const [userLabels, setUserLabels] = useState<LabelInterface[]>([])
	const [memositeLabels, setMemositeLabels] = useState<LabelInterface[]>([])

	const { memosites, labels } = useSelector(
		(store: StoreInterface) => store.memosite
	)

	useEffect(() => {
		const adminLabelsArray = []
		const userLabelsArray = []
		if (labels.length > 0) {
			for (const l of labels) {
				if (l.type === 'Admin') {
					adminLabelsArray.push(l)
				} else {
					userLabelsArray.push(l)
				}
			}
			setAdminLabels(adminLabelsArray)
			setUserLabels(userLabelsArray)
			if (selectedMemosite && selectedMemosite.labels.length > 0) {
				setMemositeLabels(selectedMemosite.labels)
			}
		}
	}, [labels, selectedMemosite])

	const detectLabelAction = (label: LabelInterface, event?: any) => {
		if (event.target.tagName === 'svg' && labelToEdit) {
			labelToEdit(label)
		} else {
			connectLabelHandler(label)
		}
	}

	const connectLabelHandler = (label: LabelInterface) => {
		if (selectedMemosite) {
			const isLabelDuplicated = selectedMemosite.labels.find(
				(l) => l.id === label.id
			)
			if (!isLabelDuplicated) {
				connectLabelToMeeting({
					meeting_id: selectedMemosite.id,
					label_id: label.id
				})
					.then(() => {
						setMemositeLabels([...memositeLabels, label])
						const updatedMemositeObject = {
							...selectedMemosite,
							labels: [...selectedMemosite.labels, label]
						}
						if (role === 'memosite_builder') {
							dispatch(
								setMemositeBuilderValue(
									'publishedMemositeObject',
									updatedMemositeObject
								)
							)
						} else if (role === 'settings_modal') {
							dispatch(
								setMemositeBuilderValue(
									'settingsModalData',
									updatedMemositeObject
								)
							)
						} else {
							dispatch(
								setMemositeValue('selectedMemosite', updatedMemositeObject)
							)
							const updatedMemosites = memosites.map((item) =>
								item.id === selectedMemosite.id
									? { ...item, labels: [...selectedMemosite.labels, label] }
									: item
							)
							dispatch(setMemositeValue('memosites', updatedMemosites))
						}
					})
					.catch((e) => console.error(e))
			}
		}
	}

	const disconnectLabelHandler = (label: LabelInterface) => {
		if (selectedMemosite) {
			removeLabelFromMeeting({
				meeting_id: selectedMemosite.id,
				label_id: label.id
			})
				.then(() => {
					const updatedLabels = selectedMemosite.labels.filter(
						(l) => l.id !== label.id
					)
					setMemositeLabels(updatedLabels)
					const updatedMemositeObject = {
						...selectedMemosite,
						labels: updatedLabels
					}
					if (role === 'memosite_builder') {
						dispatch(
							setMemositeBuilderValue(
								'publishedMemositeObject',
								updatedMemositeObject
							)
						)
					} else if (role === 'settings_modal') {
						dispatch(
							setMemositeBuilderValue(
								'settingsModalData',
								updatedMemositeObject
							)
						)
					} else {
						dispatch(
							setMemositeValue('selectedMemosite', updatedMemositeObject)
						)
						const updatedMemosites = memosites.map((item) =>
							item.id === selectedMemosite.id
								? { ...item, labels: updatedLabels }
								: item
						)
						dispatch(setMemositeValue('memosites', updatedMemosites))
					}
				})
				.catch((e) => console.error(e))
		}
	}

	return (
		<div
			className={c(
				styles.wrapper,
				role === 'memosite_builder' && styles.positionBottom
			)}
		>
			<div className={styles.heading} onClick={onClose}>
				<div className={styles.title}>{t('labels.labels')}</div>
				<Close className={styles.closeIcon} />
			</div>
			<div className={styles.labels}>
				{memositeLabels.length > 0
					? memositeLabels.map((label, index) => (
							<div onClick={() => disconnectLabelHandler(label)} key={index}>
								<Label bgColor={label.color} name={label.name} />
							</div>
					  ))
					: t('labels.no-labels')}
			</div>
			<div className={c(styles.title, styles.addLabels)}>
				{t('labels.add-labels')}
			</div>
			<div className={styles.subtitle}>{t('labels.admin-created')}</div>
			<div className={styles.labels}>
				{adminLabels.length > 0 &&
					adminLabels.map((label, index) => (
						<div onClick={() => connectLabelHandler(label)} key={index}>
							<Label bgColor={label.color} name={label.name} />
						</div>
					))}
			</div>
			<div className={styles.subtitle}>{t('labels.your-labels')}</div>
			<div className={styles.labels}>
				{userLabels.length > 0 &&
					userLabels.map((label, index) => (
						<div
							onClick={(event) => detectLabelAction(label, event)}
							key={index}
						>
							<Label
								key={index}
								bgColor={label.color}
								name={label.name}
								editable
							/>
						</div>
					))}
				<div className={styles.createLabel} onClick={createLabel}>
					<Add /> {t('labels.create_new')}
				</div>
			</div>
			<div className={styles.footer}>
				<Button
					buttonClass={styles.btn}
					label={t('buttons.done')}
					isPositive
					onClick={onClose}
					id="labels-done-button"
				/>
			</div>
		</div>
	)
}

export default Labels
