import {
	flagContentSchema,
	uploadToMyFilesSchemas,
	starAContentSchema,
	addLinkSchema,
	MyFilesAddTags,
	shareCategorySchema,
	acceptSharedFolderInvitationSchema,
	updateFilename,
	updateContentSchema,
	createPdfThumbnailSchema,
	resetContentDeletionSchema
} from '../../utils/schemas/requests'
import {
	calculateUploadProgress,
	cloudFnDelete,
	cloudFnGet,
	cloudFnPatch,
	cloudFnPost,
	cloudFnPut
} from '../../utils/requests'
import { FILE, SLIDE } from '../../utils/consts'
import {
	flagsEndpoint,
	filesStarredEndpoint,
	filesStorageUploadEndpoint,
	filesUploadEndpoint,
	filesEndpoint,
	myFilesAddTagsEndpoint,
	myFilesEndpoint,
	myFilesChangeMyFolderEndpoint,
	sharedFolderInviteEndpointV2,
	shareFolderAddUserEndpointV2,
	sharedFolderDeleteEndpoint,
	sfApiRoot,
	myFilesReorderEndpoint,
	createPdfThumbnailEndpoint,
	automatedDeletionReset,
	checkIsEmbeddable
} from '../apiEndpoints_new'

export const flagContent = (contentObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			flagsEndpoint,
			contentObject,
			{ withToken: true, authToken },
			flagContentSchema
		)
			.then((result: any) => {
				resolve({
					isSuccess: true,
					...result
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const uploadContent = (
	contentObject: any,
	categoryId: any = undefined,
	handleUploadProgress: any = undefined,
	params: any = undefined
) =>
	new Promise((resolve, reject) => {
		let formData = new FormData()
		if (Array.isArray(contentObject)) {
			contentObject.forEach((item) => {
				formData.append('uploadedfiles', item)
			})
		} else {
			formData = contentObject
		}
		// @ts-ignore
		formData.append('autoConvert', true)
		// @ts-ignore
		formData.append('status', 11)
		formData.append('categoryId', categoryId)
		if (params) {
			Object.keys(params).forEach((key) => {
				formData.append(key, params[key].toString())
			})
		}
		const uploadConfig = {
			'Content-Type': 'multipart/form-data',
			onUploadProgress: (progressEvent: any) =>
				typeof handleUploadProgress === 'function' &&
				handleUploadProgress(calculateUploadProgress(progressEvent))
		}
		cloudFnPost(
			filesUploadEndpoint,
			formData,
			uploadConfig,
			uploadToMyFilesSchemas
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const starContent = (contentObject: any, authToken: any) =>
	new Promise((resolve, reject) =>
		cloudFnPost(
			filesStarredEndpoint,
			contentObject,
			{ withToken: true, authToken },
			starAContentSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	)

export const uploadToStorage = (contentObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', contentObject[0], contentObject[0].name)
		cloudFnPost(
			filesStorageUploadEndpoint,
			formData,
			{ withToken: true, authToken },
			uploadToMyFilesSchemas
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const addLink = (content: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			filesEndpoint,
			content,
			{ withToken: true, authToken },
			addLinkSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const addTags = (content: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			myFilesAddTagsEndpoint,
			content,
			{ withToken: true, authToken },
			MyFilesAddTags
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getStarredContent = (authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(filesStarredEndpoint, {}, { withToken: true, authToken })
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteMyFiles = (authToken: any, files: any) =>
	new Promise(async (resolve, reject) => {
		const fileIds = files
			.filter((item: any) => item._type === 'file')
			.map((item: any) => item.id)
		const slideIds = files
			.filter((item: any) => item._type === 'slide')
			.map((item: any) => item.id)
		let hasErrors = false
		if (fileIds.length > 0) {
			try {
				await cloudFnDelete(`${myFilesEndpoint}/files/${fileIds.join(', ')}`)
			} catch {
				hasErrors = true
			}
		}
		if (slideIds.length > 0) {
			try {
				await cloudFnDelete(`${myFilesEndpoint}/slides/${slideIds.join(', ')}`)
			} catch {
				hasErrors = true
			}
		}
		if (hasErrors) {
			reject(new Error('There was an error'))
		}
		resolve('')
	})

export const moveMyFiles = (action: any) =>
	new Promise((resolve, reject) => {
		const { files, targetCategory } = action
		const filesToMove = files.filter((file: any) => file._type === FILE)
		const slidesToMove = files.filter((file: any) => file._type === SLIDE)
		const requestPromises = []
		if (filesToMove.length > 0) {
			const fileIds = filesToMove.map((file: any) => file.id).join(',')
			requestPromises.push(
				cloudFnPatch(
					`${myFilesChangeMyFolderEndpoint}/files/${fileIds}/${targetCategory}`
				)
			)
		}
		if (slidesToMove.length > 0) {
			const fileIds = slidesToMove.map((file: any) => file.id).join(',')
			requestPromises.push(
				cloudFnPatch(
					`${myFilesChangeMyFolderEndpoint}/slides/${fileIds}/${targetCategory}`
				)
			)
		}
		Promise.all(requestPromises)
			.then(() => {
				resolve({
					moved: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMyFileSlides = (fileId: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${myFilesEndpoint}/${fileId}/slides`, {}, null)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendSharedInvitation = (folderId: any, users: any, groups: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			sharedFolderInviteEndpointV2,
			{
				folder_id: folderId,
				users,
				groups
			},
			null,
			shareCategorySchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const acceptSharedFolderInvitation = (
	userId: number,
	folderId: number,
	isDeclined?: boolean
) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			shareFolderAddUserEndpointV2,
			{
				my_folder_id: folderId,
				user_id: userId,
				decline: isDeclined
			},
			null,
			acceptSharedFolderInvitationSchema
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const removeSharedCategory = (id: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(`${sharedFolderDeleteEndpoint}/${id}`)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const patchFilesOrSlides = (content: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			`${sfApiRoot}/files/${content._type}s/${content.id}`,
			content,
			{},
			updateFilename
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updateContentReordered = (
	tagId: number,
	items: any,
	authToken: string
) =>
	new Promise((resolve, reject) => {
		const data = {
			tags_id: tagId,
			items
		}
		cloudFnPost(
			myFilesReorderEndpoint,
			data,
			{ withToken: true, authToken },
			updateContentSchema
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const createPdfThumbnail = (checksum: string) =>
	new Promise((resolve, reject) => {
		cloudFnPut(
			`${createPdfThumbnailEndpoint}/${checksum}`,
			{},
			{ withToken: true },
			createPdfThumbnailSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const resetContentDeletion = (data: { id: number; type: string }) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			automatedDeletionReset,
			data,
			{ withToken: true },
			resetContentDeletionSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const checkIfLinkIsEmbeddable = (link: string) =>
	new Promise<{ embedded: boolean }>((resolve, reject) => {
		cloudFnGet(`${checkIsEmbeddable}/${encodeURIComponent(link)}`, {}, null)
			.then((result) => {
				resolve(result as { embedded: boolean })
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
