import {
	ADD_LOADING_CARDS,
	ADD_UPLOADING_CARDS,
	SET_FOLDER_PATH,
	SET_SELECTED_CATEGORY,
	SET_SELECTED_SLIDES,
	TOGGLE_CONVERTING,
	TOGGLE_LOADING,
	TOGGLE_MINIMIZE,
	SET_SELECTED_BUILD_MENU_ITEM
} from '../actions/buildContext'
import { BUILD } from '../../utils/consts'

const initialState = {
	selectedCategory: {
		id: null,
		categoryName: ''
	},
	selectedSlides: [],
	isLoading: false,
	numOfLoadingCards: 0,
	numOfUploadingCards: 0,
	folderPath: [],
	isMinimized: false,
	isConverting: false,
	selectedBuildMenuItem: BUILD
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_SELECTED_CATEGORY:
			return {
				...state,
				selectedCategory: action.payload.selectedCategory
			}
		case SET_SELECTED_SLIDES:
			return {
				...state,
				selectedSlides: action.payload.selectedSlides
			}
		case TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload.isLoading
			}
		case TOGGLE_MINIMIZE:
			return {
				...state,
				isMinimized: action.payload.isMinimized
			}
		case TOGGLE_CONVERTING:
			return {
				...state,
				isConverting: action.payload.isConverting
			}
		case ADD_LOADING_CARDS:
			return {
				...state,
				numOfLoadingCards: action.payload.numOfLoadingCards
			}
		case ADD_UPLOADING_CARDS:
			return {
				...state,
				numOfUploadingCards: action.payload.numOfUploadingCards
			}
		case SET_FOLDER_PATH:
			return {
				...state,
				folderPath: action.payload.folderPath
			}
		case SET_SELECTED_BUILD_MENU_ITEM:
			return {
				...state,
				selectedBuildMenuItem: action.payload.selectedBuildMenuItem
			}
		default:
			return state
	}
}

export default reducer
