import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import authUser from './reducers/authUser'
import misc from './reducers/misc'
import feed from './reducers/fetchFeed'
import tags from './reducers/tags'
import content from './reducers/content'
import myfiles from './reducers/myfiles'
import presentation from './reducers/presentation'
import users from './reducers/users'
import layout from './reducers/layout'
import temp from './reducers/temp'
import buildContext from './reducers/buildContext'
import storyboardContext from './reducers/storyBoardContext'
import analytics from './reducers/analytics'
import memosite from './reducers/memosite'
import fileManager from './reducers/fileManager'
import customSlides from './reducers/customSlides'
import memositeBuilder from './reducers/memositeBuilder'
import myPresentations from './reducers/myPresentations'

const rootReducer = combineReducers({
	authUser,
	misc,
	feed,
	fileManager,
	tags,
	content,
	myfiles,
	presentation,
	temp,
	buildContext,
	analytics,
	storyboardContext,
	users,
	layout,
	memosite,
	customSlides,
	memositeBuilder,
	myPresentations
})

const middleWareList: any = [thunk]
if (process.env.NODE_ENV === 'development') {
	// middleWareList.push(logger)
}
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(...middleWareList))
)
