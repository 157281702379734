import {
	RESET_MEMOSITE_BUILDER_REDUCER,
	SET_MEMOSITE_BUILDER_VALUE
} from '../actions/actionTypes'

const initialState = {
	status: 'closed',
	settingsModalVisible: false,
	settingsModalData: undefined,
	publishedMemositeObject: undefined,
	tempSlides: false
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_MEMOSITE_BUILDER_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case RESET_MEMOSITE_BUILDER_REDUCER:
			return initialState
		default:
			return state
	}
}

export default reducer
