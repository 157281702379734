import React from 'react'
import classnames from 'classnames'

import styles from './Results.module.scss'

interface ResultsInterface {
	customer: any
	isSelected: boolean
	setSelectedCustomer: (customer: any) => void
}

const Results = ({
	customer,
	isSelected,
	setSelectedCustomer
}: ResultsInterface) => {
	return (
		<div
			className={classnames(styles.customerItem, isSelected && styles.selected)}
			onClick={() => {
				setSelectedCustomer(isSelected ? undefined : customer)
			}}
		>
			<div>
				<p className={styles.feedItemText}>
					<strong>{customer.AccountName}</strong>
				</p>
			</div>
		</div>
	)
}

export default Results
