import getMimeTypes from './getMimeTypes'
import { filesStreamingEndpoint, sfApiRoot } from '../../api/apiEndpoints_new'

type Type = 'thumbnail' | 'original'
interface VideoUrlInterface {
	fileType: string
	fileUrl: string
}

export const buildFileUrl = (
	checksum: string,
	type: Type,
	authToken: string
) => {
	let fileUrl: string = ''
	if (type === 'original') {
		fileUrl = `${sfApiRoot}/files/assets/files/${checksum}?oauth=${encodeURIComponent(
			authToken
		)}`
	} else if (type === 'thumbnail') {
		fileUrl = `${sfApiRoot}/files/assets/thumbnails/${checksum}?oauth=${encodeURIComponent(
			authToken
		)}`
	}
	return fileUrl
}

export const getPreviewVideoUrl = (
	checksum: string,
	extension: string,
	authToken: string
): VideoUrlInterface => {
	const fileType = getMimeTypes(extension)
	return {
		fileType,
		fileUrl: `${filesStreamingEndpoint}/${checksum}?oauth=${encodeURIComponent(
			authToken
		)}`
	}
}
