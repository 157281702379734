import { nanoid } from 'nanoid'

import { getStarredContent } from '../../api/requests/content'
import { fetchContentByTagId } from '../../api/requests/tags'
import { SET_CONTENT_FOR_TAG, CLEAR_CONTENT, SET_STARRED_CONTENT } from './actionTypes'
import { toggleLoading } from './buildContext'


export const setContentForTag = (content: any) => (dispatch: any) => new Promise(resolve => {
	dispatch({
		type: SET_CONTENT_FOR_TAG,
		payload: {
			content,
		},
	})
	// @ts-ignore
	resolve()
})

export const getContentByTagId = (tagId: any, authToken: any) => (dispatch: any) => new Promise(
	resolve => {
		fetchContentByTagId(tagId, authToken)
			.then((response: any) => {
				dispatch(setContentForTag(
					response.map((item: any) => ({
						...item,
						key: nanoid(),
					})),
				))
				resolve(response)
				dispatch(toggleLoading(false))
			})
	},
)

export const clearContent = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_CONTENT,
		payload: null,
	})
}

export const setStarredContent = (starredContent: any) => (dispatch: any) => new Promise(resolve => {
	dispatch({
		type: SET_STARRED_CONTENT,
		payload: {
			starredContent,
		},
	})
	// @ts-ignore
	resolve()
})

export const getAllStarredContent = (authToken: any) => (dispatch: any) => new Promise((resolve, reject) => {
	getStarredContent(authToken)
		.then((result: any) => {
			dispatch(setStarredContent(
				result.map((item: any) => ({
					...item,
					key: nanoid(),
				})),
			))
			resolve(result)
		})
		.catch(error => {
			reject(new Error(`There was an error: ${error}`))
		})
})
