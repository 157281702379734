import React from 'react'
import { useSelector } from 'react-redux'

import Background from '../common/Background'
import PresentationBar from '../PresentationBar'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import bgImage from '../../assets/images/default-background.jpg'
import Sidebar from '../Sidebar'

enum ComponentInterface {
	BACKGROUND,
	PRESENTATION_BAR,
	SIDEBAR
}

const PageIncludes = () => {
	const { currentModule } = useSelector((store: StoreInterface) => store.temp)

	/**
	 * @description generates an object with conditionally rendered props
	 * that should be passed to <Background /> component
	 */
	const bgProps = {
		...((currentModule === ModuleNamesInterface.FOLLOW_UP ||
			currentModule === ModuleNamesInterface.BUILD ||
			currentModule === ModuleNamesInterface.LIBRARY ||
			currentModule === ModuleNamesInterface.MY_FILES ||
			currentModule === ModuleNamesInterface.STARRED ||
			currentModule === ModuleNamesInterface.USER_SETTINGS ||
			currentModule === ModuleNamesInterface.MY_PRESENTATIONS) && {
			blurDeviation: 10
		}),
		...(currentModule === ModuleNamesInterface.SIGNUP && {
			inheritedBackgroundImage: bgImage
		})
	}

	const shouldRenderComponent = (componentName: ComponentInterface) => {
		if (
			componentName === ComponentInterface.BACKGROUND &&
			(currentModule === ModuleNamesInterface.FOLLOW_UP ||
				currentModule === ModuleNamesInterface.BUILD ||
				currentModule === ModuleNamesInterface.LIBRARY ||
				currentModule === ModuleNamesInterface.MY_FILES ||
				currentModule === ModuleNamesInterface.STARRED ||
				currentModule === ModuleNamesInterface.USER_SETTINGS ||
				currentModule === ModuleNamesInterface.MY_PRESENTATIONS)
		) {
			return <Background {...bgProps} />
		}
		if (
			componentName === ComponentInterface.PRESENTATION_BAR &&
			(currentModule === ModuleNamesInterface.FOLLOW_UP ||
				currentModule === ModuleNamesInterface.BUILD ||
				currentModule === ModuleNamesInterface.LIBRARY ||
				currentModule === ModuleNamesInterface.MY_FILES ||
				currentModule === ModuleNamesInterface.STORYBOARD ||
				currentModule === ModuleNamesInterface.STARRED ||
				currentModule === ModuleNamesInterface.USER_SETTINGS ||
				currentModule === ModuleNamesInterface.MY_PRESENTATIONS)
		) {
			return <PresentationBar />
		}
		if (
			componentName === ComponentInterface.SIDEBAR &&
			(currentModule === ModuleNamesInterface.STORYBOARD ||
				currentModule === ModuleNamesInterface.FOLLOW_UP ||
				currentModule === ModuleNamesInterface.BUILD ||
				currentModule === ModuleNamesInterface.USER_SETTINGS ||
				currentModule === ModuleNamesInterface.MY_FILES ||
				currentModule === ModuleNamesInterface.STARRED ||
				currentModule === ModuleNamesInterface.LIBRARY ||
				currentModule === ModuleNamesInterface.MY_PRESENTATIONS)
		) {
			return <Sidebar />
		}
	}

	return (
		<React.Fragment>
			{shouldRenderComponent(ComponentInterface.BACKGROUND)}
			{shouldRenderComponent(ComponentInterface.PRESENTATION_BAR)}
			{shouldRenderComponent(ComponentInterface.SIDEBAR)}
		</React.Fragment>
	)
}

export default PageIncludes
