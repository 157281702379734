/**
 * @param bytes
 * @param decimals
 */
const formatBytes = (bytes: number, decimals: number | undefined) => {
	if (bytes === 0) {
		return '0 Byte'
	}
	const k = 1024
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${
		sizes[i]
	}`
}

export default formatBytes
