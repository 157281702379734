import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import * as Sentry from '@sentry/react'
import './styles/helpers/grid.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/configureStore'
import { CustomFontProvider } from './pages/Layout/components/FontPicker'
import Errors from './pages/Errors'
import './utils/helpers/global'

Sentry.init({
	dsn: `https://${process.env.REACT_APP_SENTRY_PUBLIC_KEY}@o4505051505688576.ingest.sentry.io/${process.env.REACT_APP_SENTRY_PROJECT_ID}`,
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', 'https:salesfra.me']
		}),
		new Sentry.Replay()
	],
	// Performance Monitoring
	tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 1, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate:
		process.env.REACT_APP_ENV === 'production' ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
			>
				<BrowserRouter>
					<CustomFontProvider>
						<Sentry.ErrorBoundary fallback={<Errors />}>
							<App />
						</Sentry.ErrorBoundary>
					</CustomFontProvider>
				</BrowserRouter>
			</GoogleReCaptchaProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
