import React, { useCallback } from 'react'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'

import {
	VideocamOutlined as RecordIcon,
	FileUploadOutlined as UploadIcon
} from '@mui/icons-material'

import styles from './AddVideoGreetingModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import Modal from '../../../common/ModalNew'

interface AddVideoGreetingModalInterface {
	action: (type: 'record' | 'upload', files?: [File]) => void
	handleClose: () => void
}

const AddVideoGreetingModal = ({
	action,
	handleClose
}: AddVideoGreetingModalInterface) => {
	const onDrop = useCallback((droppedFiles) => {
		if (droppedFiles.length > 0) {
			action('upload', droppedFiles)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		accept: ['.webm', '.mp4']
	})

	return (
		<Modal
			isShowing
			hide={handleClose}
			customClassName={classnames(styles.modalWrapper)}
			modalTitle={t('titles.add_video_greeting')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={handleClose}
						id="add-video-greeting-modal-cancel"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<div className={styles.block}>
					<div className={styles.thumb} onClick={() => action('record')}>
						<RecordIcon />
						<p>{t('misc.record_a_video_greeting')}</p>
					</div>
					<div className={styles.text}>
						{t('misc.record_a_video_to_introduce')}
					</div>
				</div>
				<div className={styles.block} {...getRootProps()}>
					<input {...getInputProps()} />
					<div className={styles.thumb} onClick={() => action('upload')}>
						<UploadIcon />
						<p>{t('misc.upload_a_video_file')}</p>
					</div>
					<div className={styles.text}>
						{t('misc.upload_a_previously_recorded_video')}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default AddVideoGreetingModal
