export const TEST = 'TEST'
export const AUTH_USER = 'AUTH_USER'
export const SET_STRUCTURE = 'SET_STRUCTURE'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_FEEDS = 'SET_FEEDS'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_BG_BLUR = 'SET_BG_BLUR'
export const CHANGE_MODULE_TIMESTAMP = 'CHANGE_MODULE_TIMESTAMP'
export const SET_BG_IMAGE = 'SET_BG_IMAGE'
export const SET_DESCENDANTS = 'SET_DESCENDANTS'
export const SET_CURRENT_TAG_TREE = 'SET_CURRENT_TAG_TREE'
export const SET_FOLDER_FILES_PATH = 'SET_FOLDER_FILES_PATH'
export const SET_TREE_VIEW = 'SET_TREE_VIEW'
export const OPEN = 'OPEN'
export const CLOSE = 'CLOSE'
export const SET_CONTENT_FOR_TAG = 'SET_CONTENT_FOR_TAG'
export const SET_MY_FILES_CATEGORIES = 'SET_MY_FILES_CATEGORIES'
export const ADD_TO_PRESENTATION = 'ADD_TO_PRESENTATION'
export const CLEAR_PRESENTATION = 'CLEAR_PRESENTATION'
export const DELETE_FROM_PRESENTATION = 'DELETE_FROM_PRESENTATION'
export const DELETE_BY_CHECKSUM = 'DELETE_BY_CHECKSUM'
export const DUPLICATE_BY_ID = 'DUPLICATE_BY_ID'
export const REMOVE_BY_INDEX = 'REMOVE_BY_INDEX'
export const SET_MY_FILES = 'SET_MY_FILES'
export const CLEAR_CONTENT = 'CLEAR_CONTENT'
export const SET_STARRED_CONTENT = 'SET_STARRED_CONTENT'
export const REMOVE_ALL_LOADING_THUMBNAILS = 'REMOVE_ALL_LOADING_THUMBNAILS'
export const SET_SLIDESHOW_NAMED_VALUE = 'SET_SLIDESHOW_NAMED_VALUE'
export const SET_USERS = 'SET_USERS'
export const SET_USER_GROUPS = 'SET_USER_GROUPS'
export const CLEAR_MY_FILES = 'CLEAR_MY_FILES'
export const ADD_TO_COLOR_PRESET = 'ADD_TO_COLOR_PRESET'
export const ADD_TO_PRESENTATION_BAR = 'ADD_TO_PRESENTATION_BAR'
export const CREATE_SLIDESHOW = 'CREATE_SLIDESHOW'
export const CLEAR_SLIDESHOW = 'CLEAR_SLIDESHOW'
export const SET_LOGO_IMAGE = 'SET_LOGO_IMAGE'
export const CLEAR_LOGO_IMAGE = 'CLEAR_LOGO_IMAGE'
export const CHANGE_LOGO_POSITION = 'CHANGE_LOGO_POSITION'
export const SET_LOGO_SIZE = 'SET_LOGO_SIZE'
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR'
export const SET_BACKGROUND_OPACITY = 'SET_BACKGROUND_OPACITY'
export const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE'
export const TOGGLE_PRESENTATION_DECK_SHADOW = 'TOGGLE_PRESENTATION_DECK_SHADOW'
export const SET_PRESENTATION_DECK_COLOR = 'SET_PRESENTATION_DECK_COLOR'
export const SET_CONTENT_TAB_COLOR = 'SET_CONTENT_TAB_COLOR'
export const SET_CONTENT_TAB_OPACITY = 'SET_CONTENT_TAB_OPACITY'
export const SET_STORYBOARD_FONT_COLOR = 'SET_STORYBOARD_FONT_COLOR'
export const SET_STORYBOARD_TITLE_FONT = 'SET_STORYBOARD_TITLE_FONT'
export const SET_STORYBOARD_FONT = 'SET_STORYBOARD_FONT'
export const SET_PRESENTATION_DECK_FONT_COLOR =
	'SET_PRESENTATION_DECK_FONT_COLOR'
export const SET_STORYBOARD_FONT_SIZE = 'SET_STORYBOARD_FONT_SIZE'
export const TOGGLE_UNDERLINE_TITLE = 'TOGGLE_UNDERLINE_TITLE'
export const SET_TITLE_UNDERLINE_COLOR = 'SET_TITLE_UNDERLINE_COLOR'
export const SET_NAVIGATION_STYLE = 'SET_NAVIGATION_STYLE'
export const TOGGLE_SHRINK_PREVIOUS_LEVEL = 'TOGGLE_SHRINK_PREVIOUS_LEVEL'
export const TOGGLE_BREADCRUMBS = 'TOGGLE_BREADCRUMBS'
export const TOGGLE_LANDING_PAGE = 'TOGGLE_LANDING_PAGE'
export const SET_LANDING_PAGE_LAYUOT = 'SET_LANDING_PAGE_LAYUOT'
export const SET_LANDING_PAGE_TITLE = 'SET_LANDING_PAGE_TITLE'
export const TOGGLE_STORYBOARD_FOLDER_SEARCH = 'TOGGLE_STORYBOARD_FOLDER_SEARCH'
export const TOGGLE_PARALLAX_SCROLLING_BACKGROUND =
	'TOGGLE_PARALLAX_SCROLLING_BACKGROUND'
export const SET_LANDING_PAGE_STYLE = 'SET_LANDING_PAGE_STYLE'
export const COPY_LAYOUT_OBJECT = 'COPY_LAYOUT_OBJECT'
export const SET_LAYOUT_OBJECT = 'GET_LAYOUT_OBJECT'
export const SET_FOLDER_BACKGROUND_IMAGE = 'SET_FOLDER_BACKGROUND_IMAGE'
export const SET_FOLDER_LOGO_IMAGE = 'SET_FOLDER_LOGO_IMAGE'
export const SET_FOLDER_ICON_IMAGE = 'SET_FOLDER_ICON_IMAGE'
export const SET_ALL_SHORTCUTS = 'SET_ALL_SHORTCUTS'
export const ADD_TO_TEMP_PRESENTATION = 'ADD_TO_TEMP_PRESENTATION'
export const CLEAR_TEMP_REDUCER = 'CLEAR_TEMP_REDUCER'
export const CLEAR_TEMP_PRESENTATION = 'CLEAR_TEMP_PRESENTATION'
export const SET_LOADED_PRESENTATION = 'SET_LOADED_PRESENTATION'
export const SET_THUMBNAIL_SIZE = 'SET_THUMBNAIL_SIZE'
export const SET_PRESENTATION_BAR_SIZE = 'SET_PRESENTATION_BAR_SIZE'
export const REORDER_PRESENTATION_BAR_SLIDES = 'REORDER_PRESENTATION_BAR_SLIDES'
export const ADD_TO_ANALYTICS_BATCH = 'ADD_TO_ANALYTICS_BATCH'
export const REMOVE_FROM_ANALYTICS_BATCH = 'REMOVE_FROM_ANALYTICS_BATCH'
export const CLEAR_ANALYTICS_BATCH = 'CLEAR_ANALYTICS_BATCH'
export const SET_TEMP_SHORTCUTS = 'SET_TEMP_SHORTCUTS'
export const SET_LAYOUT_SHALLOW_COPY = 'SET_LAYOUT_SHALLOW_COPY'
export const SET_GALLERY_SHALLOW_COPY = 'SET_GALLERY_SHALLOW_COPY'
export const CHANGE_AUTH_OBJECT = 'CHANGE_AUTH_OBJECT'
export const SET_INSTANCES = 'SET_INSTANCES'
export const REMOVE_USER = 'REMOVE_USER'
export const THROW_CRITICAL_ERROR = 'THROW_CRITICAL_ERROR'
export const RESOLVE_CRITICAL_ERROR = 'RESOLVE_CRITICAL_ERROR'
export const SET_ROOT_LEVEL_GALLERY = 'SET_ROOT_LEVEL_GALLERY'
export const SET_ROOT_LEVEL_GALLERY_WIDTH = 'SET_ROOT_LEVEL_GALLERY_WIDTH'
export const INIT_ROOT_LEVEL_GALLLERY_OPTIONS =
	'INIT_ROOT_LEVEL_GALLLERY_OPTIONS'
export const SET_ROOT_LEVEL_ITEMS_PER_ROW = 'SET_ROOT_LEVEL_ITEMS_PER_ROW'
export const SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS =
	'SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS'
export const SET_ROOT_LEVEL_GALLERY_SHAPE = 'SET_ROOT_LEVEL_GALLERY_SHAPE'
export const SET_ROOT_LEVEL_SHAPE_SHADOW = 'SET_ROOT_LEVEL_SHAPE_SHADOW'
export const SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR =
	'SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR'
export const SET_ROOT_LEVEL_TEXT_SHADOW = 'SET_ROOT_LEVEL_TEXT_SHADOW'
export const SET_ROOT_LEVEL_USE_FOLDER_ICONS = 'SET_ROOT_LEVEL_USE_FOLDER_ICONS'
export const SET_STORYBOARD_FONT_WEIGHT = 'SET_STORYBOARD_FONT_WEIGHT'
export const SET_STORYBOARD_TITLE_FONT_WEIGHT =
	'SET_STORYBOARD_TITLE_FONT_WEIGHT'
export const SET_TEMP_VALUE = 'SET_TEMP_VALUE'
export const SET_MEMOSITE_VALUE = 'SET_MEMOSITE_VALUE'
export const SET_MEMOSITE_BUILDER_VALUE = 'SET_MEMOSITE_BUILDER_VALUE'
export const SET_TAGS_NAMED_VALUE = 'SET_TAGS_NAMED_VALUE'
export const SET_FEEDS_NAMED_VALUE = 'SET_FEEDS_NAMED_VALUE'
export const SET_FOLDERS_CUSTOM_SLIDES = 'SET_FOLDERS_CUSTOM_SLIDES'
export const SET_TEMPLATES_CUSTOM_SLIDES = 'SET_TEMPLATES_CUSTOM_SLIDES'
export const SET_TREE_DATA = 'SET_TREE_DATA'
export const RESET_MEMOSITE_REDUCER = 'RESET_MEMOSITE_REDUCER'
export const ADD_FILE_TO_MY_FILES = 'ADD_FILE_TO_MY_FILES'
export const RESET_MEMOSITE_BUILDER_REDUCER = 'RESET_MEMOSITE_BUILDER_REDUCER'
export const SET_LOADED_PRESENTATION_DIRTY = 'SET_LOADED_PRESENTATION_DIRTY'
export const SET_PRESENTATION_BAR_SLIDES = 'SET_PRESENTATION_BAR_SLIDES'
export const CREATE_FOLDER_MODAL = 'CREATE_FOLDER_MODAL'
export const SET_MY_PRESENTATIONS_VALUE = 'SET_MY_PRESENTATIONS_VALUE'
export const RESET_MY_PRESENTATIONS_REDUCER = 'RESET_MY_PRESENTATIONS_REDUCER'
