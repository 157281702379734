import slugify from 'slugify'

import { t } from '../languages/i18n'
import { FolderInterface } from '../interfaces'

type CategoryT = {
	id: number
	code: string
	name: string
}

export const getAddedFilesCategoryId = (
	categoryList: CategoryT[]
): CategoryT | undefined => {
	return categoryList.find(
		(thisCategory) => thisCategory.code === 'system_added'
	)
}

export const getVideoGreetingsCategoryId = (
	categorylist: CategoryT[] | undefined
): number | undefined =>
	Array.isArray(categorylist)
		? categorylist.find(
				(category: CategoryT) => category.code === 'video_greetings'
		  )?.id
		: 1

export const sortMyFileCategories = (
	categoryList: FolderInterface[]
): FolderInterface[] => {
	let result: any[]
	const systemCategoryCodes = [
		'system_added',
		'system_custom',
		'video_greetings',
		'system_edited'
	]
	const invitedCategoriesPendingApproval = categoryList.filter(
		(curr) => curr.code.includes('system_shared') && curr.pending === 1
	)
	const systemCategories = categoryList
		.filter((curr) => systemCategoryCodes.includes(curr.code))
		.map((category) => ({
			...category,
			name: t(`labels.my-files-${slugify(category.name).toLowerCase()}`)
		}))
	const otherSystemCategories = categoryList.filter(
		(curr) =>
			curr.code.includes('system') &&
			!curr.code.includes('system_shared') &&
			!systemCategoryCodes.includes(curr.code)
	)
	const sharedCategories = categoryList.filter((curr) =>
		curr.code.includes('system_shared')
	)
	const allOtherCategories = categoryList.filter(
		(curr) =>
			!systemCategories.some((r) => curr.id === r.id) &&
			!otherSystemCategories.some((r) => curr.id === r.id) &&
			!sharedCategories.some((r) => curr.id === r.id)
	)
	result = [
		...invitedCategoriesPendingApproval,
		...systemCategories,
		...otherSystemCategories,
		...sharedCategories.filter((curr) => curr.pending !== 1),
		...allOtherCategories
	]
	return result
}
