import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import md5 from 'js-md5'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'

import styles from './AddLinkModal.module.scss'
import { t } from '../../utils/languages/i18n'
import Modal from '../common/ModalNew'
import Button from '../common/Button'
import { MY_FILES, PRESENTATION_BAR } from '../../utils/consts'
import Input from '../common/Input'
import {
	addLink,
	addTags,
	checkIfLinkIsEmbeddable
} from '../../api/requests/content'
import { setMyFilesContent } from '../../store/actions/myfiles'
import { getAddedFilesCategoryId } from '../../utils/helpers/myFiles'
import { addLinkInputSchema } from '../../utils/schemas/requests'
import { addToPresentation } from '../../store/actions/presentation'

const AddLinkModal = ({
	isShowing,
	handleClose,
	status = 1,
	role,
	categoryId = 1
}: any) => {
	const dispatch = useDispatch()
	const [addLinkData, setAddLinkData] = useState({
		title: '',
		link: ''
	})
	const [linkIsNotEmbeddable, toggleLinkIsNotEmbeddable] = useState(false)

	const [disabledButton, setDisabledButton] = useState(false)
	const authToken = useSelector((store: any) => store.authUser.user.token)
	const myFileContent = useSelector((state: any) => state.myfiles.myFiles)
	const buildContext = useSelector((state: any) => state.buildContext)
	const myFilesCategories = useSelector(
		(state: any) => state.myfiles.myFilesCategories
	)
	const {
		register,
		trigger,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: joiResolver(addLinkInputSchema)
	})

	/**
	 * @param {string} name the name of the link
	 * @param {string} value the value of the field
	 * @description change values for input fields
	 */
	const changeInputValue = (name: string, value: string) => {
		setValue(name, value)
		setAddLinkData({
			...addLinkData,
			[name]: value
		})
	}

	/**
	 * @description validates the input given in parameter
	 * @param {string} field the name of the field
	 */
	function validate(field: string) {
		trigger([field]).then((result) => {
			if (result === false) {
				// we have an error
				return true
			}
			return false
		})
	}

	/**
	 * @description saves the link
	 * sends notificaiton
	 * closes the modal
	 *
	 */
	function handleSaveLink() {
		const linkData = {
			title: addLinkData.link,
			name: addLinkData.title,
			type: 'url',
			checksum: md5(
				`${addLinkData.link} ${addLinkData.title} ${new Date().valueOf()}`
			),
			status,
			size: 0,
			category_id: categoryId,
			tags: []
		}
		trigger(['title', 'link']).then((isFormValid) => {
			if (isFormValid) {
				// now we can call the API
				setDisabledButton(true)
				checkIfLinkIsEmbeddable(addLinkData.link).then((response) => {
					if (response.embedded) {
						addLink(linkData, authToken).then((result: any) => {
							setDisabledButton(false)
							setAddLinkData({
								title: '',
								link: ''
							})
							addTags(
								{
									files_id: result.id,
									my_folder_id:
										buildContext.selectedCategory.id ||
										getAddedFilesCategoryId(myFilesCategories)?.id,
									type: 'file',
									_type: 'link'
								},
								authToken
							)
							toast(t('notifications.success.link-added'), {
								position: toast.POSITION.BOTTOM_RIGHT,
								type: 'success',
								autoClose: 5000
							})
							if (role === MY_FILES) {
								dispatch(setMyFilesContent(myFileContent.concat(result)))
							} else if (role === PRESENTATION_BAR) {
								dispatch(addToPresentation([result]))
							}
							toggleLinkIsNotEmbeddable(false)
							handleClose()
						})
					} else {
						toggleLinkIsNotEmbeddable(true)
					}
					setDisabledButton(false)
				})
			}
		})
	}

	return (
		<>
			<Modal
				isShowing={isShowing}
				hide={handleClose}
				customClassName={styles.shareModal}
				modalTitle={t('labels.add-link')}
				zIndex={10001}
				footer={
					<div className={styles.footer}>
						<div>
							<Button
								buttonClass={styles.btn}
								label={t('labels.cancel')}
								onClick={handleClose}
								id="add-link-modal-cancel"
							/>
						</div>
						<div>
							<Button
								buttonClass={styles.btn}
								label={t('labels.add-link')}
								isPositive
								isDisabled={
									addLinkData.link === '' ||
									addLinkData.title === '' ||
									disabledButton
								}
								onClick={() => handleSaveLink()}
								id="add-link-modal-save"
							/>
						</div>
					</div>
				}
			>
				<div className={styles.modalContent}>
					<p>{t('misc.add-link-modal-content')}</p>
					<Input
						placeholderText={t('labels.title')}
						name="title"
						initialValue={addLinkData.title}
						inputClassName={styles.input}
						onChange={(event) =>
							changeInputValue(event.target.id, event.target.value)
						}
						onBlur={() => validate('title')}
						wrapperClassName={styles.inputWrapper}
						refProp={register('title')}
						label={errors && errors.title && t('input-labels.title-required')}
						state={errors && errors.title && styles.formError}
					/>
					<Input
						placeholderText={t('labels.link-url')}
						name="link"
						initialValue={addLinkData.link}
						onChange={(event) =>
							changeInputValue(event.target.id, event.target.value)
						}
						wrapperClassName={styles.inputWrapper}
						refProp={register('link')}
						onBlur={() => validate('link')}
						label={
							errors && errors.link && t(`input-labels.${errors.link.type}`)
						}
						state={errors && errors.link && styles.formError}
					/>
					{linkIsNotEmbeddable && (
						<div className={styles.error}>{t('misc.link-not-embeddable')}</div>
					)}
				</div>
			</Modal>
		</>
	)
}

export default AddLinkModal
