import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import {
	CloudDoneOutlined,
	DeleteOutlined,
	Edit,
	GridView,
	LockOutlined,
	ViewListOutlined,
	Close,
	ContentCopy,
	OpenInBrowser,
	Share,
	PeopleOutlined
} from '@mui/icons-material'
import c from 'classnames'

import styles from './ExtendedPresentationModal.module.scss'

import Modal from '../../../../components/common/ModalNew'
import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import Button from '../../../../components/common/Button'
import {
	BUTTON_NORMAL,
	BUTTON_TEXT,
	GRID,
	ICON_LEFT,
	LIST,
	MY_MEMOSITES_
} from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'
import SlidesContainer from '../PresentationItem/SlidesContainer'
import BigGreenButton from '../../../../components/PresentationBar/components/BigGreenButton'
import { clearTempPresentation } from '../../../../store/actions/temp'
import { setMyPresentationsValue } from '../../../../store/actions/myPresentations'

interface ExtendedModalInterface {
	presentation: MeetingInterface
	presentationToDelete: (id: number) => void
	presentationToDuplicate: (presentation: MeetingInterface) => void
	openPresentationAsMemosite: (presentation: MeetingInterface) => void
	presentationToLoad: (presentation: MeetingInterface) => void
	shareWithColleague: (presentation: MeetingInterface) => void
	updatePresentationSubject: (id: number, subject: string) => void
}

const ExtendedPresentationModal = ({
	presentation,
	presentationToDelete,
	presentationToDuplicate,
	openPresentationAsMemosite,
	presentationToLoad,
	shareWithColleague,
	updatePresentationSubject
}: ExtendedModalInterface) => {
	const dispatch = useDispatch()

	const [presentationTitleUpdating, togglePresentationTitleUpdating] =
		React.useState(false)

	const { selectedPresentation, view } = useSelector(
		(store: StoreInterface) => store.myPresentations
	)
	const { user } = useSelector((store: StoreInterface) => store.authUser)

	const subjectUpdate = async (newSubject: string) => {
		togglePresentationTitleUpdating(true)
		updatePresentationSubject(presentation.id, newSubject)
	}

	const closeModalHandler = () => {
		dispatch(setMyPresentationsValue('selectedPresentation', undefined))
		dispatch(clearTempPresentation(MY_MEMOSITES_))
	}

	return (
		<Modal
			modalClassName={styles.topLevelModalWrapper}
			customClassName={styles.wrapper}
			headerClassname={styles.header}
			disableOverlayClose={true}
			hasCloseIcon={false}
			hide={closeModalHandler}
			isShowing={selectedPresentation !== undefined}
			zIndex={10000}
			before={
				<div className={styles.closeIcon}>
					<Close htmlColor="#FFFFFF" onClick={closeModalHandler} />
				</div>
			}
			after={
				<BigGreenButton role={MY_MEMOSITES_.toLowerCase()} zIndex={100011} />
			}
			top="100px"
			bottom="unset"
		>
			<div className={styles.container}>
				<div className={styles.titleRow}>
					{user.user.id === presentation.creator ? (
						<div className={styles.inputWrapper}>
							<input
								type="text"
								defaultValue={presentation.subject}
								onBlur={(e) => {
									if (!presentationTitleUpdating) {
										subjectUpdate(e.target.value)
											.then(() => {
												togglePresentationTitleUpdating(false)
												toast(
													t('notifications.success.presentation_name_updated'),
													{
														position: toast.POSITION.BOTTOM_RIGHT,
														type: toast.TYPE.SUCCESS,
														autoClose: 5000
													}
												)
											})
											.catch(() => {
												toast(t('notifications.error.something_went_wrong'), {
													position: toast.POSITION.BOTTOM_RIGHT,
													type: toast.TYPE.ERROR,
													autoClose: 5000
												})
											})
									}
								}}
								onKeyDown={(e: any) => {
									if (e.key === 'Enter')
										subjectUpdate(e.target.value).then(() => {
											e.target.blur()
											togglePresentationTitleUpdating(false)
										})
								}}
								id="extended-presentation-modal-subject-input"
							/>
							<Edit />
						</div>
					) : (
						<div className={styles.subjectNonEditable}>
							{presentation.subject}
						</div>
					)}
					<div className={styles.icons}>
						{presentation.is_secured && (
							<LockOutlined
								htmlColor="#88b0ff"
								titleAccess={t('tooltips.password_protected')}
							/>
						)}
						{presentation.crm && presentation.crm.status === 1 && (
							<CloudDoneOutlined
								htmlColor="#88b0ff"
								titleAccess={t('tooltips.sent_to_crm')}
							/>
						)}
					</div>
					<div className={styles.labels}>
						<div className={styles.slides}>
							{t('labels.slides')}
							<div className={styles.value}>{presentation.numberOfSlides}</div>
						</div>
						<div className={styles.dateCreated}>
							{t('labels.date-created')}
							<div className={styles.value}>
								{format(new Date(presentation.created_at), 'dd / MM / yyyy')}
							</div>
						</div>
						<div className={styles.lastOpened}>
							{t('labels.last-edited')}
							<div className={styles.value}>
								<React.Fragment>
									<p className={styles.lastOpenedDate}>
										{format(
											new Date(presentation.updated_at.replace(/\s/g, 'T')),
											'dd / MM / yyyy'
										)}
									</p>
								</React.Fragment>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.actionsRow}>
					<div className={styles.buttons}>
						<Button
							type={BUTTON_NORMAL}
							buttonClass={c(styles.button, styles.openButton)}
							iconSide={ICON_LEFT}
							icon={<OpenInBrowser htmlColor="#FFFFFF" />}
							label={t('labels.open-presentation')}
							onClick={() => {
								presentationToLoad(presentation)
								closeModalHandler()
							}}
							id="extended-presentation-modal-duplicate-presentation"
						/>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<Share htmlColor="#FFFFFF" />}
							label={t('buttons.create_memosite')}
							onClick={() => {
								openPresentationAsMemosite(presentation)
								closeModalHandler()
							}}
							id="extended-presentation-modal-duplicate-presentation"
						/>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<PeopleOutlined htmlColor="#FFFFFF" />}
							label={t('buttons.send_to_colleagues')}
							onClick={() => {
								shareWithColleague(presentation)
								closeModalHandler()
							}}
							id="extended-presentation-modal-duplicate-presentation"
						/>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<ContentCopy htmlColor="#FFFFFF" />}
							label={t('labels.duplicate')}
							onClick={() => {
								presentationToDuplicate(presentation)
								closeModalHandler()
							}}
							id="extended-presentation-modal-duplicate-presentation"
						/>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<DeleteOutlined htmlColor="#FFFFFF" />}
							label={t('labels.delete')}
							onClick={() => presentationToDelete(presentation.id)}
							id="extended-presentation-modal-delete-presentation"
						/>
					</div>
				</div>
				<div className={styles.contentRow}>
					<div className={styles.heading}>
						<div className={styles.labels}>
							<div className={styles.dFlex}>
								<div className={styles.sectionLabel}>{t('titles.content')}</div>
							</div>
							{view === GRID && (
								<div className={styles.slidesCount}>{`${
									presentation.extended_fields_object?.summary &&
									presentation.extended_fields_object?.summary.length
								} ${t('labels.slides')}`}</div>
							)}
						</div>
						<div className={styles.buttons}>
							{view === LIST ? (
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<GridView htmlColor="#FFFFFF" />}
									label={t('labels.grid-view')}
									onClick={() =>
										dispatch(setMyPresentationsValue('view', 'grid'))
									}
									id="extended-presentation-modal-grid-view"
								/>
							) : (
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<ViewListOutlined htmlColor="#FFFFFF" />}
									label={t('labels.list-view')}
									onClick={() =>
										dispatch(setMyPresentationsValue('view', 'list'))
									}
									id="extended-presentation-modal-list-view"
								/>
							)}
						</div>
					</div>
					<div>
						<SlidesContainer
							slides={presentation.extended_fields_object?.summary || []}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ExtendedPresentationModal
