import React from 'react'
import { useLocation } from 'react-router-dom'
import {
	PlayCircleOutlined as PlayIcon,
	LaunchOutlined as LaunchIcon
} from '@mui/icons-material/'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import styles from './Folder.module.scss'

import { SelectedTagInterface } from './interfaces'
import { StoreInterface, TagInterface } from '../../../../utils/interfaces'
import { convertBusinessRules } from '../../../../utils/convertBusinessRules'
import getEmbedUrl from '../../../../utils/helpers/getEmbedUrl'
import { t } from '../../../../utils/languages/i18n'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'
import { fetchContentByTagId } from '../../../../api/requests/tags'
import { createSlideShow } from '../../../../store/actions/presentation'

interface FolderInterface {
	tag: TagInterface
	name: string
	selectedEmbeddedTag: (tag: SelectedTagInterface) => void
	selectedTag: (tag: TagInterface) => void
}

const Folder = ({
	tag,
	name,
	selectedEmbeddedTag,
	selectedTag
}: FolderInterface) => {
	const dispatch = useDispatch()
	const location: any = useLocation()
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const refreshToken = useSelector(
		(store: StoreInterface) => store.authUser.user.refresh_token
	)

	const businessRules = convertBusinessRules(tag.business_rules)

	const folderContainerClicked = (tagName: string) => {
		if (tagName.toLowerCase() !== 'svg') {
			selectedTag(tag)
		}
	}

	const embeddedTagHandler = () => {
		const newTag = {
			id: tag.id,
			name: tag.name,
			embeddedUrl: getEmbedUrl(
				location,
				businessRules,
				refreshToken,
				authToken
			),
			target: businessRules.target || false
		}
		selectedEmbeddedTag(newTag)
	}

	const autoplayTagHandler = () => {
		fetchContentByTagId(tag.tag_id, authToken).then((result) => {
			const onlyNotHidden = result.filter((file) => file.visibility === 1)
			dispatch(
				createSlideShow({
					slides: onlyNotHidden,
					startFrom: 0
				})
			)
		})
	}

	return (
		<div
			className={styles.folderContainer}
			onClick={(event: any) => folderContainerClicked(event.target.tagName)}
			title={tag.path.map((path, index) => path.name).join(' / ')}
		>
			<div className={styles.folderShape}>
				{tag.material_number && tag.material_number > 0 ? (
					<div className={styles.numberOfFiles}>{`${tag.material_number} ${t(
						'labels.files'
					)}`}</div>
				) : null}
				<div className={styles.folderActions}>
					{businessRules.autoplay && (
						<PlayIcon onClick={() => autoplayTagHandler()} />
					)}
					{businessRules.embeddedUrl && (
						<LaunchIcon onClick={embeddedTagHandler} />
					)}
				</div>
				{businessRules && businessRules.folderIconImage && (
					<div
						className={c(
							styles.folderIcon,
							!businessRules.autoplay &&
								!businessRules.embeddedUrl &&
								styles.positioned
						)}
					>
						<img
							src={`${sfApiRoot}/files/assets/files/${
								businessRules.folderIconImage
							}?oauth=${encodeURIComponent(authToken)}`}
							alt=""
						/>
					</div>
				)}
			</div>
			<div className={styles.folderName}>{name}</div>
		</div>
	)
}

export default Folder
