/**
 * @description splits the backgroundImage URI to checksum
 * Returns string or boolean
 * this provides backward compatibiity to krypton
 * @param {string} bgImageUrl the url of the background image
 * @returns {?string} string | boolean splitted checksum of the backgroun imaage URI
 */
export function splitBgImageUrl(bgImageUrl: string):string | boolean {
	if (bgImageUrl) {
		return bgImageUrl.split('/')[bgImageUrl.split('/').length - 1]
	}
	return false
}
