import React from 'react'
import PropTypes from 'prop-types'

import { TreeRecursive } from './RecursiveTree'
import styles from './Tree.module.scss'
import classNames from 'classnames'

/**
 * @author zilahir
 * @function Tree
 * */

const Tree = ({
	autoplayTag,
	data,
	children,
	onClick,
	folderPath,
	isSidebarCollapsed,
	selectedTagToEmbed
}) => {
	const isImparative = data && !children

	return (
		<div
			className={classNames(
				styles.treeView,
				!isSidebarCollapsed ? styles.hidden : ''
			)}
		>
			{isImparative ? (
				<TreeRecursive
					autoplayTag={autoplayTag}
					folderPath={folderPath}
					onClick={onClick}
					data={data}
					selectedTagToEmbed={selectedTagToEmbed}
				/>
			) : (
				children
			)}
		</div>
	)
}

Tree.defaultProps = {
	children: undefined,
	folderPath: []
}

Tree.propTypes = {
	autoplayTag: PropTypes.func.isRequired,
	children: PropTypes.objectOf(PropTypes.any),
	data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
	folderPath: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	isSidebarCollapsed: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	selectedTagToEmbed: PropTypes.func.isRequired
}

export default Tree
