import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MeetingInterface, StoreInterface } from '../../utils/interfaces'
import CRMDynamicsOne from './CRMDynamicsOne'
import CRMDynamicsRU from './CRMDynamicsRU'
import CRMSalesforcePihlajalinna from './CRMSalesforcePihlajalinna'
import CRMLikeit from './CRMLikeit'
import CRMModal from './CRMModal'
import CrmConfirmationModal from '../CrmConfirmationModal'
import { throwCriticalError } from '../common/CriticalError'
import { saveCRM } from '../../api/requests/crm'
import { setMemositeValue } from '../../store/actions/memosite'
import { getMemositeLink } from '../../utils/helpers/memosites'
import { t } from '../../utils/languages/i18n'
import CRMSecto from './CRMSecto'
import CRMSalesforceAava from './CRMSalesforceAava'

interface CRMIntegrationInterface {
	memosite: MeetingInterface
	setMemosite: (value: MeetingInterface | null) => void
}

const CRMIntegration = ({ memosite, setMemosite }: CRMIntegrationInterface) => {
	const dispatch = useDispatch()

	const { crmSettings, instanceName } = useSelector(
		(store: StoreInterface) => store.temp
	)
	const { memosites } = useSelector((store: StoreInterface) => store.memosite)
	const { user } = useSelector((store: StoreInterface) => store.authUser)

	const [crmData, setCrmData] = React.useState<any>(null)
	const [isCrmConfirmationOpen, setIsCrmConfirmationOpen] =
		React.useState(false)

	/**
	 * @description handle crm input change
	 * @param {string} name of the field
	 * @param {string} value which is set
	 */
	const crmDataChangeHandler = (name: any, value: any) => {
		const newCrmData = crmData || {}
		setCrmData({
			...newCrmData,
			[name]: value
		})
	}

	/**
	 * render CRM components
	 */
	const renderCrmComponents = () => {
		if (crmSettings.id === 'dynamics') {
			if (crmSettings.customer === 'terveystalo') {
				return (
					<CRMDynamicsOne
						memosite={memosite}
						handleClose={() => setMemosite(null)}
						saveButtonDisabled={false}
						zIndex={10001}
					/>
				)
			} else if (crmSettings.customer === 'royal') {
				return (
					<CRMDynamicsRU
						memosite={memosite}
						handleClose={() => setMemosite(null)}
						saveButtonDisabled={false}
						zIndex={10001}
					/>
				)
			}
		}
		if (crmSettings.id === 'salesforce') {
			if (crmSettings.customer === 'pihlajalinna') {
				return (
					<CRMSalesforcePihlajalinna
						memosite={memosite}
						handleClose={() => setMemosite(null)}
						saveButtonDisabled={false}
						zIndex={10001}
					/>
				)
			} else if (crmSettings.customer === 'secto') {
				return (
					<CRMSecto
						handleClose={() => setMemosite(null)}
						memosite={memosite}
						saveButtonDisabled={false}
						zIndex={10001}
					/>
				)
			} else if (crmSettings.customer === 'aava') {
				return (
					<CRMSalesforceAava
						handleClose={() => setMemosite(null)}
						memosite={memosite}
						saveButtonDisabled={false}
						zIndex={10001}
					/>
				)
			}
		}
		if (crmSettings.id === 'likeit') {
			return (
				<CRMLikeit
					memosite={memosite}
					handleClose={() => setMemosite(null)}
					saveButtonDisabled={false}
					zIndex={10001}
				/>
			)
		}
		if (crmSettings.id === 'pipedrive') {
			if (
				crmSettings.customer === 'gptw' ||
				crmSettings.customer === 'salesframe'
			) {
				return (
					<CRMModal
						memosite={memosite}
						handleClose={() => setMemosite(null)}
						saveButtonDisabled={false}
						handleSave={() => saveMemositeCrm()}
						handleChange={crmDataChangeHandler}
						crmData={crmData}
						zIndex={10001}
					/>
				)
			}
		}
	}

	/**
	 * @description setup new memosite array after status is updated
	 * @param {number} memositeId selected memosite id
	 * @param {object} crm object returned after changing status
	 */
	const updateMemositeCrm = (memositeId: number, crm: any) => {
		const newMemosites = [...memosites].map((item) => {
			if (item.id === memositeId) {
				return {
					...item,
					crm
				}
			}
			return item
		})
		dispatch(setMemositeValue('memosites', newMemosites))
	}

	/**
	 * @description update memosite CRM status
	 */
	const saveMemositeCrm = () => {
		if (memosite) {
			try {
				if (crmSettings.id === 'elo' && !isCrmConfirmationOpen) {
					setIsCrmConfirmationOpen(true)
					return
				}
			} catch {
				return
			}
			let data = null
			let path = null
			if (crmSettings.id === 'pipedrive') {
				const memoUrl = getMemositeLink(
					instanceName,
					memosite.slug,
					user.user.role,
					true
				)
				path = crmSettings.saveData
				data = {
					content: `<h4>A memosite was created in Salesframe</h4><h4>By user ${
						user.user.firstname
					} ${
						user.user.lastname
					}</h4><h4>${new Date().toDateString()}</h4><br /><a href='${memoUrl}${
						window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
					}analytics=false'>${memoUrl}${
						window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
					}analytics=false</a>`,
					deal_id: Number.parseInt(crmData.selectedDeal.id, 10),
					user_id: user.user.id,
					meeting_id: memosite.id
				}
			}
			if (crmSettings.id === 'elo') {
				path = crmSettings.saveDoc
				data = {
					extended_fields: memosite.extended_fields,
					notes: memosite.notes,
					document: crmData.caseValue,
					businessid: crmData.customerId,
					meeting_id: memosite.id
				}
			}
			if (data && path) {
				setIsCrmConfirmationOpen(false)
				setCrmData(null)
				setMemosite(null)
				saveCRM(data, path)
					.then((response: any) => {
						try {
							updateMemositeCrm(response.body.meeting_id, response.body)
						} catch {
							console.error('No meeting id')
						}
					})
					.catch(() => {
						dispatch(
							throwCriticalError(t('notifications.error.save-crm-error'))
						)
					})
			}
		}
	}

	return (
		<div id="crm-integration">
			{crmSettings && renderCrmComponents()}
			<CrmConfirmationModal
				isActive={isCrmConfirmationOpen}
				handleClose={() => setIsCrmConfirmationOpen(false)}
				onSubmit={saveMemositeCrm}
				crmData={crmData}
			/>
		</div>
	)
}

export default CRMIntegration
