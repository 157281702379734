import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import hexToRgba from 'hex-to-rgba'
import { useLocation } from 'react-router-dom'

import styles from './Background.module.scss'
import defaultBgImage from '../../../assets/images/default-background.jpg'
import { sfApiRoot } from '../../../api/apiEndpoints_new'
import { StoreInterface } from '../../../utils/interfaces'

const BackgroundContainer = styled.div<{
	zIndex: number
	backgroundColor: string
}>`
	z-index: ${(props) => props.zIndex};
	background-color: ${(props) => props.backgroundColor};
`

const BackgroundImage = styled.img<{ opacity: string; blur: string }>`
	opacity: ${(props) => props.opacity}%;
	filter: ${(props) => props.blur};
`

interface BackgroundInterface {
	blurDeviation?: number
	inheritedBackgroundImage?: string | boolean
	skipLocationCheck?: boolean
	zIndex?: number
}

const Background = ({
	blurDeviation = 0,
	inheritedBackgroundImage,
	skipLocationCheck = false,
	zIndex = -1
}: BackgroundInterface) => {
	const layoutSettings = useSelector((store: StoreInterface) => store.layout)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const location = useLocation()

	const bgImage =
		inheritedBackgroundImage || layoutSettings.background.backgroundImage

	/**
	 * @description generates background image url regarding presence in
	 * redux store.
	 */
	const getBgImage = () => {
		if (bgImage)
			return `${sfApiRoot}/files/assets/files/${bgImage}?oauth=${encodeURIComponent(
				authToken
			)}`
		return defaultBgImage
	}

	const backgroundOverlayHandler = () => {
		if (skipLocationCheck) {
			return <div className={styles.grayBackground} />
		} else {
			if (
				!location.pathname.includes('/present') &&
				!location.pathname.includes('build')
			) {
				return <div className={styles.grayBackground} />
			} else if (location.pathname.includes('build')) {
				return <div className={styles.gradientBackground} />
			}
		}
	}

	return (
		<BackgroundContainer
			zIndex={zIndex}
			backgroundColor={hexToRgba(layoutSettings.background.backgroundColor, 1)}
			className={styles.backgroundContainer}
		>
			<BackgroundImage
				src={getBgImage()}
				opacity={layoutSettings.background.backgroundImageOpacity}
				blur={blurDeviation ? `blur(${blurDeviation}px)` : 'none'}
				className={styles.image}
			/>
			{backgroundOverlayHandler()}
		</BackgroundContainer>
	)
}

export default Background
