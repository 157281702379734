import React, { useContext } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import objectScan from 'object-scan'
import { forEach } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import styles from './FixedTagList.module.scss'
import { BREADCRUMB, FIXED_TAGLIST_TYPES, LAYOUT, SHORTCUT, STORYBOARD, TAGLIST_BREADCRUMBS } from '../../../../utils/consts'
import { usePositionReorder } from '../../../../utils/hooks/usePositionReorder'
import Tag from './components/Tag'
import { convertBusinessRules, isTagVisible } from '../../../../utils/convertBusinessRules'
import { modifyFolderOptions } from '../../../../api/requests/layout'
import { getAllShortcuts } from '../../../../store/actions/tags'
import TopHeaderContext from '../../context/topHeaderContext'

/**
 * @author zilahir
 * @function Breadcrumbs
 * */


const FixedTagList = ({
	tags, type, handleTagClick, role, isDraggingEnabled, noHiddenTags,
}) => {
	const tagTree = useSelector(store => store.tags.tagTree)
	const currentUserGroup = useSelector(store => store.authUser.user.user.userGroupId)
	const [order, updatePosition, updateOrder] = usePositionReorder(tags)
	const dispatch = useDispatch()
	const { isSearchExpanded } = useContext(TopHeaderContext)

	const isSmallScreen = useMediaQuery({
		query: '(max-width: 576px)',
	})

	const shouldCloseOnSearch = () => isSearchExpanded && isSmallScreen

	/**
	 *
	 * @description calls the API with the ancestors of the tag and fires the handleTagLick function
	 * with the result
	 * @param {object} clickedTag the object representation of the clicked shortcut tag
	 * @param {number} index the undex of the clicked tag
	 */
	function getAncestor(clickedTag, index) {
		if (role === SHORTCUT) {
			const tagPathResult = []
			// TODO: move this to it's own function
			const find = (data, id) => objectScan(['**(^children$).tag_id'], {
				abort: true,
				rtn: 'parent',
				useArraySelector: false,
				filterFn: ({ value }) => value === id,
			})(data)
			const thisTag = find(tagTree, clickedTag.tag_id)
			thisTag.path.map(tag => tagPathResult.push(find(tagTree, tag.tag_id)))
			handleTagClick(tagPathResult)
			return
		}
		handleTagClick(clickedTag, index)
	}

	/**
	 * @description handles the reorder
	 * creates a new array of the tag with the new order Number of the tag
	 * then dispatches the reorder functin whcih calles the API with each tag
	 */
	function handleReorder() {
		const promiseArray = []
		forEach(order, (tag, index) => {
			const businessRules = {
				...convertBusinessRules(tag.business_rules),
				breadCrumbOrder: index + 1,
			}
			promiseArray.push(modifyFolderOptions(
				businessRules,
				'business_rules',
				tag.id,
			))
		})
		Promise.all(promiseArray).then(() => {
			dispatch(getAllShortcuts())
		})
	}

	/**
	 * @description helper function
	 * calles the convertbusinessRules function with the current tag's
	 * stringified business_rules and the currentUser grous
	 * decides whether thsi tag is visible at the moment
	 * returns the boolean
	 * @param {string} businessRules the current tags stringified businessrules
	 * @returns {boolean} isVisible the boolean indicator whether this specific tag is visible
	 * at the moment
	 */
	const isVisible = businessRules => (
		isTagVisible(
			convertBusinessRules(businessRules), currentUserGroup, STORYBOARD,
		)
	)
	return (
		order.length > 0 && !shouldCloseOnSearch() && (
			<ul
				className={classnames(
					styles.breadcrumbs,
					styles[role.toLowerCase()],
				)}
			>
				<AnimatePresence>
					{
						order.map((tag, index) => (
							(isVisible(tag.business_rules) || (noHiddenTags === LAYOUT)) && (
								<Tag
									className={classnames(
										styles.breadcrumb,
									)}
									noHiddenTags={!isVisible(tag.business_rules)}
									handleDragEnd={() => handleReorder()}
									key={tag.id}
									index={index}
									tag={tag}
									updateOrder={updateOrder}
									updatePosition={updatePosition}
									handleTagClick={() => getAncestor(tag, index)}
									type={type}
									isDraggingEnabled={isDraggingEnabled}
								/>
							)
						))
					}
				</AnimatePresence>
			</ul>
		)
	)
}

FixedTagList.defaultProps = {
	handleTagClick: () => {},
	isDraggingEnabled: false,
	noHiddenTags: false,
	type: TAGLIST_BREADCRUMBS,
}

FixedTagList.propTypes = {
	handleTagClick: PropTypes.func,
	isDraggingEnabled: PropTypes.bool,
	noHiddenTags: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	role: PropTypes.oneOf([
		BREADCRUMB, SHORTCUT,
	]).isRequired,
	tags: PropTypes.arrayOf(
		PropTypes.objectOf(
			PropTypes.any,
		),
	).isRequired,
	type: PropTypes.oneOf([
		...FIXED_TAGLIST_TYPES,
	]),
}

export default FixedTagList
