/* eslint-disable jsx-a11y/autocomplete-valid */ // for a reason
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './Input.module.scss'
import { TEXT, PASSWORD, DEFAULT, INPUT_STATES } from '../../../utils/consts'

/**
 * @author zilahir
 * @function Input
 * */

const Input = (props) => {
	const {
		label,
		onChange,
		wrapperClassName,
		initialValue,
		inputClassName,
		type,
		name,
		keyDownEvent,
		state,
		placeholderText,
		isDisabled,
		button,
		onFocus,
		onBlur,
		refProp,
		size,
		autoFill,
		id
	} = props
	return (
		<div className={classnames(styles.inputWrapper, wrapperClassName)}>
			{button && button}
			<label className={classnames(styles.label, state)} htmlFor={name}>
				{label}
				<input
					className={classnames(
						inputClassName,
						state,
						isDisabled ? styles.disabled : ''
					)}
					ref={refProp}
					autoComplete={autoFill || `new-${name}`}
					disabled={isDisabled}
					id={id || name}
					type={type}
					onChange={onChange}
					onKeyDown={(e) => (keyDownEvent ? keyDownEvent(e.key) : null)}
					value={initialValue}
					placeholder={placeholderText}
					onFocus={onFocus}
					onBlur={onBlur}
					size={size}
					name={name}
				/>
			</label>
		</div>
	)
}

Input.defaultProps = {
	autoFill: null,
	button: null,
	initialValue: '',
	inputClassName: null,
	isDisabled: false,
	keyDownEvent: null,
	label: null,
	onBlur: () => {},
	onChange: () => {},
	onFocus: () => {},
	placeholderText: null,
	refProp: null,
	size: null,
	state: DEFAULT.toLowerCase(),
	type: TEXT.toLowerCase(),
	wrapperClassName: null
}

Input.propTypes = {
	autoFill: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	button: PropTypes.node,
	initialValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Date)
	]),
	inputClassName: PropTypes.string,
	isDisabled: PropTypes.bool,
	keyDownEvent: PropTypes.func,
	label: PropTypes.oneOfType([
		PropTypes.objectOf(PropTypes.any),
		PropTypes.string
	]),
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	placeholderText: PropTypes.string,
	refProp: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	size: PropTypes.number,
	state: PropTypes.oneOf([...INPUT_STATES]),
	type: PropTypes.oneOf([PASSWORD.toLowerCase(), TEXT.toLowerCase()]),
	wrapperClassName: PropTypes.string,
	id: PropTypes.string
}

export default Input
