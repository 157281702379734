import { useRef, useEffect } from 'react'

import { SM } from '../consts'

/**
 * @description custom react hooks that turns scrolling horizontlly
 * @param {string} horizontallScrollRef either SM OR LG
 * represents the current size if the presentation bar
 * @returns {Function} a callback cleanup function that removes the eventlistener
 */
export function useHorizontalScroll(horizontallScrollRef: any) {
	const elRef = useRef<any>()
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const el: any = elRef.current
		if (el && horizontallScrollRef === SM) {
			const onWheel = (e: any) => {
				e.preventDefault()
				el.scrollTo({
					left: el.scrollLeft + e.deltaY
				})
			}
			el.addEventListener('wheel', onWheel)
			return () => el.removeEventListener('wheel', onWheel)
		}
	}, [horizontallScrollRef])
	return elRef
}

export function useVerticalScroll() {
	const elRef: any = useRef<any>()
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const el = elRef.current
		if (el) {
			const onWheel = (e: any) => {
				e.preventDefault()
				el.scrollTo({
					top: el.scrollTop + e.deltaX
				})
			}
			el.addEventListener('wheel', onWheel)
			return () => el.removeEventListener('wheel', onWheel)
		}
	}, [])
	return elRef
}
