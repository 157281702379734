import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import { useSelector } from 'react-redux'
import { has } from 'lodash'

import { axiosInstance } from '../../../../utils/requests'
import styles from './FontPicker.module.scss'
import { defaultDropdownStyles } from '../../../../components/common/Dropdown/utils/styles'
import { t } from '../../../../utils/languages/i18n'
import { FONT_WEIGHTS } from '../../../../utils/consts'
import Dropdown from '../../../../components/common/Dropdown'
import { getCustomerFonts } from '../../../../api/requests/customerFonts'

const dropdownStyles = {
	...defaultDropdownStyles,
	valueContainer: (provided) => ({
		...defaultDropdownStyles.valueContainer(provided),
		paddingLeft: 35
	}),
	menu: (provided) => ({
		...defaultDropdownStyles.menu(provided),
		zIndex: 999
	})
}

const FontPicker = ({ onChange, selected }) => {
	const [fontList, setFontList] = useState([])
	useEffect(() => {
		axiosInstance
			.get(
				`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
			)
			.then((response) => {
				const newFontList = []
				getCustomerFonts().then((customerFonts) => {
					if (customerFonts.length > 0) {
						newFontList.push({
							label: 'Custom',
							options: customerFonts.map((item) => ({
								label: item.name,
								value: item.name,
								category: 'sans-serif',
								family: item.name,
								kind: 'webfonts#webfont',
								version: 'v1',
								files: {
									regular: item.path
								},
								variants: ['regular']
							}))
						})
					}
					const googleFonts = {
						label: 'Google',
						options: response.data.items.map((font) => ({
							...font,
							value: font.family,
							label: font.family
						}))
					}
					newFontList.push(googleFonts)
					setFontList([...newFontList])
				})
			})
	}, [])

	return fontList.length > 0 ? (
		<div className={styles.fontPickerContainer}>
			<Dropdown
				styles={dropdownStyles}
				listItems={fontList}
				placeholder={t('labels.search')}
				onChangeCallback={(chosen) => chosen && onChange(chosen)}
				defaultIndex={fontList.findIndex(
					(font) => font.value === selected.family
				)}
			/>
		</div>
	) : (
		<div />
	)
}

FontPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.shape({
		family: PropTypes.string
	}).isRequired
}

export default FontPicker

export const CustomFontProvider = ({ children }) => {
	const storyBoardFont = useSelector(
		(store) => store.layout.storyBoard.storyBoardFont
	)
	const storyBoardTitleFont = useSelector(
		(store) => store.layout.storyBoard.storyBoardTitleFont
	)
	const getData = () => {
		const data = []
		if (has(storyBoardFont, 'files')) {
			Object.keys(storyBoardFont.files)
				.filter((variant) =>
					Object.keys(FONT_WEIGHTS).some((weight) => weight === variant)
				)
				.forEach((key) => {
					data.push({
						url: storyBoardFont.files[key],
						name: storyBoardFont.family,
						weight: key
					})
				})
		}
		if (has(storyBoardTitleFont, 'files')) {
			Object.keys(storyBoardTitleFont.files)
				.filter((variant) =>
					Object.keys(FONT_WEIGHTS).some((weight) => weight === variant)
				)
				.forEach((key) => {
					data.push({
						url: storyBoardTitleFont.files[key],
						name: storyBoardTitleFont.family,
						weight: key
					})
				})
		}
		return data
	}

	const fontList = () => {
		const data = getData()
		if (data) {
			return data
				.map(
					({ name, url, weight }) =>
						`
						@font-face {
							font-family: "${name}";
							src: url("${url.startsWith('https://') ? url : url.replace('http', 'https')}");
							font-weight: ${weight}
						}
					`
				)
				.join(' ')
		}
		return ''
	}

	const GlobalStyle = createGlobalStyle`
		${fontList}
	`
	return (
		<React.Fragment>
			<GlobalStyle />
			{children}
		</React.Fragment>
	)
}

CustomFontProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
}
