import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { useSelector, useDispatch } from 'react-redux'
import objectScan from 'object-scan'

import { tagItemAnimationVarians } from '../../utils/animation'
import styles from './Tag.module.scss'
import { defaultFontSize } from '../../../../../../utils/layoutSettings'
import {
	convertBusinessRules,
	isTagVisible
} from '../../../../../../utils/convertBusinessRules'
import { createSlideShow } from '../../../../../../store/actions/presentation'
import { fetchContentByTagId } from '../../../../../../api/requests/tags'
import { LAYOUT, STORYBOARD } from '../../../../../../utils/consts'

const Label = styled.span`
	color: ${(props) => props.storyboardFontColor};
	font-size: ${(props) => props.fontSize}vw;
	font-weight: ${(props) => props.fontWeight};
`

const Tag = ({
	tag,
	handleTagClick,
	depth = 0,
	isShrinked,
	isActive,
	noHiddenTag,
	index,
	isDraggingEnabled,
	updateOrder,
	onSortEnd,
	selectedMenus
}) => {
	const { name, children = [] } = tag
	const tagId = tag.tag_id
	const tagTree = useSelector((store) => store.tags.tagTree)
	const [isDragging, setDragging] = useState(false)

	/**
	 *
	 *
	 * @description this is a helper function to find a specific tag in the structure
	 * @param {Array} data the entire tagTree structure
	 * @param {number} id the id of the tag to find
	 * @returns {object} the found tag
	 * // TODO: move this to its own function there are other occureances too
	 */
	const find = (data, id) =>
		objectScan(['**(^children$).tag_id'], {
			abort: true,
			rtn: 'parent',
			useArraySelector: false,
			filterFn: ({ value }) => value === id
		})(data)

	const foundTag = find(tagTree, tagId)

	const layoutSettings = useSelector((state) => state.layout)
	const tagBusinessRules = foundTag
		? convertBusinessRules(foundTag.business_rules)
		: undefined
	const authToken = useSelector((store) => store.authUser.user.token)
	const dispatch = useDispatch()
	const currentUserGroup = useSelector(
		(store) => store.authUser.user.user.userGroupId
	)
	const shortCutsList = useSelector((store) => store.tags.shortcuts)

	/**
	 *
	 * @description calculates the font size
	 * @returns {number} the calculated font size
	 */
	const fontSize = () =>
		isShrinked
			? Number.parseFloat(defaultFontSize / 100) *
			  layoutSettings.storyBoard.storyBoardFontSize *
			  0.5
			: Number.parseFloat(defaultFontSize / 100) *
			  layoutSettings.storyBoard.storyBoardFontSize

	/**
	 *
	 * @description checks whether the current tag is a shortcut
	 * @returns {boolean} true | false
	 */
	const isShortCut = () =>
		shortCutsList.some((shortcut) => shortcut.tag_id === tagId)

	/**
	 *
	 * @description handles autoplay on the selected tag
	 * @param {object} clickedTag the object representation of the tag which has autoplay on
	 */
	function handleAutoPlay(clickedTag) {
		// fetch content belongs to this tag
		fetchContentByTagId(clickedTag.tag_id, authToken).then((result) => {
			const onlyNotHidden = result.filter((file) => file.visibility === 1)
			dispatch(
				createSlideShow({
					slides: onlyNotHidden,
					startFrom: 0
				})
			)
		})
	}

	/**
	 *
	 * @description sets the dragging to false
	 * and calls the proided callback
	 */
	function handleDragEnd() {
		setDragging(false)
		if (typeof onSortEnd === 'function') {
			onSortEnd()
		}
	}

	/**
	 *
	 * @param {number | string} fontWeight the chosen fontWeight from Layout
	 * @description converts the regular font-weight to it's number representation
	 * @returns {number} fontWeight retruns the fontweight's number representation
	 */
	const getFontWeight = (fontWeight) =>
		fontWeight === 'regular' ? 400 : fontWeight

	return (
		<React.Fragment>
			{tagBusinessRules &&
				(isTagVisible(tagBusinessRules, currentUserGroup, STORYBOARD) ||
					noHiddenTag === LAYOUT) &&
				!isShortCut(tag) && (
					<li
						id={tag.id}
						custom={index}
						variants={tagItemAnimationVarians}
						initial="hidden"
						animate="visible"
						exit="exit"
						layout
						drag={isDraggingEnabled ? 'y' : false}
						onDragStart={() => setDragging(true)}
						onDragEnd={() => handleDragEnd()}
						onViewportBoxUpdate={(_viewportBox, delta) => {
							const y = delta.y.translate
							const x = delta.x.translate
							isDragging && updateOrder(index, { x, y })
						}}
					>
						<Label
							onClick={() => !isDragging && handleTagClick(tag, depth)}
							className={classnames(
								styles.label,
								isShrinked ? styles.shrinked : '',
								isActive ? styles.activeTag : '',
								!isTagVisible(tagBusinessRules, currentUserGroup, STORYBOARD)
									? styles.hiddenTag
									: ''
							)}
							storyboardFontColor={hexToRgba(
								layoutSettings.storyBoard.storyBoardFontColor,
								1
							)}
							fontSize={fontSize()}
							fontWeight={getFontWeight(
								layoutSettings.storyBoard.storyBoardFontWeight
							)}
						>
							{tagBusinessRules.autoplay && (
								<button
									type="button"
									onKeyDown={null}
									tabIndex={-1}
									onClick={() => handleAutoPlay(tag)}
									className={styles.autoPlayBtn}
								>
									<PlayCircleOutlineIcon
										htmlColor={hexToRgba(
											layoutSettings.storyBoard.storyBoardFontColor,
											1
										)}
									/>
								</button>
							)}
							{name}
							{children.length > 0 && (
								<ArrowForwardIosIcon
									htmlColor={hexToRgba(
										layoutSettings.storyBoard.storyBoardFontColor,
										1
									)}
								/>
							)}
						</Label>
					</li>
				)}
		</React.Fragment>
	)
}

Tag.defaultProps = {
	depth: 0,
	isActive: false,
	isDraggingEnabled: false,
	isShrinked: false,
	noHiddenTag: false,
	onSortEnd: undefined,
	updateOrder: () => {},
	updatePosition: () => {}
}

Tag.propTypes = {
	depth: PropTypes.number,
	handleTagClick: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	isActive: PropTypes.bool,
	isDraggingEnabled: PropTypes.bool,
	isShrinked: PropTypes.bool,
	noHiddenTag: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	onSortEnd: PropTypes.func,
	selectedMenus: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
		.isRequired,
	tag: PropTypes.objectOf(PropTypes.any).isRequired,
	updateOrder: PropTypes.func,
	updatePosition: PropTypes.func
}

export default Tag
