import React from 'react'
import {
	HomeOutlined,
	AccountCircleOutlined,
	ArticleOutlined
} from '@mui/icons-material'

import styles from './CardStatic.module.scss'

import { t } from '../../../../utils/languages/i18n'

interface CardStaticInterface {
	time: string
	type: 'cover' | 'profile' | 'greeting'
}

const CardStatic = ({ time, type }: CardStaticInterface) => {
	const decideIconHandler = () => {
		switch (type) {
			case 'cover':
				return <HomeOutlined className={styles.icon} />
			case 'profile':
				return <AccountCircleOutlined className={styles.icon} />
			case 'greeting':
				return <ArticleOutlined className={styles.icon} />
		}
	}

	const decideLabelHandler = () => {
		switch (type) {
			case 'cover':
				return t('labels.cover-page')
			case 'profile':
				return t('labels.profile-page')
			case 'greeting':
				return t('labels.text_greeting')
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.thumbnailContainer}>
					<p className={styles.time}>{time}</p>
					<div className={styles.iconContainer}>{decideIconHandler()}</div>
				</div>
				<p>{decideLabelHandler()}</p>
			</div>
		</div>
	)
}

export default CardStatic
