import React from 'react'
import classnames from 'classnames'

import styles from './RestrictedFilesModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Modal from '../../../common/ModalNew'
import Button from '../../../common/Button'

interface RestrictedFilesModalInterface {
	closeModal: () => void
	filesRestricted: []
	isShowing: boolean
}

const RestrictedFilesModal = ({
	closeModal,
	filesRestricted,
	isShowing
}: RestrictedFilesModalInterface) => {
	return (
		<Modal
			isShowing={isShowing}
			hide={closeModal}
			customClassName={classnames(styles.restrictedFilesModal)}
			modalTitle={t('titles.restricted-files')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.okay')}
						onClick={closeModal}
						isPositive
						id="restricted-files-modal-okay"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<p>{t('misc.following-files-are-restricted')}</p>
				{filesRestricted.length > 0 &&
					filesRestricted.map((f: any, index) => <p key={index}>{f?.name}</p>)}
			</div>
		</Modal>
	)
}

export default RestrictedFilesModal
