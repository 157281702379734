import { ALLOWED_MESSAGES, ALLOWED_ORIGINS } from '../consts'
import { addToPresentation } from '../../store/actions/presentation'
import { store } from '../../store/configureStore'
import { getAllMyFilesCategories } from '../../store/actions/myfiles'

const onSlideCreatedMessage = (event: any) => {
	if (ALLOWED_ORIGINS.includes(event.origin)) {
		if (ALLOWED_MESSAGES.includes(event.data.action)) {
			switch (event.data.action) {
				case 'newFolderCreated':
					// @ts-ignore
					store.dispatch(getAllMyFilesCategories())
					return true
				case 'newSlideCreated':
					// @ts-ignore
					store.dispatch(addToPresentation(event.data.data))
					return true
				default:
					return false
			}
		}
	}
	return false
}

export default onSlideCreatedMessage
