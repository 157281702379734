import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import FolderOpenedIcon from '@mui/icons-material/FolderOpen'
import FolderIcon from '@mui/icons-material/Folder'
import FolderClosedIcon from '@mui/icons-material/FolderOutlined'
import PlayIcon from '@mui/icons-material/PlayCircleOutlined'
import LaunchIcon from '@mui/icons-material/LaunchOutlined'

import styles from './Folder.module.scss'
import { convertBusinessRules } from '../../../../../utils/convertBusinessRules'
import getEmbedUrl from '../../../../../utils/helpers/getEmbedUrl'

const Collapsible = styled.div`
	height: ${(p) => (p.isOpen ? 'auto' : 0)};
	overflow: hidden;
	margin-top: ${(p) => (p.isOpen ? '5px' : 0)};
	margin-left: 24px;
`

export const Folder = ({
	autoplayTag,
	name,
	children,
	onClick,
	tagObject,
	folderPath,
	selectedTagToEmbed
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const location = useLocation()
	const businessRules = convertBusinessRules(tagObject.business_rules)
	const authToken = useSelector((state) => state.authUser.user.token)
	const refreshToken = useSelector((state) => state.authUser.user.refresh_token)
	const libraryTreeSelectedTagId = useSelector(
		(state) => state.temp.libraryTreeSelectedTagId
	)

	useEffect(() => {
		const tempBoolean = folderPath.some(
			(folder) => folder.tag_id === tagObject.tag_id
		)
		if (tempBoolean) {
			setIsOpen(true)
		}
		return () => {
			setIsOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderPath])

	const handleToggle = (e) => {
		e.preventDefault()
		if (!['playIcon', 'embedIcon'].includes(e.target.id)) {
			setIsOpen((currentValue) => !currentValue)
			onClick(tagObject)
		}
	}

	const embeddedTagHandler = () => {
		const newTag = {
			id: tagObject.id,
			name: tagObject.name,
			embeddedUrl: getEmbedUrl(
				location,
				businessRules,
				refreshToken,
				authToken
			),
			target: businessRules.target || false
		}
		selectedTagToEmbed(newTag)
	}

	const autoplayHandler = () => {
		autoplayTag(tagObject)
	}

	return (
		<div className={styles.oneFolder}>
			<div className={styles.selectorBtn} onClick={handleToggle}>
				<div
					className={classnames(
						styles.oneFolderInnerContainer,
						isOpen && styles.opened,
						tagObject.tag_id === libraryTreeSelectedTagId && styles.active
					)}
				>
					<span
						className={classnames(
							styles.arrowIconWrapper,
							isOpen ? styles.open : styles.closed
						)}
					>
						{isOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
					</span>
					<span
						className={isOpen ? styles.openedFolderIcon : styles.folderIcon}
					>
						{isOpen ? (
							tagObject.tag_id === libraryTreeSelectedTagId ? (
								<FolderIcon />
							) : (
								<FolderOpenedIcon />
							)
						) : (
							<FolderClosedIcon />
						)}
					</span>
					{businessRules.autoplay && (
						<span className={styles.playIcon}>
							<PlayIcon onClick={autoplayHandler} id="playIcon" />
						</span>
					)}
					{businessRules.embeddedUrl && (
						<span className={styles.embedIcon}>
							<LaunchIcon onClick={embeddedTagHandler} id="embedIcon" />
						</span>
					)}
					<span className={styles.name}>{name}</span>
				</div>
			</div>
			<Collapsible isOpen={isOpen}>{children}</Collapsible>
		</div>
	)
}

Folder.defaultProps = {
	folderPath: []
}

Folder.propTypes = {
	autoplayTag: PropTypes.func.isRequired,
	children: PropTypes.objectOf(PropTypes.any).isRequired,
	folderPath: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	tagObject: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedTagToEmbed: PropTypes.func.isRequired
}
