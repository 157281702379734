import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import WarningIcon from '@mui/icons-material/Warning'
import DeleteIcon from '@mui/icons-material/Delete'
import styled from 'styled-components'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { AnimatePresence, motion } from 'framer-motion'

import SfModal from '../../../../components/common/ModalNew'
import styles from './FolderOptions.module.scss'
import Section from '../../../../components/common/Section'
import UploadContent from '../../../../components/common/UploadContent'
import {
	UPLOAD_BUTTON,
	FLEX_START,
	FLEX_END,
	BUTTON_TEXT,
	BACKGROUND,
	LOGO_IMAGE,
	FOLDER_ICON_IMAGE,
	SUCCESS,
	BUILD,
	STORYBOARD
} from '../../../../utils/consts'
import Button from '../../../../components/common/Button'
import { t } from '../../../../utils/languages/i18n'
import Toggle from '../../../../components/common/Toggle'
import Input from '../../../../components/common/Input'
import {
	modifyFolderOptions,
	uploadImage
} from '../../../../api/requests/layout'
import { getAllShortcuts, getTags } from '../../../../store/actions/tags'
import Spinner from '../../../../components/common/Spinner'
import { dateTimePickerTheme } from '../../../../utils/theme'
import CheckBox from '../../../../components/common/CheckBox'
import {
	setCurrentBackgroundImage,
	setTagBusinessRules
} from '../../../../store/actions/storyBoardContext'
import { embedUrlSchema } from '../../../../utils/schemas/requests'
import AdvancedBusinessRulesModal from '../AdvancedBusinessRulesModal'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'

const Preview = styled.div`
	background: url(${(props) =>
		props.previewImage ? props.previewImage : 'none'});
`

const FolderOptions = ({ isVisible, handleClose }) => {
	const [isLoading, toggleLoader] = useState({ type: undefined, state: false })
	const [isEmbedUrlInvalid, toggleEmbedUrlInvalid] = useState(false)
	const authToken = useSelector((store) => store.authUser.user.token)
	const userGroups = useSelector((state) => state.users.userGroups)
	const dispatch = useDispatch()
	const storyBoardContext = useSelector((store) => store.storyboardContext)
	const [hiddenFolderFrom, setHiddenFolderFrom] = useState(
		storyBoardContext.tagBusinessRules.visibility.start_date_visible
	)
	const [hiddenFolderTo, setHiddenFolderTo] = useState(
		storyBoardContext.tagBusinessRules.visibility.end_date_visible
	)
	const [isHiddenFromBuild, toggleHiddenFromBuild] = useState(
		storyBoardContext.tagBusinessRules.isHiddenFromBuild
	)
	const [isHiddenFromShowRoom, toggleHiddenFromShowRoom] = useState(
		storyBoardContext.tagBusinessRules.isHiddenFromShowRoom
	)
	const [embedUrl, setEmbedUrl] = useState({
		embedUrl: storyBoardContext.tagBusinessRules.embeddedUrl || ''
	})

	useEffect(() => {
		toggleHiddenFromBuild(storyBoardContext.tagBusinessRules.isHiddenFromBuild)
		toggleHiddenFromShowRoom(
			storyBoardContext.tagBusinessRules.isHiddenFromShowRoom
		)
	}, [
		storyBoardContext.tagBusinessRules.isHiddenFromBuild,
		storyBoardContext.tagBusinessRules.isHiddenFromShowRoom
	])

	const [selectedUserGroups, setSeletedUserGroups] = useState([])

	const [isAdvancedBusinessRulesModalOpen, toggleAdvancedBusinessRulesModal] =
		useState(false)

	useEffect(() => {
		setEmbedUrl({
			embedUrl: storyBoardContext.tagBusinessRules.embeddedUrl || ''
		})
		const INIT_USER_GROUP =
			storyBoardContext.tagBusinessRules.visibility.userGroups.length === 0
				? userGroups.map((userGroup) => userGroup.id.toString())
				: storyBoardContext.tagBusinessRules.visibility.userGroups
		setSeletedUserGroups(INIT_USER_GROUP)
	}, [storyBoardContext, userGroups])

	const { register, trigger, setValue, errors } = useForm({
		resolver: joiResolver(embedUrlSchema)
	})
	/**
	 *
	 * @description toggle the clicked chcekbox true | false
	 * @param {object} group the clicked object of UserGroup
	 * @param {InputEvent} event the clicked input event
	 */
	function handleUserGroupSelection(group, event) {
		if (event.target.checked) {
			setSeletedUserGroups([...selectedUserGroups, group.value])
		} else {
			const filtered = selectedUserGroups.filter(
				(userGroup) => userGroup !== group.value
			)
			setSeletedUserGroups(filtered)
		}
	}

	/**
	 * @description validates the input given in parameter
	 * @param {string} field the name of the field
	 */
	function validate(field) {
		trigger([field]).then((result) => {
			if (result === false) {
				toggleEmbedUrlInvalid(true)
				return true
			}
			toggleEmbedUrlInvalid(false)
			dispatch(
				setTagBusinessRules({
					...storyBoardContext.tagBusinessRules,
					embeddedUrl: embedUrl.embedUrl
				})
			)
			return false
		})
	}

	const userGroupOptions = () =>
		userGroups.map((userGroup) => ({
			label: userGroup.name,
			value: userGroup.id.toString()
		}))

	/**
	 *
	 *
	 * @param {object} file The File object of the selected file
	 * @param {string} type Tthe type of the action currently being dispatches
	 * @description upload the selected file and returns it's hash
	 */
	function uploadFiles(file, type) {
		toggleLoader({
			type,
			state: true
		})
		uploadImage(file, authToken).then((result) => {
			const newFile = `${result.hash}.${result.extension}`
			if (type === BACKGROUND) {
				dispatch(
					setTagBusinessRules({
						...storyBoardContext.tagBusinessRules,
						'background-image': newFile
					})
				)
				dispatch(setCurrentBackgroundImage(newFile))
			} else if (type === LOGO_IMAGE) {
				dispatch(
					setTagBusinessRules({
						...storyBoardContext.tagBusinessRules,
						'logo-image': newFile
					})
				)
			} else if (type === FOLDER_ICON_IMAGE) {
				dispatch(
					setTagBusinessRules({
						...storyBoardContext.tagBusinessRules,
						folderIconImage: newFile
					})
				)
			}
			toggleLoader({
				state: false,
				type: undefined
			})
		})
	}

	/**
	 * @description dispatches a saving action with the selected settings on a folder
	 * with the specified folder (=tagId) defined in the contextProvider
	 *
	 */
	function handleSave() {
		// this is for backward compatibility, due to the new logic.
		// It means: if everything is selected, nothing is selected,
		// which means its hidden for everyone.
		// sorry.
		const userGoups =
			userGroups.length === selectedUserGroups.length ? [] : selectedUserGroups
		const folderOptions = {
			'background-image':
				storyBoardContext.tagBusinessRules['background-image'],
			'logo-image': storyBoardContext.tagBusinessRules['logo-image'],
			folderIconImage: storyBoardContext.tagBusinessRules.folderIconImage,
			shortcut: storyBoardContext.tagBusinessRules.shortcut,
			autoplay: storyBoardContext.tagBusinessRules.autoplay,
			hidden_library: storyBoardContext.tagBusinessRules.hidden_library,
			hidden_summary: storyBoardContext.tagBusinessRules.hidden_summary,
			open_library: storyBoardContext.tagBusinessRules.open_library,
			allowMultiSelection:
				storyBoardContext.tagBusinessRules.allowMultiSelection,
			hidePreviousLevels: storyBoardContext.tagBusinessRules.hidePreviousLevels,
			hideBreadcrumbs: storyBoardContext.tagBusinessRules.hideBreadcrumbs,
			showDescription: storyBoardContext.tagBusinessRules.showDescription,
			folderDescription: storyBoardContext.tagBusinessRules.folderDescription,
			embeddedUrl: embedUrl.embedUrl,
			visibility: {
				visible: storyBoardContext.tagBusinessRules.visibility.visible,
				userGroups: userGoups.map((currentGroup) => currentGroup.toString()),
				start_date_visible: hiddenFolderFrom,
				end_date_visible: hiddenFolderTo
			},
			isHiddenFromBuild,
			isHiddenFromShowRoom,
			target: storyBoardContext.tagBusinessRules.target
		}

		const joinedFolderOptions = {
			...storyBoardContext.tagBusinessRules,
			...folderOptions
		}

		modifyFolderOptions(
			joinedFolderOptions,
			'business_rules',
			storyBoardContext.selectedTag.id,
			authToken
		).then(() => {
			handleClose()
			dispatch(getTags(authToken))
			dispatch(getAllShortcuts(authToken))
			dispatch(setTagBusinessRules(folderOptions))
			toast(t('notifications.success.folder-options-saved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
		})
	}

	/**
	 *
	 * @description handles toggling the folder hiding
	 * and cleares the usergroup array if it's turned off
	 */
	function handleTagVisibility() {
		const newValue = !storyBoardContext.tagBusinessRules.visibility.visible
		if (!newValue) {
			toggleHiddenFromBuild(true)
			toggleHiddenFromShowRoom(true)
			setSeletedUserGroups([])
		}

		dispatch(
			setTagBusinessRules({
				...storyBoardContext.tagBusinessRules,
				visibility: {
					...storyBoardContext.tagBusinessRules.visibility,
					userGroups: newValue === false ? selectedUserGroups : [],
					visible: newValue
				}
			})
		)
	}

	const isFolderHiddenToggleOn = () =>
		storyBoardContext.tagBusinessRules.visibility &&
		storyBoardContext.tagBusinessRules.visibility.visible

	/**
	 *
	 * @description removes background image rendered for a tag
	 * dispatches an action which sets false on background-image on tis tag
	 * dispatches an action that removes the background image on this tag in the background reducer
	 */
	function handleBackgroundImageRemoving() {
		const { selectedTag, backgroundImage } = storyBoardContext
		let previousBackgroundImage
		if (selectedTag.length === 2) {
			previousBackgroundImage = backgroundImage[selectedTag[1].tag_id]
		} else if (selectedTag.length > 2) {
			previousBackgroundImage =
				backgroundImage[selectedTag[selectedTag.length - 1].tag_id]
		} else {
			previousBackgroundImage = backgroundImage[0].toString()
		}

		dispatch(
			setTagBusinessRules({
				...storyBoardContext.tagBusinessRules,
				'background-image': false
			})
		)
		dispatch(
			setCurrentBackgroundImage(previousBackgroundImage, selectedTag.tagId)
		)
	}

	/**
	 *
	 * @description removes logo image for a tag
	 * dispatches an action which sets false on logo-image on tis tag
	 *
	 */
	function handleLogoRemoving() {
		dispatch(
			setTagBusinessRules({
				...storyBoardContext.tagBusinessRules,
				'logo-image': false,
				folderLogoImage: false
			})
		)
	}

	return (
		<>
			<SfModal
				isShowing={isVisible && !isAdvancedBusinessRulesModalOpen}
				hide={handleClose}
				customClassName={styles.folderOptionsModal}
				modalTitle={
					storyBoardContext.selectedTag
						? `${t('layout.folder-options.folder-options')}: ${
								storyBoardContext.selectedTag.name
						  }`
						: ''
				}
				headerClassname={styles.folderOptionsModalHeader}
				footer={
					<>
						<Button onClick={() => handleClose()} label={t('labels.cancel')} />
						<Button
							onClick={() => handleSave()}
							label={t('labels.save')}
							isPositive
							isDisabled={isEmbedUrlInvalid}
						/>
					</>
				}
			>
				<Section
					sectionClassName={classnames(styles.section, styles.uploadSection)}
				>
					<div className={styles.uploadContainerRoot}>
						<div className={styles.previewContainer}>
							<Preview
								className={styles.preview}
								previewImage={`${sfApiRoot}/files/assets/files/${
									storyBoardContext.tagBusinessRules['background-image']
								}?oauth=${encodeURIComponent(authToken)}`}
							/>
						</div>
						<div
							className={classnames(
								styles.actionContainer,
								isLoading.type === BACKGROUND ? styles.loading : null
							)}
						>
							{!isLoading.state ? (
								<>
									<div className={styles.top}>
										<p>{t('labels.folder-bg')}</p>
									</div>
									<div className={styles.buttonContainer}>
										<UploadContent
											type={UPLOAD_BUTTON}
											buttonContainerClass={styles.uploadBtnContainer}
											buttonClass={styles.uploadBtn}
											buttonnLabel={t('labels.layout-upload')}
											handleFileUpload={(files) =>
												uploadFiles(files[0], BACKGROUND)
											}
											icon={<CloudUploadIcon />}
										/>
										<Button
											type={BUTTON_TEXT}
											containerClass={styles.uploadBtnContainer}
											buttonClass={styles.removeBtn}
											onClick={() => handleBackgroundImageRemoving()}
											icon={<DeleteIcon />}
											label={t('labels.layout-remove')}
										/>
									</div>
								</>
							) : (
								isLoading.state &&
								isLoading.type === BACKGROUND && <Spinner isLoading size={30} />
							)}
						</div>
					</div>
					<div className={styles.uploadContainerRoot}>
						<div className={styles.previewContainer}>
							<Preview
								className={styles.preview}
								previewImage={`${sfApiRoot}/files/assets/files/${
									storyBoardContext.tagBusinessRules['logo-image']
								}?oauth=${encodeURIComponent(authToken)}`}
							/>
						</div>
						<div
							className={classnames(
								styles.actionContainer,
								isLoading.type === LOGO_IMAGE ? styles.loading : null
							)}
						>
							{!isLoading.state ? (
								<>
									<div className={styles.top}>
										<p>{t('labels.folder-logo')}</p>
									</div>
									<div className={styles.buttonContainer}>
										<UploadContent
											type={UPLOAD_BUTTON}
											buttonContainerClass={styles.uploadBtnContainer}
											buttonClass={styles.uploadBtn}
											buttonnLabel={t('labels.upload-new')}
											icon={<CloudUploadIcon />}
											handleFileUpload={(images) =>
												uploadFiles(images[0], LOGO_IMAGE)
											}
										/>
										<Button
											containerClass={styles.uploadBtnContainer}
											buttonClass={styles.removeBtn}
											type={BUTTON_TEXT}
											icon={<DeleteIcon />}
											label={t('labels.layout-remove')}
											onClick={() => handleLogoRemoving()}
										/>
									</div>
								</>
							) : (
								isLoading.state &&
								isLoading.type === LOGO_IMAGE && <Spinner isLoading size={30} />
							)}
						</div>
					</div>
					<div className={styles.uploadContainerRoot}>
						<div className={styles.previewContainer}>
							<Preview
								className={styles.preview}
								previewImage={`${sfApiRoot}/files/assets/files/${
									storyBoardContext.tagBusinessRules.folderIconImage
								}?oauth=${encodeURIComponent(authToken)}`}
							/>
						</div>
						<div
							className={classnames(
								styles.actionContainer,
								isLoading.type === FOLDER_ICON_IMAGE ? styles.loading : null
							)}
						>
							{!isLoading.state ? (
								<>
									<div className={styles.top}>
										<p>{t('labels.folder-icon')}</p>
									</div>
									<div className={styles.buttonContainer}>
										<UploadContent
											type={UPLOAD_BUTTON}
											buttonContainerClass={styles.uploadBtnContainer}
											buttonClass={styles.uploadBtn}
											buttonnLabel={t('labels.upload-new')}
											icon={<CloudUploadIcon />}
											handleFileUpload={(files) =>
												uploadFiles(files[0], FOLDER_ICON_IMAGE)
											}
										/>
										<Button
											containerClass={styles.uploadBtnContainer}
											buttonClass={styles.removeBtn}
											type={BUTTON_TEXT}
											onClick={() =>
												dispatch(
													setTagBusinessRules({
														...storyBoardContext.tagBusinessRules,
														folderIconImage: false
													})
												)
											}
											icon={<DeleteIcon />}
											label={t('labels.layout-remove')}
										/>
									</div>
								</>
							) : (
								isLoading.state &&
								isLoading.type === FOLDER_ICON_IMAGE && (
									<Spinner isLoading size={30} />
								)
							)}
						</div>
					</div>
				</Section>
				<Section
					sectionClassName={classnames(styles.section, styles.toggleSection)}
				>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.shortcut')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.shortcut}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										shortcut: !storyBoardContext.tagBusinessRules.shortcut
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.autoplay')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.autoplay}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										autoplay: !storyBoardContext.tagBusinessRules.autoplay
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.hide-content-drawer')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.hidden_library}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										hidden_library:
											!storyBoardContext.tagBusinessRules.hidden_library
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.hide-presentation-deck')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.hidden_summary}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										hidden_summary:
											!storyBoardContext.tagBusinessRules.hidden_summary
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.auto-open-content')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.open_library}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										open_library:
											!storyBoardContext.tagBusinessRules.open_library
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.hide-previous-levels')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={
								storyBoardContext.tagBusinessRules.hidePreviousLevels
							}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										hidePreviousLevels:
											!storyBoardContext.tagBusinessRules.hidePreviousLevels
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.hide-breadcrumbs')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.hideBreadcrumbs}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										hideBreadcrumbs:
											!storyBoardContext.tagBusinessRules.hideBreadcrumbs
									})
								)
							}
						/>
					</div>
					<div className={styles.oneToggle}>
						<p className={styles.label}>
							{t('layout.folder-options.show-description')}
						</p>
						<Toggle
							states={{
								off: t('misc.off'),
								on: t('misc.on')
							}}
							currentState={storyBoardContext.tagBusinessRules.showDescription}
							onChange={() =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										showDescription:
											!storyBoardContext.tagBusinessRules.showDescription
									})
								)
							}
						/>
					</div>
				</Section>
				<Section
					sectionClassName={classnames(
						styles.section,
						styles.folderDescription
					)}
				>
					<div className={styles.inputRootContainer}>
						<Input
							name="folderdesc"
							label={t('labels.layout-folder-description')}
							initialValue={
								storyBoardContext.tagBusinessRules.folderDescription || ''
							}
							onChange={(event) =>
								dispatch(
									setTagBusinessRules({
										...storyBoardContext.tagBusinessRules,
										folderDescription: event.target.value
									})
								)
							}
							wrapperClassName={styles.inputContainer}
						/>
					</div>
				</Section>
				<Section
					sectionClassName={classnames(
						styles.section,
						styles.folderDescription
					)}
				>
					<div
						className={classnames(
							styles.inputRootContainer,
							styles.embedUrlContainer
						)}
					>
						<Input
							initialValue={embedUrl.embedUrl}
							onChange={(event) => {
								setEmbedUrl({
									embedUrl: event.target.value
								})
								setValue('embedUrl', event.target.value)
							}}
							refProp={register('embedUrl')}
							wrapperClassName={classnames(styles.inputContainer)}
							onBlur={() => validate('embedUrl')}
							label={
								errors && errors.embedUrl
									? t('input-labels.string.uri')
									: t('labels.emmbed-url')
							}
							state={errors && errors.embedUrl && styles.formError}
							name="embedUrl"
						/>
						<div className={styles.oneToggle}>
							<AnimatePresence key={embedUrl.embedUrl}>
								{embedUrl.embedUrl && embedUrl.embedUrl.length > 1 && (
									<>
										<p className={styles.label}>{t('layout.embed-new-tab')}</p>
										<Toggle
											states={{
												off: t('misc.off'),
												on: t('misc.on')
											}}
											currentState={storyBoardContext.tagBusinessRules.target}
											onChange={() =>
												dispatch(
													setTagBusinessRules({
														...storyBoardContext.tagBusinessRules,
														target: !storyBoardContext.tagBusinessRules.target
													})
												)
											}
										/>
									</>
								)}
							</AnimatePresence>
						</div>
					</div>
				</Section>
				<Section
					sectionClassName={classnames(
						styles.section,
						styles.folderDescription
					)}
					justify={FLEX_START}
				>
					<div
						className={classnames(styles.oneToggle, styles.hideFolderContainer)}
					>
						<div className={styles.toggleInner}>
							<div>
								<p className={styles.label}>
									{t('layout.folder-options.hide-folder')}
								</p>
								<Toggle
									states={{
										off: t('misc.off'),
										on: t('misc.on')
									}}
									currentState={!isFolderHiddenToggleOn()}
									onChange={() => handleTagVisibility()}
								/>
							</div>
							<div
								className={classnames(
									styles.datePickerContainer,
									isFolderHiddenToggleOn() ? styles.hidden : ''
								)}
							>
								<div className={styles.col}>
									<div className={styles.datePickerInner}>
										<p>{t('layout.folder-options.date-from')}</p>
										<ThemeProvider theme={dateTimePickerTheme}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													variant="inline"
													value={hiddenFolderFrom}
													onChange={(value) => setHiddenFolderFrom(value)}
												/>
											</MuiPickersUtilsProvider>
										</ThemeProvider>
									</div>
									<div className={styles.datePickerInner}>
										<p>{t('layout.folder-options.date-to')}</p>
										<ThemeProvider theme={dateTimePickerTheme}>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<DatePicker
													variant="inline"
													value={hiddenFolderTo}
													onChange={(value) => setHiddenFolderTo(value)}
												/>
											</MuiPickersUtilsProvider>
										</ThemeProvider>
									</div>
								</div>
							</div>
						</div>
						<div
							className={classnames(
								styles.userGroupRoot,
								isFolderHiddenToggleOn() ? styles.hidden : ''
							)}
						>
							<div className={styles.checkboxContainer}>
								<CheckBox
									name={BUILD}
									label={t('layout.folder-options.hide-in-build')}
									isChecked={isHiddenFromBuild}
									onChange={(changeEvent) =>
										toggleHiddenFromBuild(changeEvent.target.checked)
									}
								/>
								<CheckBox
									name={STORYBOARD}
									label={t('layout.folder-options.hide-in-showroom')}
									isChecked={isHiddenFromShowRoom}
									onChange={(changeEvent) =>
										toggleHiddenFromShowRoom(changeEvent.target.checked)
									}
								/>
								<AnimatePresence>
									{!isHiddenFromShowRoom && !isHiddenFromShowRoom && (
										<motion.p
											className={styles.moduleHideError}
											variants={{
												visible: {
													opacity: 1,
													y: 0
												},
												hidden: {
													opacity: 0,
													y: 10
												},
												exit: {
													opacity: 0,
													y: -10
												}
											}}
											animate={
												isHiddenFromShowRoom && isHiddenFromShowRoom
													? 'visible'
													: 'hidden'
											}
											exit="exit"
											initial="hidden"
										>
											<WarningIcon htmlColor="#ffffff" /> At least one needs to
											be selected
										</motion.p>
									)}
								</AnimatePresence>
							</div>
							<p className={styles.label}>
								{t('layout.folder-options.hide-in-groups')}
							</p>
							<div className={styles.checkboxContainer}>
								{userGroupOptions().map((userGroup) => (
									<CheckBox
										key={`checkbox-${userGroup.label}`}
										name={userGroup.label}
										label={userGroup.label}
										isChecked={selectedUserGroups.includes(userGroup.value)}
										onChange={(changeEvent) =>
											handleUserGroupSelection(userGroup, changeEvent)
										}
									/>
								))}
							</div>
						</div>
					</div>
				</Section>
				<Section
					sectionClassName={classnames(
						styles.section,
						styles.folderDescription
					)}
					justify={FLEX_END}
				>
					<div className={styles.footerBtnContainer}>
						<ul>
							<li>
								<Button
									type={BUTTON_TEXT}
									onClick={() => toggleAdvancedBusinessRulesModal(true)}
									label={t('layout.folder-options.advanced-business-rules')}
								/>
							</li>
						</ul>
					</div>
				</Section>
			</SfModal>
			<AdvancedBusinessRulesModal
				isShowing={isAdvancedBusinessRulesModalOpen}
				handleClose={() => toggleAdvancedBusinessRulesModal(false)}
			/>
		</>
	)
}

FolderOptions.propTypes = {
	handleClose: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired
}

export default FolderOptions
