import { isRestristed } from './decideThumbanil'
import { SlideInterface } from '../interfaces'

export const getRestrictedFiles = (selectedFiles: SlideInterface[]) =>
	selectedFiles.filter((file) => isRestristed(file.business_rules))

export const excludeRestriectedFiles = (
	allFiles: SlideInterface[],
	filesToExclude: SlideInterface[]
): SlideInterface[] => {
	const toRemove = new Set(filesToExclude)
	return allFiles.filter((file) => !toRemove.has(file))
}

export const removeUnnecessaryFieldsFromSlide = (
	slideItem: SlideInterface
) => ({
	...slideItem,
	attr_time: undefined,
	attr_status: undefined,
	attr_index: undefined,
	attr_checksum: undefined,
	attr_origin: undefined,
	graphBarValue: undefined,
	formattedTime: undefined,
	analytics: undefined,
	doc_count: undefined
})
