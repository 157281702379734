import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import Result from './Result'
import styles from './CRMDynamicsRUModal.module.scss'

import Modal from '../../../components/common/ModalNew'
import Spinner from '../../../components/common/Spinner'
import { t } from '../../../utils/languages/i18n'
import Section from '../../../components/common/Section'
import Button from '../../../components/common/Button'
import { FLEX_END } from '../../../utils/consts'
import Input from '../../../components/common/Input'
import {
	searchUserRU,
	sendCrmDynamicsData
} from '../../../api/requests/crmDynamics'
import { getMemositeLink } from '../../../utils/helpers/memosites'
import { StoreInterface } from '../../../utils/interfaces'

interface CRMDynamicsRUInterface {
	handleClose: () => void
	memosite: any
	saveButtonDisabled: boolean
	zIndex?: number
}

const CRMDynamicsRU = ({
	handleClose,
	memosite,
	saveButtonDisabled,
	zIndex = 9999
}: CRMDynamicsRUInterface) => {
	const [customers, setCustomers] = useState([])
	const [searchQuery, setSearchQuery] = useState('')
	const [selectedCustomerId, setSelectedCustomerId] = useState<string>('')
	const [isLoading, setIsLoading] = useState(false)

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const instanceName = useSelector(
		(state: StoreInterface) => state.temp.instanceName
	)

	const reset = () => {
		setSearchQuery('')
		setCustomers([])
		setSelectedCustomerId('')
	}

	const close = () => {
		reset()
		handleClose()
	}

	const handleSearch = () => {
		setIsLoading(true)
		searchUserRU(searchQuery)
			.then((result) => {
				if (result.value) {
					setCustomers(result.value)
					setIsLoading(false)
				}
			})
			.catch()
	}

	const handleSend = () => {
		setIsLoading(true)
		const memoUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			authUser.user.role,
			true
		)
		const data = {
			content: `<h4>${
				memosite.subject
			} memosite was created in Salesframe</h4><h4>By user ${
				authUser.user.firstname
			} ${
				authUser.user.lastname
			}</h4><h4>${new Date().toDateString()}</h4><br /><a href='${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false'>${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false</a>`,
			meeting_id: memosite.id,
			opportunity: selectedCustomerId,
			subject: 'Salesframe presentation created',
			user_id: authUser.user.id
		}
		sendCrmDynamicsData(data)
			.then(() => {
				reset()
				close()
				toast(t('notifications.success.crm-data-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
				setIsLoading(false)
			})
			.catch(() => {
				toast(t('notifications.error.crm-data-not-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: 5000
				})
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={close}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex || 9999}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>{t('labels.search-and-select-account')}</p>
			</div>
			<div>
				<div className={styles.crmContainer}>
					<Input
						onChange={(event) => setSearchQuery(event.target.value)}
						placeholderText={t('input-placeholders.account-name')}
						name="accountName"
						initialValue={searchQuery}
						inputClassName={styles.input}
					/>
					<Button
						onClick={handleSearch}
						isPositive
						label={t('labels.search')}
						buttonClass={styles.btn}
						isDisabled={saveButtonDisabled}
						id="crm-dynamics-ru-search"
					/>
				</div>
				{isLoading ? <Spinner isLoading size={44} /> : null}
				<div className={customers ? styles.notesContainer : ''}>
					{customers?.length > 0 ? (
						customers.map((item, index) => (
							<Result
								data={item}
								key={index}
								selectData={(accountid) => setSelectedCustomerId(accountid)}
								selectedCustomerId={selectedCustomerId}
							/>
						))
					) : (
						<p className={styles.noResults}>{t('misc.no-results')}</p>
					)}
				</div>
			</div>
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px 30px"
			>
				<Button
					onClick={close}
					label={t('buttons.cancel')}
					buttonClass={styles.btn}
					id="crm-dynamics-ru-cancel"
				/>
				<Button
					onClick={handleSend}
					isPositive
					label={t('labels.send')}
					buttonClass={styles.btn}
					isDisabled={!selectedCustomerId}
					id="crm-dynamics-ru-send"
				/>
			</Section>
		</Modal>
	)
}

export default CRMDynamicsRU
