import React, { useState } from 'react'
import classnames from 'classnames'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

import styles from './EmbeddedModal.module.scss'

import Modal from '../../../common/ModalNew'

interface EmbeddedModalInterface {
	closeModal: () => void
	embeddedUrl: string
	isShowing: boolean
}

const EmbeddedModal = ({
	closeModal,
	embeddedUrl,
	isShowing
}: EmbeddedModalInterface) => {
	const [isFullScreen, setIsFullScreen] = useState(false)

	return (
		<Modal
			contentClassName={styles.contentClassName}
			customClassName={classnames(
				isFullScreen ? styles.modalFullScreenWrapper : styles.modalWrapper
			)}
			disableOverlayClose={true}
			hasCloseIcon={false}
			headerClassname={styles.header}
			hide={closeModal}
			isShowing={isShowing}
			zIndex={10000}
		>
			<div
				className={styles.fullPageButtonContainer}
				onClick={() => setIsFullScreen(!isFullScreen)}
			>
				{isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
			</div>
			{embeddedUrl && (
				<iframe
					title={embeddedUrl}
					src={embeddedUrl}
					className={styles.embeddedFrame}
				/>
			)}
		</Modal>
	)
}

export default EmbeddedModal
