import { add, format } from 'date-fns'

import {
	cloudFnGet,
	cloudFnPost,
	cloudFnDelete,
	cloudFnPatch
} from '../../utils/requests'
import {
	changeMemositeCodeSchema,
	createMemositeSchema,
	getMemositePreviewObjectSchema,
	resetMemositeDeletionSchema,
	updateMemositeSchema
} from '../../utils/schemas/requests'
import { MeetingsResponseInterface } from '../../utils/interfaces'
import {
	meetingsEndpoint,
	meetingsBySlugEndpoint,
	sharedPresentationOpenedEndpoint,
	memoSecuredEndpoint,
	memositeStatistics,
	memositeAutomatedDeletionReset,
	getMemositePreview,
	materialByTimeAdvancedEndpoint,
	getMemositeSessionAdvancedEndpoint,
	filterMeetingsV2,
	presentationBySlugEndpoint
} from '../apiEndpoints_new'
import {
	MemositeAnalyticsInterface,
	MemositeSingleSessionInterface,
	MemositeStatisticsInterface,
	SlideInterfaceWithAnalytics,
	SlidePageInterface
} from '../../pages/MyMemosites/interfaces'

export const createMemoSite = (memoSiteContent: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			meetingsEndpoint,
			JSON.stringify(memoSiteContent),
			{ withToken: true, authToken },
			createMemositeSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getSingleMeetingBySlug = (
	limit: number,
	slug: string,
	isPresentation?: boolean
) =>
	new Promise<MeetingsResponseInterface>((resolve, reject) => {
		cloudFnGet(
			`${
				isPresentation ? presentationBySlugEndpoint : meetingsBySlugEndpoint
			}/${limit}/${slug}`,
			{},
			null
		)
			.then((result) => {
				resolve(result as MeetingsResponseInterface)
			})
			.catch((error) => {
				reject(error)
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMemositeOpened = (id: number) =>
	new Promise<MemositeAnalyticsInterface>((resolve, reject) => {
		cloudFnGet(`${sharedPresentationOpenedEndpoint}/${id}`, {}, null)
			.then((result) => {
				resolve(result as MemositeAnalyticsInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMemositeSlidesWithAnalytics = (id: number) =>
	new Promise<{
		pages: SlidePageInterface[]
		slides: SlideInterfaceWithAnalytics[]
	}>((resolve, reject) => {
		const endDate = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd HH:mm:ss')
		cloudFnGet(
			`${materialByTimeAdvancedEndpoint}/memosite.material.time-on-slide/0/50/all/0/1970-01-01 00:00:00/${endDate}/${id}/asc/null`,
			{},
			null
		)
			.then((result: any) => {
				resolve(
					result as {
						pages: SlidePageInterface[]
						slides: SlideInterfaceWithAnalytics[]
					}
				)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMemositeSessions = (id: any) =>
	new Promise<MemositeSingleSessionInterface[]>((resolve, reject) => {
		const endDate = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd HH:mm:ss')
		cloudFnGet(
			`${getMemositeSessionAdvancedEndpoint}/memosite.material.time-on-slide/0/50/all/0/1970-01-01 00:00:00/${endDate}/${id}/asc/null`,
			{},
			null
		)
			.then((result: any) => {
				resolve(result.body as MemositeSingleSessionInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteMemosite = (authToken: any, id: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(
			`${meetingsEndpoint}/${id}`,
			{},
			{ withToken: true, authToken }
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updateMemosite = (memositeObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			meetingsEndpoint,
			memositeObject,
			{ withToken: true, authToken },
			updateMemositeSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const changeMemositeCode = (dataObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			`${memoSecuredEndpoint}/changecode`,
			dataObject,
			{ withToken: true, authToken },
			changeMemositeCodeSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteMemositeCode = (authToken: any, id: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(
			`${memoSecuredEndpoint}/${id}`,
			{},
			{ withToken: true, authToken }
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const filterMemosites = (
	fromDate: string,
	toDate: string,
	opened: number,
	crm: number,
	page: number,
	limit: number,
	user: number,
	search: string | null,
	status: 0 | 10,
	sortBy: string,
	direction: string,
	labels: number[] | 0
) =>
	new Promise<MeetingsResponseInterface>((resolve, reject) => {
		cloudFnGet(
			`${filterMeetingsV2}/${fromDate}/${toDate}/${opened}/${crm}/${page}/${limit}/${user}/${search}/${status}/${sortBy}/${direction}/${labels}`
		)
			.then((response: any) => {
				const formattedResponse = {
					meetings: response.meetings,
					total: response.total.total
				}
				resolve(formattedResponse as MeetingsResponseInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMemositeStatistics = (reset: 0 | 1, selectedUserId?: number) =>
	new Promise<MemositeStatisticsInterface>((resolve, reject) => {
		cloudFnGet(
			`${memositeStatistics}/${reset}${
				selectedUserId ? `?userid=${selectedUserId}` : ''
			}`,
			{},
			null
		)
			.then((result: any) => {
				const loadedTime = format(new Date(), 'dd / MM / yyyy HH:mm')
				const preparedResponse = {
					...result,
					loadedTime
				}
				resolve(preparedResponse as MemositeStatisticsInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const resetMemositeDeletion = (id: number) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			`${memositeAutomatedDeletionReset}/${id}`,
			{},
			{ withToken: true },
			resetMemositeDeletionSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMemositePreviewObject = (dataObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			`${getMemositePreview}`,
			dataObject,
			{ withToken: true, authToken },
			getMemositePreviewObjectSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
