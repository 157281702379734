import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import validator from 'validator'
import { useNavigate, useParams } from 'react-router-dom'
import { useAnimation } from 'framer-motion'
import { toast } from 'react-toastify'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import styles from './Forgot.module.scss'
import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import { forget } from '../../api/requests/user'
import { DEFAULT } from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import Spinner from '../../components/common/Spinner'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface } from '../../utils/interfaces'

const SecondForgotPassword = () => {
	const { resetToken } = useParams<string>()
	const [password, setPassword] = useState('')
	const [verifyPassword, setVerifyPassword] = useState('')
	const [step1, setStep1] = useState(true)
	const [isLoading, toggleLoading] = useState(false)
	const [captchaToken, setCaptchaToken] = useState('')
	const loginControl = useAnimation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			setTempValue(
				'currentModule',
				ModuleNamesInterface.FORGET_PASSWORD_SECOND_STEP
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 * @function  submit handles submission of new password
	 */
	const submit = () => {
		loginControl.start('loading').then(() => {
			toggleLoading(true)
		})
		const userObject: any = {
			phase: '2',
			captcha: captchaToken,
			token: resetToken,
			credentials: {
				password1: password,
				password2: verifyPassword
			}
		}

		forget(userObject)
			.then(() => {
				toggleLoading(false)
				toast(t('misc.password-reset-success'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: false
				})
				navigate('/login')
				setTimeout(() => toast.dismiss(), 5000)
			})
			.catch(() => {
				toggleLoading(false)
				setStep1(true)
				navigate('/forgot-password')
				toast(t('misc.password-reset-fail'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: false
				})
				setTimeout(() => toast.dismiss(), 5000)
			})
	}

	/**
	 * @function handleVerify validates captcha
	 */
	function handleVerify(token: string) {
		setCaptchaToken(token)
	}

	/**
	 * @returns first page component
	 */
	function firstStep() {
		return (
			<React.Fragment>
				<h4 className={styles.forgotPassword}>{t('misc.reset-password')}</h4>
				<p className={styles.description}>{t('titles.enter-new-password')}</p>
				<form>
					<Input
						label={null}
						onChange={(e) => setPassword(e.target.value)}
						inputClassName={
							!validator.isStrongPassword(password) ? styles.inputError : null
						}
						name="password"
						type="password"
						wrapperClassName={styles.forgotPasswordInputPassword}
						initialValue={password}
						state={DEFAULT.toLowerCase()}
						placeholderText={t('input-placeholders.password')}
					/>
					{!validator.isStrongPassword(password) && password !== '' ? (
						<p className={styles.description}>{t('titles.pass-invalid')}</p>
					) : null}

					<Input
						label={null}
						onChange={(e) => setVerifyPassword(e.target.value)}
						inputClassName={
							password !== verifyPassword || password === ''
								? styles.inputError
								: null
						}
						name="verifypassword"
						type="password"
						wrapperClassName={styles.forgotPasswordInput}
						initialValue={verifyPassword}
						state={DEFAULT.toLowerCase()}
						placeholderText={t('input-placeholders.confirm-pass')}
					/>
					{password !== verifyPassword ? (
						<p className={styles.description}>{t('titles.pass-match')}</p>
					) : null}
					{password &&
					verifyPassword &&
					validator.isStrongPassword(password) &&
					password !== '' &&
					password === verifyPassword ? (
						<Button
							label={t('buttons.done')}
							onClick={submit}
							buttonClass={styles.forgotPasswordBtn}
							containerClass={styles.forgotPasswordBtnContainer}
							isPositive
							id="forgot-password-second-step-done"
						/>
					) : (
						<Button
							label={t('buttons.done')}
							onClick={null}
							buttonClass={styles.forgotPasswordBtn}
							containerClass={styles.forgotPasswordBtnContainer}
							isDisabled
							id="forgot-password-second-step-done"
						/>
					)}
				</form>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			{!captchaToken && (
				<GoogleReCaptcha onVerify={(token) => handleVerify(token)} />
			)}
			<div className={styles.forgotPasswordContainer}>
				<div className={styles.card}>
					<div className={styles.container}>
						<div className={styles.logo}>
							<img src={salesframeLogo} alt="salesframe" />
						</div>
						{step1 ? firstStep() : null}
					</div>
					{isLoading && (
						<div className={styles.loader}>
							<Spinner isLoading size={44} />
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	)
}

export default SecondForgotPassword
