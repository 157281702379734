import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	PostAddOutlined as TextGreetingIcon,
	VideocamOutlined as VideoGreetingIcon,
	DeleteOutlined as DeleteIcon,
	EditOutlined as EditIcon,
	PlayCircleOutlined as PlayIcon
} from '@mui/icons-material'
import c from 'classnames'

import styles from './AddGreetingCard.module.scss'

import { t } from '../../../../utils/languages/i18n'
import {
	StoreInterface,
	VideoGreetingInterface
} from '../../../../utils/interfaces'
import { BUTTON_TEXT, ICON_LEFT, LG } from '../../../../utils/consts'
import Button from '../../../common/Button'
import { setTempValue } from '../../../../store/actions/temp'
import { createThumbnailUrl } from '../../../../utils/helpers/decideThumbanil'
import Loading from '../../../Card/components/Loading'

interface AddGreetingCardInterface {
	action: (type: 'video' | 'text') => void
	addedText?: string
	addedVideo?: {
		data: VideoGreetingInterface
		length: { minutes: number; seconds: number }
	}
	handleDelete: () => void
	handleEdit: (type: 'video' | 'text') => void
	type: 'video' | 'text'
	videoUploadPercent?: number
}

const AddGreetingCard = ({
	action,
	addedText,
	addedVideo,
	handleDelete,
	handleEdit,
	type,
	videoUploadPercent
}: AddGreetingCardInterface) => {
	const dispatch = useDispatch()

	const [thumbnail, setThumbnail] = React.useState('')
	const [loader, setLoader] = React.useState(false)

	const currentThumbnailSize = useSelector(
		(store: StoreInterface) => store.misc.thumbnailSize
	)
	const authToken = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.token
	)

	React.useEffect(() => {
		if (addedVideo && !thumbnail) {
			setLoader(true)
			setTimeout(() => {
				setThumbnail(createThumbnailUrl(addedVideo.data.hash, authToken))
				setLoader(false)
			}, 1500)
		}
		if (!addedVideo) {
			setThumbnail('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addedVideo])

	const renderCard = () => {
		if (type === 'text') {
			if (addedText) {
				return (
					<div
						className={styles.addedTextWrapper}
						id="add-greeting-card-text-added"
					>
						<div
							className={c(
								styles.text,
								currentThumbnailSize === LG && styles.lgContainer
							)}
						>
							{addedText}
						</div>
						<div className={styles.actions}>
							<Button
								type={BUTTON_TEXT}
								containerClass={styles.btnTextWrapper}
								buttonClass={styles.btnText}
								iconSide={ICON_LEFT}
								icon={<DeleteIcon htmlColor="#FFFFFF" />}
								onClick={(e) => {
									e.stopPropagation()
									handleDelete()
								}}
								id="add-greeting-card-delete"
							/>
							<Button
								type={BUTTON_TEXT}
								containerClass={styles.btnTextWrapper}
								buttonClass={styles.btnText}
								iconSide={ICON_LEFT}
								icon={<EditIcon htmlColor="#FFFFFF" />}
								label={t('labels.edit')}
								onClick={(e) => {
									e.stopPropagation()
									handleEdit(type)
								}}
								id="add-greeting-card-edit"
							/>
						</div>
					</div>
				)
			} else {
				return (
					<div className={styles.add} id="add-greeting-card-text">
						<TextGreetingIcon />
						<div>{t('labels.add_text_greeting')}</div>
					</div>
				)
			}
		} else if (type === 'video') {
			if (addedVideo) {
				return (
					<div
						className={c(
							styles.addedVideoWrapper,
							loader && styles.loadingVideo
						)}
						id="add-greeting-card-video-added"
					>
						{addedVideo && thumbnail && (
							<img
								className={c(
									styles.thumbnail,
									currentThumbnailSize === LG && styles.lgContainer
								)}
								src={thumbnail}
								alt="video greeting"
							/>
						)}
						{loader && (
							<div className={styles.loader}>
								<Loading width="40px" height="40px" />
							</div>
						)}
						{!loader && (
							<div className={styles.actions}>
								<Button
									type={BUTTON_TEXT}
									containerClass={styles.btnTextWrapper}
									buttonClass={styles.btnText}
									iconSide={ICON_LEFT}
									icon={<DeleteIcon htmlColor="#FFFFFF" />}
									onClick={(e) => {
										e.stopPropagation()
										setThumbnail('')
										handleDelete()
									}}
									id="add-greeting-card-delete"
								/>
								<Button
									type={BUTTON_TEXT}
									containerClass={styles.btnTextWrapper}
									buttonClass={styles.btnText}
									iconSide={ICON_LEFT}
									icon={<PlayIcon htmlColor="#FFFFFF" />}
									label={t('buttons.play')}
									onClick={(e) => {
										e.stopPropagation()
										dispatch(
											setTempValue(
												'fileForPreview',
												addedVideo?.data.originalFileObject
											)
										)
									}}
									id="add-greeting-card-play"
								/>
							</div>
						)}
					</div>
				)
			}
			return (
				<div className={styles.add} id="add-greeting-card-video">
					<VideoGreetingIcon />
					<div>{t('labels.add_video_greeting')}</div>
					{typeof videoUploadPercent === 'number' && videoUploadPercent > 0 && (
						<div className={styles.videoUploadPercent}>
							{`${t('misc.uploading')}: ${videoUploadPercent} %`}
						</div>
					)}
				</div>
			)
		}
	}

	return (
		<div
			className={c(
				styles.wrapper,
				currentThumbnailSize === LG && styles.lgContainer
			)}
			onClick={() => {
				if (type === 'text' && !addedText) {
					action(type)
				} else if (type === 'video' && !addedVideo) {
					action(type)
				} else {
					return null
				}
			}}
		>
			{renderCard()}
		</div>
	)
}

export default AddGreetingCard
