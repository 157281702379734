import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import useFingerprint from 'use-fingerprint'
import { useTimer } from 'use-timer'
import { format } from 'date-fns'

import styles from './FilePreview.module.scss'

import {
	IMAGE_TYPES,
	MSDOC_TYPES,
	PDF,
	YOUTUBE,
	VIMEO,
	VIDEOS,
	URL
} from '../../../../utils/consts'
import { setTempValue } from '../../../../store/actions/temp'
import { createImageUrl } from '../../../../utils/helpers/decideThumbanil'
import { getPreviewVideoUrl } from '../../../../utils/helpers/fileUrl'
import isIOS from '../../../../utils/helpers/isIOS'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import { addTokenToSlideTitle } from '../../../../utils/helpers/models/slide'
import { StoreInterface } from '../../../../utils/interfaces'

const FilePreview = () => {
	const dispatch = useDispatch()
	const fingerPrint = useFingerprint()
	const { time, start, reset } = useTimer()
	const { fileForPreview } = useSelector((store: StoreInterface) => store.temp)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)

	useEffect(() => {
		if (fileForPreview) {
			start()
		}
		return () => {
			reset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileForPreview])

	/**
	 *
	 * @description renders the slide
	 * @returns {HTMLElement} image html node
	 */
	const renderSlide = () => {
		if (IMAGE_TYPES.includes(fileForPreview.type.toUpperCase())) {
			return (
				<img
					src={createImageUrl(
						fileForPreview.checksum,
						authToken,
						fileForPreview._type
					)}
					alt={fileForPreview.name}
				/>
			)
		}
		if (
			MSDOC_TYPES.findIndex(
				(item) => item.toLowerCase() === fileForPreview.type.toLowerCase()
			) > -1
		) {
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<iframe
							title={fileForPreview.name}
							className={styles.frame}
							src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
								`${sfApiRoot}/files/assets/files/${
									fileForPreview.checksum
								}?oauth=${encodeURIComponent(authToken)}`
							)}`}
						/>
					</div>
				</div>
			)
		}
		if (fileForPreview.type.toLowerCase() === PDF.toLowerCase()) {
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<iframe
							title={fileForPreview.name}
							className={styles.frame}
							src={
								isIOS()
									? `https://pdfviewer.salesfra.me/?file=${encodeURIComponent(
											`${sfApiRoot}/files/assets/files/${
												fileForPreview.checksum
											}.pdf?oauth=${encodeURIComponent(authToken)}`
									  )}`
									: `${sfApiRoot}/files/assets/files/${
											fileForPreview.checksum
									  }?oauth=${encodeURIComponent(authToken)}`
							}
						/>
					</div>
				</div>
			)
		}
		if (fileForPreview.type.toLowerCase() === YOUTUBE.toLowerCase()) {
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<iframe
							title={fileForPreview.name}
							className={styles.frame}
							src={`https://www.youtube.com/embed/${fileForPreview.external_id}`}
							frameBorder="0"
						/>
					</div>
				</div>
			)
		}
		if (fileForPreview.type.toLowerCase() === VIMEO.toLowerCase()) {
			const externalId = fileForPreview.external_id.toString()
			const vimeoId = externalId.includes('/')
				? externalId.split('/')[0]
				: externalId
			const hash = externalId.includes('/')
				? `&h=${externalId.split('/')[1]}`
				: ''
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<iframe
							title={fileForPreview.name}
							className={styles.frame}
							src={`https://player.vimeo.com/video/${vimeoId}?color=ffffff&title=0&byline=0&portrait=0${hash}`}
							frameBorder="0"
						/>
					</div>
				</div>
			)
		}
		if (
			VIDEOS.findIndex(
				(item) => item.toLowerCase() === fileForPreview.type.toLowerCase()
			) > -1
		) {
			const videoPreview = getPreviewVideoUrl(
				fileForPreview.checksum,
				fileForPreview.extension,
				authToken
			)
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<video controls={true} className={styles.frame}>
							<source type={videoPreview.fileType} src={videoPreview.fileUrl} />
							<track kind="captions" label={fileForPreview.name} />
						</video>
					</div>
				</div>
			)
		}
		if (fileForPreview.type.toLowerCase() === URL.toLowerCase()) {
			return (
				<div className={styles.frameContainer}>
					<div className={styles.frameContent}>
						<iframe
							title={fileForPreview.name}
							className={styles.frame}
							src={
								addTokenToSlideTitle(fileForPreview)
									? `${fileForPreview.title}/${encodeURIComponent(authToken)}`
									: fileForPreview.title
							}
						/>
					</div>
				</div>
			)
		}
		return null
	}

	const closePreviewHandler = () => {
		const objectToDispatch = {
			event: 'presentation.material.time-on-slide',
			checksum: fileForPreview.checksum,
			event_timestamp: format(new Date(), 'yyyy-MM-dd hh:mm'),
			id: fileForPreview.id,
			name: fileForPreview.name,
			origin: fileForPreview._type,
			parent_file: fileForPreview.parent_file || '',
			parent_file_name: fileForPreview._file ? fileForPreview._file.name : '',
			status: fileForPreview.status,
			tag_id: fileForPreview.tags_id,
			time: time > 5400 ? 5400 : time,
			type: fileForPreview.type,
			uuid: fingerPrint,
			fingerprint: fingerPrint,
			modal: 'preview'
		}
		dispatch(addToAnalyticsBatch(objectToDispatch))
		reset()
		dispatch(setTempValue('fileForPreview', undefined))
	}

	return (
		<AnimatePresence>
			{fileForPreview && (
				<motion.div
					className={styles.filePreviewRootContainer}
					animate={fileForPreview ? 'visible' : 'hidden'}
					exit="exit"
					initial="hidden"
					variants={{
						visible: {
							y: 0,
							opacity: 1
						},
						hidden: {
							y: 20,
							opacity: 0
						},
						exit: {
							y: -10,
							opacity: 0
						}
					}}
				>
					<div onClick={closePreviewHandler} className={styles.backdrop} />
					{renderSlide()}
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default FilePreview
