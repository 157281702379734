import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import styles from './RootGallery.module.scss'
import { getShapeClass } from '../ContentPane/GalleryItem'
import { convertBusinessRules, isTagVisible } from '../../../../utils/convertBusinessRules'
import { createImageUrl } from '../../../../utils/helpers/decideThumbanil'
import { LAYOUT, STORYBOARD } from '../../../../utils/consts'

const TagContainer = styled(motion.ul)`
	grid-template-columns: ${props => props.gridColumn};
	grid-gap: ${props => props.paddingBetweenItems}px;
`

const RootContainer = styled.div`
	width: ${props => props.galleryWidth}%;
`

const Item = styled(motion.li)`
	background-color: ${props => props.backgroundColor};
`

const Tag = styled.p`
	font-family: ${props => props.fontFamily};
`

const RootGallery = ({
	rootLevelTags,
	onTagClick,
	noHiddenTags,
}) => {
	const {
		itemsPerRow,
		galleryWidth,
		paddingBetweenItems,
		shape,
		shapeShadow,
		shapeBackgroundColor,
		textShadow,
		useFolderIcons,
	} = useSelector(store => store.layout.misc.rootLevelGalleryOptions)
	const layoutSettings = useSelector(state => state.layout)
	const authToken = useSelector(state => state.authUser.user.token)
	const shortCutsList = useSelector(store => store.tags.shortcuts)
	const currentUserGroup = useSelector(store => store.authUser.user.user.userGroupId)
	const { selectedTag } = useSelector(store => store.storyboardContext)

	/**
	 *
	 * @description returns either and Image node
	 * if there's image added to the tag
	 * or a TextNode with the name of the tag
	 * @param {object} tag object representation of a tag
	 * @returns {HTMLElement} HTMLEement of the tag in the gallery
	 */
	function renderImage(tag) {
		const imageUrl = convertBusinessRules(tag.business_rules).folderIconImage
		let nodeElement
		if (imageUrl && useFolderIcons) {
			nodeElement = (
				<img
					className={styles.tagGalleryItem}
					src={createImageUrl(imageUrl, authToken)}
					alt={tag.name}
				/>
			)
		} else {
			nodeElement = (
				<Tag
					className={classnames(
						styles.tagName,
						textShadow ? styles.hasTextShadow : '',
					)}
					fontFamily={
						layoutSettings.storyBoard.storyBoardFont.family
					}
				>
					{tag.name}
				</Tag>
			)
		}
		return nodeElement
	}

	const isShortCut = tag => shortCutsList.some(shortcut => shortcut.tag_id === tag.tag_id)

	const isVisible = tag => isTagVisible(
		convertBusinessRules(tag.business_rules),
		currentUserGroup, STORYBOARD,
	)

	const selectedOpacity = id => {
		if (selectedTag && !isEmpty(selectedTag)) {
			if (selectedTag.id !== id) {
				return 0.75
			}
		}
		return 1
	}

	return (
		<RootContainer
			className={styles.rootGalleryRootContainer}
			galleryWidth={galleryWidth}
		>
			<TagContainer
				paddingBetweenItems={paddingBetweenItems}
				gridColumn={`repeat(${
					itemsPerRow
						> rootLevelTags.length
						? rootLevelTags.length
						: itemsPerRow
				}, 1fr)`}
			>
				{
					rootLevelTags.map((tag, index) => (
						!isShortCut(tag) && (isVisible(tag) || noHiddenTags === LAYOUT) && (
							<Item
								custom={index}
								backgroundColor={shapeBackgroundColor}
								onClick={() => onTagClick(tag, 0)}
								className={classnames(
									styles[getShapeClass(shape)],
									shapeShadow ? styles.hasShadow : '',
								)}
								whileHover={{ scale: 1.05 }}
								whileTap={{ scale: 0.99 }}
								initial={{
									opacity: 0,
									y: 10,
								}}
								animate={isVisible(tag) ? {
									y: 0,
									x: 0,
									opacity: selectedOpacity(tag.id),
									transition: {
										staggerChildren: 0.4,
										delay: selectedOpacity(tag.id) === 1 ? index * 0.05 : 0,
										when: 'beforeChildren',
									},
								} : {
									y: 0,
									x: 0,
									opacity: 0.5,
									transition: {
										staggerChildren: 0.4,
										delay: index * 0.05,
										when: 'beforeChildren',
									},
								}}
								exit={{
									y: -10,
									opacity: 0,
									x: 0,
									transition: {
										staggerChildren: 0.4,
										delay: index * 0.05,
										when: 'beforeChildren',
									},
								}}
								transition={{
									y: { stiffness: 1000, velocity: -100 },
								}}
							>
								{
									renderImage(tag)
								}
							</Item>
						)
					))
				}
			</TagContainer>
		</RootContainer>
	)
}

RootGallery.defaultProps = {
	noHiddenTags: true,
}

RootGallery.propTypes = {
	noHiddenTags: PropTypes.bool,
	onTagClick: PropTypes.func.isRequired,
	rootLevelTags: PropTypes.arrayOf([
		PropTypes.objectOf(
			PropTypes.any,
		),
	]).isRequired,
}

export default RootGallery
