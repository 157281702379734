import validator from 'validator'

import { t } from '../languages/i18n'

export const validatePassword = (string: string) => {
	if(!validator.isStrongPassword(string))
		return t('misc.password-rule')
	return ''
}

export const validatePasswordConfirmation = (password: string, passwordConfirmation: string) => {
	if(password !== passwordConfirmation)
		return t('misc.password-not-match')
	return ''
}
