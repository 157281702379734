import React, { useState, useEffect } from 'react'
import Proptypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import hexToRgba from 'hex-to-rgba'

import styles from '../../../../components/common/Background/Background.module.scss'
import { createImageUrl } from '../../../../utils/helpers/decideThumbanil'
import defaultBgImage from '../../../../assets/images/default-background.jpg'

const BackgroundContainer = styled(motion.div)`
	z-index: -1;
	background: ${(props) => props.backgroundColor};
	transform: scale(${(props) => props.scale});
	background-size: cover;
	background-position: inherit;
`

/**
 * @author zilahir
 * @function Background
 * */

const Background = ({ selectedMenus }) => {
	const layoutSettings = useSelector((state) => state.layout)
	const backgroundImages = useSelector(
		(state) => state.storyboardContext.backgroundImage
	)
	const [bgImages, setBgImages] = useState([])

	const authToken = useSelector((state) => state.authUser.user.token)
	const opacity = 1 - layoutSettings.background.backgroundImageOpacity * 0.01
	// const bgImages = Object.keys(backgroundImages)
	const isParallaxEffectEnabled = useSelector(
		(state) => state.layout.misc.parallaxScrollingBackgound
	)

	const setSelectedMenus = () => {
		const newBgImages = []
		selectedMenus.forEach((item) => {
			if (item.business_rules) {
				const businessRules = JSON.parse(item.business_rules)
				if (businessRules['background-image']) {
					newBgImages.push(
						createImageUrl(businessRules['background-image'], authToken)
					)
				}
			}
		})
		if (newBgImages.length === 0) {
			if (layoutSettings.background.backgroundImage) {
				newBgImages.push([
					createImageUrl(layoutSettings.background.backgroundImage, authToken)
				])
			} else {
				newBgImages.push(defaultBgImage)
			}
		}
		setBgImages(newBgImages)
	}

	useEffect(() => {
		setSelectedMenus()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedMenus,
		backgroundImages,
		layoutSettings.background.backgroundImage
	])

	return (
		<AnimatePresence>
			{bgImages.map((currentBgImage, index) => (
				<BackgroundContainer
					scale={isParallaxEffectEnabled ? 1.4 : 1}
					key={`${currentBgImage}-${index}`}
					backgroundColor={`linear-gradient(0deg,
							${hexToRgba(layoutSettings.background.backgroundColor, opacity)},
							${hexToRgba(layoutSettings.background.backgroundColor, opacity)}),
							url(${currentBgImage}) no-repeat fixed center;`}
					className={styles.backgroundContainer}
					initial={{
						opacity: 0,
						x: isParallaxEffectEnabled ? -(selectedMenus.length * 20) : 0,
						scale: isParallaxEffectEnabled ? 1.4 : 1
					}}
					animate={{
						opacity: 1,
						x: isParallaxEffectEnabled ? -(selectedMenus.length * 20) : 0,
						scale: isParallaxEffectEnabled ? 1.4 : 1
					}}
					exit={{
						opacity: 0
					}}
					transition={{
						duration: 0.2
					}}
				/>
			))}
		</AnimatePresence>
	)
}

Background.defaultProps = {
	currentLevel: 0
}

Background.propTypes = {
	currentLevel: Proptypes.number
}

export default Background
