import {
	ADD_TO_TEMP_PRESENTATION,
	CLEAR_TEMP_PRESENTATION,
	CLEAR_TEMP_REDUCER,
	RESOLVE_CRITICAL_ERROR,
	SET_GALLERY_SHALLOW_COPY,
	SET_LAYOUT_SHALLOW_COPY,
	SET_TEMP_SHORTCUTS,
	THROW_CRITICAL_ERROR,
	SET_TEMP_VALUE
} from '../actions/actionTypes'

const initialState = {
	slides: {
		content_pane: [],
		presentation_bar: [],
		my_memosites: [],
		search_modal: []
	},
	tempShortcuts: [],
	layoutShallowCopy: null,
	galleryOptionsShallowCopy: null,
	criticalError: {
		isVisible: false,
		message: false
	},
	conversionFiles: [],
	updateConversionFolderId: null,
	showTermsModal: false,
	termsAccepted: {
		isNew: true,
		value: false
	},
	instanceName: null,
	crmSettings: null,
	emailBcc: null,
	fileForPreview: undefined,
	printToPdfEnabled: null,
	pdfOptimized: false,
	memositeBuilderIntroDisabled: false,
	isMemositePublished: false,
	supportedMimeType: null,
	adminFileToPreview: undefined,
	unresolvedFlaggedContentCount: 0,
	libraryHomepageVisible: false,
	libraryTreeSelectedTagId: undefined,
	showLogoutAndBackInModal: false,
	collaboraData: undefined,
	showCloseModal: false,
	stropheConnection: undefined,
	collaoraSavingFiles: [],
	isSidebarOpened: true,
	showFullScreenLoader: false,
	salesforceOpportunityId: undefined,
	splitFilesList: [],
	emptySplitSlideCards: [],
	addDocumentToPresentationDeck: false,
	uploadManagerModal: {
		isShowing: false,
		role: 'my_files',
		modalType: 'modal'
	},
	generatePdfWidget: {
		visibility: false,
		pdf: ''
	},
	uploadManagerFiles: [],
	myFilesHomepageVisible: false,
	sidebarNavsDisabled: false,
	presentationBarVisible: false,
	dashboardDiscoverBlockVisible: false
}

const reducer: any = (state: any = initialState, action: any) => {
	switch (action.type) {
		case ADD_TO_TEMP_PRESENTATION: {
			if (state.slides[action.payload.key].includes(action.payload.slide)) {
				const filteredSlides = state.slides[action.payload.key].filter(
					(slide: any) => slide.key !== action.payload.slide.key
				)
				return {
					...state,
					slides: {
						...state.slides,
						[action.payload.key]: filteredSlides
					}
				}
			}
			return {
				...state,
				slides: {
					...state.slides,
					[action.payload.key]: [
						...state.slides[action.payload.key],
						action.payload.slide
					]
				}
			}
		}
		case CLEAR_TEMP_REDUCER:
			return {
				state: initialState
			}
		case CLEAR_TEMP_PRESENTATION:
			return {
				...state,
				slides: {
					...state.slides,
					[action.payload.key]: []
				}
			}
		case SET_TEMP_SHORTCUTS:
			return {
				...state,
				tempShortcuts: action.payload.shortcuts
			}
		case SET_LAYOUT_SHALLOW_COPY:
			return {
				...state,
				layoutShallowCopy: action.payload.shallowCopy
			}
		case SET_GALLERY_SHALLOW_COPY:
			return {
				...state,
				galleryOptionsShallowCopy: action.payload.shallowCopy
			}
		case THROW_CRITICAL_ERROR:
			return {
				...state,
				criticalError: {
					isVisible: true,
					message: action.payload.errorMessage
				}
			}
		case RESOLVE_CRITICAL_ERROR:
			return {
				...state,
				criticalError: {
					isVisible: false,
					message: initialState.criticalError.message
				}
			}
		case SET_TEMP_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		default:
			return state
	}
}

export default reducer
