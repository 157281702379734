import React from 'react'
import classnames from 'classnames'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import ReactDOM from 'react-dom'

import styles from './ModalNew.module.scss'

interface ModalNewInterface {
	after?: React.ReactNode | React.ReactNode[]
	before?: React.ReactNode | React.ReactNode[]
	bottom?: string
	children: React.ReactNode | React.ReactNode[]
	contentClassName?: string
	customClassName: string
	disableOverlayClose?: boolean
	footer?: React.ReactNode | React.ReactNode[]
	hasCloseIcon?: boolean
	headerClassname?: string
	hide: () => void
	isShowing: boolean
	left?: string
	modalClassName?: string
	modalTitle?: string
	modalTitleIcon?: React.ReactNode
	overlayColor?: string
	overlayClassName?: string
	selector?: string | any
	spaceFooter?: boolean
	top?: string
	zIndex?: number
}

interface ModalOverlayInterface {
	overlayColor?: string
	zIndex: number
}

interface IconContainerInterface {
	iconColor: string
}

interface ModalWrapperInterface {
	top: string
	bottom: string
	left: string
	zIndex: number
}

const ModalOverlay = styled('div')<ModalOverlayInterface>`
	background: ${(props) => props.overlayColor};
	z-index: ${(props) => props.zIndex};
`

const IconContainer = styled('div')<IconContainerInterface>`
	color: ${(props) => props.iconColor};
`

const ModalWrapper = styled('div')<ModalWrapperInterface>`
	top: ${(props) => props.top};
	bottom: ${(props) => props.bottom};
	left: ${(props) => props.left};
	z-index: ${(props) => props.zIndex};
`

const ModalNew = ({
	after,
	before,
	bottom = '0',
	children,
	contentClassName,
	customClassName,
	disableOverlayClose,
	footer,
	hasCloseIcon = true,
	headerClassname,
	hide,
	isShowing,
	left = '0',
	modalClassName = styles.modalWrapper,
	modalTitle = '',
	modalTitleIcon,
	overlayClassName = styles.modalOverlay,
	overlayColor,
	top = '0',
	selector = document.body,
	spaceFooter,
	zIndex = 9999
}: ModalNewInterface) =>
	isShowing
		? ReactDOM.createPortal(
				<React.Fragment>
					<ModalOverlay
						overlayColor={overlayColor && overlayColor}
						className={overlayClassName}
						onClick={disableOverlayClose ? hide : () => null}
						zIndex={zIndex}
					/>
					<ModalWrapper
						className={modalClassName}
						aria-modal
						aria-hidden
						tabIndex={-1}
						role="dialog"
						top={top}
						bottom={bottom}
						left={left}
						zIndex={zIndex}
					>
						<React.Fragment>
							{before && before}
							<div className={classnames(styles.modal, customClassName)}>
								<div className={classnames(styles.header, headerClassname)}>
									{modalTitle ? (
										<h3>
											{modalTitleIcon && modalTitleIcon} {modalTitle}
										</h3>
									) : null}
									{hasCloseIcon && (
										<button
											type="button"
											className={styles.closeBtn}
											data-dismiss="modal"
											aria-label="Close"
											onClick={hide}
										>
											<IconContainer iconColor="#ffffff">
												<CloseIcon htmlColor="#ffffff" />
											</IconContainer>
										</button>
									)}
								</div>
								{children && (
									<div className={classnames(styles.content, contentClassName)}>
										{children}
									</div>
								)}
								{footer && (
									<div
										className={
											spaceFooter ? styles.spacedFooter : styles.footer
										}
									>
										{footer}
									</div>
								)}
							</div>
							{after && after}
						</React.Fragment>
					</ModalWrapper>
				</React.Fragment>,
				selector
		  )
		: null

export default ModalNew
