import Cookie from 'js-cookie'
import queryString from 'query-string'

const createQueryUrl = (location: any) => {
	const urlParams = queryString.parse(location.search)
	return Array.isArray(Object.keys(urlParams)) &&
		Object.keys(urlParams).length > 0
		? `|||${Object.keys(urlParams)[0]}`
		: ''
}

const getEmbedUrl = (
	location: any,
	businessRules: any,
	refreshToken: string,
	authToken: string
) => {
	try {
		if (businessRules.embeddedUrl.includes('salesfra.me')) {
			return `${
				businessRules.embeddedUrl
			}?${refreshToken}|||${authToken}${createQueryUrl(location)}`
		}
		if (businessRules.aad_token && Cookie.get('AAD')) {
			return `${businessRules.embeddedUrl}?refresh_token=${Cookie.get('AAD')}`
		}
		return businessRules.embeddedUrl
	} catch {
		console.error('No embedded content')
	}
	return false
}

export default getEmbedUrl
