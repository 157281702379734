import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import RatingMaterial from '@material-ui/lab/Rating'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'

const StyledRating = withStyles({
	iconFilled: {
		color: '#12E2B0',
		fontSize: '16px'
	},

	iconEmpty: {
		color: '#ffffff',
		fontSize: '16px',
		opacity: 0.5
	}
})(RatingMaterial)

const Rating = ({ rating }: { rating: number }) => (
	<StyledRating
		name="customized-color"
		emptyIcon={<StarBorderOutlinedIcon fontSize="inherit" />}
		value={rating ? rating : 0}
		precision={0.1}
		readOnly
	/>
)

export default Rating
