import React, { useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { has } from 'lodash'
import Cookies from 'js-cookie'
import c from 'classnames'

import styles from './CRMSecto.module.scss'

import Modal from '../../common/ModalNew'
import Spinner from '../../common/Spinner'
import { t } from '../../../utils/languages/i18n'
import Section from '../../common/Section'
import Button from '../../common/Button'
import { BUTTON_TEXT, FLEX_END } from '../../../utils/consts'
import Input from '../../common/Input'
import Results from './Results'
import {
	searchOpportunity,
	SectoSearchOpportunityInterface,
	sendCrmSectoData
} from '../../../api/requests/crmSecto'
import { getMemositeLink } from '../../../utils/helpers/memosites'
import { StoreInterface } from '../../../utils/interfaces'

interface CRMSectoInterface {
	handleClose: () => void
	memosite: any
	saveButtonDisabled: boolean
	zIndex?: number
}

const RESULTS_STEP = 30

const CRMSecto = ({
	handleClose,
	memosite,
	saveButtonDisabled = false,
	zIndex = 9999
}: CRMSectoInterface) => {
	const cookies = Cookies.get()
	const [searchResults, setSearchResults] = useState<any[]>([])
	const [searchResultsSlice, setSearchResultsSlice] = useState<any[]>([])
	const [accountName, setAccountName] = useState('')
	const [selectedCustomer, setSelectedCustomer] = useState<
		SectoSearchOpportunityInterface | undefined
	>(undefined)
	const [startPos, setStartPos] = useState(RESULTS_STEP)
	const [isLoading, setIsLoading] = useState(false)
	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const instanceName = useSelector(
		(state: StoreInterface) => state.temp.instanceName
	)

	const reset = () => {
		setAccountName('')
		setSearchResults([])
		setSelectedCustomer(undefined)
	}
	const close = () => {
		reset()
		handleClose()
	}

	const hasValidCookies = useCallback(() => has(cookies, 'SFDC'), [cookies])

	const handleSearch = () => {
		setSearchResults([])
		setSearchResultsSlice([])
		setSelectedCustomer(undefined)
		setIsLoading(true)
		setStartPos(RESULTS_STEP + 1)
		searchOpportunity(accountName)
			.then((result) => {
				setSearchResults(result)
				setSearchResultsSlice(
					result.length > RESULTS_STEP ? result.slice(0, RESULTS_STEP) : result
				)
				setIsLoading(false)
			})
			.catch(() => {
				const authCookies = hasValidCookies()
				if (!authCookies) {
					toast(t('notifications.error.necessary-cookies-not-found'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: 'error',
						autoClose: 5000
					})
					setIsLoading(false)
				}
			})
	}

	const handleMoreResults = () => {
		setStartPos(startPos + RESULTS_STEP)
		setSearchResultsSlice([...searchResults.slice(0, startPos + RESULTS_STEP)])
	}

	const escapeHtml = (str: string) =>
		str
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;')

	const handleSend = () => {
		setIsLoading(true)
		const memoUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			authUser.user.role,
			true
		)
		if (selectedCustomer) {
			const data = {
				content: `<b>${escapeHtml(
					'A memosite was created in Salesframe'
				)}</b><p>By user ${authUser.user.firstname} ${
					authUser.user.lastname
				} ${new Date().toDateString()}</p> <p><b>${escapeHtml(memoUrl)}${
					window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
				}analytics=false</b></p>`,
				accountId: selectedCustomer.AccountId,
				title: memosite.subject,
				userId: authUser.user.id,
				meeting_id: memosite.id
			}
			sendCrmSectoData(data)
				.then(() => {
					close()
					setSearchResults([])
					toast(t('notifications.success.crm-data-sent'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: toast.TYPE.SUCCESS,
						autoClose: 5000
					})
					setIsLoading(false)
				})
				.catch(() => {
					toast(t('notifications.error.crm-data-not-sent'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: toast.TYPE.ERROR,
						autoClose: 5000
					})
					setIsLoading(false)
				})
		}
	}

	const getCrmInputs = () => {
		return (
			<div>
				<div className={styles.crmContainer}>
					<Input
						onChange={(event: any) => setAccountName(event.target.value)}
						placeholderText={t('input-placeholders.search-account-name')}
						name="accountName"
						initialValue={accountName}
						inputClassName={styles.input}
					/>
					<Button
						onClick={handleSearch}
						isPositive
						label={t('labels.search')}
						buttonClass={styles.btn}
						isDisabled={saveButtonDisabled}
						id="crm-secto-search"
					/>
				</div>
				<div
					className={c(
						searchResults && styles.notesContainer,
						isLoading && styles.spinnerVisible
					)}
				>
					{isLoading ? <Spinner isLoading size={44} /> : null}
					{searchResultsSlice.length > 0 && (
						<div className={styles.labels}>
							<div>{t('input-placeholders.account-name')}</div>
						</div>
					)}
					{searchResultsSlice.length > 0 &&
						searchResultsSlice.map((customer, index) => (
							<Results
								customer={customer}
								isSelected={selectedCustomer === customer}
								key={index}
								setSelectedCustomer={setSelectedCustomer}
							/>
						))}
					{startPos < searchResults.length ? (
						<Button
							type={BUTTON_TEXT}
							onClick={handleMoreResults}
							label={t('labels.load-more')}
							buttonClass={styles.showMore}
							containerClass={styles.showMoreContainer}
							id="crm-secto-load-more"
						/>
					) : null}
				</div>
			</div>
		)
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={close}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>{t('labels.search-by-account-name')}</p>
			</div>
			{getCrmInputs()}
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px"
			>
				<Button
					onClick={close}
					label={t('buttons.cancel')}
					buttonClass={styles.btn}
					id="crm-secto-cancel"
				/>
				<Button
					onClick={handleSend}
					isPositive
					label={t('labels.send')}
					buttonClass={styles.btn}
					isDisabled={!selectedCustomer}
					id="crm-secto-send"
				/>
			</Section>
		</Modal>
	)
}

export default CRMSecto
