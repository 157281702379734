import React, { Fragment, useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { setTempValue } from '../../store/actions/temp'
import styles from './CollaboraEditor.module.scss'
import RenameFileModal from '../common/RenameFileModal'
import Input from '../common/Input'
import Modal from '../common/ModalNew'
import Button from '../common/Button'
import { t } from '../../utils/languages/i18n'
import { axiosInstance } from '../../utils/requests'
import { StoreInterface } from '../../utils/interfaces'
import collaboraOnlineStyles from './collaboraOnlineStyles'
import CheckBox from '../common/CheckBox'
import { store } from '../../store/configureStore'
import { wopiSaveAsOptimized, wopiSaveEdited } from '../../api/apiEndpoints_new'
import Dropdown from '../common/Dropdown'
import {
	isEditableDocument,
	isEditableSlide
} from '../../utils/helpers/isEditableSlide'
import { getAllMyFilesCategories } from '../../store/actions/myfiles'

const CollaboraEditor = () => {
	const dispatch = useDispatch()
	const {
		collaboraData,
		showCloseModal,
		collaoraSavingFiles,
		addDocumentToPresentationDeck
	} = useSelector((state: StoreInterface) => state.temp)
	const { user } = useSelector((state: StoreInterface) => state.authUser)
	const { myFilesCategories } = useSelector(
		(state: StoreInterface) => state.myfiles
	)
	const { presentationBarSlides } = useSelector(
		(state: StoreInterface) => state.presentation
	)

	const formRef = useRef<HTMLFormElement>(null)
	const frameRef = useRef<HTMLIFrameElement>(null)
	const isSaving = useRef<boolean>(false)
	const [showSaveAsModal, setShowSaveAsModal] = useState(false)
	const [newFileName, setNewFileName] = useState('')
	const [documentLoaded, setDocumentLoaded] = useState(false)
	const [isDocumentModified, setIsDocumentModified] = useState(false)
	const [disabledSaveAndCloseBtn, setDisabledSaveAndCloseBtn] = useState(false)
	const [myFilesFolders, setMyFilesFolders] = useState<any[]>([])
	const [selectedSaveAsFolder, setSelectedSaveAsFolder] =
		useState<any>(undefined)
	const [replaceModalFileData, setReplaceModalFileData] = useState<
		undefined | { filename: string; fileType: string; checksum: string }
	>(undefined)

	useEffect(() => {
		window.addEventListener('message', handleCollaboraPostMessage)
		document.getElementsByTagName('body')[0].style.overflow = 'hidden'
		formRef.current?.submit()
		if (isEditableDocument(collaboraData.file.type)) {
			setNewFileName(
				collaboraData.file.name.replace(/(\.docx|\.doc|\.xlsx|\.xls)$/i, '')
			)
		} else if (
			collaboraData.file._file &&
			isEditableSlide(collaboraData.file._file.type)
		) {
			setNewFileName(
				collaboraData.file
					? collaboraData.file.name.replace(/(\.pptx|\.ppt)$/i, '')
					: ''
			)
		}
		return () => {
			window.removeEventListener('message', handleCollaboraPostMessage)
			document.getElementsByTagName('body')[0].style.overflow = 'auto'
			setDocumentLoaded(false)
			setIsDocumentModified(false)
			dispatch(setTempValue('addDocumentToPresentationDeck', true))
			isSaving.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getDefaultSaveAsFolder = () => {
		if (collaboraData) {
			if (
				collaboraData.source === 'presentation_deck' ||
				collaboraData.source === 'memosite_builder'
			) {
				setSelectedSaveAsFolder(
					myFilesFolders.find(
						(currentFolder) => currentFolder.code === 'system_edited'
					)
				)
			} else if (collaboraData.file.status === 1) {
				// file opened from Library
				let folder = myFilesFolders.find(
					(currentFolder) => currentFolder.code === 'system_edited'
				)
				if (!folder) {
					folder = {
						id: 0,
						code: 'system_edited',
						name: 'Edited slides'
					}
				}
				setSelectedSaveAsFolder(folder)
			} else if (collaboraData.file.status === 11) {
				// file opened from My Files
				const folder = myFilesFolders.find(
					(currentFolder) => currentFolder.id === collaboraData.folderId
				)
				if (folder) {
					setSelectedSaveAsFolder(folder)
				}
			}
		}
	}

	const getAllMyFilesFolders = () => {
		// Check if folder called 'Edited slides' exists. If it doesn't fake it in the list
		if (
			myFilesCategories.findIndex((item) => item.code === 'system_edited') > -1
		) {
			setMyFilesFolders(
				myFilesCategories.map((fileCategory) => ({
					value: fileCategory.id,
					key: fileCategory.id,
					label: fileCategory.name,
					id: fileCategory.id,
					code: fileCategory.code
				}))
			)
			return
		}
		setMyFilesFolders([
			{
				value: 0,
				key: 0,
				label: 'Edited slides',
				id: 0,
				code: 'system_edited'
			},
			...myFilesCategories.map((fileCategory) => ({
				value: fileCategory.id,
				key: fileCategory.id,
				label: fileCategory.name,
				id: fileCategory.id,
				code: fileCategory.code
			}))
		])
	}

	useEffect(() => {
		if (collaboraData) {
			dispatch(
				setTempValue(
					'addDocumentToPresentationDeck',
					collaboraData.checkAddToPresentation
				)
			)
			getAllMyFilesFolders()
			if (myFilesFolders.length === 0) {
				dispatch(getAllMyFilesCategories())
			}
		} else {
			dispatch(setTempValue('addDocumentToPresentationDeck', false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collaboraData])

	useEffect(() => {
		if (myFilesFolders.length > 0) {
			getDefaultSaveAsFolder()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myFilesFolders])

	const updateFileName = (
		frameElement: Document | undefined,
		fileName: string
	) => {
		if (frameElement) {
			const documentTitleBar = frameElement.getElementById('document-titlebar')
			if (documentTitleBar) {
				const documentTitle =
					documentTitleBar.getElementsByClassName('document-title')
				if (documentTitle.length > 0) {
					documentTitle[0].innerHTML = `
						<div class="file-title">
							<p>${fileName}</p>
						</div>
					`
				}
			}
		}
	}

	const handleCollaboraPostMessage = (message: any) => {
		if (message.origin === window.location.origin) {
			if (typeof message.data === 'string') {
				try {
					const data = JSON.parse(message.data)
					if (
						data.MessageId === 'App_LoadingStatus' &&
						data.Values.Status === 'Document_Loaded'
					) {
						setDocumentLoaded(true)
						const frameDoc = frameRef?.current?.contentWindow?.document
						if (frameDoc) {
							// add styles
							const styleElement = document.createElement('style')
							frameDoc.head.appendChild(styleElement)
							styleElement.appendChild(
								document.createTextNode(collaboraOnlineStyles)
							)

							// change file name in top bar
							if (collaboraData.file) {
								let filename = ''
								if (isEditableDocument(collaboraData.file.type)) {
									filename = `${collaboraData.file.name.replace(
										/(\.docx|\.doc|\.xlsx|\.xls)$/i,
										''
									)}`
								}
								if (collaboraData.file._file) {
									filename = `${collaboraData.file.name.replace(
										/(\.pptx|\.ppt)$/i,
										''
									)}`
								}
								updateFileName(frameDoc, filename)
							}
						}
						frameRef?.current?.contentWindow?.postMessage(
							JSON.stringify({
								MessageId: 'Host_PostmessageReady',
								SendTime: Date.now()
							}),
							'*'
						)
					}
					if (data.MessageId === 'Doc_ModifiedStatus') {
						setIsDocumentModified(data.Values.Modified)
					}
					if (data.MessageId === 'Action_Save_Resp') {
						const reduxStore: any = store.getState()
						const showCloseModalRedux = reduxStore.temp.showCloseModal
						if (!showCloseModalRedux) {
							dispatch(setTempValue('showFullScreenLoader', false))
						}
						if (data.Values.success) {
							if (isSaving.current) {
								setIsDocumentModified(false)
								isSaving.current = false
								let checksum: string | undefined
								if (isEditableDocument(collaboraData?.file.type)) {
									checksum = collaboraData?.file.checksum
								} else {
									checksum = collaboraData?.file?._file?.checksum
								}
								if (checksum) {
									axiosInstance.post<{ checksum: string }>(
										wopiSaveAsOptimized,
										{
											checksum
										},
										{
											headers: {
												Authorization: `Bearer ${user.token}`
											}
										}
									)
								}
							}
							if (showCloseModalRedux) {
								sendLastDocumentToServer()
							}
							setTimeout(() => {
								if (showCloseModalRedux) {
									dispatch(setTempValue('showFullScreenLoader', false))
									setDisabledSaveAndCloseBtn(false)
									dispatch(setTempValue('showCloseModal', false))
									dispatch(setTempValue('collaboraData', undefined))
								}
							}, 1000)
						}
					}
				} catch (error) {
					console.error(error)
				}
			}
		}
	}

	const showSlideSavedNotification = () => {
		toast(t('notifications.success.slide_saved'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: 'success',
			autoClose: 5000
		})
	}

	const handleSave = () => {
		dispatch(setTempValue('showFullScreenLoader', true))
		if (showCloseModal) {
			setDisabledSaveAndCloseBtn(true)
		}
		let filename
		let fileType
		let checksum: string | undefined
		if (isEditableDocument(collaboraData.file.type)) {
			filename = `${newFileName.replace(/(\.docx|\.doc|\.xlsx|\.xls)$/i, '')}.${
				collaboraData.file.type
			}`
			fileType = collaboraData.file.type
			checksum = collaboraData.file.checksum
		}
		if (collaboraData.file._file) {
			filename = `${newFileName.replace(/(\.pptx|\.ppt)$/i, '')}.${
				collaboraData.file._file.type
			}`
			fileType = collaboraData.file._file.type
			checksum = collaboraData.file._file.checksum
		}
		if (filename && fileType) {
			dispatch(
				setTempValue('collaoraSavingFiles', [
					...collaoraSavingFiles,
					{
						name: filename,
						extension: fileType,
						checksum,
						fileId: collaboraData.file.id,
						folderId: collaboraData.folderId,
						isSave: true,
						addToPresentation: false
					}
				])
			)
			frameRef?.current?.contentWindow?.postMessage(
				JSON.stringify({
					MessageId: 'Action_Save',
					SendTime: Date.now(),
					Values: {
						DontTerminateEdit: true,
						DontSaveIfUnmodified: true,
						Notify: true
					}
				}),
				'*'
			)
			showSlideSavedNotification()
		}
	}

	const sendLastDocumentToServer = () => {
		dispatch(setTempValue('showFullScreenLoader', true))
		axiosInstance
			.post(
				wopiSaveEdited,
				{
					uuid: collaboraData.uuid,
					folder: collaboraData.folderId
				},
				{
					headers: {
						Authorization: `Bearer ${user.token}`
					}
				}
			)
			.catch(() => dispatch(setTempValue('showFullScreenLoader', false)))
	}

	const handleCloseCollabora = () => {
		sendLastDocumentToServer()
		dispatch(setTempValue('collaboraData', undefined))
		dispatch(setTempValue('showFullScreenLoader', false))
	}

	const sendSaveAsRequest = (
		filename: string,
		fileType: string,
		checksum: string,
		presentationDeckStatus: string = ''
	) => {
		dispatch(setTempValue('showFullScreenLoader', true))
		dispatch(
			setTempValue('collaoraSavingFiles', [
				...collaoraSavingFiles,
				{
					name: `${filename}.${fileType}`,
					extension: fileType,
					checksum,
					fileId: collaboraData.file.id,
					folderId: selectedSaveAsFolder?.id,
					isSave: false,
					addToPresentation: addDocumentToPresentationDeck,
					presentationDeckStatus
				}
			])
		)
		isSaving.current = true
		frameRef?.current?.contentWindow?.postMessage(
			JSON.stringify({
				MessageId: 'Action_SaveAs',
				SendTime: Date.now(),
				Values: {
					Filename: `${selectedSaveAsFolder?.id}|||${encodeURIComponent(
						filename
					)}.${fileType}`,
					Notify: true
				}
			}),
			'*'
		)
		setReplaceModalFileData(undefined)
		setShowSaveAsModal(false)
		showSlideSavedNotification()
	}

	const handleSaveAs = () => {
		let filename
		let fileType
		let checksum: string | undefined
		if (isEditableDocument(collaboraData.file.type)) {
			filename = newFileName.replace(/(\.docx|\.doc|\.xlsx|\.xls)$/i, '')
			fileType = collaboraData.file.type
			checksum = collaboraData.file.checksum
		}
		if (collaboraData.file._file) {
			filename = newFileName.replace(/(\.pptx|\.ppt)$/i, '')
			fileType = collaboraData.file._file.type
			checksum = collaboraData.file._file.checksum
		}
		if (filename && fileType && checksum) {
			if (
				addDocumentToPresentationDeck &&
				presentationBarSlides.findIndex((item) => {
					if (item._file) {
						return item._file.checksum === checksum
					}
					return item.checksum === checksum
				}) > -1
			) {
				setReplaceModalFileData({
					filename,
					fileType,
					checksum
				})
			} else {
				sendSaveAsRequest(filename, fileType, checksum)
			}
		}
	}

	const showIsFileSavingText = () => {
		let checksum: string | undefined
		if (isEditableDocument(collaboraData?.file.type)) {
			checksum = collaboraData?.file.checksum
		} else {
			checksum = collaboraData?.file?._file?.checksum
		}
		if (checksum) {
			return (
				collaoraSavingFiles.findIndex(
					(item) => item.checksum === checksum && !item.isSave
				) > -1
			)
		}
		return false
	}

	const renderSaveButton = () => {
		if (collaboraData.hideSaveButton) return <React.Fragment />
		if (collaboraData.file.status !== 1) {
			return (
				<Button
					containerClass={styles.headerBtnContainer}
					buttonClass={styles.btn}
					label={t('labels.save')}
					onClick={handleSave}
					id="collabora-editor-save"
				/>
			)
		}
		return <React.Fragment />
	}

	return (
		<Fragment>
			{collaboraData && (
				<Fragment>
					<form
						ref={formRef}
						action={collaboraData.url}
						encType="multipart/form-data"
						method="post"
						target="salesframe-editor"
					>
						<input
							name="access_token"
							value={collaboraData.token}
							type="hidden"
							id="access-token"
						/>
						<input
							name="ui_defaults"
							value="UIMode=tabbed;TextRuler=true;PresentationStatusbar=false;SpreadsheetSidebar=true"
							type="hidden"
						/>
						<input
							name="css_variables"
							value="--co-primary-element=#ff0000;--co-color-main-text=#000;--co-body-bg=#FFF;--co-txt-accent=#2e1a47;"
							type="hidden"
						/>
						<input
							name="checkfileinfo_override"
							value="ReadOnly=false"
							type="hidden"
						/>
						<input type="submit" value="" />
					</form>
					<div className={styles.collaboraEditorContainer}>
						<div className={styles.iframeContainer}>
							<div className={styles.slideEditingText}>
								{t('titles.slide_editing')}
							</div>
							<div className={styles.buttonsContainer}>
								<div className={styles.saveButtonsContainer}>
									{documentLoaded && (
										<React.Fragment>
											{showIsFileSavingText() ? (
												<div className={styles.savingContainer}>
													<p>Saving...</p>
												</div>
											) : (
												<React.Fragment>
													{renderSaveButton()}
													<Button
														containerClass={styles.headerBtnContainer}
														buttonClass={styles.btn}
														label={t('labels.save-as')}
														onClick={() => setShowSaveAsModal(true)}
														id="collabora-editor-save-as"
													/>
												</React.Fragment>
											)}
										</React.Fragment>
									)}
								</div>
								<Button
									containerClass={styles.headerBtnContainer}
									buttonClass={`${styles.btn} ${styles.btnClose}`}
									label={t('labels.close')}
									onClick={() => {
										if (isDocumentModified) {
											dispatch(setTempValue('showCloseModal', true))
										} else {
											handleCloseCollabora()
										}
									}}
									id="collabora-editor-close"
								/>
							</div>
							<iframe
								ref={frameRef}
								title="Salesframe editor"
								id="salesframe-editor"
								name="salesframe-editor"
							/>
						</div>
					</div>
					<RenameFileModal
						isActive={showSaveAsModal}
						title={t('labels.save-as')}
						handleClose={() => setShowSaveAsModal(false)}
						confirmLabel={t('buttons.save')}
						onApprove={handleSaveAs}
						zIndex={10001}
					>
						<>
							<p className={styles.saveAsText}>
								{t('misc.slide_editing_beta_feature')}&nbsp;
								<span id="collabora_save_as_beta">{t('labels.read_more')}</span>
							</p>
							{collaboraData.folderId === 0 && (
								<p className={styles.saveAsText}>
									{t('misc.collabora_save_as_to_edited_files')}
								</p>
							)}
							<div className={styles.inputFieldsWrapper}>
								<Input
									name="title"
									initialValue={newFileName}
									onChange={(event) => setNewFileName(event.target.value)}
									wrapperClassName={styles.inputWrapper}
									label={t('input-placeholders.file_name')}
								/>
								<div className={styles.inputWrapper}>
									<label htmlFor="selected_folder">
										Save into My Files folder
										<Dropdown
											listItems={myFilesFolders}
											name="selected_folder"
											value={selectedSaveAsFolder ? selectedSaveAsFolder : null}
											onChangeCallback={(value) =>
												setSelectedSaveAsFolder(value)
											}
										/>
									</label>
								</div>
							</div>
							<CheckBox
								onChange={() => {
									dispatch(
										setTempValue(
											'addDocumentToPresentationDeck',
											!addDocumentToPresentationDeck
										)
									)
								}}
								name="add_to_presentation"
								label={t('labels.add-to-presentation')}
								isChecked={addDocumentToPresentationDeck}
								wrapperClassName={styles.addToPresentationCheckboxWrapper}
							/>
						</>
					</RenameFileModal>
					<Modal
						isShowing={showCloseModal}
						hide={() => dispatch(setTempValue('showCloseModal', false))}
						customClassName={styles.closeModal}
						modalTitle={t('labels.close_editor')}
						zIndex={10000}
						footer={
							<div className={styles.footer}>
								<Button
									containerClass={styles.btnContainer}
									buttonClass={styles.btn}
									label={t('labels.cancel')}
									onClick={() =>
										dispatch(setTempValue('showCloseModal', false))
									}
									id="collabora-editor-cancel"
								/>
								<Button
									containerClass={styles.btnContainer}
									buttonClass={styles.btn}
									label={t('buttons.exit_without_saving')}
									isWarning
									onClick={() => {
										dispatch(setTempValue('showCloseModal', false))
										handleCloseCollabora()
									}}
									id="collabora-editor-exit-without-saving"
								/>
								<Button
									containerClass={styles.btnContainer}
									buttonClass={styles.btn}
									label={t('buttons.save_exit')}
									isPositive
									isDisabled={disabledSaveAndCloseBtn}
									onClick={
										collaboraData.folderId === 0
											? () => setShowSaveAsModal(true)
											: handleSave
									}
									id="collabora-editor-save-exit"
								/>
							</div>
						}
					>
						<div className={styles.modalContent}>
							<p>{t('misc.save_before_closing_collabora')}</p>
						</div>
					</Modal>
					<Modal
						isShowing={!!replaceModalFileData}
						hide={() => setReplaceModalFileData(undefined)}
						customClassName={styles.replaceFileModal}
						modalTitle={t('titles.replace_slide_in_presentation')}
						zIndex={10001}
						footer={
							<div className={styles.footer}>
								<Button
									containerClass={styles.btnContainer}
									buttonClass={styles.btn}
									label={t('labels.replace')}
									isPositive
									isDisabled={disabledSaveAndCloseBtn}
									onClick={() => {
										if (replaceModalFileData) {
											sendSaveAsRequest(
												replaceModalFileData.filename,
												replaceModalFileData.fileType,
												replaceModalFileData.checksum,
												'replace'
											)
										}
									}}
									id="collabora-editor-replace"
								/>
								<Button
									containerClass={styles.btnContainer}
									buttonClass={styles.btn}
									label={t('labels.add_as_new')}
									isPositive
									isDisabled={disabledSaveAndCloseBtn}
									onClick={() => {
										if (replaceModalFileData) {
											sendSaveAsRequest(
												replaceModalFileData.filename,
												replaceModalFileData.fileType,
												replaceModalFileData.checksum,
												'add_new'
											)
										}
									}}
									id="collabora-editor-add-as-new"
								/>
							</div>
						}
					>
						<div className={styles.modalContent}>
							<p>{t('misc.replace_file_in_presentation_deck_modal')}</p>
						</div>
					</Modal>
				</Fragment>
			)}
		</Fragment>
	)
}

export default CollaboraEditor
