import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch, useStore } from 'react-redux'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded'
import AddLinkIcon from '@mui/icons-material/AddLink'

import styles from './UploadContent.module.scss'
import {
	UPLOAD_DROPZONE,
	UPLOAD_BUTTON,
	BUTTON_TEXT,
	MY_FILES,
	CONVERSION_FILE_TYPES_ALLOWED,
	ICON_LEFT
} from '../../../utils/consts'
import Button from '../Button'
import { setMyFilesContent } from '../../../store/actions/myfiles'
import { addLoadingCards } from '../../../store/actions/buildContext'
import { uploadContent } from '../../../api/requests/content'
import { t } from '../../../utils/languages/i18n'
import { addToAnalyticsBatch } from '../../../store/actions/analytics'
import { setTempValue } from '../../../store/actions/temp'
import { getAddedFilesCategoryId } from '../../../utils/helpers/myFiles'
import AddLinkModal from '../../AddLinkModal'
import { StoreInterface } from '../../../utils/interfaces'

interface UploadContentInterface {
	type?: 'UPLOAD_DROPZONE' | 'UPLOAD_BUTTON'
	buttonClass?: string
	buttonnLabel?: string
	buttonContainerClass?: string
	icon?: JSX.Element
	handleFileUpload?: (files: any) => void
	multiple?: boolean
	acceptFiles?: string[]
	onFileUploaded?: (files: any) => void
	size?: 'LG' | 'SM'
	admin?: boolean
}

const UploadContent = ({
	type = UPLOAD_DROPZONE,
	buttonClass,
	buttonnLabel = 'Upload content',
	buttonContainerClass,
	icon,
	handleFileUpload,
	multiple = true,
	acceptFiles,
	onFileUploaded,
	size = 'SM',
	admin = false
}: UploadContentInterface) => {
	const rootReduxStore = useStore()

	const { conversionFiles, splitFilesList } = useSelector(
		(store: StoreInterface) => store.temp
	)
	const myFilesCategories = useSelector(
		(store: StoreInterface) => store.myfiles.myFilesCategories
	)
	const selectedCategory = useSelector(
		(store: StoreInterface) => store.buildContext.selectedCategory
	)

	const [isAddNewLinkModalVisible, toggleAddNewLinkModal] = useState(false)

	const dispatch = useDispatch()
	const onDrop = useCallback((files) => {
		if (typeof handleFileUpload !== 'function') {
			const categoryId =
				rootReduxStore.getState().buildContext.selectedCategory.id ||
				getAddedFilesCategoryId(myFilesCategories)
			const myFileContent = rootReduxStore.getState().myfiles.myFiles
			dispatch(addLoadingCards(files.length))
			uploadContent(files, categoryId).then((result: any) => {
				const validFiles: any[] = []
				const invalidFiles: any[] = []
				result.forEach((file: any) => {
					if (file.error) {
						invalidFiles.push(file)
					} else {
						validFiles.push(file)
					}
				})
				// adding to analytics batch
				dispatch(
					addToAnalyticsBatch({
						...validFiles,
						event: 'library.material.uploaded'
					})
				)
				toast(t('notifications.success.upload'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
				dispatch(addLoadingCards(0))
				dispatch(setMyFilesContent(myFileContent.concat(validFiles)))
				const uploadedFilesForConversion: any[] = []
				const uploadedSplitFilesList: any[] = []
				validFiles.forEach((file) => {
					if (CONVERSION_FILE_TYPES_ALLOWED.includes(file.type.toUpperCase())) {
						if (
							typeof file.conversion_needed === 'undefined' ||
							file.conversion_needed
						) {
							uploadedFilesForConversion.push({
								...file,
								intervalHandler: null,
								folderId: categoryId,
								started: new Date()
							})
						}
					}
					try {
						if (file.split_needed) {
							uploadedSplitFilesList.push({
								folderId: categoryId,
								status: file.status,
								checksum: file.checksum,
								reference: file.reference
							})
						}
					} catch (e) {
						console.error(e)
					}
				})
				if (uploadedFilesForConversion.length > 0) {
					dispatch(
						setTempValue('conversionFiles', [
							...conversionFiles,
							...uploadedFilesForConversion
						])
					)
					toast(t('notifications.success.uploaded-pptx-conversion-started'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: 'success',
						autoClose: 5000
					})
				}
				if (uploadedSplitFilesList.length > 0) {
					dispatch(
						setTempValue('splitFilesList', [
							...splitFilesList,
							...uploadedSplitFilesList
						])
					)
					toast(t('notifications.success.uploaded_pptx_split_started'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: 'success',
						autoClose: 5000
					})
				}
				if (invalidFiles.length > 0) {
					invalidFiles.forEach((file) => {
						toast(
							`${file.name} ${t(
								'notifications.error.filename-invalid-filetype'
							)}`,
							{
								position: toast.POSITION.BOTTOM_RIGHT,
								type: 'warning',
								autoClose: 5000
							}
						)
					})
				}
				if (onFileUploaded) {
					onFileUploaded([...validFiles])
				}
			})
		} else {
			handleFileUpload(files)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 * @description toggles add link modal
	 */
	const addLinkHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		toggleAddNewLinkModal(!isAddNewLinkModalVisible)
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple,
		accept: acceptFiles
	})
	return (
		<>
			{type === UPLOAD_DROPZONE ? (
				<div
					className={classnames(
						styles.uploadContentContainer,
						isDragActive ? styles.isDragging : null,
						styles[size.toLowerCase()]
					)}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<div className={styles.innerContainer}>
						<div className={styles.textContainer}>
							<p>{t('misc.empty-presentation')}</p>
							<div className={styles.flexWrapper}>
								<div className={styles.item}>
									<div>
										<NoteAddRoundedIcon />
									</div>
									{t('labels.browse-and-add-files')}
								</div>
								<div
									className={styles.item}
									onClick={(e) => addLinkHandler(e)}
									role="button"
									tabIndex={-1}
									onKeyDown={undefined}
								>
									<div>
										<AddLinkIcon />
									</div>
									{t('labels.add-a-link')}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : type === UPLOAD_BUTTON ? (
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					<Button
						label={buttonnLabel}
						onClick={() => null}
						containerClass={classnames(
							admin ? styles.textContainerAdmin : styles.uploadBtnContainer,
							buttonContainerClass
						)}
						icon={icon}
						iconSide={ICON_LEFT}
						buttonClass={classnames(styles.uploadBtn, buttonClass)}
						type={BUTTON_TEXT}
						id="upload-content-upload-button"
					/>
				</div>
			) : null}
			<AddLinkModal
				categoryId={selectedCategory.id}
				isShowing={isAddNewLinkModalVisible}
				handleClose={() => toggleAddNewLinkModal(false)}
				status={11}
				role={MY_FILES}
			/>
		</>
	)
}

export default UploadContent
