import React from 'react'
import { differenceInHours, format } from 'date-fns'
import copy from 'copy-text-to-clipboard'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
	ArrowForward as ArrowForwardIcon,
	AutoDeleteOutlined as AutoDeleteIcon,
	CloudDoneOutlined as SentToCrmIcon,
	LockOutlined as MemositeLockedIcon,
	Link as LinkIcon
} from '@mui/icons-material'

import styles from './MemositeCard.module.scss'

import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import { t } from '../../../../utils/languages/i18n'
import { getMemositeLink } from '../../../../utils/helpers/memosites'
import { FOLLOWUP } from '../../../../utils/consts'

interface MemositeCardInterface {
	memosite: MeetingInterface
}

const MemositeCard = ({ memosite }: MemositeCardInterface) => {
	const navigate = useNavigate()

	const { instanceName } = useSelector((store: StoreInterface) => store.temp)
	const { user } = useSelector((store: StoreInterface) => store.authUser)

	const hoursToDeletion = memosite.for_delete
		? differenceInHours(Date.parse(memosite.for_delete), new Date())
		: null

	const copyMemositeLink = () => {
		const memositeLink = getMemositeLink(
			instanceName,
			memosite.slug,
			user.user.role,
			true
		)
		copy(memositeLink)
		toast(t('notifications.success.link-copied'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: 'success',
			autoClose: 5000
		})
	}

	const openMemositeHandler = (tagName: string) => {
		if (tagName !== 'svg') {
			navigate(`/${FOLLOWUP.toLowerCase()}#${memosite.slug}`)
		}
	}

	return (
		<div
			className={styles.wrapper}
			onClick={(e: any) => openMemositeHandler(e.target.tagName)}
		>
			<div className={styles.subject}>
				{memosite.subject} <ArrowForwardIcon />
			</div>
			<div className={styles.info}>
				{memosite.is_secured && (
					<MemositeLockedIcon titleAccess={t('tooltips.password_protected')} />
				)}
				{memosite.crm && (
					<SentToCrmIcon titleAccess={t('tooltips.sent_to_crm')} />
				)}
				{hoursToDeletion && (
					<div
						className={styles.deletion}
						title={t('tooltips.time-left-until-automatic-deletion')}
					>
						<AutoDeleteIcon />
						{`${
							hoursToDeletion === 0 || hoursToDeletion < 24
								? '<1'
								: Math.round(hoursToDeletion / 24)
						}d`}
					</div>
				)}
				<div className={styles.copyLinkIcon} title={t('labels.copy-link')}>
					<LinkIcon
						className={styles.copyLinkIcon}
						onClick={copyMemositeLink}
					/>
				</div>
				{memosite.memo_opened && (
					<p className={styles.lastOpenedDate}>
						{format(
							new Date(memosite.memo_opened.replace(/\s/g, 'T')),
							'dd / MM / yyyy'
						)}
					</p>
				)}
			</div>
		</div>
	)
}

export default MemositeCard
