import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import {
	getMeetingsCacheEndpoint,
	postMeetingsCacheEndpoint
} from '../apiEndpoints_new'
import { saveMeetingCacheSchema } from '../../utils/schemas/requests'
import { MeetingCacheInterface } from '../../utils/interfaces'

export const saveMeetingsCache = (data: MeetingCacheInterface) =>
	new Promise<MeetingCacheInterface>((resolve, reject) => {
		cloudFnPost(postMeetingsCacheEndpoint, data, null, saveMeetingCacheSchema)
			.then((result) => {
				resolve(result as MeetingCacheInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getMeetingsCache = (key: string) =>
	new Promise<string>((resolve, reject) => {
		cloudFnGet(
			`${getMeetingsCacheEndpoint}/${encodeURIComponent(key)}`,
			null,
			null
		)
			.then((result) => {
				resolve(result as string)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
