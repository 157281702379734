import {
	addSurveyEndpoint,
	editSurveyCompany,
	getSurveyCompaniesEndpoint
} from '../apiEndpoints_new'
import { cloudFnGet, cloudFnPatch, cloudFnPost } from '../../utils/requests'
import {
	addSurveyObject,
	updateSurveyCustomerSchema
} from '../../utils/schemas/requests'

export const addSurvey = (data: any, authToken: any) =>
	new Promise((resolve, reject) =>
		cloudFnPost(
			addSurveyEndpoint,
			data,
			{ withToken: true, authToken },
			addSurveyObject
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	)

export const updateCustomer = (data: any, authToken: any) =>
	new Promise((resolve, reject) =>
		cloudFnPatch(
			editSurveyCompany,
			data,
			{ withToken: true, authToken },
			updateSurveyCustomerSchema
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	)

export const getSurveyCompanies = () =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${getSurveyCompaniesEndpoint}`)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
