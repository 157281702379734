import {
	RESET_MEMOSITE_REDUCER,
	SET_MEMOSITE_VALUE
} from '../actions/actionTypes'

const initialState = {
	memosites: [],
	memositeSort: ['created_at', 'desc'],
	memositesLoaded: false,
	selectedMemosite: undefined,
	view: 'grid',
	labels: [],
	selectedLabelsToFilter: [],
	memositeDropdownOpened: undefined,
	memositeAllowView: false
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_MEMOSITE_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case RESET_MEMOSITE_REDUCER:
			return initialState
		default:
			return state
	}
}

export default reducer
