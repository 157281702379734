import React from 'react'
import Parser from 'html-react-parser'
import Cookies from 'js-cookie'

import { t } from '../../utils/languages/i18n'
import Button from '../../components/common/Button'
import styles from './Errors.module.scss'
import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'

const Error500 = () => {
	const refreshPageHandler = () => {
		window.location.reload()
	}

	const handleLogout = () => {
		Cookies.remove('JWT')
		Cookies.remove('KMSI')
		localStorage.clear()
		window.location.href = '/login'
	}

	return (
		<React.Fragment>
			<div className={styles.errorContainer}>
				<div className={styles.card}>
					<div className={styles.container}>
						<div className={styles.logo}>
							<img src={salesframeLogo} alt="salesframe" />
						</div>
						<h4 className={styles.headline}>{t('labels.oops')}</h4>
						<div className={styles.description}>
							{Parser(t('labels.something-went-wrong'))}
							<Button
								label={t('buttons.refresh')}
								onClick={refreshPageHandler}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								isPositive
								id="error-page-refresh"
							/>
							<Button
								label={t('buttons.logout')}
								onClick={handleLogout}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="error-page-logout"
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Error500
