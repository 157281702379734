import { difference, keys } from 'lodash'

import { folderSettings, galleryOptions } from './layoutSettings'
import { TagInterface } from './interfaces'

type BusinessRules = {
	[key: string]: any
}

enum AppModule {
	STORYBOARD = 'STORYBOARD',
	BUILD = 'BUILD'
}

/**
 *
 * @description converts legacy businessrules
 * based on a set of logic to the new xenon business rules
 * if the businessrules are already xenon business rule,
 * does nothing, just copy the object key-values pair
 * into a new object
 * @param {BusinessRules} businessRules the business rules from the DB
 * @returns {BusinessRules} the converted businessrules
 */
const transformLegacyBusinessRules = (
	businessRules: BusinessRules
): BusinessRules => {
	const converted = {
		...folderSettings,
		...businessRules
	}
	const diff: any[] = difference(keys(businessRules), keys(folderSettings))

	if (diff.includes('target') && diff.includes('url')) {
		converted.embeddedUrl = businessRules.url
	}

	return converted
}

export const convertBusinessRules = (
	buesinessRulesString: string | null
): BusinessRules => {
	let resultBusinessRulesJson: any = folderSettings
	if (buesinessRulesString === null || buesinessRulesString === '') {
		resultBusinessRulesJson = folderSettings
	} else if (typeof buesinessRulesString === 'string') {
		resultBusinessRulesJson = transformLegacyBusinessRules(
			JSON.parse(buesinessRulesString)
		)
	}

	return resultBusinessRulesJson
}

export const convertGalleryOptions = (
	galleryOptionsString: string | null
): BusinessRules => {
	let resultGalleryOptionsJson
	if (galleryOptionsString === null || galleryOptionsString === '') {
		resultGalleryOptionsJson = galleryOptions
	} else if (typeof galleryOptionsString === 'string') {
		resultGalleryOptionsJson = JSON.parse(galleryOptionsString)
	}

	return resultGalleryOptionsJson
}

/*
 * @description checks if the tag is visible to the current user, but it takes the `path` into account!
 */
export const isTagVisibleNew = (
	tag: TagInterface,
	currentUserGroup: number,
	currentModule: string,
	checkPath?: boolean
) => {
	if (checkPath) {
		let isVisible = true
		for (const path of tag.path) {
			try {
				const businessRules = JSON.parse(path.business_rules)
				if (businessRules) {
					if (businessRules.visibility) {
						if (businessRules.visibility.visible === false) {
							const groups = businessRules.visibility.userGroups
							if (groups.length > 0) {
								if (groups.includes(currentUserGroup.toString()))
									isVisible = checkDateVisibility(businessRules)
							} else isVisible = checkDateVisibility(businessRules)
						}
					}
				}
			} catch (e) {
				console.error(e)
				return isVisible
			}
		}
		return isVisible
	} else {
		return isTagVisible(
			convertBusinessRules(tag.business_rules),
			currentUserGroup,
			currentModule
		)
	}
}

export const checkDateVisibility = (businessRules: BusinessRules) => {
	let isVisible = false
	if (businessRules?.visibility?.start_date_visible !== null) {
		const stdate = new Date(businessRules.visibility.start_date_visible)
		const curdate = new Date()
		if (stdate > curdate) {
			isVisible = true
		} else {
			if (businessRules.visibility.end_date_visible !== null) {
				const endate = new Date(businessRules.visibility.end_date_visible)
				if (endate < curdate) {
					isVisible = true
				}
			}
		}
	} else if (businessRules.visibility.end_date_visible !== null) {
		const curdate = new Date()
		const endate = new Date(businessRules.visibility.end_date_visible)
		if (endate > curdate) {
			isVisible = true
		}
	}
	return isVisible
}

export const isTagVisible = (
	businessRules: BusinessRules,
	currentUserGroup: number,
	currentModule: string
): boolean => {
	const checkUserGroups = () => {
		const groups = businessRules.visibility.userGroups
		if (groups.length > 0) {
			if (groups.includes(currentUserGroup.toString())) {
				return checkDateVisibility(businessRules)
			} else {
				return true
			}
		}
		return checkDateVisibility(businessRules)
	}

	let shouldBeVisible = true

	if (businessRules) {
		if (businessRules.visibility) {
			if (businessRules.visibility.visible === false) {
				if (
					currentModule === AppModule.BUILD &&
					businessRules.isHiddenFromBuild
				) {
					shouldBeVisible = checkUserGroups()
				}
				if (
					currentModule === AppModule.STORYBOARD &&
					businessRules.isHiddenFromShowRoom
				) {
					shouldBeVisible = checkUserGroups()
				}
				if (
					!businessRules.isHiddenFromBuild &&
					!businessRules.isHiddenFromShowRoom
				) {
					shouldBeVisible = checkUserGroups()
				}
			}
		}
	}

	return shouldBeVisible
}
