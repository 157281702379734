import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './LoadingOverlay.module.scss'
import Spinner from '../Spinner'

/**
 * @author zilahir
 * @function LoadingOverlay
 * */

const LoadingOverlay = ({
	isLoading,
	overlayClassName,
}) => (isLoading
	? (
		<div className={classnames(
			styles.loadingOverlayRoot,
			overlayClassName,
		)}
		>
			<Spinner isLoading={isLoading} />
		</div>
	)
	: null
)

LoadingOverlay.defaultProps = {
	isLoading: false,
	overlayClassName: undefined,
}

LoadingOverlay.propTypes = {
	isLoading: PropTypes.bool,
	overlayClassName: PropTypes.string,
}

export default LoadingOverlay
