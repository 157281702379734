import React from 'react'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
	EmailOutlined as EmailIcon,
	Link as CopyIcon,
	OpenInNew as OpenIcon,
	KeyboardArrowDownOutlined as ToggleSettingsDownIcon,
	KeyboardArrowUpOutlined as ToggleSettingsUpIcon,
	VisibilityOutlined as CharactersIcon,
	CloudUploadOutlined as SendToCRMIcon
} from '@mui/icons-material'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/styles'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import copy from 'copy-text-to-clipboard'

import styles from './PublishModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import Modal from '../../../../components/common/ModalNew'
import { BUTTON_TEXT, ICON_LEFT } from '../../../../utils/consts'
import CheckBox from '../../../common/CheckBox'
import { dateTimePickerTheme } from '../../../../utils/theme'
import Input from '../../../common/Input'
import {
	MeetingInterface,
	ModuleNamesInterface,
	StoreInterface
} from '../../../../utils/interfaces'
import MemositeLabelsManager from '../../../MemositeLabelsManager'
import ConfirmDeleteModal from '../../../common/ConfirmDeleteModalNew'
import {
	changeMemositeCode,
	createMemoSite,
	deleteMemosite,
	deleteMemositeCode,
	updateMemosite
} from '../../../../api/requests/memosite'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import { getMemositeLink } from '../../../../utils/helpers/memosites'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'
import { setTempValue } from '../../../../store/actions/temp'

interface PublishModalInterface {
	isVisible: boolean
	onClose: (restoreDefaults?: boolean) => void
	memosite: MeetingInterface
	sendToCrm: (memosite: MeetingInterface) => void
}

const PublishModal = ({
	isVisible,
	onClose,
	memosite,
	sendToCrm
}: PublishModalInterface) => {
	const dispatch = useDispatch()

	const { authUser } = useSelector((store: StoreInterface) => store)
	const { memosites } = useSelector((store: StoreInterface) => store.memosite)
	const { publishedMemositeObject } = useSelector(
		(store: StoreInterface) => store.memositeBuilder
	)
	const { instanceName, currentModule, crmSettings, emailBcc } = useSelector(
		(store: StoreInterface) => store.temp
	)

	const [settingsDropdownVisible, toggleSettingsDropdown] =
		React.useState(false)
	const [expiryDateChecked, setExpiryDateChecked] = React.useState(
		!!memosite.expiry_date
	)
	const [expiryDateValue, setExpiryDateValue] = React.useState<any>(
		memosite.expiry_date
	)
	const [password, setPassword] = React.useState('')
	const [passwordChecked, setPasswordChecked] = React.useState(
		memosite.is_secured
	)
	const [showPassword, setShowPassword] = React.useState(false)

	const [memositeName, setMemositeName] = React.useState('')
	const [cancelMemositeModalVisible, toggleCancelMemositeModal] =
		React.useState(false)
	const [memositeLink, setMemositeLink] = React.useState('')
	const [emailTo] = React.useState('')
	const [emailNotification, setEmailNotification] = React.useState(true)
	const [memositeLabelsOpened, toggleMemositeLabelsOpened] =
		React.useState(false)

	const cancelMemositeHandler = () => {
		if (publishedMemositeObject) {
			deleteMemosite(authUser.user.token, publishedMemositeObject.id)
				.then(() => {
					dispatch(
						setMemositeBuilderValue('publishedMemositeObject', undefined)
					)
					toggleCancelMemositeModal(false)
					toast(t('notifications.success.memosite-deleted'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: toast.TYPE.SUCCESS,
						autoClose: 5000
					})
					onClose()
				})
				.catch(() => {
					toast(t('notifications.error.something_went_wrong'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: toast.TYPE.ERROR,
						autoClose: 5000
					})
				})
		}
	}

	const createMemositeHandler = () => {
		const memositeObject = {
			...memosite,
			created: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			subject: memosite.subject,
			starttime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			endtime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
			secured_password: '',
			secured: false,
			extended_fields: JSON.stringify(memosite.extended_fields),
			notes: JSON.stringify(memosite.notes),
			send_notification: 1
		}
		createMemoSite(memositeObject, authUser.user.token).then((result: any) => {
			dispatch(
				setMemositeBuilderValue('publishedMemositeObject', {
					...result,
					labels: []
				})
			)
			const memoUrl = getMemositeLink(
				instanceName || '',
				result.slug,
				authUser.user.user.role,
				true
			)
			setMemositeLink(memoUrl)
			setMemositeName(result.subject)
			dispatch(
				addToAnalyticsBatch({
					event: 'memosite.saved',
					id: result.id,
					name: result.name
				})
			)
		})
	}

	/**
	 * @description copy memosite link and show notification
	 */
	const copyMemositeLink = () => {
		copy(memositeLink)
		toast(t('notifications.success.link-copied'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: toast.TYPE.SUCCESS,
			autoClose: 5000
		})
	}

	/**
	 * @description open window to send memosite by email
	 */
	const emailMemosite = () => {
		window.location.href = `mailto:${
			emailTo !== '' ? emailTo : "Your customer's email address"
		}?${emailBcc && `bcc=${emailBcc}&`}subject=Meeting ${
			memosite.subject
		}&body=Hello!%20I%20created%20this%20Memosite%20for%20you:%0D%0A%0D%0A${memositeLink}%0D%0A%0D%0ABest%20regards,%0D%0A%0D%0A${
			authUser.user.user.firstname
		}%20${authUser.user.user.lastname}`
	}

	/**
	 * @description update email notification settings on checkbox change
	 */
	const updateEmailNotificationHandler = () => {
		const memositeUpdated = {
			...publishedMemositeObject,
			send_notification: emailNotification ? 5 : 1
		}
		updateMemosite(memositeUpdated, authUser.user.token)
			.then(() => {
				// its important to toggle the state here!
				setEmailNotification(!emailNotification)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	/**
	 * @description update memosite password
	 */
	const updatePasswordHandler = async () => {
		if (passwordChecked) {
			const data = {
				meeting_id: publishedMemositeObject && publishedMemositeObject.id,
				code: password
			}
			await changeMemositeCode(data, authUser.user.token)
		} else {
			await deleteMemositeCode(
				authUser.user.token,
				publishedMemositeObject && publishedMemositeObject.id
			)
		}
	}

	const updateMemositeHandler = async () => {
		if (memosite.is_secured !== passwordChecked) {
			await updatePasswordHandler()
		}
		const expiryDateFormatted =
			expiryDateValue &&
			format(new Date(expiryDateValue), 'yyyy-MM-dd HH:mm:ss')
		const extendedFieldsUpdated = publishedMemositeObject && {
			...JSON.parse(publishedMemositeObject.extended_fields),
			expiryDate: expiryDateChecked ? expiryDateFormatted : null
		}
		const updatedMemositeObject = {
			...publishedMemositeObject,
			subject: memositeName,
			secured_password: password,
			secured: !!password,
			extended_fields: JSON.stringify(extendedFieldsUpdated),
			send_notification: !emailNotification ? 5 : 1
		}
		updateMemosite(updatedMemositeObject, authUser.user.token)
			.then(() => {
				onClose(true)
			})
			.catch(() => {
				toast(t('notifications.error.something_went_wrong'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: toast.TYPE.ERROR,
					autoClose: 5000
				})
			})
		if (currentModule === ModuleNamesInterface.FOLLOW_UP) {
			dispatch(setTempValue('reloadMemosites', true))
		}
	}

	React.useEffect(() => {
		if (isVisible && memosite) {
			createMemositeHandler()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, memosite])

	return (
		<Modal
			isShowing={isVisible}
			hide={onClose}
			modalTitle={t('titles.memosite_saved')}
			customClassName={styles.publishModal}
			disableOverlayClose={false}
			footer={
				<>
					<Button
						label={t('buttons.cancel')}
						onClick={() => toggleCancelMemositeModal(true)}
						id="publish-modal-cancel"
					/>
					<Button
						label={t('buttons.close')}
						isPositive
						onClick={updateMemositeHandler}
						isDisabled={false}
						id="publish-modal-close"
					/>
				</>
			}
		>
			<div
				className={c(
					styles.content,
					memositeLabelsOpened && styles.expandHeight
				)}
			>
				<p>{t('misc.copy_your_memosite_link_below')}</p>
				<div className={styles.actions}>
					<div className={styles.linkTitle}>{t('labels.memosite_link')}</div>
					<div
						className={styles.link}
						onClick={() => window.open(memositeLink, '_blank')}
						title={t('tooltips.open_memosite_in_new_tab')}
						id="publish-modal-memosite-link"
					>
						<div>{memositeLink}</div>
						<OpenIcon />
					</div>
					<div className={styles.buttons}>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<CopyIcon htmlColor="#FFFFFF" />}
							label={t('buttons.copy_link')}
							onClick={copyMemositeLink}
							id="publish-modal-copy-link"
						/>
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<EmailIcon htmlColor="#FFFFFF" />}
							label={t('buttons.email')}
							onClick={emailMemosite}
							id="publish-modal-email"
						/>
					</div>
				</div>
				<div className={styles.checkboxes}>
					<CheckBox
						wrapperClassName={styles.checkboxWrapper}
						isChecked={emailNotification}
						onChange={updateEmailNotificationHandler}
						label={t('misc.email-notification-when-opened')}
						name={t('misc.email-notification-when-opened')}
						id="publish-modal-email-notification-checkbox"
					/>
					{crmSettings && (
						<Button
							type={BUTTON_TEXT}
							buttonClass={styles.button}
							iconSide={ICON_LEFT}
							icon={<SendToCRMIcon htmlColor="#FFFFFF" />}
							label={t('buttons.send-to-crm')}
							onClick={() => sendToCrm(publishedMemositeObject || memosite)}
							id="publish-modal-send-to-crm"
						/>
					)}
				</div>
				<div className={c(styles.settingsDropdown)}>
					<div
						className={styles.heading}
						onClick={() => toggleSettingsDropdown(!settingsDropdownVisible)}
					>
						<div>{t('labels.memosite-settings')}</div>
						<div id="publish-modal-memosite-settings-expand-toggle">
							{settingsDropdownVisible ? (
								<ToggleSettingsUpIcon htmlColor="#FFFFFF" />
							) : (
								<ToggleSettingsDownIcon htmlColor="#FFFFFF" />
							)}
						</div>
					</div>
					<div
						className={c(
							styles.memositeSettings,
							settingsDropdownVisible ? styles.visible : styles.hidden
						)}
					>
						<Input
							type={'text'}
							label={t('input-labels.memosite_name')}
							placeholderText={t('input-labels.memosite-name-placeholder')}
							name={t('input-labels.memosite_name')}
							inputClassName={styles.inputWrapper}
							wrapperClassName={styles.memositeName}
							onChange={(e) => setMemositeName(e.target.value)}
							initialValue={memositeName}
							id="publish-modal-memosite-name-input"
						/>
						<div className={styles.row}>
							<div>
								<CheckBox
									isChecked={expiryDateChecked}
									onChange={() => setExpiryDateChecked(!expiryDateChecked)}
									name={t('input-labels.expiration_date')}
									label={t('input-labels.expiration_date')}
									wrapperClassName={styles.expiryDateCheckboxWrapper}
									id="publish-modal-expiry-date-checkbox"
								/>
								<ThemeProvider theme={dateTimePickerTheme}>
									<MuiPickersUtilsProvider
										locale={enLocale}
										utils={DateFnsUtils}
									>
										<div className={styles.datePickerWrapper}>
											<DatePicker
												variant="inline"
												hiddenLabel
												format="yyyy / MM / dd"
												value={expiryDateValue}
												onChange={(val) => setExpiryDateValue(val)}
												id="publish-modal-expiry-date-picker"
											/>
										</div>
									</MuiPickersUtilsProvider>
								</ThemeProvider>
							</div>
							<div>
								<div className={styles.flex}>
									<CheckBox
										isChecked={passwordChecked}
										onChange={() => setPasswordChecked(!passwordChecked)}
										name={t('labels.password')}
										label={t('input-labels.memosite_password')}
										id="publish-modal-memosite-password-checkbox"
									/>
									<Button
										type={BUTTON_TEXT}
										containerClass={styles.btnTextWrapper}
										buttonClass={styles.charactersBtn}
										iconSide={ICON_LEFT}
										icon={<CharactersIcon htmlColor="#FFFFFF" />}
										label={t('buttons.characters')}
										onClick={() => setShowPassword(!showPassword)}
										id="publish-modal-show-password"
									/>
								</div>
								<Input
									type={showPassword ? 'text' : 'password'}
									placeholderText={t('input-placeholders.set_password')}
									name={t('labels.password')}
									inputClassName={styles.passwordInput}
									wrapperClassName={styles.inputWrapper}
									onChange={(e) => setPassword(e.target.value)}
									initialValue={password}
									id="publish-modal-password-input"
								/>
							</div>
						</div>
						<div className={styles.labelsWrapper}>
							{publishedMemositeObject && (
								<MemositeLabelsManager
									labelsOpened={toggleMemositeLabelsOpened}
									memosite={publishedMemositeObject}
									memosites={memosites}
									role="memosite_builder"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<ConfirmDeleteModal
				onApprove={cancelMemositeHandler}
				handleClose={() => toggleCancelMemositeModal(false)}
				isActive={cancelMemositeModalVisible}
				confirmLabel={t('buttons.yes_delete_memosite')}
				cancelLabel={t('buttons.no')}
				zIndex={10002}
				title={t('titles.are-you-sure')}
			>
				<div className={styles.cancelMemositeModalContent}>
					<p>{t('misc.are_you_sure_cancel_publishing_memosite')}</p>
				</div>
			</ConfirmDeleteModal>
		</Modal>
	)
}

export default PublishModal
