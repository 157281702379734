import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './CreateEditLabel.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../../components/common/Button'
import Input from '../../../../components/common/Input'
import { labelColors } from '../../emptyObjectDefinitions'
import {
	createLabel,
	deleteLabel,
	updateLabel
} from '../../../../api/requests/labels'
import { LabelInterface, StoreInterface } from '../../../../utils/interfaces'
import { setMemositeValue } from '../../../../store/actions/memosite'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const LabelColor = styled('div')`
	background-color: ${(props: { color: string }) => props.color};
`

const CreateEditLabel = ({
	onClose,
	existingLabel,
	updateMemositeLabels
}: {
	onClose: (updatedLabel?: LabelInterface, action?: string) => void
	existingLabel?: LabelInterface
	updateMemositeLabels: boolean
}) => {
	const dispatch = useDispatch()
	const [labelName, setLabelName] = useState('')
	const [selectedColor, setSelectedColor] = useState('')
	const [colorsInUse, setColorsInUse] = useState<string[]>([])
	const [deleteLabelModalVisible, toggleDeleteLabelModalVisible] =
		useState(false)
	const [disableDeleteButton, toggleDisableDeleteButton] = useState(false)

	const { memosites, selectedMemosite, labels } = useSelector(
		(store: StoreInterface) => store.memosite
	)

	const createLabelHandler = () => {
		createLabel({
			name: labelName,
			type: updateMemositeLabels ? 'User' : 'Admin',
			color: selectedColor
		})
			.then((response) => {
				if (updateMemositeLabels) {
					dispatch(setMemositeValue('labels', [...labels, response]))
				}
				onClose(response, 'created')
			})
			.catch((e) => console.error(e))
	}

	const updateLabelHandler = () => {
		if (existingLabel) {
			updateLabel(existingLabel.id, {
				name: labelName,
				type: existingLabel.type,
				color: selectedColor
			})
				.then((response) => {
					if (updateMemositeLabels) {
						const updatedLabels = labels.map((l) =>
							l.id === existingLabel.id ? response : l
						)
						dispatch(setMemositeValue('labels', updatedLabels))
						if (selectedMemosite) {
							const updatedMemositeLabels = selectedMemosite.labels.map((l) =>
								l.id === existingLabel.id ? response : l
							)
							dispatch(
								setMemositeValue('selectedMemosite', {
									...selectedMemosite,
									labels: updatedMemositeLabels
								})
							)
							const updatedMemosites = memosites.map((item) => ({
								...item,
								labels: item.labels.map((l) =>
									l.id === existingLabel.id ? response : l
								)
							}))
							dispatch(setMemositeValue('memosites', updatedMemosites))
						}
					}
					onClose(response, 'updated')
				})
				.catch((e) => console.error(e))
		}
	}

	const deleteLabelHandler = () => {
		if (existingLabel) {
			toggleDisableDeleteButton(true)
			deleteLabel(existingLabel.id)
				.then(() => {
					const updatedLabels = labels.filter((l) => l.id !== existingLabel.id)
					dispatch(setMemositeValue('labels', updatedLabels))
					toggleDisableDeleteButton(false)
					toggleDeleteLabelModalVisible(false)
					onClose(existingLabel, 'deleted')
				})
				.catch((e) => {
					console.error(e)
					toggleDisableDeleteButton(false)
					toggleDeleteLabelModalVisible(false)
				})
		}
	}

	useEffect(() => {
		if (existingLabel) {
			setLabelName(existingLabel.name)
			setSelectedColor(existingLabel.color)
		}
		const colors: string[] = []
		// eslint-disable-next-line array-callback-return
		labels.map((label) => {
			if (label.color && !colors.includes(label.color)) {
				colors.push(label.color)
			}
		})
		setColorsInUse(colors)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [existingLabel])

	return (
		<div className={styles.wrapper}>
			<div className={styles.heading}>
				<div className={styles.title}>
					{existingLabel ? t('labels.edit-label') : t('labels.create-label')}
				</div>
				<Close className={styles.closeIcon} onClick={() => onClose()} />
			</div>
			<Input
				placeholderText={t('input-placeholders.name-your-label')}
				onChange={(event) => setLabelName(event.target.value)}
				name="labelName"
				inputClassName={styles.input}
				label={t('labels.label-name')}
				initialValue={labelName}
				wrapperClassName={styles.inputWrapper}
			/>
			<div className={styles.subtitle}>{`${t('labels.label-color')} (${t(
				'labels.circled-already-in-use'
			)})`}</div>
			<div className={styles.colorsWrapper}>
				{labelColors.map((color: string, index: number) => (
					<LabelColor
						color={color}
						className={c(
							styles.color,
							color === selectedColor && styles.selected,
							colorsInUse.includes(color) && styles.inUse
						)}
						key={index}
						onClick={() => setSelectedColor(color)}
					/>
				))}
			</div>
			<div className={styles.footer}>
				{existingLabel && (
					<Button
						buttonClass={styles.btn}
						label={t('buttons.delete-label')}
						onClick={() => toggleDeleteLabelModalVisible(true)}
						isDisabled={labelName === '' || selectedColor === ''}
						id="create-edit-label-delete-label"
					/>
				)}
				<Button
					buttonClass={styles.btn}
					label={
						existingLabel ? t('buttons.save-changes') : t('labels.create-label')
					}
					isPositive
					onClick={existingLabel ? updateLabelHandler : createLabelHandler}
					isDisabled={labelName === '' || selectedColor === ''}
					id="create-edit-label-save-button"
				/>
			</div>
			<DeleteConfirmationModal
				deleteLabel={deleteLabelHandler}
				disableDeleteButton={disableDeleteButton}
				handleClose={() => toggleDeleteLabelModalVisible(false)}
				isActive={deleteLabelModalVisible}
			/>
		</div>
	)
}

export default CreateEditLabel
