import React from 'react'
import { useDispatch } from 'react-redux'

import { subscribe, unsubscribe } from '../../utils/helpers/event'
import { ModuleNamesInterface } from '../../utils/interfaces'
import { setTempValue } from '../../store/actions/temp'
import { store } from '../../store/configureStore'
import { getMyFilesByCategoryId } from '../../store/actions/myfiles'
import {
	addToPresentation,
	setPresentationBarSlides
} from '../../store/actions/presentation'

const XMPPMessageHandler = () => {
	const dispatch = useDispatch()

	const saveDocumentHandler = (content: any) => {
		const messageData = content.detail
		const reduxStore: any = store.getState()
		const { collaoraSavingFiles } = reduxStore.temp
		const currentCollaboraSavingFile = [...collaoraSavingFiles].find(
			(collaboraSavingFile) =>
				collaboraSavingFile.checksum === messageData.source
		)
		const newCollaoraSavingFiles = [...collaoraSavingFiles].filter(
			(item) => item.checksum !== messageData.source
		)
		dispatch(setTempValue('collaoraSavingFiles', newCollaoraSavingFiles))
		// check if user is currently My Files and in folder where file is saved
		// if it is, reload file list for that folder
		const { currentModule } = reduxStore.temp
		const { selectedCategory } = reduxStore.buildContext
		const { token } = reduxStore.authUser.user
		if (
			currentModule === ModuleNamesInterface.MY_FILES &&
			Number(messageData.content.tags_id) === selectedCategory.id
		) {
			dispatch(getMyFilesByCategoryId(selectedCategory.id, token))
		}
		if (currentModule === ModuleNamesInterface.STORYBOARD) {
			const { myFiles } = reduxStore.myfiles
			if (myFiles.length > 0) {
				if (
					Number(messageData.content.tags_id) === Number(myFiles[0].tags_id)
				) {
					dispatch(getMyFilesByCategoryId(myFiles[0].tags_id, token))
				}
			}
		}
		const { presentationBarSlides } = reduxStore.presentation
		// check if user checked to add file to presentation deck
		if (currentCollaboraSavingFile?.addToPresentation) {
			// check if file already exists in presentation deck
			if (
				presentationBarSlides.findIndex((item: any) => {
					if (item._file) {
						return item._file.checksum === currentCollaboraSavingFile.checksum
					}
					return item.checksum === currentCollaboraSavingFile.checksum
				}) > -1
			) {
				if (currentCollaboraSavingFile?.presentationDeckStatus) {
					if (
						currentCollaboraSavingFile?.presentationDeckStatus === 'add_new'
					) {
						dispatch(addToPresentation([messageData.content]))
					} else if (
						currentCollaboraSavingFile?.presentationDeckStatus === 'replace'
					) {
						const newPresentationBarSlides = presentationBarSlides.map(
							(item: any) => {
								if (item._file && item._file.checksum === messageData.source) {
									return messageData.content
								} else if (item.checksum === messageData.source) {
									return messageData.content
								}
								return item
							}
						)
						dispatch(setPresentationBarSlides(newPresentationBarSlides))
					}
				}
			} else {
				dispatch(addToPresentation([messageData.content]))
			}
		} else if (
			presentationBarSlides.findIndex((item: any) => {
				if (item._file) {
					return item._file.checksum === currentCollaboraSavingFile.checksum
				}
				return item.checksum === currentCollaboraSavingFile.checksum
			}) > -1 &&
			currentCollaboraSavingFile.isSave
		) {
			const newPresentationBarSlides = presentationBarSlides.map(
				(item: any) => {
					if (item._file && item._file.checksum === messageData.source) {
						return messageData.content
					} else if (item.checksum === messageData.source) {
						return messageData.content
					}
					return item
				}
			)
			dispatch(setPresentationBarSlides(newPresentationBarSlides))
		}
	}

	const pdfCreatedHandler = (content: any) => {
		dispatch(
			setTempValue('generatePdfWidget', {
				visibility: true,
				pdf: content.detail.content
			})
		)
	}

	React.useEffect(() => {
		subscribe('collabora.saveas', saveDocumentHandler)
		subscribe('collabora.save', saveDocumentHandler)
		subscribe('pdf.created', pdfCreatedHandler)

		return () => {
			unsubscribe('collabora.saveas', saveDocumentHandler)
			unsubscribe('collabora.save', saveDocumentHandler)
			unsubscribe('pdf.created', pdfCreatedHandler)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return null
}

export default XMPPMessageHandler
