import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getConfig } from '../../api/requests/config'

const Offers = () => {
	const navigate = useNavigate()
	const { offerId } = useParams()

	useEffect(() => {
		getConfig('offers')
			.then((response) => {
				navigate(`/present/${response.value}?${offerId}`)
			})
			.catch(() => navigate('/present'))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div />
}

export default Offers
