import React from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

import boxIcon from './svg.svg'

/**
 * @author zilahir
 * @function BoxIcon
 * */

export const BoxIcon = ({
	className,
}) => (
	<ReactSVG
		role="img"
		src={boxIcon}
		wrapper="span"
		className={className}
	/>
)

BoxIcon.defaultProps = {
	className: null,
}

BoxIcon.propTypes = {
	className: PropTypes.string,
}
