import { cloudFnGet, cloudFnPut } from '../../utils/requests'
import { sendEthypharmCrmSchema } from '../../utils/schemas/requests'
import {
	likeItAccountsEndpoint,
	likeItAddNoteEndpoint
} from '../apiEndpoints_new'

export const searchUserLikeit = (name: string) =>
	new Promise<any>((resolve, reject) => {
		cloudFnGet(`${likeItAccountsEndpoint}/${name}`)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendCrmLikeitData = (data: JSON) =>
	new Promise<any>((resolve, reject) => {
		cloudFnPut(likeItAddNoteEndpoint, data, {}, sendEthypharmCrmSchema)
			.then((response) => {
				resolve({
					success: true,
					response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
