import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Resizable } from 're-resizable'
import { useNavigate } from 'react-router-dom'
import {
	AccountBalance as LibrarySelectedIcon,
	AccountBalanceOutlined as LibraryIcon,
	AutoAwesomeMosaic as DashboardSelectedIcon,
	AutoAwesomeMosaicOutlined as DashboardIcon,
	Folder as MyFilesSelectedIcon,
	FolderOutlined as MyFilesIcon,
	FolderSpecial as StarredSelectedIcon,
	FolderSpecialOutlined as StarredIcon,
	HelpOutline as HelpCenterIcon,
	ListAlt as MyMemositesSelectedIcon,
	ListAltOutlined as MyMemositesIcon,
	ManageAccountsOutlined as AdminToolsIcon,
	MenuOpen as MenuOpenIcon,
	Poll as AnalyticsSelectedIcon,
	PollOutlined as AnalyticsIcon,
	Settings as SettingsSelectedIcon,
	SettingsOutlined as SettingsIcon,
	Slideshow as ShowroomSelectedIcon,
	SlideshowOutlined as ShowroomIcon,
	Work as MyPresentationsSelectedIcon,
	WorkOutline as MyPresentationsIcon
} from '@mui/icons-material'
import { motion } from 'framer-motion'

import styles from './Sidebar.module.scss'

import { t } from '../../utils/languages/i18n'
import Button from '../common/Button'
import {
	ANALYTICS,
	BUILD,
	BUTTON_NORMAL,
	BUTTON_TEXT,
	DASHBOARD,
	FOLLOWUP,
	ICON_LEFT,
	LIBRARY,
	MY_FILES,
	MY_PRESENTATIONS,
	PRESENT,
	PRESENTATION_BAR,
	SETTINGS,
	STARRED
} from '../../utils/consts'
import { getAllMyFilesCategories } from '../../store/actions/myfiles'
import {
	setFolderPath,
	setSelectedBuildMenuItem,
	setSelectedCategory
} from '../../store/actions/buildContext'
import { clearTempPresentation, setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import UserMenu from './components/UserMenu'
import InstanceSwitchModal from '../InstanceSwitchModal'
import getAdminPath from '../../utils/helpers/getAdminPath'
import {
	setTagBusinessRules,
	setTagGalleryOptions
} from '../../store/actions/storyBoardContext'
import MyFilesNav from './components/MyFilesNav'
import LibraryNav from './components/LibraryNav'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from './conts'
import useOuterClick from '../../utils/hooks/useOuterClick'

const variants = {
	open: {
		width: 'auto'
	},
	closed: {
		width: SIDEBAR_CLOSED_WIDTH,
		overflow: 'hidden'
	}
}

const Sidebar = () => {
	const sidebarRef = React.useRef<any>()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const windowSize = useWindowSize()

	const initialSize = {
		width: SIDEBAR_OPENED_WIDTH,
		height: windowSize.height || '100%'
	}

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const { isSidebarOpened, currentModule } = useSelector(
		(store: StoreInterface) => store.temp
	)
	const [sidebarSize, setSidebarSize] = React.useState(initialSize)
	const [isInstanceSwitchModalOpen, toggleInstanceSwitchModal] =
		React.useState(false)
	const [myFilesNavVisible, toggleMyFilesNavVisible] = React.useState(false)
	const [libraryNavVisible, toggleLibraryNavVisible] = React.useState(false)
	const [collapsedMenuHovered, toggleCollapsedMenuHovered] =
		React.useState(false)
	const [isHelpMenuOpened, toggleHelpMenuOpened] = React.useState(false)

	React.useEffect(() => {
		dispatch(getAllMyFilesCategories())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		switch (currentModule) {
			case ModuleNamesInterface.LIBRARY:
				toggleLibraryNavVisible(true)
				if (myFilesNavVisible) {
					toggleMyFilesNavVisible(false)
				}
				break
			case ModuleNamesInterface.MY_FILES:
				toggleMyFilesNavVisible(true)
				if (libraryNavVisible) {
					toggleLibraryNavVisible(false)
				}
				break
			default:
				toggleLibraryNavVisible(false)
				toggleMyFilesNavVisible(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentModule])

	/**
	 *
	 * @description handles the nav item clicks
	 */
	const handleNavigation = (nav: string) => {
		if (myFilesNavVisible) {
			toggleMyFilesNavVisible(false)
		}
		if (libraryNavVisible) {
			toggleLibraryNavVisible(false)
		}
		switch (nav) {
			case DASHBOARD:
				dispatch(setFolderPath([]))
				dispatch(
					setSelectedCategory({
						id: null,
						categoryName: ''
					})
				)
				if (!isSidebarOpened) {
					dispatch(setTempValue('isSidebarOpened', true))
				}
				navigate(`/${BUILD.toLowerCase()}`)
				break
			case PRESENT:
				if (isSidebarOpened) {
					dispatch(setTempValue('isSidebarOpened', false))
				}
				navigate(`/${nav.toLowerCase()}`)
				break
			case ANALYTICS:
				navigate(`/${nav.toLowerCase()}`)
				break
			case MY_PRESENTATIONS:
				navigate(`/${nav.toLowerCase().replace('_', '-')}`)
				break
			case FOLLOWUP:
				navigate(`/${nav.toLowerCase()}`)
				break
			case STARRED:
				dispatch(setSelectedBuildMenuItem(nav))
				navigate(`/${nav.toLowerCase()}-files`)
				break
			case MY_FILES:
				toggleMyFilesNavVisible(true)
				dispatch(setSelectedBuildMenuItem(nav))
				navigate(`/${nav.toLowerCase().replace('_', '-')}`)
				break
			case LIBRARY:
				toggleLibraryNavVisible(true)
				dispatch(setTempValue('libraryHomepageVisible', true))
				dispatch(setSelectedBuildMenuItem(nav))
				navigate(`/${nav.toLowerCase()}`)
				break
			case SETTINGS:
				navigate(`/${nav.toLowerCase()}`)
				break
		}
		dispatch(setTagBusinessRules({}))
		dispatch(setTagGalleryOptions({}))
		dispatch(clearTempPresentation(PRESENTATION_BAR))
	}

	/**
	 *
	 * @description opens the admin tools
	 */
	const openAdminTools = () => {
		window.open(getAdminPath(authUser.refresh_token, authUser.token), '_self')
	}

	const clickOutsideHandler = useOuterClick(() => {
		toggleHelpMenuOpened(false)
	})

	return (
		<div
			className={classnames(
				styles.wrapper,
				currentModule === ModuleNamesInterface.STORYBOARD &&
					styles.positionAbsolute
			)}
			ref={sidebarRef}
		>
			<motion.div
				className={classnames(
					styles.sidebarRoot,
					!isSidebarOpened && styles.hoverable,
					currentModule === ModuleNamesInterface.STORYBOARD &&
						styles.positionRelative
				)}
				onHoverStart={() =>
					!isSidebarOpened && toggleCollapsedMenuHovered(true)
				}
				onHoverEnd={() => !isSidebarOpened && toggleCollapsedMenuHovered(false)}
				initial="open"
				variants={variants}
				transition={{
					ease: 'easeOut',
					duration: 0.1
				}}
				animate={isSidebarOpened ? 'open' : 'closed'}
				style={{ zIndex: 998 }}
			>
				<Resizable
					size={sidebarSize}
					onResizeStop={(e, direction, ref, d) => {
						if (['left', 'right'].includes(direction)) {
							setSidebarSize({
								width: sidebarSize.width + d.width,
								height: sidebarSize.height
							})
						}
					}}
					className={styles.resizable}
					onResizeStart={(e, direction) => {
						if (!['left', 'right'].includes(direction)) {
							return false
						}
					}}
				>
					<div className={styles.moduleSidebarRootContainer}>
						<div className={styles.userHelpContainer}>
							<Button
								onClick={() => {
									dispatch(setTempValue('isSidebarOpened', !isSidebarOpened))
								}}
								type={BUTTON_NORMAL}
								buttonClass={styles.toggleBtn}
								containerClass={classnames(
									styles.toggleBtnContainer,
									isSidebarOpened ? styles.open : styles.closed,
									!isSidebarOpened && collapsedMenuHovered && styles.hovered
								)}
								icon={
									<MenuOpenIcon
										fontSize="small"
										htmlColor="#ffffff"
										className={classnames(
											isSidebarOpened
												? styles.menuIconOpened
												: styles.menuIconClosed
										)}
									/>
								}
								id="sidebar-toggle-btn"
							/>
							{(collapsedMenuHovered || isSidebarOpened) && (
								<div
									className={classnames(
										styles.userItemsWrapper,
										isSidebarOpened && styles.opened
									)}
									ref={clickOutsideHandler}
								>
									<div className={styles.userMenuItem}>
										<UserMenu
											toggleInstanceSwitchModal={toggleInstanceSwitchModal}
										/>
									</div>
									<Button
										label={t('buttons.help')}
										type={BUTTON_TEXT}
										iconSide={ICON_LEFT}
										icon={<HelpCenterIcon />}
										containerClass={styles.helpButtonContainer}
										buttonClass={styles.helpButton}
										onClick={() => toggleHelpMenuOpened(true)}
										id="sidebar-help"
									/>
									{isHelpMenuOpened && (
										<>
											<div
												className={classnames(
													styles.helpMenuContainer,
													!isSidebarOpened && styles.rightMargin
												)}
											>
												<ul>
													<li>
														<Button
															onClick={() => null}
															label={t('buttons.take_the_tour')}
															type={BUTTON_TEXT}
															buttonClass={styles.button}
															id="helo-menu-take-the-tour"
														/>
													</li>
													<li>
														<Button
															label={t('buttons.help_center')}
															type={BUTTON_TEXT}
															buttonClass={styles.button}
															onClick={() => {
																window.open(
																	'https://salesframe.freshdesk.com/support/solutions',
																	'_blank'
																)
															}}
															id="help-menu-help"
														/>
													</li>
													<li>
														<Button
															onClick={() => {
																// @ts-ignore
																FreshworksWidget('open')
															}}
															label={t('buttons.contact_support')}
															type={BUTTON_TEXT}
															buttonClass={styles.button}
															id="help-menu-contact-support"
														/>
													</li>
												</ul>
											</div>
										</>
									)}
								</div>
							)}
						</div>

						<div className={styles.btnContainer}>
							<Button
								type={BUTTON_TEXT}
								label={t('labels.dashboard')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.BUILD &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.BUILD ? (
										<DashboardSelectedIcon htmlColor="#ffffff" />
									) : (
										<DashboardIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(DASHBOARD)}
								tooltip={t('labels.dashboard')}
								id="sidebar-dashboard"
							/>
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.present')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.STORYBOARD &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.STORYBOARD ? (
										<ShowroomSelectedIcon htmlColor="#ffffff" />
									) : (
										<ShowroomIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(PRESENT)}
								tooltip={t('buttons.present')}
								id="sidebar-present"
							/>
							{authUser.user.role === 'Manager' && (
								<Button
									type={BUTTON_TEXT}
									label={t('labels.analytics')}
									containerClass={styles.sidebarBtnContainer}
									buttonClass={classnames(
										styles.sidebarBtn,
										currentModule === ModuleNamesInterface.ANALYTICS &&
											styles.activeBtn
									)}
									iconClass={styles.icon}
									iconSide={ICON_LEFT}
									icon={
										currentModule === ModuleNamesInterface.ANALYTICS ? (
											<AnalyticsSelectedIcon htmlColor="#ffffff" />
										) : (
											<AnalyticsIcon htmlColor="#ffffff" />
										)
									}
									onClick={() => handleNavigation(ANALYTICS)}
									tooltip={t('labels.analytics')}
									id="sidebar-analytics"
								/>
							)}
							{['Sysadmin', 'Admin'].includes(authUser.user.role) && (
								<Button
									type={BUTTON_TEXT}
									label={t('labels.admin-tools')}
									containerClass={styles.sidebarBtnContainer}
									buttonClass={classnames(styles.sidebarBtn)}
									iconClass={styles.icon}
									iconSide={ICON_LEFT}
									icon={<AdminToolsIcon htmlColor="#ffffff" />}
									onClick={openAdminTools}
									tooltip={t('labels.admin-tools')}
									id="sidebar-admin-tools"
								/>
							)}
							<div className={styles.separator}>
								{(isSidebarOpened || collapsedMenuHovered) &&
									t('buttons.build')}
							</div>
							<Button
								type={BUTTON_TEXT}
								label={t('labels.library')}
								containerClass={classnames(
									styles.sidebarBtnContainer,
									styles.treeviewRoot,
									(libraryNavVisible ||
										currentModule === ModuleNamesInterface.LIBRARY) &&
										styles.selected
								)}
								buttonClass={classnames(
									styles.sidebarBtn,
									(libraryNavVisible ||
										currentModule === ModuleNamesInterface.LIBRARY) &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									libraryNavVisible ||
									currentModule === ModuleNamesInterface.LIBRARY ? (
										<LibrarySelectedIcon htmlColor="#ffffff" />
									) : (
										<LibraryIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => {
									handleNavigation(LIBRARY)
								}}
								tooltip={t('labels.library')}
								id="sidebar-library"
							/>
							<Button
								type={BUTTON_TEXT}
								label={t('labels.myfiles')}
								containerClass={classnames(
									styles.sidebarBtnContainer,
									currentModule === ModuleNamesInterface.MY_FILES &&
										styles.selected
								)}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.MY_FILES &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.MY_FILES ? (
										<MyFilesSelectedIcon htmlColor="#ffffff" />
									) : (
										<MyFilesIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(MY_FILES)}
								tooltip={t('labels.myfiles')}
								id="sidebar-myfiles"
							/>
							<Button
								type={BUTTON_TEXT}
								label={t('labels.starred')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.STARRED &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.STARRED ? (
										<StarredSelectedIcon htmlColor="#ffffff" />
									) : (
										<StarredIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(STARRED)}
								tooltip={t('labels.starred')}
								id="sidebar-starred"
							/>
							<div className={styles.separator}>
								{(isSidebarOpened || collapsedMenuHovered) &&
									t('labels.manage')}
							</div>
							<Button
								type={BUTTON_TEXT}
								label={t('titles.my_presentations')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.MY_PRESENTATIONS &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.MY_PRESENTATIONS ? (
										<MyPresentationsSelectedIcon htmlColor="#ffffff" />
									) : (
										<MyPresentationsIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(MY_PRESENTATIONS)}
								tooltip={t('titles.my_presentations')}
								id="sidebar-my-presentations"
							/>
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.my-memosites')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.FOLLOW_UP &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.FOLLOW_UP ? (
										<MyMemositesSelectedIcon htmlColor="#ffffff" />
									) : (
										<MyMemositesIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(FOLLOWUP)}
								tooltip={t('buttons.my-memosites')}
								id="sidebar-my-memosites"
							/>
							<div className={styles.separator} />
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.settings')}
								containerClass={styles.sidebarBtnContainer}
								buttonClass={classnames(
									styles.sidebarBtn,
									currentModule === ModuleNamesInterface.USER_SETTINGS &&
										styles.activeBtn
								)}
								iconClass={styles.icon}
								iconSide={ICON_LEFT}
								icon={
									currentModule === ModuleNamesInterface.USER_SETTINGS ? (
										<SettingsSelectedIcon htmlColor="#ffffff" />
									) : (
										<SettingsIcon htmlColor="#ffffff" />
									)
								}
								onClick={() => handleNavigation(SETTINGS)}
								tooltip={t('buttons.settings')}
								id="sidebar-settings"
							/>
						</div>
					</div>
				</Resizable>
				<InstanceSwitchModal
					isActive={isInstanceSwitchModalOpen}
					handleClose={() => toggleInstanceSwitchModal(false)}
				/>
			</motion.div>
			<MyFilesNav isVisible={myFilesNavVisible} />
			<LibraryNav isVisible={libraryNavVisible} />
		</div>
	)
}

export default Sidebar
