import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { SketchPicker } from '@zilahir/react-color'
import { motion } from 'framer-motion'

import styles from './ColorPicker.module.scss'

/**
 * @author zilahir
 * @function ColorPicker
 * */

const ColorIndicator = styled.button`
	background-color: ${props => props.color};
	border-color: ${props => (props.color !== 'transparent' ? props.color : '#ffffff')};
`

const colorPickerVariants = {
	hidden: {
		opacity: 0,
		x: 30,
	},
	visible: {
		opacity: 1,
		x: 0,
	},
}

const ColorPicker = ({
	onChange,
	initialColor,
}) => {
	const [currentColor, setCurrentColor] = useState(initialColor)
	const [isColorPickerVisible, toggleColorPickerVisible] = useState(false)
	const [presetColors, setPresetColors] = useState(['#ff0000'])
	const [clickCount, setClickCount] = useState(0)
	const [timeOut, setTime] = useState(null)

	/**
	 *
	 * @description sets the color picker's color to the selected value
	 * @param {string} newColor the hex value of the chosenc olor
	 */
	function handleColorChange(newColor) {
		setCurrentColor(newColor)
		if (onChange) {
			onChange(newColor)
		}
	}


	/**
	 *
	 * @description adds a new color to the preset
	 */
	function addColorToPreset() {
		setPresetColors([...presetColors, currentColor])
	}


	/**
	 *
	 * @description handles single and double clicking within the icons of the color preset
	 * by double cliking the color will be removed from the list
	 * @param {string} color the color which was cicked
	 */
	function handleDoubleClick(color) {
		setClickCount(curr => curr + 1)
		const currClickCount = clickCount + 1
		if (currClickCount === 1) {
			setTime(setTimeout(() => {
				setClickCount(0)
			}, 300))
		} else if (currClickCount === 2) {
			clearTimeout(timeOut)
			setClickCount(0)
			const filteredColors = presetColors.filter(currColor => currColor !== color.hex)
			setPresetColors(filteredColors)
		}
	}

	/**
	 *
	 * @description handles initialColor change. Without this, initial color
	 * change stays unhandled.
	 */
	useEffect(() => {
		setCurrentColor(initialColor)
	}, [initialColor])

	return (
		<div className={styles.colorPickerContainer}>
			<div className={styles.innerContainer}>
				<ColorIndicator
					className={styles.colorIndicator}
					type="button"
					onClick={() => toggleColorPickerVisible(isVisible => !isVisible)}
					color={currentColor}
				/>
				<p>
					{currentColor}
				</p>
			</div>
			<motion.div
				className={classnames(
					styles.colorPicker,
					isColorPickerVisible ? styles.open : styles.hidden,
				)}
				variants={colorPickerVariants}
				animate={isColorPickerVisible ? 'visible' : 'hidden'}
				initial="hidden"
				transition={{ duration: 0.3 }}
			>
				<SketchPicker
					onChange={color => handleColorChange(color.hex)}
					color={currentColor}
					presetColors={presetColors}
					addNewColorToPreset={() => addColorToPreset()}
					duplicateClick={color => handleDoubleClick(color)}
				/>
			</motion.div>
			{
				isColorPickerVisible && (
					ReactDOM.createPortal(
						<div
							className={styles.overlay}
							onClick={() => toggleColorPickerVisible(false)}
							role="button"
							onKeyDown={null}
							tabIndex={-1}
						/>, document.body,
					)
				)
			}
		</div>
	)
}

ColorPicker.defaultProps = {
	initialColor: '#000000',
}

ColorPicker.propTypes = {
	initialColor: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

export default ColorPicker
