export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'
export const SET_SELECTED_SLIDES = 'SET_SELECTED_SLIDES'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const ADD_LOADING_CARDS = 'ADD_LOADING_CARDS'
export const ADD_UPLOADING_CARDS = 'ADD_UPLOADING_CARDS'
export const SET_FOLDER_PATH = 'SET_FOLDER_PATH'
export const TOGGLE_MINIMIZE = 'TOGGLE_MINIMIZE'
export const TOGGLE_CONVERTING = 'TOGGLE_CONVERTING'
export const SET_SELECTED_BUILD_MENU_ITEM = 'SET_SELECTED_BUILD_MENU_ITEM'

export const setSelectedCategory =
	(selectedCategory: any) => (dispatch: any) => {
		dispatch({
			type: SET_SELECTED_CATEGORY,
			payload: {
				selectedCategory
			}
		})
	}

export const setSelectedSlides = (selectedSlides: any) => (dispatch: any) => {
	dispatch({
		type: SET_SELECTED_SLIDES,
		payload: {
			selectedSlides
		}
	})
}

export const toggleLoading = (boolean: any) => (dispatch: any) => {
	dispatch({
		type: TOGGLE_LOADING,
		payload: {
			isLoading: boolean
		}
	})
}

export const toggleMinimize = (boolean: any) => (dispatch: any) => {
	dispatch({
		type: TOGGLE_MINIMIZE,
		payload: {
			isMinimized: boolean
		}
	})
}

export const toggleConverting = (boolean: any) => (dispatch: any) => {
	dispatch({
		type: TOGGLE_CONVERTING,
		payload: {
			isConverting: boolean
		}
	})
}

export const addLoadingCards = (numOfLoadingCards: any) => (dispatch: any) => {
	dispatch({
		type: ADD_LOADING_CARDS,
		payload: {
			numOfLoadingCards
		}
	})
}

export const addUploadingCards =
	(numOfUploadingCards: number) => (dispatch: any) => {
		dispatch({
			type: ADD_UPLOADING_CARDS,
			payload: {
				numOfUploadingCards
			}
		})
	}

export const setFolderPath = (folderPath: any) => (dispatch: any) => {
	dispatch({
		type: SET_FOLDER_PATH,
		payload: {
			folderPath
		}
	})
}

export const setSelectedBuildMenuItem =
	(selectedBuildMenuItem: string) => (dispatch: any) => {
		dispatch({
			type: SET_SELECTED_BUILD_MENU_ITEM,
			payload: {
				selectedBuildMenuItem
			}
		})
	}
