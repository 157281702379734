import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'

import styles from './ShareWithColleagueModal.module.scss'
import autoSuggestStyles from './AutoSuggesStyle.module.scss'

import SfModal from '../common/ModalNew'
import Section from '../common/Section'
import Button from '../common/Button'
import { FLEX_START, FLEX_DIRECTION_COL } from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import SuggestType from '../common/AutoSuggest'
import { shareWithCollague } from '../../api/requests/presentations'
import { getUsersByGroupId } from '../../store/actions/users'
import { StoreInterface, UserDataInterface } from '../../utils/interfaces'

interface ShareWithColleagueModalInterface {
	isVisible: boolean
	handleClose: () => void
	presentationId: number
}

const ShareWithColleagueModal = ({
	isVisible,
	handleClose,
	presentationId
}: ShareWithColleagueModalInterface) => {
	const dispatch = useDispatch()

	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const userList = useSelector((store: StoreInterface) => store.users.users)

	const [selectedRecepients, setSelectedRecepients] = useState<
		UserDataInterface[]
	>([])
	const [message, setMessage] = useState('')

	useEffect(() => {
		if (isVisible) {
			dispatch(getUsersByGroupId())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible])

	/**
	 *
	 * @description shares a selected presentation
	 */
	const sharePresentation = () => {
		const shareObject = {
			id: presentationId,
			message,
			users: selectedRecepients.map((user) => user.id)
		}

		shareWithCollague(shareObject, authToken).then((result) => {
			if (result.success) {
				setSelectedRecepients([])
				setMessage('')
				toast(t('notifications.success.presentation-shared'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
			}
			handleClose()
		})
	}

	/**
	 *
	 * @description add the selected recepeitn to the list of recepients
	 */
	const handleRecepientSelection = (selected: UserDataInterface) => {
		if (!selectedRecepients.includes(selected)) {
			setSelectedRecepients([...selectedRecepients, selected])
		}
	}

	/**
	 *
	 * @description removes the recepient from the list and updates it
	 */
	const handleDeleteRecepient = (recepient: UserDataInterface) => {
		const filtered = selectedRecepients.filter(
			(currRecepient) => currRecepient.id !== recepient.id
		)
		setSelectedRecepients(filtered)
	}

	return (
		<>
			<SfModal
				isShowing={isVisible}
				hide={handleClose}
				customClassName={styles.shareModal}
				modalTitle="Share Presentation with a colleague"
				footer={
					<div className={styles.modalFooter}>
						<Button
							buttonClass={styles.btn}
							label={t('labels.cancel')}
							onClick={handleClose}
							id="share-with-colleague-modal-cancel"
						/>
						<Button
							buttonClass={styles.btn}
							label={t('labels.share')}
							isPositive
							onClick={() => sharePresentation()}
							isDisabled={selectedRecepients.length === 0}
							id="share-with-colleague-modal-share"
						/>
					</div>
				}
				zIndex={10001}
			>
				<Section
					sectionClassName={styles.section}
					justify={FLEX_START}
					flexDirection={FLEX_DIRECTION_COL}
					alignItems={FLEX_START}
				>
					<p>{t('misc.add-colleagues')}</p>
					<div className={styles.inputContainer}>
						<SuggestType
							theme={autoSuggestStyles}
							suggestionList={userList}
							placeHolder={t('labels.type-a-name')}
							getBackSelected={(selected) => handleRecepientSelection(selected)}
						/>
						<ul className={styles.selectedRecepients}>
							{selectedRecepients.map((currSelected) => (
								<li key={currSelected.id}>
									{`${currSelected.firstname} ${currSelected.lastname}`}
									<Button
										buttonClass={styles.deleteBtn}
										icon={<CloseIcon htmlColor="#ffffff" />}
										onClick={() => handleDeleteRecepient(currSelected)}
										id="share-with-colleague-modal-delete"
									/>
								</li>
							))}
						</ul>
					</div>
				</Section>
				<Section
					sectionClassName={styles.section}
					justify={FLEX_START}
					alignItems={FLEX_START}
					flexDirection={FLEX_DIRECTION_COL}
				>
					<p>{t('misc.shared-presentation-message')}</p>
					<textarea onChange={(e) => setMessage(e.target.value)} />
				</Section>
			</SfModal>
		</>
	)
}

export default ShareWithColleagueModal
