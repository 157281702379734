import React, { useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CloseIcon from '@mui/icons-material/Close'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import styles from './UploadWidget.module.scss'

import {
	Wrapper,
	WidgetWrapper,
	ProgressBar,
	SpaceBetween,
	FilesWrapper
} from './WidgetStyles'
import Spinner from '../../components/common/Spinner'
import Button from '../../components/common/Button'
import { SlideInterface, StoreInterface } from '../../utils/interfaces'
import { t } from '../../utils/languages/i18n'
import formatBytes from '../../utils/helpers/formatBytes'
import { ICON_RIGHT } from '../../utils/consts'

interface Files {
	name: string
	size: number
}
interface UploadWidgetInterface {
	percentage: number
	files: Files[]
	uploadedFiles: Files[]
	apiResponse: SlideInterface[]
	onClose: () => void
}

const UploadWidget = ({
	apiResponse,
	files,
	onClose,
	percentage,
	uploadedFiles
}: UploadWidgetInterface) => {
	const [expand, setExpand] = useState(true)
	const buildContext = useSelector(
		(state: StoreInterface) => state.buildContext
	)

	const getTotal = (): number => {
		const totalSize = uploadedFiles?.map((item: { size: any }) => item.size)
		const sumfiles = _.sum(totalSize)
		return sumfiles
	}

	return (
		<Wrapper
			onClick={() => setExpand(!expand)}
			role="button"
			onKeyDown={undefined}
			tabIndex={-1}
		>
			<WidgetWrapper>
				<div>
					<SpaceBetween>
						{percentage === 100 ? (
							<p>
								{t('misc.upload-complete')}! {percentage}%
							</p>
						) : (
							<p>
								{`${t('misc.uploading')} ${files.length + uploadedFiles.length} 
								files... ${percentage}%`}
							</p>
						)}
						{!expand && apiResponse.length === 0 && (
							<p style={{ fontWeight: '600' }}>
								{t('misc.expand')} <ArrowUpwardIcon fontSize="inherit" />
							</p>
						)}

						{expand && apiResponse.length === 0 && (
							<p style={{ fontWeight: '600' }}>
								{t('misc.minimize')} <ArrowDownwardIcon fontSize="inherit" />
							</p>
						)}

						{apiResponse.length > 0 && (
							<Button
								label={t('labels.close')}
								icon={<CloseIcon style={{ fontSize: 20 }} />}
								iconSide={ICON_RIGHT}
								onClick={onClose}
								id="upload-widget-close"
								buttonClass={styles.closeButton}
							/>
						)}
					</SpaceBetween>
					{/* @ts-ignore */}
					<ProgressBar percentage={percentage} className="progress_bar">
						<div />
					</ProgressBar>
					{expand && (
						<>
							{' '}
							<SpaceBetween>
								<p>
									{t('misc.total-size')}: {formatBytes(getTotal(), 0)}
								</p>
								<p>
									{' '}
									{t('misc.uploaded')}:{' '}
									{formatBytes((getTotal() * percentage) / 100, 0)}
								</p>
							</SpaceBetween>
							<FilesWrapper>
								{apiResponse?.map((item: any, index) => (
									<SpaceBetween key={index}>
										<p>{item.name}</p>
										<div style={{ display: 'inline-flex' }}>
											{!buildContext.isConverting &&
												item.error &&
												t('misc.file-already-exists')}
											{!buildContext.isConverting &&
												!item.error &&
												t('misc.done')}
											{buildContext.isConverting && t('misc.converting')}

											{!buildContext.isConverting ? (
												item.error ? (
													<div style={{ marginLeft: '5px' }}>
														<div
															style={{
																display: 'inline-flex',
																backgroundColor: '#ff2d2d',
																borderRadius: '50%',
																width: 11,
																height: 11
															}}
														/>
													</div>
												) : (
													<div
														style={{
															display: 'inline-flex',
															backgroundColor: '#11F3BD',
															borderRadius: '50%',
															width: 11,
															height: 11,
															marginTop: '4px',
															marginLeft: '2px'
														}}
													/>
												)
											) : (
												<Spinner isLoading size={9} color="#32353D" />
											)}
										</div>
									</SpaceBetween>
								))}
								{files.map((item: any, index) => (
									<SpaceBetween key={index}>
										<p>{item.name}</p>
										<div style={{ display: 'inline-flex' }}>
											{percentage === 100 &&
												apiResponse.length > 0 &&
												!buildContext.isConverting &&
												!item?.error &&
												t('misc.uploaded')}
											{percentage === 100 &&
												apiResponse.length > 0 &&
												!buildContext.isConverting &&
												item?.error &&
												t('misc.file-already-exists')}
											{percentage !== 100 && t('misc.uploading')}{' '}
											{percentage === 100 &&
												buildContext.isConverting &&
												t('misc.converting')}
											<div style={{ marginLeft: '5px' }}>
												{(percentage === 100 && apiResponse.length > 0) ||
												buildContext.isConverting ? (
													<div
														style={{
															display: 'inline-flex',
															backgroundColor: '#11F3BD',
															borderRadius: '50%',
															width: 11,
															height: 11
														}}
													/>
												) : (
													<Spinner isLoading size={11} color="#32353D" />
												)}
											</div>
										</div>
									</SpaceBetween>
								))}
							</FilesWrapper>
						</>
					)}
				</div>
			</WidgetWrapper>
		</Wrapper>
	)
}

export default UploadWidget
