import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './StarredFiles.module.scss'

import {
	setSelectedBuildMenuItem,
	setSelectedCategory,
	toggleLoading
} from '../../store/actions/buildContext'
import FileBrowser from '../../components/FileBrowser'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import { getAllStarredContent } from '../../store/actions/content'
import { setTempValue } from '../../store/actions/temp'
import { STARRED } from '../../utils/consts'
import classnames from 'classnames'
import { addToAnalyticsBatch } from '../../store/actions/analytics'

const StarredFiles = () => {
	const dispatch = useDispatch()

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const { isLoading } = useSelector(
		(store: StoreInterface) => store.buildContext
	)

	React.useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.STARRED))
		dispatch(setSelectedBuildMenuItem(STARRED))
		dispatch(getAllStarredContent(authUser.token))
		dispatch(toggleLoading(false))
		dispatch(
			setSelectedCategory({
				id: null,
				categoryName: ''
			})
		)
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.Starred`
			})
		)
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Starred`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classnames(styles.wrapper)}>
			<FileBrowser
				handleNavigation={(value) => dispatch(setSelectedBuildMenuItem(value))}
				isLoading={isLoading}
			/>
		</div>
	)
}

export default StarredFiles
