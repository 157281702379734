import getSalesframeAPIUrl from '../utils/getSalesframeAPIUrl'

const sfApiRoot = `${process.env.REACT_APP_SALESFRAME_NEW_API}/api/rest`
const sfApiBase = process.env.REACT_APP_SALESFRAME_NEW_API
const sfComApiBase = getSalesframeAPIUrl()
const salesframeComAPIRoot = `${getSalesframeAPIUrl()}/api/rest`

const getNewsEndpoint = `${sfApiRoot}/messages/get-news`
const newsEndpoint = `${sfApiRoot}/messages/news`
const configEndpoint = `${sfApiRoot}/messages/configs`
const customerFontsEndpoint = `${sfApiRoot}/messages/customer/fonts`
const usersWelcomeMessages = `${sfApiRoot}/messages/users/welcome`
const filesStarredEndpoint = `${sfApiRoot}/files/starred`
const flagsEndpoint = `${sfApiRoot}/flags/flags`
const filesStorageUploadEndpoint = `${sfApiRoot}/files/storage/upload`
const filesUploadEndpoint = `${sfApiRoot}/files/upload`
const filesEndpoint = `${sfApiRoot}/files/files`
const filesConverterEndpoint = `${sfApiRoot}/files/converter`
const filesConverterRestoreEndpoint = `${sfApiRoot}/files/converter/restore`
const appearanceUploadEndpoint = `${sfApiRoot}/files/appearance/upload`
const filesTagsEndpoint = `${sfApiRoot}/files/tags`
const presentationToPdfEndpoint = `${sfApiRoot}/files/presentation-to-pdf`
const createPdfThumbnailEndpoint = `${sfApiRoot}/files/pdf-thumbnail`
const splitFileEndpoint = `${sfApiRoot}/files/splitting`
const openfireLoginEndpoint = `${sfApiRoot}/files/openfire-login`
const getSavedFileEndpoint = `${sfApiRoot}/files/get-saved-file`
const myFilesAddTagsEndpoint = `${sfApiRoot}/structure/my-files/add-tags`
const myFilesEndpoint = `${sfApiRoot}/structure/my-files`
const myFilesReorderEndpoint = `${sfApiRoot}/structure/my-files/reorder`
const myFilesChangeMyFolderEndpoint = `${sfApiRoot}/structure/my-files/change-myfolder`
const myFolderEndpoint = `${sfApiRoot}/structure/my-folder`
const sharedFolderInviteEndpoint = `${sfApiRoot}/structure/shared-folder-invite`
const shareFolderAddUserEndpoint = `${sfApiRoot}/structure/shared-folder-addUser`
const sharedFolderDeleteEndpoint = `${sfApiRoot}/structure/shared-folder-delete`
const appearanceEndpoint = `${sfApiRoot}/structure/appearance`
const tagsEndpoint = `${sfApiRoot}/structure/tags`
const tagShortcutsAllEndpoint = `${sfApiRoot}/structure/tags/shortcuts/all`
const allBackgroundImagesEndpoint = `${sfApiRoot}/structure/tags/background-images/all`
const searchAdvanceEndpoint = `${sfApiRoot}/structure/assets/search-advance`
const fullTagStructureEndpoint = `${sfApiRoot}/structure/full-tag-structure`
const myFoldersEndpoint = `${sfApiRoot}/structure/my-folder`
const reorderStructureEndpoint = `${sfApiRoot}/structure/reorder-structure`
const integrationEndpoint = `${sfApiRoot}/integration`
const dynamicsOpportunitiesEndpoint = `${sfApiRoot}/integration/dynamics-user-accounts-opportunities`
const dynamicsUserAccountsEndpoint = `${sfApiRoot}/integration/dynamics-user-accounts`
const dynamicsAddAnnotationsEndpoint = `${sfApiRoot}/integration/dynamics-add-annotations`
const addisSearchEndpoint = `${sfApiRoot}/integration/addis/search`
const addisSearchNextEndpoint = `${sfApiRoot}/integration/addis/searchNext`
const addisUpdateEndpoint = `${sfApiRoot}/integration/addis/update`
const salesforceContactsEndpoint = `${sfApiRoot}/integration/salesforce-contacts`
const salesforceNoteContactEndpoint = `${sfApiRoot}/integration/salesforce/note-contact`
const salesforceSearchEndpoint = `${sfApiRoot}/integration/salesforce-search`
const getSalesforceOpportunityIdEndpoint = `${sfApiRoot}/integration/get-salesforce-opportunity-id`
const salesforceSearchOpportunityEndpoint = `${sfApiRoot}/integration/salesforce-search-opportunity`
const salesforceSearchSectoEndpoint = `${sfApiRoot}/integration/salesforce-search-by-account-name`
const salesforcePostSectoEndpoint = `${sfApiRoot}/integration/salesforce-add-notes-v2`
const salesforceAddNotesEndpoint = `${sfApiRoot}/integration/salesforce-add-notes`
const salesforceAddNotesCustomEndpoint = `${sfApiRoot}/integration/salesforce-add-notes-custom`
const likeItAccountsEndpoint = `${sfApiRoot}/integration/likeit-accounts`
const likeItAddNoteEndpoint = `${sfApiRoot}/integration/likeit-add-note`
const userGroupsEndpoint = `${salesframeComAPIRoot}/users/usergroups`
const usersEndpoint = `${salesframeComAPIRoot}/users/users`
const authorizationCustomEndpoint = `${salesframeComAPIRoot}/users/authorization-custom`
const twoFaVerifyEndpoint = `${salesframeComAPIRoot}/users/authentication/2FA/verify`
const checkUserEndpoint = `${salesframeComAPIRoot}/users/customers/check-user`
const usersAuthenticationEndpoint = `${salesframeComAPIRoot}/users/authentication`
const usersAuthenticationNewEndpoint = `${salesframeComAPIRoot}/users/authentication-new`
const userProfileImageEndpoint = `${salesframeComAPIRoot}/users/users-profile-image`
const resetUserPasswordEndpoint = `${salesframeComAPIRoot}/users/reset-user-password`
const authenticatorSetupEndpoint = `${salesframeComAPIRoot}/users/authentication/authenticator/setup`
const usersInvitationEndpoint = `${salesframeComAPIRoot}/users/invitation`
const usersLogoutEndpoint = `${salesframeComAPIRoot}/users/logout`
const getUserInstancesEndpoint = `${salesframeComAPIRoot}/users/get-user-instance`
const templateFoldersEndpoint = `${sfApiRoot}/template/template-folders/template-folders`
const templatesByFolderEndpoint = `${sfApiRoot}/template/templates/templates-by-folder`
const meetingsEndpoint = `${sfApiRoot}/meetings/meetings`
const shareMeetingEndpoint = `${sfApiRoot}/meetings/meetings/share`
const acceptShareMeetingEndpoint = `${sfApiRoot}/meetings/meetings/accept`
const declineShareMeetingEndpoint = `${sfApiRoot}/meetings/meetings/decline`
const searchMeetingsEndpoint = `${sfApiRoot}/meetings/search-meetings`
const getMeetingsEndpoint = `${sfApiRoot}/meetings/get-meetings`
const meetingsBySlugEndpoint = `${sfApiRoot}/meetings/meetings-by-slug`
const memoSecuredEndpoint = `${sfApiRoot}/meetings/memo-secured`
const sharedPresentationOpenedEndpoint = `${sfApiRoot}/analytics/shared-presentations-opened`
const loginInfoEndpoint = `${sfApiRoot}/analytics/login-info`
const analyticsEventsEndpoint = `${sfApiRoot}/analytics/events`
const forgottenPassword = `${sfApiBase}/forgotten`
const filesStreamingEndpoint = `${sfApiRoot}/files/streaming`
const addSurveyEndpoint = `${sfApiRoot}/surveys/add-survey`
const getSurveyCompaniesEndpoint = `${sfApiRoot}/surveys/get-survey-companies`
const oauthEndpoint = `${sfApiRoot}/oauth`
const editSurveyCompany = `${sfApiRoot}/surveys/edit-survey-company`
const automatedDeletionReset = `${sfApiRoot}/files/automated-deletion-reset`
const checkIsEmbeddable = `${sfApiRoot}/messages/check-is-embeddable`
const usersAdvanceReportEndpoint = `${sfApiRoot}/analytics/users-advance-report`
const getLabels = `${sfApiRoot}/meetings/labels`
const addLabelToMeeting = `${sfApiRoot}/meetings/label-to-meeting`
const deleteLabelFromMeeting = `${sfApiRoot}/meetings/label-from-meeting`
const createOrUpdateLabel = `${sfApiRoot}/meetings/labels`
const filterMeetings = `${sfApiRoot}/meetings/filter-meetings`
const filterMeetingsV2 = `${sfApiRoot}/meetings/filter-meetings-v2`
const memositeStatistics = `${sfApiRoot}/meetings/memosites-analytics`
const memositeAutomatedDeletionReset = `${sfApiRoot}/meetings/automated-deletion-reset`
const wopiSaveEdited = `${sfComApiBase}/wopi/files/save-edited`
const wopiSaveAsOptimized = `${sfComApiBase}/wopi/files/save-as-optimized`
const getMemositePreview = `${sfApiRoot}/meetings/sent`
const addLabelToMeetingBulk = `${sfApiRoot}/meetings/label-to-meeting-bulk`
const materialByTimeAdvancedEndpoint = `${sfApiRoot}/analytics/memosite-material-by-time-advanced`
const getMemositeSessionAdvancedEndpoint = `${sfApiRoot}/analytics/get-memosite-session-advanced`
const getFilesAssetsEndpoint = `${sfApiRoot}/files/assets`
const generatePdfEndpoint = `${sfApiRoot}/files/presentation-to-pdf-v2`
const getPresentationDeckCacheEndpoint = `${sfApiRoot}/meetings/presentation-cache`
const presentationBySlugEndpoint = `${sfApiRoot}/meetings/presentation-by-slug`
const featuredFoldersEndpoint = `${sfApiRoot}/structure/tags/featured-folders`
const getPopularFilesEndpointV2 = `${sfApiRoot}/analytics/popular-files-v2`
const postMeetingsCacheEndpoint = `${sfApiRoot}/meetings/save-cache`
const getMeetingsCacheEndpoint = `${sfApiRoot}/meetings/get-cache`
const sharedFolderInviteEndpointV2 = `${sfApiRoot}/structure/shared-folder-invite-v2`
const shareFolderAddUserEndpointV2 = `${sfApiRoot}/structure/shared-folder-addUser-v2`
const getNewsByCodeEndpoint = `${sfApiRoot}/messages/get-news-by-code`

export {
	sfApiRoot,
	getNewsEndpoint,
	newsEndpoint,
	configEndpoint,
	customerFontsEndpoint,
	usersWelcomeMessages,
	filesStarredEndpoint,
	flagsEndpoint,
	filesStorageUploadEndpoint,
	filesUploadEndpoint,
	filesEndpoint,
	myFilesAddTagsEndpoint,
	myFilesEndpoint,
	myFilesChangeMyFolderEndpoint,
	myFolderEndpoint,
	sharedFolderInviteEndpoint,
	shareFolderAddUserEndpoint,
	sharedFolderDeleteEndpoint,
	appearanceEndpoint,
	tagsEndpoint,
	tagShortcutsAllEndpoint,
	allBackgroundImagesEndpoint,
	searchAdvanceEndpoint,
	fullTagStructureEndpoint,
	myFoldersEndpoint,
	reorderStructureEndpoint,
	myFilesReorderEndpoint,
	filesConverterEndpoint,
	filesConverterRestoreEndpoint,
	appearanceUploadEndpoint,
	filesTagsEndpoint,
	presentationToPdfEndpoint,
	createPdfThumbnailEndpoint,
	splitFileEndpoint,
	openfireLoginEndpoint,
	getSavedFileEndpoint,
	integrationEndpoint,
	dynamicsOpportunitiesEndpoint,
	dynamicsUserAccountsEndpoint,
	dynamicsAddAnnotationsEndpoint,
	addisSearchEndpoint,
	addisSearchNextEndpoint,
	addisUpdateEndpoint,
	salesforceContactsEndpoint,
	salesforceNoteContactEndpoint,
	salesforceSearchEndpoint,
	getSalesforceOpportunityIdEndpoint,
	salesforceSearchOpportunityEndpoint,
	salesforceSearchSectoEndpoint,
	salesforcePostSectoEndpoint,
	salesforceAddNotesEndpoint,
	salesforceAddNotesCustomEndpoint,
	likeItAccountsEndpoint,
	likeItAddNoteEndpoint,
	userGroupsEndpoint,
	usersEndpoint,
	authorizationCustomEndpoint,
	twoFaVerifyEndpoint,
	checkUserEndpoint,
	usersAuthenticationEndpoint,
	usersAuthenticationNewEndpoint,
	userProfileImageEndpoint,
	resetUserPasswordEndpoint,
	authenticatorSetupEndpoint,
	usersInvitationEndpoint,
	usersLogoutEndpoint,
	getUserInstancesEndpoint,
	templateFoldersEndpoint,
	templatesByFolderEndpoint,
	meetingsEndpoint,
	shareMeetingEndpoint,
	acceptShareMeetingEndpoint,
	declineShareMeetingEndpoint,
	searchMeetingsEndpoint,
	getMeetingsEndpoint,
	meetingsBySlugEndpoint,
	memoSecuredEndpoint,
	sharedPresentationOpenedEndpoint,
	loginInfoEndpoint,
	analyticsEventsEndpoint,
	forgottenPassword,
	filesStreamingEndpoint,
	addSurveyEndpoint,
	getSurveyCompaniesEndpoint,
	oauthEndpoint,
	editSurveyCompany,
	automatedDeletionReset,
	checkIsEmbeddable,
	usersAdvanceReportEndpoint,
	getLabels,
	addLabelToMeeting,
	deleteLabelFromMeeting,
	createOrUpdateLabel,
	filterMeetings,
	filterMeetingsV2,
	memositeStatistics,
	memositeAutomatedDeletionReset,
	wopiSaveEdited,
	wopiSaveAsOptimized,
	getMemositePreview,
	addLabelToMeetingBulk,
	materialByTimeAdvancedEndpoint,
	getMemositeSessionAdvancedEndpoint,
	getFilesAssetsEndpoint,
	generatePdfEndpoint,
	getPresentationDeckCacheEndpoint,
	presentationBySlugEndpoint,
	featuredFoldersEndpoint,
	getPopularFilesEndpointV2,
	postMeetingsCacheEndpoint,
	getMeetingsCacheEndpoint,
	sharedFolderInviteEndpointV2,
	shareFolderAddUserEndpointV2,
	getNewsByCodeEndpoint
}
