import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import { saveCrmSchema } from '../../utils/schemas/requests'
import { integrationEndpoint } from '../apiEndpoints_new'

export const getCrmData = (crmCode: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${integrationEndpoint}/${crmCode}`, {}, null)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const saveCRM = (crmData: any, path: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(`${integrationEndpoint}/${path}`, crmData, {}, saveCrmSchema)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
