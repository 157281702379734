import axios from 'axios'
import { isEmpty } from 'lodash'

import { POST, GET, PATCH, DELETE, PUT } from './consts'
import { requestTypeSchema } from './schemas/requests'
import { store } from '../store/configureStore'
import { CHANGE_AUTH_OBJECT } from '../store/actions/actionTypes'

export const axiosInstance = axios.create()

const DEFAULT_OPTIONS = {
	onUploadProgress: () => undefined,
	Accept: 'application/json',
	'Content-Type': 'application/json'
}

export const changeAuthObject = (newAuthObject: any) => (dispatch: any) => {
	dispatch({
		type: CHANGE_AUTH_OBJECT,
		payload: {
			accessToken: newAuthObject.accessToken,
			refreshToken: newAuthObject.refreshToken
		}
	})
}

export const cloudFnRequest = (
	method: any,
	path: any,
	params: any,
	data: any,
	options: any,
	dataSchema?: any,
	headers?: any
) => {
	const { error } = requestTypeSchema.validate(method)
	const request = axios.create()

	const opt = {
		...DEFAULT_OPTIONS,
		...options
	}

	request.interceptors.request.use(
		async (config) => {
			const reduxStore = store.getState()
			const { user } = reduxStore.authUser
			const currentAuthToken = user.token

			const headerConfig = {
				Authorization: `Bearer ${currentAuthToken}`,
				Accept: opt.Accept,
				'Content-Type': opt['Content-Type']
			}
			const head = {
				...headers,
				...headerConfig
			}

			config.onUploadProgress = opt.onUploadProgress
			config.headers = head

			return config
		},
		(axiosError) => {
			Promise.reject(axiosError)
		}
	)

	request.interceptors.response.use(
		(response) => response,
		async (requestError) => {
			/*const originalRequest = requestError.config
			if (requestError.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true
				const reduxStore = store.getState()
				const { user } = reduxStore.authUser
				const currentAuthToken = user.token
				const currentRefreshToken = user.refresh_token
				const newToken = await refreshAccesToken(
					currentAuthToken,
					currentRefreshToken
				)
				if (newToken) {
					axios.defaults.headers.common.Authorization = `Bearer ${newToken.token}`
				}
				return request(originalRequest)
			}*/
			return Promise.reject(requestError)
		}
	)

	if (data && !isEmpty(data)) {
		const isDataValid = dataSchema.validate(data)
		if (isDataValid.error) {
			throw new TypeError(
				`Validation error on object: ${JSON.stringify(data)}: ${
					isDataValid.error
				}`
			)
		}
	}
	if (error) {
		throw new TypeError(`error: ${error}`)
	}
	return Promise.resolve({
		baseURL: path,
		method,
		path,
		data,
		params
	}).then(
		(requestData) =>
			new Promise((resolve, reject) => {
				request(requestData)
					.then((requestResult) => {
						resolve(requestResult.data)
					})
					.catch((requestError) => {
						console.error('path', requestError)
						reject(
							new Error(
								JSON.stringify({
									authSuccess: false,
									reason: requestError,
									response: requestError.response || null
								})
							)
						)
					})
			})
	)
}

export const cloudFnPost = (
	path: any,
	data?: any,
	options?: any,
	schema?: any,
	headers?: any
) => cloudFnRequest(POST, path, undefined, data, options, schema, headers)

export const cloudFnPut = (
	path: any,
	data?: any,
	options?: any,
	schema?: any,
	headers?: any
) => cloudFnRequest(PUT, path, undefined, data, options, schema, headers)

export const cloudFnPatch = (
	path: any,
	data?: any,
	options?: any,
	schema?: any,
	headers?: any
) => cloudFnRequest(PATCH, path, undefined, data, options, schema, headers)

export const cloudFnDelete = (
	path: any,
	data?: any,
	options?: any,
	schema?: any,
	headers?: any
) => cloudFnRequest(DELETE, path, undefined, data, options, schema, headers)

export const cloudFnGet = (
	path: any,
	params?: any,
	options?: any,
	headers?: any
) => cloudFnRequest(GET, path, params, undefined, options, undefined, headers)

export const calculateUploadProgress = (progressEvent: any) =>
	Math.round((progressEvent.loaded * 100) / progressEvent.total)

const makeRequestCreator = () => {
	let source: any
	return (apiEndpoint: any, authToken: any, params: any) => {
		if (source) {
			source.cancel()
		}
		source = axios.CancelToken.source()
		const axiosConfig: any = {
			headers: {
				Authorization: `Bearer ${authToken}`
			},
			cancelToken: source.token
		}
		if (params) {
			axiosConfig.params = params
		}
		return new Promise((resolve, reject) => {
			axios(apiEndpoint, axiosConfig)
				.then((response) => resolve(response.data))
				.catch((error) =>
					reject(new Error(axios.isCancel(error) ? 'cancel' : error))
				)
		})
	}
}

export const asyncRequest = makeRequestCreator()
