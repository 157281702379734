import { SM } from '../../utils/consts'
import {
	TOGGLE_MODAL,
	SET_BG_BLUR,
	CHANGE_MODULE_TIMESTAMP,
	SET_BG_IMAGE,
	SET_PRESENTATION_BAR_SIZE,
	SET_THUMBNAIL_SIZE
} from '../actions/actionTypes'

const initialState = {
	isModalOpen: {},
	changedModulesAt: {},
	bgImage: null,
	presentationBarSize: SM,
	thumbnailSize: SM
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case TOGGLE_MODAL:
			return {
				...state,
				isModalOpen: {
					[action.payload.modal]: action.payload.boolean
				}
			}
		case SET_BG_BLUR:
			return {
				...state,
				bgBlurDeviation: action.payload.deviationValue
			}
		case CHANGE_MODULE_TIMESTAMP:
			return {
				...state,
				changedModulesAt: {
					...state.changedModulesAt,
					[action.payload.whichModule]: action.payload.timestamp
				}
			}
		case SET_BG_IMAGE:
			return {
				...state,
				bgImage: action.payload.bgImage
			}
		case SET_THUMBNAIL_SIZE:
			return {
				...state,
				thumbnailSize: action.payload.thumbnailSize
			}
		case SET_PRESENTATION_BAR_SIZE:
			return {
				...state,
				presentationBarSize: action.payload.presentationBarSize
			}
		default:
			return state
	}
}

export default reducer
