import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '../ModalNew'
import { t } from '../../../utils/languages/i18n'
import Section from '../Section'
import {
	resolveCriticalError,
	throwCriticalErrorMessage
} from '../../../store/actions/temp'
import styles from './CriticalError.module.scss'
import Button from '../Button'
import { FLEX_START } from '../../../utils/consts'

export const throwCriticalError = (message) => (dispatch) =>
	dispatch(throwCriticalErrorMessage(message))

const CriticalError = () => {
	const { isVisible, message } = useSelector(
		(state) => state.temp.criticalError
	)
	const dispatch = useDispatch()
	return (
		<Modal
			isShowing={isVisible}
			customClassName={styles.criticalErrorModal}
			hasCloseIcon={false}
		>
			<Section padding="0px 20px" justify={FLEX_START}>
				<p className={styles.errorMessage}>
					{message || t('notifications.error.general')}
				</p>
			</Section>
			<Section padding="20px" justify="center">
				<Button
					isPositive
					label="OK"
					onClick={() => dispatch(resolveCriticalError())}
					buttonClass={styles.okButton}
					containerClass={styles.okBtnContainer}
					id="critical-error-ok-button"
				/>
			</Section>
		</Modal>
	)
}

export default CriticalError
