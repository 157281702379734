import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import hexToRgba from 'hex-to-rgba'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { has } from 'lodash'
import slugify from 'slugify'
import { toast } from 'react-toastify'
import { useTimer } from 'use-timer'
// @ts-ignore
import objectScan from 'object-scan'

import ContentPane from './components/ContentPane'
import styles from './StoryBoard.module.scss'
import {
	BREADCRUMB,
	BUTTON_TEXT,
	ICON_LEFT,
	LAYOUT,
	PLACEHOLDER,
	PRESENT,
	SHORTCUT,
	STORYBOARD,
	TAGLIST_SHORTCUT
} from '../../utils/consts'
import {
	getContentByTagId,
	setContentForTag
} from '../../store/actions/content'
import FixedTagList from './components/FixedTagList'
import Logo from '../../components/common/Logo'
import Background from './components/Background'
import { defaultFontSize } from '../../utils/layoutSettings'
import {
	convertBusinessRules,
	convertGalleryOptions
} from '../../utils/convertBusinessRules'
import Embedded from './components/Embedded'
import { getTagListFromLocation } from '../../utils/helpers/routeHelper'
import FixedNavigation from './components/FixedNavigation'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import {
	setCurrentBackgroundImage,
	setCurrentLogoImage,
	setSelectedTag,
	setTagBusinessRules,
	setTagGalleryOptions
} from '../../store/actions/storyBoardContext'
import StoryBoardTagTree from './components/StoryBoardTagTree'
import { getAllShortcuts, getTags } from '../../store/actions/tags'
import { getOffers } from '../../api/requests/offers'
import Search from './components/Search'
import { titleVariants } from './animations'
import RootGallery from './components/RootGallery'
import { splitBgImageUrl } from '../../utils/helpers/backgroundHelpers'
import TopHeaderContext from './context/topHeaderContext'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import { getLayout } from '../../store/actions/layout'
import getEmbedUrl from '../../utils/helpers/getEmbedUrl'
import { t } from '../../utils/languages/i18n'
import { Info as InfoIcon } from '@mui/icons-material'
import Button from '../../components/common/Button'

const Title = styled(motion.h1)`
	color: ${(props: any) => props.storyboardFontColor};
	font-size: ${(props: any) => props.fontSize + 0.5}vw;
	text-decoration: ${(props: any) => props.textUnderline};
	text-decoration-color: ${(props: any) => props.textUnderLineColor};
	font-weight: ${(props: any) => props.fontWeight};
	font-family: '${(props: any) => props.fontFamily}';
`

const Description = styled.p`
	color: ${(props: any) => props.storyboardFontColor};
	font-family: ${(props: any) => props.fontFamily};
`

const LandingPageTitle = styled.h1`
	color: ${(props: any) => props.storyboardFontColor};
	font-size: ${(props: any) => props.fontSize + 0.5}vw;
`

const StoryBoard = ({ role = PRESENT, containerRef = null }: any) => {
	const location: any = useLocation()
	const navigate: any = useNavigate()
	const layoutSettings = useSelector((state: any) => state.layout)
	const dispatch = useDispatch()
	const authToken = useSelector((store: any) => store.authUser.user.token)
	const tagTree = useSelector((store: any) => store.tags.tagTree)
	const selectedTag = useSelector(
		(store: any) => store.storyboardContext.selectedTag
	)
	const refreshToken = useSelector(
		(state: any) => state.authUser.user.refresh_token
	)
	const [selectedMenus, setSelectedMenus] = useState<any>([])
	const [isEmbedModalOpen, toggleEmbedModalOpen] = useState(false)
	const [isSerchInputFocused, toggleSearchInputFocused] = useState(false)
	const [hidePreviousHelper, setHidePreviousHelper] = useState({
		level: -1,
		bool: false
	})
	const [galleryTitle, setGalleryTitle] = useState('')
	const [isContenetPaneOpen, toggleContentPane] = useState(false)
	const layoutBackgroundImage = useSelector(
		(state: any) => state.layout.background.backgroundImage
	)
	const backgroundImages = useSelector(
		(state: any) => state.storyboardContext.backgroundImage
	)
	const logoImages = useSelector(
		(state: any) => state.storyboardContext.logoImage
	)
	const [isSearchExpanded, toggleSearchExpanded] = useState(false)
	const { tagGalleryOptions } = useSelector(
		(state: any) => state.storyboardContext
	)

	const isLandingPageOn = useSelector(
		(store: any) => store.layout.misc.landingPage
	)

	const isBreadcrumbsOn = useSelector(
		(state: any) => state.layout.misc.breadcrumbs
	)
	const isRootGalleryOn = useSelector(
		(store: any) => store.layout.misc.rootLevelGallery
	)
	const { isSidebarOpened } = useSelector((store: StoreInterface) => store.temp)
	const isRootLevelGallery = () => isRootGalleryOn && selectedMenus.length === 0

	const { time, start, reset } = useTimer()

	useEffect(() => {
		if (isEmbedModalOpen) {
			start()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEmbedModalOpen])

	const closeEmbedModalHandler = () => {
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.storyboard.external.opened.time',
				tag_id: selectedTag.tag_id,
				name: selectedTag.name,
				time
			})
		)
		reset()
		toggleEmbedModalOpen(false)
	}

	slugify.extend({ '%': '' })

	/**
	 *
	 *
	 * @description this is a helper function to find a specific tag in the structure
	 * @param {Array} data the entire tagTree structure
	 * @param {number} id the id of the tag to find
	 * @returns {object} the found tag
	 */
	const find = (data: any, id: any) =>
		objectScan(['**(^children$).tag_id'], {
			abort: true,
			rtn: 'parent',
			useArraySelector: false,
			filterFn: ({ value }: any) => value === id
		})(data)

	/**
	 *
	 *
	 * @description this is a helper function to find a specific tag in the structure
	 * @param {Array} data the entire tagTree structure
	 * @param {number} id the id of the tag to find
	 * @returns {object} the found tag
	 */
	const findById = (data: any, id: any) =>
		objectScan(['**(^children$).id'], {
			abort: true,
			rtn: 'parent',
			useArraySelector: false,
			filterFn: ({ value }: any) => value === id
		})(data)

	const isFirstLevelLandingPage = () => isLandingPageOn
	const shortCutsList = useSelector((store: any) => store.tags.shortcuts)
	const storyBoardContext = useSelector((store: any) => store.storyboardContext)

	/**
	 *
	 *
	 * @description chcecks if we have pathname in location state
	 * @param {object} fromLocation the current locatino object from React-Roouter
	 * @returns {boolean} true|false
	 */
	const isOfferInUrl = (fromLocation: any) =>
		has(fromLocation, 'state.from.pathname')

	/**
	 *
	 *
	 * @description strips the tag id and returns in a format that can be handled by URL
	 * @param {string} tagName the name of the tag, we need to strip
	 * @param {number} tagId the id of the tag
	 * @returns {string} stripped tagName-tagId represtantation of the tag
	 */
	function stripTag(tagName: string, tagId: number) {
		const stripped = slugify(tagName, {
			remove: /[%/]/g
		})
		return `${tagId}-${stripped.toLowerCase()}`
	}

	/**
	 * @description creates a string if tag names from the tagList array
	 * provided in the parameters
	 * @param {Array} tagList the array containing a Set of tags
	 * @returns {string} concatenated string of the tags
	 */
	const createUrlFromTags = (tagList: any) =>
		tagList.map((tag: any) => stripTag(tag.name, tag.id)).join('/')

	/**
	 * @description handles the background image logic
	 * if there's a background image set for a folder, it's children will inherit it
	 * if there's a background image set for a folder, it's being stored in the backgroundImage state
	 * @param {string} bgImage the checksum representation of the background image
	 * @param {number} depth the level the clicked tag belongs to
	 * @param {object} tag the object representation of the clicked tag
	 */
	function handleBackgroundImage(bgImage: string, depth: number, tag: any) {
		const tagId = tag.tag_id

		if (bgImage) {
			dispatch(setCurrentBackgroundImage(splitBgImageUrl(bgImage), tagId))
		} else if (tag.path.length > 1 && depth !== 0) {
			const removeSelf = tag.path.filter(
				(thisTag: any) => thisTag.tag_id !== tag.tag_id
			)
			const previousBackground = Object.keys(backgroundImages).find(
				(backgroundImage) =>
					Number.parseInt(backgroundImage, 10) ===
					removeSelf[removeSelf.length - 1].tag_id
			)
			if (previousBackground) {
				dispatch(
					setCurrentBackgroundImage(backgroundImages[previousBackground], tagId)
				)
			}
		} else {
			dispatch(setCurrentBackgroundImage(layoutBackgroundImage))
		}
	}

	/**
	 * @description handles the logo image logic
	 * if there's a logo image set for a folder, it's children will inherit it
	 * if there's a logo image set for a folder, it's being stored in the logoImage state
	 * @param {string} logoImage the checksum representation of the logo image
	 * @param {number} depth the level the clicked tag belongs to
	 * @param {object} tag the object representation of the clicked tag
	 */
	function handleLogoImage(logoImage: any, depth: number, tag: any) {
		const tagId = tag.tag_id

		if (logoImage) {
			dispatch(setCurrentLogoImage(splitBgImageUrl(logoImage), tagId))
		} else if (tag.path.length > 1 && depth !== 0) {
			const removeSelf = tag.path.filter(
				(thisTag: any) => thisTag.tag_id !== tag.tag_id
			)
			const previousLogo = Object.keys(logoImages).find(
				(logoImg) =>
					Number.parseInt(logoImg, 10) ===
					removeSelf[removeSelf.length - 1].tag_id
			)
			if (previousLogo) {
				dispatch(setCurrentLogoImage(logoImages[previousLogo], tagId))
			}
		}
	}

	/**
	 * @description handles everything that related to tagClick,
	 * even when this _click_ is handled pragmitcally
	 * We need to tell the analytics we've selected this tag
	 * fetch the content for this tag - and dispatch the related action reducer with the value
	 * fetch business rules - and dispatch the related action reducer with the value
	 * fetch gallery options
	 * @param {object} thisTag the object representation of the _last_ selected tag
	 * @param {number} depth the current level this tag belongs to
	 */
	function tagMisc(thisTag: any, depth: number) {
		if (thisTag) {
			// miscellaneous stuff
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.storyboard.tagOpened',
					tag_id: thisTag.tag_id,
					name: thisTag.name
				})
			)
			const folder = findById(tagTree, thisTag?.id)

			dispatch(getContentByTagId(thisTag.tag_id, authToken))
			dispatch(setSelectedTag(thisTag))

			const businessRules = convertBusinessRules(folder.business_rules)
			toggleContentPane(businessRules.open_library)
			let galleryRules = convertGalleryOptions(thisTag.gallery_options)

			if (!thisTag.hasChildren && tagGalleryOptions.useGallery) {
				// if the previous tag is a gallery, and a <GalleryItem> is being clicked
				// we need the preserve the gallery layout but run everí function
				// including fetching the business rules, calling the analytics API
				// setting the tag to selected
				galleryRules = {
					...galleryRules,
					useGallery: true,
					...storyBoardContext.tagGalleryOptions
				}
			}

			if (tagGalleryOptions.useGallery || galleryRules.useGallery) {
				setGalleryTitle(thisTag.name)
			} else {
				// just for the sake of consistency
				setGalleryTitle('')
			}

			handleBackgroundImage(businessRules['background-image'], depth, thisTag)
			handleLogoImage(businessRules['logo-image'], depth, thisTag)

			const { hidePreviousLevels } = businessRules

			if (hidePreviousLevels) {
				setHidePreviousHelper({
					level: thisTag.level,
					bool: true
				})
			} else if (thisTag.level < hidePreviousHelper.level) {
				setHidePreviousHelper({
					level: -1,
					bool: false
				})
			}

			if (businessRules.embeddedUrl) {
				if (businessRules.target && businessRules.target === true) {
					if (!location.pathname.includes(LAYOUT.toLowerCase())) {
						window.open(businessRules.embeddedUrl, '_blank')
					} else {
						toast(t('notifications.success.this-opens-a-link-in-new-tab'), {
							position: toast.POSITION.BOTTOM_RIGHT,
							type: 'success',
							autoClose: 5000
						})
					}
				} else {
					toggleEmbedModalOpen(true)
				}
			} else {
				toggleEmbedModalOpen(false)
			}

			dispatch(setTagBusinessRules(businessRules))
			dispatch(setTagGalleryOptions(galleryRules))
		}
	}

	// since the temp presentation reducer is common,
	// let's clear it when arriving to StoryBoard
	// so we can add slides from <ContentPane />
	// tell the analytics we are here!
	useEffect(() => {
		if (role !== LAYOUT) {
			dispatch(setTempValue('currentModule', ModuleNamesInterface.STORYBOARD))
		}
		dispatch(setSelectedTag({}))
		dispatch(getAllShortcuts())
		if (tagTree.length > 0) {
			if (isOfferInUrl(location)) {
				// we have offers in url
				const offerId: any =
					location.state.from.pathname.split('/')[
						location.state.from.pathname.split('/').length - 1
					]
				getOffers().then((offerResult) => {
					const { value }: any = offerResult

					// we need to find this tag and is parent, and select them int he storyboard
					const offerTagId = value.split('-')[0]
					const tagPathResult: any = []
					const thisTag = findById(tagTree, Number.parseInt(offerTagId, 10))
					thisTag.path.map((tag: any) =>
						tagPathResult.push(findById(tagTree, tag.id))
					)
					setSelectedMenus(tagPathResult)
					// @ts-ignore
					tagMisc(tagPathResult[tagPathResult.length - 1])

					// handle the url still
					navigate({
						pathname: `/${location.pathname.split('/')[1]}/${createUrlFromTags(
							thisTag.path
						)}`,
						search: `?${offerId}`
					})
				})
			}

			// check if there's tags in the url
			const tagsInLocation = getTagListFromLocation(location)
			if (tagsInLocation.length > 0) {
				const tagPathResult: any = []
				const thisTag = findById(
					tagTree,
					tagsInLocation[tagsInLocation.length - 1].id
				)
				thisTag?.path.map((tag: any) =>
					tagPathResult.push(findById(tagTree, tag.id))
				)
				setSelectedMenus(tagPathResult)
			}
			dispatch(
				addToAnalyticsBatch({
					event: `loadModule.Present`
				})
			)

			const tagList = getTagListFromLocation(location)
			if (tagList.length > 0) {
				// @ts-ignore
				tagMisc(findById(tagTree, tagList[tagList.length - 1].id))
			}
			dispatch(setContentForTag([]))
		} else {
			dispatch(getTags(authToken))
			dispatch(getLayout(authToken))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagTree])

	useEffect(() => {
		if (isSidebarOpened) {
			dispatch(setTempValue('isSidebarOpened', false))
		}
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Present`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 *
	 * @description listens for presseing back button in the browser
	 * strips the tags from the url, and runs the related actions reducers
	 * and local state changes when neccesary
	 */
	function onBackButtonEvent() {
		const tagsInLocation: any = getTagListFromLocation(location)
		if (tagsInLocation.length > 0) {
			const tagPathResult: any = []
			const thisTag = find(
				tagTree,
				tagsInLocation[tagsInLocation.length - 1].tag_id
			)
			thisTag.path.map((tag: any) =>
				tagPathResult.push(find(tagTree, tag.tag_id))
			)
			setSelectedMenus(tagPathResult)
		} else {
			// we arrived to the root level
			setSelectedMenus([])
			dispatch(setSelectedTag({}))
			dispatch(setTagBusinessRules({}))
			dispatch(setTagGalleryOptions({}))
			// there's no embed modal on the root level
			toggleEmbedModalOpen(false)
		}
	}

	useEffect(() => {
		window.addEventListener('popstate', onBackButtonEvent)

		return () => window.removeEventListener('popstate', onBackButtonEvent)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 *
	 *
	 * @param {object} clickedTag object representation of the clicked tag
	 * @description sets th url parameters of the clicked tag's path
	 */
	function handleTagUrlPath(clickedTag: any) {
		navigate({
			pathname: `/${location.pathname.split('/')[1]}/${createUrlFromTags(
				clickedTag.path
			)}`,
			state: {
				tags: clickedTag.path
			}
		})
	}

	const handleTagClick = (tag: any, depth: any) => {
		const clickedTag = tag
		tagMisc(tag, depth)
		if (depth === 0 && !tag.hasChildren && isRootLevelGallery()) return
		if (!tag.hasChildren && tagGalleryOptions.useGallery) return

		setSelectedMenus((currentTags: any) => {
			const newTags: any = [...currentTags]
			// trim any levels after the depth
			newTags.length = depth
			if (tag) {
				newTags[depth] = tag
			}
			return newTags
		})

		if (location.pathname.includes('present')) {
			handleTagUrlPath(clickedTag)
		}
	}

	/**
	 *
	 * @description reset everything
	 * and goes to the root level of the tagtree
	 */
	function handleGoToRootLevel() {
		setSelectedMenus([])
		dispatch(setSelectedTag({}))
		dispatch(setTagBusinessRules({}))
		dispatch(setTagGalleryOptions({}))
		setHidePreviousHelper({
			level: -1,
			bool: false
		})
		dispatch(setContentForTag([]))
		dispatch(setCurrentBackgroundImage(layoutBackgroundImage))
		dispatch(setCurrentLogoImage([]))

		// there's no embed modal on the root level
		toggleEmbedModalOpen(false)

		// handle the URL if we are in present
		if (location.pathname.includes('present')) {
			navigate({
				pathname: `/${location.pathname.split('/')[1]}`,
				state: {
					tags: []
				}
			})
		}
	}

	/**
	 *
	 * @param {number | string} fontWeight the chosen fontWeight from Layout
	 * @description converts the regular font-weight to it's number representation
	 * @returns {number} fontWeight retruns the fontweight's number representation
	 */
	const getFontWeight = (fontWeight: number | string) =>
		fontWeight === 'regular' ? 400 : fontWeight

	/**
	 *
	 * @description removes the last element of the CurrentLevel object
	 */
	function handleBackNavigation() {
		dispatch(getTags()) // TODO: this is a temporarily fix
		const remainTags = selectedMenus.filter(
			(element: any, index: number) => index < selectedMenus.length - 1
		)

		// handling URL change if we are in present

		if (location.pathname.includes('present')) {
			if (remainTags.length > 0) {
				// we need this to avoid: present//{tagName-tagId}
				navigate({
					pathname: `/${location.pathname.split('/')[1]}/${createUrlFromTags(
						remainTags
					)}`,
					state: {
						tags: remainTags
					}
				})
			} else {
				navigate({
					pathname: `/${location.pathname.split('/')[1]}`,
					state: {
						tags: []
					}
				})
			}
		}

		if (remainTags.length > 0) {
			const lastTag = remainTags[remainTags.length - 1]
			tagMisc(lastTag, remainTags.length)
		} else {
			// setting back initial state becase we are at the root level
			dispatch(setSelectedTag({}))
			dispatch(setTagBusinessRules({}))
			dispatch(setTagGalleryOptions({}))
			dispatch(setCurrentBackgroundImage(layoutBackgroundImage))
			// there's no embed modal on the root level
			toggleEmbedModalOpen(false)
			// clear out the content pane too
			dispatch(setContentForTag([]))
			setHidePreviousHelper({
				level: -1,
				bool: false
			})
			// empty the fetched content reducer
			dispatch(setContentForTag([]))
			dispatch(setCurrentLogoImage([]))
		}
		setSelectedMenus(remainTags)
	}

	/**
	 *
	 * @description decides what title to show
	 * either the selected folder's name
	 * or landingPageTitle which is now rooTLevelTitle
	 * or false which case a PLACEHOLDER will be visible to keep
	 * the space for the later titles
	 * @returns {string} the title
	 */
	const getTitle = () => {
		let title: boolean
		if (selectedMenus.length === 0 && layoutSettings.misc.landingPageTitle) {
			title = layoutSettings.misc.landingPageTitle
		} else {
			title =
				selectedMenus.length > 0
					? selectedMenus[selectedMenus.length - 1].name
					: false
		}
		return title
	}

	/**
	 *
	 * @description returns boolean based on showDescription value
	 * @returns {boolean} folder showDescription value from business_rules
	 */
	const folderDescriptionVisible = () => {
		if (selectedMenus.length > 0) {
			const thisTag = selectedMenus[selectedMenus.length - 1]
			const businessRules = convertBusinessRules(thisTag.business_rules)
			return businessRules.showDescription
		}
	}

	/**
	 *
	 * @description returns boolean based on hideBreadcrumbs value
	 * @returns {boolean} folder hideBreadcrumbs value from business_rules
	 */
	const folderBreadcrumbsVisible = () => {
		if (selectedMenus.length > 0) {
			const thisTag = selectedMenus[selectedMenus.length - 1]
			const businessRules = convertBusinessRules(thisTag.business_rules)
			return !businessRules.hideBreadcrumbs
		}
	}

	/**
	 *
	 * @description returns the folder description of the selected tag
	 * @returns {string} folder description
	 */
	function getFolderDescription() {
		if (selectedMenus.length > 0) {
			const thisTag = selectedMenus[selectedMenus.length - 1]
			const businessRules = convertBusinessRules(thisTag.business_rules)
			return businessRules.folderDescription
		}
		return PLACEHOLDER
	}

	/**
	 *
	 * @description handles the breadcrumb click
	 * sets the url based onb the clicked breadcrumb
	 * @param {Array} clickedBreadcrumb array repreentation of the clicked tag's path
	 */
	function handleBreadcrumbClick(clickedBreadcrumb: any) {
		if (location.pathname.includes('present')) {
			navigate({
				pathname: `/${location.pathname.split('/')[1]}/${createUrlFromTags(
					clickedBreadcrumb
				)}`,
				state: {
					tags: clickedBreadcrumb
				}
			})
		}
		// let's call the tagmisc of the last element of the array
		// becase that's the last tag in the tree which is visible
		// @ts-ignore
		tagMisc(clickedBreadcrumb[clickedBreadcrumb.length - 1])
		setSelectedMenus(clickedBreadcrumb)
	}

	useEffect(() => {
		dispatch(
			setCurrentBackgroundImage(layoutSettings.background.backgroundImage)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 *
	 *
	 * @param {Array} tagPath the array conaining the path of the selected tag
	 * @description sets the active tags based on the path of the selected tag
	 */
	const navigateInSearch = (tagPath: any) => {
		setSelectedMenus(tagPath)
		// @ts-ignore
		tagMisc(tagPath[tagPath.length - 1])
		handleTagUrlPath(tagPath[tagPath.length - 1])
	}

	/**
	 * @description find tag and open it in showroom
	 * @param {object} selectedTagParam selected tag
	 */
	const searchFromContentPane = (selectedTagParam: any) => {
		const tagPathResult: any = []
		const thisTag = find(tagTree, Number.parseInt(selectedTagParam.tag_id, 10))
		thisTag.path.map((tag: any) =>
			tagPathResult.push(find(tagTree, tag.tag_id))
		)
		navigateInSearch(tagPathResult)
	}

	return (
		<React.Fragment>
			<FixedNavigation
				isVisible={selectedMenus.length > 0}
				handleBackNavigation={() => handleBackNavigation()}
				toRootLevel={() => handleGoToRootLevel()}
			/>
			<Background selectedMenus={selectedMenus} />
			<div id="slidingpane" className={styles.storyboard}>
				{!storyBoardContext.tagBusinessRules.hidden_library && (
					<ContentPane
						isOpen={isContenetPaneOpen}
						toggleContentPane={toggleContentPane}
						openSearchFolder={searchFromContentPane}
					/>
				)}
				<div
					className={classnames(
						styles.topContainer,
						isFirstLevelLandingPage() && selectedMenus.length === 0
							? styles.hidden
							: ''
					)}
				>
					<div
						className={classnames(
							styles.breadCrumbsRootContainer,
							selectedMenus.length === 0 ? styles.noBreadcrumbs : ''
						)}
					>
						<TopHeaderContext.Provider
							// @ts-ignore
							value={{ isSearchExpanded, toggleSearchExpanded }}
						>
							{isBreadcrumbsOn && folderBreadcrumbsVisible() && (
								<div className={styles.left}>
									{/* @ts-ignore */}
									<FixedTagList
										tags={selectedMenus}
										handleTagClick={handleTagClick}
										role={BREADCRUMB}
									/>
								</div>
							)}
							<div className={styles.right}>
								{shortCutsList.length > 0 && (
									// @ts-ignore
									<FixedTagList
										tags={shortCutsList}
										type={TAGLIST_SHORTCUT}
										role={SHORTCUT}
										handleTagClick={(shortcuts) =>
											handleBreadcrumbClick(shortcuts)
										}
										isDraggingEnabled={false}
										noHiddenTags={role === LAYOUT ? LAYOUT : STORYBOARD}
									/>
								)}
								<Button
									containerClass={styles.featuredBtnWrapper}
									buttonClass={styles.featuredBtnText}
									type={BUTTON_TEXT}
									iconSide={ICON_LEFT}
									icon={
										<InfoIcon
											htmlColor={
												layoutSettings.storyBoard.storyBoardFontColor
													? hexToRgba(
															layoutSettings.storyBoard.storyBoardFontColor,
															1
													  )
													: ''
											}
										/>
									}
									onClick={() => null}
									id="showroom-featured-guide-button"
									tooltip={t('tooltips.quick_guide_for', [
										t('buttons.present')
									])}
								/>
								{layoutSettings.storyBoard.storyBoardFolderSearch && (
									<Search role={STORYBOARD} onTagClick={navigateInSearch} />
								)}
							</div>
						</TopHeaderContext.Provider>
					</div>
					<div className={styles.innerContainer}>
						{getTitle() && (
							<Title
								// @ts-ignore
								key={getTitle()}
								variants={titleVariants}
								initial="initial"
								animate={getTitle() ? 'animated' : 'out'}
								transition={{ ease: 'easeOut', duration: 0.3 }}
								className={classnames(
									styles.title,
									storyBoardContext.tagGalleryOptions.useGallery
										? styles.gallery
										: '',
									isRootGalleryOn &&
										selectedMenus.length === 0 &&
										styles.rootGalleryTitle
								)}
								fontSize={
									// @ts-ignore
									Number.parseFloat(defaultFontSize / 100) *
									layoutSettings.storyBoard.storyBoardFontSize
								}
								storyboardFontColor={
									getTitle()
										? hexToRgba(
												layoutSettings.storyBoard.storyBoardFontColor,
												1
										  )
										: 'transparent'
								}
								fontFamily={
									layoutSettings.storyBoard.storyBoardTitleFont.family
								}
								textUnderline={
									layoutSettings.misc.underlineTitle && getTitle()
										? 'underline'
										: 'none'
								}
								textUnderLineColor={hexToRgba(
									layoutSettings.misc.titleUnderlineColor,
									1
								)}
								fontWeight={getFontWeight(
									layoutSettings.storyBoard.storyBoardTitleFontWeight
								)}
							>
								{!tagGalleryOptions.useGallery
									? getTitle() || PLACEHOLDER
									: galleryTitle}
							</Title>
						)}
						{folderDescriptionVisible() &&
							getFolderDescription() !== PLACEHOLDER && (
								<Description
									// @ts-ignore
									fontFamily={layoutSettings.storyBoard.storyBoardFont.family}
									storyboardFontColor={
										getFolderDescription() === PLACEHOLDER
											? 'transparent'
											: hexToRgba(
													layoutSettings.storyBoard.storyBoardFontColor,
													1
											  )
									}
									className={styles.description}
								>
									{getFolderDescription()}
								</Description>
							)}
					</div>
				</div>
				<motion.div
					className={classnames(
						styles.storyboardRootContainer,
						isFirstLevelLandingPage() && selectedMenus.length > 0
							? styles[layoutSettings.misc.navigationStyle.type.toLowerCase()]
							: '',
						isFirstLevelLandingPage() && selectedMenus.length === 0
							? [
									styles.isLandingPage,
									styles[
										layoutSettings.misc.landingPageStyle.type.toLowerCase()
									]
							  ]
							: '',
						!isFirstLevelLandingPage()
							? styles[layoutSettings.misc.navigationStyle.type.toLowerCase()]
							: ''
					)}
					initial={false}
				>
					{isFirstLevelLandingPage() &&
						layoutSettings.misc.landingPageTitle &&
						selectedMenus.length === 0 && (
							<LandingPageTitle
								className={classnames(
									styles.landingPageTitle,
									styles[
										layoutSettings.misc.landingPageStyle.type.toLowerCase()
									]
								)}
								// @ts-ignore
								fontSize={
									// @ts-ignore
									Number.parseFloat(defaultFontSize / 100) *
									layoutSettings.storyBoard.storyBoardFontSize
								}
								storyboardFontColor={hexToRgba(
									layoutSettings.storyBoard.storyBoardFontColor,
									1
								)}
								fontFamily={
									layoutSettings.storyBoard.storyBoardTitleFont.family
								}
							>
								{layoutSettings.misc.landingPageTitle}
							</LandingPageTitle>
						)}
					{isRootLevelGallery() ? (
						<RootGallery
							rootLevelTags={tagTree}
							onTagClick={handleTagClick}
							// @ts-ignore
							noHiddenTags={role === LAYOUT ? LAYOUT : STORYBOARD}
						/>
					) : (
						<StoryBoardTagTree
							tags={tagTree}
							containerRef={containerRef}
							isDraggingEnabled={false}
							selectedMenus={selectedMenus}
							handleTagClick={handleTagClick}
							noHiddenTags={role === LAYOUT ? LAYOUT : STORYBOARD}
							hidePrevious={hidePreviousHelper}
						/>
					)}
				</motion.div>
				<Logo />
			</div>
			<Embedded
				isOpen={isEmbedModalOpen}
				handleClose={closeEmbedModalHandler}
				title="embed"
				embeddedUrl={getEmbedUrl(
					location,
					storyBoardContext.tagBusinessRules,
					refreshToken,
					authToken
				)}
			/>
			{isSerchInputFocused &&
				ReactDOM.createPortal(
					<div
						role="button"
						onKeyDown={undefined}
						tabIndex={-1}
						onClick={() => toggleSearchInputFocused(false)}
					/>,
					// @ts-ignore
					document.querySelector('#slidingpane')
				)}
		</React.Fragment>
	)
}

export default StoryBoard
