import React from 'react'
import styles from './DeleteConfirmationModal.module.scss'

import { t } from '../../../../../utils/languages/i18n'
import Button from '../../../../../components/common/Button'
import Modal from '../../../../../components/common/ModalNew'

interface DeleteConfirmationModalInterface {
	deleteLabel: () => void
	disableDeleteButton: boolean
	handleClose: () => void
	isActive: boolean
}

const DeleteConfirmationModal = ({
	deleteLabel,
	disableDeleteButton,
	handleClose,
	isActive
}: DeleteConfirmationModalInterface) => (
	<Modal
		customClassName={styles.wrapper}
		disableOverlayClose={true}
		hasCloseIcon={true}
		hide={handleClose}
		isShowing={isActive}
		modalTitle={t('titles.delete-label')}
		zIndex={10003}
		footer={
			<div className={styles.buttonsContainer}>
				<Button
					label={t('buttons.cancel')}
					onClick={handleClose}
					id="delete-confirmation-modal-delete-label-cancel"
				/>
				<Button
					label={t('buttons.delete')}
					onClick={deleteLabel}
					isPositive
					isDisabled={disableDeleteButton}
					id="delete-confirmation-modal-delete-label-delete"
				/>
			</div>
		}
	>
		<div className={styles.content}>
			<p>{t('misc.are-you-sure-delete-label')}</p>
		</div>
	</Modal>
)

export default DeleteConfirmationModal
