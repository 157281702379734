import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import objectScan from 'object-scan'
import classnames from 'classnames'
import SearchIcon from '@mui/icons-material/Search'
import { motion } from 'framer-motion'
import AsyncSelect from 'react-select/async'
import hexToRgba from 'hex-to-rgba'
import { useSelector, useDispatch } from 'react-redux'
import { flatMapDeep } from 'lodash'

import styles from './Search.module.scss'
import { t } from '../../../../utils/languages/i18n'
import {
	setFolderPath,
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading
} from '../../../../store/actions/buildContext'
import { getContentByTagId } from '../../../../store/actions/content'
import { BUILD, LIBRARY, STORYBOARD } from '../../../../utils/consts'
import {
	isTagVisible,
	convertBusinessRules
} from '../../../../utils/convertBusinessRules'
import TopHeaderContext from '../../context/topHeaderContext'

const customStyles = {
	indicatorsContainer: () => ({
		display: 'none'
	}),
	dropdownIndicator: () => ({
		display: 'none'
	}),
	menu: (provided) => ({
		...provided,
		background: hexToRgba('#464A54', 1),
		width: 230,
		left: -50,
		top: 35,
		borderRadius: 20,
		overflow: 'hidden'
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: 205,
		zIndex: 99,
		'::-webkit-scrollbar': {
			width: 7
		},
		'::-webkit-scrollbar-track': {
			background: hexToRgba('#464A54', 1)
		},
		'::-webkit-scrollbar-thumb': {
			background: hexToRgba('#000000', 0.5)
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: '#555'
		}
	}),
	option: (provided) => ({
		...provided,
		background: 'transparent',
		fontSize: 13,
		padding: '6px 12px',
		'&:hover': {
			background: hexToRgba('#000000', 0.25)
		}
	}),
	singleValue: (provided) => ({
		...provided,
		fontSize: 13,
		color: '#ffffff'
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: 13,
		color: '#ffffff',
		'&:hover': {
			cursor: 'text'
		}
	}),
	control: (provided) => ({
		...provided,
		background: 'transparent',
		border: 0,
		boxShadow: 'none',
		minHeight: 20,
		whiteSpace: 'nowrap'
	}),
	input: (provided) => ({
		...provided,
		fontSize: 13,
		color: '#ffffff',
		padding: 0
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0 4px'
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontSize: 14,
		color: '#ffffff'
	})
}

const Search = ({ role, onTagClick, navigate }) => {
	const [flattenTagTree, setFlattenTagTree] = useState([])
	const [selectedOption, setSelectedOption] = useState('')
	const dispatch = useDispatch()
	const layoutSettings = useSelector((state) => state.layout)
	const authToken = useSelector((state) => state.authUser.user.token)
	const currentUserGroup = useSelector(
		(store) => store.authUser.user.user.userGroupId
	)
	const { isSearchExpanded, toggleSearchExpanded } =
		useContext(TopHeaderContext)

	/**
	 *
	 *
	 * @description this is a helper function to find a specific tag in the structure
	 * @param {Array} data the entire tagTree structure
	 * @param {number} id the id of the tag to find
	 * @returns {object} the found tag
	 */
	const find = (data, id) =>
		objectScan(['**(^children$).tag_id'], {
			abort: true,
			rtn: 'parent',
			useArraySelector: false,
			filterFn: ({ value }) => value === id
		})(data)

	const tagTree = useSelector((store) => store.tags.tagTree)
	useEffect(() => {
		let isMounted = true
		const flatten = (item) => [item, flatMapDeep(item.children, flatten)]
		const result = flatMapDeep(tagTree, flatten)
		if (isMounted) {
			setFlattenTagTree(
				result
					.map((tag) => ({
						tagId: tag.tag_id,
						label: tag.name.toString(),
						key: tag.tag_id,
						isVisible: isTagVisible(
							convertBusinessRules(tag.business_rules),
							currentUserGroup,
							STORYBOARD
						)
					}))
					.filter((tag) => tag.isVisible)
			)
		}

		return () => {
			isMounted = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const filterTags = (inputValue) =>
		flattenTagTree.filter((tag) =>
			tag.label.toLowerCase().includes(inputValue.toLowerCase())
		)

	const loadOptions = (inputValue, callback) => {
		callback(filterTags(inputValue))
	}

	const goToFolder = (tag) => {
		dispatch(setFolderPath(tag.path))
		dispatch(toggleLoading(true))
		dispatch(getContentByTagId(tag.tag_id, authToken)).then(() => {
			dispatch(toggleLoading(false))
		})
		dispatch(
			setSelectedCategory({
				...tag,
				id: tag.tag_id,
				categoryName: tag.filename
			})
		)
		dispatch(setSelectedSlides([]))
	}

	/**
	 *
	 * @description navigates in the storyboard to the selected tag
	 * @param {number} selectedTag the id of the clicked tag
	 */
	function goToTag(selectedTag) {
		const tagPathResult = []
		const thisTag = find(tagTree, Number.parseInt(selectedTag.tagId, 10))
		thisTag.path.map((tag) => tagPathResult.push(find(tagTree, tag.tag_id)))
		if (role === BUILD) {
			navigate(LIBRARY)
			goToFolder({
				tag_id: tagPathResult[tagPathResult.length - 1].tag_id,
				path: tagPathResult
			})
		} else if (role === STORYBOARD) {
			onTagClick(tagPathResult)
		}
		setSelectedOption('')
		toggleSearchExpanded(false)
	}
	return (
		<div
			className={classnames(
				styles.searchContainer,
				isSearchExpanded ? styles.hasFocus : '',
				styles[role.toLowerCase()]
			)}
		>
			<motion.div
				className={styles.searchInput}
				animate={isSearchExpanded ? 'visible' : 'hidden'}
				variants={{
					visible: {
						opacity: 1,
						width: 140
					},
					hidden: {
						opacity: 0,
						width: 0
					}
				}}
			>
				<AsyncSelect
					cacheOptions
					value={selectedOption}
					styles={customStyles}
					defaultOptions={flattenTagTree}
					placeholder="Search folders"
					loadOptions={loadOptions}
					onChange={(selected) => goToTag(selected)}
					noOptionsMessage={() => t('labels.no-folder-found')}
				/>
			</motion.div>
			<button
				type="button"
				className={styles.searchBtn}
				onClick={() => toggleSearchExpanded((currentValue) => !currentValue)}
			>
				<SearchIcon
					htmlColor={hexToRgba(
						layoutSettings.storyBoard.storyBoardFontColor,
						1
					)}
				/>
			</button>
		</div>
	)
}

Search.defaultProps = {
	navigate: () => {},
	onTagClick: () => {}
}

Search.propTypes = {
	navigate: PropTypes.func,
	onTagClick: PropTypes.func,
	role: PropTypes.oneOf([BUILD, STORYBOARD]).isRequired
}

export default Search
