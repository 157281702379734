import React from 'react'
import screenfull from 'screenfull'

/**
 * @description open page in full screen
 * @param {object} event the performed clicked event
 */
const handleFullScreen = (event: React.MouseEvent) => {
	event.stopPropagation()
	if (screenfull.isFullscreen) {
		screenfull.exit()
	} else {
		screenfull.request(document.querySelectorAll('body')[0])
	}
}

export default handleFullScreen
