import UrlPattern from 'url-pattern'
import { RouteHelperComponentInterface } from '../interfaces'

const urlRegexp = new RegExp('build(/.*)?')

export const tagUrlPattern = new UrlPattern(/^\/present\/(.*)$/)

type Tag = {
	id: number;
	name: string;
}

export interface Location {
	hash: string;
	pathname: string;
	search: string;
	state?: any;
}

export const isBuildRoute = (location: Location): boolean => {
	let result = false
	const regexResult = urlRegexp.exec(location.pathname)
	if (regexResult !== null && Array.isArray(regexResult)) {
		result = true
	}
	return result
}

export const shouldComponentRender = (
	location: Location,
	component: RouteHelperComponentInterface,
) => {
	if (
		(location.pathname.includes('present')
			|| location.pathname.includes('layout')
			|| location.pathname.includes('login'))
		&& (component === RouteHelperComponentInterface.TOP_HEADER || component === RouteHelperComponentInterface.BACKGROUND)
	) {
		return false
	}
	if (
		(location.pathname.includes('layout')
			|| location.pathname.includes('login'))
		&& component === RouteHelperComponentInterface.SIDE_MENU
	) {
		return false
	}
	if (
		(location.pathname.includes('layout')
			|| location.pathname.includes('settings')
			|| location.pathname.includes('login')
			|| location.pathname.includes('present'))
		&& component === RouteHelperComponentInterface.PRESENTATION_BAR
	) {
		return false
	}
	if (location.pathname.includes('admin') && !location.pathname.includes('/present/')) {
		return false
	}
	return true
}

export const hasBlurOnBackground = (location: Location): boolean => {
	let result = true
	if (location.pathname.includes('/present')) {
		result = false
	}
	return result
}

export const stripTagNameToRouteParam = (tag: Tag): string => `${tag.id}-${tag.name.replace(/\s/g, '-').toLowerCase()}`

export const getTagListFromLocation = (location: Location): Array<Tag> => {
	const result = tagUrlPattern.match(location.pathname)
	if (result) {
		const tagList = result[0].split('/')
		return tagList.map((tag: string) => {
			const [, ...strippedTag] = tag.split('-')
			return {
				name: strippedTag.join(' '),
				tag_id: Number.parseInt(tag.split('-')[0], 10), // THIS IS NOT CORRECT BUT LEAVING IT HERE SORRY
				id: Number.parseInt(tag.split('-')[0], 10),
			}
		})
	}
	return []
}
