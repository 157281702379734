import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	HomeOutlined,
	AccountCircleOutlined,
	ArticleOutlined
} from '@mui/icons-material'

import styles from './SlidesContainer.module.scss'

import CardStatic from '../../CardStatic'
import Card from '../../../../../components/Card'
import { t } from '../../../../../utils/languages/i18n'
import {
	GRID,
	LIST,
	COVER,
	PROFILE,
	MY_MEMOSITES_,
	GREETING
} from '../../../../../utils/consts'
import { addToTempPresentation } from '../../../../../store/actions/temp'
import { StoreInterface } from '../../../../../utils/interfaces'
import { decideThumbnail } from '../../../../../utils/helpers/decideThumbanil'
import SlideGraph from '../../SlideGraph'
import CardList from '../../CardList'
import {
	SlideInterfaceWithAnalytics,
	SlidePageInterface
} from '../../../interfaces'

interface SlidesContainerInterface {
	pages: SlidePageInterface[]
	slides: SlideInterfaceWithAnalytics[]
}

const SlidesContainer = ({ pages, slides }: SlidesContainerInterface) => {
	const dispatch = useDispatch()

	const tempPresentationSlides = useSelector(
		(store: StoreInterface) => store.temp.slides
	)
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { view } = useSelector((store: StoreInterface) => store.memosite)

	const handleSelect = (file: any, e: any) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(file, MY_MEMOSITES_))
	}
	const coverPage = pages.find((item: any) => item.attr_label === 'cover_page')

	const profilePage = pages.find(
		(item: any) => item.attr_label === 'profile_page'
	)

	const textGreetingPage = pages.find(
		(item: any) => item.attr_label === 'text_greeting'
	)
	const videoGreetingPage = pages.find(
		(item: any) => item.attr_label === 'video_greeting'
	)

	return (
		<div>
			{view === GRID ? (
				<div className={styles.slidesContainerGrid}>
					{coverPage && (
						<div className={styles.cardContainer}>
							<div className={styles.slideGraph}>
								<SlideGraph type={GRID} width={coverPage.graphBarValue} />
							</div>
							<div className={styles.slideItem}>
								<CardStatic time={coverPage.formattedTime} type={COVER} />
							</div>
						</div>
					)}
					{textGreetingPage && (
						<div className={styles.cardContainer}>
							<div className={styles.slideGraph}>
								<SlideGraph
									type={GRID}
									width={textGreetingPage.graphBarValue}
								/>
							</div>
							<div className={styles.slideItem}>
								<CardStatic
									time={textGreetingPage.formattedTime}
									type={GREETING}
								/>
							</div>
						</div>
					)}
					{videoGreetingPage && (
						<div className={styles.cardContainer}>
							<div className={styles.slideGraph}>
								<SlideGraph
									type={GRID}
									width={videoGreetingPage.graphBarValue}
								/>
							</div>
							<Card
								isSelected={false}
								cardObject={{
									checksum: videoGreetingPage.attr_checksum,
									extension: t('labels.greeting').toUpperCase(),
									type: t('labels.greeting').toUpperCase(),
									thumbnail: videoGreetingPage.attr_checksum,
									time: videoGreetingPage.formattedTime,
									name: videoGreetingPage.name
								}}
								className={styles.slideItem}
								hasLayoutFontColor={false}
								handleCardClick={() => null}
								hasToolbar={false}
								openFileEditor={() => null}
								isEditable={false}
							/>
						</div>
					)}
					{slides.map((item: any, index: number) => (
						<div key={`${index.toString()}`} className={styles.cardContainer}>
							<div className={styles.slideGraph}>
								<SlideGraph type={GRID} width={item.graphBarValue} />
							</div>
							<Card
								isSelected={tempPresentationSlides[
									MY_MEMOSITES_.toLowerCase()
								].includes(item)}
								cardObject={{
									...item,
									extension: item.type,
									id: item.id,
									time: item.formattedTime
								}}
								className={styles.slideItem}
								handleCardClick={(e) => handleSelect(item, e)}
								hasLayoutFontColor={false}
								hasToolbar
								openFileEditor={() => null}
								isEditable={false}
							/>
						</div>
					))}
					{profilePage && (
						<div className={styles.cardContainer}>
							<div className={styles.slideGraph}>
								<SlideGraph type={GRID} width={profilePage.graphBarValue} />
							</div>
							<div className={styles.slideItem}>
								<CardStatic time={profilePage.formattedTime} type={PROFILE} />
							</div>
						</div>
					)}
				</div>
			) : (
				<div className={styles.slidesContainerListWrapper}>
					<div className={styles.headers}>
						<div className={styles.slidesCount}>{`${slides.length} ${t(
							'labels.slides'
						)}`}</div>
						<div className={styles.dFlex}>
							<div>{t('misc.type')}</div>
							<div>{t('labels.time-on-slide')}</div>
						</div>
					</div>
					<div className={styles.slidesContainerList}>
						{coverPage && (
							<div className={styles.listItem}>
								<div className={styles.thumbnail}>
									<HomeOutlined />
								</div>
								<div className={styles.info}>
									<div>{t('labels.cover-page')}</div>
								</div>
								<div className={styles.slideGraph}>
									<SlideGraph type={LIST} width={coverPage.graphBarValue} />
								</div>
								<div className={styles.type} />
								<div className={styles.time}>{coverPage.formattedTime}</div>
							</div>
						)}
						{textGreetingPage && (
							<div className={styles.listItem}>
								<div className={styles.thumbnail}>
									<ArticleOutlined />
								</div>
								<div className={styles.info}>
									<div>{t('labels.text_greeting')}</div>
								</div>
								<div className={styles.slideGraph}>
									<SlideGraph
										type={LIST}
										width={textGreetingPage.graphBarValue}
									/>
								</div>
								<div className={styles.type} />
								<div className={styles.time}>
									{textGreetingPage.formattedTime}
								</div>
							</div>
						)}
						{videoGreetingPage && (
							<div className={styles.listItem}>
								<div className={styles.thumbnail}>
									<img
										src={decideThumbnail(
											{
												checksum: videoGreetingPage.attr_checksum,
												extension: t('labels.greeting').toUpperCase(),
												thumbnail: videoGreetingPage.attr_checksum,
												title: videoGreetingPage.name,
												external_id: ''
											},
											user.token
										)}
										alt=""
									/>
								</div>
								<div className={styles.info}>
									<div>{t('labels.video-greeting')}</div>
								</div>
								<div className={styles.slideGraph}>
									<SlideGraph
										type={LIST}
										width={videoGreetingPage.graphBarValue}
									/>
								</div>
								<div className={styles.type} />
								<div className={styles.time}>
									{videoGreetingPage.formattedTime}
								</div>
							</div>
						)}
						{slides.length > 0 &&
							slides.map((item: any, index: number) => (
								<CardList authToken={user.token} item={item} key={index} />
							))}
						{profilePage && (
							<div className={styles.listItem}>
								<div className={styles.thumbnail}>
									<AccountCircleOutlined />
								</div>
								<div className={styles.info}>
									<div>{t('labels.profile-page')}</div>
								</div>
								<div className={styles.slideGraph}>
									<SlideGraph type={LIST} width={profilePage.graphBarValue} />
								</div>
								<div className={styles.type} />
								<div className={styles.time}>{profilePage.formattedTime}</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default SlidesContainer
