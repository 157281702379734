import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { uniqBy } from 'lodash'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { differenceInSeconds } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

//@ts-ignore
import { arrayMoveByIndex } from 'array-move-multiple/index'

import styles from './FileBrowser.module.scss'

import Embedded from './components/EmbeddedModal'
import Folder from './components/Folder'
import ActionHeader from './components/ActionHeader'
import Card from '../Card'
import {
	LIBRARY,
	ADDED_FILES,
	CUSTOM_SLIDES,
	MY_FILES,
	STARRED,
	PRESENTATION_BAR,
	SUCCESS,
	BUILD,
	ERROR,
	LG
} from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import Spinner from '../common/Spinner'
import { getAddedFilesCategoryId } from '../../utils/helpers/myFiles'
import {
	addToTempPresentation,
	clearTempPresentation,
	setTempValue
} from '../../store/actions/temp'
import NoContent from './components/NoContent'
import {
	splitFile,
	sendFileToConvert,
	sendFileToRestore
} from '../../api/requests/converter'
import {
	setMyFilesContent,
	getMyFilesByCategoryId
} from '../../store/actions/myfiles'
import { updateContentReordered } from '../../api/requests/content'
import {
	setFolderPath,
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading as toggleLoadingState
} from '../../store/actions/buildContext'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import {
	convertBusinessRules,
	isTagVisible
} from '../../utils/convertBusinessRules'
import { getContentByTagId } from '../../store/actions/content'
import {
	isEditableSlide,
	isEditableDocument
} from '../../utils/helpers/isEditableSlide'
import UploadCard from '../UploadCard'
import getSalesframeAPIUrl from '../../utils/getSalesframeAPIUrl'

const SortableWrapper = SortableContainer(
	({ children }, divStyle, currentPresentationBarSize) => (
		<div
			style={divStyle}
			className={classnames(
				styles.sortableContainer,
				styles[currentPresentationBarSize === LG && LG.toLowerCase()]
			)}
		>
			{children}
		</div>
	)
)
const SortableItem = SortableElement(({ children }) => <>{children}</>)
let embeddedContentOpenedTime

const FileBrowser = ({ handleNavigation, isLoading }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { selectedCategory, numOfLoadingCards, selectedBuildMenuItem } =
		useSelector((state) => state.buildContext)
	const fileContent = useSelector((state) => state.content.content)
	const myFileContent = useSelector((state) => state.myfiles.myFiles)
	const starredContent = useSelector((state) => state.content.starredContent)
	const myFilesCategories = useSelector(
		(state) => state.myfiles.myFilesCategories
	)
	const tempPresentationSlides = useSelector((state) => state.temp.slides)
	const {
		conversionFiles,
		splitFilesList,
		emptySplitSlideCards,
		collaoraSavingFiles,
		uploadManagerFiles,
		isSidebarOpened,
		sidebarNavsDisabled
	} = useSelector((state) => state.temp)
	const { thumbnailSize } = useSelector((state) => state.misc)
	const updateConversionFolderId = useSelector(
		(state) => state.temp.updateConversionFolderId
	)
	const authToken = useSelector((state) => state.authUser.user.token)
	const currentUserGroup = useSelector(
		(state) => state.authUser.user.user.userGroupId
	)

	const [loading, toggleLoading] = useState(false)
	const [selectedTagToEmbed, setSelectedTagToEmbed] = useState()
	const [loadingThumbnailId, toggleLoadingThumbnailId] = useState(null)
	const [isDragging, toggleIsDragging] = useState(false)
	const [draggingCardIndex, setDraggingCardIndex] = useState(null)

	/**
	 *
	 * @description handles the isLoading prop to update
	 * local loading state accordingly
	 */
	useEffect(() => {
		toggleLoading(isLoading)
	}, [isLoading])

	const categoryId =
		selectedCategory?.id || getAddedFilesCategoryId(myFilesCategories)?.id

	const onDrop = useCallback(
		(files) => {
			dispatch(
				setTempValue('uploadManagerFiles', [...uploadManagerFiles, ...files])
			)
			dispatch(
				setTempValue('uploadManagerModal', {
					isShowing: true,
					role: 'my_files',
					modalType: 'modal'
				})
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[categoryId, myFileContent]
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
		accept: null,
		noDragEventsBubbling: true,
		noClick: true
	})

	useEffect(() => {
		if (updateConversionFolderId === categoryId) {
			dispatch(getMyFilesByCategoryId(categoryId))
			dispatch(setTempValue('updateConversionFolderId', null))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateConversionFolderId])

	/**
	 *
	 * @description handles the select / deselect of card click
	 * @param {object} file the id of the clicked slide object
	 * @param {object} e the event of the performed click
	 */
	const handleSelect = (file, e) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(file, PRESENTATION_BAR))
	}

	/**
	 *
	 *
	 * @param {string} category the selected category returned by the Context A
	 * @returns {string} the translated label of my files OR custom slides, or the category itself
	 */
	const handleSelectedCategoryName = (category) => {
		switch (category) {
			case ADDED_FILES:
				return t('labels.added-files')
			case CUSTOM_SLIDES:
				return t('labels.custom-slides')
			default:
				return category
		}
	}

	/**
	 *
	 * @description decides what content to map inside the fileBrowser
	 * filtering out the hidden files from the arrays
	 * @returns {Array} content an array of the corresponding slides
	 */
	const getFileBrowserContent = () => {
		let thisContent = []
		if (selectedBuildMenuItem === MY_FILES) {
			thisContent = myFileContent
		} else if (selectedBuildMenuItem === LIBRARY) {
			if (selectedCategory.tag_id) {
				thisContent = fileContent.filter((file) => file.visibility === 1)
			}
		} else if (selectedBuildMenuItem === STARRED) {
			thisContent = uniqBy(starredContent, 'id').filter(
				(file) => file.visibility !== 0
			)
		}
		return thisContent
	}

	/**
	 * @description add file to conversion queue
	 * if file object is passed, send that file to conversion, otherwise use file from reducer
	 */
	const convertToSlides = () => {
		const file = {
			...tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0]
		}
		if (file.children === true) {
			sendFileToRestore({
				file_id: file.id,
				tag_id: file.tags_id
			}).then((result) => {
				const myFilesWithResult = [...myFileContent, ...result]
				dispatch(setMyFilesContent(myFilesWithResult))
				dispatch(
					addToTempPresentation(
						tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0],
						PRESENTATION_BAR
					)
				)
			})
		} else {
			sendFileToConvert({
				id: tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].id,
				checksum:
					tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].checksum,
				categoryId:
					tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0].category_id
			})
				.then((result) => {
					toast(t('notifications.success.conversion-started'), {
						position: toast.POSITION.BOTTOM_RIGHT,
						type: SUCCESS.toLowerCase(),
						autoClose: 5000
					})
					if (result.checksum) {
						dispatch(
							setTempValue('conversionFiles', [
								...conversionFiles,
								{
									...file,
									intervalHandler: null,
									folderId: categoryId,
									started: new Date()
								}
							])
						)
						dispatch(
							addToTempPresentation(
								tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0],
								PRESENTATION_BAR
							)
						)
					}
				})
				.catch((error) => {
					try {
						if (error.response.status === 400) {
							toast(t('notifications.error.file-already-converted'), {
								position: toast.POSITION.BOTTOM_RIGHT,
								type: ERROR.toLowerCase(),
								autoClose: 5000
							})
						}
					} catch {
						console.error('Conversion pdf no error response')
					}
				})
		}
	}

	/**
	 * @description add file to conversion queue
	 * if file object is passed, send that file to conversion, otherwise use file from reducer
	 */
	const splitToSlides = () => {
		const file = {
			...tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0],
			intervalHandler: null,
			folderId: categoryId,
			started: new Date()
		}
		splitFile(
			file.id,
			file.status,
			file.tags_id,
			file.checksum,
			file.name
		).then((result) => {
			dispatch(
				setTempValue('splitFilesList', [
					...splitFilesList,
					{
						folderId: categoryId,
						status: file.status,
						checksum: file.checksum,
						reference: result.reference,
						keepOriginalFile: false
					}
				])
			)
			dispatch(
				addToTempPresentation(
					tempPresentationSlides[PRESENTATION_BAR.toLowerCase()][0],
					PRESENTATION_BAR
				)
			)
		})
	}

	/**
	 *
	 * @description checkes whether the toolbar should be visible or not
	 * @param {object} file object representation of the current file
	 * @returns {boolean} whether the toolbar should be visible or not
	 */
	const isToolbarVisible = (file) => {
		const lastIndex =
			tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length - 1
		const slides = tempPresentationSlides[PRESENTATION_BAR.toLowerCase()]
		return slides[lastIndex] === file
	}

	/**
	 * @description select all files
	 * @param {boolean} isDeselect check if it's deselecting cards
	 */
	const selectAllHandler = (isDeselect = false) => {
		dispatch(clearTempPresentation(PRESENTATION_BAR))
		if (isDeselect) {
			return
		}
		getFileBrowserContent().forEach((item) =>
			dispatch(addToTempPresentation(item, PRESENTATION_BAR))
		)
	}

	/**
	 * @description SortableContainer uses this function onSortEnd event
	 * It will create a new array with reordered items and update them both
	 * on the server and in the redux store.
	 * IMPORTANT! This function will update the redux state BEFORE it sends
	 * the request to the server!! Without this, animation will late for a while.
	 * If server returns and error, redux state will be reverted to previous state!
	 */
	const onSortEnd = ({ oldIndex, newIndex }) => {
		let reorderedItems
		if (tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length > 1) {
			let indexesFromSelected = []
			tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].forEach(
				(selectedItem) => {
					indexesFromSelected.push(myFileContent.indexOf(selectedItem))
				}
			)
			reorderedItems = arrayMoveByIndex(
				myFileContent,
				indexesFromSelected,
				oldIndex < newIndex ? newIndex - 1 : newIndex
			)
		} else {
			reorderedItems = arrayMoveImmutable(myFileContent, oldIndex, newIndex)
		}
		dispatch(setMyFilesContent(reorderedItems))
		updateContentReordered(categoryId, reorderedItems, authToken).catch(() => {
			dispatch(setMyFilesContent(myFileContent))
		})
		toggleIsDragging(false)
	}

	/**
	 *
	 * @description when user clicks on folder name in breadcrumbs
	 * it will take him to that folder and fetch the content
	 * @param {object} tag object representation of the selected tag
	 */
	const goToFolder = (tag) => {
		dispatch(setFolderPath(tag.path))
		dispatch(toggleLoadingState(true))
		dispatch(getContentByTagId(tag.tag_id, authToken))
		dispatch(
			setSelectedCategory({
				...tag,
				id: tag.tag_id,
				categoryName: tag.filename
			})
		)
		setSelectedSlides([])
		navigate(`/${LIBRARY.toLowerCase()}/${tag.tag_id}`)
	}

	/**
	 *
	 * @description sets the state to selected tag and openedTime constant to
	 * current Date value
	 * @param {object} tag object representation of the selected tag
	 */
	const embeddedUrlHandler = (tag) => {
		setSelectedTagToEmbed(tag)
		embeddedContentOpenedTime = new Date()
	}

	/**
	 *
	 * @description checks for how long the embedded url was opened, sends the
	 * analytics and then sets values back to previous state
	 */
	const closeEmbedModalHandler = () => {
		const time = differenceInSeconds(new Date(), embeddedContentOpenedTime)
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.storyboard.external.opened.time',
				tag_id: selectedTagToEmbed.id,
				name: selectedTagToEmbed.name,
				time: time
			})
		)
		setSelectedTagToEmbed(undefined)
		embeddedContentOpenedTime = undefined
	}

	const decideEmbedOrOpenInNew = () => {
		if (selectedTagToEmbed && selectedTagToEmbed.target) {
			window.open(selectedTagToEmbed.embeddedUrl, '_blank')
			setSelectedTagToEmbed(undefined)
		} else {
			return (
				<Embedded
					isShowing={!!selectedTagToEmbed}
					closeModal={closeEmbedModalHandler}
					embeddedUrl={selectedTagToEmbed ? selectedTagToEmbed.embeddedUrl : ''}
				/>
			)
		}
	}

	const openCollabora = (file) => {
		const uuid = uuidv4()
		const selectedTagId = selectedCategory.id
		let url
		if (isEditableDocument(file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file.checksum}|${
				file.status === 1 ? 0 : selectedTagId
			}|${file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		} else if (isEditableSlide(file._file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file._file.checksum}|${
				file.status === 1 ? 0 : selectedTagId
			}|${file._file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		}
		if (url) {
			const collaboraObj = {
				url: `${window.location.origin}/editor/browser/6ba7057/cool.html?WOPISrc=${url}`,
				token: authToken,
				file,
				uuid,
				folderId: file.status === 1 ? 0 : selectedTagId,
				source: 'folder'
			}
			dispatch(setTempValue('collaboraData', collaboraObj))
		}
	}

	const checkIfFileIsEditable = (file) => {
		if (isEditableDocument(file.type)) {
			if (file.status === 11) {
				return true
			}
			if (file.status === 1 && file.editing === 1) {
				return true
			}
		}
		if (file._file && selectedBuildMenuItem !== STARRED) {
			if (isEditableSlide(file._file.type) && file._file.splitted === 1) {
				if (file.status === 11) {
					return true
				}
				if (file.status === 1 && file._file.editing === 1) {
					return true
				}
			}
		}
		return false
	}

	const getEmptySplitCardsFiltered = () => {
		return emptySplitSlideCards.filter((emptyFile) => {
			if (emptyFile.folderId) {
				return emptyFile.folderId === categoryId
			}
			return Number(emptyFile.tags_id) === categoryId
		})
	}

	const isSavingFile = (file) => {
		const checksum = file._file ? file._file.checksum : file.checksum
		return (
			collaoraSavingFiles.findIndex(
				(item) => item.checksum === checksum && item.fileId === file.id
			) > -1
		)
	}

	return (
		<div
			className={classnames(
				styles.fileBrowserRootWrapper,
				isDragActive && selectedBuildMenuItem === MY_FILES && styles.isDragging,
				!isSidebarOpened && styles.additionalLeftMarginSmallScreens,
				!isSidebarOpened && sidebarNavsDisabled && styles.additionalLeftMargin
			)}
			{...(selectedBuildMenuItem === MY_FILES && getRootProps())}
		>
			{selectedBuildMenuItem === MY_FILES && (
				<input {...getInputProps()} style={{ display: 'none' }} />
			)}
			<ActionHeader
				handleNavigation={handleNavigation}
				selectedTag={{
					...selectedCategory,
					name: selectedCategory.categoryName
						? handleSelectedCategoryName(selectedCategory.categoryName)
						: selectedCategory.name
				}}
				role={selectedBuildMenuItem}
				convertToSlides={convertToSlides}
				splitToSlides={splitToSlides}
				folderHasFiles={getFileBrowserContent().length > 0}
				selectAll={() =>
					selectAllHandler(
						getFileBrowserContent().length ===
							tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length
					)
				}
				selectedEmbeddedTag={(tag) => embeddedUrlHandler(tag)}
				isSelectedAll={
					getFileBrowserContent().length ===
					tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length
				}
				goToFolder={(tag) => goToFolder(tag)}
				isLoadingThumbnail={(id) => toggleLoadingThumbnailId(id)}
			/>
			{selectedBuildMenuItem === LIBRARY &&
				selectedCategory &&
				!loading &&
				getFileBrowserContent().length === 0 &&
				!selectedCategory.hasChildren && <NoContent />}
			{!loading ? (
				<SortableWrapper
					axis="xy"
					distance={20}
					onSortEnd={onSortEnd}
					transitionDuration={400}
					updateBeforeSortStart={(node) => {
						setDraggingCardIndex(node.index)
						tempPresentationSlides[PRESENTATION_BAR.toLowerCase()].length > 1 &&
							toggleIsDragging(true)
					}}
					lockToContainerEdges
				>
					<div
						className={classnames(
							styles.fileContainer,
							isDragActive && selectedBuildMenuItem === MY_FILES
								? styles.isDragging
								: null
						)}
					>
						{selectedBuildMenuItem === MY_FILES && (
							<UploadCard
								toggleUploadManagerModalVisible={() =>
									dispatch(
										setTempValue('uploadManagerModal', {
											isShowing: true,
											role: 'my_files',
											modalType: 'modal'
										})
									)
								}
								onDrop={onDrop}
							/>
						)}
						{selectedCategory.hasChildren &&
							selectedCategory.children.map(
								(currentTag) =>
									isTagVisible(
										convertBusinessRules(currentTag.business_rules),
										currentUserGroup,
										BUILD
									) && (
										<Folder
											name={currentTag.name}
											key={currentTag.tag_id}
											tag={currentTag}
											selectedEmbeddedTag={(tag) => embeddedUrlHandler(tag)}
											selectedTag={(tag) => goToFolder(tag)}
										/>
									)
							)}
						{getFileBrowserContent().map((currentFile, index) => (
							<SortableItem
								key={`item-${currentFile.key}`}
								index={index}
								value={currentFile.id}
								disabled={selectedBuildMenuItem !== MY_FILES}
							>
								<Card
									isSelected={tempPresentationSlides[
										PRESENTATION_BAR.toLowerCase()
									].includes(currentFile)}
									isToolbarVisible={isToolbarVisible(currentFile)}
									key={currentFile.key}
									size={thumbnailSize}
									isLoadingCard={
										currentFile.isLoading ||
										currentFile.id === loadingThumbnailId ||
										(isSavingFile(currentFile) &&
											selectedBuildMenuItem !== LIBRARY) ||
										false
									}
									isConverting={
										conversionFiles.findIndex(
											(item) =>
												item.checksum === currentFile.checksum &&
												item.sortorder === currentFile.sortorder
										) > -1
									}
									handleCardClick={(e) => handleSelect(currentFile, e)}
									cardObject={{
										...currentFile,
										isNew: false,
										extension: currentFile.type,
										id: currentFile.id
									}}
									sortableItem={SortableElement}
									hasLayoutFontColor={false}
									openFileEditor={openCollabora}
									isEditable={checkIfFileIsEditable(currentFile)}
									isSplitting={
										splitFilesList.findIndex(
											(splitFile) => splitFile.checksum === currentFile.checksum
										) > -1
									}
									draggingFiles={
										isDragging &&
										index === draggingCardIndex &&
										tempPresentationSlides[PRESENTATION_BAR.toLowerCase()]
											.length
									}
									className={
										tempPresentationSlides[
											PRESENTATION_BAR.toLowerCase()
										].includes(currentFile) &&
										isDragging &&
										styles.draggingCard
									}
								/>
							</SortableItem>
						))}
						{selectedBuildMenuItem === MY_FILES && (
							<>
								{getEmptySplitCardsFiltered().map((currentFile, index) => (
									<Card
										isSelected={
											currentFile.id &&
											tempPresentationSlides[
												PRESENTATION_BAR.toLowerCase()
											].includes(currentFile)
										}
										size={thumbnailSize}
										isLoadingCard={!currentFile.id}
										isDraggable={false}
										isToolbarVisible={isToolbarVisible(currentFile)}
										cardObject={{
											...currentFile,
											isNew: true,
											extension: currentFile.type || '',
											id: currentFile.id || ''
										}}
										hasLayoutFontColor={false}
										key={currentFile.key || `split_file-${index}`}
										handleCardClick={
											currentFile.id
												? (e) => handleSelect(currentFile, e)
												: undefined
										}
									/>
								))}
								{collaoraSavingFiles
									.filter(
										(savingFile) =>
											!savingFile.isSave && savingFile.folderId === categoryId
									)
									.map((savingFile) => (
										<Card
											isSelected={false}
											size={thumbnailSize}
											isLoadingCard
											isDraggable={false}
											isToolbarVisible={false}
											cardObject={{
												...savingFile,
												isNew: false,
												extension: '',
												id: ''
											}}
											hasLayoutFontColor={false}
											key={uuidv4()}
										/>
									))}
							</>
						)}
						{new Array(numOfLoadingCards).fill().map(() => (
							<Card
								size={thumbnailSize}
								isLoadingCard
								isDraggable={false}
								isToolbarVisible={false}
								cardObject={{
									isNew: true,
									name: 'demo',
									extension: 'ppt'
								}}
								hasLayoutFontColor={false}
							/>
						))}
					</div>
				</SortableWrapper>
			) : (
				<div className={styles.loader}>
					<Spinner isLoading={loading} />
				</div>
			)}
			{!!selectedTagToEmbed && decideEmbedOrOpenInNew()}
		</div>
	)
}

FileBrowser.defaultProps = {
	isLoading: false
}

FileBrowser.propTypes = {
	handleNavigation: PropTypes.func,
	isLoading: PropTypes.bool
}

export default FileBrowser
