import React from 'react'

import styles from './Result.module.scss'

import { t } from '../../../../utils/languages/i18n'

interface ResultsInterface {
	data: any
	selectedData: (data: any) => void
	selectedCustomerName?: string
}

const Index = ({
	data,
	selectedData,
	selectedCustomerName
}: ResultsInterface) => (
	<div
		className={styles.customerItem}
		tabIndex={0}
		onClick={() => selectedData(data)}
	>
		<p className={styles.feedItemText}>
			<span>{data.name}</span>
		</p>
		{!selectedCustomerName && data._parentaccountid_value && (
			<p className={styles.feedFooter}>
				<span>{`${t('labels.parent-account')} ${
					data._parentaccountid_value
				}`}</span>
			</p>
		)}
		{data.description && (
			<p className={styles.feedFooter}>
				<span>{`${t('labels.parent-account')} ${data.description}`}</span>
			</p>
		)}
		{selectedCustomerName && (
			<p className={styles.feedFooter}>
				<span>{selectedCustomerName}</span>
			</p>
		)}
	</div>
)

export default Index
