import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import styles from './GalleryItem.module.scss'
import {
	GALLERY_SHAPE_BOX,
	GALLERY_SHAPE_CIRCLE,
	GALLERY_SHAPE_NONE,
	GALLERY_SHAPE_ROUNDEDN_BOX
} from '../../../../../utils/consts'
import { sfApiRoot } from '../../../../../api/apiEndpoints_new'

/**
 * @author zilahir
 * @function GalleryItem
 * */

const Item = styled(motion.div)`
	background-color: ${(props) => props.backgroundColor};
`

const Tag = styled.p`
	font-family: ${(props) => props.fontFamily};
`

/**
 *
 * @param {string} givenShape the shape const the gallery folder was  saved with
 * @returns {string} shape the converted shape into className
 */
export function getShapeClass(givenShape) {
	let shape
	if (givenShape === GALLERY_SHAPE_CIRCLE) {
		shape = 'shapeCircle'
	} else if (givenShape === GALLERY_SHAPE_ROUNDEDN_BOX) {
		shape = 'shapeRoundedBox'
	} else if (givenShape === GALLERY_SHAPE_BOX) {
		shape = 'box'
	} else if (givenShape === GALLERY_SHAPE_NONE) {
		shape = 'none'
		// this is not really neccessary just for the sake of consistence
	}
	return shape
}

const GalleryItem = ({
	depth,
	onClick,
	shape,
	imageUrl,
	backgroundColor,
	index,
	tag,
	shapeShadow,
	textShadow,
	showInLayout,
	isSelected
}) => {
	const authToken = useSelector((state) => state.authUser.user.token)
	const layoutSettings = useSelector((state) => state.layout)

	const galleryItemAnimationVariants = {
		hidden: {
			opacity: 0,
			y: 10
		},
		visible: (custom) => ({
			y: 0,
			opacity: showInLayout ? 0.5 : 1,
			transition: {
				staggerChildren: 0.4,
				delay: custom * 0.05,
				when: 'beforeChildren'
			}
		}),
		exit: (custom) => ({
			opacity: 0,
			y: 0,
			transition: {
				staggerChildren: 0.4,
				delay: custom * 0.05,
				when: 'beforeChildren'
			}
		})
	}

	const selectedGalleryItemAnimationVariants = {
		notSelected: (custom) => ({
			opacity: 0.75,
			scale: 1,
			transition: {
				staggerChildren: 0.4,
				delay: custom * 0.05,
				when: 'beforeChildren'
			}
		}),
		selected: {
			opacity: 1,
			scale: 1.05
		}
	}

	const getAnimationVariants = () => {
		if (isSelected) {
			const state =
				isSelected.tag_id === tag.tag_id ? 'selected' : 'notSelected'
			return {
				variant: selectedGalleryItemAnimationVariants,
				states: {
					animate: state,
					initial: 'hidden'
				}
			}
		}
		return {
			variant: galleryItemAnimationVariants,
			states: {
				animate: 'visible',
				initial: 'hidden'
			}
		}
	}

	return (
		<Item
			className={classnames(
				styles.galleryItem,
				shapeShadow ? styles.hasShadow : '',
				styles[getShapeClass(shape)],
				!imageUrl ? styles.center : ''
			)}
			onClick={() => onClick(tag, depth)}
			tabIndex={-1}
			onKeyDown={null}
			role="button"
			backgroundColor={backgroundColor}
			custom={index}
			whileHover={{ scale: 1.05 }}
			whileTap={{ scale: 0.99 }}
			variants={getAnimationVariants().variant}
			initial={getAnimationVariants().states.initial}
			animate={getAnimationVariants().states.animate}
			exit="exit"
			showInLayout={showInLayout}
		>
			{!imageUrl ? (
				<Tag
					className={classnames(
						styles.tagName,
						textShadow ? styles.hasTextShadow : ''
					)}
					fontFamily={layoutSettings.storyBoard.storyBoardFont.family}
				>
					{tag.name}
				</Tag>
			) : (
				<img
					src={`${sfApiRoot}/files/assets/files/${imageUrl}?oauth=${encodeURIComponent(
						authToken
					)}`}
					alt={tag.name}
				/>
			)}
		</Item>
	)
}

GalleryItem.defaultProps = {
	backgroundColor: 'transparent',
	isSelected: undefined,
	shape: '',
	showInLayout: true
}

GalleryItem.propTypes = {
	backgroundColor: PropTypes.string,
	depth: PropTypes.number.isRequired,
	imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
	index: PropTypes.number.isRequired,
	isSelected: PropTypes.shape({
		tag_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}),
	onClick: PropTypes.func.isRequired,
	shape: PropTypes.string,
	shapeShadow: PropTypes.bool.isRequired,
	showInLayout: PropTypes.bool,
	tag: PropTypes.objectOf(PropTypes.any).isRequired,
	textShadow: PropTypes.bool.isRequired
}

export default GalleryItem
