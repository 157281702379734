import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import { sendOppFonectaSchema } from '../../utils/schemas/requests'
import {
	salesforcePostSectoEndpoint,
	salesforceSearchSectoEndpoint
} from '../apiEndpoints_new'

export interface SectoSearchOpportunityInterface {
	AccountName: string
	AccountId: string
	OpportunityName: string
	OpportunityId: string
}

export const searchOpportunity = (name: string) =>
	new Promise<SectoSearchOpportunityInterface[]>((resolve, reject) => {
		cloudFnGet(`${salesforceSearchSectoEndpoint}/${name}`, {}, {}, null)
			.then((result) => {
				resolve(result as SectoSearchOpportunityInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendCrmSectoData = (data: any) =>
	new Promise<any>((resolve, reject) => {
		cloudFnPost(salesforcePostSectoEndpoint, data, {}, sendOppFonectaSchema)
			.then((response) => {
				resolve({
					success: true,
					response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
