import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

import styles from './LogoutAndBackInModal.module.scss'

import { t } from '../../utils/languages/i18n'
import Button from '../common/Button'
import Modal from '../common/ModalNew'
import { StoreInterface } from '../../utils/interfaces'
import { setTempValue } from '../../store/actions/temp'

const LogoutAndBackInModal = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const showLogoutAndBackInModal = useSelector(
		(reduxStore: StoreInterface) => reduxStore.temp.showLogoutAndBackInModal
	)
	const { role } = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.user
	)

	const closeModalHandler = () => {
		dispatch(setTempValue('showLogoutAndBackInModal', false))
	}

	const logout = () => {
		navigate('/logout')
		dispatch(setTempValue('showLogoutAndBackInModal', false))
	}

	return (
		<Modal
			isShowing={showLogoutAndBackInModal}
			hide={closeModalHandler}
			customClassName={classnames(styles.modalWrapper)}
			modalTitle={t('titles.user-role-changed')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.not-now')}
						onClick={closeModalHandler}
						id="logout-and-back-in-modal-not-now"
					/>
					<Button
						label={t('buttons.logout')}
						onClick={logout}
						isPositive
						id="logout-and-back-in-modal-logout"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<p>{`${t('misc.your-admin-has-changed-your-role')} ${role}. ${t(
					'misc.you-will-need-to-logout'
				)}`}</p>
			</div>
		</Modal>
	)
}

export default LogoutAndBackInModal
