import styled from 'styled-components'

const Grid = styled('div')`
	position: absolute;
	top: 0;
	left: 0;
	width: ${(props: { width: number }) => props.width}%;
	height: 8px;
	margin-bottom: 5px;
	background-color: #88b0ff;
	border-radius: 5px;
`

const List = styled('div')`
	position: absolute;
	top: 0;
	left: 0;
	width: ${(props: { width: number }) => props.width}%;
	height: 8px;
	background-color: #88b0ff;
	border-radius: 10px;
`

const SlideGraph = ({ width, type }: { width: number; type: string }) => {
	if (type === 'grid') return <Grid width={width} />
	return <List width={width} />
}

export default SlideGraph
