import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import c from 'classnames'
import { differenceInSeconds } from 'date-fns'
import {
	SearchOutlined as SearchIcon,
	FolderOutlined as FolderIcon,
	KeyboardArrowDown as ExpandIcon,
	InsertDriveFileOutlined as FileIcon
} from '@mui/icons-material'

import styles from './Search.module.scss'

import SfModal from '../common/ModalNew'
import Input from '../common/Input'
import { t } from '../../utils/languages/i18n'
import Button from '../common/Button'
import { asyncRequest } from '../../utils/requests'
import { getContentByTagId } from '../../store/actions/content'
import {
	setFolderPath,
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading
} from '../../store/actions/buildContext'
import { BUTTON_TEXT, LIBRARY, SEARCH_MODAL } from '../../utils/consts'
import Spinner from '../common/Spinner'
import { searchAdvanceEndpoint } from '../../api/apiEndpoints_new'
import {
	addToTempPresentation,
	clearTempPresentation,
	setTempValue
} from '../../store/actions/temp'
import {
	ModuleNamesInterface,
	SlideInterface,
	StoreInterface
} from '../../utils/interfaces'
import Folder from '../FileBrowser/components/Folder'
import Card from '../Card'
import { capitalizeFirstLetter } from '../../utils/helpers/stringMutations'
import BigGreenButton from '../PresentationBar/components/BigGreenButton'
import Embedded from '../../components/FileBrowser/components/EmbeddedModal'
import { addToAnalyticsBatch } from '../../store/actions/analytics'

interface SearchInterface {
	show: boolean
	handleClose: () => void
	openSearchFolder?: (tag: any) => void
	activePage?: string
}

let embeddedContentOpenedTime: Date | undefined

const Search = ({
	show,
	handleClose,
	openSearchFolder,
	activePage
}: SearchInterface) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const emptyContent: { tags: any[]; files: SlideInterface[] } = {
		tags: [],
		files: []
	}

	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const { currentModule } = useSelector((store: StoreInterface) => store.temp)
	const tempPresentationSlides = useSelector(
		(store: StoreInterface) => store.temp.slides
	)

	const [searchTerm, setSearchTerm] = React.useState('')
	const [content, setContent] = React.useState(emptyContent)
	const [folderLimit, setFolderLimit] = React.useState(8)
	const [fileLimit, setFileLimit] = React.useState(8)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [showResults, toggleShowResults] = React.useState(false)
	const [tagToSearch, setTagToSearch] = React.useState(undefined)
	const [foldersExpanded, toggleFoldersExpanded] = React.useState(true)
	const [filesExpanded, toggleFilesExpanded] = React.useState(true)
	const [selectedTagToEmbed, setSelectedTagToEmbed] =
		React.useState<any>(undefined)

	React.useEffect(() => {
		if (activePage === LIBRARY && tagToSearch) {
			handleSearchFolder(tagToSearch)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePage])

	React.useEffect(() => {
		if (searchTerm.length >= 3) {
			setSearchLoading(true)
			const timeout = setTimeout(() => {
				search(searchTerm)
			}, 1500)
			return () => clearTimeout(timeout)
		} else {
			setSearchLoading(false)
			setContent(emptyContent)
			toggleShowResults(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	const search = (value: string) => {
		setSearchLoading(true)
		toggleShowResults(true)
		const param: any = {}
		let data
		if (value[0] === '"' && value[value.length - 1] === '"') {
			data = value.slice(1, -1)
			param.a = data.toString()
		} else if (value.includes(',')) {
			data = value
				.split(',')
				.filter((item) => item.trim().length > 1)
				.map((item) => item.trim())
			param.o = data.toString()
		} else if (value.includes('+')) {
			data = value
				.split('+')
				.filter((item) => item.trim().length > 1)
				.map((item) => item.trim())
			param.a = data.toString()
		} else if (value.includes('-')) {
			data = value
				.split('-')
				.filter((item) => item.trim().length > 1)
				.map((item) => item.trim())
			param.e = data.toString()
		} else {
			data = value
				.split(' ')
				.filter((item) => item.trim().length > 1)
				.map((item) => item.trim())
			param.o = data.toString()
		}
		asyncRequest(searchAdvanceEndpoint, authToken, param)
			.then((response: any) => {
				setContent({
					tags: response.tags.map((item: any, index: number) => ({
						...item,
						key: index
					})),
					files: response.files.map((item: SlideInterface, index: number) => ({
						...item,
						key: index
					}))
				})
				setSearchLoading(false)
			})
			.catch((error) => {
				if (error.message !== 'cancel') {
					setSearchLoading(false)
				}
			})
	}

	const onSearchTerm = (value: string) => {
		setSearchTerm(value)
	}

	const goToFolder = (tag: any) => {
		if (currentModule !== ModuleNamesInterface.BUILD) {
			handleSearchFolder(tag)
		}
		navigate(`/${LIBRARY.toLowerCase()}/${tag.tag_id}`)
	}

	const handleSearchFolder = (tag: any) => {
		setTagToSearch(undefined)
		dispatch(setFolderPath(tag.path))
		dispatch(toggleLoading(true))
		dispatch(getContentByTagId(tag.tag_id, authToken))
		dispatch(toggleLoading(false))
		dispatch(
			setSelectedCategory({
				...tag,
				id: tag.tag_id,
				categoryName: tag.filename
			})
		)
		dispatch(setSelectedSlides([]))
		closeModalHandler()
		dispatch(setTempValue('libraryHomepageVisible', false))
	}

	const openFolderWithPropFunc = (tag: any) => {
		if (openSearchFolder) {
			openSearchFolder(tag)
		}
		closeModalHandler()
	}

	const handleSelect = (file: any, e: any) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(file, SEARCH_MODAL.toLowerCase()))
	}

	const closeModalHandler = () => {
		setSearchTerm('')
		setContent(emptyContent)
		dispatch(clearTempPresentation(SEARCH_MODAL))
		handleClose()
	}

	const embeddedUrlHandler = (tag: any) => {
		setSelectedTagToEmbed(tag)
		embeddedContentOpenedTime = new Date()
	}

	const decideEmbedOrOpenInNew = () => {
		if (selectedTagToEmbed && selectedTagToEmbed.target) {
			window.open(selectedTagToEmbed.embeddedUrl, '_blank')
			setSelectedTagToEmbed(undefined)
		} else {
			return (
				<Embedded
					isShowing={!!selectedTagToEmbed}
					closeModal={closeEmbedModalHandler}
					embeddedUrl={selectedTagToEmbed ? selectedTagToEmbed.embeddedUrl : ''}
				/>
			)
		}
	}

	const closeEmbedModalHandler = () => {
		if (embeddedContentOpenedTime) {
			const time = differenceInSeconds(new Date(), embeddedContentOpenedTime)
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.storyboard.external.opened.time',
					tag_id: selectedTagToEmbed.id,
					name: selectedTagToEmbed.name,
					time
				})
			)
		}
		setSelectedTagToEmbed(undefined)
		embeddedContentOpenedTime = undefined
	}

	return (
		<React.Fragment>
			<SfModal
				isShowing={show}
				hide={closeModalHandler}
				customClassName={styles.searchModal}
				modalTitle={t('labels.search')}
				modalTitleIcon={<SearchIcon />}
				headerClassname={styles.modalHeader}
				overlayClassName={styles.searchModalOverlay}
				zIndex={currentModule === ModuleNamesInterface.BUILD ? 9999 : 10199}
				after={<BigGreenButton role={SEARCH_MODAL.toLowerCase()} />}
			>
				<div className={styles.content}>
					<div className={styles.search}>
						<Input
							placeholderText={`${t('labels.search')}...`}
							name="search"
							initialValue={searchTerm}
							autoFill="off"
							onChange={(e) => onSearchTerm(e.target.value)}
						/>
						{searchLoading && (
							<div className={styles.spinner}>
								<Spinner isLoading={searchLoading} size={25} />
							</div>
						)}
						<p>{t('misc.search-input-description')}</p>
					</div>
					<div className={styles.searchContent}>
						{searchTerm && showResults && (
							<>
								<div
									className={c(styles.group, foldersExpanded && styles.opened)}
								>
									<div
										className={styles.heading}
										onClick={() => toggleFoldersExpanded(!foldersExpanded)}
									>
										<span>
											<FolderIcon />
											{t('titles.folders')}
											{content.tags.length > 0
												? ` (${content.tags.length})`
												: ''}
										</span>
										<div
											className={c(
												styles.accordion,
												foldersExpanded && styles.opened
											)}
										>
											<ExpandIcon className={styles.expandIcon} />
										</div>
									</div>
									<div className={styles.tagList}>
										{content && (
											<>
												{content.tags.length > 0 ? (
													content.tags
														.slice(0, folderLimit)
														.map((item: any, index) => (
															<div className={styles.path} key={index}>
																<div
																	className={styles.text}
																	title={item.path
																		.map((path: any) => path.name)
																		.join(' / ')}
																>
																	{item.path
																		.map((path: any) => path.name)
																		.join(' / ')}
																</div>
																<Folder
																	name={item.name}
																	key={item.tag_id}
																	tag={item}
																	selectedEmbeddedTag={(tag) =>
																		embeddedUrlHandler(tag)
																	}
																	selectedTag={() =>
																		openSearchFolder
																			? openFolderWithPropFunc(item)
																			: goToFolder(item)
																	}
																/>
															</div>
														))
												) : (
													<p className={styles.emptyText}>
														{searchLoading
															? t('labels.searching')
															: t('labels.no-folders')}
														...
													</p>
												)}
											</>
										)}
									</div>
									{content.tags.length > 0 &&
										content.tags.length > folderLimit && (
											<div>
												<Button
													type={BUTTON_TEXT}
													label={t('labels.load-more')}
													containerClass={styles.loadMoreBtn}
													onClick={() => setFolderLimit(folderLimit + 8)}
													id="search-load-more-button"
												/>
											</div>
										)}
								</div>
								<div
									className={c(styles.group, filesExpanded && styles.opened)}
								>
									<div
										className={styles.heading}
										onClick={() => toggleFilesExpanded(!filesExpanded)}
									>
										<span>
											<FileIcon />
											{capitalizeFirstLetter(t('labels.files'))}
											{content.files.length > 0
												? ` (${content.files.length})`
												: ''}
										</span>
										<div
											className={c(
												styles.accordion,
												filesExpanded && styles.opened
											)}
										>
											<ExpandIcon className={styles.expandIcon} />
										</div>
									</div>
									<div className={styles.tagList}>
										{content && (
											<>
												{content.files.length > 0 ? (
													content.files
														.slice(0, fileLimit)
														.map((file, index) => (
															<div className={styles.path} key={index}>
																<div
																	className={c(styles.text, styles.filesText)}
																	title={file.path
																		.map((path) => path.name)
																		.join(' / ')}
																>
																	{file.path
																		.map((path) => path.name)
																		.join(' / ')}
																</div>
																<Card
																	isSelected={tempPresentationSlides[
																		SEARCH_MODAL.toLowerCase()
																	].includes(file)}
																	cardObject={{
																		...file,
																		extension: file.type
																	}}
																	handleCardClick={(event) =>
																		handleSelect(file, event)
																	}
																	hasLayoutFontColor={false}
																	hasToolbar={true}
																	openFileEditor={() => null}
																	isEditable={false}
																/>
															</div>
														))
												) : (
													<p className={styles.emptyText}>
														{searchLoading
															? t('labels.searching')
															: t('labels.no-content')}
														...
													</p>
												)}
											</>
										)}
									</div>
									{content.files.length > 0 &&
										content.files.length > fileLimit && (
											<div>
												<Button
													type={BUTTON_TEXT}
													label={t('labels.load-more')}
													containerClass={styles.loadMoreBtn}
													onClick={() => setFileLimit(fileLimit + 8)}
													id="search-load-more-button"
												/>
											</div>
										)}
									{!!selectedTagToEmbed && decideEmbedOrOpenInNew()}
								</div>
							</>
						)}
					</div>
				</div>
			</SfModal>
		</React.Fragment>
	)
}

export default Search
