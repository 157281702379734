import { RESET_MEMOSITE_REDUCER, SET_MEMOSITE_VALUE } from './actionTypes'

export const setMemositeValue = (name: any, value: any) => ({
	type: SET_MEMOSITE_VALUE,
	payload: {
		name,
		value
	}
})

export const resetMemositeReducer = () => ({
	type: RESET_MEMOSITE_REDUCER
})
