import React from 'react'
import PropTypes from 'prop-types'

import styles from './CrmConfirmationModal.module.scss'
import ConfirmDeleteModal from '../common/ConfirmDeleteModal'
import { t } from '../../utils/languages/i18n'
import Section from '../common/Section'

const CrmConfirmationModal = ({
	isActive,
	handleClose,
	onSubmit,
	buttonDisabled,
	crmData
}) => (
	<ConfirmDeleteModal
		isActive={isActive}
		handleClose={handleClose}
		title={t('titles.sending-crm-data')}
		onApprove={onSubmit}
		disableButton={buttonDisabled}
	>
		<Section
			padding="10px 20px 0px"
			flexDirection="column"
			alignItems="flex-start"
		>
			<p className={styles.crmInfo}>
				{t('titles.crm-confirmation-description')}
			</p>
			<p className={styles.crmInfo}>
				{t('titles.entered-client-id')}:{' '}
				<strong>{crmData ? crmData.customerId : ''}</strong>
			</p>
			<p className={styles.crmInfo}>
				{t('titles.entered-document-name')}:{' '}
				<strong>{crmData ? crmData.caseValue : ''}</strong>
			</p>
		</Section>
	</ConfirmDeleteModal>
)

CrmConfirmationModal.defaultProps = {
	buttonDisabled: false,
	crmData: null
}

CrmConfirmationModal.propTypes = {
	buttonDisabled: PropTypes.bool,
	crmData: PropTypes.shape({
		caseValue: PropTypes.string,
		customerId: PropTypes.string
	}),
	handleClose: PropTypes.func.isRequired,
	isActive: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired
}

export default CrmConfirmationModal
