import React from 'react'

import styles from './Stats.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { getFormattedDuration } from '../../../../utils/helpers/timeFormat'
import Rating from '../MemositeItem/Rating'
import { MeetingInterface } from '../../../../utils/interfaces'
import {
	MemositeAnalyticsInterface,
	MemositeSingleSessionInterface
} from '../../interfaces'

interface StatsInterface {
	fullStats?: boolean
	memosite: MeetingInterface
	memositeAnalytics: MemositeAnalyticsInterface
	singleSession?: MemositeSingleSessionInterface
}

const Stats = ({
	fullStats,
	memosite,
	memositeAnalytics,
	singleSession
}: StatsInterface) => {
	return (
		<div className={styles.statsRow}>
			<div className={styles.sectionLabel}>{t('titles.stats')}</div>
			{fullStats ? (
				<div className={styles.statsGrid}>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.opened')}:</div>
						<div className={styles.value}>
							{memositeAnalytics.number_of_memosites} {t('labels.times')}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.unique-opens')}:</div>
						<div className={styles.value}>
							{memositeAnalytics.unique_visitors}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.downloads')}:</div>
						<div className={styles.value}>
							<span className={styles.label}>{t('labels.pdf')}:</span>{' '}
							<span className={styles.value}>
								{memositeAnalytics.number_of_downloaded}
							</span>
							<span className={styles.separator} />
							<span className={styles.label}>{t('labels.zip')}:</span>{' '}
							<span className={styles.value}>
								{memositeAnalytics.number_of_downloaded_zip}
							</span>
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.total-time')}:</div>
						<div className={styles.value}>
							{getFormattedDuration(memositeAnalytics.average_time)}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.avg-time')}:</div>
						<div className={styles.value}>
							{getFormattedDuration(
								memositeAnalytics.average_time /
									memositeAnalytics.number_of_memosites
							)}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.avg-rating')}:</div>
						<div className={styles.value}>
							<p className={styles.rating}>
								<Rating rating={memosite.rating || 0} />
								<span className={styles.number}>
									{memosite.rating ? memosite.rating : 0}
								</span>
							</p>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.statsGrid}>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.total-time')}:</div>
						<div className={styles.value}>
							{singleSession && getFormattedDuration(singleSession.total_time)}
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.downloads')}:</div>
						<div className={styles.value}>
							<span className={styles.label}>{t('labels.pdf')}:</span>{' '}
							<span className={styles.value}>N/A</span>
							<span className={styles.separator} />
							<span className={styles.label}>{t('labels.zip')}:</span>{' '}
							<span className={styles.value}>N/A</span>
						</div>
					</div>
					<div className={styles.block}>
						<div className={styles.label}>{t('labels.rating')}:</div>
						<div className={styles.value}>
							<p className={styles.rating}>
								<Rating rating={memosite.rating || 0} />
								<span className={styles.number}>
									{memosite.rating || 'N/A'}
								</span>
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default Stats
