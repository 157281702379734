import {
	SET_CURRENT_TAG_TREE,
	SET_ALL_SHORTCUTS,
	SET_TAGS_NAMED_VALUE,
	SET_FOLDER_FILES_PATH
} from '../actions/actionTypes'

const initialState = {
	tagTree: [],
	shortcuts: [],
	backgroundImages: [],
	arrayOfFolders: []
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_CURRENT_TAG_TREE:
			return {
				...state,
				tagTree: action.payload.tagTree
			}
		case SET_FOLDER_FILES_PATH:
			return {
				...state,
				arrayOfFolders: action.payload.arrayOfFolders
			}
		case SET_ALL_SHORTCUTS:
			return {
				...state,
				shortcuts: action.payload.shortcuts
			}
		case SET_TAGS_NAMED_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		default:
			return state
	}
}

export default reducer
