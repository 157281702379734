import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import styles from './CRMModal.module.scss'

import Modal from '../../../components/common/ModalNew'
import { t } from '../../../utils/languages/i18n'
import Section from '../../../components/common/Section'
import Button from '../../../components/common/Button'
import { FLEX_END } from '../../../utils/consts'
import { getCrmData } from '../../../api/requests/crm'
import Dropdown from '../../../components/common/Dropdown'
import Input from '../../../components/common/Input'
import { StoreInterface } from '../../../utils/interfaces'

interface CRMModalInterface {
	handleClose: () => void
	memosite: any
	saveButtonDisabled: boolean
	handleSave: () => void
	handleChange: (key: string, value: any) => void
	crmData: {
		caseValue: string
		customerId: string
		selectedDeal: {
			id: number
		}
	}
	zIndex?: number
}

const CRMModal = ({
	handleClose,
	memosite,
	saveButtonDisabled = false,
	handleSave,
	handleChange,
	crmData,
	zIndex
}: CRMModalInterface) => {
	const [deals, setDeals] = useState([])
	const crmSettings = useSelector(
		(store: StoreInterface) => store.temp.crmSettings
	)

	useEffect(() => {
		if (memosite) {
			try {
				if (deals.length === 0 && crmSettings.id === 'pipedrive') {
					getCrmData(crmSettings.getData)
						.then((dealsResponse: any) => {
							setDeals(dealsResponse.body.data)
						})
						.catch((e) => console.error(e))
				}
			} catch (error) {
				console.error(error)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memosite])

	/**
	 * @description get CRM fields depending on CRM id
	 * @returns {JSX.Element} return CRM input fields
	 */
	const getCrmInputs = () => {
		if (crmSettings) {
			if (crmSettings.id === 'pipedrive') {
				return (
					<div>
						<Section padding="20px" flexDirection="column">
							<Dropdown
								listItems={deals.map(({ id, title }) => ({
									value: title,
									key: id,
									label: title,
									id
								}))}
								placement="bottom"
								defaultIndex={null}
								placeholder={t('labels.choose-deal')}
								onChangeCallback={(selected) =>
									handleChange('selectedDeal', selected)
								}
							/>
						</Section>
					</div>
				)
			}
			if (crmSettings.id === 'elo') {
				return (
					<div className={styles.crmContainer}>
						<Input
							onChange={(event) =>
								handleChange('customerId', event.target.value)
							}
							placeholderText={t('labels.customer-id')}
							name="customerId"
							initialValue={crmData ? crmData.customerId : ''}
							inputClassName={styles.input}
						/>
						<Input
							onChange={(event) =>
								handleChange('caseValue', event.target.value)
							}
							placeholderText={t('input-labels.document-name')}
							name="caseValue"
							initialValue={crmData ? crmData.caseValue : ''}
							inputClassName={styles.input}
						/>
					</div>
				)
			}
		}
		return null
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={handleClose}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>{memosite && memosite.subject}</p>
			</div>
			{getCrmInputs()}
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px"
			>
				<Button
					onClick={handleClose}
					label={t('labels.back')}
					buttonClass={styles.btn}
					id="crm-modal-back"
				/>
				<Button
					onClick={handleSave}
					isPositive
					label={t('labels.save')}
					buttonClass={styles.btn}
					isDisabled={saveButtonDisabled}
					id="crm-modal-save"
				/>
			</Section>
		</Modal>
	)
}

export default CRMModal
