import styled from 'styled-components'
import { MoreHorizOutlined } from '@mui/icons-material'

import styles from './Label.module.scss'

const Wrapper = styled('div')`
	background-color: ${(props: { color: string }) => props.color};
`

interface LabelInterface {
	bgColor: string
	editable?: boolean
	name: string
}

const Label = ({ bgColor, editable, name }: LabelInterface) => {
	return (
		<Wrapper color={bgColor} className={styles.wrapper}>
			<div>{name}</div>
			{editable && <MoreHorizOutlined />}
		</Wrapper>
	)
}

export default Label
