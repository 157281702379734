import { useEffect, useRef } from 'react'

/**
	* @param {string} eventName the nam eof the event
	* @param {Function} handler the handler function
	* @param {import('react').DOMElement} element the element the handler is attached to
 */
export function useEventListener(eventName: any, handler: any, element: any = window) {
	// Create a ref that stores handler
	const savedHandler: any = useRef()

	useEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(
		() => {
			const isSupported = element && element.addEventListener
			if (!isSupported) return

			const eventListener = (event: any) => savedHandler.current(event)
			element.addEventListener(eventName, eventListener)

			// linter missing this feature for hook cleanup this the rule disable
			// eslint-disable-next-line consistent-return
			return () => {
				element.removeEventListener(eventName, eventListener)
			}
		},
		[eventName, element],
	)
}
