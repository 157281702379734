import { useState, useEffect } from 'react'

/**
 * @description this is a custom React hook that returns the current user agent window's size
 * @returns {object} returns on objec twith a height and a weidht property with the values
 */
export function useWindowSize() {
	const isClient = typeof window === 'object'

	/**
	 * @description gets the curernt user agent window's width and height values
	 * @returns {object} returns on objec twith a height and a weidht property with the values
	 */
	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		}
	}

	const [windowSize, setWindowSize] = useState(getSize)

	useEffect((): any => {
		if (!isClient) {
			return false
		}

		/**
		 * @description re eecutes the hooks once the window resize is detected
		 * calls the setWindowSize() function which recalculates the sizes and returns
		 */
		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return windowSize
}
