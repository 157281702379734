import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { useDropzone } from 'react-dropzone'
import md5 from 'js-md5'
import validator from 'validator'
import ClearIcon from '@mui/icons-material/Clear'
import { toast } from 'react-toastify'

import styles from './UploadManagerModal.module.scss'

import { t } from '../../utils/languages/i18n'
import Button from '../../components/common/Button'
import Modal from '../../components/common/ModalNew'
import Input from '../../components/common/Input'
import {
	addLoadingCards,
	toggleConverting
} from '../../store/actions/buildContext'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { addToTempPresentation, setTempValue } from '../../store/actions/temp'
import {
	addLink,
	addTags,
	checkIfLinkIsEmbeddable,
	uploadContent
} from '../../api/requests/content'
import { SlideInterface, StoreInterface } from '../../utils/interfaces'
import { KEY, PPTX, PPT, PRESENTATION_BAR } from '../../utils/consts'
import { getAddedFilesCategoryId } from '../../utils/helpers/myFiles'
import formatBytes from '../../utils/helpers/formatBytes'
import UploadWidget from '../UploadWidget'
import {
	addLoadingThumbnail,
	addToPresentation,
	removeAllLoadingThumbnai
} from '../../store/actions/presentation'
import { setMyFilesContent } from '../../store/actions/myfiles'
import { store } from '../../store/configureStore'
import CheckBox from '../common/CheckBox'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import { nanoid } from 'nanoid'

interface Files {
	name: string
	size: number
}

interface LinkObject {
	title: string
	link: string
}

const powerPointFileTypes = [
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation'
]

const UploadManagerModal = () => {
	const dispatch = useDispatch()

	const authToken = useSelector(
		(state: StoreInterface) => state.authUser.user.token
	)
	const categoryList = useSelector(
		(state: StoreInterface) => state.myfiles.myFilesCategories
	)
	const { conversionFiles, uploadManagerModal, uploadManagerFiles } =
		useSelector((state: StoreInterface) => state.temp)
	const buildContext = useSelector(
		(state: StoreInterface) => state.buildContext
	)
	const myFiles = useSelector((state: StoreInterface) => state.myfiles.myFiles)

	const [links, setLinks] = useState<LinkObject[]>([])
	const [title, setTitle] = useState('')
	const [link, setLink] = useState('')
	/*const [files, setFiles] = useState([])*/
	const [showWarningURL, setshowWarningURL] = useState(false)

	// wrapper states
	const [apiResponse, setApiResponse] = useState<SlideInterface[]>([])
	const [percentage, setPercentage] = useState(0)
	const [uploadableFiles, setUploadableFiles] = useState<Files[]>([])
	const [uploadedFiles, setUploadedFiles] = useState<Files[]>([])
	const [splitPPTXFiles, setSplitPPTXFiles] = useState(true)
	const [keepOriginalPPTXFile, setKeepOriginalPPTXFile] = useState(false)
	const [linkIsNotEmbeddable, toggleLinkIsNotEmbeddable] = useState(false)

	const addLinks = () => {
		const protocolPattern = /^((http|https):\/\/)/
		let newLink = ''
		if (!protocolPattern.test(link)) {
			newLink = 'https://' + link
		}

		const linkObj = {
			title,
			link: newLink || link
		}
		checkIfLinkIsEmbeddable(linkObj.link)
			.then((response) => {
				if (response.embedded) {
					setLinks([...links, linkObj])
					setTitle('')
					setLink('')
				} else {
					toggleLinkIsNotEmbeddable(true)
				}
			})
			.then(() => {
				if (linkIsNotEmbeddable) {
					toggleLinkIsNotEmbeddable(false)
				}
			})
	}

	const getTotal = () => {
		const totalSize = uploadManagerFiles.map((item: { size: any }) => item.size)
		return _.sum(totalSize)
	}

	const onDrop = useCallback(
		(droppedFiles) => {
			dispatch(
				setTempValue('uploadManagerFiles', [
					...uploadManagerFiles,
					...droppedFiles
				])
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadManagerFiles]
	)

	const categoryId =
		uploadManagerModal.role === 'my_files'
			? buildContext.selectedCategory?.id
			: getAddedFilesCategoryId(categoryList)?.id

	const handleClose = () => {
		dispatch(
			setTempValue('uploadManagerModal', {
				...uploadManagerModal,
				isShowing: false,
				modalType: 'modal'
			})
		)
	}

	const uploadFunction = () => {
		if (uploadManagerFiles.length > 0) {
			setUploadableFiles(uploadManagerFiles)
			if (uploadManagerModal.role === 'presentation_bar')
				dispatch(addLoadingThumbnail(uploadManagerFiles.length))
			else if (uploadManagerModal.role === 'my_files') {
				dispatch(addLoadingCards(uploadManagerFiles.length))
			}
			dispatch(
				setTempValue('uploadManagerModal', {
					...uploadManagerModal,
					modalType: 'widget'
				})
			)
			const filesCopy = [...uploadManagerFiles]
			dispatch(setTempValue('uploadManagerFiles', []))
			let params
			if (
				filesCopy.findIndex((item: any) =>
					powerPointFileTypes.includes(item.type)
				) > -1
			) {
				params = {
					splitToSlides: splitPPTXFiles,
					keepOriginalFile: keepOriginalPPTXFile
				}
			}
			uploadContent(filesCopy, categoryId, setPercentage, params)
				.then((result: any) => {
					if (uploadManagerModal.role === 'my_files') {
						try {
							dispatch(setMyFilesContent([...myFiles].concat(result)))
						} catch (e) {
							console.error(e)
						}
					}
					setUploadedFiles((previous: any) => previous.concat(filesCopy))
					result[0].children = 0
					const validFiles: any[] = []
					setApiResponse(result)
					result.forEach((file: { error: any }) => {
						if (!file.error) {
							validFiles.push(file)
						}
					})
					if (uploadManagerModal.role === 'my_files') {
						dispatch(addLoadingCards(0))
					}
					dispatch(
						addToAnalyticsBatch({
							...validFiles,
							event: 'library.material.uploaded'
						})
					)
					const uploadedFilesForConversion: any[] = []
					const uploadedSplitFilesList: any[] = []
					validFiles.forEach((file) => {
						if (file.type.toUpperCase() === KEY) {
							if (
								typeof file.conversion_needed === 'undefined' ||
								file.conversion_needed
							) {
								uploadedFilesForConversion.push({
									...file,
									intervalHandler: null,
									folderId: categoryId,
									started: new Date()
								})
							}
						}
						if (
							splitPPTXFiles &&
							[PPT, PPTX].includes(file.type.toUpperCase())
						) {
							uploadedSplitFilesList.push({
								folderId: categoryId,
								status: file.status,
								checksum: file.checksum,
								reference: file.reference,
								keepOriginalFile: keepOriginalPPTXFile
							})
						}
						if (uploadManagerModal.role === 'editing_memosite') {
							dispatch(
								addToTempPresentation(
									{ ...file, key: nanoid() },
									PRESENTATION_BAR
								)
							)
						}
					})
					if (uploadManagerModal.role === 'editing_memosite') {
						dispatch(setMemositeBuilderValue('tempSlides', true))
					}
					if (uploadManagerModal.role === 'presentation_bar') {
						dispatch(removeAllLoadingThumbnai())
						dispatch(addToPresentation(validFiles))
					}
					if (uploadedFilesForConversion.length > 0) {
						dispatch(toggleConverting(true))
						dispatch(
							setTempValue('conversionFiles', [
								...conversionFiles,
								...uploadedFilesForConversion
							])
						)
					}
					const reduxStore: any = store.getState()
					if (uploadedSplitFilesList.length > 0) {
						const splitFilesListStore = reduxStore.temp.splitFilesList
						dispatch(
							setTempValue('splitFilesList', [
								...splitFilesListStore,
								...uploadedSplitFilesList
							])
						)
						toast(t('notifications.success.uploaded_pptx_split_started'), {
							position: toast.POSITION.BOTTOM_RIGHT,
							type: 'success',
							autoClose: 5000
						})
					}
					setUploadableFiles([])
				})
				.catch((e) => {
					console.error(e)
					dispatch(removeAllLoadingThumbnai())
				})
		}
		if (links.length > 0) {
			// eslint-disable-next-line array-callback-return
			links.map((item) => {
				const linkData = {
					title: item.link,
					name: item.title,
					type: 'url',
					checksum: md5(`${item.link} ${item.title} ${new Date().valueOf()}`),
					status: 11,
					size: 0,
					category_id: categoryId,
					tags: []
				}
				addLink(linkData, authToken).then((result: any) => {
					addTags(
						{
							files_id: result.id,
							my_folder_id: categoryId,
							type: 'file',
							_type: 'link'
						},
						authToken
					).then(() => {
						toast(t('notifications.success.link-added'), {
							position: toast.POSITION.BOTTOM_RIGHT,
							type: 'success',
							autoClose: 5000
						})
						if (uploadManagerModal.role === 'presentation_bar') {
							dispatch(removeAllLoadingThumbnai())
							dispatch(addToPresentation([result]))
						} else if (uploadManagerModal.role === 'my_files') {
							try {
								dispatch(setMyFilesContent([...myFiles].concat(result)))
							} catch (e) {
								console.error(e)
							}
						} else if (uploadManagerModal.role === 'editing_memosite') {
							dispatch(
								addToTempPresentation(
									{ ...result, key: nanoid() },
									PRESENTATION_BAR
								)
							)
							dispatch(setMemositeBuilderValue('tempSlides', true))
						}
					})
				})
			})
			setLinks([])
			handleClose()
		}
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true
	})

	const validateURL = () => {
		if (validator.isURL(link)) {
			return addLinks()
		}
		return setshowWarningURL(true)
	}

	const removeItem = (removableItem: Files) => {
		const items = uploadManagerFiles?.filter((item: any) => {
			return item.name !== removableItem.name
		})
		dispatch(setTempValue('uploadManagerFiles', items))
	}

	const closeWidgetHandler = () => {
		setUploadedFiles([])
		setUploadableFiles([])
		dispatch(setTempValue('uploadManagerFiles', []))
		setLinks([])
		handleClose()
	}

	return (
		<React.Fragment>
			{uploadManagerModal.modalType === 'modal' ? (
				<Modal
					isShowing
					modalTitle={t('titles.upload_or_add_materials')}
					hasCloseIcon
					hide={closeWidgetHandler}
					customClassName={styles.modalWrapper}
					footer={
						<React.Fragment>
							<Button
								label={t('buttons.cancel')}
								onClick={closeWidgetHandler}
								id="upload-manager-modal-cancel"
							/>
							<Button
								label={t('buttons.add')}
								onClick={uploadFunction}
								isDisabled={
									uploadManagerFiles.length === 0 && links.length === 0
								}
								id="upload-manager-modal-add"
							/>
						</React.Fragment>
					}
				>
					<div className={styles.body}>
						<div className={styles.container}>
							<div className={styles.statsColumnsWrapper}>
								<div className={styles.uploadColumn}>
									<div className={isDragActive ? styles.dragged : ''}>
										<div className={styles.rowTitle}>
											{t('titles.upload-files')}
										</div>
										<div className={styles.statsRow}>
											{t('labels.drag_and_drop_from_your_computer')}
										</div>
										<div className={styles.buttonUpload} {...getRootProps()}>
											<input {...getInputProps()} />
											<Button
												label={t('labels.browse-and-upload')}
												onClick={() => null}
												buttonClass={styles.modalButton}
												id="upload-manager-modal-browse-and-upload"
											/>
										</div>
										{uploadManagerFiles?.length > 0 && (
											<div className={styles.fileList}>
												{t('misc.added-files')}:
											</div>
										)}
										{uploadManagerFiles?.map((item: Files, index: number) => (
											<div className={styles.files} key={index}>
												<p className={styles.file}>{item.name}</p>
												<div>{formatBytes(item.size, 1)}</div>
												<Button
													icon={<ClearIcon />}
													onClick={() => removeItem(item)}
													containerClass={styles.buttonContainer}
													id="upload-manager-modal-remove-item"
												/>
											</div>
										))}
										{uploadManagerFiles?.length > 1 && (
											<div className={styles.files} key={uuidv4()}>
												<div>{t('misc.total-size')}</div>
												<div>{formatBytes(getTotal(), 0)}</div>
											</div>
										)}
									</div>
								</div>
								<div className={styles.linkColumn}>
									<div className={styles.rowTitle}>{t('misc.add-link')}</div>
									<div className={styles.explainer}>
										{t('misc.add_links_to_web_content')}
									</div>
									<div className={styles.input}>
										<Input
											type="text"
											placeholderText={t('input-placeholders.add-title')}
											initialValue={title}
											onChange={(e) => setTitle(e.target.value)}
											name="add title"
										/>
									</div>
									{showWarningURL && (
										<div className={styles.input}>
											{t('misc-please-provide-url')}
										</div>
									)}
									<div className={styles.input}>
										<Input
											type="text"
											placeholderText={t('input-placeholders.add-url')}
											initialValue={link}
											onChange={(e) => setLink(e.target.value)}
											name="add url"
										/>
									</div>
									<div className={styles.buttonUpload}>
										<Button
											label={t('misc.add-link')}
											onClick={() => validateURL()}
											buttonClass={styles.modalButton}
											id="upload-manager-modal-add-link"
										/>
									</div>
									{linkIsNotEmbeddable && (
										<div className={styles.error}>
											{t('misc.link-not-embeddable')}
										</div>
									)}
									{links.length > 0 && (
										<div className={styles.list}>{t('misc.added-links')}:</div>
									)}
									{links.map((item) => (
										<div className={styles.listLink} key={uuidv4()}>
											<div>{item.title}</div>
											<div>{item.link}</div>
										</div>
									))}
								</div>
							</div>
							{uploadManagerFiles.findIndex((uploadedFile: any) =>
								powerPointFileTypes.includes(uploadedFile.type)
							) > -1 && (
								<div className={styles.checkboxContainer}>
									<CheckBox
										onChange={() => setSplitPPTXFiles(!splitPPTXFiles)}
										name="split_pptx"
										isChecked={splitPPTXFiles}
										label={t('input-labels.split_pptx_into_slides')}
										wrapperClassName={styles.checkboxWrapper}
									/>
									{splitPPTXFiles ? (
										<CheckBox
											onChange={() =>
												setKeepOriginalPPTXFile(!keepOriginalPPTXFile)
											}
											name="keep_original_pptx"
											isChecked={keepOriginalPPTXFile}
											label={t('input-labels.keep_original_pptx_file')}
										/>
									) : (
										<p>{t('misc.upload_pptx_split_file_info')}</p>
									)}
								</div>
							)}
						</div>
					</div>
				</Modal>
			) : (
				<UploadWidget
					percentage={percentage}
					uploadedFiles={uploadedFiles}
					files={uploadableFiles}
					apiResponse={apiResponse}
					onClose={closeWidgetHandler}
				/>
			)}
		</React.Fragment>
	)
}

export default UploadManagerModal
