import { SET_FEEDS } from '../actions/actionTypes'

const initialState = {
	feed: [],
	note: []
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_FEEDS:
			return {
				...state,
				[action.payload.type.toLowerCase()]: action.payload.items
			}
		default:
			return state
	}
}

export default reducer
