import { useEffect, useRef } from 'react'

/**
 * @param {Function} update the update function
 * that updates the ref with the width and height attributes
 * @returns {object} the ref representtion of the DOM element
 */
export function useMeasurePosition(update: any) {
	// We'll use a `ref` to access the DOM element that the `motion.li` produces.
	// This will allow us to measure its height and position, which will be useful to
	// decide when a dragging element should switch places with its siblings.
	const ref: any = useRef(null)

	// Update the measured position of the item so we can calculate when we should rearrange.
	useEffect(() => {
		if (ref.current) {
			// in conditonal rendering the index might exists
			// but the reference to the dom not
			update({
				height: ref.current.offsetHeight,
				top: ref.current.offsetTop,
				width: ref.current.offsetWidth,
				left: ref.current.offsetLeft,
			})
		}
	})

	return ref
}
