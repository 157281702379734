import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { Resizable } from 're-resizable'
import { CreateNewFolderOutlined as CreateNewFolderIcon } from '@mui/icons-material'

import styles from './MyFilesNav.module.scss'

import { FolderInterface, StoreInterface } from '../../../../utils/interfaces'
import Category from '../../../common/Category'
import { t } from '../../../../utils/languages/i18n'
import { PRESENTATION_BAR } from '../../../../utils/consts'
import Spinner from '../../../common/Spinner'
import {
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading
} from '../../../../store/actions/buildContext'
import {
	clearTempPresentation,
	setTempValue
} from '../../../../store/actions/temp'
import {
	createFolderModal,
	getMyFilesByCategoryId
} from '../../../../store/actions/myfiles'
import { MY_FILES_NAV_WIDTH } from '../../conts'
import { useWindowSize } from '../../../../utils/hooks/useWindowSize'

const sidebar = {
	open: {
		x: 0,
		minWidth: MY_FILES_NAV_WIDTH,
		width: '100%',
		opacity: 1,
		transition: {
			ease: 'easeOut',
			duration: 0.2
		}
	},
	closed: {
		opacity: 0,
		x: -200,
		minWidth: 0,
		width: 0,
		transition: {
			ease: 'easeOut',
			duration: 0.2
		}
	}
}

interface MyFilesNavInterface {
	isVisible: boolean
}

const MyFilesNav = ({ isVisible }: MyFilesNavInterface) => {
	const dispatch = useDispatch()
	const windowSize = useWindowSize()

	const initialSize = {
		width: MY_FILES_NAV_WIDTH,
		height: windowSize.height || '100%'
	}

	const [isCreatingNewCategory] = React.useState(false)
	const [size, setSize] = React.useState(initialSize)

	const authUser = useSelector((store: StoreInterface) => store.authUser)
	const buildContext = useSelector(
		(store: StoreInterface) => store.buildContext
	)
	const myFilesCategories = useSelector(
		(store: StoreInterface) => store.myfiles.myFilesCategories
	)

	const { isSidebarOpened, myFilesHomepageVisible, sidebarNavsDisabled } =
		useSelector((store: StoreInterface) => store.temp)

	/**
	 *
	 * @description sets the clicked category as selected
	 * fetches the content from that category
	 * toggles a loading state
	 */
	const selectFolderHandler = (folder: FolderInterface) => {
		dispatch(toggleLoading(true))
		if (myFilesHomepageVisible) {
			dispatch(setTempValue('myFilesHomepageVisible', false))
		}
		setSelectedCategory(folder)
		dispatch(clearTempPresentation(PRESENTATION_BAR))
		dispatch(getMyFilesByCategoryId(folder.id, authUser.user.token))
			// @ts-ignore
			.then(() => {
				dispatch(toggleLoading(false))
			})
		dispatch(
			setSelectedCategory({
				...folder,
				id: folder.id,
				categoryName: folder.name
			})
		)
		dispatch(setSelectedSlides([]))
	}

	return (
		<motion.div
			animate={isVisible ? 'open' : 'closed'}
			variants={sidebar}
			initial="closed"
			className={classnames(
				styles.myFilesContainer,
				isVisible && styles.visible,
				sidebarNavsDisabled && styles.navsDisabled,
				!isSidebarOpened && styles.collapsedMenuHovered
			)}
		>
			<Resizable
				size={size}
				onResizeStop={(e, direction, ref, d) => {
					if (['left', 'right'].includes(direction)) {
						setSize({
							width: size.width + d.width,
							height: size.height
						})
					}
				}}
				className={styles.resizable}
				onResizeStart={(e, direction) => {
					if (!['left', 'right'].includes(direction)) {
						return false
					}
				}}
			>
				<ul className={classnames(styles.categoryList)}>
					{myFilesCategories.map((folder: FolderInterface) => (
						<li key={folder.id}>
							<Category
								categoryName={folder.name}
								selectCategory={() => selectFolderHandler(folder)}
								isSelected={
									buildContext.selectedCategory &&
									buildContext.selectedCategory.id === folder.id
								}
								code={folder.code}
								users={folder.users || []}
							/>
						</li>
					))}
					<li>
						{!isCreatingNewCategory ? (
							<div
								className={styles.newFolderButton}
								onClick={() => dispatch(createFolderModal(true))}
								id="sidebar-my-files-create-new-folder-button"
							>
								<CreateNewFolderIcon />
								{t('titles.create_new_folder')}
							</div>
						) : (
							<Spinner
								wrapperClassName={styles.spinnerWrapper}
								isLoading={isCreatingNewCategory}
								size={20}
							/>
						)}
					</li>
				</ul>
			</Resizable>
		</motion.div>
	)
}

export default MyFilesNav
