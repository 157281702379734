import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DeviceDetector from 'device-detector-js'

import styles from './SsoMobileConfirm.module.scss'
import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'
import Button from '../../components/common/Button'
import useQuery from '../../utils/hooks/useQuery'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface } from '../../utils/interfaces'

const SsoMobileConfirm = () => {
	const [mobileAddress, setMobileAddress] = useState('')
	const query = useQuery()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			setTempValue('currentModule', ModuleNamesInterface.SSO_MOBILE_CONFIRM)
		)
		const deviceDetector = new DeviceDetector()
		const device = deviceDetector.parse(navigator.userAgent)
		const uriPath = `login/step-3/sso?refresh_token=${query.get(
			'kmsi'
		)}&customer_id=${query.get('cuid')}&user_id=${query.get('usid')}`
		if (device?.os?.name === 'Android') {
			setMobileAddress(`me.salesfra.OfflineApp://${uriPath}`)
		} else if (device?.os?.name === 'iOS') {
			setMobileAddress(`SalesframeOffline:${uriPath}`)
		} else if (device?.os?.name === 'Windows') {
			setMobileAddress(
				`salesframe-offline://${query.get('kmsi')}/${query.get(
					'cuid'
				)}/${query.get('usid')}`
			)
		} else {
			setMobileAddress('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.pageWrapper}>
			<div className={styles.inner}>
				<div className={styles.logo}>
					<img src={salesframeLogo} alt="salesframe" />
				</div>
				<Button
					label="Back to mobile application"
					onClick={() => window.open(mobileAddress)}
					buttonClass={styles.confirmBtn}
					containerClass={styles.btnContainer}
					isPositive
					id="sso-mobile-confirm-back-to-mobile-app"
				/>
			</div>
		</div>
	)
}

export default SsoMobileConfirm
