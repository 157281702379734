import React from 'react'
import { Popover as TinyPopover, PopoverPosition } from 'react-tiny-popover'

import styles from './Popover.module.scss'

interface PopoverInterface {
	content: JSX.Element
	children: JSX.Element
	handleClose: () => {}
	isOpen: boolean
	position?: PopoverPosition
	zIndex?: string
}

const Popover = ({
	isOpen,
	handleClose,
	children,
	content,
	position,
	zIndex
}: PopoverInterface) => (
	<TinyPopover
		isOpen={isOpen}
		positions={[position || 'bottom']}
		containerStyle={{
			zIndex: zIndex || '9',
			width: '250px'
		}}
		onClickOutside={handleClose}
		align="center"
		reposition={false}
		content={<div className={styles.popoverContainer}>{content}</div>}
	>
		{children}
	</TinyPopover>
)

export default Popover
