import React, { useState, useEffect } from 'react'
import validator from 'validator'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import styles from './UserInvitation.module.scss'

import salesframeLogo from '../../assets/logos/salesframe-logo-white.svg'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import { userInvitation } from '../../api/requests/user'
import { DEFAULT } from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import Spinner from '../../components/common/Spinner'
import { setTempValue } from '../../store/actions/temp'
import { ModuleNamesInterface } from '../../utils/interfaces'

const TERMS_URL = 'https://www.salesframe.com/terms-of-service'
const PRIVACY_POLICY_URL = 'https://www.salesframe.com/privacy-policy'

const UserInvitation = () => {
	const dispatch = useDispatch()
	const { token } = useParams()
	const navigate = useNavigate()

	const [password, setPassword] = useState('')
	const [verifyPassword, setVerifyPassword] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [termsChecked, setTermsChecked] = useState(false)
	const [isLoading, toggleLoading] = useState(false)
	const [captchaToken, setCaptchaToken] = useState('')

	useEffect(() => {
		dispatch(
			setTempValue('currentModule', ModuleNamesInterface.USER_INVITATION)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {}, [captchaToken])

	/**
	 * @function handleChange toogle for privacy and terms statements
	 */
	const handleTermsChange = () => {
		setTermsChecked(!termsChecked)
	}

	/**
	 * @function  submit handles submission of signup
	 */
	const handleSubmit = () => {
		toggleLoading(true)
		const userObject: any = {
			csrfToken: captchaToken,
			invitation: token,
			firstname: firstName,
			lastname: lastName,
			password1: password,
			password2: verifyPassword
		}
		userInvitation(userObject)
			.then((response: any) => {
				navigate('/login')
			})
			.then(() => null)
			.catch((error) => {
				console.error(error)
				setCaptchaToken('')
				toggleLoading(false)
			})
	}

	/**
	 * @function handleVerify validates captcha
	 * @param captchaValidationToken
	 */
	const handleVerify = (captchaValidationToken: string) => {
		setCaptchaToken(captchaValidationToken)
	}

	return (
		<React.Fragment>
			<div className={styles.backgroundContainer} />
			<div className={styles.signupContainer}>
				<div className={styles.card}>
					<div className={styles.container}>
						<div className={styles.logo}>
							<img src={salesframeLogo} alt="salesframe" />
						</div>
						<div>
							{!captchaToken && (
								<GoogleReCaptcha
									onVerify={(captchaValidationToken) =>
										handleVerify(captchaValidationToken)
									}
								/>
							)}
							<h4 className={styles.signup}>{t('misc.welcome')}!</h4>
							<p className={styles.description}>
								{t('titles.please-fill-out-your-personal-info')}
							</p>
							<form>
								<Input
									label={null}
									onChange={(e) => setFirstName(e.target.value)}
									name="firstname"
									wrapperClassName={styles.signupInput}
									initialValue={firstName}
									state={DEFAULT.toLowerCase()}
									placeholderText={t('input-placeholders.first-name')}
								/>
								<Input
									label={null}
									onChange={(e) => setLastName(e.target.value)}
									name="lastname"
									wrapperClassName={styles.signupInput}
									initialValue={lastName}
									state={DEFAULT.toLowerCase()}
									placeholderText={t('input-placeholders.last-name')}
								/>
								<Input
									label={null}
									onChange={(e) => setPassword(e.target.value)}
									inputClassName={
										password.length > 0 && !validator.isStrongPassword(password)
											? styles.inputError
											: null
									}
									name="password"
									type="password"
									wrapperClassName={styles.signupInputPassword}
									initialValue={password}
									state={DEFAULT.toLowerCase()}
									placeholderText={t('input-placeholders.password')}
								/>
								{!validator.isStrongPassword(password) && password !== '' ? (
									<p className={styles.description}>
										{t('titles.pass-invalid')}
									</p>
								) : null}
								<Input
									label={null}
									onChange={(e) => setVerifyPassword(e.target.value)}
									inputClassName={
										password !== verifyPassword ? styles.inputError : null
									}
									name="verifypassword"
									type="password"
									wrapperClassName={styles.signupInput}
									initialValue={verifyPassword}
									state={DEFAULT.toLowerCase()}
									placeholderText={t('input-placeholders.confirm-pass')}
								/>
								{password !== verifyPassword && verifyPassword !== '' ? (
									<p className={styles.description}>{t('titles.pass-match')}</p>
								) : null}
								<div className={styles.description}>
									<input
										type="checkbox"
										className={styles.checkbox}
										checked={termsChecked}
										onChange={handleTermsChange}
									/>
									{window.location.host.includes('app-dev.memosites.io') ||
									window.location.host.includes('app.memosites.io')
										? t('misc.agree-terms-conditions')
										: t('misc.agree-terms-conditions-salesframe')}
									<a href={TERMS_URL} className={styles.links}>
										{t('misc.terms')}
									</a>
									{t('misc.and')}{' '}
									<a href={PRIVACY_POLICY_URL} className={styles.links}>
										{t('misc.privacy')}
									</a>
								</div>
								{password &&
								verifyPassword &&
								firstName &&
								lastName &&
								validator.isStrongPassword(password) &&
								password !== '' &&
								termsChecked ? (
									<Button
										label={t('buttons.done')}
										onClick={handleSubmit}
										buttonClass={styles.signupBtn}
										containerClass={styles.signupBtnContainer}
										isPositive
										id="user-invitation-submit"
									/>
								) : (
									<Button
										label={t('buttons.done')}
										onClick={() => null}
										buttonClass={styles.signupBtn}
										containerClass={styles.signupBtnContainer}
										isDisabled
										id="user-invitation-error-submit"
									/>
								)}
							</form>
						</div>
					</div>
					{isLoading && (
						<div className={styles.loader}>
							<Spinner isLoading size={44} />
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	)
}

export default UserInvitation
