import { has, isNull } from 'lodash'

import {
	cloudFnGet,
	cloudFnPost,
	cloudFnPatch,
	cloudFnDelete
} from '../../utils/requests'
import {
	acceptSharedPresentationSchema,
	declineSharedPresentationSchema,
	presentationDeckCacheSchema,
	savePresentationSchema,
	shareWithCollagueSchema,
	updatePresentationSchema,
	updatePresentationSubjectSchema
} from '../../utils/schemas/requests'
import {
	meetingsEndpoint,
	shareMeetingEndpoint,
	acceptShareMeetingEndpoint,
	declineShareMeetingEndpoint,
	getMeetingsEndpoint,
	getPresentationDeckCacheEndpoint
} from '../apiEndpoints_new'
import { SlideInterface } from '../../utils/interfaces'

export const fetchContent = (
	authToken: string,
	startFromPage: number = 0,
	presentationsPerPage: number
) =>
	new Promise((resolve, reject) => {
		cloudFnGet(
			`${getMeetingsEndpoint}/0/${startFromPage}/${presentationsPerPage}/created_at/desc`,
			{},
			{ withToken: true, authToken }
		)
			.then((result: any) => {
				const savedPresentations: any[] = []
				result.meetings.map((presentation: any) => {
					const slides = JSON.parse(presentation.extended_fields)
					if (has(slides, 'summary') && !isNull(presentation.subject)) {
						savedPresentations.push({
							...presentation,
							slides: slides.summary
						})
					}
					return true
				})
				resolve(savedPresentations)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const savePresentation = (presentationObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			meetingsEndpoint,
			presentationObject,
			{ withToken: true, authToken },
			savePresentationSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updatePresentation = (presentationObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			meetingsEndpoint,
			presentationObject,
			{ withToken: true, authToken },
			updatePresentationSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updatePresentationTitle = (
	presentationObject: any,
	authToken: any
) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			meetingsEndpoint,
			presentationObject,
			{ withToken: true, authToken },
			updatePresentationSubjectSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deletePresentation = (presentationId: any, authToken: any) =>
	new Promise((resolve, reject) =>
		cloudFnDelete(
			`${meetingsEndpoint}/${presentationId}`,
			{},
			{ withToken: true, authToken }
		)
			.then(() => {
				resolve({
					success: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	)

export const shareWithCollague = (presentationData: any, authToken: any) =>
	new Promise<{ success?: boolean }>((resolve, reject) =>
		cloudFnPost(
			shareMeetingEndpoint,
			presentationData,
			{ withToken: true, authToken },
			shareWithCollagueSchema
		)
			.then(() => {
				resolve({
					success: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	)

export const acceptSharedPresentation = (
	presentationData: any,
	authToken: any
) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			acceptShareMeetingEndpoint,
			presentationData,
			{ withToken: true, authToken },
			acceptSharedPresentationSchema
		)
			.then(() => {
				resolve({
					accepted: true
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const declineSharedPresentation = (
	presentationData: any,
	authToken: any
) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			declineShareMeetingEndpoint,
			presentationData,
			{ withToken: true, authToken },
			declineSharedPresentationSchema
		)
			.then(() => {
				resolve({
					accepted: false
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getPresentationDeckCache = () =>
	new Promise<{ slides: SlideInterface[] }>((resolve, reject) => {
		cloudFnGet(getPresentationDeckCacheEndpoint, {}, { withToken: true })
			.then((result) => {
				resolve(result as { slides: SlideInterface[] })
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updatePresentationDeckCache = (
	presentationDeck: SlideInterface[]
) =>
	new Promise<SlideInterface[]>((resolve, reject) => {
		cloudFnPost(
			getPresentationDeckCacheEndpoint,
			{ slides: presentationDeck },
			{ withToken: true },
			presentationDeckCacheSchema
		)
			.then((result) => {
				resolve(result as SlideInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
