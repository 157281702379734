import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LabelOutlined as LabelIcon } from '@mui/icons-material'

import styles from './MemositeLabelsManager.module.scss'

import Label from '../common/Label'
import { t } from '../../utils/languages/i18n'
import Labels from '../../pages/MyMemosites/components/Labels'
import CreateEditLabel from '../../pages/MyMemosites/components/CreateEditLabel'
import { setMemositeValue } from '../../store/actions/memosite'
import {
	LabelInterface,
	MeetingInterface,
	StoreInterface
} from '../../utils/interfaces'
import { getAllLabels } from '../../api/requests/labels'

interface MemositeLabelsManagerInterface {
	labelsOpened: (isOpened: boolean) => void
	memosite: MeetingInterface
	memosites: MeetingInterface[]
	role: 'my_memosites' | 'memosite_builder' | 'settings_modal'
}

const MemositeLabelsManager = ({
	labelsOpened,
	memosite,
	memosites,
	role
}: MemositeLabelsManagerInterface) => {
	const dispatch = useDispatch()

	const { labels } = useSelector((store: StoreInterface) => store.memosite)
	const { user } = useSelector((store: StoreInterface) => store.authUser)

	const [labelsVisible, toggleLabelsVisible] = React.useState(false)
	const [createLabelVisible, toggleCreateLabelVisible] = React.useState(false)
	const [editLabelVisible, toggleEditLabelVisible] =
		React.useState<null | LabelInterface>(null)

	React.useEffect(() => {
		if (labels.length === 0) {
			getAllLabels().then((response) =>
				dispatch(setMemositeValue('labels', response))
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.labels}>
			{memosite &&
				memosite.labels &&
				memosite.labels.length > 0 &&
				memosite.labels.map((label, index) => (
					<div
						onClick={() =>
							user.user.id === memosite.creator &&
							toggleLabelsVisible(!labelsVisible)
						}
						key={index}
					>
						<Label bgColor={label.color} name={label.name} />
					</div>
				))}
			{user.user.id === memosite.creator && (
				<div
					className={styles.editLabels}
					onClick={() => {
						toggleLabelsVisible(!labelsVisible)
						labelsOpened(!labelsVisible)
					}}
					id="memosite-labels-manager-edit-labels-button"
				>
					<LabelIcon /> {t('labels.edit-labels')}
				</div>
			)}
			{labelsVisible && (
				<Labels
					onClose={() => {
						toggleLabelsVisible(false)
						labelsOpened(false)
					}}
					createLabel={() => {
						toggleLabelsVisible(false)
						toggleCreateLabelVisible(true)
					}}
					labelToEdit={(labelToEdit) => {
						toggleLabelsVisible(false)
						toggleCreateLabelVisible(true)
						toggleEditLabelVisible(labelToEdit)
					}}
					role={role}
					selectedMemosite={memosite}
				/>
			)}
			{createLabelVisible && (
				<CreateEditLabel
					onClose={(deletedLabel, action) => {
						toggleLabelsVisible(true)
						toggleCreateLabelVisible(false)
						toggleEditLabelVisible(null)
						if (deletedLabel) {
							if (action === 'deleted' && memosite) {
								// remove deleted label from memosites
								const updatedmemositeLabels = memosite.labels.filter(
									(l) => l.id !== deletedLabel.id
								)
								dispatch(
									setMemositeValue('memosite', {
										...memosite,
										labels: updatedmemositeLabels
									})
								)
								// remove deleted label from other memosites (where it was added)
								const updatedMemosites: MeetingInterface[] = []
								// eslint-disable-next-line array-callback-return
								memosites.map((memo) => {
									const updatedMemo = {
										...memo,
										labels: memo.labels.filter((l) => l.id !== deletedLabel.id)
									}
									updatedMemosites.push(updatedMemo)
								})
								dispatch(setMemositeValue('memosites', updatedMemosites))
							}
						}
					}}
					existingLabel={editLabelVisible || undefined}
					updateMemositeLabels
				/>
			)}
		</div>
	)
}

export default MemositeLabelsManager
