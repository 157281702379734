import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InfoIcon from '@mui/icons-material/Info'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ReactDOM from 'react-dom'

import styles from './Tooltip.module.scss'

/**
 * @author zilahir
 * @function Tooltip
 * */

const toolTipVarians = {
	hidden: {
		x: -50,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
}

const ToolTipDiv = styled(motion.div)`
	top: ${props => props.top}px;
`

const Tooltip = ({
	infoText,
	container,
}) => {
	const [isTooltipVisible, toggleToolTipVisible] = useState(false)
	const tooltipRef = useRef(null)

	return (
		<>
			<button
				ref={tooltipRef}
				className={styles.tooltipBtn}
				onClick={() => toggleToolTipVisible(isVisible => !isVisible)}
				type="button"
			>
				<InfoIcon htmlColor="#ffffff" />
			</button>
			{
				isTooltipVisible && ReactDOM.createPortal(
					<AnimatePresence>
						<ToolTipDiv
							variants={toolTipVarians}
							initial="hidden"
							animate={
								isTooltipVisible ? 'visible' : 'hidden'
							}
							transition={{
								duration: 0.3,
							}}
							className={classnames(
								styles.tooltipContainer,
							)}
							top={tooltipRef.current ? tooltipRef.current.getBoundingClientRect().y : 0}
						>
							<p>
								{infoText}
							</p>
						</ToolTipDiv>
					</AnimatePresence>, container,
				)
			}
			{
				isTooltipVisible && ReactDOM.createPortal(
					<div
						className={styles.overlay}
						onClick={() => toggleToolTipVisible(false)}
						role="button"
						onKeyDown={null}
						tabIndex={-1}
					/>, document.body,
				)
			}
		</>
	)
}

Tooltip.defaultProps = {
	container: document.body,
}

Tooltip.propTypes = {
	container: PropTypes.objectOf(
		PropTypes.string,
	),
	infoText: PropTypes.string.isRequired,
}

export default Tooltip
