import { ViewMemositeOptions } from '../pages/MyMemosites/interfaces'

export interface UserDataInterface {
	id: number
	firstname: string
	lastname: string
	email: string
	role: string
	profile_picture: string
	usergroups_id: number
	created_at: string
	phone: string
	profile_url: string
	sso?: string
	timezone?: string
	language: string
	userGroupName: string
}

export interface UserInterface {
	token: string
	refresh_token: string
	customerName: string
	customerId: string
	user: UserDataInterface
	authSuccess: boolean
}

export interface InstanceInterface {
	name: string
	customer_id: string
	customers_id: number
	userid: number
	access?: 'password' | 'sso'
}

export interface AuthUserInterface {
	user: UserInterface
	instances: InstanceInterface[]
}

export interface UserGroupInterface {
	id: number
	name: string
	created_at: string
	updated_at: string
	parent_id: number
	users_number: number
}

export interface UserGroupsResponseInterface {
	total_users: number
	userGroups: UserGroupInterface[]
	allUsers: UserDataInterface[]
	users: UserDataInterface[]
}

export interface AddUserInterface {
	username: string
	role: string
	firstname: string
	lastname: string
	email: string
	phone: string
	password: string
	passwordAgain: string
	usergroups_id: string
}

export interface CustomerInstances {
	id: number
	name: string
}

export interface SelectedFlagInterface {
	id: number
	reason: string
	comments: string
	resolved: number
	material_id: number
	type: string
	description: string | undefined
}

export interface UserActivityBucketInterface {
	key_as_string: string
	key: number
	doc_count: number
	users: {
		doc_count_error_upper_bound: number
		sum_other_doc_count: number
		buckets: {
			key: number
			doc_count: number
		}[]
	}
}

export interface UserActivityInterface {
	statusCode: number
	body: {
		aggregations: {
			user_activity: {
				buckets: UserActivityBucketInterface[]
			}
		}
	}
}

export interface WebHookObjectInterface {
	uri: string
	method: string
}

export interface WebHookInterface {
	upload_post?: WebHookObjectInterface
	users_post?: WebHookObjectInterface
	usergroups_post?: WebHookObjectInterface
	tags_post?: WebHookObjectInterface
	account_post?: WebHookObjectInterface
	contact_post?: WebHookObjectInterface
	news_post?: WebHookObjectInterface
	formdata_post?: WebHookObjectInterface
	memositeopened_post?: WebHookObjectInterface
}

export interface ConnectedAppInterface {
	client_id: string
	redirect_uri: string
}

export interface UserRoleInterface {
	key: string
	label: string
	numberOfUsers: number
}

export interface FileTagInterface {
	folder_id: number
	tag_id: number
	name: string
}

export interface SlideInterface {
	visibility: number
	id: number
	index: number
	category_id: number
	name: string
	checksum: string
	type: string
	status: number
	updated_at: string
	created_at: string
	size: number
	version: null
	creator: null
	modifier: number
	slug: null
	external_id: null
	title: null | string | undefined
	description: null
	has_thumbnail: number
	thumbnail: null
	converted: null
	sortorder: number
	tags_id: number
	_thumbnail: number
	_type: string
	key: string
	isExpired: boolean
	business_rules: string
	tags: FileTagInterface[]
	conversion_needed?: boolean
	parent_file?: string
	analytics?: any
	for_delete?: string
	deleted?: null | number
	split_needed?: boolean
	reference?: string
	intervalHandler?: null | ReturnType<typeof setInterval>
	splitPercentage?: number
	_file?: {
		id: number
		name: string
		status: number
		type: string
		splitted: number | null
	}
	path: PathInterface[]
}

export interface PathInterface {
	id: number
	name: string
	tag_id: number
	business_rules: string
}

export interface TagInterface {
	$ref: number
	business_rules: string
	category_id: number
	children: TagInterface[]
	description: string
	filename: string
	gallery_options: string
	group_id: number
	hasChildren: boolean
	id: number
	level: number
	material_number?: number
	name: string
	parent: number
	path: PathInterface[]
	size: number
	sortorder: number
	tag_id: number
	updated_at: string
	breadcrumbs: PathInterface[]
	content_updated: string
}

export interface TagListIinterface {
	value: KeyValueInterface
	label: string
}

export interface KeyValueInterface {
	tag: number
	name: string
}

export interface CopyFilesInterface {
	tag: number
	items: CopyFilesItemsInterface[]
}

export interface CopyFilesItemsInterface {
	type: string
	id: number
	business_rules: string
	visibility: number
	name: string
}

export interface TagsReducerInterface {
	arrayOfFolders: TagListIinterface[]
	backgroundImages: string[]
	tagTree: TagInterface[]
}

export interface SelectedCategoryInterface {
	id: number
	categoryName: string
}

export interface BuildContextInterface {
	isConverting: boolean
	isMinimized: boolean
	folderPath: string[]
	selectedCategory: SelectedCategoryInterface
	selectedSlides: SlideInterface[]
	numOfLoadingCards: number
	numOfUploadingCards: number
	user: UserDataInterface
	isLoading: boolean
	selectedBuildMenuItem: string
}

export interface ContentInterface {
	content: SlideInterface[]
	starredContent: SlideInterface[]
}

export interface StoryBoardContextInterface {
	selectedTag: SlideInterface
}

export interface VideoGreetingInterface {
	filename: string
	hash: string
	greetingVideo: string
	greetingVideoLength: {
		minutes: number
		seconds: number
	}
	originalFileObject?: SlideInterface
}

export interface MemositeExtendedFieldsInterface {
	summary: SlideInterface[] | []
	videoGreeting: VideoGreetingInterface | undefined
	isXenonCreated: boolean
	expiryDate: string | boolean
	userName: string
}

interface MemositeNotesInterface {
	actions: string[]
	notes: any[]
}

export interface MemositeObjectInterface {
	created: string
	id: string
	status: number
	slug: string
	prospect_id: number
	starttime: string
	endtime: string
	subject: string
	secured_password: string
	secured: boolean
	notes: MemositeNotesInterface
	extended_fields: MemositeExtendedFieldsInterface
	greetings_draft: string
}

export interface InstanceDataInterface {
	id: string
	value: string
}

export interface SplitFileInterface {
	folderId: number
	checksum: string
	status: number
	reference: string
}

export interface TempReducerInterface {
	conversionFiles: SlideInterface[]
	currentModule: ModuleNamesInterface
	supportedMimeType: string
	adminFileToPreview: FileInterface | undefined
	reloadMemosites: boolean
	showTermsModal: boolean
	termsAccepted: {
		isNew: boolean
		value: boolean
	}
	unresolvedFlaggedContentCount: number
	instanceName: string
	libraryHomepageVisible: boolean
	showLogoutAndBackInModal: boolean
	slides: any
	fileForPreview: any
	emailBcc: string
	crmSettings: any
	automatedDeleteTime: undefined | number
	collaboraData: {
		url: string
		token: string
		uuid: string
		folderId: number
		file: {
			id: number
			name: string
			status: number
			checksum: string
			type: string
			_file?: {
				name: string
				status: number
				type: string
				checksum: string
			}
		}
		hideSaveButton?: boolean
		checkAddToPresentation?: boolean
		source: 'folder' | 'presentation_deck' | 'memosite_builder'
	}
	isSidebarOpened: boolean
	showFullScreenLoader: boolean
	splitFilesList: SplitFileInterface[]
	emptySplitSlideCards: {
		index: number
		folderId: number
		reference: string
	}[]
	salesforceOpportunityId: undefined | string
	memositeAutomatedDeleteTime: undefined | number
	showCloseModal: boolean
	stropheConnection: undefined | Strophe.Connection
	collaoraSavingFiles: any[]
	addDocumentToPresentationDeck: boolean
	uploadManagerModal: {
		isShowing: boolean
		role: 'presentation_bar' | 'my_files' | 'editing_memosite'
		modalType: 'modal' | 'widget'
	}
	generatePdfWidget: {
		visibility: boolean
		pdf: string
	}
	uploadManagerFiles: []
	myFilesHomepageVisible: boolean
	sidebarNavsDisabled: boolean
	presentationBarVisible: boolean
	dashboardDiscoverBlockVisible: boolean
}

export interface MemositeReducerInterface {
	memosites: MeetingInterface[]
	selectedMemosite: MeetingInterface | undefined
	view: ViewMemositeOptions
	labels: LabelInterface[]
	selectedLabelsToFilter: LabelInterface[]
	memositeDropdownOpened: number | undefined
	memositeAllowView: boolean
}

export interface CustomSlidesInterface {
	content: any[]
	folders: any[]
}

export interface LayoutStoreInterface {
	logoImage: {
		logoSize: string
		logoPosition: string
		logoImageUrl?: string
	}
	background: {
		backgroundColor: string
		backgroundImageOpacity: string
		backgroundImage: string
	}
	misc: any
	storyBoard: {
		storyBoardFont: {
			variants: any
		}
		storyBoardFontWeight: string
		storyBoardTitleFont: {
			variants: any
			files: any
		}
	}
	presentation: {
		presentationDeckColor: string
		presentationDeckFontColor: string
	}
}

export interface FolderInterface {
	business_rules: null | string
	children: number
	code: string
	created_at: string
	creator: number
	description: null | string
	id: number
	modifier: number
	name: string
	parent: number
	sort_order: null | number
	status: number
	type: string
	update_at: string
	users: [{ name: string }] | []
	pending: number
	invited_by: string
}

interface MyFilesInterface {
	myFilesCategories: [FolderInterface] | []
	myFiles: any
	createFolderModalVisible: boolean
}

interface MemositeBuilderReducerInterface {
	status: 'closed' | 'opened' | 'editing'
	settingsModalVisible: boolean
	settingsModalData: MeetingInterface
	publishedMemositeObject: MeetingInterface | undefined
	tempSlides: boolean
}

interface MyPresentationsReducerInterface {
	presentations: MeetingInterface[]
	selectedPresentation: MeetingInterface | undefined
	view: ViewMemositeOptions
	presentationDropdownOpened: number | undefined
	reloadPresentations: boolean
}

export interface StoreInterface {
	myfiles: MyFilesInterface
	storyboardContext: any
	fileManager: any
	buildContext: BuildContextInterface
	content: ContentInterface
	authUser: AuthUserInterface
	tags: TagsReducerInterface
	users: UserGroupsResponseInterface
	temp: TempReducerInterface
	memosite: MemositeReducerInterface
	customSlides: CustomSlidesInterface
	layout: LayoutStoreInterface
	misc: {
		thumbnailSize: 'SM' | 'LG'
		presentationBarSize: 'SM' | 'LG'
	}
	presentation: {
		loadedPresentation: any
		presentationBarSlides: any[]
		loadedPresentationDirty: boolean
	}
	memositeBuilder: MemositeBuilderReducerInterface
	myPresentations: MyPresentationsReducerInterface
}

export interface GeneratePdfRequestDataInterface {
	extended_fields: {
		summary: MemositeExtendedFieldsInterface
	}
	document: string
	userName: string
}

export interface LocationState {
	from: {
		pathname: string
	}
}

export enum RouteHelperComponentInterface {
	BACKGROUND = 'BACKGROUND',
	TOP_HEADER = 'TOP_HEADER',
	SIDE_MENU = 'SIDE_MENU',
	PRESENTATION_BAR = 'PRESENTATION_BAR',
	SLIDESHOW = 'SLIDESHOW'
}

export enum ModuleNamesInterface {
	LOGIN,
	LOGOUT,
	SIGNUP,
	FORGET_PASSWORD,
	FORGET_PASSWORD_SECOND_STEP,
	SSO_MOBILE_CONFIRM,
	USER_INVITATION,
	STORYBOARD,
	FOLLOW_UP,
	BUILD,
	USER_SETTINGS,
	LAYOUT,
	ANALYTICS,
	MOBILE_VERIFICATION_CODE,
	MY_FILES,
	STARRED,
	LIBRARY,
	MY_PRESENTATIONS
}

export enum SortEnum {
	ASC = 'asc',
	DESC = 'desc'
}

export interface FileInterface {
	id: number
	name: string
	checksum: string
	title: string | null
	created_at: string
	updated_at: string
	size: number
	type: string
	external_id: string | null
}

export interface MeetingExtendedFiledInterface {
	summary?: FileInterface[]
	isXenonCreated: boolean
	expiryDate?: boolean
	userName: string
}

export interface MeetingInterface {
	id: number
	endtime: string
	subject: string
	created_at: string
	updated_at: string
	extended_fields: string
	status: number
	memo_opened: null | string
	slug: string
	extended_fields_object?: MeetingExtendedFiledInterface
	numberOfSlides?: number
	expiry_date?: string | boolean | undefined
	notes?: string | undefined
	crm?: {
		created_at: string
		status: number
	}
	rating?: number
	is_secured: boolean
	labels: LabelInterface[]
	send_notification: number
	starttime: string
	for_delete?: string
	creator: number
	owner_id: number
}

export interface MeetingsResponseInterface {
	meetings: MeetingInterface[]
	total: number
	page?: number
}

export interface MenuItemInterface {
	name: string
	url: string
	target: string
	sort: number
}

export interface AuthUserObjectInterface {
	id: number
	name: string
	role: string
	group_id: number
	xenon: number
	timezone: boolean
}

export interface AuthUserResponseInterface {
	customerId: string
	customerName: string
	menu: MenuItemInterface[]
	length: number
	refresh_token: string
	sf_us_ki: string
	token: string
	user: AuthUserObjectInterface
	response: {
		data: {
			number_of_tries: number
		}
	}
	authSuccess?: boolean
}

export interface GetUserResponseInterface extends UserDataInterface {
	client_version: any
	creator: number
	credentials: string
	deleted_at: string
	department: null
	language: string
	manager: any
	modifier: number
	status: number
	timezone: string
	title: string
	updated_at: string
}

export interface GetConfigResponseInterface {
	id: string
	value: number | boolean | string
}

export interface InstanceInterface {
	created_at: string
	id: number
	email: string
	name: string
}

export interface InstanceCreateInterface {
	name: string
	email: string
	country: string
}

export interface FeedPropertiesInterface {
	subject: string
	description: string
	comments: string
	material_name: string
	meeting: MeetingInterface
	name: string
	request: {
		slug: string
		firstName: string
		lastName: string
		emailAddress: string
	}
	slug?: string
}

export interface FeedResponseItemInterface {
	business_rules?: any | null
	content: string
	content_new: string
	created_at: string
	creator: number
	id: number
	ingress?: any | null
	modifier: number
	presentationName: string
	properties: string | null | FeedPropertiesInterface
	public: number
	title: string | null
	type: string
	updated_at: string
	user?: any
}

export interface FeedResponseInterface {
	data: FeedResponseItemInterface[]
	total: number
}

export interface SplitFileResponse {
	status: number
	content: string
	reference: string
	percentage: number
}

export enum SortingMemositesColumnEnum {
	CREATED_AT = 'created_at',
	MEMO_OPENED = 'memo_opened'
}

export interface LabelInterface {
	id: number
	name: string
	color: string
	type: string
}

export interface InstanceResponseInterface {
	id: number
	name: string
	code: string
}

export interface MeetingCacheInterface {
	key: string
	value: string
	time: number
}

export interface PendingPresentationInterface {
	id: string
	subject: string
	invited_by: string
	notificationId: number
}

export interface SearchResultTagInterface {
	business_rules: string | null
	description: string
	material_number: number
	name: string
	path: [
		{ business_rules: string | null; name: string; tag_id: number; id: number }
	]
}
