import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'

import styles from './Gallery.module.scss'
import GalleryItem from '../../../ContentPane/GalleryItem'
import {
	convertBusinessRules,
	isTagVisible
} from '../../../../../../utils/convertBusinessRules'
import { LAYOUT, STORYBOARD } from '../../../../../../utils/consts'
import { fetchContentByTagId } from '../../../../../../api/requests/tags'
import { createSlideShow } from '../../../../../../store/actions/presentation'

const RootContainer = styled.div`
	width: ${(props) => props.galleryWidth}%;
	grid-template-columns: ${(props) => props.gridColumn};
	grid-gap: ${(props) => props.gridGap}px;
`

const Gallery = ({ tags, handleTagClick, depth, noHiddenTags, isVisible }) => {
	const storyBoardContext = useSelector(
		(store) => store.storyboardContext.tagGalleryOptions
	)
	const currentUserGroup = useSelector(
		(store) => store.authUser.user.user.userGroupId
	)
	const shortCutsList = useSelector((store) => store.tags.shortcuts)
	const authToken = useSelector((store) => store.authUser.user.token)
	const dispatch = useDispatch()
	const {
		galleryWidth,
		itemsPerRow,
		paddingBetweenItems,
		shape,
		shapeBackgroundColor,
		shapeShadow,
		textShadow
	} = storyBoardContext

	const [selected, setSelected] = useState(undefined)
	const [tagsWithoutShortcuts, setTagsWithoutShortcuts] = useState([])

	const getTagsWithoutShortcuts = () => {
		const newTags = []
		tags.forEach((item) => {
			if (!shortCutsList.some((shortcut) => shortcut.tag_id === item.tag_id)) {
				newTags.push(item)
			}
		})
		return newTags
	}

	useEffect(() => {
		setTagsWithoutShortcuts(getTagsWithoutShortcuts())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags])

	/**
	 *
	 * @description handles autoplay on the selected tag
	 * @param {object} clickedTag the object representation of the tag which has autoplay on
	 * this is a COPY of function with the same name from
	 * src/pages/StoryBoard/components/StoryBoardTagTree/components/Tag/index.tsx
	 */
	function handleAutoPlay(clickedTag) {
		// fetch content belongs to this tag
		fetchContentByTagId(clickedTag.tag_id, authToken).then((result) => {
			const onlyNotHidden = result.filter((file) => file.visibility === 1)
			dispatch(
				createSlideShow({
					slides: onlyNotHidden,
					startFrom: 0
				})
			)
		})
	}

	/**
	 *
	 *
	 * @description handles the gallery item click
	 * with the provided tagClick function,
	 * and a local helper fucntion when there's no subtags of the clicked
	 * gallery item tag
	 * @param {object} tag the object representation of the clicked tag
	 * @param {number} level the level the clicked tag placed to
	 */
	function handleGalleryItemClick(tag, level) {
		handleTagClick(tag, level)
		if (!tag.hasChildren) {
			setSelected(tag)
		}
		const businessRules = convertBusinessRules(tag.business_rules)
		businessRules.autoplay && handleAutoPlay(tag)
	}

	return (
		<RootContainer
			className={styles.galleryRootContainer}
			galleryWidth={galleryWidth}
			gridGap={paddingBetweenItems}
			gridColumn={`repeat(${
				itemsPerRow > tagsWithoutShortcuts.length
					? tagsWithoutShortcuts.length
					: itemsPerRow
			}, 1fr)`}
		>
			<AnimatePresence>
				{isVisible &&
					tagsWithoutShortcuts.map(
						(currentTag, index) =>
							(isTagVisible(
								convertBusinessRules(currentTag.business_rules),
								currentUserGroup,
								STORYBOARD
							) ||
								noHiddenTags === LAYOUT) && (
								<GalleryItem
									in={`level-${currentTag.level}`}
									index={index}
									key={currentTag.tag_id}
									isSelected={selected}
									backgroundColor={shapeBackgroundColor}
									imageUrl={
										convertBusinessRules(currentTag.business_rules)
											.folderIconImage
									}
									shape={shape}
									shapeShadow={shapeShadow}
									textShadow={textShadow}
									tag={currentTag}
									onClick={() => handleGalleryItemClick(currentTag, depth)}
									depth={depth}
									showInLayout={
										!isTagVisible(
											convertBusinessRules(currentTag.business_rules),
											currentUserGroup,
											STORYBOARD
										)
									}
								/>
							)
					)}
			</AnimatePresence>
		</RootContainer>
	)
}

Gallery.defaultProps = {
	isVisible: false,
	noHiddenTags: false,
	tags: []
}

Gallery.propTypes = {
	depth: PropTypes.number.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	isVisible: PropTypes.bool,
	noHiddenTags: PropTypes.func,
	tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
}

export default Gallery
