import React from 'react'

import { publish } from '../../utils/helpers/event'

interface MessageEventInterface {
	message_id?: string
	values?: any
}

const PostMessageHandler = () => {
	const postMessageHandler = (event: MessageEvent<MessageEventInterface>) => {
		if (event.data.message_id) {
			const values = event.data.values ? event.data.values : undefined
			publish(event.data.message_id, values)
		}
	}

	React.useEffect(() => {
		window.addEventListener('message', postMessageHandler)
		return () => {
			window.removeEventListener('message', postMessageHandler)
		}
	}, [])
	return null
}
export default PostMessageHandler
