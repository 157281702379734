export const timeZones = [
	{ label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12', int_value: '-12' },
	{ label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway', int_value: '-11' },
	{ label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu', int_value: '-10' },
	{ label: '(GMT-09:00) Alaska', value: 'US/Alaska', int_value: '-9' },
	{ label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles', int_value: '-8' },
	{ label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana', int_value: '-8' },
	{ label: '(GMT-07:00) Arizona', value: 'US/Arizona', int_value: '-7' },
	{ label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua', int_value: '-7' },
	{ label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain', int_value: '-7' },
	{ label: '(GMT-06:00) Central America', value: 'America/Managua', int_value: '-6' },
	{ label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central', int_value: '-6' },
	{ label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City', int_value: '-6' },
	{ label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan', int_value: '-6' },
	{ label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota', int_value: '-5' },
	{ label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern', int_value: '-5' },
	{ label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana', int_value: '-5' },
	{ label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic', int_value: '-12' },
	{ label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas', int_value: '-4' },
	{ label: '(GMT-04:00) Manaus', value: 'America/Manaus', int_value: '-4' },
	{ label: '(GMT-04:00) Santiago', value: 'America/Santiago', int_value: '-4' },
	{ label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland', int_value: '-3.5' },
	{ label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo', int_value: '-3' },
	{ label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires', int_value: '-3' },
	{ label: '(GMT-03:00) Greenland', value: 'America/Godthab', int_value: '-3' },
	{ label: '(GMT-03:00) Montevideo', value: 'America/Montevideo', int_value: '-3' },
	{ label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha', int_value: '-2' },
	{ label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde', int_value: '-1' },
	{ label: '(GMT-01:00) Azores', value: 'Atlantic/Azores', int_value: '-1' },
	{ label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca', int_value: '0' },
	{ label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', value: 'Etc/Greenwich', int_value: '0' },
	{ label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'Europe/Amsterdam', int_value: '1' },
	{ label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'Europe/Belgrade', int_value: '1' },
	{ label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels', int_value: '1' },
	{ label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo', int_value: '1' },
	{ label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos', int_value: '1' },
	{ label: '(GMT+02:00) Amman', value: 'Asia/Amman', int_value: '2' },
	{ label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens', int_value: '2' },
	{ label: '(GMT+02:00) Beirut', value: 'Asia/Beirut', int_value: '2' },
	{ label: '(GMT+02:00) Cairo', value: 'Africa/Cairo', int_value: '2' },
	{ label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare', int_value: '2' },
	{ label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki', int_value: '2' },
	{ label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem', int_value: '2' },
	{ label: '(GMT+02:00) Minsk', value: 'Europe/Minsk', int_value: '2' },
	{ label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek', int_value: '2' },
	{ label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait', int_value: '3' },
	{ label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow', int_value: '3' },
	{ label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi', int_value: '3' },
	{ label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi', int_value: '3' },
	{ label: '(GMT+03:30) Tehran', value: 'Asia/Tehran', int_value: '3.5' },
	{ label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat', int_value: '4' },
	{ label: '(GMT+04:00) Baku', value: 'Asia/Baku', int_value: '4' },
	{ label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan', int_value: '4' },
	{ label: '(GMT+04:30) Kabul', value: 'Asia/Kabul', int_value: '4.5' },
	{ label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg', int_value: '5' },
	{ label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi', int_value: '5' },
	{ label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Calcutta', int_value: '5.5' },
	{ label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta', int_value: '5.5' },
	{ label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu', int_value: '5.75' },
	{ label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty', int_value: '6' },
	{ label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka', int_value: '6' },
	{ label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon', int_value: '6.5' },
	{ label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok', int_value: '7' },
	{ label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk', int_value: '7' },
	{ label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong', int_value: '8' },
	{ label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur', int_value: '8' },
	{ label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk', int_value: '8' },
	{ label: '(GMT+08:00) Perth', value: 'Australia/Perth', int_value: '8' },
	{ label: '(GMT+08:00) Taipei', value: 'Asia/Taipei', int_value: '8' },
	{ label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo', int_value: '9' },
	{ label: '(GMT+09:00) Seoul', value: 'Asia/Seoul', int_value: '9' },
	{ label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk', int_value: '9' },
	{ label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide', int_value: '9.5' },
	{ label: '(GMT+09:30) Darwin', value: 'Australia/Darwin', int_value: '9.5' },
	{ label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane', int_value: '10' },
	{ label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra', int_value: '10' },
	{ label: '(GMT+10:00) Hobart', value: 'Australia/Hobart', int_value: '10' },
	{ label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam', int_value: '10' },
	{ label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok', int_value: '10' },
	{ label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan', int_value: '11' },
	{ label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland', int_value: '12' },
	{ label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji', int_value: '12' },
	{ label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu', int_value: '13' },
]
