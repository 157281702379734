import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	position: fixed;
	bottom: 32px;
	right: 32px;
	z-index: 10099;
`

export const WidgetWrapper = styled.div`
	min-height: 50px;
	background: #464a54;
	box-shadow: 3px 3px 40px #00000050;
	border-radius: 10px;
	display: flex;
	align-items: center;
	padding: 10px 20px;
	position: relative;
	cursor: pointer;
	color: #ffffff;

	p {
		max-width: 150px;
		display: inline-block;
		font: normal normal 400 13px/18px Open Sans;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	div {
		font: normal normal 400 13px/18px Open Sans;
	}

	img {
		width: 50px;
	}

	input {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	.right {
		margin-left: 50px;
		height: 100%;
	}
`

export const ProgressBar = styled.div`
	width: 300px;
	height: 6px;
	border-radius: 10px;
	background: #ffffff30;
	margin-top: 15px;
	margin-bottom: 15px;
	position: relative;

	div {
		width: ${({ percentage }) => `${percentage}%`};
		transition: width 1s;
		height: 100%;
		background-color: #11f3bd;
		border-radius: 10px;
	}
`

export const SpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;

	span {
		opacity: 0.5;
	}
`

export const FilesWrapper = styled.div`
	margin-top: 15px;
`
