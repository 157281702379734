import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import { sendDynamicsCrmSchema } from '../../utils/schemas/requests'
import {
	dynamicsOpportunitiesEndpoint,
	dynamicsUserAccountsEndpoint,
	dynamicsAddAnnotationsEndpoint
} from '../apiEndpoints_new'

export const searchUser = (accountName: string) =>
	new Promise<any>((resolve, reject) => {
		cloudFnGet(
			`${dynamicsOpportunitiesEndpoint}/0/${accountName}`,
			{},
			{},
			null
		)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const searchUserRU = (accountName: string) =>
	new Promise<any>((resolve, reject) => {
		cloudFnGet(`${dynamicsUserAccountsEndpoint}/0/${accountName}`, {}, {}, null)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendCrmDynamicsData = (data: any) =>
	new Promise<any>((resolve, reject) => {
		cloudFnPost(dynamicsAddAnnotationsEndpoint, data, {}, sendDynamicsCrmSchema)
			.then((response) => {
				resolve({
					success: true,
					response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
