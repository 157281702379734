import React from 'react'
import { useDispatch } from 'react-redux'
import { CreateNewFolderOutlined as NewFolderIcon } from '@mui/icons-material'

import styles from './CreateNewFolder.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { createFolderModal } from '../../../../store/actions/myfiles'

const CreateNewFolder = () => {
	const dispatch = useDispatch()
	return (
		<div
			className={styles.wrapper}
			onClick={() => dispatch(createFolderModal(true))}
		>
			<div className={styles.button}>
				<NewFolderIcon />
				<div>{t('titles.create_new_folder')}</div>
			</div>
		</div>
	)
}

export default CreateNewFolder
