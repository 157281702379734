import { cloudFnPut, cloudFnPost, cloudFnGet } from '../../utils/requests'
import {
	saveConfigSchema,
	patchConfigSchema
} from '../../utils/schemas/requests'
import { GetConfigResponseInterface } from '../../utils/interfaces'
import { configEndpoint } from '../apiEndpoints_new'

export const saveConfig = (key: any, value: any, isNew?: any) =>
	new Promise((resolve, reject) => {
		if (isNew) {
			cloudFnPost(configEndpoint, { key, value }, null, saveConfigSchema)
				.then((result) => {
					resolve(result)
				})
				.catch((error) => {
					reject(new Error(`There was an errro: ${error}`))
				})
		} else {
			cloudFnPut(
				`${configEndpoint}/${encodeURIComponent(key)}`,
				{ value, key },
				null,
				patchConfigSchema
			)
				.then((result) => {
					resolve(result)
				})
				.catch((error) => {
					reject(new Error(`There was an errro: ${error}`))
				})
		}
	})

export const getConfig = (key: string) =>
	new Promise<GetConfigResponseInterface>((resolve, reject) => {
		cloudFnGet(`${configEndpoint}/${encodeURIComponent(key)}`, null, null)
			.then((result) => {
				resolve(result as GetConfigResponseInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an errro: ${error}`))
			})
	})
