import Joi from 'joi'

import { REQUESTS } from '../consts'

export const requestTypeSchema = Joi.string().valid(...REQUESTS)
export const loginSchema = Joi.object({
	username: Joi.string().required(),
	password: Joi.string().required(),
	client: Joi.string(),
	token: Joi.string().required()
})

export const userUpdateSchema = Joi.object({
	id: Joi.number().required(),
	username: Joi.string().allow(null).allow(''),
	role: Joi.string().allow(null).allow(''),
	firstname: Joi.string().allow(null).allow(''),
	lastname: Joi.string().allow(null).allow(''),
	email: Joi.string().allow(null).allow(''),
	phone: Joi.allow(null).allow(''),
	password: Joi.string().allow(null).allow(''),
	passwordAgain: Joi.string().allow(null).allow(''),
	usergroups_id: Joi.number().allow(null).allow(''),
	profile_picture: Joi.string().allow(null).allow(''),
	timezone: Joi.string().allow(null).allow(''),
	profile_url: Joi.string().allow(null).allow(''),
	old_password: Joi.string().allow(null).allow(''),
	language: Joi.string().allow(null).allow('')
})

export const flagContentSchema = Joi.object({
	material_id: Joi.number().required(),
	type: Joi.string().required(),
	description: Joi.string().allow(''),
	reason: Joi.string().required()
})

export const mfAuthenticationSchema = Joi.object({
	OTP: Joi.number().required()
})

export const uploadToMyFilesSchemas = Joi.object()
export const uploadToLibrarySchemas = Joi.object()

export const createMyFilesCategorySchema = Joi.object({
	name: Joi.string().required(),
	parent: Joi.number().valid(0).required()
})

export const starAContentSchema = Joi.object({
	type: Joi.string().required(),
	material_id: Joi.number().required(),
	status: Joi.number().required()
})

export const savePresentationSchema = Joi.object({
	subject: Joi.string().required(),
	starttime: Joi.string().required(),
	endtime: Joi.string().required(),
	extended_fields: Joi.string().required(),
	status: Joi.number().valid(0).required(),
	prospect_id: Joi.number().allow(1).required()
})

export const updatePresentationSchema = Joi.object({
	id: Joi.number().required(),
	subject: Joi.string().required(),
	starttime: Joi.string().required(),
	endtime: Joi.string().required(),
	extended_fields: Joi.string().required(),
	status: Joi.number().valid(0).required(),
	prospect_id: Joi.number().allow(1).allow(null).required(),
	description: Joi.string().allow(null).allow(''),
	notes: Joi.array().allow(null),
	location: Joi.string().allow(null).allow(''),
	external_owner_id: Joi.number().allow(null),
	owner_id: Joi.number().required(),
	creator: Joi.number().required(),
	modifier: Joi.number().required(),
	updated_at: Joi.date().required(),
	created_at: Joi.date().required(),
	send_notification: Joi.bool(),
	_memo: Joi.string().allow(null).allow(''),
	slug: Joi.string()
}).unknown()

export const updateMemositeSchema = Joi.object({
	id: Joi.number().required(),
	subject: Joi.required(),
	starttime: Joi.date().required(),
	endtime: Joi.date().allow(null).allow('').required(),
	extended_fields: Joi.string().required(),
	status: Joi.number().valid(10).required(),
	prospect_id: Joi.number().allow(1).allow(null).required(),
	description: Joi.string().allow(null).allow(''),
	notes: Joi.string().allow('').allow(null),
	location: Joi.string().allow(null).allow(''),
	external_owner_id: Joi.number().allow(null),
	owner_id: Joi.number().required(),
	creator: Joi.number().required(),
	modifier: Joi.number().required(),
	updated_at: Joi.date().required(),
	created_at: Joi.date().required(),
	_memo: Joi.string().allow(null).allow(''),
	slug: Joi.string(),
	is_secured: Joi.bool()
}).unknown()

export const updatePresentationSubjectSchema = Joi.object({
	id: Joi.number().required(),
	subject: Joi.string().required()
}).unknown()

export const uploadImageSchema = Joi.object({
	path: Joi.string().required()
})

export const layoutSchema = Joi.string()

export const folderOptionsSchema = Joi.object({
	business_rules: Joi.string()
})

export const galleryOptionsSchema = Joi.object({
	options: Joi.string()
})

export const createMemositeSchema = Joi.string()

export const renameMyFileCategorySchema = Joi.object({
	id: Joi.number().required(),
	name: Joi.string().required()
})

export const analyticsSchema = Joi.array().items(
	Joi.object()
		.keys({
			event: Joi.string().required(),
			event_timestamp: Joi.string().required()
		})
		.unknown(true)
)

export const shareWithCollagueSchema = Joi.object({
	id: Joi.number().required(),
	users: Joi.array().items(Joi.number()).required(),
	message: Joi.string().allow('')
})

export const addLinkInputSchema = Joi.object({
	title: Joi.string().required(),
	link: Joi.string().uri().required()
})

export const addLinkSchema = Joi.any()

export const MyFilesAddTags = Joi.object({
	files_id: Joi.number().required(),
	my_folder_id: Joi.number().required(),
	type: Joi.string().required(),
	_type: Joi.string().required()
})

export const updateContentSchema = Joi.object({
	tags_id: Joi.number().required(),
	items: Joi.array()
})

export const embedUrlSchema = Joi.object({
	embedUrl: Joi.string().uri().allow(null).allow('')
})

export const converterSchema = Joi.object({
	id: Joi.number().required(),
	checksum: Joi.string().required(),
	categoryId: Joi.number().required()
})

export const converterForRestoreSchema = Joi.object({
	file_id: Joi.number().required(),
	tag_id: Joi.number().required()
})

export const saveConfigSchema = Joi.object({
	key: Joi.string().required(),
	value: Joi.required()
})

export const patchConfigSchema = Joi.object({
	value: Joi.required(),
	key: Joi.string().required()
})

export const acceptSharedPresentationSchema = Joi.object({
	presentationId: Joi.string().required(),
	messageId: Joi.string().required()
})

export const declineSharedPresentationSchema = Joi.object({
	messageId: Joi.string().required()
})

export const saveCrmSchema = Joi.object()

export const saveFeedSchema = Joi.object({
	type: Joi.alternatives(Joi.string(), Joi.number()).required(),
	public: Joi.bool(),
	content: Joi.string()
})

export const shareCategorySchema = Joi.object({
	folder_id: Joi.number().required(),
	users: Joi.array(),
	groups: Joi.array()
})

export const acceptSharedFolderInvitationSchema = Joi.object({
	my_folder_id: Joi.number(),
	user_id: Joi.number(),
	decline: Joi.bool()
})

export const updateFilename = Joi.object({
	id: Joi.number().required(),
	category_id: Joi.number().required(),
	checksum: Joi.string().required(),
	children: Joi.bool().allow(1).allow(0),
	content: Joi.allow(null).allow(''),
	created_at: Joi.string(),
	creator: Joi.number().allow(null),
	description: Joi.string().allow(null).allow(''),
	files_id: Joi.number().allow(null),
	key: Joi.string(),
	modifier: Joi.number().allow(null).allow(''),
	name: Joi.string(),
	page_order: Joi.string().allow(null).allow(''),
	pagenumber: Joi.number().allow(null).allow(''),
	parent_file: Joi.string(),
	size: Joi.number(),
	slug: Joi.string().allow(null).allow(''),
	sortorder: Joi.number(),
	starred: Joi.bool().allow(null),
	status: Joi.number(),
	tags: Joi.array(),
	tags_id: Joi.number(),
	thumbnail: Joi.string().allow(null).allow(''),
	title: Joi.string().allow(null).allow(''),
	type: Joi.string(),
	updated_at: Joi.string(),
	_file: Joi.object(),
	_thumbnail: Joi.string().allow(null).allow(''),
	_type: Joi.string(),
	version: Joi.allow(null),
	external_id: Joi.allow(null),
	has_thumbnail: Joi.allow(null),
	converted: Joi.allow(null)
})

export const changeMemositeCodeSchema = Joi.object({
	meeting_id: Joi.number().required(),
	code: Joi.string().required()
})

export const searchOppFonectaSchema = Joi.object({
	token: Joi.string().allow(null).allow(''),
	search: Joi.string().allow(null).allow('')
})

export const searchContactsEvacSchema = Joi.object({
	token: Joi.string().allow(null).allow(''),
	search: Joi.string().allow(null).allow('')
})
export const searchUserEthypharm = Joi.object({
	nameFirstname: Joi.string().allow(null).allow(''),
	cityStreet: Joi.string().allow(null).allow(''),
	resultSize: Joi.number().allow(null).allow('')
})
export const searchMoreUserSchema = Joi.object({
	requestId: Joi.string().allow(null).allow(''),
	startpos: Joi.number().allow(null).allow('')
})
export const sendEthypharmCrmSchema = Joi.object()

export const sendDynamicsCrmSchema = Joi.object()

export const sendOppFonectaSchema = Joi.object()

export const sendCrmEvacSchema = Joi.object()

export const sendCrmAavaSchema = Joi.object({
	content: Joi.string().required(),
	opportunityId: Joi.string().required(),
	title: Joi.string().required(),
	user_id: Joi.number().required(),
	meeting_id: Joi.number().required()
})

export const forgotPasswordSchema = Joi.object({
	phase: Joi.string().allow(null).allow(''),
	email: Joi.string().allow(null).allow(''),
	captcha: Joi.string(),
	token: Joi.string(),
	credentials: Joi.object({
		password1: Joi.string().allow(null).allow('').required(),
		password2: Joi.string().allow(null).allow('').required()
	})
})

export const userInvitationSchema = Joi.object({
	csrfToken: Joi.string().allow(null).allow('').required(),
	firstname: Joi.string().allow(null).allow('').required(),
	lastname: Joi.string().allow(null).allow('').required(),
	password1: Joi.string().allow(null).allow('').required(),
	password2: Joi.string().allow(null).allow('').required(),
	invitation: Joi.string().allow(null).allow('').required()
})

export const mfaVerifySchema = Joi.object({
	GUID: Joi.string().required(),
	UID: Joi.number().required(),
	signature: Joi.string().required(),
	code: Joi.string().required()
})

export const addSurveyObject = Joi.object({
	name: Joi.string(),
	checksum: Joi.string(),
	template_id: Joi.number(),
	company_id: Joi.string(),
	industry: Joi.string(),
	companySize: Joi.string()
})

export const updateSurveyCustomerSchema = Joi.object({
	id: Joi.string(),
	name: Joi.string(),
	company_id: Joi.string(),
	industry: Joi.string(),
	companySize: Joi.string()
})

export const createPdfThumbnailSchema = Joi.object({
	checksum: Joi.string().required()
})

export const createPdfSchema = Joi.object({
	extended_fields: {
		summary: Joi.array().required()
	},
	document: Joi.string().required(),
	userName: Joi.string().required()
})

export const labelSchema = Joi.object({
	name: Joi.string().required(),
	type: Joi.string().required(),
	color: Joi.string().required()
})

export const labelToMeetingSchema = Joi.object({
	meeting_id: Joi.number().required(),
	label_id: Joi.number().required()
})

export const deleteLabelSchema = Joi.object({
	id: Joi.number().required()
})

export const resetContentDeletionSchema = Joi.object({
	id: Joi.number().required(),
	type: Joi.string().required()
})

export const splitFileSchema = Joi.object({
	id: Joi.number().required(),
	status: Joi.number().required(),
	tags_id: Joi.alternatives(Joi.string(), Joi.number()).required(),
	checksum: Joi.string().required(),
	name: Joi.string().required()
})

export const resetMemositeDeletionSchema = Joi.object()

export const getMemositePreviewObjectSchema = Joi.object()

export const labelToMeetingBulkSchema = Joi.object({
	meeting_id: Joi.number().required(),
	labels: Joi.array().required()
})

export const presentationDeckCacheSchema = Joi.object({
	slides: Joi.array().required()
})

export const saveMeetingCacheSchema = Joi.object({
	key: Joi.string().required(),
	value: Joi.string().required(),
	time: Joi.number().required()
})
