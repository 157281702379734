import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import hexToRgba from 'hex-to-rgba'

import { useMeasurePosition } from '../../../../../../utils/hooks/useMeasurePosition'
import { FIXED_TAGLIST_TYPES, TAGLIST_BREADCRUMBS } from '../../../../../../utils/consts'

const Label = styled(motion.li)`
	color: ${props => props.storyboardFontColor};
	font-family: ${props => props.fontFamily};
`

const Tag = ({
	index,
	tag,
	handleTagClick,
	updatePosition,
	updateOrder,
	handleDragEnd,
	type,
	className,
	isDraggingEnabled,
	noHiddenTags,
}) => {
	const { layout } = useSelector(state => state)
	const ref = useMeasurePosition(pos => updatePosition(index, pos))
	const [isDragging, setDragging] = useState(false)

	const tagItemAnimationVarians = {
		visible: i => ({
			opacity: !noHiddenTags ? 1 : 0.5,
			y: 0,
			transition: {
				y: { stiffness: 1000, velocity: -100 },
				delay: i * 0.1,
			},
		}),
		hidden: {
			opacity: 0,
			y: 20,
			transation: {
				y: { stiffness: 1000 },
			},
		},
		exit: i => ({
			opacity: 0,
			y: 20,
			transition: {
				y: { stiffness: 1000, velocity: -100 },
				delay: i * 0.1,
			},
		}),
	}


	/**
	 *
	 * @description sets the dragging to false
	 * calls the handleDragEnd callback if
	 * it's a function
	 */
	function endDragging() {
		setDragging(false)
		if (typeof handleDragEnd === 'function') {
			handleDragEnd()
		}
	}
	return (
		<Label
			variants={tagItemAnimationVarians}
			className={className}
			fontFamily={
				layout.storyBoard.storyBoardFont.family
			}
			storyboardFontColor={
				hexToRgba(layout.storyBoard.storyBoardFontColor, 1)
			}
			ref={ref}
			layout
			animate="visible"
			custom={index}
			exit="exit"
			initial="hidden"
			drag={isDraggingEnabled ? 'x' : false}
			onDragStart={() => setDragging(true)}
			onDragEnd={() => endDragging(false)}
			onViewportBoxUpdate={(_viewportBox, delta) => {
				isDragging && updateOrder(index, delta.x.translate)
			}}
			role="button"
			onClick={handleTagClick}
		>
			{tag.name}
			{
				type === TAGLIST_BREADCRUMBS && (
					<ArrowForwardIosIcon
						style={{
							width: '0.8vw',
							height: '0.8vw',
						}}
						htmlColor={
							hexToRgba(layout.storyBoard.storyBoardFontColor, 1)
						}
					/>
				)
			}
		</Label>
	)
}

Tag.defaultProps = {
	className: undefined,
	isDraggingEnabled: false,
	noHiddenTags: true,
	type: TAGLIST_BREADCRUMBS,
}

Tag.propTypes = {
	className: PropTypes.string,
	handleDragEnd: PropTypes.func.isRequired,
	handleTagClick: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	isDraggingEnabled: PropTypes.bool,
	noHiddenTags: PropTypes.bool,
	tag: PropTypes.shape({
		name: PropTypes.string,
	}).isRequired,
	type: PropTypes.oneOf([
		...FIXED_TAGLIST_TYPES,
	]),
	updateOrder: PropTypes.func.isRequired,
	updatePosition: PropTypes.func.isRequired,
}

export default Tag
