const isValidJSON = (json: string) => {
	try {
		JSON.parse(json)
	} catch {
		return false
	}
	return true
}

export default isValidJSON
