import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'
import { useNavigate } from 'react-router-dom'
import { differenceInSeconds, format, subDays } from 'date-fns'
import Parser from 'html-react-parser'
import _, { debounce, flatMapDeep } from 'lodash'
import { utcToZonedTime } from 'date-fns-tz'
import {
	MessageOutlined as FeedIcon,
	Search as SearchIcon,
	WorkOutline as PresentationsIcon,
	ArrowForward as ArrowForwardIcon,
	Link as SharedLinksIcon,
	Close as CloseIcon,
	NewReleasesOutlined as FeaturedFoldersIcon,
	Info as InfoIcon
} from '@mui/icons-material'

import styles from './DashboardNew.module.scss'

import { t } from '../../utils/languages/i18n'
import Search from '../../components/Search'
import {
	FeedResponseInterface,
	FeedResponseItemInterface,
	MeetingInterface,
	ModuleNamesInterface,
	SlideInterface,
	StoreInterface,
	TagInterface
} from '../../utils/interfaces'
import { sfApiRoot } from '../../api/apiEndpoints_new'
import {
	ADMIN,
	BUILD,
	BUTTON_NORMAL,
	BUTTON_TEXT,
	FOLLOWUP,
	ICON_LEFT,
	ICON_RIGHT,
	LIBRARY,
	MY_PRESENTATIONS,
	PRESENTATION_BAR
} from '../../utils/consts'
import Button from '../../components/common/Button'
import PresentationCard from './components/PresentationCard'
import { filterMemosites } from '../../api/requests/memosite'
import MemositeCard from './components/MemositeCard'
import Spinner from '../../components/common/Spinner'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import { saveConfig } from '../../api/requests/config'
import {
	addToTempPresentation,
	clearTempPresentation,
	setTempValue
} from '../../store/actions/temp'
import { deleteFeed, getFeeds } from '../../api/requests/feeds'
import SendMessageModal from './components/SendMessageModal'
import FeedItem from './components/FeedItem'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import {
	setFolderPath,
	setSelectedCategory
} from '../../store/actions/buildContext'
import { getFeaturedFolders } from '../../api/requests/featuredFolders'
import FeaturedFolderCard from './components/FeaturedFolderCard/FeaturedFolderCard'
import Folder from '../../components/FileBrowser/components/Folder'
import Embedded from '../../components/FileBrowser/components/EmbeddedModal'
import { getPopularFiles } from '../../api/requests/analytics'
import Card from '../../components/Card'
import { getMeetingsCache, saveMeetingsCache } from '../../api/requests/cache'
import { useWindowSize } from '../../utils/hooks/useWindowSize'
import { isTagVisibleNew } from '../../utils/convertBusinessRules'
import ConfirmDeleteModal from '../../components/common/ConfirmDeleteModal'

let embeddedContentOpenedTime: Date | undefined

const DashboardNew = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const windowSize = useWindowSize()
	const featuredFoldersElementRef = React.useRef<HTMLDivElement>(null)
	const contentWrapperElementRef = React.useRef<HTMLDivElement>(null)

	const [showSearch, toggleShowSearch] = React.useState(false)
	const [presentations, setPresentations] = React.useState<MeetingInterface[]>(
		[]
	)
	const [memosites, setMemosites] = React.useState<MeetingInterface[]>([])
	const [loadingPresentations, toggleLoadingPresentations] =
		React.useState(true)
	const [loadingMemosites, toggleLoadingMemosites] = React.useState(true)
	const [feedVisible, toggleFeedVisible] = React.useState(false)
	const [feedTabSelected, setFeedTabSelected] = React.useState<
		'all' | 'notifications' | 'messages'
	>('all')
	const [notifications, setNotifications] = React.useState<any[]>([])
	const [messages, setMessages] = React.useState<any[]>([])
	const [loadingFeed, toggleLoadingFeed] = React.useState(false)
	const [sendMessageModalVisible, toggleSendMessageModal] =
		React.useState(false)
	const [featuredFolders, setFeaturedFolders] = React.useState<TagInterface[]>(
		[]
	)
	const [recentlyUpdatedFolders, setRecentlyUpdatedFolders] = React.useState<
		TagInterface[]
	>([])
	const [selectedTagToEmbed, setSelectedTagToEmbed] =
		React.useState<any>(undefined)
	const [mostPopularFiles, setMostPopularFiles] = React.useState<
		SlideInterface[]
	>([])
	const [idsOfUnreadMessages, setIdsOfUnreadMessages] = React.useState<
		number[]
	>([])
	const [idsOfUnreadNotifications, setIdsOfUnreadNotifications] =
		React.useState<number[]>([])
	const [messagesPopupVisible, toggleMessagesPopup] = React.useState(false)
	const [notificationsPopupVisible, toggleNotificationsPopup] =
		React.useState(false)
	const [featuredFoldersCentered, toggleFeaturedFoldersCentered] =
		React.useState(false)
	const [isSmallScreen, toggleSmallScreen] = React.useState(false)
	const [isContainerScrollable, toggleContainerScrollable] =
		React.useState(false)
	const [deleteFeedMessageId, setDeleteFeedMessageId] = React.useState<
		null | number
	>(null)
	const [disableDeleteFeedButton, setDisableDeleteFeedButton] =
		React.useState(false)

	const { selectedBuildMenuItem } = useSelector(
		(store: StoreInterface) => store.buildContext
	)
	const { logoImage } = useSelector((store: StoreInterface) => store.layout)
	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const {
		isSidebarOpened,
		dashboardDiscoverBlockVisible,
		slides,
		presentationBarVisible
	} = useSelector((store: StoreInterface) => store.temp)
	const tagTree = useSelector((state: StoreInterface) => state.tags.tagTree)
	const memositeBuilderStatus = useSelector(
		(state: StoreInterface) => state.memositeBuilder.status
	)

	/**
	 * @description check if the featured folders should be centered
	 */
	React.useEffect(() => {
		if (!featuredFoldersElementRef.current) return
		const resizeObserver = new ResizeObserver(
			debounce(() => {
				const featuredContainerWidth =
					featuredFoldersElementRef?.current?.clientWidth || 0
				const containerWidth =
					contentWrapperElementRef?.current?.clientWidth || 0
				if (featuredFolders.length * 220 < featuredContainerWidth) {
					toggleFeaturedFoldersCentered(true)
				} else {
					toggleFeaturedFoldersCentered(false)
				}
				toggleSmallScreen(725 > containerWidth)
			})
		)
		resizeObserver.observe(featuredFoldersElementRef?.current)
		return () => resizeObserver.disconnect() // clean up
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowSize, featuredFolders])

	/**
	 * @description check if the content wrapper is scrollable
	 */
	React.useEffect(() => {
		if (!contentWrapperElementRef.current) return
		const resizeObserver = new ResizeObserver(
			debounce(() => {
				const containerHeight =
					contentWrapperElementRef?.current?.scrollHeight || 0
				toggleContainerScrollable(window.innerHeight < containerHeight + 110)
			})
		)
		resizeObserver.observe(contentWrapperElementRef?.current)
		return () => resizeObserver.disconnect() // clean up
	}, [windowSize, featuredFolders, recentlyUpdatedFolders, mostPopularFiles])

	const fetchPresentations = () => {
		filterMemosites(
			'1970-01-01%2000:00:00',
			`${format(new Date(), 'yyyy-MM-dd')} 23:59:59`,
			0,
			0,
			0,
			8,
			0,
			null,
			0,
			'updated_at',
			'desc',
			0
		).then((response) => {
			toggleLoadingPresentations(false)
			const presentationsList: MeetingInterface[] = response.meetings.map(
				(item: MeetingInterface) => ({
					...item,
					numberOfSlides:
						JSON.parse(item.extended_fields)?.summary?.length || 0,
					extended_fields_object: JSON.parse(item.extended_fields)
				})
			)
			setPresentations(presentationsList)
		})
	}

	const fetchMemosites = () => {
		filterMemosites(
			'1970-01-01%2000:00:00',
			`${format(new Date(), 'yyyy-MM-dd')} 23:59:59`,
			1,
			0,
			0,
			4,
			0,
			null,
			10,
			'memo_opened',
			'desc',
			0
		).then((response) => {
			toggleLoadingMemosites(false)
			const memositesList: MeetingInterface[] = response.meetings.map(
				(item: MeetingInterface) => ({
					...item,
					numberOfSlides:
						JSON.parse(item.extended_fields)?.summary?.length || 0,
					extended_fields_object: JSON.parse(item.extended_fields)
				})
			)
			setMemosites(memositesList)
		})
	}

	const collectUnreadMessages = (
		messagesParam: FeedResponseItemInterface[],
		dateParam: string
	) => {
		const ids: number[] = []
		messagesParam.forEach((item) => {
			if (
				utcToZonedTime(new Date(item.created_at), 'UTC').getTime() >
				new Date(dateParam).getTime()
			) {
				ids.push(item.id)
			}
		})
		return ids
	}

	const fetchNotifications = (date?: string) => {
		toggleLoadingFeed(true)
		getFeeds(notifications.length, 'note', date).then(
			(response: FeedResponseInterface) => {
				toggleLoadingFeed(false)
				setNotifications(
					notifications.length > 0
						? notifications.concat(response.data)
						: response.data
				)
				if (date) {
					const ids = collectUnreadMessages(response.data, date)
					if (ids.length > 0) {
						setIdsOfUnreadNotifications(ids)
						toggleNotificationsPopup(true)
					}
				}
			}
		)
	}

	const fetchMessages = (date?: string, isRefresh?: boolean) => {
		toggleLoadingFeed(true)
		getFeeds(isRefresh ? 0 : messages.length, 'feed', date).then(
			(response: FeedResponseInterface) => {
				toggleLoadingFeed(false)
				if (isRefresh) {
					setMessages(response.data)
				} else {
					setMessages(
						messages.length > 0 ? messages.concat(response.data) : response.data
					)
				}
				if (date) {
					const ids = collectUnreadMessages(response.data, date)
					if (ids.length > 0) {
						setIdsOfUnreadMessages(ids)
						toggleMessagesPopup(true)
					}
				}
			}
		)
	}

	const fetchFeaturedFolders = () => {
		getFeaturedFolders().then((res) => {
			setFeaturedFolders(res)
		})
	}

	/**
	 * @description load more notifications and/or feeds
	 * @param {string} typeParam can be "note" or "feed"
	 */
	const loadMore = (typeParam: string) => {
		if (typeParam === 'feed') {
			fetchMessages()
		} else {
			fetchNotifications()
		}
	}

	const sortRecentUpdatedFolders = () => {
		// sort folders by updated_at
		const flatten: any = (item: any) => [
			item,
			flatMapDeep(item.children, flatten)
		]
		const flatTagTree: TagInterface[] = flatMapDeep(tagTree, flatten)
		const sortedFolders = flatTagTree.sort(
			(a: TagInterface, b: TagInterface) =>
				new Date(b.content_updated).getTime() -
				new Date(a.content_updated).getTime()
		)
		setRecentlyUpdatedFolders(sortedFolders.slice(0, 20))
	}

	const fetchMostPopularFiles = () => {
		getPopularFiles(
			`${format(subDays(new Date(), 30), 'yyyy-MM-dd')}T00:00:00`,
			`${format(new Date(), 'yyyy-MM-dd')}T23:59:59`
		).then((response) => {
			setMostPopularFiles(response.popular)
		})
	}

	const fetchLastOpenedFeedDate = () => {
		getMeetingsCache('lastOpenedFeedDate').then((response) => {
			if (response) {
				fetchNotifications(response)
				fetchMessages(response)
			} else {
				fetchNotifications()
				fetchMessages()
				// })
			}
		})
	}

	React.useEffect(() => {
		if (tagTree.length > 0) {
			sortRecentUpdatedFolders()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagTree])

	React.useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.BUILD))
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.Build`
			})
		)
		fetchPresentations()
		fetchMemosites()
		fetchLastOpenedFeedDate()
		fetchFeaturedFolders()
		fetchMostPopularFiles()
		return () => {
			dispatch(clearTempPresentation(PRESENTATION_BAR))
			dispatch(
				setSelectedCategory({
					id: null,
					categoryName: ''
				})
			)
			dispatch(setFolderPath([]))
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Build`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getLoadMoreButton = () => {
		if (feedTabSelected === 'notifications') {
			return (
				<Button
					type={BUTTON_TEXT}
					label={t('labels.load-more')}
					onClick={() => loadMore('note')}
					buttonClass={styles.loadMoreBtn}
					containerClass={styles.loadMoreBtnContainer}
					id="dashboard-load-more-notifications-button"
				/>
			)
		}
		if (feedTabSelected === 'messages') {
			return (
				<Button
					type={BUTTON_TEXT}
					label={t('labels.load-more')}
					onClick={() => loadMore('feed')}
					buttonClass={styles.loadMoreBtn}
					containerClass={styles.loadMoreBtnContainer}
					id="dashboard-load-more-messages-button"
				/>
			)
		} else {
			return (
				<Button
					type={BUTTON_TEXT}
					label={t('labels.load-more')}
					onClick={() => {
						loadMore('feed')
						loadMore('note')
					}}
					buttonClass={styles.loadMoreBtn}
					containerClass={styles.loadMoreBtnContainer}
					id="dashboard-load-more-messages-and-notifications-button"
				/>
			)
		}
	}

	/**
	 *
	 * @description when user clicks on folder name in breadcrumbs
	 * it will take him to that folder and fetch the content
	 * @param {object} tag object representation of the selected tag
	 */
	const goToFolder = (tag: TagInterface) => {
		navigate(`/${LIBRARY.toLowerCase()}/${tag.tag_id}`)
	}

	/**
	 *
	 * @description sets the state to selected tag and openedTime constant to
	 * current Date value
	 * @param {object} tag object representation of the selected tag
	 */
	const embeddedUrlHandler = (tag: any) => {
		setSelectedTagToEmbed(tag)
		embeddedContentOpenedTime = new Date()
	}

	const decideEmbedOrOpenInNew = () => {
		if (selectedTagToEmbed && selectedTagToEmbed.target) {
			window.open(selectedTagToEmbed.embeddedUrl, '_blank')
			setSelectedTagToEmbed(undefined)
		} else {
			return (
				<Embedded
					isShowing={!!selectedTagToEmbed}
					closeModal={closeEmbedModalHandler}
					embeddedUrl={selectedTagToEmbed ? selectedTagToEmbed.embeddedUrl : ''}
				/>
			)
		}
	}

	/**
	 *
	 * @description checks for how long the embedded url was opened, sends the
	 * analytics and then sets values back to previous state
	 */
	const closeEmbedModalHandler = () => {
		if (embeddedContentOpenedTime) {
			const time = differenceInSeconds(new Date(), embeddedContentOpenedTime)
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.storyboard.external.opened.time',
					tag_id: selectedTagToEmbed.id,
					name: selectedTagToEmbed.name,
					time
				})
			)
		}
		setSelectedTagToEmbed(undefined)
		embeddedContentOpenedTime = undefined
	}

	const resetFeedHandler = () => {
		toggleFeedVisible(false)
		setIdsOfUnreadMessages([])
		setIdsOfUnreadNotifications([])
		// notification cache validity is set to 30 days
		saveMeetingsCache({
			key: 'lastOpenedFeedDate',
			value: format(utcToZonedTime(new Date(), 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
			time: 60 * 60 * 24 * 30
		})
	}

	const removeFeedItem = () => {
		if (deleteFeedMessageId) {
			setDisableDeleteFeedButton(true)
			deleteFeed(deleteFeedMessageId)
				.then(() => {
					const newFeeds = messages.filter(
						(item) => item.id !== deleteFeedMessageId
					)
					setMessages(newFeeds)
					setDisableDeleteFeedButton(false)
					setDeleteFeedMessageId(null)
				})
				.catch(() => {
					setDeleteFeedMessageId(null)
					setDisableDeleteFeedButton(false)
				})
		}
	}

	return (
		<div
			className={c(
				styles.pageWrapper,
				!isSidebarOpened && styles.sidebarClosed
			)}
		>
			<div
				className={c(
					styles.contentWrapper,
					(presentationBarVisible || memositeBuilderStatus !== 'closed') &&
						styles.deckOpened,
					isContainerScrollable && styles.scrollable
				)}
				ref={contentWrapperElementRef}
			>
				<div className={styles.searchRow}>
					{logoImage?.logoImageUrl && (
						<div className={styles.logo}>
							<img
								src={`${sfApiRoot}/files/assets/files/${
									logoImage?.logoImageUrl
								}?oauth=${encodeURIComponent(authUser.token)}`}
								alt="logo"
								className={styles.logoImage}
							/>
						</div>
					)}
					<div
						className={styles.searchButton}
						onClick={() => toggleShowSearch(true)}
						id="dashboard-search-button"
					>
						<div>
							<SearchIcon />
							<div>{t('labels.search_for_content')}</div>
						</div>
					</div>
					<Button
						containerClass={styles.featuredBtnWrapper}
						buttonClass={styles.featuredBtnText}
						type={BUTTON_TEXT}
						iconSide={ICON_LEFT}
						icon={<InfoIcon />}
						onClick={() => null}
						id="dashboard-featured-guide-button"
						tooltip={t('tooltips.quick_guide_for', [t('labels.dashboard')])}
					/>
					<div
						className={styles.feedButton}
						onClick={() =>
							feedVisible ? resetFeedHandler() : toggleFeedVisible(true)
						}
					>
						{(idsOfUnreadMessages.length > 0 ||
							idsOfUnreadNotifications.length > 0) && (
							<div className={styles.indicator}>
								{`${
									idsOfUnreadMessages.length + idsOfUnreadNotifications.length
								} ${t('labels.new')}`}
							</div>
						)}
						<Button
							type={BUTTON_TEXT}
							label={t('labels.feed')}
							containerClass={styles.container}
							buttonClass={styles.btn}
							iconClass={styles.icon}
							iconSide={ICON_LEFT}
							icon={<FeedIcon />}
							onClick={() =>
								feedVisible ? resetFeedHandler() : toggleFeedVisible(true)
							}
							tooltip={t('labels.feed')}
							id="dashboard-feed-button"
						/>
						{(idsOfUnreadMessages.length > 0 ||
							idsOfUnreadNotifications.length > 0) && (
							<div className={styles.popupWrapper}>
								{messagesPopupVisible && (
									<div className={c(styles.popup)}>
										{t('misc.you_have_x_new_messages_in_your_feed', [
											idsOfUnreadMessages.length.toString()
										])}
										<CloseIcon onClick={() => toggleMessagesPopup(false)} />
									</div>
								)}
								{notificationsPopupVisible && (
									<div className={c(styles.popup)}>
										{t('misc.you_have_x_new_notifications_in_your_feed', [
											idsOfUnreadNotifications.length.toString()
										])}
										<CloseIcon
											onClick={() => toggleNotificationsPopup(false)}
										/>
									</div>
								)}
							</div>
						)}
					</div>
					{feedVisible && (
						<div className={styles.feedWrapper}>
							<div className={styles.nav}>
								<div className={styles.tabs}>
									<Button
										label={t('labels.all')}
										type={BUTTON_TEXT}
										buttonClass={c(
											styles.tabBtn,
											feedTabSelected === 'all' && styles.active
										)}
										onClick={() => setFeedTabSelected('all')}
										id="dashboard-feed-tab-all-button"
									/>
									<Button
										label={t('labels.notifications')}
										type={BUTTON_TEXT}
										buttonClass={c(
											styles.tabBtn,
											feedTabSelected === 'notifications' && styles.active
										)}
										onClick={() => setFeedTabSelected('notifications')}
										id="dashboard-feed-tab-notifications-button"
									/>
									<Button
										label={t('notifications.messages')}
										type={BUTTON_TEXT}
										buttonClass={c(
											styles.tabBtn,
											feedTabSelected === 'messages' && styles.active
										)}
										onClick={() => setFeedTabSelected('messages')}
										id="dashboard-feed-tab-messages-button"
									/>
								</div>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.closeBtn}
									iconClass={styles.icon}
									iconSide={ICON_LEFT}
									icon={<CloseIcon />}
									onClick={resetFeedHandler}
									id="dashboard-feed-close-button"
								/>
							</div>
							{authUser &&
								authUser?.user?.role === ADMIN &&
								['all', 'messages'].includes(feedTabSelected) && (
									<Button
										type={BUTTON_NORMAL}
										onClick={() => toggleSendMessageModal(true)}
										label={`${t('labels.write-message-to-users')}...`}
										buttonClass={styles.writeMessageBtn}
										containerClass={styles.writeMessageBtnContainer}
										id="dashboard-write-message-button"
									/>
								)}
							<div className={styles.content}>
								{feedTabSelected === 'all' &&
									_.orderBy(
										[...notifications, ...messages],
										'created_at',
										'desc'
									).map((item, index) => (
										<FeedItem
											key={index}
											item={item}
											isUnread={[
												...idsOfUnreadMessages,
												...idsOfUnreadNotifications
											].includes(item.id)}
											sendFeedToDelete={setDeleteFeedMessageId}
										/>
									))}
								{feedTabSelected === 'notifications' &&
									notifications.map((notification, index) => (
										<FeedItem
											key={index}
											item={notification}
											isUnread={idsOfUnreadNotifications.includes(
												notification.id
											)}
										/>
									))}
								{feedTabSelected === 'messages' &&
									messages.map((message, index) => (
										<FeedItem
											key={index}
											item={message}
											isUnread={idsOfUnreadMessages.includes(message.id)}
											sendFeedToDelete={setDeleteFeedMessageId}
										/>
									))}
								{loadingFeed && (
									<div className={styles.spinnerWrapper}>
										<Spinner isLoading={true} />
									</div>
								)}
								{getLoadMoreButton()}
							</div>
						</div>
					)}
				</div>
				{featuredFolders.length > 0 && (
					<div
						className={c(
							styles.featuredFolders,
							isSmallScreen && styles.withBackground
						)}
					>
						<div className={styles.heading}>
							<div>
								<FeaturedFoldersIcon />
								<h1>{t('titles.featured')}</h1>
							</div>
						</div>
						{isSmallScreen && (
							<div className={styles.contentSmallScreen}>
								{featuredFolders.map((folder) => (
									<Folder
										name={folder.name}
										key={folder.tag_id}
										tag={folder}
										selectedEmbeddedTag={(tag) => embeddedUrlHandler(tag)}
										selectedTag={(tag) => goToFolder(tag)}
									/>
								))}
							</div>
						)}
						<div
							ref={featuredFoldersElementRef}
							className={c(
								styles.content,
								featuredFoldersCentered && styles.centered,
								isSmallScreen && styles.hidden
							)}
						>
							{featuredFolders.map((folder, index) => (
								<FeaturedFolderCard
									folder={folder}
									key={index}
									selectedTagToEmbed={setSelectedTagToEmbed}
								/>
							))}
						</div>
					</div>
				)}
				{dashboardDiscoverBlockVisible && (
					<div className={styles.discoverContent}>
						<div className={styles.heading}>{t('titles.discover_content')}</div>
						<div className={styles.description}>
							{t('misc.discover_content_description')}
						</div>
						<div>
							<Button
								type={BUTTON_NORMAL}
								isPositive
								label={t('buttons.go_to_library')}
								onClick={() => navigate(`/${LIBRARY.toLowerCase()}`)}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="dashboard-my-shared-links-button"
								icon={<ArrowForwardIcon />}
								iconSide={ICON_RIGHT}
							/>
						</div>
						<div className={styles.closeBtn}>
							<Button
								type={BUTTON_NORMAL}
								label={t('buttons.close')}
								onClick={() =>
									saveConfig(
										`dashboard.discover.${authUser.user.id}`,
										1,
										false
									).then(() => {
										dispatch(
											setTempValue('dashboardDiscoverBlockVisible', false)
										)
									})
								}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="dashboard-discover-content-close-button"
								icon={<CloseIcon />}
								iconSide={ICON_RIGHT}
							/>
						</div>
					</div>
				)}
				<div className={styles.splitRow}>
					{presentations.length > 0 && !loadingPresentations && (
						<div className={c(styles.item, styles.presentations)}>
							<div className={styles.heading}>
								<div>
									<div className={styles.intro}>
										<PresentationsIcon /> {t('titles.presentations')}
									</div>
									<div className={styles.subHeading}>
										{t('labels.recently_saved')}
									</div>
								</div>
								<div>
									<Button
										type={BUTTON_TEXT}
										label={t('labels.my-presentations')}
										onClick={() =>
											navigate(
												`/${MY_PRESENTATIONS.toLowerCase().replace('_', '-')}`
											)
										}
										buttonClass={styles.btn}
										containerClass={styles.btnContainer}
										id="dashboard-my-presentations-button"
										icon={<ArrowForwardIcon />}
										iconSide={ICON_RIGHT}
									/>
								</div>
							</div>
							<div className={styles.content}>
								{presentations.map((presentation, index) => (
									<PresentationCard key={index} presentation={presentation} />
								))}
							</div>
						</div>
					)}
					{loadingPresentations && (
						<div className={styles.item}>
							<div className={styles.spinnerWrapper}>
								<Spinner isLoading={loadingPresentations} />
							</div>
						</div>
					)}
					{/* MARKETING BLOCK - PRESENTATIONS */}
					{presentations.length === 0 && !loadingPresentations && (
						<div className={c(styles.marketingBlock, styles.presentations)}>
							<div className={styles.heading}>
								{t('titles.create_presentations')}
							</div>
							<div className={styles.description}>
								{Parser(
									t('misc.no_saved_presentations_yet', [
										`<strong>${t('labels.content_library')}</strong>`
									])
								)}
							</div>
							<Button
								type={BUTTON_NORMAL}
								isPositive
								label={t('buttons.go_to_library')}
								onClick={() => navigate(`/${LIBRARY.toLowerCase()}`)}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="dashboard-marketing-my-presentations-go-to-library-button"
								icon={<ArrowForwardIcon />}
								iconSide={ICON_RIGHT}
							/>
						</div>
					)}
					{memosites.length > 0 && !loadingMemosites && (
						<div className={c(styles.item, styles.memosites)}>
							<div className={styles.heading}>
								<div>
									<div className={styles.intro}>
										<SharedLinksIcon /> {t('labels.my-memosites')}
									</div>
									<div className={styles.subHeading}>
										{t('labels.recently_opened')}
									</div>
								</div>
								<div>
									<Button
										type={BUTTON_TEXT}
										label={t('buttons.my-memosites')}
										onClick={() => navigate(`/${FOLLOWUP.toLowerCase()}`)}
										buttonClass={styles.btn}
										containerClass={styles.btnContainer}
										id="dashboard-my-shared-links-button"
										icon={<ArrowForwardIcon />}
										iconSide={ICON_RIGHT}
									/>
								</div>
							</div>
							<div className={styles.date}>{t('labels.last-opened')}</div>
							<div className={styles.content}>
								{memosites.map(
									(memosite, index) =>
										memosite.memo_opened && (
											<MemositeCard key={index} memosite={memosite} />
										)
								)}
							</div>
						</div>
					)}
					{loadingMemosites && (
						<div className={styles.item}>
							<div className={styles.spinnerWrapper}>
								<Spinner isLoading={loadingPresentations} />
							</div>
						</div>
					)}
					{/* MARKETING BLOCk - MEMOSITES */}
					{memosites.length === 0 && !loadingMemosites && (
						<div className={c(styles.marketingBlock, styles.memosites)}>
							<div className={styles.heading}>
								{t('titles.share_content_with_customers')}
							</div>
							<div className={styles.description}>
								{Parser(
									t('misc.no_shared_links_yet', [
										`<strong>${t('buttons.create_memosite')}</strong>`
									])
								)}
							</div>
							<Button
								type={BUTTON_NORMAL}
								isPositive
								label={t('buttons.create_a_shareable_link')}
								onClick={() =>
									dispatch(setMemositeBuilderValue('status', 'opened'))
								}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="dashboard-marketing-my-shared-links-button"
								icon={<ArrowForwardIcon />}
								iconSide={ICON_RIGHT}
							/>
						</div>
					)}
				</div>
				<div className={styles.recentlyUpdatedFolders}>
					<div className={styles.heading}>
						<h1>{t('titles.recently_updated_folders')}</h1>
						<Button
							type={BUTTON_TEXT}
							label={t('buttons.go_to_library')}
							onClick={() => navigate(`/${LIBRARY.toLowerCase()}`)}
							buttonClass={styles.btn}
							containerClass={styles.btnContainer}
							id="dashboard-recently-updated-folder-go-to-library"
							icon={<ArrowForwardIcon />}
							iconSide={ICON_RIGHT}
						/>
					</div>
					<div className={styles.content}>
						{recentlyUpdatedFolders.map(
							(currentTag: any) =>
								isTagVisibleNew(
									currentTag,
									authUser.user.usergroups_id,
									BUILD,
									true
								) && (
									<Folder
										name={currentTag.name}
										key={currentTag.tag_id}
										tag={currentTag}
										selectedEmbeddedTag={(tag) => embeddedUrlHandler(tag)}
										selectedTag={(tag) => goToFolder(tag)}
									/>
								)
						)}
					</div>
				</div>
				{mostPopularFiles.length > 2 && (
					<div className={styles.mostPopularFiles}>
						<div className={styles.heading}>
							<div className={styles.intro}>
								<h1>{t('titles.most_popular_files')}</h1>
								<h2>{`${t('titles.group')}: ${authUser.user.userGroupName}, ${t(
									'misc.past_x_days',
									['30']
								)}`}</h2>
							</div>
							<Button
								type={BUTTON_TEXT}
								label={t('buttons.go_to_library')}
								onClick={() => navigate(`/${LIBRARY.toLowerCase()}`)}
								buttonClass={styles.btn}
								containerClass={styles.btnContainer}
								id="dashboard-most-popular-files-go-to-library"
								icon={<ArrowForwardIcon />}
								iconSide={ICON_RIGHT}
							/>
						</div>
						<div className={styles.content}>
							{mostPopularFiles.map((file) => (
								<Card
									isSelected={slides[PRESENTATION_BAR.toLowerCase()].includes(
										file
									)}
									key={file.key}
									cardObject={{
										...file,
										extension: file.type
									}}
									className={styles.slideItem}
									handleCardClick={(e) => {
										e.stopPropagation()
										e.preventDefault()
										dispatch(addToTempPresentation(file, PRESENTATION_BAR))
									}}
									hasLayoutFontColor={false}
									hasToolbar
									openFileEditor={() => null}
									isEditable={false}
								/>
							))}
						</div>
					</div>
				)}
				<Search
					show={showSearch}
					handleClose={() => toggleShowSearch(false)}
					activePage={selectedBuildMenuItem}
				/>
				<SendMessageModal
					closeModal={() => toggleSendMessageModal(false)}
					isShowing={sendMessageModalVisible}
					toggleRefreshMessages={() => {
						fetchMessages(undefined, true)
					}}
				/>
				{!!selectedTagToEmbed && decideEmbedOrOpenInNew()}
				<ConfirmDeleteModal
					isActive={!!deleteFeedMessageId}
					handleClose={() => setDeleteFeedMessageId(null)}
					title={t('titles.are-you-sure')}
					disableButton={disableDeleteFeedButton}
					onApprove={removeFeedItem}
				>
					<div className={styles.deleteFeedContent}>
						{t('misc.delete-this-message')}
					</div>
				</ConfirmDeleteModal>
			</div>
		</div>
	)
}

export default DashboardNew
