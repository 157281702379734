import {
	ADD_TO_COLOR_PRESET,
	SET_LOGO_IMAGE,
	CLEAR_LOGO_IMAGE,
	CHANGE_LOGO_POSITION,
	SET_LOGO_SIZE,
	SET_BACKGROUND_COLOR,
	SET_BACKGROUND_OPACITY,
	TOGGLE_PRESENTATION_DECK_SHADOW,
	SET_PRESENTATION_DECK_COLOR,
	SET_CONTENT_TAB_COLOR,
	SET_CONTENT_TAB_OPACITY,
	SET_STORYBOARD_FONT,
	SET_STORYBOARD_TITLE_FONT,
	SET_STORYBOARD_FONT_COLOR,
	SET_PRESENTATION_DECK_FONT_COLOR,
	SET_STORYBOARD_FONT_SIZE,
	TOGGLE_UNDERLINE_TITLE,
	SET_TITLE_UNDERLINE_COLOR,
	SET_NAVIGATION_STYLE,
	TOGGLE_SHRINK_PREVIOUS_LEVEL,
	TOGGLE_BREADCRUMBS,
	TOGGLE_LANDING_PAGE,
	SET_LANDING_PAGE_STYLE,
	SET_LANDING_PAGE_TITLE,
	TOGGLE_STORYBOARD_FOLDER_SEARCH,
	TOGGLE_PARALLAX_SCROLLING_BACKGROUND,
	SET_LAYOUT_OBJECT,
	SET_BACKGROUND_IMAGE,
	SET_ROOT_LEVEL_GALLERY,
	SET_ROOT_LEVEL_GALLERY_WIDTH,
	INIT_ROOT_LEVEL_GALLLERY_OPTIONS,
	SET_ROOT_LEVEL_ITEMS_PER_ROW,
	SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS,
	SET_ROOT_LEVEL_GALLERY_SHAPE,
	SET_ROOT_LEVEL_SHAPE_SHADOW,
	SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR,
	SET_ROOT_LEVEL_TEXT_SHADOW,
	SET_ROOT_LEVEL_USE_FOLDER_ICONS,
	SET_STORYBOARD_FONT_WEIGHT,
	SET_STORYBOARD_TITLE_FONT_WEIGHT,
} from '../actions/actionTypes'
import { layoutSettings, rootLevelGalleryOptions } from '../../utils/layoutSettings'

export const initialState = {
	colorPreset: [],
	logoImage: {
		logoPosition: layoutSettings.logoPosition,
		logoImageUrl: layoutSettings.logoImageUrl,
		logoSize: layoutSettings.logoSize,
	},
	background: {
		backgroundColor: layoutSettings.backgroundColor,
		backgroundImageOpacity: layoutSettings.backgroundImageOpacity,
		backgroundImage: layoutSettings['background-image'],
	},
	presentation: {
		presentationDeckShadow: layoutSettings.presentationDeckShadow,
		presentationDeckColor: layoutSettings.presentationDeckColor,
		presentationDeckFontColor: layoutSettings.presentationDeckFontColor,
	},
	content: {
		contentTabColor: layoutSettings.contentTabColor,
		contentTabOpacity: layoutSettings.contentTabOpacity,
	},
	storyBoard: {
		storyBoardFont: layoutSettings.storyBoardFont,
		storyBoardTitleFont: layoutSettings.storyBoardTitleFont,
		storyBoardFontColor: layoutSettings.storyBoardFontColor,
		storyBoardFontSize: layoutSettings.storyBoardFontSize,
		storyBoardFolderSearch: layoutSettings.storyBoardFolderSearch,
		storyBoardFontWeight: layoutSettings.storyBoardFontWeight,
		storyBoardTitleFontWeight: layoutSettings.storyBoardTitleFontWeight,
	},
	misc: {
		underlineTitle: layoutSettings.underLineTitle,
		titleUnderlineColor: layoutSettings.titleUnderLineColor,
		navigationStyle: layoutSettings.navigationStyle,
		shrinkPreviousLevel: layoutSettings.shrinkPreviousLevel,
		breadcrumbs: layoutSettings.breadcrumbs,
		landingPage: layoutSettings.landingPage,
		landingPageTitle: layoutSettings.landingPageTitle,
		parallaxScrollingBackgound: layoutSettings.parallaxScrollingBackground,
		landingPageStyle: layoutSettings.landingPageStyle,
		rootLevelGallery: layoutSettings.rootLevelGallery,
		rootLevelGalleryOptions: {
			...rootLevelGalleryOptions,
		},
	},
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
	case ADD_TO_COLOR_PRESET:
		return {
			...state,
			colorPreset: state.colorPreset.concat(action.payload.newColor),
		}
	case SET_LOGO_IMAGE:
		return {
			...state,
			logoImage: {
				...state.logoImage,
				logoImageUrl: action.payload.logoImage,
			},
		}
	case CLEAR_LOGO_IMAGE:
		return {
			...state,
			logoImage: initialState.logoImage,
		}
	case CHANGE_LOGO_POSITION:
		return {
			...state,
			logoImage: {
				...state.logoImage,
				logoPosition: action.payload.logoPosition,
			},
		}
	case SET_LOGO_SIZE:
		return {
			...state,
			logoImage: {
				...state.logoImage,
				logoSize: action.payload.logoSize,
			},
		}
	case SET_BACKGROUND_COLOR:
		return {
			...state,
			background: {
				...state.background,
				backgroundColor: action.payload.backgroundColor,
			},
		}
	case SET_BACKGROUND_IMAGE:
		return {
			...state,
			background: {
				...state.background,
				backgroundImage: action.payload.backgroundImage,
			},
		}
	case SET_BACKGROUND_OPACITY:
		return {
			...state,
			background: {
				...state.background,
				backgroundImageOpacity: action.payload.backgroundImageOpacity,
			},
		}
	case TOGGLE_PRESENTATION_DECK_SHADOW:
		return {
			...state,
			presentation: {
				...state.presentation,
				presentationDeckShadow: action.payload.presentationDeckShadow,
			},
		}
	case SET_PRESENTATION_DECK_COLOR:
		return {
			...state,
			presentation: {
				...state.presentation,
				presentationDeckColor: action.payload.presentationDeckColor,
			},
		}
	case SET_CONTENT_TAB_COLOR:
		return {
			...state,
			content: {
				...state.content,
				contentTabColor: action.payload.contentTabColor,
			},
		}
	case SET_CONTENT_TAB_OPACITY:
		return {
			...state,
			content: {
				...state.content,
				contentTabOpacity: action.payload.contentTabOpacity,
			},
		}
	case SET_STORYBOARD_FONT:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardFont: action.payload.storyBoardFont,
			},
		}
	case SET_STORYBOARD_TITLE_FONT:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardTitleFont: action.payload.storyBoardTitleFont,
			},
		}
	case SET_STORYBOARD_FONT_COLOR:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardFontColor: action.payload.storyBoardFontColor,
			},
		}
	case SET_PRESENTATION_DECK_FONT_COLOR:
		return {
			...state,
			presentation: {
				...state.presentation,
				presentationDeckFontColor: action.payload.presentationDeckFontColor,
			},
		}
	case SET_STORYBOARD_FONT_SIZE:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardFontSize: action.payload.storyBoardFontSize,
			},
		}
	case TOGGLE_UNDERLINE_TITLE:
		return {
			...state,
			misc: {
				...state.misc,
				underlineTitle: action.payload.titleUnderlined,
			},
		}
	case SET_TITLE_UNDERLINE_COLOR:
		return {
			...state,
			misc: {
				...state.misc,
				titleUnderlineColor: action.payload.titleUnderlineColor,
			},
		}
	case SET_NAVIGATION_STYLE:
		return {
			...state,
			misc: {
				...state.misc,
				navigationStyle: action.payload.navigationStyle,
			},
		}
	case TOGGLE_SHRINK_PREVIOUS_LEVEL:
		return {
			...state,
			misc: {
				...state.misc,
				shrinkPreviousLevel: action.payload.isPreviousLevelShrink,
			},
		}
	case TOGGLE_BREADCRUMBS:
		return {
			...state,
			misc: {
				...state.misc,
				breadcrumbs: action.payload.isBreadcrumbsVisible,
			},
		}
	case TOGGLE_LANDING_PAGE:
		return {
			...state,
			misc: {
				...state.misc,
				landingPage: action.payload.landingPage,
			},
		}
	case SET_LANDING_PAGE_STYLE:
		return {
			...state,
			misc: {
				...state.misc,
				landingPageStyle: action.payload.landingPageStyle,
			},
		}
	case SET_LANDING_PAGE_TITLE:
		return {
			...state,
			misc: {
				...state.misc,
				landingPageTitle: action.payload.landingPageTitle,
			},
		}
	case TOGGLE_STORYBOARD_FOLDER_SEARCH:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardFolderSearch: action.payload.storyboardFolderSearch,
			},
		}
	case TOGGLE_PARALLAX_SCROLLING_BACKGROUND:
		return {
			...state,
			misc: {
				...state.misc,
				parallaxScrollingBackgound: action.payload.parallaxScrollingBackground,
			},
		}
	case SET_ROOT_LEVEL_GALLERY:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGallery: action.payload.isRootLevelGallery,
			},
		}
	case SET_ROOT_LEVEL_GALLERY_WIDTH:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					galleryWidth: action.payload.rootLevelGalleryWidth,
				},
			},
		}
	case SET_ROOT_LEVEL_ITEMS_PER_ROW:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					itemsPerRow: action.payload.itemsPerRow,
				},
			},
		}
	case SET_ROOT_LEVEL_GALLERY_PADDING_BETWEEN_ITEMS:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					paddingBetweenItems: action.payload.paddingBetweenItems,
				},
			},
		}
	case SET_ROOT_LEVEL_GALLERY_SHAPE:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					shape: action.payload.shape,
				},
			},
		}
	case INIT_ROOT_LEVEL_GALLLERY_OPTIONS:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...rootLevelGalleryOptions,
				},
			},
		}
	case SET_ROOT_LEVEL_SHAPE_SHADOW:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					shapeShadow: action.payload.rootLevelShapeShadow,
				},
			},
		}
	case SET_ROOT_LEVEL_SHAPE_BACKGROUND_COLOR:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					shapeBackgroundColor: action.payload.shapeBackgroundColor,
				},
			},
		}
	case SET_ROOT_LEVEL_TEXT_SHADOW:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					textShadow: action.payload.textShadow,
				},
			},
		}
	case SET_ROOT_LEVEL_USE_FOLDER_ICONS:
		return {
			...state,
			misc: {
				...state.misc,
				rootLevelGalleryOptions: {
					...state.misc.rootLevelGalleryOptions,
					useFolderIcons: action.payload.isUseFolderIcons,
				},
			},
		}
	case SET_STORYBOARD_FONT_WEIGHT:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardFontWeight: action.payload.fontWeight,
			},
		}
	case SET_STORYBOARD_TITLE_FONT_WEIGHT:
		return {
			...state,
			storyBoard: {
				...state.storyBoard,
				storyBoardTitleFontWeight: action.payload.fontWeight,
			},
		}
	case SET_LAYOUT_OBJECT: {
		const layoutObject = {
			...action.payload.layoutObject,
			storyBoard: {
				...state.storyBoard,
				...action.payload.layoutObject.storyBoard,
			},
		}
		return layoutObject
	}
	default:
		return state
	}
}

export default reducer
