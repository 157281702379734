import { sortBy } from 'lodash'

import { cloudFnGet } from '../../utils/requests'
import {
	SET_DESCENDANTS,
	SET_CURRENT_TAG_TREE,
	SET_ALL_SHORTCUTS,
	SET_TAGS_NAMED_VALUE,
	SET_FOLDER_FILES_PATH
} from './actionTypes'
import { convertBusinessRules } from '../../utils/convertBusinessRules'
import {
	fullTagStructureEndpoint,
	tagShortcutsAllEndpoint,
	allBackgroundImagesEndpoint
} from '../../api/apiEndpoints_new'

export const setDescendants =
	(descendants: any, parentId: any) => (dispatch: any) =>
		new Promise((resolve) => {
			dispatch({
				type: SET_DESCENDANTS,
				payload: {
					descendants,
					parentId
				}
			})
			resolve(true)
		})

export const setCurrentTagTree = (tagTree: any) => (dispatch: any) => {
	dispatch({
		type: SET_CURRENT_TAG_TREE,
		payload: {
			tagTree
		}
	})
}

export const foldersForSelection = (arrayOfFolders: any) => (dispatch: any) => {
	dispatch({
		type: SET_FOLDER_FILES_PATH,
		payload: {
			arrayOfFolders
		}
	})
}

export const getTags = (authToken?: any) => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(fullTagStructureEndpoint, {}, { withToken: true })
			.then((response) => {
				dispatch(setCurrentTagTree(response))
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const setAllShortcuts = (shortcuts: any) => (dispatch: any) => {
	dispatch({
		type: SET_ALL_SHORTCUTS,
		payload: {
			shortcuts
		}
	})
}

export const setTagsValue = (name: any, value: any) => (dispatch: any) => {
	dispatch({
		type: SET_TAGS_NAMED_VALUE,
		payload: {
			name,
			value
		}
	})
}

export const getAllShortcuts = () => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(tagShortcutsAllEndpoint)
			.then((response: any) => {
				const sorted = sortBy(
					response,
					(tag) => convertBusinessRules(tag.business_rules).breadCrumbOrder
				)
				dispatch(setAllShortcuts(sorted))
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getTagsBackgroundImages = () => (dispatch: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(allBackgroundImagesEndpoint)
			.then((response) => {
				dispatch(setTagsValue('backgroundImages', response))
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
