import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import c from 'classnames'
import {
	OpenInNew as ViewIcon,
	OpenInBrowser as OpenIcon,
	PlayArrow as PlayIcon
} from '@mui/icons-material'

import styles from './PresentationCard.module.scss'
import Button from '../../../../components/common/Button'
import {
	BUTTON_TEXT,
	ICON_LEFT,
	MY_PRESENTATIONS
} from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'
import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import { decideThumbnail } from '../../../../utils/helpers/decideThumbanil'
import {
	addToPresentation,
	clearPresentation,
	createSlideShow,
	setLoadedPresentation,
	setLoadedPresentationDirty
} from '../../../../store/actions/presentation'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import ConfirmDeleteModal from '../../../../components/common/ConfirmDeleteModal'

interface PresentationCardInterface {
	presentation: MeetingInterface
}

const PresentationCard = ({ presentation }: PresentationCardInterface) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [unsavedChangesModalVisible, toggleUnsavedChangesModal] =
		React.useState(false)

	const authUser = useSelector((store: StoreInterface) => store.authUser.user)
	const memositeBuilderStatus = useSelector(
		(store: StoreInterface) => store.memositeBuilder.status
	)
	const { loadedPresentationDirty } = useSelector(
		(store: StoreInterface) => store.presentation
	)

	const openPresentationHandler = () => {
		dispatch(clearPresentation())
		dispatch(addToPresentation(presentation?.extended_fields_object?.summary))
		dispatch(setLoadedPresentation(presentation))
		dispatch(setLoadedPresentationDirty(false))
		dispatch(setMemositeBuilderValue('memoInProgress', false))
		toast(t('notifications.success.presentation-loaded'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: toast.TYPE.SUCCESS,
			autoClose: 5000
		})
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.opened',
				id: presentation.id,
				name: presentation.subject
			})
		)
	}

	const checkForUnsavedChangesHandler = (e: MouseEvent) => {
		e.stopPropagation()
		if (memositeBuilderStatus === 'opened' || loadedPresentationDirty) {
			toggleUnsavedChangesModal(true)
		} else {
			openPresentationHandler()
		}
	}

	const renderPlaceholderSlides = (slidesNo: number) => {
		const slides = []
		for (let i = 0; i < 4 - slidesNo; i++) {
			slides.push(<div className={styles.moreSlides} key={i} />)
		}
		return slides
	}

	const playPresentationHandler = (e: MouseEvent) => {
		e.stopPropagation()
		dispatch(
			createSlideShow({
				slides: presentation.extended_fields_object?.summary,
				startFrom: 0
			})
		)
	}

	const viewPresentationHandler = () => {
		navigate(
			`/${MY_PRESENTATIONS.toLowerCase().replace('_', '-')}#${
				presentation.slug
			}`
		)
	}

	return (
		<div className={styles.wrapper} onClick={viewPresentationHandler}>
			<div className={styles.slides}>
				<div className={styles.slidesOverlay}>
					<Button
						type={BUTTON_TEXT}
						label={t('buttons.view')}
						onClick={viewPresentationHandler}
						buttonClass={styles.btn}
						containerClass={styles.btnContainer}
						id="dashboard-presentation-card-view-button"
						icon={<ViewIcon />}
						iconSide={ICON_LEFT}
					/>
				</div>
				{presentation.extended_fields_object?.summary &&
					presentation.extended_fields_object?.summary
						.slice(0, 3)
						.map((slide, index) => (
							<img
								src={decideThumbnail(
									{
										title: slide.title || '',
										extension: slide.type || '',
										checksum: slide.checksum,
										external_id: slide.external_id || '',
										thumbnail: ''
									},
									authUser.token
								)}
								alt={slide.name}
								key={index}
							/>
						))}
				{presentation.numberOfSlides && presentation.numberOfSlides > 3 ? (
					<div className={styles.moreSlides}>
						{`+ ${presentation.numberOfSlides - 3} ${t(
							'labels.slides'
						).toLowerCase()} `}
					</div>
				) : (
					renderPlaceholderSlides(presentation.numberOfSlides || 0)
				)}
			</div>
			<div
				className={c(
					styles.title,
					presentation.subject &&
						presentation.subject.length < 26 &&
						styles.compact
				)}
			>
				{presentation.subject}
			</div>
			<div className={styles.info}>
				<div className={styles.buttons}>
					<Button
						type={BUTTON_TEXT}
						label={t('labels.open')}
						onClick={(e) => checkForUnsavedChangesHandler(e)}
						buttonClass={c(styles.btn, styles.openBtn)}
						containerClass={styles.btnContainer}
						id="dashboard-presentation-card-open-button"
						icon={<OpenIcon />}
						iconSide={ICON_LEFT}
					/>
					{presentation.slug && (
						<Button
							type={BUTTON_TEXT}
							label={t('buttons.play')}
							onClick={(e) => playPresentationHandler(e)}
							buttonClass={styles.btn}
							containerClass={styles.btnContainer}
							id="dashboard-presentation-card-play-button"
							icon={<PlayIcon />}
							iconSide={ICON_LEFT}
						/>
					)}
				</div>
			</div>
			<ConfirmDeleteModal
				isActive={unsavedChangesModalVisible}
				handleClose={() => toggleUnsavedChangesModal(false)}
				onApprove={() => {
					toggleUnsavedChangesModal(false)
					if (unsavedChangesModalVisible) {
						openPresentationHandler()
					}
				}}
				title={t('titles.are-you-sure')}
				confirmLabel={t('labels.open-presentation')}
				cancelLabel={t('buttons.cancel')}
			>
				<div className={styles.confirmNewPresentation}>
					<p>{t('misc.are_you_sure_open_new_presentation')}</p>
				</div>
			</ConfirmDeleteModal>
		</div>
	)
}

export default PresentationCard
