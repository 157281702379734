import hexToRgba from 'hex-to-rgba'
import { createTheme } from '@material-ui/core'

export const theme = {
	colors: {
		blue: '#1782c2',
		userSettingsModalBackgroundColor: hexToRgba('#444444', 0.5),
		spinnerColor: hexToRgba('#12e2b0', 1),
		thumbnailBackgroundColor: hexToRgba('#464a54', 1)
	}
}

export const dateTimePickerTheme = createTheme({
	palette: {
		background: {
			paper: hexToRgba('#32353d', 1)
		},
		primary: {
			main: hexToRgba('#ffffff', 0.15),
			contrastText: hexToRgba('#12e2b0', 1)
		},
		secondary: {
			main: hexToRgba('#12e2b0', 1),
			contrastText: hexToRgba('#000000', 0.8)
		},
		action: {
			active: hexToRgba('#000000', 1),
			hoverOpacity: 0.5
		},
		text: {
			primary: hexToRgba('#ffffff', 1),
			hint: hexToRgba('#ffffff', 1)
		}
	},
	typography: {
		fontFamily: 'Open Sans'
	},
	overrides: {
		MuiPopover: {
			root: {
				// @ts-ignore
				zIndex: '99999 !important'
			}
		},
		MuiIconButton: {
			root: {
				color: hexToRgba('#12e2b0', 1)
			}
		},
		MuiTab: {
			textColorInherit: {
				color: hexToRgba('#ffffff', 1)
			}
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		},
		MuiInput: {
			underline: {
				'&:before': {
					content: 'unset'
				},
				'&:after': {
					content: 'unset'
				}
			},
			root: {
				'&:hover': {
					background: hexToRgba('#000000', 0.25)
				},
				background: hexToRgba('#000000', 0.15),
				borderRadius: 20,
				borderBottom: 0,
				height: 40,
				margin: 0,
				padding: 0,
				paddingLeft: 20,
				color: hexToRgba('#ffffff', 1),
				letterSpacing: 0,
				fontSize: 14
			}
		}
	}
})

export const dateTimePickerThemeTransparent = createTheme({
	palette: {
		background: {
			paper: hexToRgba('#32353d', 1)
		},
		primary: {
			main: hexToRgba('#ffffff', 0.15),
			contrastText: hexToRgba('#12e2b0', 1)
		},
		secondary: {
			main: hexToRgba('#12e2b0', 1),
			contrastText: hexToRgba('#000000', 0.8)
		},
		action: {
			active: hexToRgba('#000000', 1),
			hoverOpacity: 0.5
		},
		text: {
			primary: hexToRgba('#ffffff', 1),
			hint: hexToRgba('#ffffff', 1)
		}
	},
	typography: {
		fontFamily: 'Open Sans'
	},
	overrides: {
		MuiPopover: {
			root: {
				// @ts-ignore
				zIndex: '99999 !important'
			}
		},
		MuiIconButton: {
			root: {
				color: hexToRgba('#12e2b0', 1)
			}
		},
		MuiTab: {
			textColorInherit: {
				color: hexToRgba('#ffffff', 1)
			}
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		},
		MuiInput: {
			underline: {
				'&:before': {
					content: 'unset'
				},
				'&:after': {
					content: 'unset'
				}
			},
			root: {
				'&:hover': {
					// background: hexToRgba('#000000', 0.25),
				},
				// background: hexToRgba('#000000', 0.15),
				// borderRadius: 20,
				borderBottom: 0,
				// height: 40,
				margin: 0,
				padding: 0,
				// paddingLeft: 20,
				color: hexToRgba('#12e2b0', 1),
				letterSpacing: 0,
				fontSize: 13
			}
		}
	}
})
