import {
	SET_MY_FILES_CATEGORIES,
	SET_MY_FILES,
	ADD_FILE_TO_MY_FILES,
	CREATE_FOLDER_MODAL
} from '../actions/actionTypes'

const initialState = {
	myFilesCategories: [],
	myFiles: [],
	createFolderModalVisible: false
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_MY_FILES_CATEGORIES:
			return {
				...state,
				myFilesCategories: action.payload.myFilesCategories
			}
		case SET_MY_FILES:
			return {
				...state,
				myFiles: action.payload.myFilesContent
			}
		case ADD_FILE_TO_MY_FILES:
			return {
				...state,
				myFiles: [...state.myFiles, action.payload.myFile]
			}
		case CREATE_FOLDER_MODAL:
			return {
				...state,
				createFolderModalVisible: action.payload.createFolderModalVisible
			}
		default:
			return state
	}
}

export default reducer
