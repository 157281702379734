import getUnixTime from 'date-fns/getUnixTime'

import {
	TOGGLE_MODAL,
	SET_BG_BLUR,
	CHANGE_MODULE_TIMESTAMP,
	SET_BG_IMAGE,
	SET_THUMBNAIL_SIZE,
	SET_PRESENTATION_BAR_SIZE,
} from './actionTypes'

export const toggleModal = (boolean: any, modal: any) => (dispatch: any) => new Promise(resolve => {
	dispatch({
		type: TOGGLE_MODAL,
		payload: {
			modal,
			boolean,
		},
	})
	resolve(boolean)
})


export const stBgBlurDeviation = (deviationValue: any) => (dispatch: any) => new Promise(resolve => {
	dispatch({
		type: SET_BG_BLUR,
		payload: {
			deviationValue,
		},
	})
	resolve(deviationValue)
})

export const changeModuleTimestamp = (whichModule: any) => (dispatch: any) => {
	dispatch({
		type: CHANGE_MODULE_TIMESTAMP,
		payload: {
			whichModule,
			timestamp: getUnixTime(new Date()),
		},
	})
}

export const setBgImage = (bgImage: any) => (dispatch: any) => {
	dispatch({
		type: SET_BG_IMAGE,
		payload: {
			bgImage,
		},
	})
}

export const setThumbanilSize = (thumbnailSize: any) => (dispatch: any) => {
	dispatch({
		type: SET_THUMBNAIL_SIZE,
		payload: {
			thumbnailSize,
		},
	})
}

export const setPresentationbarSize = (presentationBarSize: any) => (dispatch: any) => {
	dispatch({
		type: SET_PRESENTATION_BAR_SIZE,
		payload: {
			presentationBarSize,
		},
	})
}
