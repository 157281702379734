import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
	ArrowForward as GoToFolderIcon,
	OpenInNew as OpenLinkIcon
} from '@mui/icons-material'

import styles from './FeaturedFolderCard.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { StoreInterface, TagInterface } from '../../../../utils/interfaces'
import Button from '../../../../components/common/Button'
import { BUTTON_TEXT, ICON_RIGHT, LIBRARY } from '../../../../utils/consts'
import { sfApiRoot } from '../../../../api/apiEndpoints_new'
import { SelectedTagInterface } from '../../../../components/FileBrowser/components/Folder/interfaces'
import getEmbedUrl from '../../../../utils/helpers/getEmbedUrl'

interface FolderCardInterface {
	folder: TagInterface
	selectedTagToEmbed: (selectedTag: SelectedTagInterface) => void
}

const FeaturedFolderCard = ({
	folder,
	selectedTagToEmbed
}: FolderCardInterface) => {
	const navigate = useNavigate()
	const location = useLocation()

	const businessRules = JSON.parse(folder.business_rules)

	const { token, refresh_token } = useSelector(
		(state: StoreInterface) => state.authUser.user
	)

	const decideIcon = () => {
		if (businessRules) {
			if (businessRules.embeddedUrl) {
				if (businessRules.folderIconImage) {
					return (
						<div className={styles.folderIcon}>
							<img
								src={`${sfApiRoot}/files/assets/files/${
									businessRules.folderIconImage
								}?oauth=${encodeURIComponent(token)}`}
								alt=""
							/>
						</div>
					)
				} else {
					return <div className={styles.emptyDivider} />
				}
			} else {
				if (businessRules.folderIconImage) {
					return (
						<div className={styles.folderIcon}>
							<img
								src={`${sfApiRoot}/files/assets/files/${
									businessRules.folderIconImage
								}?oauth=${encodeURIComponent(token)}`}
								alt=""
							/>
						</div>
					)
				} else {
					return <div className={styles.folderShape} />
				}
			}
		} else {
			return <div className={styles.folderShape} />
		}
	}

	const embeddedTagHandler = () => {
		const newTag = {
			id: folder.id,
			name: folder.name,
			embeddedUrl: getEmbedUrl(location, businessRules, refresh_token, token),
			target: businessRules.target || false
		}
		selectedTagToEmbed(newTag)
	}

	const cardClickHandler = () => {
		if (businessRules?.embeddedUrl) {
			embeddedTagHandler()
		} else {
			navigate(`/${LIBRARY.toLowerCase()}/${folder.tag_id}`)
		}
	}

	return (
		<div className={styles.wrapper} onClick={cardClickHandler}>
			{decideIcon()}
			<div className={styles.name}>{folder.name}</div>
			{businessRules?.folderDescription && (
				<div className={styles.text}>{businessRules?.folderDescription}</div>
			)}
			{businessRules && businessRules.embeddedUrl ? (
				<Button
					type={BUTTON_TEXT}
					label={t('labels.open-link')}
					onClick={embeddedTagHandler}
					buttonClass={styles.btn}
					containerClass={styles.btnContainer}
					id="dashboard-featured-folder-open-link-button"
					icon={<OpenLinkIcon />}
					iconSide={ICON_RIGHT}
				/>
			) : (
				<Button
					type={BUTTON_TEXT}
					label={t('buttons.go_to_folder')}
					onClick={() => navigate(`/${LIBRARY.toLowerCase()}/${folder.tag_id}`)}
					buttonClass={styles.btn}
					containerClass={styles.btnContainer}
					id="dashboard-featured-folder-go-to-folder-button"
					icon={<GoToFolderIcon />}
					iconSide={ICON_RIGHT}
				/>
			)}
		</div>
	)
}

export default FeaturedFolderCard
