import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { StoreInterface, ModuleNamesInterface } from '../../utils/interfaces'
import styles from './Analytics.module.scss'
import { setTempValue } from '../../store/actions/temp'

const Analytics = () => {
	const dispatch = useDispatch()
	const authToken = useSelector(
		(state: StoreInterface) => state.authUser.user.token
	)
	const refreshToken = useSelector(
		(state: StoreInterface) => state.authUser.user.refresh_token
	)

	useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.ANALYTICS))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.analyticsFrame}>
			<iframe
				src={`${process.env.REACT_APP_ANALYTICS_URL}?${refreshToken}|||${authToken}`}
				title="analytics"
				id="analyticsIframe"
				frameBorder={0}
			/>
		</div>
	)
}

export default Analytics
