import React from 'react'

import styles from './DeleteMemositeModal.module.scss'

import Modal from '../../../../components/common/ModalNew'
import Button from '../../../../components/common/Button'
import { t } from '../../../../utils/languages/i18n'

interface DeleteMemositeModalInterface {
	deleteMemosite: () => void
	disableDeleteButton: boolean
	handleClose: () => void
	isActive: boolean
}

const DeleteMemositeModal = ({
	deleteMemosite,
	disableDeleteButton,
	handleClose,
	isActive
}: DeleteMemositeModalInterface) => {
	return (
		<Modal
			customClassName={styles.wrapper}
			disableOverlayClose={true}
			hasCloseIcon={true}
			hide={handleClose}
			isShowing={isActive}
			modalTitle={t('titles.are-you-sure')}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={handleClose}
						id="delete-memosite-modal-cancel"
					/>
					<Button
						label={t('buttons.confirm')}
						onClick={deleteMemosite}
						isPositive
						isDisabled={disableDeleteButton}
						id="delete-memosite-modal-confirm"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<p>{t('titles.delete-memosite')}</p>
			</div>
		</Modal>
	)
}

export default DeleteMemositeModal
