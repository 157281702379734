import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useSelector } from 'react-redux'

import styles from './FullScreenLoader.module.scss'
import { StoreInterface } from '../../../utils/interfaces'

const FullScreenLoader = () => {
	const { showFullScreenLoader } = useSelector(
		(store: StoreInterface) => store.temp
	)

	return (
		<React.Fragment>
			{showFullScreenLoader && (
				<div className={styles.fullScreenLoader}>
					<ClipLoader color="#ffffff" size={30} />
				</div>
			)}
		</React.Fragment>
	)
}

export default FullScreenLoader
