import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { StoreInterface } from './utils/interfaces'

interface AuthProviderInterface {
	children: JSX.Element
	isAdmin?: boolean
	isManager?: boolean
}

const PrivateRoute = ({
	children,
	isAdmin = false,
	isManager = false
}: AuthProviderInterface) => {
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const isAdminLoggedIn = user.user
		? user.user.role === 'Admin' || user.user.role === 'Sysadmin'
		: false
	const isManagerLoggedIn = user.user ? user.user.role === 'Manager' : false

	if (!user.token) {
		return <Navigate to="/login" />
	}

	if (user.token && isAdmin && !isAdminLoggedIn) {
		return <Navigate to="/" />
	}

	if (user.token && isManager && !isManagerLoggedIn) {
		return <Navigate to="/" />
	}

	return children
}

export default PrivateRoute
