import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	FolderOutlined as FolderIcon,
	Info as InfoIcon,
	VerticalSplitOutlined as NavigationToggleIcon
} from '@mui/icons-material'
import c from 'classnames'

import styles from './MyFiles.module.scss'

import {
	setSelectedBuildMenuItem,
	setSelectedCategory,
	toggleLoading
} from '../../store/actions/buildContext'
import FileBrowser from '../../components/FileBrowser'
import {
	FolderInterface,
	ModuleNamesInterface,
	StoreInterface
} from '../../utils/interfaces'
import { setTempValue } from '../../store/actions/temp'
import { BUTTON_TEXT, ICON_LEFT, MY_FILES } from '../../utils/consts'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { t } from '../../utils/languages/i18n'
import Folder from './components/Folder'
import Button from '../../components/common/Button'
import Modal from '../../components/common/ModalNew'
import {
	createFolderModal,
	createMyFilesFolder,
	getAllMyFilesCategories
} from '../../store/actions/myfiles'
import Input from '../../components/common/Input'
import Spinner from '../../components/common/Spinner'
import CreateNewFolder from './components/CreateNewFolder'

const MyFiles = () => {
	const dispatch = useDispatch()

	const { isLoading } = useSelector(
		(store: StoreInterface) => store.buildContext
	)
	const { myFilesCategories, createFolderModalVisible } = useSelector(
		(store: StoreInterface) => store.myfiles
	)
	const { myFilesHomepageVisible, sidebarNavsDisabled, isSidebarOpened } =
		useSelector((store: StoreInterface) => store.temp)
	const { authUser } = useSelector((store: StoreInterface) => store)

	const [newFolderName, setNewFolderName] = React.useState(
		t('labels.new-folder')
	)
	const [creatingNewFolder, toggleCreatingNewFolder] = React.useState(false)

	React.useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.MY_FILES))
		dispatch(setSelectedBuildMenuItem(MY_FILES))
		dispatch(toggleLoading(true))
		dispatch(setTempValue('myFilesHomepageVisible', true))
		return () => {
			dispatch(
				setSelectedCategory({
					id: null,
					categoryName: ''
				})
			)
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.MyFiles`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const createNewFolderHandler = () => {
		toggleCreatingNewFolder(true)
		createMyFilesFolder(newFolderName, authUser.user.token).then(() => {
			dispatch(getAllMyFilesCategories())
			toggleCreatingNewFolder(false)
			dispatch(createFolderModal(false))
			setNewFolderName(t('labels.new-folder'))
		})
	}

	const closeHandler = () => {
		dispatch(createFolderModal(false))
		setNewFolderName(t('labels.new-folder'))
	}

	return (
		<div className={styles.wrapper}>
			{myFilesHomepageVisible ? (
				<div
					className={c(
						styles.homepage,
						!isSidebarOpened && styles.additionalLeftMarginSmallScreens,
						!isSidebarOpened &&
							sidebarNavsDisabled &&
							styles.additionalLeftMargin
					)}
				>
					<div className={styles.heading}>
						<div>
							<div
								title={t('tooltips.show_hide_folder_structure')}
								className={styles.navToggleIcon}
								onClick={() =>
									dispatch(
										setTempValue('sidebarNavsDisabled', !sidebarNavsDisabled)
									)
								}
							>
								<NavigationToggleIcon />
							</div>
							<Button
								containerClass={styles.featuredBtnWrapper}
								buttonClass={styles.featuredBtnText}
								type={BUTTON_TEXT}
								iconSide={ICON_LEFT}
								icon={<InfoIcon />}
								onClick={() => null}
								id="myfiles-homepage-featured-guide-button"
								tooltip={t('tooltips.quick_guide_for', [t('labels.myfiles')])}
							/>
						</div>
						<FolderIcon className={styles.folderIcon} />
						{t('misc.my-files')}
					</div>
					<div className={styles.content}>
						{myFilesCategories &&
							myFilesCategories.map((folder: FolderInterface) => (
								<Folder folder={folder} key={folder.id} />
							))}
						<CreateNewFolder />
					</div>
				</div>
			) : (
				<FileBrowser
					handleNavigation={(value) =>
						dispatch(setSelectedBuildMenuItem(value))
					}
					isLoading={isLoading}
				/>
			)}
			<Modal
				isShowing={createFolderModalVisible}
				hide={() => dispatch(createFolderModal(false))}
				customClassName={styles.modalWrapper}
				modalTitle={t('titles.create_new_folder')}
				headerClassname={styles.header}
				zIndex={10000}
				footer={
					<div className={styles.buttonsContainer}>
						<Button
							label={t('buttons.cancel')}
							onClick={closeHandler}
							id="my-files-create-folder-modal-cancel"
						/>
						<Button
							label={t('buttons.create_folder')}
							onClick={createNewFolderHandler}
							isPositive
							id="my-files-create-folder-modal-create"
						/>
					</div>
				}
			>
				<div className={styles.content}>
					<Input
						label={t('labels.folder_name')}
						onChange={(event: any) => setNewFolderName(event.target.value)}
						placeholderText={t('labels.folder_name')}
						name={t('labels.folder_name')}
						initialValue={newFolderName}
						inputClassName={styles.input}
					/>
					<div className={styles.spinner}>
						<Spinner
							wrapperClassName={styles.spinnerWrapper}
							isLoading={creatingNewFolder}
							size={20}
						/>
					</div>
				</div>
			</Modal>
		</div>
	)
}

export default MyFiles
