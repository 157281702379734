import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import c from 'classnames'
import {
	CloudDoneOutlined,
	CloudUploadOutlined,
	DeleteOutlined,
	Edit,
	EmailOutlined,
	GridView,
	History,
	Link,
	LockOutlined,
	OpenInNew,
	SettingsOutlined,
	ViewListOutlined,
	Close,
	EditOutlined,
	ContentCopy,
	DownloadForOfflineOutlined,
	FileDownloadOutlined,
	PictureAsPdfOutlined
} from '@mui/icons-material'
import _ from 'lodash'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import axios from 'axios'

import styles from './ExtendedMemositeModal.module.scss'

import Sessions from './Sessions'
import Modal from '../../../../components/common/ModalNew'
import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import { setMemositeValue } from '../../../../store/actions/memosite'
import Button from '../../../../components/common/Button'
import {
	BUTTON_TEXT,
	GRID,
	ICON_LEFT,
	IMAGE_TYPES,
	LIST,
	MSDOC_TYPES,
	MY_MEMOSITES_,
	PDF,
	PDF_ALLOWED_TYPES,
	VIDEOS
} from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'
import Stats from '../Stats'
import Spinner from '../../../../components/common/Spinner'
import SlidesContainer from '../MemositeItem/SlidesContainer'
import {
	MemositeAnalyticsInterface,
	SlideInterfaceWithAnalytics,
	SlidePageInterface
} from '../../interfaces'
import { initialMemositeAnalytics } from '../../emptyObjectDefinitions'
import {
	getMemositeOpened,
	getMemositeSlidesWithAnalytics
} from '../../../../api/requests/memosite'
import BigGreenButton from '../../../../components/PresentationBar/components/BigGreenButton'
import { clearTempPresentation } from '../../../../store/actions/temp'
import MemositeLabelsManager from '../../../../components/MemositeLabelsManager'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'
import { getFormattedDuration } from '../../../../utils/helpers/timeFormat'
import { addToAnalyticsBatch } from '../../../../store/actions/analytics'
import { getFilesAssetsEndpoint } from '../../../../api/apiEndpoints_new'
import { createPdfNew } from '../../../../api/requests/pdf'

interface ExtendedModalInterface {
	copyMemositeLink: () => void
	emailMemosite: (e: any) => void
	memosite: MeetingInterface
	memositeLink: string
	memositeToDelete: (id: number) => void
	memositeToDuplicate: (memosite: MeetingInterface) => void
	openCRMModal: (memosite: MeetingInterface) => void
	toggleSettingsModalVisible: (value: boolean) => void
	updateMemositeSubject: (id: number, subject: string) => void
}

const ExtendedMemositeModal = ({
	copyMemositeLink,
	emailMemosite,
	memosite,
	memositeLink,
	memositeToDelete,
	memositeToDuplicate,
	openCRMModal,
	toggleSettingsModalVisible,
	updateMemositeSubject
}: ExtendedModalInterface) => {
	const dispatch = useDispatch()

	const [showSessions, setShowSessions] = useState(false)
	const [slides, setSlides] = useState<SlideInterfaceWithAnalytics[]>([])
	const [pages, setPages] = useState<SlidePageInterface[]>([])
	const [memositeTitleUpdating, toggleMemositeTitleUpdating] = useState(false)
	const [memositeAnalytics, setMemositeAnalytics] =
		useState<MemositeAnalyticsInterface>(initialMemositeAnalytics)
	const [loadingSlides, toggleLoadingSlides] = useState(false)
	const [isDownloadDropdownVisible, toggleDownloadDropdownVisible] =
		useState(false)
	const [generatingPdf, toggleGeneratingPdf] = useState(false)
	const [generatingZip, toggleGeneratingZip] = useState(false)

	const { memosites, selectedMemosite, view } = useSelector(
		(store: StoreInterface) => store.memosite
	)
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { crmSettings } = useSelector((store: StoreInterface) => store.temp)

	useEffect(() => {
		toggleLoadingSlides(true)
		getMemositeSlidesWithAnalytics(memosite.id).then((response) => {
			const sortedSlides: any[] = _.sortBy(
				[...response.slides, ...response.pages],
				'attr_time'
			).reverse()
			const highestValue = sortedSlides[0].attr_time || 0
			const slidesWithAnalytics = response.slides.map((slide: any) => {
				return {
					...slide,
					graphBarValue:
						highestValue === 0
							? 100
							: Math.round((slide.attr_time / highestValue) * 100),
					formattedTime: getFormattedDuration(slide.attr_time)
				}
			})
			const pagesWithAnalytics = response.pages.map((page: any) => {
				return {
					...page,
					graphBarValue:
						highestValue === 0
							? 100
							: Math.round((page.attr_time / highestValue) * 100),
					formattedTime: getFormattedDuration(page.attr_time)
				}
			})
			setSlides(slidesWithAnalytics)
			setPages(pagesWithAnalytics)
			toggleLoadingSlides(false)
		})
		getMemositeOpened(memosite.id).then((response: any) => {
			setMemositeAnalytics(response)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const subjectUpdate = async (newSubject: string) => {
		toggleMemositeTitleUpdating(true)
		updateMemositeSubject(memosite.id, newSubject)
	}

	const closeModalHandler = () => {
		dispatch(setMemositeValue('selectedMemosite', undefined))
		dispatch(clearTempPresentation(MY_MEMOSITES_))
	}

	const downloadZipFilesHandler = () => {
		toggleGeneratingZip(true)
		const fileRequests: Promise<{
			data: {
				type: string
				checksum: string
				file: Blob
			}
		}>[] = []
		const fileTypes: string[] = []
		slides
			.filter((slide) => !slide.deleted)
			.forEach((slide) => {
				if (slide) {
					if (
						IMAGE_TYPES.includes(slide.type.toUpperCase()) ||
						MSDOC_TYPES.includes(slide.type.toUpperCase()) ||
						slide.type.toUpperCase() === PDF ||
						VIDEOS.includes(slide.type.toUpperCase())
					) {
						fileRequests.push(
							axios.get(
								`${getFilesAssetsEndpoint}/files/${
									slide.checksum
								}?oauth=${encodeURIComponent(user.token)}`,
								{
									responseType: 'blob',
									transformResponse: [
										(data: any) => {
											return {
												type: slide.type,
												checksum: slide.checksum,
												file: data
											}
										}
									]
								}
							)
						)
						fileTypes.push(slide.type)
					}
				}
			})
		Promise.all(fileRequests)
			.then((response) => {
				const zip = new JSZip()
				response.forEach((item) => {
					zip.file(`${item.data.checksum}.${item.data.type}`, item.data.file)
				})
				zip
					.generateAsync({ type: 'blob' })
					.then((content) => {
						saveAs(content, `${memosite.subject}.zip`)
						dispatch(
							addToAnalyticsBatch({
								memo: {
									id: memosite.id,
									slug: memosite.slug
								},
								event: 'user.memosite.zip'
							})
						)
						toggleGeneratingZip(false)
					})
					.catch(() => toggleGeneratingZip(false))
			})
			.catch(() => toggleGeneratingZip(false))
	}

	const downloadPdfHandler = async (event: { stopPropagation: () => void }) => {
		event.stopPropagation()
		const allowedSlides = slides.filter((sf) =>
			PDF_ALLOWED_TYPES.includes(sf.type.toUpperCase())
		)
		toggleGeneratingPdf(true)
		const pdfData = {
			extended_fields: {
				summary: allowedSlides
			},
			document: 'presentation',
			userName: `${user.user.firstname} ${user.user.lastname}`
		}
		// @ts-ignore
		const createPdfResult = await createPdfNew(pdfData, user.token)
		if (createPdfResult) {
			dispatch(
				addToAnalyticsBatch({
					memo: {
						id: memosite.id,
						slug: memosite.slug
					},
					event: 'user.memosite.downloaded'
				})
			)
			toggleGeneratingPdf(false)
		}
	}

	return (
		<Modal
			modalClassName={styles.topLevelModalWrapper}
			customClassName={styles.wrapper}
			headerClassname={styles.header}
			disableOverlayClose={true}
			hasCloseIcon={false}
			hide={closeModalHandler}
			isShowing={selectedMemosite !== undefined}
			zIndex={10000}
			before={
				<div className={styles.closeIcon}>
					<Close htmlColor="#FFFFFF" onClick={closeModalHandler} />
				</div>
			}
			after={
				<BigGreenButton role={MY_MEMOSITES_.toLowerCase()} zIndex={100011} />
			}
			top="100px"
			bottom="unset"
		>
			{showSessions ? (
				<div className={styles.sessionsWrapper}>
					<Sessions
						closeSessions={() => setShowSessions(false)}
						memosite={selectedMemosite}
					/>
				</div>
			) : (
				<div className={styles.container}>
					<div className={styles.titleRow}>
						{user.user.id === memosite.creator ? (
							<div className={styles.inputWrapper}>
								<input
									type="text"
									defaultValue={memosite.subject}
									onBlur={(e) => {
										if (!memositeTitleUpdating) {
											subjectUpdate(e.target.value)
												.then(() => {
													toggleMemositeTitleUpdating(false)
													toast(
														t('notifications.success.memosite_name_updated'),
														{
															position: toast.POSITION.BOTTOM_RIGHT,
															type: 'success',
															autoClose: 5000
														}
													)
												})
												.catch(() => {
													toast(t('notifications.error.something_went_wrong'), {
														position: toast.POSITION.BOTTOM_RIGHT,
														type: 'error',
														autoClose: 5000
													})
												})
										}
									}}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter')
											subjectUpdate(e.target.value).then(() => {
												e.target.blur()
												toggleMemositeTitleUpdating(false)
											})
									}}
									id="extended-memosite-modal-subject-input"
								/>
								<Edit />
							</div>
						) : (
							<div className={styles.subjectNonEditable}>
								{memosite.subject}
							</div>
						)}
						<div className={styles.icons}>
							{memosite.is_secured && (
								<LockOutlined
									htmlColor="#88b0ff"
									titleAccess={t('tooltips.password_protected')}
								/>
							)}
							{memosite.crm && memosite.crm.status === 1 && (
								<CloudDoneOutlined
									htmlColor="#88b0ff"
									titleAccess={t('tooltips.sent_to_crm')}
								/>
							)}
						</div>
						<div className={styles.labels}>
							<div className={styles.slides}>
								{t('labels.slides')}
								<div className={styles.value}>{memosite.numberOfSlides}</div>
							</div>
							{memosite.expiry_date &&
								typeof memosite.expiry_date === 'string' && (
									<div className={styles.expires}>
										{t('labels.expires')}
										<div className={styles.value}>
											{format(new Date(memosite.expiry_date), 'dd / MM / yyyy')}
										</div>
									</div>
								)}
							<div className={styles.dateCreated}>
								{t('labels.date-created')}
								<div className={styles.value}>
									{format(new Date(memosite.created_at), 'dd / MM / yyyy')}
								</div>
							</div>
							<div className={styles.lastOpened}>
								{t('labels.last-opened')}
								<div className={styles.value}>
									{memosite.memo_opened ? (
										<React.Fragment>
											{memosite.memo_opened === 'no_data' ? (
												t('labels.data-not-available')
											) : (
												<p className={styles.lastOpenedDate}>
													{format(
														new Date(memosite.memo_opened.replace(/\s/g, 'T')),
														'dd / MM / yyyy'
													)}
												</p>
											)}
										</React.Fragment>
									) : (
										<p className={styles.notYetOpened}>
											{t('labels.not-yet-opened')}
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.actionsRow}>
						{user.user.id === memosite.creator && (
							<div className={styles.buttons}>
								<div className={styles.lastEdited}>
									{t('labels.last-edited')}
									<span>
										{format(
											new Date(memosite.updated_at.replace(/\s/g, 'T')),
											'dd / MM / yyyy'
										)}
									</span>
								</div>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<EditOutlined htmlColor="#FFFFFF" />}
									label={t('buttons.edit_memosite')}
									onClick={() => {
										dispatch(setMemositeBuilderValue('status', 'editing'))
										dispatch(setMemositeValue('selectedMemosite', memosite))
									}}
									id="extended-memosite-modal-edit-memosite"
								/>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<ContentCopy htmlColor="#FFFFFF" />}
									label={t('labels.duplicate')}
									onClick={() => {
										memositeToDuplicate(memosite)
										closeModalHandler()
									}}
									id="extended-memosite-modal-duplicate-memosite"
								/>
								<Button
									type={BUTTON_TEXT}
									buttonClass={c(
										styles.button,
										isDownloadDropdownVisible && styles.buttonWithDropdownActive
									)}
									iconSide={ICON_LEFT}
									icon={<DownloadForOfflineOutlined htmlColor="#FFFFFF" />}
									label={t('labels.download')}
									onClick={(event) => {
										event.stopPropagation()
										toggleDownloadDropdownVisible(!isDownloadDropdownVisible)
									}}
									id="extended-memosite-modal-download-memosite"
								/>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<DeleteOutlined htmlColor="#FFFFFF" />}
									label={t('labels.delete')}
									onClick={() => memositeToDelete(memosite.id)}
									id="extended-memosite-modal-delete-memosite"
								/>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<SettingsOutlined htmlColor="#FFFFFF" />}
									label={t('labels.settings')}
									onClick={() => toggleSettingsModalVisible(true)}
									id="extended-memosite-modal-settings-memosite"
								/>
								{isDownloadDropdownVisible && (
									<div className={styles.downloadDropdown}>
										<div key="zip" className={styles.dropdownItem}>
											<Button
												type={BUTTON_TEXT}
												buttonClass={styles.dropdownItemBtn}
												iconSide={ICON_LEFT}
												icon={<FileDownloadOutlined htmlColor="#FFFFFF" />}
												label={t('buttons.download_files')}
												onClick={downloadZipFilesHandler}
												isDisabled={generatingZip}
												id="extended-memosite-modal-download-files"
											/>
										</div>
										<div key="pdf" className={styles.dropdownItem}>
											<Button
												type={BUTTON_TEXT}
												buttonClass={styles.dropdownItemBtn}
												iconSide={ICON_LEFT}
												icon={<PictureAsPdfOutlined htmlColor="#FFFFFF" />}
												label={t('buttons.download_pdf')}
												onClick={(event) => downloadPdfHandler(event)}
												isDisabled={generatingPdf}
												id="extended-memosite-modal-download-pdf"
											/>
										</div>
										<div key="notes" className={styles.notes}>
											{t('misc.downloads_dont_include_web_content')}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
					<div className={styles.labelsRow}>
						{selectedMemosite && (
							<MemositeLabelsManager
								labelsOpened={() => null}
								memosite={selectedMemosite}
								memosites={memosites}
								role="my_memosites"
							/>
						)}
						<div className={styles.actions}>
							<div
								className={styles.link}
								onClick={() => window.open(memositeLink, '_blank')}
								title={t('tooltips.open_memosite_in_new_tab')}
							>
								<div>{memositeLink}</div>
								<OpenInNew />
							</div>
							<div className={styles.buttons}>
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<Link htmlColor="#FFFFFF" />}
									label={t('misc.copy')}
									onClick={copyMemositeLink}
									id="extended-memosite-modal-copy-memosite-link"
								/>
								{user.user.id === memosite.creator && (
									<React.Fragment>
										<Button
											type={BUTTON_TEXT}
											buttonClass={styles.button}
											iconSide={ICON_LEFT}
											icon={<EmailOutlined htmlColor="#FFFFFF" />}
											label={t('buttons.email')}
											onClick={(e) => emailMemosite(e)}
											id="extended-memosite-modal-email-memosite"
										/>
										{crmSettings && (
											<Button
												type={BUTTON_TEXT}
												buttonClass={c(
													styles.button,
													memosite.crm &&
														memosite.crm.status === 1 &&
														styles.success,
													memosite.crm &&
														memosite.crm.status === 0 &&
														styles.failed
												)}
												iconSide={ICON_LEFT}
												icon={<CloudUploadOutlined htmlColor="#FFFFFF" />}
												label={t('titles.send-to-crm')}
												onClick={() => openCRMModal(memosite)}
												isDisabled={memosite.crm && memosite.crm.status === 1}
												id="extended-memosite-modal-send-to-crm"
											/>
										)}
									</React.Fragment>
								)}
							</div>
							<div className={styles.sent}>
								{memosite.crm &&
									`${t('misc.sent')} ${format(
										new Date(memosite.crm.created_at.replace(/\s/g, 'T')),
										'dd / MM / yyyy'
									)}`}
							</div>
						</div>
					</div>
					<Stats
						fullStats
						memosite={memosite}
						memositeAnalytics={memositeAnalytics}
					/>
					<div className={styles.contentRow}>
						<div className={styles.heading}>
							<div className={styles.labels}>
								<div className={styles.dFlex}>
									<div className={styles.sectionLabel}>
										{t('titles.content')}
									</div>
									<div>({t('titles.total-time-spent-on-slide')})</div>
								</div>
								{view === GRID && (
									<div className={styles.slidesCount}>{`${slides.length} ${t(
										'labels.slides'
									)}`}</div>
								)}
							</div>
							<div className={styles.buttons}>
								{view === LIST ? (
									<Button
										type={BUTTON_TEXT}
										buttonClass={styles.button}
										iconSide={ICON_LEFT}
										icon={<GridView htmlColor="#FFFFFF" />}
										label={t('labels.grid-view')}
										onClick={() => dispatch(setMemositeValue('view', 'grid'))}
										id="extended-memosite-modal-grid-view"
									/>
								) : (
									<Button
										type={BUTTON_TEXT}
										buttonClass={styles.button}
										iconSide={ICON_LEFT}
										icon={<ViewListOutlined htmlColor="#FFFFFF" />}
										label={t('labels.list-view')}
										onClick={() => dispatch(setMemositeValue('view', 'list'))}
										id="extended-memosite-modal-list-view"
									/>
								)}
								<Button
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									iconSide={ICON_LEFT}
									icon={<History htmlColor="#FFFFFF" />}
									label={t('labels.sessions')}
									onClick={() => setShowSessions(true)}
									id="extended-memosite-modal-sessions"
								/>
							</div>
						</div>
						<div>
							{loadingSlides && <Spinner isLoading={loadingSlides} />}
							<SlidesContainer pages={pages} slides={slides} />
						</div>
					</div>
				</div>
			)}
		</Modal>
	)
}

export default ExtendedMemositeModal
