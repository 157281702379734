import React, { useState } from 'react'
import Proptypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { toast } from 'react-toastify'

import {
	FLEX_DIRECTION_COL,
	FLEX_START,
	PRESENTATION_BAR,
	SUCCESS
} from '../../../utils/consts'
import { t } from '../../../utils/languages/i18n'
import Button from '../Button'
import Dropdown from '../Dropdown'
import Modal from '../ModalNew'
import Section from '../Section'
import styles from './MoveFileModal.module.scss'
import { moveMyFiles } from '../../../api/requests/content'
import Spinner from '../Spinner'
import { setMyFilesContent } from '../../../store/actions/myfiles'
import { clearTempPresentation } from '../../../store/actions/temp'

/**
 * @author zilahir
 * @function ConfirmDeleteModal
 * */

const MoveMyFileModal = ({ isShowing, handleClose }) => {
	const allMyFilesCategories = useSelector(
		(store) => store.myfiles.myFilesCategories
	)
	const currentMyFiles = useSelector((store) => store.myfiles.myFiles)
	const selectedFiles = useSelector(
		(store) => store.temp.slides[PRESENTATION_BAR.toLowerCase()]
	)
	const [targetCategory, setTargetCategroy] = useState(false)
	const [isLoading, toggleLoading] = useState(false)
	const dispatch = useDispatch()

	/**
	 *
	 * @description calls the file moving API
	 * shows notification
	 */
	function handleFileMove() {
		toggleLoading(true)
		const actionObject = {
			files: selectedFiles,
			targetCategory: targetCategory.id
		}
		moveMyFiles(actionObject).then(() => {
			toggleLoading(false)
			handleClose()
			const filteredMyFiles = currentMyFiles.filter((file) =>
				selectedFiles.some((selected) => selected.id !== file.id)
			)
			dispatch(setMyFilesContent(filteredMyFiles))
			dispatch(clearTempPresentation(PRESENTATION_BAR.toLowerCase()))
			toast(t('notifications.success.myfiles-moved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
		})
	}

	return (
		<Modal
			modalTitle={t('labels.moves')}
			hide={handleClose}
			customClassName={styles.moveMyFilesModal}
			isShowing={isShowing}
			contentClassName={styles.modalContent}
			footer={
				<>
					<Button
						label={t('buttons.cancel')}
						onClick={() => handleClose()}
						buttonClass={styles.btn}
						id="move-file-modal-cancel-button"
					/>
					<Button
						label={t('labels.move')}
						onClick={() => handleFileMove()}
						buttonClass={styles.btn}
						isPositive
						id="move-file-modal-move-button"
					/>
				</>
			}
		>
			<Section
				sectionClassName={styles.section}
				flexDirection={FLEX_DIRECTION_COL}
				justify={FLEX_START}
				alignItems={FLEX_START}
				padding="0 20px"
			>
				<p className={styles.desc}>{t('labels.select-category-move')}</p>
			</Section>
			<div
				className={classnames(
					styles.actionContainer,
					isLoading ? styles.hidden : ''
				)}
			>
				<Section
					sectionClassName={styles.section}
					flexDirection={FLEX_DIRECTION_COL}
					justify={FLEX_START}
					padding="20px"
				>
					<Dropdown
						listItems={allMyFilesCategories.map(({ id, name }) => ({
							value: name,
							key: id,
							label: name,
							id
						}))}
						onChangeCallback={(target) => setTargetCategroy(target)}
					/>
				</Section>
			</div>
			<div
				className={classnames(
					styles.loaderContainer,
					!isLoading ? styles.hidden : ''
				)}
			>
				<Spinner isLoading={isLoading} size={44} />
			</div>
		</Modal>
	)
}

MoveMyFileModal.propTypes = {
	handleClose: Proptypes.func.isRequired,
	isShowing: Proptypes.bool.isRequired
}

export default MoveMyFileModal
