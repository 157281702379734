import React from 'react'
import { useDispatch, useStore } from 'react-redux'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import { setMyFilesContent } from '../../store/actions/myfiles'
import { setTempValue } from '../../store/actions/temp'
import { unsubscribe, subscribe } from '../../utils/helpers/event'
import {
	addToPresentation,
	deleteByIndex,
	setPresentationBarSlides
} from '../../store/actions/presentation'
import { ModuleNamesInterface } from '../../utils/interfaces'

const SplitPresentationHandler = () => {
	const dispatch = useDispatch()
	const reduxStore = useStore()

	const splitStartedHandler = (content: any) => {
		// get message object from event
		const messageData = content.detail
		// retrieve splitFilesList from redux since
		const { splitFilesList } = reduxStore.getState().temp
		// find index of file which by reference where splitting is finished
		const currentSplitFileIndex = splitFilesList.findIndex(
			(item: any) => item.reference === messageData.source
		)
		if (currentSplitFileIndex > -1) {
			// get folder id of file where splitting is finished
			const folderId = splitFilesList[currentSplitFileIndex].folderId
			const keepOriginalFile =
				splitFilesList[currentSplitFileIndex].keepOriginalFile
			// remove file from splitFilesList by found index
			const newSplitFilesList = [...splitFilesList]
			newSplitFilesList.splice(currentSplitFileIndex, 1)
			dispatch(setTempValue('splitFilesList', newSplitFilesList))
			const { presentationBarSlides } = reduxStore.getState().presentation
			const slideIndex = presentationBarSlides.findIndex(
				(slide: any) => slide.reference === messageData.source
			)
			// add empty card for each slide from original pptx file
			const newEmptySplitSlideCards = _.range(0, messageData.content.total).map(
				(item) => ({
					index: item,
					folderId,
					reference: messageData.source,
					isInPresentationDeck: slideIndex > -1
				})
			)
			dispatch(setTempValue('emptySplitSlideCards', newEmptySplitSlideCards))
			// remove original file from My files by reference if keepOriginalFile is false
			if (!keepOriginalFile) {
				const { myFiles } = reduxStore.getState().myfiles
				dispatch(
					setMyFilesContent(
						_.orderBy(
							myFiles.filter(
								(myFile: any) => myFile.reference !== messageData.source
							),
							['sortorder'],
							['asc']
						)
					)
				)
			}
			// check if file exists in presentation deck
			if (slideIndex > -1) {
				// if file exists in presentation deck remove by index
				dispatch(deleteByIndex([slideIndex]))
				// if file is in presentation deck append empty presentations to array in deck
				dispatch(
					addToPresentation(
						_.range(0, messageData.content.total).map((item) => ({
							index: item,
							tags_id: folderId,
							reference: messageData.source,
							isLoading: true
						}))
					)
				)
			}
		}
	}

	const replaceSplitFile = (content: any) => {
		// get message object from event
		const messageData = content.detail
		// retrieve emptySplitSlideCards from redux store
		const { emptySplitSlideCards, currentModule } = reduxStore.getState().temp
		const currentFile = {
			...messageData.content,
			key: uuidV4()
		}
		// remove empty card
		const newEmptySplitSlideCards = [...emptySplitSlideCards].filter(
			(emptyCard) =>
				emptyCard.reference === messageData.source &&
				emptyCard.index !== messageData.index
		)
		dispatch(setTempValue('emptySplitSlideCards', newEmptySplitSlideCards))
		const { selectedCategory } = reduxStore.getState().buildContext
		if (
			currentModule === ModuleNamesInterface.MY_FILES &&
			selectedCategory.id === Number(currentFile.tags_id)
		) {
			const { myFiles } = reduxStore.getState().myfiles
			dispatch(
				setMyFilesContent(
					_.orderBy([...myFiles, currentFile], ['sortorder'], ['asc'])
				)
			)
		}
		const { presentationBarSlides } = reduxStore.getState().presentation
		const newPresentationsList = [...presentationBarSlides].map((item: any) => {
			try {
				if (
					item.reference === messageData.source &&
					item.index === messageData.index
				) {
					return messageData.content
				}
			} catch (error) {
				console.debug(error)
			}
			return item
		})
		dispatch(setPresentationBarSlides(newPresentationsList))
	}

	React.useEffect(() => {
		subscribe('split.started', splitStartedHandler)
		subscribe('split.slide', replaceSplitFile)

		return () => {
			unsubscribe('split.started', splitStartedHandler)
			unsubscribe('split.slide', replaceSplitFile)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div />
}

export default SplitPresentationHandler
