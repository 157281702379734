import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import { useSearchParams } from 'react-router-dom'

import styles from './Logo.module.scss'
import { createImageUrl } from '../../../utils/helpers/decideThumbanil'
import { titleVariants } from '../../../pages/StoryBoard/animations'

const LogoContainer = styled(motion.div)`
	background-image: url(${(props) => props.logoUrl});
	width: ${(props) => props.size}vw;
	height: ${(props) => props.size}vw;
	z-index: 1;
`

const Logo = () => {
	let [searchParams] = useSearchParams()

	const authToken = useSelector((state) => state.authUser.user.token)
	const { logoImage } = useSelector((state) => state.layout)
	const { tagBusinessRules, logoImage: reducerLogoImage } = useSelector(
		(state) => state.storyboardContext
	)
	const isUsedFromAdmin = searchParams.get('admin')

	const defineLogoImage = () => {
		if (tagBusinessRules['logo-image']) {
			return tagBusinessRules['logo-image']
		}
		if (!isEmpty(reducerLogoImage)) {
			const img =
				reducerLogoImage[
					Object.keys(reducerLogoImage)[
						Object.keys(reducerLogoImage).length - 1
					]
				]
			if (img && typeof img === 'string') {
				return img
			}
		}
		return logoImage.logoImageUrl
	}

	return (
		<>
			{logoImage.logoImageUrl && (
				<AnimatePresence>
					<LogoContainer
						exit="out"
						key={defineLogoImage()}
						variants={titleVariants}
						initial="initial"
						animate="animated"
						className={classnames(
							styles.logoContainer,
							styles[logoImage.logoPosition.replace('_', '').toLowerCase()],
							isUsedFromAdmin && styles.bottomMarginIncreased
						)}
						logoUrl={createImageUrl(defineLogoImage(), authToken)}
						size={Number.parseInt(logoImage.logoSize, 10) * 3}
					/>
				</AnimatePresence>
			)}
		</>
	)
}

export default Logo
