import React, { createRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './Templates.module.scss'

import Button from '../Button'
import { t } from '../../../../utils/languages/i18n'
import { StoreInterface } from '../../../../utils/interfaces'
import { useEventListener } from '../../../../utils/hooks/useEventListener'
import { addToPresentation } from '../../../../store/actions/presentation'

interface TemplateInterface {
	background: string
}

interface TemplateInterface {
	thumb: string
	name: string
	content: string
	business_rules: string | null
	id: number
}

interface TemplatesInterface {
	template: TemplateInterface
}

const TemplatePicture = styled.div<any>`
	background-image: url(${(props: TemplateInterface) => props.background});
	background-size: cover;
`
const Template = ({ template }: TemplatesInterface) => {
	const [isHovered, setHovered] = useState(false)
	const [openIframe, setopenIframe] = useState(false)
	const iframe = createRef<any>()
	const dispatch = useDispatch()
	const authToken = useSelector(
		(state: StoreInterface) => state.authUser.user.token
	)
	const refreshToken = useSelector(
		(state: StoreInterface) => state.authUser.user.refresh_token
	)
	/**
	 * @description handles response from customs slides
	 * if custom slides send closeApplication action, close the application
	 * and if sends newSlideCreated close add slide to presentation and close custom slides
	 * @param {object} event holds data which are sent from custom slides back to xenon
	 */
	function onMessage(event: any) {
		if (
			event.origin === 'https://customers.salesfra.me' ||
			event.origin.includes('.amplifyapp.com') ||
			event.origin === 'https://customslides.salesfra.me'
		) {
			if (event.data.action.includes('closeApplication')) {
				setopenIframe(false)
			}
			if (event.data.action.includes('newSlideCreated')) {
				dispatch(addToPresentation(event.data.data))
				setopenIframe(false)
			}
		}
	}

	useEventListener('message', onMessage, window)
	return (
		<>
			<TemplatePicture
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				background={template.thumb}
				className={styles.wrapper}
			>
				{isHovered && (
					<div className={styles.hover}>
						<div className={styles.button}>
							<Button
								label={t('labels.open')}
								variant="primary"
								hasRadius
								onClick={() => setopenIframe(true)}
								noPadding
								height={40}
								leftPadding={10}
								rightPadding={10}
							/>
						</div>
					</div>
				)}
			</TemplatePicture>
			{openIframe && (
				<div className={styles.modalContent}>
					<div
						style={{
							zIndex: 99999,
							position: 'absolute',
							top: 0,
							right: 0,
							bottom: 0,
							left: 0
						}}
					>
						<iframe
							ref={iframe}
							title="custom slides"
							src={`${process.env.REACT_APP_CUSTOM_SLIDES_URL}/index.html?${refreshToken}|||${authToken}|||user|||${template?.id}`}
							style={{
								width: '100%',
								height: '100%',
								border: 'none'
							}}
						/>
					</div>
				</div>
			)}
		</>
	)
}

export default Template
