import React, { useEffect, useState } from 'react'
import { format, isBefore } from 'date-fns'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
	DateRange,
	Search,
	FilterList,
	FilterListOff,
	CloseOutlined,
	ArrowDropDown,
	ArrowDropUp,
	Info as InfoIcon
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/styles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import styles from './MyMemosites.module.scss'

import {
	MeetingInterface,
	MeetingsResponseInterface,
	ModuleNamesInterface,
	SortEnum,
	SortingMemositesColumnEnum,
	StoreInterface
} from '../../utils/interfaces'
import { t } from '../../utils/languages/i18n'
import MemositeItem from './components/MemositeItem'
import Button from '../../components/common/Button'
import { BUTTON_TEXT, FOLLOWUP, ICON_LEFT } from '../../utils/consts'
import {
	createMemoSite,
	deleteMemosite,
	filterMemosites,
	getSingleMeetingBySlug
} from '../../api/requests/memosite'
import {
	resetMemositeReducer,
	setMemositeValue
} from '../../store/actions/memosite'
import { setTempValue } from '../../store/actions/temp'
import Pagination from './components/Pagination'
import { updatePresentationTitle } from '../../api/requests/presentations'
import Spinner from '../../components/common/Spinner'
import DeleteMemositeModal from './components/DeleteMemositeModal'
import Label from '../../components/common/Label'
import FilterLabelsDropdown from './components/FilterLabelsDropdown'
import { dateTimePickerThemeTransparent } from '../../utils/theme'
import { FilterValuesInterface } from './interfaces'
import { initialFilterState } from './emptyObjectDefinitions'
import Statistics from './components/Statistics'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import CRMIntegration from '../../components/CRMIntegration'
import { incrementIfEndsWithNumber } from '../../utils/helpers/memosites'
import {
	connectLabelToMeetingBulk,
	getAllLabels
} from '../../api/requests/labels'
import { getConfig } from '../../api/requests/config'

const memositesPerPage = 25

interface FilteredMemositesInterface {
	selectedPage: number
	dateStart?: MaterialUiPickersDate
	dateEnd?: MaterialUiPickersDate
	opened?: number
	sentToCrm?: number
	userId?: number
	searchTerm?: string
	sortingByColumn?: string
	sortDirection?: SortEnum
	labels?: number[]
}

const MyMemosites = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const { slug } = useParams()
	const slugHash = location.hash.replace('#', '')

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
		undefined
	)

	// delete memosite
	const [deleteMemositeModalVisible, toggleDeleteMemositeModalVisible] =
		useState<number | null>(null)
	const [disableDeleteButton, setDisableDeleteButton] = useState(false)

	const [selectedCRMMemosite, setSelectedCRMMemosite] =
		useState<MeetingInterface | null>(null)

	// FILTERS
	const [filterValues, setFilterValues] =
		useState<FilterValuesInterface>(initialFilterState)
	const [filterUsed, toggleFilterUsed] = useState(false)
	// filters - dropdown visibility
	const [openedFilterVisible, toggleOpenedFilterVisible] = useState(false)
	const [sentFilterVisible, toggleSentFilterVisible] = useState(false)
	const [labelsFilterVisible, toggleLabelsFilterVisible] = useState(false)

	// sorting
	const [sortingColumn, setSortingColumn] =
		useState<SortingMemositesColumnEnum>(SortingMemositesColumnEnum.CREATED_AT)

	// pagination
	const [page, setPage] = useState<number>(0)
	const [sort, setSort] = useState<SortEnum>(SortEnum.DESC)
	const [totalPages, setTotalPages] = useState<number>(0)

	// redux store
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { reloadMemosites } = useSelector((store: StoreInterface) => store.temp)
	const { memosites, selectedMemosite, selectedLabelsToFilter } = useSelector(
		(store: StoreInterface) => store.memosite
	)
	const { users } = useSelector((store: StoreInterface) => store.users)

	const handleMemositesResponse = (response: MeetingsResponseInterface) => {
		setTotalPages(Math.ceil(response.total / memositesPerPage))
		const memositeList: MeetingInterface[] = response.meetings.map(
			(item: MeetingInterface) => ({
				...item,
				numberOfSlides: JSON.parse(item.extended_fields)?.summary?.length || 0,
				expiry_date: JSON.parse(item.extended_fields)?.expiryDate || undefined
			})
		)
		dispatch(setMemositeValue('memosites', memositeList))
	}

	const changeSort = (sortColumn: any) => {
		const sortDirection =
			sortColumn === sortingColumn
				? sort === SortEnum.DESC
					? SortEnum.ASC
					: SortEnum.DESC
				: SortEnum.DESC
		setSort(sortDirection)
		setSortingColumn(sortColumn)
		getFilteredMemosites({
			selectedPage: 0,
			dateStart: filterValues.dateStart,
			dateEnd: filterValues.dateEnd,
			opened: filterValues.opened,
			sentToCrm: filterValues.sentToCrm,
			userId: selectedUserId,
			searchTerm: filterValues.searchTerm,
			sortingByColumn: sortColumn,
			sortDirection,
			labels:
				selectedLabelsToFilter.length > 0
					? selectedLabelsToFilter.map((item) => item.id)
					: undefined
		})
	}

	useEffect(() => {
		if (reloadMemosites) {
			dispatch(setTempValue('reloadMemosites', false))
			getFilteredMemosites({ selectedPage: 0 })
			getAllLabels().then((response) =>
				dispatch(setMemositeValue('labels', response))
			)
		}
		// reset memosite reducer when user leaves the page
		return () => {
			dispatch(resetMemositeReducer())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadMemosites])

	const fetchMemositeAllowViewConfig = () => {
		const isAllowedView = (val: string) => {
			if (val === 'all') {
				return true
			} else {
				if (val === 'admins' && user.user.role === 'Admin') {
					return true
				} else if (
					val === 'managers' &&
					['Admin', 'Manager'].includes(user.user.role)
				) {
					return true
				} else {
					return false
				}
			}
		}

		try {
			getConfig('memosite.allow.view').then((response) => {
				if (response !== null && typeof response.value === 'string') {
					dispatch(
						setMemositeValue('memositeAllowView', isAllowedView(response.value))
					)
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.FOLLOW_UP))
		fetchMemositeAllowViewConfig()
		getAllLabels().then((response) =>
			dispatch(setMemositeValue('labels', response))
		)
		if (slug || slugHash) {
			setIsLoading(true)
			getSingleMeetingBySlug(memositesPerPage, slug || slugHash)
				.then((response: MeetingsResponseInterface) => {
					setIsLoading(false)
					if (response.page) {
						setPage(response.page)
					}
					handleMemositesResponse(response)
					const memosite = response.meetings.find(
						(item: MeetingInterface) =>
							item.slug === slug || item.slug === slugHash
					)
					dispatch(setMemositeValue('selectedMemosite', memosite))
				})
				.catch(() => setIsLoading(false))
		} else {
			getFilteredMemosites({ selectedPage: 0 })
		}
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.FollowUp`
			})
		)
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.FollowUp`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updateMemositeSubjectHandler = (id: number, subject: string) => {
		updatePresentationTitle(
			{
				id,
				subject
			},
			user.token
		).then(() => {
			const updatedMemosites = memosites.map((item) =>
				item.id === id ? { ...item, subject } : item
			)
			dispatch(setMemositeValue('memosites', updatedMemosites))
		})
	}

	const handleOpenMemosite = (memosite: MeetingInterface) => {
		if (selectedMemosite?.id === memosite.id) {
			dispatch(setMemositeValue('selectedMemosite', undefined))
			navigate(`/${FOLLOWUP.toLowerCase()}`)
			return
		}
		dispatch(setMemositeValue('selectedMemosite', memosite))
		navigate(`/${FOLLOWUP.toLowerCase()}#${memosite.slug}`)
	}

	/**
	 * @param {string} fieldName is name of the field to determine caret location
	 * @description determine which caret should be show next to which field
	 * @returns {object} caret element
	 */
	const getSortingCaretIcon = (fieldName: string) => {
		return (
			<span>
				{sortingColumn === fieldName && (
					<React.Fragment>
						{sort === 'desc' ? (
							<ArrowDropDown className={styles.arrowIcon} />
						) : (
							<ArrowDropUp className={styles.arrowIcon} />
						)}
					</React.Fragment>
				)}
			</span>
		)
	}

	const deleteMemositeHandler = () => {
		deleteMemosite(user.token, deleteMemositeModalVisible)
			.then(() => {
				const newMemosites = memosites.filter(
					(item: any) => item.id !== deleteMemositeModalVisible
				)
				dispatch(setMemositeValue('memosites', newMemosites))
				toggleDeleteMemositeModalVisible(null)
				setDisableDeleteButton(false)
				if (selectedMemosite?.id === deleteMemositeModalVisible) {
					dispatch(setMemositeValue('selectedMemosite', undefined))
					navigate(`/${FOLLOWUP.toLowerCase()}`)
				}
				toast(t('notifications.success.memosite-deleted'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
			})
			.catch(() => {
				toggleDeleteMemositeModalVisible(null)
				setDisableDeleteButton(false)
			})
	}

	const duplicateMemositeHandler = async (memosite: MeetingInterface) => {
		const memositeObject = {
			...memosite,
			memo_opened: null,
			subject: memosite.subject.includes('copy')
				? incrementIfEndsWithNumber(memosite.subject)
				: memosite.subject + ' copy 01'
		}
		createMemoSite(memositeObject, user.token).then((response: any) => {
			if (memositeObject.labels && memositeObject.labels.length > 0) {
				const labelIds = memositeObject.labels.map((l) => l.id)
				connectLabelToMeetingBulk({
					meeting_id: response.id,
					labels: labelIds
				})
			}
			dispatch(setTempValue('reloadMemosites', true))
		})
	}

	const getFilteredMemosites = ({
		selectedPage,
		dateStart,
		dateEnd,
		opened,
		sentToCrm,
		userId,
		searchTerm,
		sortingByColumn,
		sortDirection,
		labels
	}: FilteredMemositesInterface) => {
		setPage(selectedPage || 0)
		setIsLoading(true)
		if (selectedLabelsToFilter.length > 0) {
			setFilterValues({
				...filterValues,
				labels: selectedLabelsToFilter.map((item) => item.id)
			})
		}
		filterMemosites(
			dateStart
				? `${format(dateStart, 'yyyy-MM-dd')} 00:00:00`
				: '1970-01-01%2000:00:00',
			dateEnd
				? `${format(dateEnd, 'yyyy-MM-dd')} 23:59:59`
				: `${format(new Date(), 'yyyy-MM-dd')} 23:59:59`,
			filterValues.opened,
			filterValues.sentToCrm,
			selectedPage,
			memositesPerPage,
			userId || 0,
			searchTerm || null,
			10,
			sortingByColumn || 'created_at',
			sortDirection || 'desc',
			labels || 0
		)
			.then((response) => {
				setIsLoading(false)
				handleMemositesResponse(response)
				setTotalPages(Math.ceil(response.total / memositesPerPage))
			})
			.catch((e) => console.error(e))
	}

	const resetFiltersHandler = () => {
		toggleFilterUsed(false)
		setFilterValues(initialFilterState)
		dispatch(setMemositeValue('selectedLabelsToFilter', []))
		getFilteredMemosites({ selectedPage: 0 })
	}

	const changeSelectedUserHandler = (userId: number) => {
		if (userId !== selectedUserId) {
			setPage(0)
		}
		setSelectedUserId(userId)
	}

	React.useEffect(() => {
		if (selectedUserId) {
			getFilteredMemosites({
				selectedPage: page,
				userId: selectedUserId
			})
			dispatch(setMemositeValue('selectedLabelsToFilter', []))
			setFilterValues(initialFilterState)
			toggleFilterUsed(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserId])

	const getSelectedUserName = () => {
		if (selectedUserId === user.user.id) {
			return t('labels.my-memosites')
		} else {
			const userObject = users.find((u) => u.id === selectedUserId)
			if (userObject) {
				return `${userObject.firstname} ${userObject.lastname} ${t(
					'labels.memosites'
				)}`
			}
			return t('labels.my-memosites')
		}
	}

	return (
		<div className={styles.wrapper}>
			<div>
				<div>
					<Statistics changeSelectedUser={changeSelectedUserHandler} />
					<div className={styles.heading}>
						<div className={styles.title}>{getSelectedUserName()}</div>
						<Button
							containerClass={styles.btnTextWrapper}
							buttonClass={styles.btnText}
							type={BUTTON_TEXT}
							iconSide={ICON_LEFT}
							icon={<InfoIcon />}
							onClick={() => null}
							id="my-memosites-featured-guide-button"
							tooltip={t('tooltips.quick_guide_for', [
								t('labels.my-memosites')
							])}
						/>
					</div>
					{!isLoading && (
						<div
							className={c(styles.filtersWrapper, filterUsed && styles.used)}
						>
							<div className={styles.filters}>
								<div className={styles.left}>
									<div className={c(styles.dates, styles.hoverable)}>
										<DateRange />
										<ThemeProvider theme={dateTimePickerThemeTransparent}>
											<MuiPickersUtilsProvider
												locale={enLocale}
												utils={DateFnsUtils}
											>
												<DatePicker
													variant="inline"
													format="dd / MM / yyyy"
													value={filterValues.dateStart || null}
													onChange={(value) => {
														if (
															value &&
															filterValues.dateEnd &&
															isBefore(
																new Date(value),
																new Date(filterValues.dateEnd)
															)
														) {
															setFilterValues({
																...filterValues,
																dateStart: value
															})
														} else {
															toast(
																t(
																	'notifications.warning.end_date_cant_be_earlier_than_start_date'
																),
																{
																	position: toast.POSITION.BOTTOM_RIGHT,
																	type: 'warning',
																	autoClose: 5000
																}
															)
														}
													}}
													emptyLabel={t('labels.no_start_date')}
													className={styles.datePicker}
													disableFuture
												/>
											</MuiPickersUtilsProvider>
										</ThemeProvider>
										<div> -</div>
										<ThemeProvider theme={dateTimePickerThemeTransparent}>
											<MuiPickersUtilsProvider
												locale={enLocale}
												utils={DateFnsUtils}
											>
												<DatePicker
													variant="inline"
													format="dd / MM / yyyy"
													value={filterValues.dateEnd || null}
													onChange={(value) => {
														if (filterValues.dateStart) {
															if (
																isBefore(
																	new Date(filterValues.dateStart || ''),
																	new Date(value || '')
																)
															) {
																setFilterValues({
																	...filterValues,
																	dateEnd: value
																})
															} else {
																toast(
																	t(
																		'notifications.warning.end_date_cant_be_earlier_than_start_date'
																	),
																	{
																		position: toast.POSITION.BOTTOM_RIGHT,
																		type: 'warning',
																		autoClose: 5000
																	}
																)
															}
														} else {
															setFilterValues({
																...filterValues,
																dateEnd: value
															})
														}
													}}
													emptyLabel={format(new Date(), 'dd / MM / yyyy')}
													className={styles.datePicker}
													disableFuture
												/>
											</MuiPickersUtilsProvider>
										</ThemeProvider>
									</div>
									<div
										onClick={() =>
											toggleOpenedFilterVisible(!openedFilterVisible)
										}
										className={styles.hoverable}
									>
										<div className={styles.label}>{t('labels.opened')}:</div>
										{filterValues.opened === 0
											? t('labels.all')
											: filterValues.opened === 1
											? t('labels.opened')
											: t('labels.not-opened')}
										<div
											className={c(
												styles.dropdown,
												openedFilterVisible && styles.visible
											)}
										>
											<div
												className={c(
													styles.item,
													filterValues.opened === 0 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('labels.all')}
													onClick={() =>
														setFilterValues({ ...filterValues, opened: 0 })
													}
													id="my-memosites-opened-all-button"
												/>
											</div>
											<div
												className={c(
													styles.item,
													filterValues.opened === 1 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('labels.opened')}
													onClick={() =>
														setFilterValues({ ...filterValues, opened: 1 })
													}
													id="my-memosites-opened-opened-button"
												/>
											</div>
											<div
												className={c(
													styles.item,
													filterValues.opened === 2 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('labels.not-opened')}
													onClick={() =>
														setFilterValues({ ...filterValues, opened: 2 })
													}
													id="my-memosites-opened-not-opened-button"
												/>
											</div>
										</div>
									</div>
									<div
										onClick={() => toggleSentFilterVisible(!sentFilterVisible)}
										className={styles.hoverable}
									>
										<div className={styles.label}>
											{t('buttons.sent-to-crm')}:
										</div>
										{filterValues.sentToCrm === 0
											? t('labels.all')
											: filterValues.sentToCrm === 1
											? t('misc.sent')
											: t('misc.not-sent')}
										<div
											className={c(
												styles.dropdown,
												sentFilterVisible && styles.visible
											)}
										>
											<div
												className={c(
													styles.item,
													filterValues.sentToCrm === 0 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('labels.all')}
													onClick={() =>
														setFilterValues({ ...filterValues, sentToCrm: 0 })
													}
													id="my-memosites-sent-all-button"
												/>
											</div>
											<div
												className={c(
													styles.item,
													filterValues.sentToCrm === 1 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('misc.sent')}
													onClick={() =>
														setFilterValues({ ...filterValues, sentToCrm: 1 })
													}
													id="my-memosites-sent-sent-button"
												/>
											</div>
											<div
												className={c(
													styles.item,
													filterValues.sentToCrm === 2 && styles.selected
												)}
											>
												<Button
													type={BUTTON_TEXT}
													buttonClass={styles.button}
													label={t('misc.not-sent')}
													onClick={() =>
														setFilterValues({ ...filterValues, sentToCrm: 2 })
													}
													id="my-memosites-sent-not-sent-button"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className={styles.right}>
									<div className={styles.search}>
										<div className={styles.searchInput}>
											<input
												type="text"
												placeholder={t(
													'input-placeholders.filter_by_search_term'
												)}
												value={filterValues.searchTerm}
												onChange={(event) => {
													setFilterValues({
														...filterValues,
														searchTerm: event.target.value
													})
												}}
												onKeyDown={(e: any) => {
													if (e.key === 'Enter') {
														toggleFilterUsed(true)
													}
												}}
											/>
											{filterValues.searchTerm.length > 0 ? (
												<CloseOutlined
													onClick={() =>
														setFilterValues({
															...filterValues,
															searchTerm: ''
														})
													}
												/>
											) : (
												<Search />
											)}
										</div>
									</div>
								</div>
							</div>
							<div className={styles.secondRow}>
								<div className={styles.labelsWrapper}>
									<div
										className={styles.label}
										onClick={() =>
											toggleLabelsFilterVisible(!labelsFilterVisible)
										}
									>
										{t('labels.labels')}:
									</div>
									<div
										className={styles.labelsList}
										onClick={() =>
											toggleLabelsFilterVisible(!labelsFilterVisible)
										}
									>
										{selectedLabelsToFilter.length > 0 ? (
											selectedLabelsToFilter.map((label, index) => (
												<Label
													bgColor={label.color}
													name={label.name}
													key={index}
												/>
											))
										) : (
											<p>{t('labels.no-labels-selected')}</p>
										)}
									</div>
									{labelsFilterVisible && (
										<FilterLabelsDropdown
											onClose={() => {
												toggleLabelsFilterVisible(false)
												setFilterValues({
													...filterValues,
													labels: selectedLabelsToFilter.map((item) => item.id)
												})
											}}
										/>
									)}
								</div>
								<div className={styles.filterButtons}>
									{filterUsed && (
										<div
											onClick={resetFiltersHandler}
											id="my-memosites-reset-filters"
										>
											<FilterListOff />
											{t('labels.reset-filters')}
										</div>
									)}
									{filterValues !== initialFilterState && (
										<div
											onClick={() => {
												toggleFilterUsed(true)
												getFilteredMemosites({
													selectedPage: 0,
													dateStart: filterValues.dateStart,
													dateEnd: filterValues.dateEnd,
													opened: filterValues.opened,
													sentToCrm: filterValues.sentToCrm,
													userId: selectedUserId,
													searchTerm: filterValues.searchTerm,
													sortingByColumn: sortingColumn,
													sortDirection: sort,
													labels:
														selectedLabelsToFilter.length > 0
															? selectedLabelsToFilter.map((item) => item.id)
															: undefined
												})
											}}
											id="my-memosites-apply-filters"
										>
											<FilterList />
											{t('labels.apply-filters')}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{isLoading ? (
						<div className={styles.spinnerWrapper}>
							<Spinner isLoading={isLoading} />
						</div>
					) : (
						<React.Fragment>
							<div className={styles.myMemositesList}>
								<div className={styles.heading}>
									<div className={styles.title} />
									<div className={styles.slides}>{t('labels.slides')}</div>
									<div className={styles.dateExpires}>
										{t('labels.expires')}
									</div>
									<div
										className={c(styles.dateCreated, styles.sortable)}
										onClick={sort ? () => changeSort('created_at') : () => null}
									>
										{t('labels.date-created')}
										{getSortingCaretIcon('created_at')}
									</div>
									<div
										className={c(styles.dateLastOpened, styles.sortable)}
										onClick={
											sort ? () => changeSort('memo_opened') : () => null
										}
									>
										{`${t('labels.last-opened')} (${
											memosites.filter((m) => m.memo_opened !== null).length
										}/${memosites.length})`}
										{getSortingCaretIcon('memo_opened')}
									</div>
								</div>
								<div className={styles.listContainer}>
									{memosites.length > 0 ? (
										memosites?.map((item, index) => (
											<MemositeItem
												handleOpenMemosite={handleOpenMemosite}
												memosite={item}
												memositeToDelete={toggleDeleteMemositeModalVisible}
												memositeToDuplicate={duplicateMemositeHandler}
												key={index}
												openCRMModal={setSelectedCRMMemosite}
												updateMemositeSubject={updateMemositeSubjectHandler}
											/>
										))
									) : (
										<p className={styles.noMemositesFound}>
											{t('misc.no_memosites_found_try_adjusting_your_filters')}
										</p>
									)}
									{memosites.length > 0 && (
										<Pagination
											changePage={(pageToSet) => {
												getFilteredMemosites({
													selectedPage: pageToSet.selected,
													dateStart: filterValues.dateStart,
													dateEnd: filterValues.dateEnd,
													opened: filterValues.opened,
													sentToCrm: filterValues.sentToCrm,
													userId: selectedUserId,
													searchTerm: filterValues.searchTerm,
													sortingByColumn: sortingColumn,
													sortDirection: sort,
													labels:
														selectedLabelsToFilter.length > 0
															? selectedLabelsToFilter.map((item) => item.id)
															: undefined
												})
											}}
											memositePaginationPage={page}
											numberOfPages={totalPages}
										/>
									)}
								</div>
							</div>
						</React.Fragment>
					)}
					<DeleteMemositeModal
						deleteMemosite={deleteMemositeHandler}
						disableDeleteButton={disableDeleteButton}
						handleClose={() => toggleDeleteMemositeModalVisible(null)}
						isActive={!!deleteMemositeModalVisible}
					/>
					{selectedCRMMemosite && (
						<CRMIntegration
							memosite={selectedCRMMemosite}
							setMemosite={setSelectedCRMMemosite}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default MyMemosites
