import {
	AUTH_USER,
	CHANGE_AUTH_OBJECT,
	REMOVE_USER,
	SET_INSTANCES
} from '../actions/actionTypes'

const initialState = {
	user: {
		authSuccess: false,
		username: {}
	},
	instances: []
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case AUTH_USER:
			return {
				...state,
				user: action.payload.user
			}
		case REMOVE_USER:
			return initialState
		case CHANGE_AUTH_OBJECT:
			return {
				...state,
				user: {
					...state.user,
					token: action.payload.accessToken,
					refresh_token: action.payload.refreshToken,
					authSuccess: true
				}
			}
		case SET_INSTANCES:
			return {
				...state,
				instances: action.payload.instances
			}
		default:
			return state
	}
}

export default reducer
