import React from 'react'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

const JSONEditor = ({
	setJsonSrc,
	jsonSrc,
	onChange
}) => {
	return (
		<ReactJson
			src={jsonSrc}
			style={{
				width: '100%',
			}}
			theme="paraiso"
			onEdit={edited => {
				setJsonSrc(edited.updated_src)
				onChange(true)
			}}
			onAdd={added => {
				setJsonSrc(added.updated_src)
				onChange(true)}
			}
			onDelete={deleted => {
				setJsonSrc(deleted.updated_src)
				onChange(true)}
			}
		/>
	)
}

JSONEditor.defaultProps = {
	onChange: null,
	setJsonSrc: null,
}

JSONEditor.propTypes = {
	jsonSrc: PropTypes.objectOf(
		PropTypes.any,
	).isRequired,
	onChange: PropTypes.func,
	setJsonSrc: PropTypes.func
}

export default JSONEditor
