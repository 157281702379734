import { omit } from 'lodash'

import {
	SET_SELECTED_TAG,
	SET_TAG_BUSINESS_RULES,
	SET_TAG_GALLERY_OPTIONS,
	RESET_STORYBOARDCONTEXT,
	SET_CURRENT_BACKGROUND_IMAGE,
	SET_INITIAL_BACKGROUND_IMAGE,
	SET_CURRENT_LOGO_IMAGE,
	SET_INITIAL_LOGO_IMAGE
} from '../actions/storyBoardContext'

const initialState = {
	selectedTag: {},
	tagBusinessRules: {},
	tagGalleryOptions: {},
	backgroundImage: {},
	logoImage: {}
}

const reducer = (state: any = initialState, action: any) => {
	switch (action.type) {
		case SET_SELECTED_TAG:
			return {
				...state,
				selectedTag: action.payload.selectedTag
			}
		case SET_TAG_BUSINESS_RULES:
			return {
				...state,
				tagBusinessRules: action.payload.tagBusinessRules
			}
		case SET_TAG_GALLERY_OPTIONS:
			return {
				...state,
				tagGalleryOptions: action.payload.tagGalleryOptions
			}
		case SET_CURRENT_BACKGROUND_IMAGE: {
			const toOmit = Object.keys(state.backgroundImage).filter(
				(key) => Number.parseInt(key, 10) > action.payload.level
			)

			const filtered = Object.assign(omit(state.backgroundImage, [...toOmit]), {
				[action.payload.level]: action.payload.backgroundImage
			})
			return {
				...state,
				backgroundImage: filtered
			}
		}
		case SET_INITIAL_BACKGROUND_IMAGE: {
			const filtered: any = Object.keys(state.backgroundImage).filter(
				(key) => key === '0'
			)
			return {
				...state,
				backgroundImage: {
					0: state.backgroundImage[filtered]
				}
			}
		}
		case SET_CURRENT_LOGO_IMAGE: {
			const toOmit = Object.keys(state.logoImage).filter(
				(key) => Number.parseInt(key, 10) > action.payload.level
			)

			const filtered = Object.assign(omit(state.logoImage, [...toOmit]), {
				[action.payload.level]: action.payload.logoImage
			})
			return {
				...state,
				logoImage: filtered
			}
		}
		case SET_INITIAL_LOGO_IMAGE: {
			const filtered: any = Object.keys(state.logoImage).filter(
				(key) => key === '0'
			)
			return {
				...state,
				logoImage: {
					0: state.logoImage[filtered]
				}
			}
		}
		case RESET_STORYBOARDCONTEXT:
			return {
				...initialState,
				backgroundImage: state.backgroundImage,
				logoImage: state.logoImage
			}
		default:
			return state
	}
}

export default reducer
