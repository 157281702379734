import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

import SfModal from '../../../../components/common/ModalNew'
import styles from './Embedded.module.scss'

const Embedded = ({ isOpen, embeddedUrl, handleClose, title }) => {
	const [isFullScreen, setIsFullScreen] = useState(false)

	return (
		<>
			<SfModal
				isShowing={isOpen}
				hide={handleClose}
				disableOverlayClose
				overlayClassName={styles.overlay}
				modalClassName={
					isFullScreen ? styles.embedFullPageModal : styles.embedModal
				}
				customClassName={
					isFullScreen
						? styles.embedFullPageModalContent
						: styles.embedModalContent
				}
				headerClassname={styles.embedModalHeader}
				selector={document.querySelector('#slidingpane')}
				hasCloseIcon={false}
			>
				<div
					className={styles.fullPageButtonContainer}
					onClick={() => setIsFullScreen(!isFullScreen)}
					role="button"
					onKeyDown={null}
					tabIndex={-1}
				>
					{isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
				</div>
				{embeddedUrl && (
					<iframe
						rel="noopener noreferrer"
						title={title}
						src={embeddedUrl}
						className={styles.embeddedFrame}
					/>
				)}
			</SfModal>
		</>
	)
}

Embedded.defaultProps = {
	embeddedUrl: false,
	handleClose: () => {}
}

Embedded.propTypes = {
	embeddedUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	handleClose: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired
}

export default Embedded
