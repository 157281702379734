import React, { useState } from 'react'
import {
	CloudDoneOutlined,
	LockOutlined,
	MoreHoriz,
	Launch,
	Link,
	EmailOutlined,
	CloudUploadOutlined,
	DeleteOutlined,
	SettingsOutlined,
	AutoDeleteOutlined,
	EditOutlined,
	ContentCopy
} from '@mui/icons-material'
import c from 'classnames'
import { differenceInHours, format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import copy from 'copy-text-to-clipboard'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import styles from './MemositeItem.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { BUTTON_TEXT, ICON_LEFT } from '../../../../utils/consts'
import Button from '../../../../components/common/Button'
import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import { getMemositeLink } from '../../../../utils/helpers/memosites'
import { setMemositeValue } from '../../../../store/actions/memosite'
import ExtendedMemositeModal from '../ExtendedMemositeModal'
import AutomatedDeletionModal from '../../components/AutomatedDeletionModal'
import { resetMemositeDeletion } from '../../../../api/requests/memosite'
import { store as reducerStore } from '../../../../store/configureStore'
import { setMemositeBuilderValue } from '../../../../store/actions/memositeBuilder'

const LabelCircle = styled('div')`
	background-color: ${(props: { color: string }) => props.color};
`

interface MemositeItemInterface {
	handleOpenMemosite: (memosite: MeetingInterface) => void
	memosite: MeetingInterface
	memositeToDelete: (id: number) => void
	memositeToDuplicate: (memosite: MeetingInterface) => void
	openCRMModal: (memosite: MeetingInterface) => void
	updateMemositeSubject: (id: number, subject: string) => void
}

const MemositeItem = ({
	handleOpenMemosite,
	memosite,
	memositeToDelete,
	memositeToDuplicate,
	openCRMModal,
	updateMemositeSubject
}: MemositeItemInterface) => {
	const dispatch = useDispatch()

	const hoursToDeletion = memosite.for_delete
		? differenceInHours(Date.parse(memosite.for_delete), new Date())
		: null

	const [automatedDeletionModalVisible, toggleAutomatedDeletionModalVisible] =
		useState(false)

	const { instanceName, emailBcc, crmSettings } = useSelector(
		(store: StoreInterface) => store.temp
	)
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { selectedMemosite, memositeDropdownOpened } = useSelector(
		(store: StoreInterface) => store.memosite
	)
	const memositeBuilderStatus = useSelector(
		(store: StoreInterface) => store.memositeBuilder.status
	)

	const memositeLink = getMemositeLink(
		instanceName,
		memosite.slug,
		user.user.role,
		true
	)

	const dropdown = () => (
		<div className={styles.dropdown}>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<EditOutlined htmlColor="#FFFFFF" />}
					label={t('buttons.edit_memosite')}
					onClick={() => {
						dispatch(setMemositeBuilderValue('status', 'editing'))
						dispatch(setMemositeValue('selectedMemosite', memosite))
					}}
					id="memosite-item-edit-memosite-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<ContentCopy htmlColor="#FFFFFF" />}
					label={t('labels.duplicate')}
					onClick={() => {
						memositeToDuplicate(memosite)
						dispatch(setMemositeValue('memositeDropdownOpened', undefined))
					}}
					id="memosite-item-duplicate-memosite-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<Launch htmlColor="#FFFFFF" />}
					label={t('labels.open-link')}
					onClick={() => window.open(memositeLink, '_blank')}
					id="memosite-item-open-link-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<Link htmlColor="#FFFFFF" />}
					label={t('labels.copy-link')}
					onClick={copyMemositeLink}
					id="memosite-item-copy-link-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<EmailOutlined htmlColor="#FFFFFF" />}
					label={t('labels.email-link')}
					onClick={(e) => emailMemosite(e)}
					id="memosite-item-email-link-button"
				/>
			</div>
			{crmSettings && (
				<div className={styles.item}>
					<Button
						type={BUTTON_TEXT}
						buttonClass={c(
							styles.button,
							memosite.crm && memosite.crm.status === 1 && styles.success,
							memosite.crm && memosite.crm.status === 0 && styles.failed
						)}
						iconSide={ICON_LEFT}
						icon={<CloudUploadOutlined htmlColor="#FFFFFF" />}
						label={t('titles.send-to-crm')}
						onClick={() => openCRMModal(memosite)}
						isDisabled={memosite.crm && memosite.crm.status === 1}
						id="memosite-item-send-to-crm-button"
					/>
				</div>
			)}
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<DeleteOutlined htmlColor="#FFFFFF" />}
					label={t('labels.delete-memosite')}
					onClick={() => memositeToDelete(memosite.id)}
					id="memosite-item-delete-memosite-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<SettingsOutlined htmlColor="#FFFFFF" />}
					label={t('labels.memosite-settings')}
					onClick={() => {
						dispatch(setMemositeBuilderValue('settingsModalVisible', true))
						dispatch(setMemositeBuilderValue('settingsModalData', memosite))
					}}
					id="memosite-item-memosite-settings-button"
				/>
			</div>
		</div>
	)

	const copyMemositeLink = () => {
		copy(memositeLink)
		toast(t('notifications.success.link-copied'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: 'success',
			autoClose: 5000
		})
	}

	const emailMemosite = (e: MouseEvent) => {
		e.stopPropagation()
		const memositeUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			user.user.role,
			true
		)
		const link = `mailto:insert email here?${
			emailBcc && `bcc=${emailBcc}&`
		}subject=Meeting memo&body=Hello,%20I%20created%20a%20memosite%20for%20you:%0D%0A%0D%0A${memositeUrl}`
		window.open(link, '_blank')
	}

	const resetMemositeDeletionHandler = () => {
		resetMemositeDeletion(memosite.id)
			.then((resp: any) => {
				const memosites = reducerStore.getState().memosite.memosites
				if (Array.isArray(memosites)) {
					const updatedMemosites = memosites.map((memo: MeetingInterface) =>
						memo.id === memosite.id
							? { ...memo, for_delete: resp.for_delete }
							: memo
					)
					dispatch(setMemositeValue('memosites', updatedMemosites))
				}
				toggleAutomatedDeletionModalVisible(false)
			})
			.catch((e) => {
				console.error(e)
				toggleAutomatedDeletionModalVisible(false)
			})
	}

	return (
		<div className={c(styles.wrapper)} id={`memosite-item-${memosite.id}`}>
			<div
				className={c(styles.baseView, styles.withBg)}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					if (memositeBuilderStatus !== 'editing') {
						handleOpenMemosite(memosite)
					}
				}}
			>
				<div className={styles.icons}>
					{memosite.is_secured && (
						<LockOutlined
							htmlColor="#88b0ff"
							titleAccess={t('tooltips.password_protected')}
						/>
					)}
					{memosite.crm && (
						<CloudDoneOutlined
							htmlColor={memosite.crm.status === 1 ? '#88b0ff' : '#ff8c24'}
							titleAccess={t('tooltips.sent_to_crm')}
						/>
					)}
				</div>
				<div className={styles.title}>
					<React.Fragment>
						<div>{memosite.subject}</div>
						<div className={styles.labels}>
							{memosite.labels.length > 0 &&
								memosite.labels.map((label, index) => (
									<LabelCircle
										key={index}
										color={label.color}
										className={styles.labelCircle}
										title={label.name}
									/>
								))}
						</div>
					</React.Fragment>
				</div>
				{hoursToDeletion && (
					<div
						className={c(
							styles.deletion,
							Math.round(hoursToDeletion / 24) < 30 ? styles.warning : ''
						)}
						title={t('tooltips.time-left-until-automatic-deletion')}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							toggleAutomatedDeletionModalVisible(true)
						}}
					>
						<AutoDeleteOutlined />
						{`${
							hoursToDeletion === 0 || hoursToDeletion < 24
								? '<1'
								: Math.round(hoursToDeletion / 24)
						}d`}
					</div>
				)}
				{user.user.id === memosite.creator && (
					<div
						className={styles.menu}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							dispatch(
								setMemositeValue(
									'memositeDropdownOpened',
									memositeDropdownOpened === memosite.id
										? undefined
										: memosite.id
								)
							)
						}}
					>
						<MoreHoriz
							className={c(
								styles.icon,
								memositeDropdownOpened === memosite.id && styles.zIndex9
							)}
							titleAccess={t('tooltips.actions')}
							id="memosite-item-actions-button"
						/>
						{memositeDropdownOpened === memosite.id && dropdown()}
					</div>
				)}
				<div className={styles.slides}>{memosite.numberOfSlides}</div>
				<div className={styles.date}>
					{memosite.expiry_date &&
						typeof memosite.expiry_date === 'string' &&
						format(new Date(memosite.expiry_date), 'dd / MM / yyyy')}
				</div>
				<div className={c(styles.date, styles.createdDate)}>
					{format(new Date(memosite.created_at), 'dd / MM / yyyy')}
				</div>
				<div className={c(styles.date, styles.lastOpened)}>
					{memosite.memo_opened ? (
						<React.Fragment>
							{memosite.memo_opened === 'no_data' ? (
								t('labels.data-not-available')
							) : (
								<p className={styles.lastOpenedDate}>
									{format(
										new Date(memosite.memo_opened.replace(/\s/g, 'T')),
										'dd / MM / yyyy'
									)}
								</p>
							)}
						</React.Fragment>
					) : (
						<p className={styles.notYetOpened}>{t('labels.not-opened')}</p>
					)}
				</div>
			</div>
			{selectedMemosite?.id === memosite.id &&
				memositeBuilderStatus !== 'editing' && (
					<ExtendedMemositeModal
						copyMemositeLink={copyMemositeLink}
						emailMemosite={emailMemosite}
						memosite={memosite}
						memositeLink={memositeLink}
						memositeToDelete={memositeToDelete}
						memositeToDuplicate={memositeToDuplicate}
						openCRMModal={openCRMModal}
						toggleSettingsModalVisible={() => {
							dispatch(setMemositeBuilderValue('settingsModalVisible', true))
							dispatch(setMemositeBuilderValue('settingsModalData', memosite))
						}}
						updateMemositeSubject={updateMemositeSubject}
					/>
				)}
			{automatedDeletionModalVisible && (
				<AutomatedDeletionModal
					action={resetMemositeDeletionHandler}
					closeModal={() => toggleAutomatedDeletionModalVisible(false)}
					hoursToDeletion={hoursToDeletion}
					isVisible={automatedDeletionModalVisible}
				/>
			)}
		</div>
	)
}

export default MemositeItem
