import { forEach } from 'lodash'
import { nanoid } from 'nanoid'

import {
	ADD_TO_PRESENTATION,
	CLEAR_PRESENTATION,
	DELETE_FROM_PRESENTATION,
	DUPLICATE_BY_ID,
	REMOVE_BY_INDEX,
	REMOVE_ALL_LOADING_THUMBNAILS,
	CREATE_SLIDESHOW,
	CLEAR_SLIDESHOW,
	SET_LOADED_PRESENTATION,
	REORDER_PRESENTATION_BAR_SLIDES,
	DELETE_BY_CHECKSUM,
	SET_LOADED_PRESENTATION_DIRTY,
	SET_PRESENTATION_BAR_SLIDES
} from './actionTypes'
import { t } from '../../utils/languages/i18n'
import { addToAnalyticsBatch } from './analytics'

export const addToPresentation = (slides: any) => (dispatch: any) => {
	const slidesToAdd = slides.map((slide: any) => ({
		...slide,
		key: nanoid()
	}))
	dispatch({
		type: ADD_TO_PRESENTATION,
		payload: {
			slides: slidesToAdd
		}
	})
}

export const clearPresentation = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_PRESENTATION,
		payload: null
	})
}

export const deleteSlideByChecksum = (checksum: string) => (dispatch: any) => {
	dispatch({
		type: DELETE_BY_CHECKSUM,
		payload: {
			checksum
		}
	})
}

export const deleteById = (idToDelete: any) => (dispatch: any) => {
	dispatch({
		type: DELETE_FROM_PRESENTATION,
		payload: {
			idToDelete
		}
	})
}

export const duplicateById =
	(selectedSlides: any, lastSelectedIndex: any) => (dispatch: any) => {
		dispatch({
			type: DUPLICATE_BY_ID,
			payload: {
				selectedSlides,
				lastSelectedIndex
			}
		})
	}

export const deleteByIndex = (indexes: any) => (dispatch: any) => {
	dispatch({
		type: REMOVE_BY_INDEX,
		payload: {
			indexes
		}
	})
}

export const addLoadingThumbnail =
	(numOfLoadingSlides: any) => (dispatch: any) => {
		// @ts-ignore
		const loadingThumbnails = new Array(numOfLoadingSlides).fill().map(() => ({
			isLoading: true,
			name: t('misc.loading'),
			id: 0,
			key: nanoid()
		}))
		dispatch({
			type: ADD_TO_PRESENTATION,
			payload: {
				slides: [...loadingThumbnails]
			}
		})
	}

export const removeAllLoadingThumbnai = () => (dispatch: any) => {
	dispatch({
		type: REMOVE_ALL_LOADING_THUMBNAILS,
		payload: null
	})
}

export const createSlideShow = (slideShowSlides: any) => (dispatch: any) => {
	const { slides } = slideShowSlides
	const analyticsBatch = slides.map((fileItem: any) => ({
		origin: fileItem._type,
		event: 'presentation.library.filePresented',
		...fileItem
	}))
	forEach(analyticsBatch, (item) => {
		dispatch(addToAnalyticsBatch(item))
	})
	dispatch({
		type: CREATE_SLIDESHOW,
		payload: {
			slideShowSlides: slides,
			startFrom: slideShowSlides.startFrom
		}
	})
}

export const clearSlideShow = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_SLIDESHOW,
		payload: null
	})
}

export const setLoadedPresentation = (presentation: any) => (dispatch: any) => {
	dispatch({
		type: SET_LOADED_PRESENTATION,
		payload: {
			presentation
		}
	})
}

export const reorderPresentationSlides =
	(reorderedList: any) => (dispatch: any) => {
		dispatch({
			type: REORDER_PRESENTATION_BAR_SLIDES,
			payload: {
				presentationBarSlides: reorderedList
			}
		})
	}

export const setLoadedPresentationDirty =
	(value: boolean) => (dispatch: any) => {
		dispatch({
			type: SET_LOADED_PRESENTATION_DIRTY,
			payload: {
				value
			}
		})
	}

export const setPresentationBarSlides = (slides: any) => (dispatch: any) => {
	dispatch({
		type: SET_PRESENTATION_BAR_SLIDES,
		payload: {
			slides
		}
	})
}
