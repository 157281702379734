import React, { useEffect, useRef, useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import { ReactSVG } from 'react-svg'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { has, isEmpty, isEqual } from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import StopIcon from '@mui/icons-material/Stop'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { motion } from 'framer-motion'

import styles from './Layout.module.scss'
import Button from '../../components/common/Button'
import { ChevronLeft } from '../../icons/chevronLeft'
import StoryBoard from '../StoryBoard'
import InputRange from '../../components/common/InputRange'
import ColorPicker from '../../components/common/ColorPicker'
import Tooltip from '../../components/common/Tooltip'
import UploadContent from '../../components/common/UploadContent'
import {
	BUTTON_TEXT,
	FONT_WEIGHTS,
	GALLERY_SHAPE_BOX,
	GALLERY_SHAPE_CIRCLE,
	GALLERY_SHAPE_NONE,
	GALLERY_SHAPE_ROUNDEDN_BOX,
	GALLERY_SHAPES,
	HORIZONTAL,
	LAYOUT,
	LOGO_ALIGNMENTS,
	UPLOAD_BUTTON,
	VERTICAL
} from '../../utils/consts'
import { t } from '../../utils/languages/i18n'
import Toggle from '../../components/common/Toggle'
import ButtonGroup from '../../components/common/ButtonGroup'
import CheckBox from '../../components/common/CheckBox'
import Input from '../../components/common/Input'
import GalleryOptions from './components/GalleryOptions'
import FolderOptions from './components/FolderOptions'
import {
	changeLogoPosition,
	clearLogoImage,
	removeBackgroundImage,
	setBackgroundColor,
	setBackgroundImageOpacity,
	setContentTabColor,
	setContentTabOpacity,
	setLandingPageStyle,
	setLandingPageTitle,
	setLayout,
	setLogoSize,
	setNavigationStyle,
	setPresentationDeckColor,
	setPresentationDeckFontColor,
	setRootLevelGalleryItemShape,
	setRootLevelGalleryItemsPerRow,
	setRootLevelGalleryWidth,
	setRootLevelPaddingBetweenIems,
	setRootLevelShapeBackgroundColor,
	setRootLevelShapeShadow,
	setRootLevelTextShadow,
	setRootLevelUseFolderIcons,
	setStoryBoardFont,
	setStoryBoardFontColor,
	setStoryBoardFontSize,
	setStoryBoardFontWeight,
	setStoryBoardTitleFont,
	setStoryBoardTitleFontWeight,
	setTitleUnderline,
	setTitleUnderlineColor,
	toggleBreadcrumbsVisible,
	toggleLandingPage,
	togglePresentationDeckShadow,
	toggleRootLevelGallery,
	toggleShrinkPreviousLevel,
	toggleStoryBoardFolderSearch,
	toggletParallaxScrollingBackground,
	uploadBackroundImage,
	uploadLogo
} from '../../store/actions/layout'
import { updateLayout } from '../../api/requests/layout'
import ConfirmDeleteModal from '../../components/common/ConfirmDeleteModal'
import {
	resetStoryBoardContext,
	setCurrentBackgroundImage,
	setTagBusinessRules,
	setTagGalleryOptions
} from '../../store/actions/storyBoardContext'
import {
	setGalleryOptionsShallowCopy,
	setLayoutShallowCopy,
	setTempValue
} from '../../store/actions/temp'
import { folderSettings } from '../../utils/layoutSettings'
import RadioGroup from '../../components/common/RadioGroup'
import { BoxIcon } from '../../icons/box'
import LoadingOverlay from '../../components/common/LoadingOverlay'
import Dropdown from '../../components/common/Dropdown'
import { defaultDropdownStyles } from '../../components/common/Dropdown/utils/styles'
import FontPicker from './components/FontPicker'
import { getAllUserGroups } from '../../store/actions/users'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import PresentationBar from '../../components/PresentationBar'

const dropdownStyles = {
	...defaultDropdownStyles,
	control: () => ({
		...defaultDropdownStyles.control
	}),
	menu: (provided: any) => ({
		...defaultDropdownStyles.menu(provided),
		zIndex: 999
	})
}

const rootLevelGaleryVariants = {
	visible: {
		y: 0,
		height: 'auto',
		opacity: 1
	},
	hidden: {
		y: 10,
		height: 0,
		opacity: 0
	}
}

const Layout = () => {
	const dispatch = useDispatch()

	const initialPresentationDeckShadow = useSelector(
		(store: any) => store.layout.presentation.presentationDeckShadow
	)

	const shrinkPreviousLevel = useSelector(
		(store: any) => store.layout.misc.shrinkPreviousLevel
	)

	const [isGalleryOptionsVisible, toggleGalleryOptions] = useState(false)
	const [isFolderOptionsVisible, toggleFolderOptions] = useState(false)
	const [isConfirmModalVisible, toggleConfirmModalVisible] = useState(false)
	const [isLoading, toggleLoading] = useState(false)

	const previewInnerRef = useRef(null)
	const { layoutShallowCopy, galleryOptionsShallowCopy } = useSelector(
		(state: any) => state.temp
	)
	const storyBoardFont = useSelector(
		(store: any) => store.layout.storyBoard.storyBoardFont
	)
	const storyBoardTitleFont = useSelector(
		(store: any) => store.layout.storyBoard.storyBoardTitleFont
	)

	const { storyBoard } = useSelector((store: any) => store.layout)

	const authToken = useSelector((store: any) => store.authUser.user.token)
	const storyBoardContext = useSelector((store: any) => store.storyboardContext)
	const tagGalleryOptions = useSelector(
		(store: any) => store.storyboardContext.tagGalleryOptions
	)
	const initialLogoSize = useSelector(
		(store: StoreInterface) => store.layout.logoImage.logoSize
	)
	const logoAlignment = useSelector(
		(store: StoreInterface) => store.layout.logoImage.logoPosition
	)
	const initialBackgroundColor = useSelector(
		(store: StoreInterface) => store.layout.background.backgroundColor
	)
	const { layout } = useSelector((store: StoreInterface) => store)

	const initiaBackgroundImageOpacity = useSelector(
		(store: StoreInterface) => store.layout.background.backgroundImageOpacity
	)

	const { rootLevelGalleryOptions } = useSelector(
		(store: StoreInterface) => store.layout.misc
	)

	/**
	 *
	 * @description checks whether the layout object is differs from it's shallow copy
	 * @returns {boolean} dirty or not
	 */
	function checkIfDirty() {
		const hasChanged = isEqual(layout, layoutShallowCopy)
		return hasChanged
	}

	useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.LAYOUT))
		dispatch(getAllUserGroups(authToken))
		dispatch(resetStoryBoardContext())
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.Layout`
			})
		)
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Layout`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const initialPresentationDeckColor = useSelector(
		(store: any) => store.layout.presentation.presentationDeckColor
	)
	const initialContentTabColor = useSelector(
		(store: any) => store.layout.content.contentTabColor
	)
	const initialContentTabOpacity = useSelector(
		(store: any) => store.layout.content.contentTabOpacity
	)
	const storyBoardFontColor = useSelector(
		(state: any) => state.layout.storyBoard.storyBoardFontColor
	)
	const presentationDeckFontColor = useSelector(
		(state: any) => state.layout.presentation.presentationDeckFontColor
	)
	const initialStoryboardFontSize = useSelector(
		(state: any) => state.layout.storyBoard.storyBoardFontSize
	)

	const initialTitleUnderLined = useSelector(
		(state: any) => state.layout.misc.underlineTitle
	)
	const initialTitleUnderlineColor = useSelector(
		(state: any) => state.layout.misc.titleUnderlineColor
	)
	const initialSelectedStyle = useSelector(
		(state: any) => state.layout.misc.navigationStyle.key
	)
	const initialBreadcrumbsState = useSelector(
		(state: any) => state.layout.misc.breadcrumbs
	)

	const initialLandingPage = useSelector(
		(state: any) => state.layout.misc.landingPage
	)
	const [isLandingPage, setLandingPage] = useState(initialLandingPage)
	const currentLandingPageStyle = useSelector(
		(state: any) => state.layout.misc.landingPageStyle.key
	)
	const [landingPageStyle, setCurrentLandingPageStyle] = useState(
		currentLandingPageStyle
	)
	const initialLandingPgeTitle = useSelector(
		(state: any) => state.layout.misc.landingPageTitle
	)
	const [landingPageTitle, setLandigPageTitle] = useState(
		initialLandingPgeTitle
	)
	const initialStoryBoardFolderSearch = useSelector(
		(state: any) => state.layout.storyBoard.storyBoardFolderSearch
	)

	const initialParallaxScrollingBackground = useSelector(
		(state: any) => state.layout.misc.parallaxScrollingBackgound
	)

	const { rootLevelGallery } = useSelector((state: any) => state.layout.misc)

	const [isRootLevelGallery, setRootLevelGallery] = useState(rootLevelGallery)

	const layoutObject = useSelector((state: any) => state.layout)

	const shapeButtons = [
		{ key: 1, label: 'None', icon: <CloseIcon />, type: GALLERY_SHAPE_NONE },
		{ key: 2, label: 'Box', icon: <StopIcon />, type: GALLERY_SHAPE_BOX },
		{
			key: 3,
			label: 'Rounded box',
			icon: <BoxIcon className={styles.roundedBox} />,
			type: GALLERY_SHAPE_ROUNDEDN_BOX
		},
		{
			key: 4,
			label: 'Circle',
			icon: <FiberManualRecordIcon />,
			type: GALLERY_SHAPE_CIRCLE
		}
	]

	/**
	 *
	 * @description handles backnavigation
	 */
	function handleGoBack() {
		if (!checkIfDirty()) {
			toggleConfirmModalVisible(true)
		} else {
			dispatch(setTagGalleryOptions({}))
			dispatch(setTagBusinessRules(folderSettings))
			window.parent.postMessage({ action: 'layoutClosed' }, '*')
		}
	}

	/**
	 *
	 * @description opens the gallery sidemenu
	 * and creates shallow copy of the currently selected tag's
	 * gallery options, for isDirty check
	 */
	function openGalleryOptions() {
		// open the menu
		dispatch(setGalleryOptionsShallowCopy(tagGalleryOptions))
		toggleGalleryOptions((isOpen) => !isOpen)
	}

	/**
	 *
	 * @description dispatched the action with the key value of the selected
	 * navigation style
	 * @param {number} selectedLandingPageStyle the key of the selected navigation style
	 */
	function handleLandingPageStyle(selectedLandingPageStyle: any) {
		const chosenNavigationStyle = {
			key: selectedLandingPageStyle.key,
			type: selectedLandingPageStyle.type
		}
		setCurrentLandingPageStyle(chosenNavigationStyle)
		dispatch(setLandingPageStyle(chosenNavigationStyle))
	}

	/**
	 *
	 *
	 * @param {File} logo the chosen logo file
	 */
	function handleLogoUpload(logo: any) {
		dispatch(uploadLogo(logo, authToken))
	}

	/**
	 *
	 * @description changes the size of the logo with layout action reducer
	 * and dispatches the event in the reducer
	 * @param {number} logoSize the chosen logosize
	 */
	function handleLogoSizeChange(logoSize: any) {
		dispatch(setLogoSize(logoSize))
	}

	/**
	 *
	 * @description changes the alignment of the logo
	 * and dispatches the event in the reducer
	 * @param {string} selectedLogoAlignment the selected logo alignment
	 * one of [...LOGO_ALIGNMENTS]
	 */
	function handleLogoAlignment(selectedLogoAlignment: string) {
		dispatch(changeLogoPosition(selectedLogoAlignment))
	}

	/**
	 *
	 * @description sets the selected opacity value
	 * and dispatches the event in the reducer
	 * @param {number} opacityValue the selected opacity value
	 */
	function handleBackgroundImageOpacityChange(opacityValue: number) {
		dispatch(setBackgroundImageOpacity(opacityValue))
	}

	/**
	 *
	 * @description toggles the presentation deck shadow
	 * and dispatches the event in the reducer
	 */
	function handlePresentationDeckShadowToggle() {
		dispatch(togglePresentationDeckShadow(!initialPresentationDeckShadow))
	}

	/**
	 *
	 * @description dispatches the event in the reducer
	 * @param {number} opacityValue the selected opacity value
	 */
	function handleCotnentTabOpacityChange(opacityValue: number) {
		dispatch(setContentTabOpacity(opacityValue))
	}

	/**
	 *
	 * @description dispatches the event in the reducer
	 * @param {number} value the selected opacity value
	 */
	function handleStoryboardFontSizeChange(value: number) {
		dispatch(setStoryBoardFontSize(value))
	}

	/**
	 *
	 * @description hndles navigation style changes
	 * and dispatches action reducer
	 * @param {number} navStyle the selected navstyle
	 */
	function handleNavigationStyleChange(navStyle: any) {
		const chosenNavigationStyle = {
			key: navStyle.key,
			type: navStyle.type
		}
		dispatch(setNavigationStyle(chosenNavigationStyle))
	}

	/**
	 *
	 * @description toggles between true | false
	 * dispatches the the action reducer with the chosen boolean value
	 */
	function handleToggleTitleUnderLined() {
		dispatch(setTitleUnderline(!initialTitleUnderLined))
	}

	/**
	 *
	 * @description disptches the action reducer with the value
	 * of whether the previos level should be shrinket or not
	 */
	function handleToggleShrinkPreviousLevel() {
		dispatch(toggleShrinkPreviousLevel(!shrinkPreviousLevel))
	}

	/**
	 *
	 * @description dispatches the action reducer with the value
	 * of whether the breadcrumbs should be visible or not
	 */
	function handleToggleBreadcrumbs() {
		dispatch(toggleBreadcrumbsVisible(!initialBreadcrumbsState))
	}

	/**
	 *
	 * @description dispatches the action reduer with the value
	 * of whether the first level as landing page
	 */
	function handleLandingPage() {
		const currentState = !isLandingPage
		setLandingPage(currentState)
		dispatch(toggleLandingPage(currentState))
		if (currentState) {
			setRootLevelGallery(false)
			dispatch(toggleRootLevelGallery(false))
		}
	}

	/**
	 *
	 * @description sets the local state with the new value
	 * and dispatches an action that toggles the new value in the reducer
	 */
	function handleRootLevelGallery() {
		const newState = !isRootLevelGallery
		if (newState) {
			setLandingPage(false)
			dispatch(toggleLandingPage(false))
		}
		setRootLevelGallery(newState)
		dispatch(toggleRootLevelGallery(newState))
	}

	/**
	 *
	 * @description sets the new landing page title
	 * @param {string} newLandingPageTitle the new landingpage title given by the user
	 */
	function handleLandingPageTitleChange(newLandingPageTitle: string) {
		setLandigPageTitle(newLandingPageTitle)
	}

	/**
	 *
	 * @description dispatches folder state action educer with
	 * the vlaue whether the folder search is enabled or not
	 */
	function handleFolderSearch() {
		dispatch(toggleStoryBoardFolderSearch(!initialStoryBoardFolderSearch))
	}

	/**
	 *
	 * @description dispatces an action with the value
	 * whether the parallax effect is enabled or not
	 */
	function handleParallaxEffect() {
		dispatch(
			toggletParallaxScrollingBackground(!initialParallaxScrollingBackground)
		)
	}

	/**
	 *
	 * @description saves the modified layout into the database
	 * and changes the shallowcopy of the layout with the current one
	 * that controls the discard and the save buttons
	 * and the back navigation unsaved changes modal
	 */
	function handleLayoutSave() {
		updateLayout(layoutObject, authToken).then(() => {
			dispatch(setLayoutShallowCopy(layoutObject))
			toast(t('notifications.success.layout-saved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: 'success',
				autoClose: 5000
			})
		})
	}

	/**
	 *
	 *
	 * @param {object} backgroundImage the object of the backgroundimage
	 * @description uploads the file, and sets the hash url from response in the redux store
	 */
	function handleBackgroundUpdate(backgroundImage: any) {
		toggleLoading(true)
		dispatch(uploadBackroundImage(backgroundImage, authToken))
			// @ts-ignore
			.then((toolBackgroundImage) => {
				console.debug('toolBackgroundImage', toolBackgroundImage)
				dispatch(setCurrentBackgroundImage(toolBackgroundImage, 0))
				toggleLoading(false)
			})
	}

	/**
	 *
	 * @description hides the modal, set the click confirmation to true,
	 * and executes the callback provided in the arguments
	 * @param {Function} callback a callback function that being executed
	 */
	function closeModal(callback: any) {
		toggleConfirmModalVisible(false)
		setTimeout(() => {
			callback()
		}, 100)
	}

	const handleNavigationClick = () =>
		closeModal(() => {
			window.parent.postMessage({ action: 'layoutClosed' }, '*')
		})

	/**
	 * @param {string} role role of the button that specifies extra callbacks
	 * @description changes the layout object value with the ShallowCopy
	 */
	function discardLayout(role: string) {
		dispatch(setLayout(layoutShallowCopy))
		if (role === 'BACK') {
			window.parent.postMessage({ action: 'layoutClosed' }, '*')
		}
	}

	/**
	 *
	 * @description closes the gallery options sideber
	 * discars the changes by setting the currently selected tags's
	 * gallery options with the shallow copy
	 */
	function handleGalleryOptionsClose() {
		toggleGalleryOptions(false)
	}

	/**
	 *
	 * @description this functinon is called in the gallery settings
	 * when the cance button is pressed it discards the changes
	 * and closes the menu
	 */
	function cancelGalleryChanges() {
		dispatch(setTagGalleryOptions(galleryOptionsShallowCopy))
		handleGalleryOptionsClose()
	}

	/**
	 *
	 * @description removes the currently used background
	 */
	function handleBackgroundImageRemoving() {
		dispatch(removeBackgroundImage())
		dispatch(setCurrentBackgroundImage(false, 0))
	}

	/**
	 *
	 * @description dispatches an action that sets the chosen font into the Store
	 * @param {object} chosenFont object representation of the chosen font
	 */
	function handleFontChange(chosenFont: any) {
		dispatch(setStoryBoardFont(chosenFont))
	}

	const getFontWeightValue = () => {
		return layout.storyBoard.storyBoardFont.variants.findIndex(
			(value: any) => value === layout.storyBoard.storyBoardFontWeight
		)
	}

	return (
		<div>
			<div className={styles.topHeader}>
				<Button
					buttonClass={styles.backBtn}
					containerClass={styles.backBtnContainer}
					iconClass={styles.backIcon}
					onClick={() => handleGoBack()}
					icon={<ChevronLeft />}
					id="layout-back-navigation"
				/>
			</div>
			<div className={styles.layoutRootContainer}>
				<div className={classnames(styles.sidebar)}>
					<div className={classnames(styles.sidebarDefaultSettings)}>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.bg-color')}</p>
								<Tooltip infoText={t('tooltips.bg-color')} />
							</div>
							<ColorPicker
								onChange={(color) => dispatch(setBackgroundColor(color))}
								initialColor={initialBackgroundColor}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.bg-image')}</p>
								<Tooltip infoText={t('tooltips.bg-image')} />
							</div>
							<div className={styles.uploadContainer}>
								<UploadContent
									buttonContainerClass={styles.uploadBtnContainer}
									buttonClass={styles.uploadBtn}
									type={UPLOAD_BUTTON}
									buttonnLabel={t('labels.upload-new')}
									handleFileUpload={(files) => handleBackgroundUpdate(files[0])}
									icon={<CloudUploadIcon />}
								/>
								<Button
									containerClass={styles.uploadBtnContainer}
									buttonClass={styles.removeBtn}
									type={BUTTON_TEXT}
									icon={<DeleteIcon />}
									label={t('labels.layout-remove')}
									onClick={() => handleBackgroundImageRemoving()}
									id="layout-remove-background-image"
								/>
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.bg-opacity')}</p>
								<Tooltip infoText={t('tooltips.bg-opacity')} />
							</div>
							<InputRange
								inputRange={[0, 100]}
								valueFormatter={(value) => `${value} %`}
								inheritedValue={Number.parseInt(
									initiaBackgroundImageOpacity,
									10
								)}
								inputClassName={styles.sliderInput}
								onDragEnd={(value) => handleBackgroundImageOpacityChange(value)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.logo-image')}</p>
								<Tooltip infoText={t('tooltips.logo-image')} />
							</div>
							<div className={styles.uploadContainer}>
								<UploadContent
									buttonContainerClass={styles.uploadBtnContainer}
									buttonClass={styles.uploadBtn}
									type={UPLOAD_BUTTON}
									buttonnLabel={t('labels.upload-new')}
									icon={<CloudUploadIcon />}
									handleFileUpload={(files) => handleLogoUpload(files[0])}
								/>
								<Button
									containerClass={styles.uploadBtnContainer}
									buttonClass={styles.removeBtn}
									type={BUTTON_TEXT}
									onClick={() => dispatch(clearLogoImage())}
									icon={<DeleteIcon />}
									label={t('labels.layout-remove')}
									id="layout-remove-logo-image"
								/>
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.logo-alignment')}</p>
								<Tooltip infoText={t('tooltips.logo-alignment')} />
							</div>
							<div className={styles.logoAlignemnts}>
								{LOGO_ALIGNMENTS.map((alignMent) => (
									<Button
										key={`alignment-${alignMent.position.toLowerCase()}`}
										buttonClass={styles.alignmentBtn}
										containerClass={styles.alignmentBtnContainer}
										onClick={() => handleLogoAlignment(alignMent.position)}
										icon={
											<ReactSVG
												role="img"
												wrapper="span"
												src={alignMent.image}
												className={classnames(
													styles.logoAlignment,
													logoAlignment === alignMent.position
														? styles.active
														: styles.notActive
												)}
											/>
										}
										id="layout-logo-alignment"
									/>
								))}
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.logo-size')}</p>
								<Tooltip infoText={t('tooltips.logo-size')} />
							</div>
							<InputRange
								inputRange={[0, 10]}
								valueFormatter={(value) => value}
								inputClassName={styles.sliderInput}
								onDragEnd={(value) => handleLogoSizeChange(value)}
								inheritedValue={Number.parseInt(initialLogoSize, 10)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.pres-deck-shadow')}</p>
								<Tooltip infoText={t('tooltips.pres-deck-shadow')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={initialPresentationDeckShadow}
								onChange={handlePresentationDeckShadowToggle}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.pres-deck-color')}</p>
								<Tooltip infoText={t('tooltips.pres-deck-color')} />
							</div>
							<ColorPicker
								initialColor={initialPresentationDeckColor}
								onChange={(color) => dispatch(setPresentationDeckColor(color))}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.content-tab-color')}</p>
								<Tooltip infoText={t('tooltips.content-tab-color')} />
							</div>
							<ColorPicker
								initialColor={initialContentTabColor}
								onChange={(color) => dispatch(setContentTabColor(color))}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.content-tab-opacity')}
								</p>
								<Tooltip infoText={t('tooltips.content-tab-opacity')} />
							</div>
							<InputRange
								inputRange={[0, 100]}
								valueFormatter={(value) => `${value}%`}
								inputClassName={styles.sliderInput}
								inheritedValue={Number.parseInt(initialContentTabOpacity, 10)}
								onDragEnd={(value) => handleCotnentTabOpacityChange(value)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.storyboard-font')}</p>
								<Tooltip infoText={t('tooltips.storyboard-font')} />
							</div>
							<div className={styles.fontPickerRoot}>
								<TextFormatIcon htmlColor="#ffffff" />
								<FontPicker
									onChange={(font) => handleFontChange(font)}
									selected={storyBoardFont}
								/>
							</div>
							<div className={styles.footer}>
								{`${t('labels.selected-font')} ${storyBoardFont.value}`}
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.storyboard-font-weight')}
								</p>
								<Tooltip infoText={t('tooltips.storyboard-font')} />
							</div>
							<div className={styles.fontWeightRootContainer}>
								<FormatBoldIcon htmlColor="#ffffff" />
								{has(layout.storyBoard.storyBoardFont, 'variants') && (
									<Dropdown
										listItems={layout.storyBoard.storyBoardFont.variants
											.filter((value: any) =>
												Object.keys(FONT_WEIGHTS).some(
													(weight) => weight === value
												)
											)
											.map((fontWeight: any, index: number) => ({
												fontWeight,
												key: index,
												// @ts-ignore
												label: FONT_WEIGHTS[fontWeight],
												value: fontWeight
											}))}
										styles={dropdownStyles}
										onChangeCallback={({ value }) =>
											dispatch(setStoryBoardFontWeight(value))
										}
										defaultIndex={getFontWeightValue()}
									/>
								)}
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.storyboard-title-font')}
								</p>
								<Tooltip infoText={t('tooltips.storyboard-title-font')} />
							</div>
							<div className={styles.fontPickerRoot}>
								<TextFormatIcon htmlColor="#ffffff" />
								<FontPicker
									onChange={(font) => dispatch(setStoryBoardTitleFont(font))}
									selected={storyBoardTitleFont}
								/>
							</div>
							<div className={styles.footer}>
								{`${t('labels.selected-font')} ${storyBoardTitleFont.value}`}
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.storyboard-title-font-weight')}
								</p>
								<Tooltip infoText={t('tooltips.storyboard-font')} />
							</div>

							<div className={styles.fontWeightRootContainer}>
								<FormatBoldIcon htmlColor="#ffffff" />
								{has(layout.storyBoard.storyBoardTitleFont, 'variants') && (
									<Dropdown
										listItems={layout.storyBoard.storyBoardTitleFont.variants
											.filter((value: any) =>
												Object.keys(FONT_WEIGHTS).some(
													(weight) => weight === value
												)
											)
											.map((fontWeight: any, index: number) => ({
												fontWeight,
												key: index,
												// @ts-ignore
												label: FONT_WEIGHTS[fontWeight],
												value: fontWeight
											}))}
										styles={dropdownStyles}
										onChangeCallback={({ value }) =>
											dispatch(setStoryBoardTitleFontWeight(value))
										}
										defaultIndex={Object.keys(
											layout.storyBoard.storyBoardTitleFont.files
										)
											.filter((variant) =>
												Object.keys(FONT_WEIGHTS).some(
													(weight) => weight === variant
												)
											)
											.findIndex(
												(weight) =>
													weight === storyBoard.storyBoardTitleFontWeight
											)}
									/>
								)}
							</div>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.storyboard-font-color')}
								</p>
								<Tooltip infoText={t('tooltips.storyboard-font-color')} />
							</div>
							<ColorPicker
								onChange={(color) => dispatch(setStoryBoardFontColor(color))}
								initialColor={storyBoardFontColor}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.pres-deck-font-color')}
								</p>
								<Tooltip infoText={t('tooltips.pres-deck-font-color')} />
							</div>
							<ColorPicker
								onChange={(color) =>
									dispatch(setPresentationDeckFontColor(color))
								}
								initialColor={presentationDeckFontColor}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.storyboard-font-size')}
								</p>
								<Tooltip infoText={t('tooltips.storyboard-font-size')} />
							</div>
							<InputRange
								inputRange={[50, 250]}
								valueFormatter={(value) => `${value}%`}
								inputClassName={styles.sliderInput}
								onDragEnd={(value) => handleStoryboardFontSizeChange(value)}
								inheritedValue={Number.parseInt(initialStoryboardFontSize, 10)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.underline-title')}</p>
								<Tooltip infoText={t('tooltips.underline-title')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={initialTitleUnderLined}
								onChange={handleToggleTitleUnderLined}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.underline-color')}</p>
								<Tooltip infoText={t('tooltips.underline-color')} />
							</div>
							<ColorPicker
								onChange={(color) => dispatch(setTitleUnderlineColor(color))}
								initialColor={initialTitleUnderlineColor}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.nav-style')}</p>
							</div>
							<ButtonGroup
								active={initialSelectedStyle}
								buttons={[
									{
										key: 0,
										label: t(`layout.${VERTICAL.toLowerCase()}`),
										type: VERTICAL
									},
									{
										key: 1,
										label: t(`layout.${HORIZONTAL.toLowerCase()}`),
										type: HORIZONTAL
									}
								]}
								onClick={(selected) => handleNavigationStyleChange(selected)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.shrink-previous')}</p>
								<Tooltip infoText={t('tooltips.shrink-previous')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={shrinkPreviousLevel}
								onChange={handleToggleShrinkPreviousLevel}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.breadcrumbs')}</p>
								<Tooltip infoText={t('tooltips.breadcrumbs')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={initialBreadcrumbsState}
								onChange={handleToggleBreadcrumbs}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.first_level_title')}</p>
								<Tooltip infoText={t('tooltips.landing-page')} />
							</div>
							<CheckBox
								isChecked={isLandingPage}
								name="landingpage"
								onChange={() => handleLandingPage()}
								label={t('layout.landing-page-checkbox')}
								wrapperClassName={styles.checkboxContainer}
								disabled={isRootLevelGallery}
							/>
							<ButtonGroup
								disabled={!isLandingPage}
								active={landingPageStyle}
								buttons={[
									{
										key: 0,
										label: t(`layout.${VERTICAL.toLowerCase()}`),
										type: VERTICAL
									},
									{
										key: 1,
										label: t(`layout.${HORIZONTAL.toLowerCase()}`),
										type: HORIZONTAL
									}
								]}
								onClick={(selected) => handleLandingPageStyle(selected)}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>
									{t('layout.landing-page-gallery')}
								</p>
								<Tooltip infoText={t('tooltips.landing-page-gallery')} />
							</div>
							<CheckBox
								isChecked={isRootLevelGallery}
								name="gallerLandingPage"
								onChange={() => handleRootLevelGallery()}
								label={t('layout.landing-pgage-gallery-checkbox')}
								wrapperClassName={styles.checkboxContainer}
								disabled={isLandingPage}
							/>
						</div>
						<motion.div
							className={classnames(
								styles.rootLevelGallerySettings,
								!isRootLevelGallery ? styles.hidden : ''
							)}
							variants={rootLevelGaleryVariants}
							animate={isRootLevelGallery ? 'visible' : 'hidden'}
							exit="hidden"
						>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.gallery-width')}
									</p>
									{/* @ts-ignore */}
									<Tooltip />
								</div>
								<InputRange
									inputRange={[30, 90]}
									valueFormatter={(value) => `${value} %`}
									inheritedValue={Number.parseInt(
										rootLevelGalleryOptions.galleryWidth,
										10
									)}
									inputClassName={styles.sliderInput}
									onDragEnd={(value) =>
										dispatch(
											setRootLevelGalleryWidth(Number.parseInt(value, 10))
										)
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.number-of-items-per-row')}
									</p>
									{/* @ts-ignore */}
									<Tooltip />
								</div>
								<InputRange
									inputRange={[1, 10]}
									valueFormatter={(value) => value}
									inheritedValue={Number.parseInt(
										rootLevelGalleryOptions.itemsPerRow,
										10
									)}
									inputClassName={styles.sliderInput}
									onDragEnd={(value) =>
										dispatch(
											setRootLevelGalleryItemsPerRow(Number.parseInt(value, 10))
										)
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.padding-between-items')}
									</p>
									{/* @ts-ignore */}
									<Tooltip />
								</div>
								<InputRange
									inputRange={[5, 99]}
									valueFormatter={(value) => value}
									inheritedValue={Number.parseInt(
										rootLevelGalleryOptions.paddingBetweenItems,
										10
									)}
									inputClassName={styles.sliderInput}
									onDragEnd={(value) =>
										dispatch(
											setRootLevelPaddingBetweenIems(Number.parseInt(value, 10))
										)
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.use-folder-icons')}
									</p>
								</div>
								<Toggle
									wrapperClassname={styles.toggleContainer}
									states={{
										off: t('misc.off'),
										on: t('misc.on')
									}}
									currentState={rootLevelGalleryOptions.useFolderIcons}
									onChange={(state) =>
										dispatch(setRootLevelUseFolderIcons(state))
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>{t('layout.gallery.shape')}</p>
								</div>
								<RadioGroup
									withCustomIcon
									defaultSelected={GALLERY_SHAPES.findIndex(
										(thisShape) => thisShape === rootLevelGalleryOptions.shape
									)}
									continerClass={styles.shapeSelector}
									radioItems={[...shapeButtons]}
									onChange={(selected) =>
										dispatch(
											setRootLevelGalleryItemShape(GALLERY_SHAPES[selected])
										)
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.shape-background-color')}
									</p>
								</div>
								<ColorPicker
									onChange={(color) =>
										dispatch(setRootLevelShapeBackgroundColor(color))
									}
									initialColor={rootLevelGalleryOptions.shapeBackgroundColor}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.shape-shadow')}
									</p>
								</div>
								<Toggle
									wrapperClassname={styles.toggleContainer}
									states={{
										off: t('misc.off'),
										on: t('misc.on')
									}}
									currentState={rootLevelGalleryOptions.shapeShadow}
									onChange={(selected) =>
										dispatch(setRootLevelShapeShadow(selected))
									}
								/>
							</div>
							<div className={styles.oneSetting}>
								<div className={styles.header}>
									<p className={styles.title}>
										{t('layout.gallery.text-shadow')}
									</p>
								</div>
								<Toggle
									wrapperClassname={styles.toggleContainer}
									states={{
										off: t('misc.off'),
										on: t('misc.on')
									}}
									currentState={rootLevelGalleryOptions.textShadow}
									onChange={(status) =>
										dispatch(setRootLevelTextShadow(status))
									}
								/>
							</div>
						</motion.div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.first-level-title')}</p>
								<Tooltip infoText={t('tooltips.landing-title')} />
							</div>
							<Input
								name="landingpage"
								initialValue={landingPageTitle}
								onChange={(event) =>
									handleLandingPageTitleChange(event.target.value)
								}
								onBlur={() => dispatch(setLandingPageTitle(landingPageTitle))}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.folder-search')}</p>
								<Tooltip infoText={t('tooltips.folder-search')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={initialStoryBoardFolderSearch}
								onChange={handleFolderSearch}
							/>
						</div>
						<div className={styles.oneSetting}>
							<div className={styles.header}>
								<p className={styles.title}>{t('layout.parallax')}</p>
								<Tooltip infoText={t('tooltips.parallax')} />
							</div>
							<Toggle
								wrapperClassname={styles.toggleContainer}
								states={{
									off: t('misc.off'),
									on: t('misc.on')
								}}
								currentState={initialParallaxScrollingBackground}
								onChange={handleParallaxEffect}
							/>
						</div>
					</div>
				</div>
				<div className={styles.previewContainer}>
					<div className={styles.actionButtonsContainer}>
						<ul>
							<li>
								<Button
									buttonClass={styles.actionBtn}
									label={t('labels.layout-folder-options')}
									onClick={() => toggleFolderOptions((currState) => !currState)}
									isDisabled={isEmpty(storyBoardContext.selectedTag)}
									id="layout-folder-options"
								/>
							</li>
							<li>
								<Button
									buttonClass={styles.actionBtn}
									label={t('labels.layout-gallery')}
									onClick={() => openGalleryOptions()}
									isDisabled={isEmpty(storyBoardContext.selectedTag)}
									id="layout-gallery-options"
								/>
							</li>
						</ul>
						<ul>
							<li>
								<Button
									buttonClass={styles.actionBtn}
									label={t('labels.layout-discard')}
									isDisabled={checkIfDirty()}
									onClick={discardLayout}
									id="layout-discard-changes"
								/>
							</li>
							<li>
								<Button
									buttonClass={styles.actionBtn}
									label={t('labels.layout-save')}
									isPositive={!checkIfDirty()}
									isDisabled={checkIfDirty()}
									onClick={() => handleLayoutSave()}
									id="layout-save-changes"
								/>
							</li>
						</ul>
					</div>
					<div className={styles.previewInner} ref={previewInnerRef}>
						<PresentationBar />
						<StoryBoard containerRef={previewInnerRef} role={LAYOUT} />
					</div>
				</div>
			</div>
			{isLoading && (
				<LoadingOverlay overlayClassName={styles.loadingOverlay} isLoading />
			)}
			{!isEmpty(storyBoardContext.selectedTag) && (
				<>
					<GalleryOptions
						isOpen={isGalleryOptionsVisible}
						onClose={() => handleGalleryOptionsClose()}
						cancelChages={() => cancelGalleryChanges()}
					/>
					<FolderOptions
						isVisible={isFolderOptionsVisible}
						handleClose={() => toggleFolderOptions(false)}
					/>
				</>
			)}
			<ConfirmDeleteModal
				isActive={isConfirmModalVisible}
				declineCallback={() => discardLayout('BACK')}
				handleClose={() => toggleConfirmModalVisible(false)}
				title={t('titles.unsaved-changes')}
				onApprove={() => handleNavigationClick()}
				cancelLabel={t('buttons.discard-changes')}
				confirmLabel={t('buttons.save-changes')}
			>
				<p className={styles.confirmDelete}>
					{t('misc.layout-unsaved-changes')}
				</p>
			</ConfirmDeleteModal>
		</div>
	)
}

export default Layout
