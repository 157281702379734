import {
	cloudFnDelete,
	cloudFnGet,
	cloudFnPatch,
	cloudFnPost
} from '../../utils/requests'
import {
	userUpdateSchema,
	mfAuthenticationSchema,
	forgotPasswordSchema,
	userInvitationSchema
} from '../../utils/schemas/requests'
import { GetUserResponseInterface } from '../../utils/interfaces'
import {
	authenticatorSetupEndpoint,
	forgottenPassword,
	loginInfoEndpoint,
	resetUserPasswordEndpoint,
	userProfileImageEndpoint,
	usersEndpoint,
	usersInvitationEndpoint,
	usersWelcomeMessages
} from '../apiEndpoints_new'

export const getUser = (userId: number, authToken: string) =>
	new Promise<GetUserResponseInterface>((resolve, reject) => {
		cloudFnGet(
			`${usersEndpoint}/${userId}`,
			{},
			{
				withToken: true,
				authToken
			}
		)
			.then((result) => {
				resolve(result as GetUserResponseInterface)
			})
			.catch((error) => {
				reject(error)
			})
	})

export const modifyUser = (userObject: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			`${usersEndpoint}/${userObject.id}`,
			userObject,
			{ withToken: true },
			userUpdateSchema
		)
			.then((response) => {
				resolve({
					success: true,
					user: response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteProfilePicture = (userObject: any) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(`${userProfileImageEndpoint}/${userObject.id}`)
			.then((response) => {
				resolve({
					success: true,
					user: response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
export const modifyUserPassword = (userObject: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			resetUserPasswordEndpoint,
			userObject,
			{ withToken: true },
			userUpdateSchema
		)
			.then((response) => {
				resolve({
					success: true,
					user: response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const toggleMfAuthentication = (userObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			authenticatorSetupEndpoint,
			userObject,
			{ withToken: true, authToken },
			mfAuthenticationSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const checkMfAuthentication = (authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(authenticatorSetupEndpoint, {}, { withToken: true, authToken })
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendWelcomeEmail = (userId: number) =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${loginInfoEndpoint}/${userId}`, {}, { withToken: true })
			.then((response: any) => {
				let isSent = false
				if (response.body.login_number === 0) {
					isSent = true
					cloudFnPost(usersWelcomeMessages)
				}
				resolve({
					isSent
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const forget = (userObject: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(forgottenPassword, userObject, null, forgotPasswordSchema)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const userInvitation = (data: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(usersInvitationEndpoint, data, null, userInvitationSchema)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(error)
			})
	})
