import { cloudFnPatch, cloudFnPost, cloudFnPut } from '../../utils/requests'
import {
	folderOptionsSchema,
	galleryOptionsSchema,
	layoutSchema,
	uploadImageSchema
} from '../../utils/schemas/requests'
import {
	appearanceEndpoint,
	appearanceUploadEndpoint,
	tagsEndpoint
} from '../apiEndpoints_new'

export const updateLayout = (layoutObject: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPatch(
			`${appearanceEndpoint}/2`,
			JSON.stringify(layoutObject),
			{ withToken: true, authToken },
			layoutSchema
		)
			.then((result: any) => {
				resolve({
					isSuccess: true,
					...result
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const uploadImage = (image: any, authToken: any) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', image)
		cloudFnPost(
			`${appearanceUploadEndpoint}?oauth=${authToken}`,
			formData,
			{ withToken: true, authToken },
			uploadImageSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(error)
			})
	})

export const modifyFolderOptions = (
	folderOptions: any,
	option: any,
	tagId: any,
	authToken: any
) =>
	new Promise((resolve, reject) => {
		const schema =
			option === 'business_rules' ? folderOptionsSchema : galleryOptionsSchema
		const formattedFormatOptions = {
			[option]: JSON.stringify(folderOptions)
		}
		cloudFnPut(
			`${tagsEndpoint}/${tagId}`,
			formattedFormatOptions,
			{ withToken: true, authToken },
			schema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(error)
			})
	})
