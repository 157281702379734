import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import styled from 'styled-components'
import hexToRgba from 'hex-to-rgba'
import SearchIcon from '@mui/icons-material/Search'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'

import { layoutSettings as defaultLayout } from '../../../../utils/layoutSettings'
import {
	isEditableDocument,
	isEditableSlide
} from '../../../../utils/helpers/isEditableSlide'
import {
	LIBRARY,
	MY_FILES,
	STARRED,
	SM,
	LG,
	BUTTON_TEXT,
	CONTENT_PANE,
	ICON_LEFT
} from '../../../../utils/consts'
import Button from '../../../../components/common/Button'
import { t } from '../../../../utils/languages/i18n'
import Card from '../../../../components/Card'
import styles from './ContentPane.module.scss'
import {
	addToTempPresentation,
	clearTempPresentation
} from '../../../../store/actions/temp'
import { getMyFilesByCategoryId } from '../../../../store/actions/myfiles'
import Spinner from '../../../../components/common/Spinner'
import BigGreenButton from '../../../../components/PresentationBar/components/BigGreenButton'
import BlurOverlay from '../../../../components/common/BlurOverlay'
import Search from '../../../../components/Search'
import { createSlideShow } from '../../../../store/actions/presentation'
import Tab from './components/Tab'
import { genericAnimation } from '../../animations'
import { getAllStarredContent } from '../../../../store/actions/content'
import { setTempValue } from '../../../../store/actions/temp'
import getSalesframeAPIUrl from '../../../../utils/getSalesframeAPIUrl'

const variants = {
	visible: {
		width: '85vw'
	},
	hidden: {
		width: 0
	}
}

const HandlerWithOpacity = styled.div`
	background: ${(props) => props.contentTabColor};
	height: ${(props) => props.calculatedHeight};
`

const ContentPane = ({
	activeTabProp,
	isIosDevice,
	isOpen,
	openSearchFolder,
	toggleContentPane,
	topDistance
}) => {
	const [activeTab, setActiveTab] = useState(activeTabProp || LIBRARY)
	const [selectedMyFilesCategory, setSelectedMyFilesCategory] = useState(false)
	const [isLoading, toggleLoading] = useState(false)
	const [showSearch, toggleShowSearch] = useState(false)
	const myFileContent = useSelector((state) => state.myfiles.myFiles)
	const thisTagContent = useSelector((state) => state.content.content)
	const starredContent = useSelector((state) => state.content.starredContent)
	const storyBoardContext = useSelector((state) => state.storyboardContext)
	const currentThumbnailSize = useSelector((state) => state.misc.thumbnailSize)
	const myFilesCategories = useSelector(
		(state) => state.myfiles.myFilesCategories
	)
	const authToken = useSelector((store) => store.authUser.user.token)
	const tempPresentationSlides = useSelector(
		(store) => store.temp.slides[CONTENT_PANE.toLowerCase()]
	)
	const { collaoraSavingFiles } = useSelector((state) => state.temp)
	const layoutSettings = useSelector((state) => state.layout)
	const [slidingPaneSelector, setSlidingPaneSelector] = useState(false)
	const location = useLocation()
	const slides = getContent().map((item) => item)

	const dispatch = useDispatch()

	useEffect(() => {
		setSlidingPaneSelector('#slidingpane')
		if (isOpen) {
			dispatch(getAllStarredContent(authToken))
		}
		// to avoid unneccesary re-renders, we are opening the pane in an "uncontrolled way"
		// this is not the best approach here, we can think about a more performanced one later
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	/**
	 *
	 * @description handles the select / deselect of card click
	 * adds the file related analytics data to the batch in reducer
	 * adds / removed the clicked file from the tempPresentation reducer
	 * @param {object} file the clicked slide object
	 * @param {number} index index of the currently selected slide object
	 * @param {object} e the event of the permormed click
	 */
	function handleSelect(file, index, e) {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(file, CONTENT_PANE))
	}

	/**
	 *
	 * @description decides what content to should be returned according to the selected tba
	 * @returns {Array} array of files
	 */
	function getContent() {
		let content = []
		if (activeTab === LIBRARY) {
			content = thisTagContent.filter((file) => file.visibility === 1)
		} else if (activeTab === STARRED) {
			content = starredContent.filter((file) => file.visibility !== 0)
		} else if (activeTab === MY_FILES) {
			content = myFileContent
		}

		return content
	}

	const countVisibleFiles = (files) => {
		const filtered = files.filter((file) => file.visibility === 1)
		return filtered.length
	}

	/**
	 *
	 * @description sets the clicked myfiles category to active
	 * and dispatches an action that fetches the files belonging to that specifi my files category
	 * @param {object} clickedMyFileCategory the object representes the clicked myfiles category
	 */
	function handleMyFilesCategoryChange(clickedMyFileCategory) {
		toggleLoading(true)
		setSelectedMyFilesCategory(clickedMyFileCategory)
		dispatch(getMyFilesByCategoryId(clickedMyFileCategory.id, authToken)).then(
			() => {
				toggleLoading(false)
			}
		)
	}

	/**
	 *
	 * @description creates a new presentation with the selected slides
	 */
	function handlePlaySelected() {
		dispatch(
			createSlideShow({
				slides: tempPresentationSlides,
				startFrom: 0
			})
		)
		toggleContentPane(false)
	}

	/**
	 *
	 * @description creates a new presentation with the all slides
	 */
	function handlePlayAll() {
		dispatch(
			createSlideShow({
				slides: slides,
				startFrom: 0
			})
		)
		toggleContentPane(false)
	}

	/**
	 *
	 * @description toogles the content pane state
	 * cleares the data from CONTENT_PANE temporary reducer
	 */
	function handleContentPaneState() {
		toggleContentPane((isCurrentlyOpen) => !isCurrentlyOpen)
		dispatch(clearTempPresentation(CONTENT_PANE))
	}

	/**
	 *
	 * @description defines the content pane tabs
	 * based on that where it is called from
	 */
	const defineTabs = () => {
		const tabItems = [
			{
				role: MY_FILES,
				label: t('labels.my-files-short'),
				id: 'showroom-content-pane-tab-my-files'
			}
		]
		if (!activeTabProp) {
			tabItems.push({
				role: LIBRARY,
				label: t('labels.library'),
				id: 'showroom-content-pane-tab-library'
			})
		}

		return tabItems.map((currentTabButton) => (
			<li key={currentTabButton.role}>
				<Tab
					containerClass={styles.tabBtn}
					buttonClass={classnames(
						styles.btn,
						currentTabButton.role === activeTab ? styles.selectedTab : ''
					)}
					label={currentTabButton.label}
					onClick={() => setActiveTab(currentTabButton.role)}
					isSelected={currentTabButton.role === activeTab}
					id={currentTabButton.id}
				/>
			</li>
		))
	}

	/**
	 * @description handle select/unselect all
	 * @param {boolean} isDeselect check if it's deselecting cards
	 */
	const toggleSelectAll = (isDeselect = false) => {
		dispatch(clearTempPresentation(CONTENT_PANE))
		if (isDeselect) {
			return
		}
		getContent().forEach((item) =>
			dispatch(addToTempPresentation(item, CONTENT_PANE))
		)
	}

	/**
	 * @description get selected tag title
	 * @returns {string} tag name
	 */
	function getSelectedTagTitle() {
		try {
			return storyBoardContext.selectedTag.name
		} catch {
			return ''
		}
	}

	/**
	 * @description this fn will find system_added category and pass it to the
	 * fn which is used to select the category
	 */
	const findSystemAddedCategory = () => {
		const addedFilesCategory = myFilesCategories.find(
			(item) => item.code === 'system_added'
		)
		addedFilesCategory && handleMyFilesCategoryChange(addedFilesCategory)
	}

	/**
	 * @description once the myFilesCategories are loaded, findSystemAddedCategory
	 * will be executed
	 */
	useEffect(() => {
		if (isOpen) {
			myFilesCategories.length > 0 && findSystemAddedCategory()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myFilesCategories, isOpen])

	const openCollabora = (file) => {
		handleContentPaneState()
		const uuid = uuidv4()
		const selectedTagId = Number(file.tags_id)
		let url
		if (isEditableDocument(file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file.checksum}|${
				file.status === 1 ? 0 : selectedTagId
			}|${file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		} else if (isEditableSlide(file._file.type)) {
			url = `${getSalesframeAPIUrl()}/wopi/files/${file._file.checksum}|${
				file.status === 1 ? 0 : selectedTagId
			}|${file._file.status}|${file.status}|${uuid}|${window.location.host}|v2`
		}
		if (url) {
			const collaboraObj = {
				url: `${window.location.origin}/editor/browser/6ba7057/cool.html?WOPISrc=${url}`,
				token: authToken,
				file,
				uuid,
				folderId: file.status === 1 ? 0 : selectedTagId,
				source: 'folder'
			}
			dispatch(setTempValue('collaboraData', collaboraObj))
		}
	}
	const checkIfFileIsEditable = (file) => {
		if (isEditableDocument(file.type)) {
			if (file.status === 11) {
				return true
			}
			if (file.status === 1 && file.editing === 1) {
				return true
			}
		}
		if (file._file) {
			if (isEditableSlide(file._file.type) && file._file.splitted === 1) {
				if (file.status === 11) {
					return true
				}
				if (file.status === 1 && file._file.editing === 1) {
					return true
				}
			}
		}
		return false
	}

	const isSavingFile = (file) => {
		const checksum = file._file ? file._file.checksum : file.checksum
		return (
			collaoraSavingFiles.findIndex(
				(item) => item.checksum === checksum && item.fileId === file.id
			) > -1
		)
	}

	return (
		<div className={styles.mainContent}>
			<AnimatePresence>
				<motion.div
					variants={variants}
					initial="hidden"
					animate={isOpen ? 'visible' : 'hidden'}
					transition={{
						type: 'spring',
						bounce: 0,
						duration: 0.25
					}}
					className={styles.contentPaneRootContainer}
					style={{
						height: location.pathname.includes('layout')
							? 'calc(100vh - 220px)'
							: `calc(${isIosDevice ? '95vh' : '100vh'} - 120px)`,
						zIndex: isOpen ? 10001 : 999,
						top: topDistance || 40
					}}
				>
					<HandlerWithOpacity
						className={classnames(
							styles.handlerBtnContainer,
							!isOpen ? styles.animateHover : ''
						)}
						contentTabColor={
							layoutSettings.content
								? hexToRgba(
										layoutSettings.content.contentTabColor,
										layoutSettings.content.contentTabOpacity * 0.01
								  )
								: hexToRgba(
										defaultLayout.contentTabColor,
										defaultLayout.contentTabOpacity * 0.01
								  )
						}
						calculatedHeight={
							location.pathname.includes('layout')
								? 'calc(100vh - 220px)'
								: `calc(${isIosDevice ? '95vh' : '100vh'} - 120px)`
						}
					>
						<button
							onClick={() => handleContentPaneState()}
							className={styles.contentLibraryHandler}
							type="button"
						>
							<span>
								{activeTabProp
									? t('labels.my-files-short')
									: t('labels.content')}
							</span>
							<span>{!activeTabProp && countVisibleFiles(thisTagContent)}</span>
						</button>
					</HandlerWithOpacity>
					<div className={styles.contentContainerPane}>
						<div className={styles.slidingPaneInner}>
							<div className={styles.topHeader}>
								<div className={classnames(styles.topHeaderInner, styles.left)}>
									<AnimateSharedLayout>
										<ul>{defineTabs()}</ul>
									</AnimateSharedLayout>
								</div>
								<div
									className={classnames(styles.topHeaderInner, styles.right)}
								>
									<ul>
										<li>
											<Button
												iconClass={styles.searchIcon}
												label={t('labels.search')}
												onClick={() => toggleShowSearch(true)}
												buttonClass={styles.btnSearch}
												iconSide={ICON_LEFT}
												icon={<SearchIcon />}
												id="showroom-content-pane-search-button"
											/>
										</li>
										<li>
											<Button
												label={
													tempPresentationSlides.length > 0
														? t('labels.play-selected')
														: t('labels.play-all')
												}
												onClick={() =>
													tempPresentationSlides.length > 0
														? handlePlaySelected()
														: handlePlayAll()
												}
												buttonClass={classnames(
													styles.btnPlay,
													styles.playSelectedBtn
												)}
												isPositive
												isDisabled={slides?.length === 0}
												id="showroom-content-pane-play-button"
											/>
										</li>
									</ul>
								</div>
							</div>
							<div className={styles.containerRoot}>
								{activeTab === LIBRARY && (
									<div className={styles.containerHeader}>
										<p>{getSelectedTagTitle()}</p>
										<div className={styles.selectAllButtonIconContainer}>
											<Button
												label={
													getContent().length ===
														tempPresentationSlides.length && slides?.length > 0
														? t('labels.deselect_all')
														: t('labels.select-all')
												}
												type={BUTTON_TEXT}
												icon={<LibraryAddCheckIcon />}
												iconSide={ICON_LEFT}
												buttonClass={styles.selectAllButton}
												iconClass={styles.selectAllButtonIcon}
												onClick={() =>
													toggleSelectAll(
														getContent().length ===
															tempPresentationSlides.length
													)
												}
												isDisabled={slides?.length === 0}
												id="showroom-content-pane-select-button"
											/>
										</div>
									</div>
								)}
								{activeTab === MY_FILES && myFilesCategories.length > 0 && (
									<div className={styles.tagTitleContainer}>
										<ul className={styles.myFilesCategories}>
											{myFilesCategories.map((myFileCategory) => (
												<li key={myFileCategory.id}>
													<Button
														label={myFileCategory.name}
														onClick={() =>
															handleMyFilesCategoryChange(myFileCategory)
														}
														buttonClass={classnames(
															styles.myFileCategoryBtn,
															selectedMyFilesCategory === myFileCategory
																? styles.selected
																: ''
														)}
														type={BUTTON_TEXT}
														id={`showroom-content-pane-my-files-category-button-${myFileCategory.name}`}
													/>
												</li>
											))}
										</ul>
										<h1>
											{activeTab === LIBRARY &&
												storyBoardContext.selectedTag &&
												storyBoardContext.selectedTag.name}
										</h1>
									</div>
								)}
								{!isLoading ? (
									<div
										className={classnames(
											styles.thumbnailContainer,
											isLoading ? styles.center : ''
										)}
									>
										<AnimatePresence key={activeTab}>
											{getContent().map((currentFile, index) => (
												<motion.div
													variants={genericAnimation}
													key={index.toString()}
													initial="hidden"
													exit="exit"
													animate="visible"
													custom={index}
												>
													<Card
														hasToolbar
														index={index}
														handleCardClick={(e) =>
															handleSelect(currentFile, index, e)
														}
														isSelected={tempPresentationSlides.includes(
															currentFile
														)}
														isToolbarVisible={
															tempPresentationSlides[
																tempPresentationSlides.length - 1
															] === currentFile
														}
														isLoadingCard={isSavingFile(currentFile) || false}
														key={`${currentFile.chekcsum}-${index.toString()}`}
														size={currentThumbnailSize === SM ? SM : LG}
														cardObject={{
															...currentFile,
															name: currentFile.name,
															extension: currentFile.type,
															id: currentFile.id
														}}
														hasLayoutFontColor={false}
														openFileEditor={openCollabora}
														isEditable={checkIfFileIsEditable(currentFile)}
													/>
												</motion.div>
											))}
											{activeTab === MY_FILES && (
												<React.Fragment>
													{collaoraSavingFiles
														.filter(
															(savingFile) =>
																!savingFile.isSave &&
																savingFile.folderId ===
																	selectedMyFilesCategory.id
														)
														.map((savingFile, index) => (
															<motion.div
																variants={genericAnimation}
																key={`saving-file-${index}`}
																initial="hidden"
																exit="exit"
																animate="visible"
																custom={index}
															>
																<Card
																	isSelected={false}
																	size={currentThumbnailSize === SM ? SM : LG}
																	isLoadingCard
																	isDraggable={false}
																	isToolbarVisible={false}
																	cardObject={{
																		...savingFile,
																		isNew: false,
																		extension: '',
																		id: ''
																	}}
																	hasLayoutFontColor={false}
																/>
															</motion.div>
														))}
												</React.Fragment>
											)}
										</AnimatePresence>
									</div>
								) : (
									<div className={styles.loaderContainer}>
										<Spinner isLoading={isLoading} size={30} />
									</div>
								)}
							</div>
							<div className={styles.bigGreenOuterContainer}>
								<BigGreenButton role={CONTENT_PANE.toLowerCase()} />
							</div>
						</div>
					</div>
				</motion.div>
			</AnimatePresence>
			{slidingPaneSelector && (
				<BlurOverlay
					isVisible
					show={isOpen}
					selector={slidingPaneSelector}
					onClose={() => toggleContentPane(false)}
				/>
			)}
			<Search
				show={showSearch}
				handleClose={() => toggleShowSearch(false)}
				openSearchFolder={openSearchFolder}
			/>
		</div>
	)
}

ContentPane.defaultProps = {
	activeTabProp: undefined,
	isOpen: false,
	openSearchFolder: undefined,
	topDistance: undefined
}

ContentPane.propTypes = {
	activeTabProp: PropTypes.string,
	isIosDevice: PropTypes.bool,
	isOpen: PropTypes.bool,
	openSearchFolder: PropTypes.func,
	toggleContentPane: PropTypes.func.isRequired,
	topDistance: PropTypes.string
}

export default ContentPane
