import { BOTTOM_RIGHT, GALLERY_SHAPE_ROUNDEDN_BOX, REGULAR, VERTICAL } from './consts'

export const layoutSettings: any = {
	backgroundColor: '#000000',
	'background-image': false,
	backgroundImageOpacity: 100,
	'logo-image': false,
	logoPosition: BOTTOM_RIGHT,
	logoSize: 5,
	presentationDeckShadow: true,
	presentationDeckColor: '#00Bd8f',
	contentTabColor: '#313e4b',
	contentTabOpacity: 90,
	storyBoardFont: { font: 'Open Sans', weight: 600, files: [] },
	storyBoardTitleFont: { font: 'Open Sans', wight: 700, files: [] },
	storyBoardFontColor: '#ffffff',
	presentationDeckFontColor: '#ffffff',
	storyBoardFontSize: 100,
	underLineTitle: false,
	titleUnderLineColor: '#ffffff',
	navigationStyle: { key: 0, type: VERTICAL },
	shrinkPreviousLevel: true,
	breadcrumbs: false,
	landingPage: false,
	landingPageStyle: { key: 0, type: VERTICAL },
	landingPageTitle: false,
	storyBoardFolderSearch: true,
	parallaxScrollingBackground: false,
	rootLevelGallery: false,
	storyBoardFontWeight: REGULAR,
	storyBoardTitleFontWeight: REGULAR,
}

export const galleryOptions = {
	useGallery: false,
	useFolderIcons: false,
	shape: GALLERY_SHAPE_ROUNDEDN_BOX,
	shapeShadow: false,
	textShadow: false,
	itemsPerRow: 10,
	paddingBetweenItems: 10,
	galleryWidth: 40,
	shapeBackgroundColor: '#2f4159',
}

export const rootLevelGalleryOptions = {
	shape: GALLERY_SHAPE_ROUNDEDN_BOX,
	shapeShadow: false,
	textShadow: false,
	itemsPerRow: 10,
	paddingBetweenItems: 10,
	galleryWidth: 40,
	shapeBackgroundColor: '#2f4159',
	useFolderIcons: false,
}

export const folderSettings = {
	'background-image': false,
	'logo-image': false,
	folderIconImage: false,
	shortcut: false,
	autoplay: false,
	hidden_library: false,
	hidden_summary: false,
	open_library: false,
	allowMultiSelection: false,
	hidePreviousLevels: false,
	hideBreadcrumbs: false,
	showDescription: false,
	folderDescription: false,
	embeddedUrl: false,
	breadCrumbOrder: null,
	restricted: false,
	visibility: {
		visible: true,
		userGroups: [],
		start_date_visible: null,
		end_date_visible: null,
	},
	isHiddenFromBuild: true,
	isHiddenFromShowRoom: true,
	target: false,
}

export const defaultFontSize = 2.5
