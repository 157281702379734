import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import {
	searchOppFonectaSchema,
	sendCrmAavaSchema,
	sendOppFonectaSchema
} from '../../utils/schemas/requests'
import {
	salesforceSearchOpportunityEndpoint,
	salesforceAddNotesCustomEndpoint,
	getSalesforceOpportunityIdEndpoint,
	salesforceAddNotesEndpoint
} from '../apiEndpoints_new'

interface SearchOpportunityInterface {
	search: string
}

export const searchOpportunityByIdPihlajalinna = (id: string) =>
	new Promise<any>((resolve, reject) => {
		cloudFnGet(`${getSalesforceOpportunityIdEndpoint}/${id}`, {}, {}, null)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const searchOpportunityPihlajalinna = (search: string) =>
	new Promise<SearchOpportunityInterface>((resolve, reject) => {
		cloudFnPost(
			salesforceSearchOpportunityEndpoint,
			{ search },
			{},
			searchOppFonectaSchema
		)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendCrmDataPihlajalinna = (data: any) =>
	new Promise<any>((resolve, reject) => {
		cloudFnPost(
			salesforceAddNotesCustomEndpoint,
			data,
			{},
			sendOppFonectaSchema
		)
			.then((response) => {
				resolve({
					success: true,
					response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const sendCrmDataAava = (data: any) =>
	new Promise<any>((resolve, reject) => {
		cloudFnPost(salesforceAddNotesEndpoint, data, {}, sendCrmAavaSchema)
			.then((response) => {
				resolve({
					success: true,
					response
				})
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
