import hexToRgba from 'hex-to-rgba'

export const defaultDropdownStyles = {
	indicatorsContainer: () => ({
		display: 'none',
	}),
	dropdownIndicator: provided => ({
		...provided,
	}),
	container: provided => ({
		...provided,
		width: '100%',
	}),
	control: provided => ({
		...provided,
		background: hexToRgba('#000000', 0.15),
		borderRadius: 20,
		boxShadow: 'none',
		height: 40,
		whiteSpace: 'nowrap',
		border: 0,
		cursor: 'text',
	}),
	singleValue: provided => ({
		...provided,
		fontSize: 13,
		color: '#ffffff',
	}),
	menu: provided => ({
		...provided,
		background: hexToRgba('#464A54', 1),
		borderRadius: 20,
		overflow: 'hidden',
	}),
	menuList: provided => ({
		...provided,
		maxHeight: 205,
		zIndex: 99,
		'::-webkit-scrollbar': {
			width: 7,
		},
		'::-webkit-scrollbar-track': {
			background: hexToRgba('#464A54', 1),
		},
		'::-webkit-scrollbar-thumb': {
			background: hexToRgba('#000000', 0.5),
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: '#555',
		},
	}),
	option: provided => ({
		...provided,
		background: 'transparent',
		fontSize: 13,
		color: '#ffffff',
		padding: '12px 12px',
		'&:hover': {
			background: hexToRgba('#000000', 0.25),
			cursor: 'pointer',
		},
	}),
	input: provided => ({
		...provided,
		caretColor: '#ffffff',
		color: '#ffffff',
	}),
	valueContainer: provided => ({
		...provided,
	}),
}
