import React, { useEffect, useState } from 'react'
import _, { has, isNull, orderBy } from 'lodash'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowBack, GridView, ViewListOutlined } from '@mui/icons-material'
import { zonedTimeToUtc } from 'date-fns-tz'

import styles from './Sessions.module.scss'

import Stats from '../../Stats'
import { t } from '../../../../../utils/languages/i18n'
import {
	MeetingInterface,
	StoreInterface
} from '../../../../../utils/interfaces'
import {
	MemositeAnalyticsInterface,
	MemositeSingleSessionInterface
} from '../../../interfaces'
import { initialMemositeAnalytics } from '../../../emptyObjectDefinitions'
import {
	getMemositeOpened,
	getMemositeSessions
} from '../../../../../api/requests/memosite'
import Button from '../../../../../components/common/Button'
import { BUTTON_TEXT, ICON_LEFT, LIST } from '../../../../../utils/consts'
import { setMemositeValue } from '../../../../../store/actions/memosite'
import Spinner from '../../../../../components/common/Spinner'
import SlidesContainer from '../../MemositeItem/SlidesContainer'
import { getFormattedDuration } from '../../../../../utils/helpers/timeFormat'

interface SessionsInterface {
	closeSessions: () => void
	memosite: MeetingInterface | undefined
}

const Sessions = ({ closeSessions, memosite }: SessionsInterface) => {
	const dispatch = useDispatch()

	const [memositeAnalytics, setMemositeAnalytics] =
		useState<MemositeAnalyticsInterface>(initialMemositeAnalytics)
	const [sessions, setSessions] = useState<MemositeSingleSessionInterface[]>([])
	const [loadingSessions, setLoadingSessions] = useState<boolean>(false)
	const [loadingStats, setIsLoadingStats] = useState<boolean>(false)

	const { view } = useSelector((store: StoreInterface) => store.memosite)
	const { user } = useSelector((store: StoreInterface) => store.authUser)

	useEffect(() => {
		if (memosite) {
			try {
				setLoadingSessions(true)
				setIsLoadingStats(true)
				getMemositeOpened(memosite.id)
					.then((response) => {
						setMemositeAnalytics(response)
						setIsLoadingStats(false)
					})
					.catch((e) => {
						console.error(e)
						setIsLoadingStats(false)
					})
				getMemositeSessions(memosite.id)
					.then((response) => {
						setLoadingSessions(false)
						if (response) {
							const orderedSessions = orderBy(
								response,
								['event_timestamp'],
								['desc']
							)
							const updatedSessions = orderedSessions.map((session) => {
								// organize slides
								const sortedSlides: any[] = _.sortBy(
									[...session.slides, ...session.pages],
									'attr_time'
								).reverse()
								const highestValue = sortedSlides[0].attr_time || 0
								const slidesWithAnalytics = session.slides.map((slide) => {
									return {
										...slide,
										graphBarValue:
											highestValue === 0
												? 100
												: Math.round((slide.attr_time / highestValue) * 100),
										formattedTime: getFormattedDuration(slide.attr_time)
									}
								})
								const pagesWithAnalytics = session.pages.map((page) => {
									return {
										...page,
										graphBarValue:
											highestValue === 0
												? 100
												: Math.round((page.attr_time / highestValue) * 100),
										formattedTime: getFormattedDuration(page.attr_time),
										name:
											page.attr_label === 'video_greeting'
												? memosite.subject
												: page.name
									}
								})
								return {
									...session,
									slides: slidesWithAnalytics,
									pages: pagesWithAnalytics
								}
							})
							setSessions(updatedSessions)
							setLoadingSessions(false)
						}
					})
					.catch((e) => {
						console.error(e)
						setLoadingSessions(false)
					})
			} catch (e) {
				console.error(e)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getSessionTime = (timestamp: string): string => {
		const hasUserTimeZone =
			has(user.user, 'timezone') &&
			!isNull(user.user.timezone) &&
			user.user.timezone !== ''
		const userTimeZone = hasUserTimeZone
			? JSON.parse(user.user.timezone || '')
			: false
		try {
			if (timestamp) {
				if (userTimeZone) {
					return format(
						zonedTimeToUtc(new Date(`${timestamp} UTC`), userTimeZone.value),
						'dd / MM / yyyy HH:mm'
					)
				} else {
					return format(new Date(timestamp), 'dd / MM / yyyy HH:mm')
				}
			}
			return ''
		} catch {
			return ''
		}
	}

	return (
		<div className={styles.wrapper}>
			{memosite && (
				<div>
					<div className={styles.back} onClick={closeSessions}>
						<ArrowBack /> {t('labels.back')}
					</div>
					<h1>{t('labels.sessions')}</h1>
					<div className={styles.title}>{memosite.subject}</div>
					{(loadingStats || loadingSessions) && (
						<Spinner isLoading={loadingStats} />
					)}
					{!loadingSessions &&
						sessions.length > 0 &&
						sessions.map((item, index: number) => (
							<div className={styles.singleSessionWrapper} key={index}>
								<div className={styles.lineSeparator} />
								<h2>
									{`${t('labels.session')} ${sessions.length - index}`}{' '}
									<span>{getSessionTime(item.event_timestamp)}</span>
								</h2>
								<Stats
									memosite={memosite}
									memositeAnalytics={memositeAnalytics}
									singleSession={item}
								/>
								<div className={styles.contentLabel}>
									<div className={styles.label}>
										<div className={styles.sectionLabel}>
											{t('misc.content_viewed')}
										</div>
										<div>({t('titles.total-time-spent-on-slide')})</div>
									</div>
									<div>
										{view === LIST ? (
											<Button
												type={BUTTON_TEXT}
												buttonClass={styles.button}
												iconSide={ICON_LEFT}
												icon={<GridView htmlColor="#FFFFFF" />}
												label={t('labels.grid-view')}
												onClick={() =>
													dispatch(setMemositeValue('view', 'grid'))
												}
												id="sessions-grid-view-button"
											/>
										) : (
											<Button
												type={BUTTON_TEXT}
												buttonClass={styles.button}
												iconSide={ICON_LEFT}
												icon={<ViewListOutlined htmlColor="#FFFFFF" />}
												label={t('labels.list-view')}
												onClick={() =>
													dispatch(setMemositeValue('view', 'list'))
												}
												id="sessions-list-view-button"
											/>
										)}
									</div>
								</div>
								<SlidesContainer pages={item.pages} slides={item.slides} />
							</div>
						))}
				</div>
			)}
		</div>
	)
}

export default Sessions
