import React from 'react'
import {
	MoreHoriz,
	DeleteOutlined,
	ContentCopy,
	WorkOutline,
	OpenInBrowser,
	Share,
	PeopleOutlined
} from '@mui/icons-material'
import c from 'classnames'
import { format, isToday } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'

import styles from './PresentationItem.module.scss'

import { t } from '../../../../utils/languages/i18n'
import { BUTTON_TEXT, ICON_LEFT } from '../../../../utils/consts'
import Button from '../../../../components/common/Button'
import { MeetingInterface, StoreInterface } from '../../../../utils/interfaces'
import ExtendedPresentationModal from '../ExtendedPresentationModal'
import { setMyPresentationsValue } from '../../../../store/actions/myPresentations'

interface PresentationItemInterface {
	handleOpenPresentation: (presentation: MeetingInterface) => void
	presentation: MeetingInterface
	presentationToDelete: (id: number) => void
	presentationToDuplicate: (presentation: MeetingInterface) => void
	openPresentationAsMemosite: (presentation: MeetingInterface) => void
	presentationToLoad: (presentation: MeetingInterface) => void
	shareWithColleague: (presentation: MeetingInterface) => void
	updatePresentationSubject: (id: number, subject: string) => void
}

const PresentationItem = ({
	handleOpenPresentation,
	presentation,
	presentationToDelete,
	presentationToDuplicate,
	openPresentationAsMemosite,
	presentationToLoad,
	shareWithColleague,
	updatePresentationSubject
}: PresentationItemInterface) => {
	const dispatch = useDispatch()

	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { selectedPresentation, presentationDropdownOpened } = useSelector(
		(store: StoreInterface) => store.myPresentations
	)
	const memositeBuilderStatus = useSelector(
		(store: StoreInterface) => store.memositeBuilder.status
	)

	const dropdown = () => (
		<div className={styles.dropdown}>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={c(styles.button, styles.openButton)}
					iconSide={ICON_LEFT}
					icon={<OpenInBrowser htmlColor="#FFFFFF" />}
					label={t('labels.open-presentation')}
					onClick={() => presentationToLoad(presentation)}
					id="presentation-item-duplicate-presentation-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<Share htmlColor="#FFFFFF" />}
					label={t('buttons.create_memosite')}
					onClick={() => openPresentationAsMemosite(presentation)}
					id="presentation-item-duplicate-presentation-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<PeopleOutlined htmlColor="#FFFFFF" />}
					label={t('buttons.send_to_colleagues')}
					onClick={() => shareWithColleague(presentation)}
					id="presentation-item-duplicate-presentation-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<ContentCopy htmlColor="#FFFFFF" />}
					label={t('labels.duplicate')}
					onClick={() => {
						presentationToDuplicate(presentation)
						dispatch(
							setMyPresentationsValue('presentationDropdownOpened', undefined)
						)
					}}
					id="presentation-item-duplicate-presentation-button"
				/>
			</div>
			<div className={styles.item}>
				<Button
					type={BUTTON_TEXT}
					buttonClass={styles.button}
					iconSide={ICON_LEFT}
					icon={<DeleteOutlined htmlColor="#FFFFFF" />}
					label={t('buttons.delete_presentation')}
					onClick={() => presentationToDelete(presentation.id)}
					id="presentation-item-delete-presentation-button"
				/>
			</div>
		</div>
	)

	return (
		<div
			className={c(styles.wrapper)}
			id={`presentation-item-${presentation.id}`}
		>
			<div
				className={c(
					styles.baseView,
					styles.withBg,
					isToday(new Date(presentation.created_at)) && styles.highlighted
				)}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					if (memositeBuilderStatus !== 'editing') {
						handleOpenPresentation(presentation)
					}
				}}
			>
				<div className={styles.title}>
					<React.Fragment>
						<WorkOutline />
						<div>{presentation.subject}</div>
					</React.Fragment>
				</div>
				<div
					className={c(styles.loadPresentation)}
					title={t('labels.open-presentation')}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						presentationToLoad(presentation)
					}}
				>
					<OpenInBrowser />
				</div>
				{user.user.id === presentation.owner_id && (
					<div
						className={styles.menu}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							dispatch(
								setMyPresentationsValue(
									'presentationDropdownOpened',
									presentationDropdownOpened === presentation.id
										? undefined
										: presentation.id
								)
							)
						}}
					>
						<MoreHoriz
							className={c(
								styles.icon,
								presentationDropdownOpened === presentation.id && styles.zIndex9
							)}
							titleAccess={t('tooltips.actions')}
							id="presentation-item-actions-button"
						/>
						{presentationDropdownOpened === presentation.id && dropdown()}
					</div>
				)}
				<div className={styles.slides}>{presentation.numberOfSlides}</div>
				<div className={c(styles.date, styles.createdDate)}>
					{format(new Date(presentation.created_at), 'dd / MM / yyyy')}
				</div>
				<div className={c(styles.date, styles.lastOpened)}>
					<p className={styles.lastOpenedDate}>
						{format(
							new Date(presentation.updated_at.replace(/\s/g, 'T')),
							'dd / MM / yyyy'
						)}
					</p>
				</div>
			</div>
			{selectedPresentation?.id === presentation.id &&
				memositeBuilderStatus !== 'editing' && (
					<ExtendedPresentationModal
						presentation={presentation}
						presentationToDelete={presentationToDelete}
						presentationToDuplicate={presentationToDuplicate}
						openPresentationAsMemosite={openPresentationAsMemosite}
						presentationToLoad={presentationToLoad}
						shareWithColleague={shareWithColleague}
						updatePresentationSubject={updatePresentationSubject}
					/>
				)}
		</div>
	)
}

export default PresentationItem
