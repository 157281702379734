import React from 'react'
import PropTypes from 'prop-types'

import styles from './Results.module.scss'

const Results = (props) => {
	const { id, name, BIN, getAccountId } = props

	function handleSelect() {
		getAccountId(id)
	}

	return (
		<div className={styles.customerItem} tabIndex="0" onClick={handleSelect}>
			<div className={styles}>
				<p className={styles.feedItemText}>
					<strong>{name}</strong>
					<strong>{BIN}</strong>
				</p>
			</div>
		</div>
	)
}

Results.defaultProps = {
	name: '',
	getAccountId: null,
	BIN: null,
	id: null
}

Results.propTypes = {
	id: PropTypes.string.isRequired,
	BIN: PropTypes.string.isRequired,
	getAccountId: PropTypes.func,
	name: PropTypes.string.isRequired
}

export default Results
