import React from 'react'
import { WorkOutline } from '@mui/icons-material'
import c from 'classnames'

import styles from './PendingPresentationItem.module.scss'
import Button from '../../../../components/common/Button'
import { BUTTON_NORMAL } from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'

interface PresentationItemInterface {
	presentation: {
		id: string
		subject: string
		invited_by: string
		notificationId: number
	}
	presentationToAccept: (
		id: string,
		notificationId: number,
		isDeclined?: boolean
	) => void
}

const PendingPresentationItem = ({
	presentation,
	presentationToAccept
}: PresentationItemInterface) => {
	return (
		<div className={styles.wrapper} id={`presentation-item-${presentation.id}`}>
			<div className={c(styles.baseView, styles.withBg)}>
				<div className={styles.title}>
					<React.Fragment>
						<WorkOutline />
						<div>
							<div className={styles.lighter}>
								{`${presentation.invited_by} ${t(
									'misc.shared_a_presentation_with_you'
								)}`}
								:
							</div>
							<div className={styles.bolder}>{presentation.subject}</div>
						</div>
					</React.Fragment>
				</div>
				<div className={styles.buttons}>
					<Button
						type={BUTTON_NORMAL}
						label={t('buttons.dismiss')}
						onClick={() =>
							presentationToAccept(
								presentation.id,
								presentation.notificationId,
								true
							)
						}
						buttonClass={styles.btn}
						containerClass={styles.btnContainer}
						id="my-presentations-pending-presentation-dismiss-button"
					/>
					<Button
						type={BUTTON_NORMAL}
						isPositive
						label={t('buttons.accept')}
						onClick={() =>
							presentationToAccept(presentation.id, presentation.notificationId)
						}
						buttonClass={styles.btn}
						containerClass={styles.btnContainer}
						id="my-presentations-pending-presentation-accept-button"
					/>
				</div>
			</div>
		</div>
	)
}

export default PendingPresentationItem
