import { ADD_TO_ANALYTICS_BATCH, CLEAR_ANALYTICS_BATCH } from '../actions/actionTypes'

const initialState = {
	batch: [],
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
	case ADD_TO_ANALYTICS_BATCH:
		return {
			...state,
			batch: state.batch.concat(action.payload.analyticsPayload),
		}
	case CLEAR_ANALYTICS_BATCH:
		return {
			...state,
			batch: initialState.batch,
		}
	default:
		return state
	}
}

export default reducer
