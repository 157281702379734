import {
	RESET_MY_PRESENTATIONS_REDUCER,
	SET_MY_PRESENTATIONS_VALUE
} from '../actions/actionTypes'

const initialState = {
	presentations: [],
	presentationsLoaded: false,
	selectedPresentation: undefined,
	view: 'grid',
	presentationDropdownOpened: undefined,
	reloadPresentations: false
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_MY_PRESENTATIONS_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value
			}
		case RESET_MY_PRESENTATIONS_REDUCER:
			return initialState
		default:
			return state
	}
}

export default reducer
