import React from 'react'
import Proptypes from 'prop-types'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import { t } from '../../utils/languages/i18n'
import SfModal from '../common/ModalNew'
import styles from './InstanceSwitchModal.module.scss'
import {
	INSTANCE_SWITCH_MODAL,
	FLEX_START,
	FLEX_DIRECTION_COL,
	FLEX_END
} from '../../utils/consts'
import Section from '../common/Section'
import Button from '../common/Button'
import { axiosInstance } from '../../utils/requests'
import { usersLogoutEndpoint } from '../../api/apiEndpoints_new'
import getSalesframeAPIUrl from '../../utils/getSalesframeAPIUrl'

const InstanceSwitchModal = (props) => {
	const { isActive, handleClose } = props
	const { instances } = useSelector((state) => state.authUser)
	const authToken = useSelector((store) => store.authUser.user.token)

	/**
	 * @description certaes oauth url based on the selected instance
	 */
	function handleInstanceSwitching(instance) {
		axiosInstance
			.delete(usersLogoutEndpoint, {
				headers: {
					Authorization: `Bearer ${authToken}`
				}
			})
			.then(() => {
				Cookies.remove('JWT')
				Cookies.remove('KMSI')
				localStorage.clear()
				window.location.href = `${getSalesframeAPIUrl()}/oauth/${instance.code}`
			})
	}

	return (
		<SfModal
			isShowing={isActive}
			hide={handleClose}
			customClassName={styles.instanceSwitchModal}
			modal={INSTANCE_SWITCH_MODAL}
			modalTitle={t('titles.switch-instance')}
		>
			<Section
				sectionClassName={styles.section}
				padding="30px"
				justify={FLEX_START}
				flexDirection={FLEX_DIRECTION_COL}
				alignItems={FLEX_START}
				subTitle={t('titles.select-instance')}
			>
				<div className={styles.instanceSelectors}>
					{instances &&
						instances.map((instance) => (
							<Button
								key={instance.code}
								label={instance.name}
								onClick={() => handleInstanceSwitching(instance)}
								isPositive
								id="instance-switch-button"
							/>
						))}
				</div>
			</Section>
			<Section
				justify={FLEX_END}
				padding="30px"
				sectionClassName={styles.instanceSwitchModalFooter}
			>
				<Button
					label={t('buttons.cancel')}
					onClick={() => handleClose()}
					buttonClass={styles.cancelBtn}
					id="instance-switch-cancel-button"
				/>
			</Section>
		</SfModal>
	)
}

InstanceSwitchModal.defaultProps = {
	isActive: null
}

InstanceSwitchModal.propTypes = {
	handleClose: Proptypes.func.isRequired,
	isActive: Proptypes.bool
}

export default InstanceSwitchModal
