import { intervalToDuration } from 'date-fns'

export const getFormattedDuration = (time: number): string => {
	try {
		if (time && time.toString() !== 'NaN') {
			const duration = intervalToDuration({ start: 0, end: time * 1000 })
			const minutes =
				duration.minutes && duration.minutes > 0 ? `${duration.minutes}m ` : ''
			const hours =
				duration.hours && duration.hours > 0 ? `${duration.hours}h ` : ''
			return `${hours}${minutes}${duration.seconds}s`
		}
		return '0s'
	} catch {
		return '0s'
	}
}
