import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { nanoid } from 'nanoid'
import c from 'classnames'

import { defaultDropdownStyles } from './utils/styles'

const Dropdown = ({
	className,
	defaultIndex,
	isDisabled,
	listItems,
	onChangeCallback,
	placeholder,
	placement,
	styles,
	name,
	value
}) => (
	<Select
		className={c('react-select', className)}
		classNamePrefix="react-select"
		defaultValue={defaultIndex >= 0 && listItems[defaultIndex]}
		isDisabled={isDisabled || false}
		isLoading={false}
		isClearable
		isSearchable
		name={name}
		options={listItems}
		styles={styles}
		onChange={(selected) => onChangeCallback(selected)}
		menuPlacement={placement}
		{...(placeholder && { placeholder })}
		value={value || undefined}
	/>
)

Dropdown.defaultProps = {
	className: null,
	defaultIndex: 0,
	name: nanoid(),
	onChangeCallback: () => {},
	placeholder: null,
	placement: 'bottom',
	styles: defaultDropdownStyles,
	value: null
}

Dropdown.propTypes = {
	className: PropTypes.string,
	defaultIndex: PropTypes.number,
	isDisabled: PropTypes.bool,
	listItems: PropTypes.instanceOf(Object).isRequired,
	name: PropTypes.string,
	onChangeCallback: PropTypes.func,
	placeholder: PropTypes.string,
	placement: PropTypes.string,
	styles: PropTypes.instanceOf(Object),
	value: PropTypes.shape({
		id: PropTypes.string,
		label: PropTypes.string
	})
}

export default Dropdown
