import React, { useState } from 'react'
import reactStringReplace from 'react-string-replace'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import ConfirmDeleteModal from '../common/ConfirmDeleteModal'
import { t } from '../../utils/languages/i18n'
import styles from './TermsConditionsModal.module.scss'
import { setTempValue } from '../../store/actions/temp'
import { saveConfig } from '../../api/requests/config'
import { StoreInterface } from '../../utils/interfaces'

const TermsConditionsModal = () => {
	const [showSecondModal, setShowSecondModal] = useState(false)
	const [disableButton, setDisableButton] = useState(false)
	const { showTermsModal } = useSelector((state: StoreInterface) => state.temp)
	const userId = useSelector(
		(state: StoreInterface) => state.authUser.user.user.id
	)
	const dispatch = useDispatch()

	function replaceLinks() {
		let content: any = t('misc.terms-content-line-2')
		content = reactStringReplace(
			content,
			'{terms-of-service-text}',
			(match, i) => (
				<a
					key={i}
					href="https://www.salesframe.com/terms-of-service"
					target="_blank"
					rel="noreferrer"
				>
					{t('misc.terms-of-service-text')}
				</a>
			)
		)
		content = reactStringReplace(
			content,
			'{privacy-policy-text}',
			(match, i) => (
				<a
					key={i}
					href="https://www.salesframe.com/privacy-policy"
					target="_blank"
					rel="noreferrer"
				>
					{t('misc.privacy-policy-text')}
				</a>
			)
		)
		return content
	}

	function firstModalCancelHandler() {
		setShowSecondModal(true)
	}

	function goBackHandler() {
		setShowSecondModal(false)
	}

	function termsNotAcceptedHandler() {
		setShowSecondModal(false)
		dispatch(setTempValue('showTermsModal', false))
		Cookies.remove('JWT')
		Cookies.remove('KMSI')
		localStorage.clear()
		window.location.href = '/login'
	}

	function acceptTermsHandler() {
		setDisableButton(true)
		saveConfig(`user.terms.${userId}`, 'true').then(() => {
			setDisableButton(false)
			dispatch(setTempValue('showTermsModal', false))
			dispatch(
				setTempValue('termsAccepted', {
					isNew: false,
					value: true
				})
			)
		})
	}

	return (
		<React.Fragment>
			<ConfirmDeleteModal
				isActive={showTermsModal}
				handleClose={
					showSecondModal ? termsNotAcceptedHandler : firstModalCancelHandler
				}
				title={t('titles.accept-terms-conditions')}
				onApprove={showSecondModal ? goBackHandler : acceptTermsHandler}
				disableButton={disableButton}
				confirmLabel={
					showSecondModal
						? t('buttons.go-back')
						: t('buttons.accept-and-continue')
				}
				cancelLabel={
					showSecondModal
						? t('buttons.do-not-accept-terms')
						: t('buttons.cancel')
				}
				disableOverlayClose
			>
				<div className={styles.termsContent}>
					{showSecondModal ? (
						<React.Fragment>
							<p>{t('misc.terms-content-second-modal-line-1')}</p>
							<p>{t('misc.terms-content-second-modal-line-2')}</p>
						</React.Fragment>
					) : (
						<React.Fragment>
							<p>{t('misc.terms-content-line-1')}</p>
							<p>{replaceLinks()}</p>
							<p>{t('misc.terms-content-line-3')}</p>
						</React.Fragment>
					)}
				</div>
			</ConfirmDeleteModal>
		</React.Fragment>
	)
}

export default TermsConditionsModal
