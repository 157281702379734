import { orderBy } from 'lodash'

export const getMemositeLink = (
	instanceName: string,
	slug: string,
	userRole: string,
	useHttpsPrefix: boolean
): string => {
	if (window.location.origin === 'https://app.salesframe.com') {
		return `${
			useHttpsPrefix ? 'https://' : ''
		}${instanceName}.salesframe.com/content?id=${slug}`
	}
	if (window.location.origin === 'https://memosites.io') {
		return `https://staging.salesfra.me/sent/${slug}`
	}
	if (
		process.env.REACT_APP_ENV === 'development' &&
		process.env.REACT_APP_MEMOSITE_URL
	) {
		return `${process.env.REACT_APP_MEMOSITE_URL}/sent/${slug}`
	}
	if (
		process.env.REACT_APP_ENV === 'staging' ||
		process.env.REACT_APP_ENV === 'development'
	) {
		return `https://staging.salesfra.me/sent/${slug}`
	}
	return `${
		useHttpsPrefix ? 'https://' : ''
	}${instanceName}.salesfra.me/sent/${slug}`
}

export const sortMemosites = (
	data: any[],
	sort: 'asc' | 'desc',
	field: string
) => {
	const fakeSortValue =
		sort === 'desc' ? '00000000000000000' : 'zzzzzzzzzzzzzzz'
	return orderBy(
		data,
		[
			(item) => {
				if (!item[field] || item[field] === 'no_data') {
					return fakeSortValue
				}
				return item[field]
			}
		],
		sort
	)
}

export const incrementIfEndsWithNumber = (inputString: string) => {
	// Use a regular expression to check if the string ends with a number
	const regex = /\d+$/
	const match = inputString.match(regex)

	// If there is a match (string ends with a number), increment the number by 1
	if (match) {
		const numero = parseInt(match[0], 10)
		const incrementedNumber = (numero + 1)
			.toString()
			.padStart(match[0].length, '0')
		const incrementedString = inputString.replace(regex, incrementedNumber)
		return incrementedString
	}
}
