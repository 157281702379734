import React, { useEffect, useState } from 'react'

import styles from './Countdown.module.scss'

interface CountDownInterface {
	countdownTime?: number
	onEnd: () => void
}

const CountDown = ({ countdownTime = 3, onEnd }: CountDownInterface) => {
	const [seconds, setSeconds] = useState(countdownTime)

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds !== 0) {
				setSeconds((rem) => rem - 1)
			} else {
				onEnd()
			}
		}, 1000)
		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seconds])

	return (
		<div className={styles.countdownContainer}>
			<p>{seconds}</p>
		</div>
	)
}

export default CountDown
