import React from 'react'
import { toast } from 'react-toastify'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import enLocale from 'date-fns/locale/en-US'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { VisibilityOutlined as CharactersIcon } from '@mui/icons-material'
import c from 'classnames'

import styles from './MemositeSettingsModal.module.scss'

import Modal from '../../components/common/ModalNew'
import Button from '../../components/common/Button'
import { t } from '../../utils/languages/i18n'
import Input from '../../components/common/Input'
import CheckBox from '../../components/common/CheckBox'
import { dateTimePickerTheme } from '../../utils/theme'
import {
	changeMemositeCode,
	updateMemosite,
	deleteMemositeCode
} from '../../api/requests/memosite'
import { ModuleNamesInterface, StoreInterface } from '../../utils/interfaces'
import MemositeLabelsManager from '../MemositeLabelsManager'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import { BUTTON_TEXT, ICON_LEFT } from '../../utils/consts'
import { setTempValue } from '../../store/actions/temp'

const MemositeSettingsModal = () => {
	const dispatch = useDispatch()

	const memosite = useSelector(
		(store: StoreInterface) => store.memositeBuilder.settingsModalData
	)
	const { currentModule } = useSelector((store: StoreInterface) => store.temp)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const memosites = useSelector(
		(store: StoreInterface) => store.memosite.memosites
	)

	const [expiryDateChecked, setExpiryDateChecked] = React.useState(
		!!memosite.expiry_date
	)
	const [expiryDateValue, setExpiryDateValue] = React.useState<any>(
		memosite.expiry_date
	)
	const [password, setPassword] = React.useState('')
	const [passwordChecked, setPasswordChecked] = React.useState(
		memosite.is_secured
	)
	const [showPassword, setShowPassword] = React.useState(false)
	const [emailNotification, setEmailNotification] = React.useState(
		memosite.send_notification !== 5
	)
	const [memositeLabelsOpened, toggleMemositeLabelsOpened] =
		React.useState(false)

	/**
	 * @description update expiryDate value in memosite
	 */
	const updateExpiryDateHandler = async () => {
		const expiryDateFormatted =
			expiryDateValue &&
			format(new Date(expiryDateValue), 'yyyy-MM-dd HH:mm:ss')
		const extendedFieldsUpdated = {
			...JSON.parse(memosite.extended_fields),
			expiryDate: expiryDateChecked ? expiryDateFormatted : null
		}
		const memositeUpdated = {
			...memosite,
			extended_fields: JSON.stringify(extendedFieldsUpdated)
		}
		await updateMemosite(memositeUpdated, authToken)
	}

	/**
	 * @description update memosite password
	 */
	const updatePasswordHandler = async () => {
		if (passwordChecked) {
			const data = {
				meeting_id: memosite.id,
				code: password
			}
			await changeMemositeCode(data, authToken)
		} else {
			await deleteMemositeCode(authToken, memosite.id)
		}
	}

	/**
	 * @description update email notification settings on checkbox change
	 */
	const updateEmailNotificationHandler = () => {
		const memositeUpdated = {
			...memosite,
			send_notification: emailNotification ? 5 : 1
		}
		updateMemosite(memositeUpdated, authToken)
			.then(() => {
				toast(t('notifications.success.updated-successfully'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: toast.TYPE.SUCCESS,
					autoClose: 5000
				})
				// its important to toggle the state here!
				setEmailNotification(!emailNotification)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const closeModalHandler = () => {
		dispatch(setMemositeBuilderValue('settingsModalVisible', false))
		dispatch(setMemositeBuilderValue('settingsModalData', undefined))
	}

	const updateMemositeHandler = async () => {
		if (memosite.is_secured !== passwordChecked) {
			await updatePasswordHandler()
		}
		if (!!memosite.expiry_date !== expiryDateChecked) {
			await updateExpiryDateHandler()
		}
		toast(t('notifications.success.updated-successfully'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: toast.TYPE.SUCCESS,
			autoClose: 5000
		})
		if (currentModule === ModuleNamesInterface.FOLLOW_UP) {
			dispatch(setTempValue('reloadMemosites', true))
		}
		closeModalHandler()
	}

	return (
		<Modal
			isShowing
			hide={closeModalHandler}
			modalTitle={t('labels.memosite-settings')}
			customClassName={styles.settingsModal}
			disableOverlayClose={false}
			footer={
				<>
					<Button
						label={t('buttons.cancel')}
						onClick={closeModalHandler}
						id="memosite-settings-modal-cancel"
					/>
					<Button
						label={t('buttons.save_and_close')}
						isPositive
						onClick={updateMemositeHandler}
						isDisabled={false}
						id="memosite-settings-modal-save"
					/>
				</>
			}
			zIndex={10001}
		>
			<div
				className={c(
					styles.content,
					memositeLabelsOpened && styles.expandHeight
				)}
			>
				<div className={styles.row}>
					<div>
						<CheckBox
							isChecked={expiryDateChecked}
							onChange={() => setExpiryDateChecked(!expiryDateChecked)}
							name={t('input-labels.expiration_date')}
							label={t('input-labels.expiration_date')}
							wrapperClassName={styles.expiryDateCheckboxWrapper}
							id="memosite-settings-modal-expiry-date-checkbox"
						/>
						<ThemeProvider theme={dateTimePickerTheme}>
							<MuiPickersUtilsProvider locale={enLocale} utils={DateFnsUtils}>
								<div className={styles.datePickerWrapper}>
									<DatePicker
										className={c(
											styles.datePicker,
											!expiryDateChecked && styles.disabled
										)}
										variant="inline"
										hiddenLabel
										format="yyyy / MM / dd"
										value={expiryDateValue}
										onChange={(val) => setExpiryDateValue(val)}
										disabled={!expiryDateChecked}
									/>
								</div>
							</MuiPickersUtilsProvider>
						</ThemeProvider>
					</div>
					<div>
						<div className={styles.flex}>
							<CheckBox
								isChecked={passwordChecked}
								onChange={() => setPasswordChecked(!passwordChecked)}
								name={t('labels.password')}
								label={t('input-labels.memosite_password')}
								id="memosite-settings-modal-password-checkbox"
							/>
							<Button
								type={BUTTON_TEXT}
								containerClass={styles.btnTextWrapper}
								buttonClass={styles.charactersBtn}
								iconSide={ICON_LEFT}
								icon={<CharactersIcon htmlColor="#FFFFFF" />}
								label={t('buttons.characters')}
								onClick={() => setShowPassword(!showPassword)}
								id="memosite-settings-modal-show-password"
							/>
						</div>
						<Input
							type={showPassword ? 'text' : 'password'}
							placeholderText={t('input-placeholders.set_password')}
							name={t('labels.password')}
							inputClassName={styles.passwordInput}
							wrapperClassName={styles.inputWrapper}
							onChange={(e) => setPassword(e.target.value)}
							initialValue={password}
							isDisabled={!passwordChecked}
						/>
					</div>
				</div>
				<div className={styles.labelsWrapper}>
					<MemositeLabelsManager
						labelsOpened={toggleMemositeLabelsOpened}
						memosite={memosite}
						memosites={memosites}
						role="settings_modal"
					/>
				</div>
				<CheckBox
					wrapperClassName={styles.checkboxWrapper}
					isChecked={emailNotification}
					onChange={updateEmailNotificationHandler}
					label={t('misc.email-notification-when-opened')}
					name={t('misc.email-notification-when-opened')}
					id="memosite-settings-modal-email-notification-checkbox"
				/>
			</div>
		</Modal>
	)
}

export default MemositeSettingsModal
