import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInSeconds } from 'date-fns'
import {
	AccountBalanceOutlined as FolderIcon,
	Info as InfoIcon,
	VerticalSplitOutlined as NavigationToggleIcon
} from '@mui/icons-material'
import c from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { flatMapDeep } from 'lodash'

import styles from './Library.module.scss'

import Folder from '../../components/FileBrowser/components/Folder'
import {
	setFolderPath,
	setSelectedBuildMenuItem,
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading as toggleLoadingState
} from '../../store/actions/buildContext'
import { getContentByTagId } from '../../store/actions/content'
import {
	ModuleNamesInterface,
	StoreInterface,
	TagInterface
} from '../../utils/interfaces'
import Embedded from '../../components/FileBrowser/components/EmbeddedModal'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { setTempValue } from '../../store/actions/temp'
import { SelectedTagInterface } from '../../components/FileBrowser/components/Folder/interfaces'
import { t } from '../../utils/languages/i18n'
import {
	convertBusinessRules,
	isTagVisible
} from '../../utils/convertBusinessRules'
import { BUILD, BUTTON_TEXT, ICON_LEFT, LIBRARY } from '../../utils/consts'
import FileBrowser from '../../components/FileBrowser'
import Button from '../../components/common/Button'

let embeddedContentOpenedTime: Date | 0

const Library = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const { tagId } = params
	const navigate = useNavigate()

	const [selectedTagToEmbed, setSelectedTagToEmbed] = useState<any>()

	const tagTree = useSelector((state: StoreInterface) => state.tags.tagTree)
	const authToken = useSelector(
		(store: StoreInterface) => store.authUser.user.token
	)
	const currentUserGroup = useSelector(
		(store: StoreInterface) => store.authUser.user.user.usergroups_id
	)
	const libraryHomepageVisible = useSelector(
		(store: StoreInterface) => store.temp.libraryHomepageVisible
	)
	const { isLoading } = useSelector(
		(store: StoreInterface) => store.buildContext
	)
	const { sidebarNavsDisabled, isSidebarOpened } = useSelector(
		(store: StoreInterface) => store.temp
	)

	React.useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.LIBRARY))
		dispatch(setSelectedBuildMenuItem(LIBRARY))
		if (!tagId) {
			dispatch(setTempValue('libraryHomepageVisible', true))
		}
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.Library`
			})
		)
		return () => {
			dispatch(
				setSelectedCategory({
					id: null,
					categoryName: ''
				})
			)
			dispatch(setFolderPath([]))
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.Library`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (tagTree.length > 0) {
			if (tagId) {
				const flatten: any = (item: any) => [
					item,
					flatMapDeep(item.children, flatten)
				]
				const flatTagTree: TagInterface[] = flatMapDeep(tagTree, flatten)
				const tagToSelect = flatTagTree.find(
					(tag: TagInterface) => tag.tag_id === Number(tagId)
				)
				if (tagToSelect) {
					goToFolder(tagToSelect)
					dispatch(setTempValue('libraryTreeSelectedTagId', tagToSelect.tag_id))
					dispatch(setTempValue('libraryHomepageVisible', false))
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tagTree])

	/**
	 *
	 * @description sets the state to selected tag and openedTime constant to
	 * current Date value
	 * @param {object} tag object representation of the selected tag
	 */
	const embeddedUrlHandler = (tag: SelectedTagInterface) => {
		setSelectedTagToEmbed(tag)
		embeddedContentOpenedTime = new Date()
	}

	/**
	 *
	 * @description when user clicks on folder name in breadcrumbs
	 * it will take him to that folder and fetch the content
	 * @param {object} tag object representation of the selected tag
	 */
	const goToFolder = (tag: TagInterface) => {
		dispatch(setTempValue('libraryHomepageVisible', false))
		dispatch(setFolderPath(tag.path))
		dispatch(toggleLoadingState(true))
		dispatch(getContentByTagId(tag.tag_id, authToken))
		dispatch(
			setSelectedCategory({
				...tag,
				id: tag.tag_id,
				categoryName: tag.filename
			})
		)
		setSelectedSlides([])
		navigate(`/${LIBRARY.toLowerCase()}/${tag.tag_id}`)
	}

	/**
	 *
	 * @description checks for how long the embedded url was opened, sends the
	 * analytics and then sets values back to previous state
	 */
	const closeEmbedModalHandler = () => {
		const time = differenceInSeconds(new Date(), embeddedContentOpenedTime)
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.storyboard.external.opened.time',
				tag_id: selectedTagToEmbed.id,
				name: selectedTagToEmbed.name,
				time
			})
		)
		setSelectedTagToEmbed(undefined)
		embeddedContentOpenedTime = 0
	}

	const decideEmbedOrOpenInNew = () => {
		if (selectedTagToEmbed && selectedTagToEmbed.target) {
			window.open(selectedTagToEmbed.embeddedUrl, '_blank')
			setSelectedTagToEmbed(undefined)
		} else {
			return (
				<Embedded
					isShowing={!!selectedTagToEmbed}
					closeModal={closeEmbedModalHandler}
					embeddedUrl={selectedTagToEmbed ? selectedTagToEmbed.embeddedUrl : ''}
				/>
			)
		}
	}

	return (
		<div className={styles.wrapper}>
			{libraryHomepageVisible ? (
				<div
					className={c(
						styles.homepage,
						!isSidebarOpened && styles.additionalLeftMarginSmallScreens,
						!isSidebarOpened &&
							sidebarNavsDisabled &&
							styles.additionalLeftMargin
					)}
				>
					<div className={styles.heading}>
						<div>
							<div
								title={t('tooltips.show_hide_folder_structure')}
								className={styles.navToggleIcon}
								onClick={() =>
									dispatch(
										setTempValue('sidebarNavsDisabled', !sidebarNavsDisabled)
									)
								}
							>
								<NavigationToggleIcon />
							</div>
							<Button
								containerClass={styles.featuredBtnWrapper}
								buttonClass={styles.featuredBtnText}
								type={BUTTON_TEXT}
								iconSide={ICON_LEFT}
								icon={<InfoIcon />}
								onClick={() => null}
								id="library-homepage-featured-guide-button"
								tooltip={t('tooltips.quick_guide_for', [t('labels.library')])}
							/>
						</div>
						<FolderIcon className={styles.folderIcon} />
						{t('labels.library')}
					</div>
					<div className={styles.fileContainer}>
						{tagTree.map(
							(currentTag: TagInterface) =>
								isTagVisible(
									convertBusinessRules(currentTag.business_rules),
									currentUserGroup,
									BUILD
								) && (
									<Folder
										name={currentTag.name}
										key={currentTag.tag_id}
										tag={currentTag}
										selectedEmbeddedTag={(tag) => embeddedUrlHandler(tag)}
										selectedTag={(tag) => goToFolder(tag)}
									/>
								)
						)}
					</div>
					{!!selectedTagToEmbed && decideEmbedOrOpenInNew()}
				</div>
			) : (
				<FileBrowser
					handleNavigation={(value) =>
						dispatch(setSelectedBuildMenuItem(value))
					}
					isLoading={isLoading}
				/>
			)}
		</div>
	)
}

export default Library
