import { TagInterface } from '../../utils/interfaces'
import { cloudFnGet } from '../../utils/requests'
import { featuredFoldersEndpoint } from '../apiEndpoints_new'

export const getFeaturedFolders = () =>
	new Promise<TagInterface[]>((resolve, reject) => {
		cloudFnGet(`${featuredFoldersEndpoint}`, null, { withToken: true })
			.then((response) => {
				resolve(response as TagInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
