const getMimeTypes = (extension: string): string => {
	switch (extension) {
		case 'mp4':
			return 'video/mp4'
		case 'webm':
			return 'video/webm'
		default:
			return ''
	}
}

export default getMimeTypes
