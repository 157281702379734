import React from 'react'
import styled from 'styled-components'

const SectionElement = styled.div<{
	flexDirection: string
	justify: string
	alignItems: string
	padding?: string | number
}>`
	padding: ${(props) => props.padding || '20px'};
	flex-direction: ${(props) => props.flexDirection};
	justify-content: ${(props) => props.justify};
	align-items: ${(props) => props.alignItems};
	display: flex;
`

interface SectionInterface {
	padding?: string
	flexDirection?: string
	justify?: string
	alignItems?: string
	children?: any
	title?: string
	sectionClassName?: string
	subTitle?: string
}

const Section = ({
	padding,
	flexDirection = 'row',
	justify = 'center',
	alignItems = 'center',
	children,
	title,
	sectionClassName,
	subTitle
}: SectionInterface) => {
	return (
		<SectionElement
			padding={padding}
			flexDirection={flexDirection}
			justify={justify}
			alignItems={alignItems}
			className={sectionClassName}
		>
			{title && <h1>{title}</h1>}
			{subTitle && <h2>{subTitle}</h2>}
			{children}
		</SectionElement>
	)
}

export default Section
