import styled from 'styled-components'

const Wrapper = styled.nav`
	display: block;
	display: flex;
	flex-direction: row;
	max-height: calc(100% - ${props => props.minus}px);
`

export default Wrapper
