import {
	ADD_TO_TEMP_PRESENTATION,
	CLEAR_TEMP_PRESENTATION,
	CLEAR_TEMP_REDUCER,
	RESOLVE_CRITICAL_ERROR,
	SET_GALLERY_SHALLOW_COPY,
	SET_LAYOUT_SHALLOW_COPY,
	SET_TEMP_SHORTCUTS,
	THROW_CRITICAL_ERROR,
	SET_TEMP_VALUE
} from './actionTypes'

export const addToTempPresentation =
	(slide: any, key: any) => (dispatch: any) => {
		dispatch({
			type: ADD_TO_TEMP_PRESENTATION,
			payload: {
				slide,
				key: key.toLowerCase()
			}
		})
	}

export const clearTempReducer = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_TEMP_REDUCER,
		payload: undefined
	})
}

export const clearTempPresentation = (role: any) => (dispatch: any) => {
	dispatch({
		type: CLEAR_TEMP_PRESENTATION,
		payload: {
			key: role.toLowerCase()
		}
	})
}

export const setTempShortcuts = (tempShortcuts: any) => (dispatch: any) => {
	dispatch({
		type: SET_TEMP_SHORTCUTS,
		payload: {
			shortcuts: tempShortcuts
		}
	})
}

export const setLayoutShallowCopy =
	(layoutShallowCopy: any) => (dispatch: any) => {
		dispatch({
			type: SET_LAYOUT_SHALLOW_COPY,
			payload: {
				shallowCopy: layoutShallowCopy
			}
		})
	}

export const setGalleryOptionsShallowCopy =
	(galleryOptionsShallowCopy: any) => (dispatch: any) => {
		dispatch({
			type: SET_GALLERY_SHALLOW_COPY,
			payload: {
				shallowCopy: galleryOptionsShallowCopy
			}
		})
	}

export const throwCriticalErrorMessage =
	(errorMessage: any) => (dispatch: any) => {
		dispatch({
			type: THROW_CRITICAL_ERROR,
			payload: {
				errorMessage
			}
		})
	}

export const resolveCriticalError = () => (dispatch: any) => {
	dispatch({
		type: RESOLVE_CRITICAL_ERROR,
		payload: undefined
	})
}

export const setTempValue = (name: any, value: any) => (dispatch: any) => {
	dispatch({
		type: SET_TEMP_VALUE,
		payload: {
			name,
			value
		}
	})
}
