import React, { useState } from 'react'
import c from 'classnames'
import styled from 'styled-components'
import styles from './Button.module.scss'

interface ButtonElementInterface {
	height?: number | undefined
	leftPadding?: number | undefined
	rightPadding?: number | undefined
	topPadding?: number | undefined
	bottomPadding?: number | undefined
}

interface ButtonInterface extends ButtonElementInterface {
	type?: 'button' | 'submit' | 'reset'
	label?: string | undefined
	icon?: JSX.Element
	iconPosition?: 'left' | 'right'
	iconOnly?: boolean
	noPadding?: boolean
	onClick?: () => void | undefined
	variant?: 'clean' | 'default' | 'primary'
	hasRadius?: boolean
	disabled?: boolean
	hasTooltip?: boolean
	breadcrumbs?: string
	selected?: boolean
	build?: boolean
}

const ButtonElement = styled.button`
	height: ${(props: ButtonElementInterface) => `${props.height}px` || 'auto'};
	padding-left: ${(props: ButtonElementInterface) =>
		props.leftPadding ? `${props.leftPadding}px` : 'inherit'};
	padding-right: ${(props: ButtonElementInterface) =>
		props.rightPadding ? `${props.rightPadding}px` : 'inherit'};
`

const FolderButton = ({
	type = 'button',
	label,
	icon,
	iconPosition = 'left',
	iconOnly = false,
	noPadding = false,
	onClick,
	variant = 'clean',
	hasRadius = false,
	height,
	leftPadding,
	rightPadding,
	topPadding,
	bottomPadding,
	disabled = false,
	hasTooltip = false,
	breadcrumbs = '',
	selected = false,
	build = false
}: ButtonInterface) => {
	const [isToolTipVisible, setIsToolTipVisible] = useState(false)
	return (
		<div
			onMouseEnter={() => setIsToolTipVisible(true)}
			onMouseLeave={() => setIsToolTipVisible(false)}
			className={build ? styles.rootBuild : styles.rootAdmin}
		>
			{hasTooltip && isToolTipVisible && (
				<div className={styles.tooltipContainer}>
					<p>{breadcrumbs}</p>
				</div>
			)}
			<ButtonElement
				type={type}
				className={c(
					styles.button,
					!noPadding && styles.buttonPadding,
					styles[`${variant}Button`],
					hasRadius && styles.radiusButton,
					selected && styles.buttonSelected
				)}
				onClick={onClick}
				disabled={disabled}
				{...(height && { height })}
				{...(leftPadding && { leftPadding })}
				{...(rightPadding && { rightPadding })}
				{...(topPadding && { topPadding })}
				{...(bottomPadding && { bottomPadding })}
			>
				{icon && iconPosition === 'left' && (
					<div
						className={c(
							styles.buttonIcon,
							!iconOnly && styles.iconLeftContainer
						)}
					>
						{icon}
					</div>
				)}
				{label && <div className={c(styles.buttonLabel)}>{label}</div>}
				{icon && iconPosition === 'right' && icon}
			</ButtonElement>
		</div>
	)
}

export default FolderButton
