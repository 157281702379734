import {
	ADD_TO_PRESENTATION,
	DELETE_FROM_PRESENTATION,
	CLEAR_PRESENTATION,
	DUPLICATE_BY_ID,
	REMOVE_BY_INDEX,
	REMOVE_ALL_LOADING_THUMBNAILS,
	CREATE_SLIDESHOW,
	CLEAR_SLIDESHOW,
	SET_LOADED_PRESENTATION,
	REORDER_PRESENTATION_BAR_SLIDES,
	DELETE_BY_CHECKSUM,
	SET_LOADED_PRESENTATION_DIRTY,
	SET_PRESENTATION_BAR_SLIDES
} from '../actions/actionTypes'
import { SlideInterface } from '../../utils/interfaces'

const initialState = {
	presentationBarSlides: [],
	slideShowSlides: {
		slides: [],
		startFrom: 0
	},
	loadedPresentation: undefined,
	loadedPresentationDirty: false
}

const reducer = (state: any = initialState, action: any) => {
	switch (action.type) {
		case ADD_TO_PRESENTATION:
			return {
				...state,
				presentationBarSlides: state.presentationBarSlides.concat(
					action.payload.slides
				)
			}
		case DELETE_FROM_PRESENTATION: {
			const filtered = state.presentationBarSlides.filter(
				(slide: any) =>
					!action.payload.idToDelete.some(
						({ id, key }: any) => id === slide.id && key === slide.key
					)
			)
			return {
				...state,
				presentationBarSlides: filtered
			}
		}
		case CLEAR_PRESENTATION:
			return {
				...state,
				presentationBarSlides: initialState.presentationBarSlides
			}
		case REORDER_PRESENTATION_BAR_SLIDES:
			return {
				...state,
				presentationBarSlides: action.payload.presentationBarSlides
			}
		case DUPLICATE_BY_ID: {
			const currentSlides = state.presentationBarSlides
			currentSlides.splice(
				action.payload.lastSelectedIndex,
				0,
				...action.payload.selectedSlides
			)
			currentSlides.join()

			return {
				...state,
				presentationBarSlides: currentSlides
			}
		}
		case DELETE_BY_CHECKSUM: {
			const currentSlides = state.presentationBarSlides
			const filtredSlides = currentSlides.filter(
				(slide: SlideInterface) => slide.checksum !== action.payload.checksum
			)
			return {
				...state,
				presentationBarSlides: filtredSlides
			}
		}
		case REMOVE_BY_INDEX: {
			const removeByIndexes = action.payload.indexes
			const currentSlides = state.presentationBarSlides
			const indexSet = new Set(removeByIndexes)
			// @ts-ignore
			const filtredSlides = currentSlides.filter(
				(_: any, index: unknown) => !indexSet.has(index)
			)
			return {
				...state,
				presentationBarSlides: filtredSlides
			}
		}
		case REMOVE_ALL_LOADING_THUMBNAILS: {
			const currentSlides = state.presentationBarSlides
			const filteredSlides = currentSlides.filter(
				(slide: any) => slide.isLoading !== true
			)
			return {
				...state,
				presentationBarSlides: filteredSlides
			}
		}
		case CREATE_SLIDESHOW:
			return {
				...state,
				slideShowSlides: {
					slides: action.payload.slideShowSlides,
					startFrom: action.payload.startFrom
				}
			}
		case CLEAR_SLIDESHOW:
			return {
				...state,
				slideShowSlides: initialState.slideShowSlides
			}
		case SET_LOADED_PRESENTATION:
			return {
				...state,
				loadedPresentation: action.payload.presentation
			}
		case SET_LOADED_PRESENTATION_DIRTY:
			return {
				...state,
				loadedPresentationDirty: action.payload.value
			}
		case SET_PRESENTATION_BAR_SLIDES:
			return {
				...state,
				presentationBarSlides: action.payload.slides
			}
		default:
			return state
	}
}

export default reducer
