import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import styles from './UserMenu.module.scss'
import Button from '../../../common/Button'
import { t } from '../../../../utils/languages/i18n'
import { BUTTON_TEXT, SETTINGS } from '../../../../utils/consts'
import Profile from '../../../../assets/images/profile.png'
import { StoreInterface } from '../../../../utils/interfaces'
import packageJson from '../../../../../package.json'
import {
	sfApiRoot,
	usersLogoutEndpoint
} from '../../../../api/apiEndpoints_new'
import useOuterClick from '../../../../utils/hooks/useOuterClick'
import { axiosInstance } from '../../../../utils/requests'

interface UserMenuInterface {
	toggleInstanceSwitchModal: (value: boolean) => void
}

interface ProfileImageInterface {
	background: string
}

const ProfileImage = styled('div')<ProfileImageInterface>`
	background-image: url(${(props) => props.background});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	align-self: center;
	margin-right: 10px;
`

const UserMenu = ({ toggleInstanceSwitchModal }: UserMenuInterface) => {
	const navigate = useNavigate()

	const { instances } = useSelector((state: any) => state.authUser)
	const { isSidebarOpened } = useSelector((state: any) => state.temp)
	const { firstname, lastname } = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.user
	)
	const profilePicture = useSelector(
		(reduxStore: StoreInterface) =>
			reduxStore.authUser.user.user.profile_picture
	)
	const authToken = useSelector(
		(reduxStore: StoreInterface) => reduxStore.authUser.user.token
	)

	const [isUserMenuOpen, toggleUserMenuOpen] = useState(false)

	/**
	 *
	 * @description Toggles the settings menu
	 */
	const toggleSettingsMenu = () => {
		navigate(`/${SETTINGS.toLowerCase()}`)
	}

	/**
	 * @description removes the user from the redux state
	 *
	 */
	const handleLogout = () => {
		axiosInstance
			.delete(usersLogoutEndpoint, {
				headers: {
					Authorization: `Bearer ${authToken}`
				}
			})
			.then(() => {
				Cookies.remove('JWT')
				Cookies.remove('KMSI')
				localStorage.clear()
				window.location.href = '/login'
			})
	}

	/**
	 *
	 *
	 * @param {string} button the clicked button
	 * @description calls the associated function with the button
	 * and then closes the profile menu
	 */
	const handleClick = (button: string) => {
		if (button === 'switch') {
			toggleInstanceSwitchModal(true)
		} else if (button === 'settings') {
			toggleSettingsMenu()
		}
		toggleUserMenuOpen(false)
	}

	const clickOutsideHandler = useOuterClick(() => {
		toggleUserMenuOpen(false)
	})

	return (
		<div className={styles.userTopBarContainer} ref={clickOutsideHandler}>
			<div
				className={classnames(
					styles.userButton,
					isUserMenuOpen && styles.activeUserButton
				)}
				onClick={() => toggleUserMenuOpen(true)}
				id="user-menu-button"
			>
				<ProfileImage
					background={
						profilePicture
							? `${sfApiRoot}/files/assets/files/${profilePicture}?oauth=${encodeURIComponent(
									authToken
							  )}`
							: Profile
					}
					id="user-menu-profile-image"
				/>
				<div
					className={classnames(
						styles.fullNameText,
						isSidebarOpened && styles.sidebarOpened
					)}
					title={`${firstname} ${lastname}`}
				>
					{firstname}
					<br />
					{lastname}
				</div>
			</div>
			{isUserMenuOpen && (
				<>
					<div className={styles.menuContainer}>
						<ul>
							<li>
								<Button
									onClick={() => handleClick('switch')}
									label={t('buttons.switch')}
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									isDisabled={instances.length < 2}
									id="user-menu-switch-instance"
								/>
							</li>
							<li>
								<Button
									onClick={() => handleLogout()}
									label={t('buttons.logout')}
									type={BUTTON_TEXT}
									buttonClass={styles.button}
									id="user-menu-logout"
								/>
							</li>
							<li>
								<div
									className={styles.bottom}
									id="user-menu-salesframe-version"
								>
									{t('labels.version')} {packageJson.version}
								</div>
							</li>
						</ul>
					</div>
				</>
			)}
		</div>
	)
}

export default UserMenu
