import { has, isNull } from 'lodash'
import getVideoId from 'get-video-id'

import genericFileIcon from '../../assets/misc/file_icon_2.png'
import videoFileIcon from '../../assets/misc/file_video.png'
import {
	THUMBNAIL_TYPES,
	VIDEOS,
	VIDEO_EXTENSIONS,
	VIMEO,
	YOUTUBE,
	PRESENTATION_BAR,
	MEMOSITE_BAR
} from '../consts'
import { convertBusinessRules } from '../convertBusinessRules'
import { sfApiRoot } from '../../api/apiEndpoints_new'

export interface CardObject {
	title: string
	extension: string
	checksum: string
	external_id: string
	thumbnail: string
}

enum ContentType {
	FILE = 'file',
	SLIDE = 'slide'
}

const CONTENT_TYPES = {
	file: 'files',
	slide: 'slides'
}

/**
 *
 *
 * @param {string} cheksum the chelcsum of the file
 * @param {string} authToken the authorization token to sign the request
 * @description generates the URL for the file's thumbnail
 * @returns {string} the generated url from the file's thumbnail
 */
export const createThumbnailUrl = (
	cheksum: string,
	authToken: string
): string =>
	`${sfApiRoot}/files/assets/thumbnails/${cheksum}?oauth=${encodeURIComponent(
		authToken
	)}`

/**
 * @param {object} cardObject the object representation of a file
 * @param {string} authToken the authorization token to sign the image requests
 * @description decides which icon to render for a specific type of file
 * @returns {string} the image corresponding the current file type
 */
export function decideThumbnail(
	cardObject: CardObject,
	authToken: string
): string {
	let thumbnail
	if (
		has(cardObject, 'thumbnail') &&
		!isNull(cardObject.thumbnail) &&
		cardObject.thumbnail !== ''
	) {
		// it has customm thumbnail
		const temp = cardObject.thumbnail
		thumbnail = createThumbnailUrl(temp, authToken)
	} else {
		const { extension, checksum } = cardObject
		if (extension) {
			if (
				THUMBNAIL_TYPES.includes(extension.toUpperCase()) &&
				!VIDEO_EXTENSIONS.includes(extension)
			) {
				thumbnail = createThumbnailUrl(checksum, authToken)
			} else if (VIDEOS.includes(extension.toUpperCase())) {
				thumbnail = videoFileIcon
			} else if (extension === YOUTUBE.toLowerCase()) {
				const videoId = getVideoId(cardObject.title)
				thumbnail = `https://img.youtube.com/vi/${videoId.id}/mqdefault.jpg`
			} else if (extension === VIMEO.toLowerCase()) {
				if (cardObject.external_id.toString().includes('/')) {
					thumbnail = videoFileIcon
				} else {
					thumbnail = createThumbnailUrl(checksum, authToken)
				}
			} else {
				thumbnail = genericFileIcon
			}
		} else {
			thumbnail = genericFileIcon
		}
	}

	return thumbnail
}

/**
 *
 *
 * @param {string} cheksum the chelcsum of the file
 * @param {string} authToken the authorization token to sign the request
 * @param {Enum} type the type of the file
 * @description generates the URL for the file
 * @returns {string} the generated url from the file's thumbnail
 */
export const createImageUrl = (
	cheksum: string,
	authToken: string,
	type: ContentType = ContentType.FILE
) =>
	`${sfApiRoot}/files/assets/${
		CONTENT_TYPES[type]
	}/${cheksum}/true?oauth=${encodeURIComponent(authToken)}`

/**
 *
 *
 * @param {string} businessRules string of the businessrules
 * @description tries to parse the given stringified json
 * checks if it has restructed propety
 * and the property is true
 * @returns {boolean} whether the current file is restriced or not
 */
export const isRestristed = (businessRules: string): boolean => {
	try {
		const parsedRules = convertBusinessRules(businessRules)
		if (has(parsedRules, 'restricted') && parsedRules.restricted) {
			return true
		}
	} catch {
		return false
	}
	return false
}

export const isLoadedInDeck = (role: string): boolean => {
	try {
		return [
			PRESENTATION_BAR.toLowerCase(),
			MEMOSITE_BAR.toLowerCase()
		].includes(role)
	} catch {
		return false
	}
}
