import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import StopIcon from '@mui/icons-material/Stop'
import { isEqual } from 'lodash'
import { motion } from 'framer-motion'

import styles from './GalleryOptions.module.scss'
import layoutStyles from '../../Layout.module.scss'
import Toggle from '../../../../components/common/Toggle'
import { GALLERY_SHAPES, LEFT, SUCCESS } from '../../../../utils/consts'
import { t } from '../../../../utils/languages/i18n'
import RadioGroup from '../../../../components/common/RadioGroup'
import { BoxIcon } from '../../../../icons/box'
import InputRange from '../../../../components/common/InputRange'
import Button from '../../../../components/common/Button'
import { modifyFolderOptions } from '../../../../api/requests/layout'
import { getTags } from '../../../../store/actions/tags'
import ColorPicker from '../../../../components/common/ColorPicker'
import { setTagGalleryOptions } from '../../../../store/actions/storyBoardContext'

/**
 * @author zilahir
 * @function GalleryOptions
 * */

const variants = {
	open: {
		left: 0,
	},
	closed: {
		left: -360,
	},
}

const GalleryOptions = ({
	isOpen,
	onClose,
	cancelChages,
}) => {
	const dispatch = useDispatch()
	const storyBoardContext = useSelector(store => store.storyboardContext)
	const authToken = useSelector(store => store.authUser.user.token)
	const { galleryOptionsShallowCopy } = useSelector(state => state.temp)
	const tagGalleryOptions = useSelector(store => store.storyboardContext.tagGalleryOptions)

	/**
	 *
	 * @description checks whether the layout galleryOptions object
	 * is differs from it's shallow copy
	 * @returns {boolean} dirty or not
	 */
	function checkIfGalleryOptionsDirty() {
		const hasChanged = isEqual(tagGalleryOptions, galleryOptionsShallowCopy)
		return hasChanged
	}


	/**
	 *
	 * @description handles the sving of the gallery options
	 * by clling tha api with options property
	 */
	function handleSave() {
		const newGalleryOptions = {
			useGallery: storyBoardContext.tagGalleryOptions.useGallery,
			useFolderIcons: storyBoardContext.tagGalleryOptions.useFolderIcons,
			shape: storyBoardContext.tagGalleryOptions.shape,
			shapeShadow: storyBoardContext.tagGalleryOptions.shapeShadow,
			textShadow: storyBoardContext.tagGalleryOptions.textShadow,
			itemsPerRow: storyBoardContext.tagGalleryOptions.itemsPerRow,
			paddingBetweenItems: storyBoardContext.tagGalleryOptions.paddingBetweenItems,
			galleryWidth: storyBoardContext.tagGalleryOptions.galleryWidth,
			shapeBackgroundColor: storyBoardContext.tagGalleryOptions.shapeBackgroundColor,
		}

		modifyFolderOptions(
			newGalleryOptions,
			'options',
			storyBoardContext.selectedTag.id,
			authToken,
		).then(() => {
			dispatch(getTags(authToken))
			toast(t('notifications.success.folder-options-saved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000,
			})
			onClose()
		})
	}
	return (
		<motion.div
			className={styles.galleryOptionsPane}
			variants={variants}
			animate={isOpen ? 'open' : 'closed'}
			direction={LEFT}
			handler={null}
			initial="closed"
			hasOverlay={false}
			onClose={onClose}
		>
			<div className={styles.galleryRootContainer}>
				<div className={styles.header}>
					<h1>
						{t('layout.gallery.gallery')}
						<p>
							{
								storyBoardContext.selectedTag
									? storyBoardContext.selectedTag.name : ''
							}
						</p>
					</h1>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.use-gallery')}
						</p>
					</div>
					<Toggle
						wrapperClassname={layoutStyles.toggleContainer}
						states={{
							off: t('misc.off'),
							on: t('misc.on'),
						}}
						currentState={storyBoardContext.tagGalleryOptions.useGallery}
						onChange={
							() => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions,
									useGallery: !storyBoardContext.tagGalleryOptions.useGallery,
								},
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.use-folder-icons')}
						</p>
					</div>
					<Toggle
						wrapperClassname={layoutStyles.toggleContainer}
						states={{
							off: t('misc.off'),
							on: t('misc.on'),
						}}
						currentState={storyBoardContext.tagGalleryOptions.useFolderIcons}
						onChange={
							() => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions,
									useFolderIcons: !storyBoardContext.tagGalleryOptions.useFolderIcons },
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.shape')}
						</p>
					</div>
					<RadioGroup
						withCustomIcon
						defaultSelected={
							GALLERY_SHAPES.findIndex(
								thisShape => thisShape === storyBoardContext.tagGalleryOptions.shape,
							)
						}
						continerClass={styles.shapeSelector}
						radioItems={[
							{ key: 11, label: 'None', icon: <CloseIcon /> },
							{ key: 22, label: 'Box', icon: <StopIcon /> },
							{ key: 33, label: 'Rounded box', icon: <BoxIcon className={styles.roundedBox} /> },
							{ key: 44, label: 'Circle', icon: <FiberManualRecordIcon /> },
						]}
						onChange={selected => dispatch(setTagGalleryOptions({
							...storyBoardContext.tagGalleryOptions, shape: GALLERY_SHAPES[selected],
						}))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.shape-background-color')}
						</p>
					</div>
					<ColorPicker
						onChange={color => dispatch(setTagGalleryOptions({
							...storyBoardContext.tagGalleryOptions, shapeBackgroundColor: color,
						}))
						}
						initialColor={
							storyBoardContext.tagGalleryOptions.shapeBackgroundColor
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.shape-shadow')}
						</p>
					</div>
					<Toggle
						wrapperClassname={styles.toggleContainer}
						states={{
							off: t('misc.off'),
							on: t('misc.on'),
						}}
						currentState={storyBoardContext.tagGalleryOptions.shapeShadow}
						onChange={
							() => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions,
									shapeShadow: !storyBoardContext.tagGalleryOptions.shapeShadow },
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.text-shadow')}
						</p>
					</div>
					<Toggle
						wrapperClassname={styles.toggleContainer}
						states={{
							off: t('misc.off'),
							on: t('misc.on'),
						}}
						currentState={storyBoardContext.tagGalleryOptions.textShadow}
						onChange={
							() => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions,
									textShadow: !storyBoardContext.tagGalleryOptions.textShadow },
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.number-of-items-per-row')}
						</p>
					</div>
					<InputRange
						inheritedOptions={storyBoardContext.tagGalleryOptions.itemsPerRow}
						inputRange={[2, 16]}
						valueFormatter={value => value}
						onDragEnd={
							value => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions, itemsPerRow: value },
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.padding-between-items')}
						</p>
					</div>
					<InputRange
						inputRange={[5, 100]}
						inheritedValue={storyBoardContext.tagGalleryOptions.paddingBetweenItems}
						valueFormatter={value => value}
						onDragEnd={
							value => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions, paddingBetweenItems: value },
							))
						}
					/>
				</div>
				<div className={layoutStyles.oneSetting}>
					<div className={layoutStyles.header}>
						<p className={layoutStyles.title}>
							{t('layout.gallery.gallery-width')}
						</p>
					</div>
					<InputRange
						inputRange={[10, 100]}
						valueFormatter={value => `${value}%`}
						inheritedValue={storyBoardContext.tagGalleryOptions.galleryWidth}
						onDragEnd={
							value => dispatch(setTagGalleryOptions(
								{ ...storyBoardContext.tagGalleryOptions, galleryWidth: value },
							))
						}
						inheritedOptions={storyBoardContext.tagGalleryOptions.galleryWidth}
					/>
				</div>
				<div className={classnames(
					layoutStyles.oneSetting,
					layoutStyles.noBottomMargin,
				)}
				>
					<div className={styles.btnContainer}>
						<ul>
							<li>
								<Button
									label={t('labels.cancel')}
									onClick={() => cancelChages()}
									buttonClass={classnames(
										styles.actionBtn,
										styles.footerBtn,
									)}
								/>
							</li>
							<li>
								<Button
									label={t('labels.save')}
									isPositive
									isDisabled={checkIfGalleryOptionsDirty()}
									buttonClass={classnames(
										styles.footerBtn,
									)}
									onClick={() => handleSave()}
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

GalleryOptions.propTypes = {
	cancelChages: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default GalleryOptions
