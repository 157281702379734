import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './CheckBox.module.scss'

/**
 * @author zilahir
 * @function CheckBox
 * */

const CheckBox = ({
	wrapperClassName,
	checkBoxClassName,
	isChecked,
	name,
	label,
	onChange,
	admin,
	disabled,
	id
}) => (
	<div
		className={classnames(
			styles.checkBoxWrapper,
			isChecked ? styles.isChecked : styles.notChecked,
			wrapperClassName,
			disabled && styles.checkboxDisabled
		)}
	>
		<label
			className={admin ? styles.adminLabel : styles.label}
			htmlFor={id || `check-${name}`}
		>
			<input
				id={id || `check-${name}`}
				type="checkbox"
				onChange={onChange}
				checked={isChecked}
				className={classnames(styles.checkbox, checkBoxClassName)}
				disabled={disabled}
			/>
			{label && label}
		</label>
	</div>
)

CheckBox.defaultProps = {
	admin: false,
	checkBoxClassName: null,
	disabled: false,
	label: null,
	wrapperClassName: null
}

CheckBox.propTypes = {
	admin: PropTypes.bool,
	checkBoxClassName: PropTypes.string,
	disabled: PropTypes.bool,
	isChecked: PropTypes.bool.isRequired,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	wrapperClassName: PropTypes.string,
	id: PropTypes.string
}

export default CheckBox
