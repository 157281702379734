import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Proptypes from 'prop-types'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import CloseIcon from '@mui/icons-material/Close'

import styles from './CustomSlides.module.scss'
import FolderButton from './components/FolderButton'
import Button from '../common/Button'
import { useEventListener } from '../../utils/hooks/useEventListener'
import { addToPresentation } from '../../store/actions/presentation'
import {
	getFoldersForCustomSlides,
	getTemplatesForCustomSlides
} from '../../store/actions/customSlides'
import { fetchTemplates } from '../../api/requests/customSlides'
import Template from './components/Templates/Templates'
import Spinner from '../common/Spinner'

const CustomSlides = ({ isShowing, handleClose }) => {
	const [loading, setloading] = useState(false)
	const [selectedFolder, setSelectedFolder] = useState()
	const [emptyFolder, setemptyFolder] = useState()
	const dispatch = useDispatch()
	const folders = useSelector((state) => state.customSlides.folders)
	const templates = useSelector((state) => state.customSlides.content)

	useEffect(() => {
		dispatch(getFoldersForCustomSlides())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setloading(true)
		Promise.all([
			dispatch(getTemplatesForCustomSlides(selectedFolder?.id || 1))
		]).then(() => setloading(false))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFolder])

	useEffect(() => {
		if (folders.length) {
			folders.map((folder) =>
				fetchTemplates(folder.id).then((templates) => {
					if (!templates.length) {
						setemptyFolder(folder)
					}
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/**
	 * @description handles response from customs slides
	 * if custom slides send closeApplication action, close the application
	 * and if sends newSlideCreated close add slide to presentation and close custom slides
	 * @param {object} event holds data which are sent from custom slides back to xenon
	 */
	function onMessage(event) {
		if (
			event.origin === 'https://customers.salesfra.me' ||
			event.origin.includes('.amplifyapp.com') ||
			event.origin === 'https://customslides.salesfra.me'
		) {
			if (event.data.action.includes('closeApplication')) {
				handleClose()
			}
			if (event.data.action.includes('newSlideCreated')) {
				dispatch(addToPresentation(event.data.data))
				handleClose()
			}
		}
	}

	useEventListener('message', onMessage, window)

	return (
		<>
			{isShowing && (
				<div className={styles.modalContent}>
					<div
						style={{
							zIndex: 99999,
							position: 'absolute',
							top: 0,
							right: 0,
							bottom: 0,
							left: 0
						}}
					>
						<div className={styles.slideContent}>
							<div className={styles.header}>
								<div>{folders[0]?.name}</div>
								<Button onClick={() => handleClose()} icon={<CloseIcon />} />
							</div>
							<div className={styles.folders}>
								{' '}
								{folders?.map((folder, index) => (
									<FolderButton
										build
										key={index}
										label={folder?.name}
										icon={<FolderOutlinedIcon style={{ fontSize: 20 }} />}
										iconPosition="left"
										variant="default"
										hasRadius
										onClick={() => setSelectedFolder(folder)}
										height={40}
										leftPadding={12}
										rightPadding={16}
										selected={folder?.id === (selectedFolder?.id || 1)}
										disabled={emptyFolder?.id === folder?.id}
									/>
								))}
							</div>
							<div className={styles.sortableContainer}>
								{!loading ? (
									templates.map((template, index) => (
										<Template key={index} template={template} />
									))
								) : (
									<div className={styles.spinner}>
										<Spinner isLoading={loading} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

CustomSlides.propTypes = {
	handleClose: Proptypes.func.isRequired,
	isShowing: Proptypes.bool.isRequired
}

export default CustomSlides
