import React, { useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import Modal from '../../../components/common/ModalNew'
import Spinner from '../../../components/common/Spinner'
import { t } from '../../../utils/languages/i18n'
import Section from '../../../components/common/Section'
import styles from './CRMLikeitModal.module.scss'
import Button from '../../../components/common/Button'
import { FLEX_END } from '../../../utils/consts'
import Input from '../../../components/common/Input'
import Results from './Results/Results'
import {
	searchUserLikeit,
	sendCrmLikeitData
} from '../../../api/requests/crmLikeit'
import { getMemositeLink } from '../../../utils/helpers/memosites'

const CRMLikeit = ({ handleClose, memosite, saveButtonDisabled, zIndex }) => {
	const cookies = Cookies.get()
	const [allResults, setAllResults] = useState(undefined)
	const [name, setName] = useState('')
	const [AccountId, setAccountId] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const authUser = useSelector((store) => store.authUser.user)
	const instanceName = useSelector((state) => state.temp.instanceName)
	const { LIT_UUID } = cookies
	const reset = () => {
		setName('')
		setAllResults(null)
	}
	const close = () => {
		reset()
		handleClose()
	}
	const handleSearch = () => {
		setIsLoading(true)
		searchUserLikeit(name)
			.then((result) => {
				setAllResults(result.Data)
				setIsLoading(false)
			})
			.catch()
	}

	const handleSend = () => {
		setIsLoading(true)
		const memoUrl = getMemositeLink(
			instanceName,
			memosite.slug,
			authUser.role,
			true
		)
		const data = {
			content: `<h4>A memosite was created in Salesframe</h4><h4>By user ${
				authUser.user.firstname
			} ${
				authUser.user.lastname
			}</h4><h4>${new Date().toDateString()}</h4><br /><a href='${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false'>${memoUrl}${
				window.location.origin === 'https://app.salesframe.com' ? '&' : '?'
			}analytics=false</a>`,
			accountId: AccountId,
			user_id: LIT_UUID,
			meeting_id: memosite.id,
			title: memosite.subject
		}
		sendCrmLikeitData(data)
			.then(() => {
				close()
				setAllResults(null)
				toast(t('notifications.success.crm-data-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'success',
					autoClose: 5000
				})
				setIsLoading(false)
			})
			.catch(() => {
				toast(t('notifications.error.crm-data-not-sent'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: 'error',
					autoClose: 5000
				})
				setIsLoading(false)
			})
	}

	/**
	 * @description get CRM fields depending on CRM id
	 * @returns {JSX.Element} return CRM input fields
	 */
	function getCrmInputs() {
		return (
			<div>
				<div className={styles.crmContainer}>
					<Input
						onChange={(event) => setName(event.target.value)}
						placeholderText="Last name, first name"
						id="nameFirstname"
						name="nameFirstname"
						initialValue={name}
						inputClassName={styles.input}
					/>
					<Button
						onClick={handleSearch}
						isPositive
						label="Search"
						buttonClass={styles.btn}
						isDisabled={saveButtonDisabled}
					/>
				</div>
				{isLoading ? <Spinner isLoading size={44} /> : null}

				<div className={allResults ? styles.notesContainer : null}>
					{allResults &&
						allResults.map((customer) => (
							<Results
								className={styles.result}
								key={customer.Id}
								id={customer.Id}
								BIN={customer.BusinessIdentityNumber}
								name={customer.Name}
								getAccountId={setAccountId}
							/>
						))}
				</div>
			</div>
		)
	}

	return (
		<Modal
			isShowing={!!memosite}
			hide={close}
			modalTitle={t('titles.send-to-crm')}
			headerClassname={styles.title}
			customClassName={styles.modal}
			contentClassName={styles.modalContent}
			zIndex={zIndex}
		>
			<div className={styles.sectionMemoName}>
				<p className={styles.label}>Search and select your contact</p>
			</div>
			{getCrmInputs()}
			<Section
				justify={FLEX_END}
				sectionClassName={styles.footer}
				padding="20px 30px"
			>
				<Button
					onClick={close}
					label={t('buttons.cancel')}
					buttonClass={styles.btn}
				/>
				<Button
					onClick={handleSend}
					isPositive
					label="Send"
					buttonClass={styles.btn}
					isDisabled={saveButtonDisabled}
				/>
			</Section>
		</Modal>
	)
}

CRMLikeit.defaultProps = {
	crmData: null,
	memosite: {},
	saveButtonDisabled: false,
	setEmailTo: null,
	zIndex: 9999
}

CRMLikeit.propTypes = {
	crmData: PropTypes.shape({
		caseValue: PropTypes.string,
		customerId: PropTypes.string,
		selectedDeal: PropTypes.shape({
			id: PropTypes.number
		})
	}),
	handleClose: PropTypes.func.isRequired,
	memosite: PropTypes.objectOf(null),
	saveButtonDisabled: PropTypes.bool,
	zIndex: PropTypes.number
}

export default CRMLikeit
