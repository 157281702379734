import React from 'react'
import { GroupOutlined as SharedFolderIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import c from 'classnames'

import styles from './Folder.module.scss'

import { FolderInterface, StoreInterface } from '../../../../utils/interfaces'
import {
	setSelectedCategory,
	setSelectedSlides,
	toggleLoading
} from '../../../../store/actions/buildContext'
import {
	clearTempPresentation,
	setTempValue
} from '../../../../store/actions/temp'
import { BUTTON_NORMAL, PRESENTATION_BAR } from '../../../../utils/consts'
import {
	getAllMyFilesCategories,
	getMyFilesByCategoryId
} from '../../../../store/actions/myfiles'
import { t } from '../../../../utils/languages/i18n'
import Button from '../../../../components/common/Button'
import { acceptSharedFolderInvitation } from '../../../../api/requests/content'

interface FolderComponentInterface {
	folder: FolderInterface
}

const Folder = ({ folder }: FolderComponentInterface) => {
	const dispatch = useDispatch()

	const authUser = useSelector((store: StoreInterface) => store.authUser)
	const { myFilesHomepageVisible } = useSelector(
		(store: StoreInterface) => store.temp
	)

	/**
	 *
	 * @description sets the clicked category as selected
	 * fetches the content from that category
	 * toggles a loading state
	 */
	const selectFolderHandler = () => {
		dispatch(toggleLoading(true))
		if (myFilesHomepageVisible) {
			dispatch(setTempValue('myFilesHomepageVisible', false))
		}
		setSelectedCategory(folder)
		dispatch(clearTempPresentation(PRESENTATION_BAR))
		dispatch(getMyFilesByCategoryId(folder.id, authUser.user.token))
			// @ts-ignore
			.then(() => {
				dispatch(toggleLoading(false))
			})
		dispatch(
			setSelectedCategory({
				...folder,
				id: folder.id,
				categoryName: folder.name
			})
		)
		dispatch(setSelectedSlides([]))
		// toggleNewCategoryCreatedId(undefined)
	}

	const acceptFolderHandler = (
		event: React.MouseEvent,
		idDeclined?: boolean
	) => {
		event.stopPropagation()
		event.preventDefault()
		acceptSharedFolderInvitation(
			authUser.user.user.id,
			Number(folder.id),
			idDeclined
		).then(() => {
			dispatch(getAllMyFilesCategories())
		})
	}

	return (
		<div
			className={styles.folderContainer}
			onClick={() => selectFolderHandler()}
			id="my-files-create-new-folder-button"
		>
			<div
				className={c(
					styles.folderShape,
					folder.code !== 'user_created' &&
						folder.code !== 'system_shared' &&
						styles.defaultFolder,
					folder.pending === 1 && styles.invited
				)}
			>
				<div className={styles.folderActions}>
					{folder.pending === 1 ? (
						<div className={styles.sharedFolder}>
							<div className={styles.invitedBy}>
								<div>{t('misc.shared_by')}</div>
								{folder.invited_by}
							</div>
							<div className={styles.buttons}>
								<Button
									type={BUTTON_NORMAL}
									label={t('buttons.dismiss')}
									onClick={(e) => acceptFolderHandler(e, true)}
									buttonClass={styles.btn}
									containerClass={styles.btnContainer}
									id="my-files-shared-folder-dismiss-button"
								/>
								<Button
									type={BUTTON_NORMAL}
									isPositive
									label={t('buttons.accept')}
									onClick={(e) => acceptFolderHandler(e)}
									buttonClass={styles.btn}
									containerClass={styles.btnContainer}
									id="my-files-shared-folder-accept-button"
								/>
							</div>
						</div>
					) : (
						<React.Fragment>
							{folder.code === 'system_shared' && <SharedFolderIcon />}
						</React.Fragment>
					)}
				</div>
			</div>
			<div className={styles.folderName}>{folder.name}</div>
		</div>
	)
}

export default Folder
