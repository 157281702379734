/**
 *
 * @description array of customer IDs who's able to see
 * risk meter button in the presentation bar & modal
 * 0 = Juka instanssi customerId
 * 1 = Lahitapiola customerId
 */
export const riskMeterCustomers = [
	'3f8ec75b67e1289582b70f3ff835a0b0',
	'3ed38ba0f993322bb48a07d9df9cebc2'
]
export const jukanInstanssiCustomer = '3f8ec75b67e1289582b70f3ff835a0b0'
