import { cloudFnGet } from '../../utils/requests'
import {
	templateFoldersEndpoint,
	templatesByFolderEndpoint
} from '../apiEndpoints_new'

export const fetchFolders = () =>
	new Promise((resolve, reject) => {
		cloudFnGet(templateFoldersEndpoint)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const fetchTemplates = (folderId: number) =>
	new Promise((resolve, reject) => {
		cloudFnGet(`${templatesByFolderEndpoint}/${folderId}`)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
