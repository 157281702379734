import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './InputRange.module.scss'

/**
 * @author zilahir
 * @function InputRange
 * */

const InputRange = ({
	inputRange,
	hasFeedbackValue,
	valueFormatter,
	inheritedValue,
	inputClassName,
	onFocusOut,
	containerClassName,
	handleChange,
	onDragEnd
}) => {
	const [value, setValue] = useState(inheritedValue)

	React.useEffect(() => {
		setValue(inheritedValue)
	}, [inheritedValue])

	/**
	 *
	 * @description changes the local state with the selected value
	 * executes function provided by prop (usually a dispatcher)
	 * @param {number} sliderValue the value of the slider
	 */
	function onChange(sliderValue) {
		setValue(sliderValue)
		if (handleChange) {
			handleChange(sliderValue)
		}
	}

	/**
	 *
	 * @description this function execute the onFoucOus function
	 * which had been passed via props
	 */
	function handleOnFocusOut() {
		if (onFocusOut) {
			onFocusOut()
		}
	}
	return (
		<div className={classnames(styles.inputRangeContainer, containerClassName)}>
			<input
				className={inputClassName}
				type="range"
				min={inputRange[0]}
				max={inputRange[1]}
				onChange={(e) => onChange(e.target.value)}
				value={value}
				step={1}
				onBlur={() => handleOnFocusOut()}
				onMouseUp={(e) => onDragEnd(e.target.value)}
			/>
			{hasFeedbackValue && (
				<div className={styles.feedBack}>
					<p>{valueFormatter(value)}</p>
				</div>
			)}
		</div>
	)
}

InputRange.defaultProps = {
	containerClassName: null,
	handleChange: () => {},
	hasFeedbackValue: true,
	inheritedValue: 10,
	inputClassName: null,
	onDragEnd: () => {},
	onFocusOut: () => {},
	valueFormatter: () => {}
}

InputRange.propTypes = {
	containerClassName: PropTypes.string,
	handleChange: PropTypes.func,
	hasFeedbackValue: PropTypes.bool,
	inheritedValue: PropTypes.number,
	inputClassName: PropTypes.string,
	inputRange: PropTypes.arrayOf(PropTypes.number).isRequired,
	onDragEnd: PropTypes.func,
	onFocusOut: PropTypes.func,
	valueFormatter: PropTypes.func
}

export default InputRange
