import React, { useState } from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'

import styles from './ButtonGroup.module.scss'

/**
 * @author zilahir
 * @function ButtonGroup
 * */

const Button = styled.button`
	border-top-left-radius: ${props => (props.hasLeftBorderR ? '5px' : 0)};
	border-bottom-left-radius: ${props => (props.hasLeftBorderR ? '5px' : 0)};
	border-top-right-radius: ${props => (props.hasRightBorderR ? '5px' : 0)};
	border-bottom-right-radius: ${props => (props.hasRightBorderR ? '5px' : 0)};
`

const ButtonGroup = props => {
	const {
		buttons,
		active,
		onClick,
		toggle,
		toggleClass,
		buttonClass,
		disabled,
	} = props
	const [activeBtn, setActiveBtn] = useState(active)

	/**
 	*
 	* @description Set the button to active in the instance, and executes a function
 	* received from props
	 * @param {number} setToActive id of the button needs to set to active
	 * @param {object} currBtn Object of the clicked butotn
	 */
	function handleClick(setToActive, currBtn) {
		onClick(currBtn)
		setActiveBtn(setToActive)
	}

	return (
		<div className={classnames(
			toggle ? [styles.toggleBtnContainer, toggleClass] : styles.groupButtonContainer,
		)}
		>
			{
				!toggle
					? buttons.map((currBtn, i) => (
						<Button
							disabled={disabled}
							key={currBtn.key}
							type="button"
							onClick={() => handleClick(i, currBtn)}
							className={classnames(
								styles.button,
								i === activeBtn && !disabled ? styles.active : null,
								buttonClass,
							)}
							hasLeftBorderR={i === 0}
							hasRightBorderR={i === buttons.length - 1}
						>
							{currBtn.label}
						</Button>
					))
					: buttons.map(currBtn => (
						{ ...currBtn }
					))
			}
		</div>
	)
}

ButtonGroup.defaultProps = {
	active: null,
	buttonClass: null,
	disabled: false,
	onClick: null,
	toggle: false,
	toggleClass: null,
}

ButtonGroup.propTypes = {
	active: Proptypes.number,
	buttonClass: Proptypes.string,
	buttons: Proptypes.arrayOf(
		Proptypes.any,
	).isRequired,
	disabled: Proptypes.bool,
	onClick: Proptypes.func,
	toggle: Proptypes.bool,
	toggleClass: Proptypes.string,
}

export default ButtonGroup
