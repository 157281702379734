import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FolderOpenedIcon from '@mui/icons-material/Folder'
import FolderClosedIcon from '@mui/icons-material/FolderOutlined'
import PlayIcon from '@mui/icons-material/PlayCircleOutlined'
import LaunchIcon from '@mui/icons-material/LaunchOutlined'

import styles from './File.module.scss'
import { convertBusinessRules } from '../../../../../utils/convertBusinessRules'
import getEmbedUrl from '../../../../../utils/helpers/getEmbedUrl'

export const File = ({
	autoplayTag,
	folderPath,
	name,
	onClick,
	tagObject,
	selectedTagToEmbed
}) => {
	const location = useLocation()
	const businessRules = convertBusinessRules(tagObject.business_rules)

	const [isOpen, setIsOpen] = useState(false)

	const authToken = useSelector((state) => state.authUser.user.token)
	const refreshToken = useSelector((state) => state.authUser.user.refresh_token)

	useEffect(() => {
		const tempBoolean = folderPath.some(
			(folder) => folder.tag_id === tagObject.tag_id
		)
		if (tempBoolean) {
			setIsOpen(true)
		}
		return () => {
			setIsOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folderPath])

	const embeddedTagHandler = () => {
		const newTag = {
			id: tagObject.id,
			name: tagObject.name,
			embeddedUrl: getEmbedUrl(
				location,
				businessRules,
				refreshToken,
				authToken
			),
			target: businessRules.target || false
		}
		selectedTagToEmbed(newTag)
	}

	const clickHandler = (e) => {
		if (!['playIcon', 'embedIcon'].includes(e.target.id)) {
			onClick(tagObject)
		}
	}

	const autoplayHandler = () => {
		autoplayTag(tagObject)
	}

	return (
		<div
			className={classnames(
				styles.oneFile,
				folderPath.some((folder) => folder.tag_id === tagObject.tag_id) &&
					styles.active
			)}
			onClick={(event) => clickHandler(event)}
		>
			<span className={styles.icon}>
				{isOpen ? <FolderOpenedIcon /> : <FolderClosedIcon />}
			</span>
			{businessRules.autoplay && (
				<span className={styles.playIcon}>
					<PlayIcon onClick={autoplayHandler} id="playIcon" />
				</span>
			)}
			{businessRules.embeddedUrl && (
				<span className={styles.embedIcon}>
					<LaunchIcon onClick={embeddedTagHandler} id="embedIcon" />
				</span>
			)}
			<span className={styles.name}>{name}</span>
		</div>
	)
}

File.defaultProps = {
	folderPath: []
}

File.propTypes = {
	autoplayTag: PropTypes.func.isRequired,
	folderPath: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	selectedTagToEmbed: PropTypes.func.isRequired,
	tagObject: PropTypes.objectOf(PropTypes.any).isRequired
}
