import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoDeleteOutlined, DeleteOutline } from '@mui/icons-material'
import c from 'classnames'
import { differenceInHours } from 'date-fns'

import styles from './CardList.module.scss'

import { decideThumbnail } from '../../../../utils/helpers/decideThumbanil'
import { t } from '../../../../utils/languages/i18n'
import { MODAL, MY_MEMOSITES_, SLIDE } from '../../../../utils/consts'
import { SlideInterface, StoreInterface } from '../../../../utils/interfaces'
import ToolBar from '../../../../components/Card/components/ToolBar'
import FlagContentModal from '../../../../components/FlagContentModal'
import { addToTempPresentation } from '../../../../store/actions/temp'

interface CardListInterface {
	authToken: string
	item: SlideInterface
}

const CardList = ({ authToken, item }: CardListInterface) => {
	const dispatch = useDispatch()

	const [isFlaggingModalOpen, toggleFlaggingModal] = React.useState(false)
	const [isHovered, setToolBarVisible] = React.useState(false)

	const tempPresentationSlides = useSelector(
		(store: StoreInterface) => store.temp.slides
	)

	const handleToolBarAction = (action: string) => {
		if (action === MODAL) {
			toggleFlaggingModal(true)
		}
	}

	const handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(item, MY_MEMOSITES_))
	}

	const hoursToDeletion = item.for_delete
		? differenceInHours(Date.parse(item.for_delete), new Date())
		: null

	return (
		<div
			className={c(
				styles.listItem,
				tempPresentationSlides[MY_MEMOSITES_.toLowerCase()].includes(item) &&
					styles.selected
			)}
			onMouseEnter={() => setToolBarVisible(true)}
			onMouseLeave={() => setToolBarVisible(false)}
			onClick={(event) => handleSelect(event)}
		>
			<ToolBar
				isVisible={isHovered}
				cardObject={item}
				action={(action) => handleToolBarAction(action)}
				isCardList
				isEditable={false}
				openFileEditor={() => null}
			/>
			<div className={styles.thumbnail}>
				{item.deleted ? (
					<div className={styles.deleted}>
						<DeleteOutline />
					</div>
				) : (
					<img
						src={decideThumbnail(
							{
								title: item.title || '',
								extension: item.type || '',
								checksum: item.checksum,
								external_id: item.external_id || '',
								thumbnail: item.thumbnail || ''
							},
							authToken
						)}
						alt={item.name}
					/>
				)}
			</div>
			<div className={styles.info}>
				<div>{item.name}</div>
			</div>
			{item.for_delete && (
				<div
					className={styles.deletionTime}
					title={t('tooltips.time-left-until-automatic-deletion')}
				>
					<AutoDeleteOutlined />{' '}
					{hoursToDeletion !== null &&
						`${
							hoursToDeletion === 0 || hoursToDeletion < 24
								? '<1'
								: Math.round(hoursToDeletion / 24)
						}d`}
				</div>
			)}
			{item.isExpired && (
				<div className={styles.expired}>{t('labels.expired')}</div>
			)}
			<div className={styles.type}>
				{item._type === SLIDE
					? t('labels.slide')?.toUpperCase()
					: item.type?.toUpperCase()}
			</div>
			<FlagContentModal
				isActive={isFlaggingModalOpen}
				handleClose={() => toggleFlaggingModal(false)}
				cardObject={item}
				zIndex={10003}
			/>
		</div>
	)
}

export default CardList
