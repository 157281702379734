import LanguageDetector from 'i18next-browser-languagedetector'

export const languages = [
	{
		key: 0,
		lng: 'en',
		label: 'English',
		en: 'English',
		fi: 'Englanti',
		sv: 'Engelska',
		fr: 'Anglais',
		de: 'Englisch',
		dk: 'Engelsk',
		it: 'Inglese',
		es: 'Inglés',
		data: require('./locales/en.json')
	},
	{
		key: 1,
		lng: 'fi',
		label: 'Finnish',
		en: 'Finnish',
		fi: 'Suomi',
		sv: 'Finska',
		fr: 'Finnois',
		de: 'Finnisch',
		dk: 'Finsk',
		it: 'Finlandese',
		es: 'Finlandés',
		data: require('./locales/fi.json')
	},
	{
		key: 2,
		lng: 'sv',
		label: 'Swedish',
		en: 'Swedish',
		fi: 'Ruotsi',
		sv: 'Svenska',
		fr: 'Suédois',
		de: 'Schwedisch',
		dk: 'Svensk',
		it: 'Svedese',
		es: 'Sueco',
		data: require('./locales/sv.json')
	},
	{
		key: 3,
		lng: 'fr',
		label: 'French',
		en: 'French',
		fi: 'Ranska',
		sv: 'Franska',
		fr: 'Français',
		de: 'Französisch',
		dk: 'Fransk',
		it: 'Francese',
		es: 'Francés',
		data: require('./locales/fr.json')
	},
	{
		key: 4,
		lng: 'de',
		label: 'German',
		en: 'German',
		fi: 'Saksa',
		sv: 'Tyska',
		fr: 'Allemande',
		de: 'Deutsch',
		dk: 'Tysk',
		it: 'Tedesco',
		es: 'Alemán',
		data: require('./locales/de.json')
	},
	{
		key: 5,
		lng: 'dk',
		label: 'Danish',
		en: 'Danish',
		fi: 'Tanska',
		sv: 'Danska',
		fr: 'Danois',
		de: 'Dänisch',
		dk: 'Dansk',
		it: 'Danese',
		es: 'Danés',
		data: require('./locales/dk.json')
	},
	{
		key: 6,
		lng: 'it',
		label: 'Italian',
		en: 'Italian',
		fi: 'Italia',
		sv: 'Italienska',
		fr: 'Italien',
		de: 'Italienisch',
		dk: 'Italiensk',
		it: 'Italiano',
		es: 'Italiano',
		data: require('./locales/it.json')
	},
	{
		key: 7,
		lng: 'es',
		label: 'Spanish',
		en: 'Spanish',
		fi: 'Espanja',
		sv: 'Spanska',
		fr: 'Espagnol',
		de: 'Spanisch',
		dk: 'Spansk',
		it: 'Spagnolo',
		es: 'Español',
		data: require('./locales/es.json')
	}
]

let currentLanguage = languages[0]
const fallbackLanguage = languages[0]
const detector = new LanguageDetector()

/**
 * @param newLng
 */
export function setLanguage(newLng: string | undefined) {
	currentLanguage =
		languages.find((language) => language.lng === newLng) || fallbackLanguage

	detector.cacheUserLanguage(currentLanguage.lng)
}

/**
 * @returns {string} the currentlyy applied language
 */
export function getLanguage() {
	return currentLanguage.lng
}

export const getActiveLanguage = () =>
	languages.find((currLang) => currLang.lng === getLanguage())

/**
 * @param {string} data the piece we are looking for
 * @param {string} parts the path in the translation files
 * @returns {string} the found data in the language files
 */
function findPartsForData(data: any, parts: string[]) {
	let transation = data
	for (let i = 0; i < parts.length; i += 1) {
		const part = parts[i]
		if (transation[part] === undefined) {
			return undefined
		}
		transation = transation[part]
	}
	if (typeof transation !== 'string') {
		return undefined
	}
	return transation
}

/**
 * @param path
 * @param replacement
 * @returns {string} the localized piece of translated text
 */
export function t(path: string, replacement?: string[]) {
	const parts = path.split('.')
	let translation =
		findPartsForData(currentLanguage.data, parts) ||
		findPartsForData(fallbackLanguage.data, parts)
	if (translation === undefined) {
		throw new Error(`Can't find translation for ${path}`)
	}
	if (replacement) {
		replacement.forEach((item) => {
			if (translation) {
				translation = translation.replace(`{{%s}}`, item)
			}
		})
	}
	return translation
}

const languageDetector: any = new LanguageDetector()
languageDetector.init({
	languageUtils: {
		formatLanguageCode(lng: string) {
			return lng
		},
		isWhitelisted: () => true
	},
	checkWhitelist: false
})

setLanguage(languageDetector.detect())
