import React from 'react'
import classnames from 'classnames'

import styles from './Result.module.scss'

import { t } from '../../../../utils/languages/i18n'

interface ResultsInterface {
	data: any
	selectData: (accountid: any) => void
	selectedCustomerName?: string
	selectedCustomerId: string
}

const Index = ({
	data,
	selectData,
	selectedCustomerName,
	selectedCustomerId
}: ResultsInterface) => {
	const selectDataHandler = () => {
		if (data.accountid === selectedCustomerId) {
			selectData('')
		} else {
			selectData(data.accountid)
		}
	}

	return (
		<div
			className={classnames(
				styles.customerItem,
				data.accountid === selectedCustomerId && styles.selected
			)}
			tabIndex={0}
			onClick={selectDataHandler}
		>
			<p className={styles.feedItemText}>
				<span>{`${data.ru_sap_customer_number} - ${data.name}`}</span>
			</p>
			{data.description && (
				<p className={styles.feedFooter}>
					<span>{`${t('labels.parent-account')} ${data.description}`}</span>
				</p>
			)}
			{selectedCustomerName && (
				<p className={styles.feedFooter}>
					<span>{selectedCustomerName}</span>
				</p>
			)}
		</div>
	)
}

export default Index
