import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	CloseOutlined as HideIcon,
	FileDownloadOutlined as DownloadIcon
} from '@mui/icons-material/'

import styles from './GeneratingPdfWidget.module.scss'

import { t } from '../../utils/languages/i18n'
import Spinner from '../common/Spinner'
import { StoreInterface } from '../../utils/interfaces'
import { BUTTON_TEXT, ICON_RIGHT } from '../../utils/consts'
import Button from '../common/Button'
import { setTempValue } from '../../store/actions/temp'

const GeneratingPdfWidget = () => {
	const dispatch = useDispatch()

	const { generatePdfWidget } = useSelector(
		(store: StoreInterface) => store.temp
	)

	return (
		<div className={styles.wrapper}>
			<div>
				{generatePdfWidget.pdf
					? t('labels.pdf_created')
					: `${t('labels.generating_pdf')}...`}
				{!generatePdfWidget.pdf && (
					<Spinner isLoading size={13} color="#FFFFFF" />
				)}
			</div>
			{generatePdfWidget.pdf ? (
				<Button
					type={BUTTON_TEXT}
					containerClass={styles.button}
					buttonClass={styles.oneButton}
					iconSide={ICON_RIGHT}
					icon={<DownloadIcon />}
					label={t('labels.download')}
					onClick={() => {
						window.open(generatePdfWidget.pdf, '_blank')
						dispatch(
							setTempValue('generatePdfWidget', { visibility: false, pdf: '' })
						)
					}}
					id="generating-pdf-widget-download"
				/>
			) : (
				<Button
					type={BUTTON_TEXT}
					containerClass={styles.button}
					buttonClass={styles.oneButton}
					iconSide={ICON_RIGHT}
					icon={<HideIcon />}
					label={t('buttons.hide')}
					onClick={() =>
						dispatch(
							setTempValue('generatePdfWidget', { visibility: false, pdf: '' })
						)
					}
					id="generating-pdf-widget-hide"
				/>
			)}
		</div>
	)
}

export default GeneratingPdfWidget
