import { format } from 'date-fns'
import { has } from 'lodash'

import { ADD_TO_ANALYTICS_BATCH, CLEAR_ANALYTICS_BATCH } from './actionTypes'

export const addToAnalyticsBatch = (analyticsBatch: any) => (dispatch: any) => {
	if (!has(analyticsBatch, 'event'))
		throw new Error('Analytics event name is missing')

	dispatch({
		type: ADD_TO_ANALYTICS_BATCH,
		payload: {
			analyticsPayload: {
				...analyticsBatch,
				event_timestamp: format(new Date(), 'yyyy-MM-dd hh:mm:ss')
			}
		}
	})
}

export const clearAnalyticsBatch = () => (dispatch: any) => {
	dispatch({
		type: CLEAR_ANALYTICS_BATCH,
		paload: null
	})
}
