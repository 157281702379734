export const tagListVariants = {
	open: {
		transition: { staggerChildren: 0.07, delayChildren: 0.2 },
	},
	closed: {
		transition: { staggerChildren: 0.05, staggerDirection: -1 },
	},
}

export const tagItemAnimationVarians = {
	visible: i => ({
		opacity: 1,
		y: 0,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
			delay: i * 0.1,
		},
	}),
	hidden: {
		opacity: 0,
		y: 20,
		transation: {
			y: { stiffness: 1000 },
		},
	},
	exit: i => ({
		opacity: 0,
		y: 20,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
			delay: i * 0.1,
		},
	}),
}
