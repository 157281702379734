import React from 'react'
import c from 'classnames'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
	AccountCircleOutlined,
	OpenInBrowser,
	PlayCircleOutline,
	Refresh,
	Star,
	TimerOutlined
} from '@mui/icons-material'

import styles from './Statistics.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Spinner from '../../../../components/common/Spinner'
import { MemositeStatisticsInterface } from '../../interfaces'
import { getMemositeStatistics } from '../../../../api/requests/memosite'
import { getFormattedDuration } from '../../../../utils/helpers/timeFormat'
import Dropdown from '../../../../components/common/Dropdown'
import { defaultDropdownStyles } from '../../../../components/common/Dropdown/utils/styles'
import { getUsersByGroupId } from '../../../../store/actions/users'
import { StoreInterface } from '../../../../utils/interfaces'
import Button from '../../../../components/common/Button'

const OpenedBar = styled('div')<{ width: number }>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${(props: { width: number }) => props.width}%;
	height: 8px;
	margin-bottom: 5px;
	background-color: #88b0ff;
	border-radius: 5px;
`

const emptyStatistics = {
	opened: 0,
	rating: 0,
	video: 0,
	total: 0,
	averageTime: 0,
	loadedTime: ''
}

interface UserSelectionProps {
	id: number
	firstname: string
	lastname: string
}

interface StatisticsInterface {
	changeSelectedUser: (id: number) => void
}

const Statistics = ({ changeSelectedUser }: StatisticsInterface) => {
	const dispatch = useDispatch()

	const { users } = useSelector((store: StoreInterface) => store.users)
	const { memositeAllowView } = useSelector(
		(store: StoreInterface) => store.memosite
	)
	const { authUser } = useSelector((store: StoreInterface) => store)

	const [isLoading, toggleIsLoading] = React.useState(false)
	const [statistics, setStatistics] =
		React.useState<MemositeStatisticsInterface>(emptyStatistics)
	const [selectedUser, toggleSelectedUser] = React.useState<UserSelectionProps>(
		{
			id: authUser.user.user.id,
			firstname: authUser.user.user.firstname,
			lastname: authUser.user.user.lastname
		}
	)
	const [dropdownUsers, setDropdownUsers] = React.useState<
		{ label: string; value: number }[]
	>([])

	const fetchMemositeStatistics = (
		isRefresh: 0 | 1,
		selectedUserId?: number
	) => {
		toggleIsLoading(true)
		getMemositeStatistics(isRefresh, selectedUserId)
			.then((response) => {
				setStatistics(response)
				toggleIsLoading(false)
			})
			.catch((e) => {
				console.error(e)
				toggleIsLoading(false)
			})
	}

	const userSelectionHandler = (selectedUserId: number) => {
		changeSelectedUser(selectedUserId)
		fetchMemositeStatistics(0, selectedUserId)
		const selectedUserResult = users.find((user) => user.id === selectedUserId)
		if (selectedUserResult) {
			toggleSelectedUser(selectedUserResult)
		}
	}

	React.useEffect(() => {
		fetchMemositeStatistics(0)
		dispatch(getUsersByGroupId(true))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (users.length > 0) {
			setDropdownUsers(
				users.map((u) => ({
					label: `${u.firstname} ${u.lastname} (${u.email})`,
					value: u.id
				}))
			)
		}
	}, [users])

	return (
		<div className={styles.wrapper}>
			<div
				className={c(
					styles.myMemositeStatisticsTitle,
					memositeAllowView && styles.responsive
				)}
			>
				<div className={styles.leftSide}>
					<div>
						{t('labels.memosite_statistics')}
						<span className={styles.subTitle}>{t('labels.all_time')}</span>
					</div>
					{!isLoading && memositeAllowView && (
						<p className={styles.lastUpdated}>
							<Refresh
								onClick={() => fetchMemositeStatistics(1)}
								id="statistics-refresh"
							/>
							{`${t('labels.last_updated')} ${statistics.loadedTime}`}
						</p>
					)}
				</div>
				{memositeAllowView ? (
					<div className={styles.rightSide}>
						{selectedUser.id !== authUser.user.user.id && (
							<Button
								buttonClass={styles.backToMeButton}
								label={t('buttons.back_to_me')}
								onClick={() => userSelectionHandler(authUser.user.user.id)}
								id="statistics-back-to-me"
							/>
						)}
						<div className={styles.dropdownWrapper}>
							<AccountCircleOutlined />
							{dropdownUsers.length > 0 && (
								<Dropdown
									className={styles.dropdown}
									styles={defaultDropdownStyles}
									listItems={dropdownUsers}
									onChangeCallback={(selected) =>
										userSelectionHandler(selected.value)
									}
									defaultIndex={0}
									placeholder={t('labels.select_user_or_start_typing')}
									value={dropdownUsers.find((d) => d.value === selectedUser.id)}
								/>
							)}
						</div>
					</div>
				) : (
					<div className={styles.rightSide}>
						{!isLoading && (
							<p className={styles.lastUpdated}>
								{`${t('labels.last_updated')} ${statistics.loadedTime}`}
								<Refresh onClick={() => fetchMemositeStatistics(1)} />
							</p>
						)}
					</div>
				)}
			</div>
			{isLoading ? (
				<div className={styles.spinnerWrapper}>
					<Spinner isLoading={isLoading} />
				</div>
			) : (
				<div className={styles.statistics}>
					<div
						className={c(styles.block, styles.opened)}
						title={t(
							'tooltips.number_of_opened_memosites_in_relation_to_total'
						)}
					>
						<div>
							<div className={styles.percentage}>
								<OpenInBrowser />
								<div className={styles.title}>
									{Math.ceil((statistics.opened * 100) / statistics.total)}%
								</div>
							</div>
							<div className={styles.openedBar}>
								<OpenedBar
									width={Math.ceil(
										(statistics.opened * 100) / statistics.total
									)}
								/>
							</div>
						</div>
						<div className={styles.openedValues}>
							<div className={styles.label}>{t('labels.memosites-opened')}</div>
							<div>
								<p>
									{statistics.opened} / <span>{statistics.total}</span>
								</p>
							</div>
						</div>
					</div>
					<div
						className={c(styles.block, styles.avgTime)}
						title={t(
							'tooltips.average_time_spent_per_session_of_all_your_memosites'
						)}
					>
						<div>
							<div>
								<TimerOutlined />
							</div>
							<div className={styles.title}>
								{getFormattedDuration(statistics.averageTime)}
							</div>
						</div>
						<p>{t('labels.avg-time-spent')}</p>
					</div>
					<div
						className={c(styles.block, styles.videoGreetings)}
						title={t(
							'tooltips.memosites_with_video_greetings_as_percentage_of_all_your_memosites'
						)}
					>
						<div>
							<div>
								<PlayCircleOutline />
							</div>
							<div className={c(styles.title, styles.mr8)}>
								{Math.ceil((statistics.video * 100) / statistics.total)}%
							</div>
							<div className={c(styles.title, styles.textBlue)}>
								{statistics.video}
							</div>
						</div>
						<p>{t('labels.video-greetings')}</p>
					</div>
					<div
						className={c(styles.block, styles.avgRating)}
						title={t(
							'tooltips.average_of_all_ratings_given_to_all_your_memosites'
						)}
					>
						<div>
							<div>
								<Star />
							</div>
							<div className={styles.title}> {statistics.rating || '-'}</div>
						</div>
						<p>{t('labels.avg-rating')}</p>
					</div>
				</div>
			)}
		</div>
	)
}

export default Statistics
