import React from 'react'
import { useSelector } from 'react-redux'

import { BUILD } from '../../../../../utils/consts'
import {
	convertBusinessRules,
	isTagVisible
} from '../../../../../utils/convertBusinessRules'
import { File } from '../File'
import { Folder } from '../Folder'

/**
 * @author zilahir
 * @function TreeRecursive
 * */

export const TreeRecursive = ({
	autoplayTag,
	data,
	onClick,
	folderPath,
	selectedTagToEmbed
}) => {
	const currentUserGroup = useSelector(
		(store) => store.authUser.user.user.userGroupId
	)

	return data.map((item) => {
		if (
			!item.hasChildren &&
			isTagVisible(
				convertBusinessRules(item.business_rules),
				currentUserGroup,
				BUILD
			)
		) {
			return (
				<File
					autoplayTag={autoplayTag}
					folderPath={folderPath}
					key={item.id}
					tagObject={item}
					onClick={onClick}
					name={item.name}
					selectedTagToEmbed={selectedTagToEmbed}
				/>
			)
		}
		if (
			item.hasChildren &&
			isTagVisible(
				convertBusinessRules(item.business_rules),
				currentUserGroup,
				BUILD
			)
		) {
			return (
				<Folder
					autoplayTag={autoplayTag}
					folderPath={folderPath}
					key={item.id}
					tagObject={item}
					onClick={onClick}
					name={item.name}
					selectedTagToEmbed={selectedTagToEmbed}
				>
					<TreeRecursive
						autoplayTag={autoplayTag}
						folderPath={folderPath}
						onClick={onClick}
						data={item.children}
						selectedTagToEmbed={selectedTagToEmbed}
					/>
				</Folder>
			)
		}
		return null
	})
}
