import { cloudFnGet } from '../../utils/requests'
import { filesTagsEndpoint } from '../apiEndpoints_new'
import { SlideInterface } from '../../utils/interfaces'

export const fetchContentByTagId = (tagId: any, authToken: any) =>
	new Promise<SlideInterface[]>((resolve, reject) => {
		cloudFnGet(
			`${filesTagsEndpoint}/${tagId}/assets`,
			{},
			{ withToken: true, authToken }
		)
			.then((response) => {
				resolve(response as SlideInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
