import { useEffect } from 'react'

const Redirect = ({
	location,
	target
}: {
	location: string
	target?: string
}) => {
	useEffect(() => {
		window.open(location, target || '_self')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default Redirect
