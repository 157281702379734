export const initialMemositeAnalytics = {
	average_time: 0,
	number_of_downloaded: 0,
	number_of_downloaded_zip: 0,
	number_of_memosites: 0,
	unique_visitors: 0
}

export const labelColors = [
	'#C70000',
	'#C78500',
	'#B0C300',
	'#0CC000',
	'#00BEB9',
	'#0007C4',
	'#7000C5',
	'#BD0058',
	'#8E1616',
	'#8E6716',
	'#92A00E',
	'#179E0E',
	'#0E9D99',
	'#0E13A1',
	'#620EA2',
	'#9C0E50',
	'#801D1D',
	'#805F1D',
	'#747E1D',
	'#237C1D',
	'#1D7B79',
	'#1D207E',
	'#551D7F',
	'#7B1D49',
	'#5D2B2B',
	'#5D4C2B',
	'#575C2B',
	'#2E5B2B',
	'#2B5B59',
	'#2B2D5C',
	'#472B5C',
	'#5A2B41'
]

export const initialFilterState = {
	dateStart: null,
	dateEnd: new Date(),
	opened: 0,
	sentToCrm: 0,
	labels: [],
	searchTerm: ''
}
