import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import Button from '../../../../../../components/common/Button'
import styles from './Tab.module.scss'
import { BUTTON_TEXT } from '../../../../../../utils/consts'

const Tab = ({
	containerClass,
	buttonClass,
	onClick,
	label,
	isSelected,
	id
}) => (
	<>
		<Button
			containerClass={containerClass}
			buttonClass={buttonClass}
			label={label}
			onClick={onClick}
			type={BUTTON_TEXT}
			id={id}
		/>
		{isSelected && (
			<motion.div
				transition={{ duration: 0.2 }}
				className={styles.activeTab}
				layoutId="selected"
			/>
		)}
	</>
)

Tab.defaultProps = {
	buttonClass: undefined,
	containerClass: undefined,
	isSelected: false,
	label: ''
}

Tab.propTypes = {
	buttonClass: PropTypes.string,
	containerClass: PropTypes.string,
	isSelected: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired
}

export default Tab
