import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'

import { removeUser } from '../../../../store/actions/authUser'
import { axiosInstance } from '../../../../utils/requests'
import { LOGIN } from '../../../../utils/consts'
import useLocalStorage from '../../../../utils/hooks/useLocalStorage'
import { setTempValue } from '../../../../store/actions/temp'
import { ModuleNamesInterface } from '../../../../utils/interfaces'
import { usersAuthenticationEndpoint } from '../../../../api/apiEndpoints_new'

const Logout = () => {
	const authToken = useSelector((store) => store.authUser.user.token)
	const dispatch = useDispatch()
	const [isSSOLoading, toggleIsSSOLoadnig] = useLocalStorage(
		'isSSOLoading',
		false
	)

	useEffect(() => {
		dispatch(setTempValue('currentModule', ModuleNamesInterface.LOGOUT))
		dispatch(removeUser())
			.then(() => {
				Cookies.remove('JWT')
				Cookies.remove('KMSI')
			})
			.then(() => {
				if (isSSOLoading) {
					toggleIsSSOLoadnig(false)
				}
				axiosInstance.delete(usersAuthenticationEndpoint, {
					headers: {
						Authorization: `Bearer ${authToken}`
					}
				})
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Navigate
			to={{
				pathname: '/login',
				state: {
					from: {
						pathname: '/'
					},
					moduleName: LOGIN
				}
			}}
		/>
	)
}

export default Logout
