import React from 'react'
import c from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import {
	CloseOutlined as ClearSearchIcon,
	Search as SearchIcon,
	WorkOutline as MainIcon,
	ArrowDropDown as SortDownIcon,
	ArrowDropUp as SortUpIcon,
	Info as InfoIcon
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { useLocation, useParams } from 'react-router-dom'

import styles from './MyPresentations.module.scss'

import {
	MeetingInterface,
	MeetingsResponseInterface,
	ModuleNamesInterface,
	PendingPresentationInterface,
	SortEnum,
	SortingMemositesColumnEnum,
	StoreInterface
} from '../../utils/interfaces'
import { t } from '../../utils/languages/i18n'
import PresentationItem from './components/PresentationItem'
import {
	createMemoSite,
	deleteMemosite,
	filterMemosites,
	getSingleMeetingBySlug
} from '../../api/requests/memosite'
import { setTempValue } from '../../store/actions/temp'
import Pagination from './components/Pagination'
import { updatePresentationTitle } from '../../api/requests/presentations'
import Spinner from '../../components/common/Spinner'
import DeleteMemositeModal from './components/DeletePresentationModal'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { incrementIfEndsWithNumber } from '../../utils/helpers/memosites'
import {
	addToPresentation,
	clearPresentation,
	setLoadedPresentation,
	setLoadedPresentationDirty
} from '../../store/actions/presentation'
import { setMemositeBuilderValue } from '../../store/actions/memositeBuilder'
import ShareWithColleagueModal from '../../components/ShareWithColleagueModal'
import {
	resetMyPresentationsReducer,
	setMyPresentationsValue
} from '../../store/actions/myPresentations'
import ConfirmDeleteModal from '../../components/common/ConfirmDeleteModal'
import PendingPresentationItem from './components/PendingPresentationItem'
import {
	acceptSharedPresentation,
	declineSharedPresentation
} from '../../api/requests/presentations'
import { BUTTON_TEXT, ICON_LEFT } from '../../utils/consts'
import Button from '../../components/common/Button'
import { getFeedNotificationsByCode } from '../../api/requests/feeds'

const presentationsPerPage = 25

const MyPresentations = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { slug } = useParams()
	const slugHash = location.hash.replace('#', '')

	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	const [searchTerm, setSearchTerm] = React.useState<string>('')
	const [pendingPresentations, setPendingPresentations] = React.useState<
		PendingPresentationInterface[]
	>([])

	const [unsavedChangesModalVisible, toggleUnsavedChangesModal] =
		React.useState<MeetingInterface | null>(null)

	// delete presentation
	const [deletePresentationModalVisible, toggleDeletePresentationModalVisible] =
		React.useState<number | null>(null)
	const [disableDeleteButton, setDisableDeleteButton] = React.useState(false)

	const [isColleagueShareModalVisible, toggleShareColleagueModal] =
		React.useState<MeetingInterface | undefined>(undefined)

	// sorting
	const [sortingColumn, setSortingColumn] =
		React.useState<SortingMemositesColumnEnum>(
			SortingMemositesColumnEnum.CREATED_AT
		)
	const [sort, setSort] = React.useState<SortEnum>(SortEnum.DESC)

	// pagination
	const [page, setPage] = React.useState<number>(0)
	const [totalPages, setTotalPages] = React.useState<number>(0)

	const { loadedPresentationDirty } = useSelector(
		(store: StoreInterface) => store.presentation
	)

	// redux store
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { presentations, selectedPresentation, reloadPresentations } =
		useSelector((store: StoreInterface) => store.myPresentations)
	const memositeBuilderStatus = useSelector(
		(store: StoreInterface) => store.memositeBuilder.status
	)

	const handleMyPresentationsResponse = (
		response: MeetingsResponseInterface
	) => {
		setTotalPages(Math.ceil(response.total / presentationsPerPage))
		const presentationsList: MeetingInterface[] = response.meetings.map(
			(item: MeetingInterface) => ({
				...item,
				numberOfSlides: JSON.parse(item.extended_fields)?.summary?.length || 0,
				extended_fields_object: JSON.parse(item.extended_fields)
			})
		)
		dispatch(setMyPresentationsValue('presentations', presentationsList))
	}

	const getFilteredPresentations = (selectedPage: number) => {
		setPage(selectedPage || 0)
		setIsLoading(true)
		filterMemosites(
			'1970-01-01%2000:00:00',
			`${format(new Date(), 'yyyy-MM-dd')} 23:59:59`,
			0,
			0,
			selectedPage,
			presentationsPerPage,
			0,
			searchTerm || null,
			0,
			sortingColumn,
			sort,
			0
		)
			.then((response) => {
				setIsLoading(false)
				handleMyPresentationsResponse(response)
			})
			.catch((e) => {
				console.error(e)
				setIsLoading(false)
			})
	}

	React.useEffect(() => {
		if (reloadPresentations) {
			dispatch(setMyPresentationsValue('reloadPresentations', false))
			getFilteredPresentations(0)
		}
		// reset myPresentations reducer when user leaves the page
		return () => {
			dispatch(resetMyPresentationsReducer())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadPresentations])

	React.useEffect(() => {
		fetchPendingPresentations()
		dispatch(
			setTempValue('currentModule', ModuleNamesInterface.MY_PRESENTATIONS)
		)
		dispatch(
			addToAnalyticsBatch({
				event: `loadModule.MyPresentations`
			})
		)
		return () => {
			dispatch(
				addToAnalyticsBatch({
					event: `unloadModule.MyPresentations`
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updatePresentationeSubjectHandler = (id: number, subject: string) => {
		updatePresentationTitle(
			{
				id,
				subject
			},
			user.token
		).then(() => {
			const updatedPresentations = presentations.map((item) =>
				item.id === id ? { ...item, subject } : item
			)
			dispatch(setMyPresentationsValue('presentations', updatedPresentations))
		})
	}

	const handleOpenPresentation = (presentation: MeetingInterface) => {
		if (selectedPresentation?.id === presentation.id) {
			dispatch(setMyPresentationsValue('selectedPresentation', undefined))
			return
		}
		dispatch(setMyPresentationsValue('selectedPresentation', presentation))
	}

	const deletePresentationHandler = () => {
		deleteMemosite(user.token, deletePresentationModalVisible)
			.then(() => {
				const newPresentations = presentations.filter(
					(item: any) => item.id !== deletePresentationModalVisible
				)
				dispatch(setMyPresentationsValue('presentations', newPresentations))
				toggleDeletePresentationModalVisible(null)
				setDisableDeleteButton(false)
				if (selectedPresentation?.id === deletePresentationModalVisible) {
					dispatch(setMyPresentationsValue('selectedPresentation', undefined))
				}
				toast(t('notifications.success.presentation_deleted'), {
					position: toast.POSITION.BOTTOM_RIGHT,
					type: toast.TYPE.SUCCESS,
					autoClose: 5000
				})
			})
			.catch(() => {
				toggleDeletePresentationModalVisible(null)
				setDisableDeleteButton(false)
			})
	}

	const duplicatePresentationHandler = async (
		presentation: MeetingInterface
	) => {
		const meetingObject = {
			...presentation,
			subject: presentation.subject.includes('copy')
				? incrementIfEndsWithNumber(presentation.subject)
				: presentation.subject + ' copy 01'
		}
		createMemoSite(meetingObject, user.token).then(() => {
			dispatch(setMyPresentationsValue('reloadPresentations', true))
		})
	}

	React.useEffect(() => {
		if (searchTerm) {
			const delaySearch = setTimeout(() => {
				getFilteredPresentations(0)
			}, 750) // Delay time in milliseconds
			return () => clearTimeout(delaySearch)
		} else {
			if (slug || slugHash) {
				setIsLoading(true)
				getSingleMeetingBySlug(presentationsPerPage, slug || slugHash, true)
					.then((response: MeetingsResponseInterface) => {
						setIsLoading(false)
						if (response.page) {
							setPage(response.page)
						}
						handleMyPresentationsResponse(response)
						const presentation = response.meetings.find(
							(item: MeetingInterface) =>
								item.slug === slug || item.slug === slugHash
						)
						dispatch(
							setMyPresentationsValue('selectedPresentation', presentation)
						)
					})
					.catch(() => setIsLoading(false))
			} else {
				getFilteredPresentations(0)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	const openPresentationHandler = (presentation: MeetingInterface) => {
		dispatch(clearPresentation())
		dispatch(addToPresentation(presentation?.extended_fields_object?.summary))
		dispatch(setLoadedPresentation(presentation))
		dispatch(setLoadedPresentationDirty(false))
		dispatch(setMemositeBuilderValue('memoInProgress', false))
		toast(t('notifications.success.presentation-loaded'), {
			position: toast.POSITION.BOTTOM_RIGHT,
			type: toast.TYPE.SUCCESS,
			autoClose: 5000
		})
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.opened',
				id: presentation.id,
				name: presentation.subject
			})
		)
	}

	const openPresentationAsMemositeHandler = (
		presentation: MeetingInterface
	) => {
		dispatch(clearPresentation())
		dispatch(addToPresentation(presentation?.extended_fields_object?.summary))
		dispatch(setMemositeBuilderValue('status', 'opened'))
	}

	/**
	 * @param {string} fieldName is name of the field to determine caret location
	 * @description determine which caret should be show next to which field
	 * @returns {object} caret element
	 */
	const getSortingCaretIcon = (fieldName: string) => {
		return (
			<span>
				{sortingColumn === fieldName && (
					<React.Fragment>
						{sort === 'desc' ? (
							<SortDownIcon className={styles.arrowIcon} />
						) : (
							<SortUpIcon className={styles.arrowIcon} />
						)}
					</React.Fragment>
				)}
			</span>
		)
	}

	const changeSort = (sortColumn: any) => {
		const sortDirection =
			sortColumn === sortingColumn
				? sort === SortEnum.DESC
					? SortEnum.ASC
					: SortEnum.DESC
				: SortEnum.DESC
		setSort(sortDirection)
		setSortingColumn(sortColumn)
		getFilteredPresentations(0)
	}

	const checkForUnsavedChangesHandler = (presentation: MeetingInterface) => {
		if (memositeBuilderStatus === 'opened' || loadedPresentationDirty) {
			toggleUnsavedChangesModal(presentation)
		} else {
			openPresentationHandler(presentation)
		}
	}

	const handlePresentationAcceptance = (
		id: string,
		notificationId: number,
		isDeclined?: boolean
	) => {
		const removePresentation = () => {
			const newPresentations = pendingPresentations.filter(
				(item: any) => item.id !== id
			)
			setPendingPresentations(newPresentations)
			getFilteredPresentations(0)
		}
		if (isDeclined) {
			declineSharedPresentation(
				{
					messageId: notificationId.toString()
				},
				user.token
			).then(() => removePresentation())
		} else {
			acceptSharedPresentation(
				{
					presentationId: id,
					messageId: notificationId.toString()
				},
				user.token
			).then(() => removePresentation())
		}
	}

	const fetchPendingPresentations = () => {
		getFeedNotificationsByCode('SHARED_PRESENTATION').then((response) => {
			const formattedPresentations: PendingPresentationInterface[] = []
			response.data.forEach((item) => {
				if (typeof item.properties === 'string') {
					const parsedProperties = JSON.parse(item.properties)
					formattedPresentations.push({
						id: parsedProperties['data-id'],
						subject: parsedProperties.subject,
						invited_by: `${item.user.firstname} ${item.user.lastname}`,
						notificationId: item.id
					})
				}
			})
			if (formattedPresentations.length > 0) {
				setPendingPresentations(formattedPresentations)
			}
		})
	}

	return (
		<div className={styles.wrapper}>
			<div>
				<div>
					<div className={styles.heading}>
						<div className={styles.title}>
							<MainIcon />
							{t('titles.my_presentations')}
						</div>
						<div className={styles.search}>
							<Button
								containerClass={styles.btnTextWrapper}
								buttonClass={styles.btnText}
								type={BUTTON_TEXT}
								iconSide={ICON_LEFT}
								icon={<InfoIcon />}
								onClick={() => null}
								id="my-presentations-featured-guide-button"
								tooltip={t('tooltips.quick_guide_for', [
									t('titles.my_presentations')
								])}
							/>
							<div className={styles.searchInput}>
								<input
									type="text"
									placeholder={t('input-labels.search_presentations')}
									value={searchTerm}
									onChange={(event) => {
										setSearchTerm(event.target.value)
									}}
								/>
								{searchTerm.length > 0 ? (
									<ClearSearchIcon
										onClick={() => {
											setSearchTerm('')
										}}
									/>
								) : (
									<SearchIcon />
								)}
							</div>
						</div>
					</div>
					{isLoading ? (
						<div className={styles.spinnerWrapper}>
							<Spinner isLoading={isLoading} />
						</div>
					) : (
						<React.Fragment>
							<div className={styles.myMemositesList}>
								<div className={styles.heading}>
									<div className={styles.title} />
									<div className={styles.slides}>{t('labels.slides')}</div>
									<div
										className={c(styles.dateCreated, styles.sortable)}
										onClick={sort ? () => changeSort('created_at') : () => null}
									>
										{t('labels.date-created')}
										{getSortingCaretIcon('created_at')}
									</div>
									<div
										className={c(styles.dateLastEdited, styles.sortable)}
										onClick={sort ? () => changeSort('updated_at') : () => null}
									>
										{t('labels.last-edited')}
										{getSortingCaretIcon('updated_at')}
									</div>
								</div>
								<div className={styles.listContainer}>
									{pendingPresentations.length > 0 &&
										pendingPresentations.map((item, index) => (
											<PendingPresentationItem
												key={index}
												presentation={item}
												presentationToAccept={handlePresentationAcceptance}
											/>
										))}
									{presentations.length > 0 ? (
										presentations?.map((item, index) => (
											<PresentationItem
												handleOpenPresentation={handleOpenPresentation}
												key={index}
												presentation={item}
												presentationToDelete={
													toggleDeletePresentationModalVisible
												}
												presentationToDuplicate={duplicatePresentationHandler}
												openPresentationAsMemosite={
													openPresentationAsMemositeHandler
												}
												presentationToLoad={checkForUnsavedChangesHandler}
												updatePresentationSubject={
													updatePresentationeSubjectHandler
												}
												shareWithColleague={(presentation) =>
													toggleShareColleagueModal(presentation)
												}
											/>
										))
									) : (
										<p className={styles.noMemositesFound}>
											{t(
												'misc.no_presentations_found_try_adjusting_your_filters'
											)}
										</p>
									)}
									{presentations.length > 0 && (
										<Pagination
											changePage={(pageToSet) =>
												getFilteredPresentations(pageToSet.selected)
											}
											memositePaginationPage={page}
											numberOfPages={totalPages}
										/>
									)}
								</div>
							</div>
						</React.Fragment>
					)}
					<DeleteMemositeModal
						deletePresentation={deletePresentationHandler}
						disableDeleteButton={disableDeleteButton}
						handleClose={() => toggleDeletePresentationModalVisible(null)}
						isActive={!!deletePresentationModalVisible}
					/>
					<ShareWithColleagueModal
						isVisible={!!isColleagueShareModalVisible}
						handleClose={() => toggleShareColleagueModal(undefined)}
						presentationId={isColleagueShareModalVisible?.id || 0}
					/>
					<ConfirmDeleteModal
						isActive={!!unsavedChangesModalVisible}
						handleClose={() => toggleUnsavedChangesModal(null)}
						onApprove={() => {
							toggleUnsavedChangesModal(null)
							if (unsavedChangesModalVisible) {
								openPresentationHandler(unsavedChangesModalVisible)
							}
						}}
						title={t('titles.are-you-sure')}
						confirmLabel={t('labels.open-presentation')}
						cancelLabel={t('buttons.cancel')}
					>
						<div className={styles.confirmNewPresentation}>
							<p>{t('misc.are_you_sure_open_new_presentation')}</p>
						</div>
					</ConfirmDeleteModal>
				</div>
			</div>
		</div>
	)
}

export default MyPresentations
