import React from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import FolderIcon from '@mui/icons-material/FolderOutlined'

import styles from './InfoModal.module.scss'

import { t } from '../../../../utils/languages/i18n'
import Button from '../../../common/Button'
import Modal from '../../../common/ModalNew'
import formatBytes from '../../../../utils/helpers/formatBytes'

interface InfoModalInterface {
	isVisible: boolean
	closeModal: () => void
	cardObject: any
	thumbnailUrl: string
}

const InfoModal = ({
	isVisible,
	closeModal,
	cardObject,
	thumbnailUrl
}: InfoModalInterface) => {
	return (
		<Modal
			isShowing={isVisible}
			hide={closeModal}
			customClassName={classnames(styles.modalWrapper)}
			modalTitle={t('titles.content-info')}
			headerClassname={styles.header}
			zIndex={10000}
			footer={
				<div className={styles.buttonsContainer}>
					<Button
						label={t('buttons.close')}
						onClick={closeModal}
						isPositive
						id="info-modal-close"
					/>
				</div>
			}
		>
			<div className={styles.content}>
				<div className={styles.contentFlex}>
					<div className={styles.img}>
						<img src={thumbnailUrl} alt="" />
					</div>
					<div className={styles.info}>
						<div className={styles.firstRow}>
							<div className={styles.name}>
								<div className={styles.label}>{t('misc.name')}</div>
								<div>{cardObject?.name}</div>
							</div>
							<div className={styles.type}>
								<div className={styles.label}>{t('misc.type')}</div>
								<div>{cardObject?.type}</div>
							</div>
							<div className={styles.size}>
								<div className={styles.label}>{t('misc.size')}</div>
								<div>{cardObject?.size && formatBytes(cardObject.size, 1)}</div>
							</div>
						</div>
						<div className={styles.secondRow}>
							<div className={styles.parent}>
								<div className={styles.label}>{t('misc.parent-file')}</div>
								<div className={styles.fileName}>{cardObject?.parent_file}</div>
							</div>
							<div className={styles.added}>
								<div className={styles.label}>{t('misc.added')}</div>
								<div>
									{cardObject?.created_at &&
										format(new Date(cardObject.created_at), 'dd / MM / yyyy')}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.description}>
					<div className={styles.label}>{t('misc.description')}</div>
					<div>{cardObject?.description}</div>
				</div>
				{cardObject.tags && cardObject.tags.length > 0 && (
					<div className={styles.folderWrapper}>
						<div className={styles.label}>{t('titles.folders')}</div>
						<div className={styles.folders}>
							{cardObject.tags.map((tag: { name: string }, index: number) => (
								<div className={styles.folder} key={index}>
									<FolderIcon />
									{tag.name}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default InfoModal
