import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from './SlidesContainer.module.scss'

import Card from '../../../../../components/Card'
import { t } from '../../../../../utils/languages/i18n'
import { GRID, MY_MEMOSITES_ } from '../../../../../utils/consts'
import { addToTempPresentation } from '../../../../../store/actions/temp'
import { FileInterface, StoreInterface } from '../../../../../utils/interfaces'
import CardList from '../../CardList'

interface SlidesContainerInterface {
	slides: FileInterface[] | []
}

const SlidesContainer = ({ slides }: SlidesContainerInterface) => {
	const dispatch = useDispatch()

	const tempPresentationSlides = useSelector(
		(store: StoreInterface) => store.temp.slides
	)
	const { user } = useSelector((store: StoreInterface) => store.authUser)
	const { view } = useSelector((store: StoreInterface) => store.myPresentations)

	const handleSelect = (file: any, e: any) => {
		e.stopPropagation()
		e.preventDefault()
		dispatch(addToTempPresentation(file, MY_MEMOSITES_))
	}

	return (
		<div>
			{view === GRID ? (
				<div className={styles.slidesContainerGrid}>
					{slides.map((item: any, index: number) => (
						<div key={`${index.toString()}`} className={styles.cardContainer}>
							<Card
								isSelected={tempPresentationSlides[
									MY_MEMOSITES_.toLowerCase()
								].includes(item)}
								cardObject={{
									...item,
									extension: item.type,
									id: item.id,
									time: item.formattedTime
								}}
								className={styles.slideItem}
								handleCardClick={(e) => handleSelect(item, e)}
								hasLayoutFontColor={false}
								hasToolbar
								openFileEditor={() => null}
								isEditable={false}
							/>
						</div>
					))}
				</div>
			) : (
				<div className={styles.slidesContainerListWrapper}>
					<div className={styles.headers}>
						<div className={styles.slidesCount}>{`${slides.length} ${t(
							'labels.slides'
						)}`}</div>
						<div className={styles.dFlex}>
							<div>{t('misc.type')}</div>
						</div>
					</div>
					<div className={styles.slidesContainerList}>
						{slides.length > 0 &&
							slides.map((item: any, index: number) => (
								<CardList authToken={user.token} item={item} key={index} />
							))}
					</div>
				</div>
			)}
		</div>
	)
}

export default SlidesContainer
