import {
	cloudFnDelete,
	cloudFnGet,
	cloudFnPatch,
	cloudFnPost
} from '../../utils/requests'
import {
	getLabels,
	addLabelToMeeting,
	deleteLabelFromMeeting,
	createOrUpdateLabel,
	addLabelToMeetingBulk
} from '../apiEndpoints_new'
import { LabelInterface } from '../../utils/interfaces'
import {
	deleteLabelSchema,
	labelSchema,
	labelToMeetingBulkSchema,
	labelToMeetingSchema
} from '../../utils/schemas/requests'

export const getAllLabels = () =>
	new Promise<LabelInterface[]>((resolve, reject) => {
		cloudFnGet(`${getLabels}`, {}, null)
			.then((response) => {
				resolve(response as LabelInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const connectLabelToMeeting = (data: {
	meeting_id: number
	label_id: number
}) =>
	new Promise<LabelInterface[]>((resolve, reject) => {
		cloudFnPost(`${addLabelToMeeting}`, data, null, labelToMeetingSchema)
			.then((response) => {
				resolve(response as LabelInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const removeLabelFromMeeting = (data: {
	meeting_id: number
	label_id: number
}) =>
	new Promise<LabelInterface[]>((resolve, reject) => {
		cloudFnDelete(`${deleteLabelFromMeeting}`, data, null, labelToMeetingSchema)
			.then((response) => {
				resolve(response as LabelInterface[])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const createLabel = (data: {
	name: string
	type: string
	color: string
}) =>
	new Promise<LabelInterface>((resolve, reject) => {
		cloudFnPost(`${createOrUpdateLabel}`, data, null, labelSchema)
			.then((response) => {
				resolve(response as LabelInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const updateLabel = (
	id: number,
	data: {
		name: string
		type: string
		color: string
	}
) =>
	new Promise<LabelInterface>((resolve, reject) => {
		cloudFnPatch(`${createOrUpdateLabel}/${id}`, data, null, labelSchema)
			.then((response) => {
				resolve(response as LabelInterface)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const deleteLabel = (id: number) =>
	new Promise((resolve, reject) => {
		cloudFnDelete(`${createOrUpdateLabel}/${id}`, {}, deleteLabelSchema)
			.then((response: any) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const connectLabelToMeetingBulk = (data: {
	meeting_id: number
	labels: number[]
}) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			`${addLabelToMeetingBulk}`,
			data,
			null,
			labelToMeetingBulkSchema
		)
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
