import React from 'react'
import classnames from 'classnames'
import ReactDOM from 'react-dom'

import styles from './BlurOverlay.module.scss'
import { isMozilla } from '../../../utils/userAgents'

interface BlurOverlayInterface {
	isVisible: boolean
	onClose: () => void
	selector?: string
	show: boolean
}

const BlurOverlay = ({
	isVisible,
	onClose,
	show,
	selector = '#root'
}: BlurOverlayInterface) =>
	isVisible
		? ReactDOM.createPortal(
				<div
					role="button"
					tabIndex={-1}
					className={classnames(
						styles.overlay,
						isMozilla() ? styles.mozillaHelper : styles.backdrop,
						!show ? styles.hidden : styles.visible
					)}
					onClick={onClose}
				/>,
				document.querySelector(selector) as Element
		  )
		: null

export default BlurOverlay
