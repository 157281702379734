import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'

import Button from '../Button'
import Input from '../Input'
import Modal from '../ModalNew'
import styles from './SavePresentationModal.module.scss'
import { t } from '../../../utils/languages/i18n'
import { savePresentation } from '../../../api/requests/presentations'
import { FLEX_DIRECTION_COL, FLEX_START, SUCCESS } from '../../../utils/consts'
import { setLoadedPresentation } from '../../../store/actions/presentation'
import { addToAnalyticsBatch } from '../../../store/actions/analytics'
import Section from '../Section'
import { removeUnnecessaryFieldsFromSlide } from '../../../utils/helpers/presentation'
import { ModuleNamesInterface } from '../../../utils/interfaces'
import { setMyPresentationsValue } from '../../../store/actions/myPresentations'

/**
 * @author zilahir
 * @function SavePresentationModal
 * */

const SavePresentationModal = ({ isOpen, handleClose, callback }) => {
	const [presentationName, setPresentationName] = useState('')
	const authToken = useSelector((store) => store.authUser.user.token)
	const presentatioinSlides = useSelector(
		(store) => store.presentation.presentationBarSlides
	)
	const { currentModule } = useSelector((store) => store.temp)
	const dispatch = useDispatch()

	/**
	 *
	 * @description Saving presentation with the currently added
	 * slides to the presentation bar
	 * and adds the saved presentation data to analytics batch
	 */
	const handleSavePresentation = () => {
		savePresentation(
			{
				subject: presentationName,
				status: 0,
				starttime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
				endtime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
				prospect_id: 1,
				extended_fields: JSON.stringify({
					summary: presentatioinSlides.map((slideItem) =>
						removeUnnecessaryFieldsFromSlide(slideItem)
					)
				})
			},
			authToken
		).then((savedPresentationData) => {
			setPresentationName('')
			if (callback) {
				callback(savedPresentationData)
			}

			dispatch(setLoadedPresentation(savedPresentationData))

			handleClose(false)
			toast(t('notifications.success.presentation-saved'), {
				position: toast.POSITION.BOTTOM_RIGHT,
				type: SUCCESS.toLowerCase(),
				autoClose: 5000
			})
			if (currentModule === ModuleNamesInterface.MY_PRESENTATIONS) {
				dispatch(setMyPresentationsValue('reloadPresentations', true))
			}
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.saved',
					id: savedPresentationData.id,
					subject: savedPresentationData.subject
				})
			)
		})
	}

	/**
	 *
	 * @description we are closing the modal, becasue we are not saving
	 */
	function closeModal() {
		handleClose(false)
	}

	return (
		<Modal
			isShowing={isOpen}
			headerClassname={styles.savePresModalHeader}
			modalClassName={styles.savePresentationModal}
			modalTitle="Save Presentation"
			customClassName={styles.savePresModal}
			hide={() => closeModal()}
			footer={
				<div className={styles.actionBtnContainer}>
					<Button
						label={t('buttons.cancel')}
						onClick={() => closeModal()}
						id="save-presentation-modal-cancel-label-button"
					/>
					<Button
						label={t('buttons.save')}
						onClick={() => handleSavePresentation()}
						isPositive
						id="save-presentation-modal-save-label-button"
					/>
				</div>
			}
		>
			<Section
				flexDirection={FLEX_DIRECTION_COL}
				justify={FLEX_START}
				alignItems={FLEX_START}
			>
				<div className={styles.innerContainer}>
					<p>{t('titles.name-presentation')}</p>
					<div className={styles.inputContainer}>
						<Input
							placeholderText="Name"
							onChange={(event) => setPresentationName(event.target.value)}
							initialValue={presentationName}
							name="presentationname"
						/>
					</div>
				</div>
			</Section>
		</Modal>
	)
}

SavePresentationModal.defaultProps = {
	callback: null,
	isOpen: false
}

SavePresentationModal.propTypes = {
	callback: PropTypes.func,
	handleClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool
}

export default SavePresentationModal
