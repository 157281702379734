import {
	converterSchema,
	converterForRestoreSchema,
	splitFileSchema
} from '../../utils/schemas/requests'
import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import {
	filesConverterEndpoint,
	filesConverterRestoreEndpoint,
	splitFileEndpoint
} from '../apiEndpoints_new'
import { SplitFileResponse } from '../../utils/interfaces'

export const sendFileToConvert = (data: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(filesConverterEndpoint, data, null, converterSchema)
			.then((result: any) => {
				resolve({
					isSuccess: true,
					...result
				})
			})
			.catch((error) => {
				reject(JSON.parse(error.message))
			})
	})

export const sendFileToRestore = (data: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			filesConverterRestoreEndpoint,
			data,
			null,
			converterForRestoreSchema
		)
			.then((result: any) => {
				resolve(result)
			})
			.catch((error) => {
				reject(JSON.parse(error.message))
			})
	})

export const getFileConvertStatus = (checksum: any) =>
	new Promise((resolve, reject) => {
		cloudFnGet(filesConverterEndpoint, { checksum }, null)
			.then((result) => {
				resolve([result])
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getSplitStatus = (reference: string) =>
	new Promise<SplitFileResponse>((resolve, reject) => {
		cloudFnGet(`${splitFileEndpoint}/${reference}`, {}, null)
			.then((result) => {
				resolve(result as SplitFileResponse)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const splitFile = (
	id: number,
	status: number,
	tagsId: string | number,
	checksum: string,
	name: string
) =>
	new Promise<SplitFileResponse>((resolve, reject) => {
		cloudFnPost(
			splitFileEndpoint,
			{
				id,
				status,
				tags_id: tagsId,
				checksum,
				name
			},
			null,
			splitFileSchema
		)
			.then((result) => {
				resolve(result as SplitFileResponse)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
