import { SET_USER_GROUPS, SET_USERS } from '../actions/actionTypes'

const initialState = {
	userGroups: [],
	users: []
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case SET_USER_GROUPS:
			return {
				...state,
				userGroups: action.payload.userGroups
			}
		case SET_USERS: {
			return {
				...state,
				users: action.payload.users
			}
		}
		default:
			return state
	}
}

export default reducer
