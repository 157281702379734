

import { SET_FOLDERS_CUSTOM_SLIDES, SET_TEMPLATES_CUSTOM_SLIDES } from '../actions/actionTypes'

const initialState = {
	folders: [],
	content: [],
}

const reducer = (state = initialState, action: any) => {
	switch (action.type) {
	case SET_FOLDERS_CUSTOM_SLIDES:
		return {
			...state,
			folders: action.payload.folders,
		}
	case SET_TEMPLATES_CUSTOM_SLIDES:
		return {
			...state,
			content: action.payload.content,
		}
	default:
		return state
	}
}

export default reducer
