const subscribe = (eventName: string, listener: any) => {
	document.addEventListener(eventName, listener)
}
const unsubscribe = (eventName: string, listener: any) => {
	document.removeEventListener(eventName, listener)
}
const publish = (eventName: string, data: unknown) => {
	const event = new CustomEvent<unknown>(eventName, { detail: data })
	document.dispatchEvent(event)
}
export { subscribe, unsubscribe, publish }
