import React from 'react'

import { t } from '../../../../../../utils/languages/i18n'
import styles from './Disconnected.module.scss'
import {
	CLOSED,
	CAMERA_ERROR,
	NO_MEDIA_RECORDER
} from '../../../../../../utils/consts'

interface DisconnectedInterface {
	cameraStatus: string
}

const Disconnected = ({ cameraStatus }: DisconnectedInterface) => (
	<div className={styles.disconnectedContainer}>
		{cameraStatus === CLOSED.toLowerCase() && (
			<React.Fragment>
				<p>{t('misc.camera-disconnected')}</p>
				<p>{t('misc.press-button-below')}</p>
			</React.Fragment>
		)}
		{cameraStatus === CAMERA_ERROR.toLowerCase() && (
			<p>{t('misc.camera_error')}</p>
		)}
		{cameraStatus === NO_MEDIA_RECORDER.toLowerCase() && (
			<React.Fragment>
				<p>{t('misc.cannot_access_media_recorder')}</p>
				<p>{t('misc.possible_ad_blocker')}</p>
			</React.Fragment>
		)}
	</div>
)

export default Disconnected
