import { cloudFnGet, cloudFnPost } from '../../utils/requests'
import { analyticsSchema } from '../../utils/schemas/requests'
import {
	analyticsEventsEndpoint,
	getPopularFilesEndpointV2
} from '../apiEndpoints_new'
import { SlideInterface } from '../../utils/interfaces'

export const sendAnalyticsBatch = (analyticsBatch: any, authToken: any) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			analyticsEventsEndpoint,
			analyticsBatch,
			{ withToken: true, authToken, contentType: 'application/json' },
			analyticsSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})

export const getPopularFiles = (timeFrom: string, timeTo: string) =>
	new Promise<{ popular: SlideInterface[] }>((resolve, reject) => {
		cloudFnGet(`${getPopularFilesEndpointV2}/${timeFrom}/${timeTo}`, {}, null)
			.then((result) => {
				resolve(result as { popular: SlideInterface[] })
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
