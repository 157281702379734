import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { nanoid } from 'nanoid'

import { defaultDropdownStyles } from './utils/styles'

import customStyles from './DropdownCreatable.module.scss'

import { t } from '../../../utils/languages/i18n'

const DropdownCreatable = ({
	defaultIndex,
	listItems,
	onChangeCallback,
	placeholder,
	placement,
	styles,
	name
}) => (
	<CreatableSelect
		className="react-select"
		classNamePrefix="react-select"
		defaultValue={defaultIndex >= 0 && listItems[defaultIndex]}
		isDisabled={false}
		isLoading={false}
		isClearable
		isSearchable
		name={name}
		options={listItems}
		styles={styles}
		onChange={(selected) => onChangeCallback(selected)}
		menuPlacement={placement}
		{...(placeholder && { placeholder })}
		getOptionLabel={(option) =>
			option.label.includes('Create') ? (
				<React.Fragment>
					<span className={customStyles.new}>{t('labels.create')}</span>{' '}
					{option.value}
				</React.Fragment>
			) : (
				option.label
			)
		}
	/>
)

DropdownCreatable.defaultProps = {
	defaultIndex: 0,
	name: nanoid(),
	onChangeCallback: () => {},
	placeholder: null,
	placement: 'bottom',
	styles: defaultDropdownStyles
}

DropdownCreatable.propTypes = {
	defaultIndex: PropTypes.number,
	listItems: PropTypes.instanceOf(Object).isRequired,
	name: PropTypes.string,
	onChangeCallback: PropTypes.func,
	placeholder: PropTypes.string,
	placement: PropTypes.string,
	styles: PropTypes.instanceOf(Object)
}

export default DropdownCreatable
