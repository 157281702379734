import React, { useEffect, useState, useCallback, useRef } from 'react'
import Reveal from 'reveal.js'
import { useSelector, useDispatch } from 'react-redux'
import 'reveal.js/css/reveal.scss'
import { AnimatePresence, motion } from 'framer-motion'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import CloseIcon from '@mui/icons-material/Close'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { toast } from 'react-toastify'
import useFingerprint from 'use-fingerprint'
import { format } from 'date-fns'
import classnames from 'classnames'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useTimer } from 'use-timer'
import screenfull from 'screenfull'

import styles from './SlideshowReveal.module.scss'

import Background from '../common/Background'
import {
	IMAGE_TYPES,
	GIF,
	MSDOC_TYPES,
	PDF,
	YOUTUBE,
	VIMEO,
	VIDEOS,
	URL,
	BUTTON_TEXT,
	SUCCESS,
	ICON_LEFT
} from '../../utils/consts'
import { createImageUrl } from '../../utils/helpers/decideThumbanil'
import { useEventListener } from '../../utils/hooks/useEventListener'
import { t } from '../../utils/languages/i18n'
import Button from '../common/Button'
import { starContent } from '../../api/requests/content'
import { getAllStarredContent } from '../../store/actions/content'
import { addToAnalyticsBatch } from '../../store/actions/analytics'
import { clearSlideShow } from '../../store/actions/presentation'
import { createPdfNew } from '../../api/requests/pdf'
import { getPreviewVideoUrl } from '../../utils/helpers/fileUrl'
import isIOS from '../../utils/helpers/isIOS'
import { addTokenToSlideTitle } from '../../utils/helpers/models/slide'
import { sfApiRoot } from '../../api/apiEndpoints_new'
import { setTempValue } from '../../store/actions/temp'

const topBarVariants = {
	hidden: {
		y: -20,
		opacity: 0
	},
	visible: {
		y: 0,
		opacity: 1,
		zIndex: 999
	}
}

const animationVariants = {
	hidden: {
		opacity: 0,
		scale: 0.75,
		y: 10,
		display: 'none'
	},
	visible: {
		opacity: 1,
		scale: 1,
		y: 0,
		display: 'block'
	},
	exit: {
		opacity: 0,
		scale: 0.85,
		y: -20
	}
}

const slideShowNavigationVariants = {
	hidden: {
		opacity: 0,
		zIndex: -1
	},
	visible: {
		opacity: 1,
		zIndex: 9
	}
}

const delay = 5

const SlideshowReveal = () => {
	const container = useRef()
	const revealRef = useRef()
	const dispatch = useDispatch()

	const [showLeft, toggleShowLeft] = useState(true)
	const [showRight, toggleShowRight] = useState(true)
	const [showTop, toggleShowTop] = useState(true)
	const [clicked, toggleClicked] = useState(true)
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
	const [revealInitialized, setRevealInitialized] = useState(false)

	const presentationSlides = useSelector(
		(state) => state.presentation.slideShowSlides.slides
	)
	const startFrom = useSelector(
		(state) => state.presentation.slideShowSlides.startFrom
	)
	const loadedPresentation = useSelector(
		(state) => state.presentation.loadedPresentation
	)
	const loggedInUserName =
		useSelector((state) => state?.authUser?.user?.user?.firstname) || undefined
	const authToken = useSelector((state) => state.authUser.user.token)
	const allStarredContent = useSelector((store) => store.content.starredContent)
	const isThisStarred =
		presentationSlides.length > 0
			? allStarredContent.some(
					(thisStarredContent) =>
						thisStarredContent.id === presentationSlides[currentSlideIndex].id
			  )
			: false
	const { time, start, reset } = useTimer()
	const fingerPrint = useFingerprint()

	/**
	 *
	 * @description clears slideshow if param is true
	 * @param {boolean} closeSlideshow if true empty presentationSlides and close slideshow
	 */
	function oncloseSlideshow(closeSlideshow) {
		if (closeSlideshow) {
			dispatch(clearSlideShow())
		}
	}

	/**
	 * @description handle start from value and events after reveal is initializes
	 */
	function revealInitializedHandler() {
		if (startFrom > 0) {
			Reveal.slide(startFrom)
			setCurrentSlideIndex(startFrom)
		} else {
			Reveal.slide(0)
			setCurrentSlideIndex(0)
		}
		Reveal.on('slidechanged', (event) => {
			setCurrentSlideIndex(event.indexh)
		})
	}

	function restart() {
		reset()
		start()
	}

	/**
	 * @description add file analytics to reducer if time is larger or equal to 1 second
	 * @param times
	 */
	function saveTime(times) {
		if (times >= 1) {
			const file = presentationSlides[currentSlideIndex]
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.material.time-on-slide',
					checksum: file.checksum,
					event_timestamp: format(new Date(), 'yyyy-MM-dd hh:mm'),
					id: file.id,
					name: file.name,
					origin: file._type,
					parent_file: file.parent_file || '',
					parent_file_name: file._file ? file._file.name : '',
					status: file.status,
					tag_id: file.tags_id,
					time: times > 5400 ? 5400 : times,
					type: file.type,
					uuid: fingerPrint,
					fingerprint: fingerPrint
				})
			)
		}
	}

	/**
	 * @description goes back in browser's history
	 * and cleares the slideShow reducer and resets time
	 * call saveTime function for saving time to reduces
	 */
	function handleGoBack() {
		saveTime(time)
		reset()
		oncloseSlideshow(true)
	}

	useEffect(() => {
		restart()
		if (presentationSlides.length > 0) {
			if (revealInitialized) {
				Reveal.sync()
				revealInitializedHandler()
			} else {
				Reveal.initialize({
					controlsLayout: 'edges',
					progress: false,
					disableLayout: true,
					display: 'flex',
					embedded: false,
					controls: false,
					preloadIframes: false,
					transition: 'slide'
				}).then(() => {
					setRevealInitialized(true)
					revealInitializedHandler()
				})
				Reveal.configure({
					keyboard: {
						27: () => {
							handleGoBack()
						}
					}
				})
			}
		} else {
			toggleShowLeft(true)
			toggleShowRight(true)
			toggleShowTop(true)
			toggleClicked(true)
			setCurrentSlideIndex(0)
			document.querySelectorAll('body')[0].removeAttribute('class')
			document.querySelectorAll('body')[0].removeAttribute('style')
			document.querySelectorAll('html')[0].removeAttribute('class')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [presentationSlides])

	const handleMouseMove = useCallback(
		({ clientX, clientY }) => {
			if (clientX < 150) {
				toggleShowLeft(true)
			} else {
				toggleShowLeft(false)
			}
			if (clientX > window.innerWidth - 150) {
				toggleShowRight(true)
			} else {
				toggleShowRight(false)
			}
			if (clientY < 60) {
				toggleShowTop(true)
			} else {
				toggleShowTop(false)
			}
		},
		[toggleShowLeft, toggleShowRight, toggleShowTop]
	)

	useEventListener('mousemove', handleMouseMove, container.current)

	useEffect(() => {
		let timer = setTimeout(() => {
			if (clicked) {
				toggleClicked(false)
			}
		}, delay * 1000)
		return () => {
			clearTimeout(timer)
		}
	}, [clicked])

	/**
	 * @description handle sldie content
	 * @param {object} slide object
	 * @returns {JSX.Element} slide content
	 */
	function renderSlides(slide) {
		if (
			IMAGE_TYPES.includes(slide.type.toUpperCase()) ||
			slide.type.toUpperCase() === GIF
		) {
			const { checksum, _type } = slide
			const imageUrl = createImageUrl(checksum, authToken, _type)
			return (
				<section className={styles.slideSection} data-transition="slide">
					<img data-src={imageUrl} alt={slide.name} className={styles.image} />
				</section>
			)
		}
		if (
			MSDOC_TYPES.findIndex((item) => item === slide.type.toUpperCase()) > -1
		) {
			return (
				<section className={styles.slideSection}>
					<div className={styles.slideContent}>
						<iframe
							title={slide.name}
							className={styles.frame}
							data-src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
								`${sfApiRoot}/files/assets/files/${
									slide.checksum
								}?oauth=${encodeURIComponent(authToken)}`
							)}`}
						/>
					</div>
				</section>
			)
		}
		if (slide.type.toUpperCase() === PDF) {
			return (
				<section className={styles.slideSection}>
					<div className={styles.slideContent}>
						<iframe
							title={slide.name}
							className={styles.frame}
							data-src={
								isIOS()
									? `https://pdfviewer.salesfra.me/?file=${encodeURIComponent(
											`${sfApiRoot}/files/assets/files/${
												slide.checksum
											}.pdf?oauth=${encodeURIComponent(authToken)}`
									  )}`
									: `${sfApiRoot}/files/assets/files/${
											slide.checksum
									  }?oauth=${encodeURIComponent(authToken)}`
							}
						/>
					</div>
				</section>
			)
		}
		if (slide.type.toUpperCase() === YOUTUBE) {
			return (
				<section className={styles.slideSection}>
					<div className={styles.slideContent}>
						<iframe
							title={slide.name}
							className={styles.frame}
							data-src={`https://www.youtube.com/embed/${slide.external_id}?rel=0&showinfo=0`}
						/>
					</div>
				</section>
			)
		}
		if (slide.type.toUpperCase() === VIMEO) {
			return (
				<section className={styles.slideSection}>
					<div className={styles.slideContent}>
						<iframe
							title={slide.name}
							className={styles.frame}
							data-src={`https://player.vimeo.com/video/${slide.external_id}?color=ffffff&title=0&byline=0&portrait=0`}
						/>
					</div>
				</section>
			)
		}
		if (VIDEOS.findIndex((item) => item === slide.type.toUpperCase()) > -1) {
			const videoPreview = getPreviewVideoUrl(
				slide.checksum,
				slide.type,
				authToken
			)
			return (
				<section className={styles.slideSection}>
					<video controls="controls" className={styles.slideContent}>
						<source
							type={videoPreview.fileType}
							data-src={videoPreview.fileUrl}
						/>
						<track kind="captions" label={slide.name} />
					</video>
				</section>
			)
		}
		if (slide.type.toUpperCase() === URL) {
			return (
				<section className={styles.slideSection}>
					<div className={styles.slideContent}>
						<iframe
							title={slide.name}
							className={styles.frame}
							data-src={
								addTokenToSlideTitle(slide)
									? `${slide.title}/${encodeURIComponent(authToken)}`
									: slide.title
							}
						/>
					</div>
				</section>
			)
		}
		return <div />
	}

	/**
	 * @description toggles whether a specific file should be starred or not
	 */
	function onStarChange() {
		const starringObject = {
			type: presentationSlides[currentSlideIndex]._type,
			material_id: presentationSlides[currentSlideIndex].id,
			status: presentationSlides[currentSlideIndex].status
		}
		starContent(starringObject, authToken).then(() => {
			dispatch(getAllStarredContent(authToken))
			toast(
				isThisStarred
					? t('notifications.success.unstarred-content')
					: t('notifications.success.starred-content'),
				{
					position: toast.POSITION.BOTTOM_RIGHT,
					type: SUCCESS.toLowerCase(),
					autoClose: 5000
				}
			)
		})
	}

	/**
	 * @description convert slides to pdf file
	 */
	const printToPdf = async () => {
		dispatch(setTempValue('generatePdfWidget', { visibility: true, pdf: '' }))
		const pdfData = {
			extended_fields: {
				summary: presentationSlides
			},
			document: 'presentation',
			userName: loggedInUserName
		}
		createPdfNew(pdfData, authToken)
		if (loadedPresentation) {
			dispatch(
				addToAnalyticsBatch({
					event: 'presentation.downloaded',
					uuid: fingerPrint
				})
			)
		}
	}

	/**
	 * @description download current file from slideshow
	 */
	const downloadFile = async () => {
		dispatch(
			addToAnalyticsBatch({
				event: 'presentation.material.download',
				origin: presentationSlides[currentSlideIndex]._type,
				parent_file_name: presentationSlides[currentSlideIndex]._file
					? presentationSlides[currentSlideIndex]._file.name
					: '',
				...presentationSlides[currentSlideIndex]
			})
		)
		const downloadLink = `${createImageUrl(
			presentationSlides[currentSlideIndex].checksum,
			authToken,
			presentationSlides[currentSlideIndex]._type
		)}`
		window.open(downloadLink, '_blank')
	}

	/**
	 * @description clicking next slideshow button and it restarts the timer and sends data
	 */
	function next() {
		restart()
		saveTime(time)
		Reveal.next()
	}
	/**
	 * @description clicking previous slideshow button and it restarts the timer and sends data
	 */
	function previous() {
		restart()
		saveTime(time)
		Reveal.prev()
	}

	const toggleFullscreen = () => {
		if (screenfull.isFullscreen) {
			screenfull.exit()
		} else {
			screenfull.request(document.querySelectorAll('body')[0])
		}
	}

	const showCloseSlideshowButton = () => {
		if (window.location !== window.parent.location) {
			// application is loaded from iframe
			if (document.referrer === 'https://callcenter.salesframe.com/') {
				return false
			}
		}
		return true
	}

	return (
		<AnimatePresence>
			<motion.div
				variants={animationVariants}
				animate={presentationSlides.length > 0 ? 'visible' : 'hidden'}
				exit="exit"
				transition={{
					duration: 0.2
				}}
				initial="hidden"
				className={`reveal ${styles.slideshowWrapper}`}
				ref={container}
			>
				<motion.div
					variants={topBarVariants}
					initial="hidden"
					animate={showTop || clicked ? 'visible' : 'hidden'}
					className={styles.topContainer}
				>
					<div className={styles.leftHeader}>
						<div className={styles.slideNumberContainer}>
							<div className={styles.verticalCenter}>
								<div className={styles.slideNumber}>
									{currentSlideIndex + 1}
								</div>
								<div className={styles.slash}>/</div>
								{presentationSlides.length > 0 && (
									<div className={styles.totalSlides}>
										{presentationSlides.length}
									</div>
								)}
							</div>
						</div>
						<div className={styles.titleContainer}>
							<div className={styles.presentationName}>
								{loadedPresentation
									? loadedPresentation.subject
									: t('misc.untitled-presentation')}
							</div>
							{presentationSlides.length > 0 && (
								<div className={styles.presentationDescription}>
									{presentationSlides[currentSlideIndex].name}
								</div>
							)}
						</div>
						<div className={styles.starButton}>
							<Button
								onClick={onStarChange}
								type={BUTTON_TEXT}
								icon={
									isThisStarred ? (
										<StarIcon className={styles.active} />
									) : (
										<StarBorderIcon />
									)
								}
								iconSide={ICON_LEFT}
								id="slideshow-star-button"
							/>
						</div>
						<div className={styles.downloadButton}>
							{presentationSlides.length > 0 && (
								<React.Fragment>
									{presentationSlides[currentSlideIndex].type ===
									YOUTUBE.toLowerCase() ? (
										<Button
											onClick={() =>
												window.open(
													`https://www.youtube.com/watch?v=${presentationSlides[currentSlideIndex].external_id}`,
													'_blank'
												)
											}
											type={BUTTON_TEXT}
											icon={<SaveAltIcon />}
											iconSide={ICON_LEFT}
											id="slideshow-download-button"
										/>
									) : (
										<Button
											onClick={downloadFile}
											type={BUTTON_TEXT}
											icon={<SaveAltIcon />}
											iconSide={ICON_LEFT}
											id="slideshow-download-button"
										/>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
					<div className={styles.rightHeader}>
						<div className={styles.verticalCenter}>
							<Button
								onClick={printToPdf}
								type={BUTTON_TEXT}
								icon={<PictureAsPdfIcon />}
								iconSide={ICON_LEFT}
								id="slideshow-pdf-button"
							/>
						</div>
						<div className={styles.fullScreenButton}>
							<Button
								onClick={toggleFullscreen}
								type={BUTTON_TEXT}
								icon={<FullscreenIcon />}
								iconSide={ICON_LEFT}
								id="slideshow-fullscreen-button"
							/>
						</div>
						{showCloseSlideshowButton() && (
							<div className={styles.closeButton}>
								<Button
									onClick={handleGoBack}
									type={BUTTON_TEXT}
									icon={<CloseIcon />}
									iconSide={ICON_LEFT}
									id="slideshow-close-button"
								/>
							</div>
						)}
					</div>
				</motion.div>
				<motion.div
					variants={slideShowNavigationVariants}
					animate={showLeft || clicked ? 'visible' : 'hidden'}
					initial="hidden"
					className={classnames(
						styles.navigationContainer,
						!showLeft ? styles.noControl : '',
						styles.left
					)}
					role="button"
					onKeyDown={null}
					tabIndex={-1}
					onClick={() => previous()}
				>
					<ArrowBackIosIcon htmlColor="#ffffff" />
				</motion.div>
				<motion.div
					variants={slideShowNavigationVariants}
					animate={showRight || clicked ? 'visible' : 'hidden'}
					initial="hidden"
					className={classnames(
						styles.navigationContainer,
						!showRight ? styles.noControl : '',
						styles.right
					)}
					role="button"
					onKeyDown={null}
					tabIndex={-1}
					onClick={() => next()}
				>
					<ArrowForwardIosIcon htmlColor="#ffffff" />
				</motion.div>
				<Background zIndex={0} blurDeviation={10} />
				<div className="reveal" ref={revealRef}>
					<div
						className="slides"
						onClick={() => toggleClicked(!clicked)}
						role="button"
						onKeyDown={null}
						tabIndex={-1}
					>
						{presentationSlides.map((item, index) => (
							<React.Fragment key={index.toString()}>
								{renderSlides(item)}
							</React.Fragment>
						))}
					</div>
				</div>
			</motion.div>
		</AnimatePresence>
	)
}

export default SlideshowReveal
