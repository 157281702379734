export const genericAnimation = {
	visible: custom => ({
		y: 0,
		opacity: 1,
		transition: {
			staggerChildren: 0.4,
			delay: custom * 0.02,
			when: 'beforeChildren',
		},
	}),
	hidden: {
		opacity: 0,
		y: 10,
	},
	exit: custom => ({
		y: -10,
		opacity: 0,
		transition: {
			staggerChildren: 0.4,
			delay: custom * 0.02,
			when: 'beforeChildren',
		},
	}),
}

export const animationOnAnimate = tagIndex => ({
	y: 0,
	x: 0,
	opacity: 1,
	transition: {
		staggerChildren: 0.4,
		delay: tagIndex * 0.05,
		when: 'beforeChildren',
	},
})

export const animationOnExit = tagIndex => ({
	y: -10,
	opacity: 0,
	x: 0,
	transition: {
		staggerChildren: 0.4,
		delay: tagIndex * 0.05,
		when: 'beforeChildren',
	},
})

export const titleVariants = {
	animated: {
		opacity: 1,
		y: 0,
	},
	out: {
		opacity: 0,
		y: 0,
	},
	initial: {
		opacity: 0,
		y: -30,
	},
}
