import React from 'react'
import PropTypes from 'prop-types'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { AnimatePresence, motion } from 'framer-motion'
import c from 'classnames'

import styles from './FixedNavigation.module.scss'

const animationVariants = {
	visible: {
		x: 0
	},
	hidden: {
		x: -50
	},
	exit: {
		x: -50
	}
}

const containerVariants = {
	notVisible: {
		height: 0
	},
	visible: {
		height: 92
	}
}

/**
 * @author zilahir
 * @function FixedNavigation
 * */

const FixedNavigation = ({ toRootLevel, handleBackNavigation, isVisible }) => (
	<motion.div
		className={c(styles.fixedNavigationContainer, styles.leftDistance)}
		variants={containerVariants}
		initial="notVisible"
		animate={isVisible ? 'visible' : 'notVisible'}
		transition={{
			type: 'spring',
			bounce: 0,
			velocity: 2,
			duration: 0.3
		}}
	>
		<AnimatePresence>
			{isVisible && (
				<motion.div
					className={styles.navBtnContainer}
					variants={animationVariants}
					initial="hidden"
					animate={isVisible ? 'visible' : 'hidden'}
					exit="exit"
					transition={{
						type: 'spring',
						bounce: 0,
						velocity: 2,
						duration: 0.3,
						delay: !isVisible ? 0.3 : 0
					}}
				>
					<motion.button
						type="button"
						onClick={handleBackNavigation}
						className={styles.button}
						whileHover={{
							scale: 1.2
						}}
					>
						<ChevronLeftIcon htmlColor="#ffffff" />
					</motion.button>
					<motion.button
						type="button"
						onClick={toRootLevel}
						className={styles.button}
						whileHover={{
							scale: 1.2
						}}
					>
						<FirstPageIcon htmlColor="#ffffff" />
					</motion.button>
				</motion.div>
			)}
		</AnimatePresence>
	</motion.div>
)

FixedNavigation.defaultProps = {
	isVisible: false
}

FixedNavigation.propTypes = {
	handleBackNavigation: PropTypes.func.isRequired,
	isVisible: PropTypes.bool,
	toRootLevel: PropTypes.func.isRequired
}

export default FixedNavigation
