import React from 'react'
import classnames from 'classnames'

import styles from './Results.module.scss'

interface ResultsInterface {
	getId: (id: string) => void
	id: string
	isSelected: boolean
	name: string
}

const Results = ({ getId, id, isSelected, name }: ResultsInterface) => {
	return (
		<div
			className={classnames(styles.customerItem, isSelected && styles.selected)}
			onClick={() => getId(id)}
		>
			<div>
				<p className={styles.feedItemText}>
					<strong>{name}</strong>
				</p>
			</div>
		</div>
	)
}

export default Results
