import { cloudFnPost } from '../../utils/requests'
import { GeneratePdfRequestDataInterface } from '../../utils/interfaces'
import { generatePdfEndpoint } from '../apiEndpoints_new'
import { createPdfSchema } from '../../utils/schemas/requests'

export const createPdfNew = (
	data: GeneratePdfRequestDataInterface,
	authToken: any
) =>
	new Promise((resolve, reject) => {
		cloudFnPost(
			generatePdfEndpoint,
			data,
			{ withToken: true, authToken },
			createPdfSchema
		)
			.then((result) => {
				resolve(result)
			})
			.catch((error) => {
				reject(new Error(`There was an error: ${error}`))
			})
	})
